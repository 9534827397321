import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Input, PageHeader, Radio, Select} from 'antd';
import React  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ApplicationState } from '../../store';
import { getRandomString, __ } from '../../utilities';
import EditableTable from './EditableTable';
import { createLocaleParameterValues, createParameterValue, loadParameter, localeIdSelector, LocaleParameterValue, parameterSelector, removeParameterValue, setLocaleId, updateParameter, updateParameterField, updateParameterValue, updateParameterValuesPositions } from './slice';

const FormWrapper = styled.div`
  width: fit-content
`

const MainContent = styled.div`
  margin-top: 25px;
  padding: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`

const formItemLayout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
};

const ParameterDetail = ({parameterId}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    React.useEffect(() => {
        async function init() {
          await dispatch(loadParameter(parameterId,form));
        }
        init();
    }, [dispatch, form, parameterId]);

    const parameter = useSelector((state: ApplicationState) =>
        parameterSelector(state)
    );

    const localeId = useSelector((state: ApplicationState) =>
        localeIdSelector(state)
    );

    const onSaveClick = () => {
        dispatch(updateParameter(form));
    }

    const onLocaleClick = (e) => {
        dispatch(setLocaleId(e.target.value,form))
    }

    const onValueRemoveClick = (valueId:string) => {
        dispatch(removeParameterValue(valueId));
    }

    const defaultTableItem:LocaleParameterValue = {
        _id: "NEW_"+getRandomString(10),
        internal_name: "NOVA HODNOTA",
        name: "NOVA HODNOTA",
        index: 9999  
    } 
    
    return <> 
        <PageHeader 
            title = {"Detail parametru"}
            ghost={false}
            extra={[
                <Button key="saveButton" onClick={onSaveClick}> 
                    Uložiť 
                </Button>,
                <Radio.Group
                    key="selectLocale"
                    value={localeId}
                    buttonStyle="solid"
                    style={{ width: '100%' }}
                    onChange={onLocaleClick}
                >
                    <Radio.Button value={1}>SK</Radio.Button>
                    <Radio.Button value={2}>CZ</Radio.Button>
                </Radio.Group>
            ]}
        /> 

        <MainContent>
            <FormWrapper>
                <Form form={form} 
                      onFieldsChange={(_, allFields) => {
                          if(_ && _.length){
                            const dataIndex = _[0].name[0];
                            const value = _[0].value;
                            dispatch(updateParameterField(dataIndex,value))
                          } 
                      }}
                      {...formItemLayout}>
                    <Form.Item 
                        label="Interný názov"
                        name="internal_name"
                        rules={[]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item 
                        label="Názov"
                        name="name"
                        rules={[]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="type"
                        label="Typ"
                    >
                        <Select placeholder="Typ parametru">
                            <Select.Option value="VALUES">Hodnotový</Select.Option>
                            <Select.Option value="NUMBERS">Číselný</Select.Option>
                        </Select>   
                    </Form.Item>

                    <Form.Item
                        name="unit"
                        label="Jednotka"
                    >
                        <Input />
                    </Form.Item> 
  
                    <Form.Item
                        name="description"
                        label="Popis"
                    >
                        <Input.TextArea />
                    </Form.Item> 

                </Form>
            </FormWrapper>
        </MainContent>
        
        {parameter && parameter.type === "VALUES" && 
           <MainContent>
                <h2> Hodnoty </h2>
                <div>
                    <EditableTable
                        rowKey = "_id"
                        onUpdate = {(data,dataIndex) => {
                            dispatch(updateParameterValue(dataIndex,data))
                        }}
                        onCreate = {(data) => {
                            dispatch(createParameterValue(data))
                        }}
                        onUpdatePositions = {(data => {
                            dispatch(updateParameterValuesPositions(data.oldIndex,data.newIndex));
                        })}
                        columns = {[
                            {
                                title: __('Interný názov'),
                                dataIndex: 'internal_name',
                                sorter: false,
                                inputType: 'text',
                                editable: true,
                            },
                            {
                                title: __('Názov'),
                                dataIndex: 'name',
                                sorter: false,
                                inputType: 'text',
                                editable: true,
                            },
                            {
                                title: __('Akcie'),
                                dataIndex: 'actions',
                                sorter: false,
                                editable: false,
                                render: (_id,data) => {
                                    return <Button type="primary"
                                                    size="small"
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => {
                                                    onValueRemoveClick(data._id);
                                                    }} 
                                            />
                                            
                                    }
                            }
                        ]}
                        dataSource = {parameter?createLocaleParameterValues(parameter.values, localeId):[]}
                        trans={{
                            addRowText:"Pridať hodnotu"
                        }}
                        defaultItem = {defaultTableItem}
                        isSortable = {true}
                    >
                        
                    </EditableTable>
                </div>
            </MainContent>
        }        
    </>
}

export default ParameterDetail;