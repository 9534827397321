
import { Modal, Row, Col, Upload, Button,Input } from 'antd';
import React,{useState} from 'react';
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import {
    changeVideoId,
    addEmptyVideo,
    removeVideo,
    changeVideoUrl,
    changeModel3dUrl,
    addEmptyModel3d,
    removeModel3d,
    addPictureToGallery,
    removePictureFromGallery,
    setDetailImageFile,
    updateAssetInfo
} from '../../containers/ProductDetail/slice';
import styled from 'styled-components';

const InputCol = styled(Col)`
    margin: 5px;
`;

const GalleryModal = (props) => {

    const dispatch = useDispatch();
    
    const { isVisible, onClose, title} = props;
    const [isDetailImageLoading] = useState(false);

    const handleOk = () => { 
        dispatch(updateAssetInfo());
        onClose();
    }

    const uploadButton = (
        <div>
          {isDetailImageLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div className="ant-upload-text">Upload</div>
        </div>
    );

    const handleDetailImageChange = (info) => {
        /*
        if (info.file.status === 'uploading') {
            setIsDetailImageLoading(true);
            return;
        }

        if (info.file.status === 'done') {
            setIsDetailImageLoading(false);  
            getBase64(info.file.originFileObj, imageUrl =>
              setDetailImageUrl(imageUrl)
            );
        }
        */
    }

    const onChangeVideoId = (e,i) => {
        dispatch(changeVideoId(e.target.value,i));
    }

    const onChangeVideoUrl = (e,i) => {
        dispatch(changeVideoUrl(e.target.value,i));
    }

    const onClickAddVideo = () => {
        dispatch(addEmptyVideo());
    }
    
    const onClickRemoveVideo = (e,i) => {
        dispatch(removeVideo(i));
    }
    
    const onChangeModel3dUrl = (e,i) => {
        dispatch(changeModel3dUrl(e.target.value,i));
    }

    const onClickAddModel3d = () => {
        dispatch(addEmptyModel3d());
    }
    
    const onClickRemoveModel3d = (e,i) => {
        dispatch(removeModel3d(i));
    }

    const beforeUploadGallery = (file: any, FileList: any[]):boolean => {
        dispatch(addPictureToGallery(file));
        return false;
    }

    const onGalleryRemove = (files) => {
        if(files.file.status === 'removed'){
            dispatch(removePictureFromGallery(files.file.uid));
        }
    }

    const beforeUploadDetail = (info:any) => {
        dispatch(setDetailImageFile(info));
        return false;
    }

    const onCancelClick = () => {
        onClose();
    }

    return (
            <Modal
                title = {title}
                visible = {isVisible}
                onOk = {handleOk}
                onCancel = {onCancelClick}
                width = {800}
            >   
                {props.assetInfo ? (
                <>    
                <Row>
                    <Col span={6}>
                    <p> Hlavný obrázok </p>
                    <Upload
                            name="file"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="http://localhost:9999/v1/product/7390462/image"
                            onChange={handleDetailImageChange}
                            beforeUpload = {beforeUploadDetail}
                        >
                            {props.detailImageFile ? <img src={props.detailImageFile.url} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                    </Col>
                    <Col span={18}/>
                </Row> 

                <Row>
                    <Col>
                        <p> Galeria </p>
                        <Upload
                            listType="picture-card"
                            fileList={props.gallery.map((galleryImage,i) => {
                                return {
                                    uid: i,
                                    name: galleryImage.name,
                                    status: 'done',
                                    url: galleryImage.url,
                                }
                            })}
                            beforeUpload={beforeUploadGallery}
                            onChange={ (files) => onGalleryRemove(files)}

                            >
                        {props.assetInfo.image.gallery.length >= 8 ? null : uploadButton}
        </Upload> 
                    </Col>                    
                </Row>

                <Row>
                    <Col>
                    <p> Videa </p> 
                    {props.assetInfo.video && props.assetInfo.video.map((video,i) => {
                        return (
                            <Row>
                               <InputCol>
                                    <Input
                                            value={video.id}
                                            style={{
                                                width: 200,
                                            }}
                                            onChange = { (e) => onChangeVideoId(e,i) }
                                            />
                               </InputCol>
                               <InputCol>
                                    <Input
                                            value={video.url}
                                            style={{
                                                width: 200,
                                            }}
                                            onChange = { (e) => onChangeVideoUrl(e,i) }
                                    /> 
                               </InputCol>
                               <InputCol>
                                    <Button icon={<DeleteOutlined />} onClick={ (e) => onClickRemoveVideo(e,i)} />
                               </InputCol>
                            </Row>
                        );
                    })}

                    <Row>
                        <InputCol>
                            <Button icon={<PlusOutlined />} onClick={onClickAddVideo}  />
                        </InputCol>
                    </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <p> 3D modely </p>
                    {props.model3d && props.model3d.map((model3d,i) => {
                        return (
                            <>
                            {model3d? 
                                <Row>
                                    <InputCol>
                                        <Input
                                            value={model3d.url}
                                            onChange = { (e) => onChangeModel3dUrl(e,i)  }
                                        /> 
                                    </InputCol>
                                    <InputCol>
                                        <Button icon={<DeleteOutlined />} onClick={(e) => onClickRemoveModel3d(e,i)} />
                                    </InputCol>
                                </Row>  
                                :null
                            }
                            </>
                        );
                    })}

                    <Row>
                        <InputCol>
                            <Button icon={<PlusOutlined />} onClick={onClickAddModel3d}  />
                        </InputCol>
                    </Row>
                    </Col>
                </Row>
                </>
                ):null
                }
            </Modal>
    )
}

export default GalleryModal;
