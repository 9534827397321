import React from 'react';
import {
    Select,
    Spin
} from 'antd';

const { Option } = Select;

const ConnectedSingleSelect = (props) => {
    const { selectWidth, disabled } = props;

    return (
      <Select
        showSearch
        value={props.value}
        placeholder={props.name}
        notFoundContent={props.isFetching ? <Spin size="small" /> : null}
        filterOption={false}
        onChange={props.onSelect}
        onSearch={props.onChange}
        style={{ width: selectWidth }}
        defaultActiveFirstOption={false}
        disabled={disabled}
      >
          {props.data.map(d => (
            <Option value={d.value} key={d.value}>
                {d.name}
            </Option>
          ))}
      </Select>
    );

}

export default ConnectedSingleSelect;
