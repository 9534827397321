import {
  DatePicker,
  PageHeader,
  Select,
  Table,
  TreeSelect,
} from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import locale from 'antd/es/date-picker/locale/sk_SK';
import { __ } from '../../utilities';
import {
  setFilter,
  startDateActiveFiltersSelector,
  endDateActiveFiltersSelector,
  loadProductStatList,
  productsListSelector,
  productsListIsFethingSelector,
  paginationSelector,
  totalSelector,
  setPagination,
  setSorter,
  loadGeneralStats,
  loadGeneralDailyStats,
  generalStatsSelector,
  generalStatsFethingSelector,
  generalDailyStatsSelector,
  loadTopCategories,
  loadChildrenCategories,
  categoryTreeSelector,
  shopFiltersDataIsFetching,
  shopActiveFiltersSelector,
  shopFiltersDataSelector,
  loadShopFilterData,
  sourceActiveFiltersSelector,
  loadSetProduct,
  productIdsActiveFiltersSelector,
  productIdsDataSelector,
} from './slice';
import { ApplicationState } from '../../store';
import moment from 'moment';
import ProductListCharts from '../../components/Product/ProductListCharts';
import ConnectedMultiSelect from '../../components/Select/ConnectedMultiSelect';

const MainContent = styled.div`
  margin-top: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;
const StatsSection = styled.div`
  margin-top: 20px;
`;

const ProductStatsList = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function init() {
      dispatch(loadProductStatList());
      dispatch(loadGeneralStats());
      dispatch(loadGeneralDailyStats());
      dispatch(loadTopCategories());
    }
    init();
  }, [dispatch]);

  const countryData = [
    { name: 'SK', value: 1 },
    { name: 'CZ', value: 2 },
  ];

  const sources = [
    { name: 'Web', value: 1 },
    { name: 'PCN', value: 2 },
  ];

  const products = useSelector((state: ApplicationState) =>
    productsListSelector(state)
  );
  const productsIsFetching = useSelector((state: ApplicationState) =>
    productsListIsFethingSelector(state)
  );

  const pagination = useSelector((state: ApplicationState) =>
    paginationSelector(state)
  );
  const total = useSelector((state: ApplicationState) => totalSelector(state));

  const generalStatsFething = useSelector((state: ApplicationState) =>
    generalStatsFethingSelector(state)
  );
  const generalDailyStats = useSelector((state: ApplicationState) =>
    generalDailyStatsSelector(state)
  );
  const generalStats = useSelector((state: ApplicationState) =>
    generalStatsSelector(state)
  );
  const loadData = () => {
    dispatch(loadProductStatList());
    dispatch(loadGeneralStats());
    dispatch(loadGeneralDailyStats());
  };

  const onChangeTable = async (pagination, filters, sorters) => {
    dispatch(setPagination(pagination.current, pagination.pageSize));
    dispatch(setSorter(sorters.field, sorters.order));
    dispatch(loadProductStatList());
  };

  const onDateRangeChange = value => {
    if (value) {
      dispatch(setFilter('startDate', value[0]));
      dispatch(setFilter('endDate', value[1]));
    } else {
      dispatch(setFilter('startDate', null));
      dispatch(setFilter('endDate', null));
    }
    loadData();
  };

  const startDate = useSelector((state: ApplicationState) =>
    startDateActiveFiltersSelector(state)
  );

  const endDate = useSelector((state: ApplicationState) =>
    endDateActiveFiltersSelector(state)
  );

  const categoryTree = useSelector((state: ApplicationState) =>
    categoryTreeSelector(state)
  );
  const onLoadTreeData = async treeNode => {
    await dispatch(loadChildrenCategories(treeNode.key));
  };
  const onSelectCategory = selectedKeys => {
    dispatch(setFilter('category', selectedKeys));
    loadData();
  };
  const onSelectCountryFilter = async value => {
    dispatch(setFilter('country', value));
    loadData();
  };

  const isFetchingShopData = useSelector((state: ApplicationState) =>
    shopFiltersDataIsFetching(state)
  );

  const shopActiveFilters = useSelector((state: ApplicationState) =>
    shopActiveFiltersSelector(state)
  );
  const shopData = useSelector((state: ApplicationState) =>
    shopFiltersDataSelector(state)
  );
  const onSelectShopFilter = async value => {
    dispatch(setFilter('shop', value));
    loadData();
  };

  const onChangeShopFilter = async value => {
    await dispatch(loadShopFilterData(value));
  };
  const onSourceChange = async value => {
    dispatch(setFilter('source', value));
    loadData();
  };
  const selectedSource = useSelector((state: ApplicationState) =>
    sourceActiveFiltersSelector(state)
  );
  const onSelectProduct = (product_id: number) => {
    dispatch(setFilter('productIds', product_id));
    loadData();
  };
  const onChangeProductSearch = async value => {
    await dispatch(loadSetProduct(value));
  };
  const selectedProducts = useSelector((state: ApplicationState) =>
    productIdsActiveFiltersSelector(state)
  );

  const productsFiltersData = useSelector((state: ApplicationState) =>
    productIdsDataSelector(state)
  );
  return (
    <>
      <PageHeader
        title={'Štatistiky produktov'}
        ghost={false}
        extra={[
          <ConnectedMultiSelect
            key={'Produkty'}
            name={'Produkty'}
            value={selectedProducts}
            data={productsFiltersData}
            selectWidth={200}
            onChange={onChangeProductSearch}
            onSelect={onSelectProduct}
          />,
          <ConnectedMultiSelect
            key={'Eshop'}
            name={__('Eshop')}
            isFetching={isFetchingShopData}
            value={shopActiveFilters}
            data={shopData}
            selectWidth={150}
            onChange={onChangeShopFilter}
            onSelect={onSelectShopFilter}
          />,
          <TreeSelect
            key={'category'}
            multiple
            showSearch={false}
            style={{ width: 190 }}
            dropdownStyle={{ maxHeight: 900, overflow: 'auto' }}
            placeholder="Kategorie"
            onChange={onSelectCategory}
            loadData={onLoadTreeData}
            treeData={categoryTree}
          />,
          <Select
            key={'Krajina'}
            showSearch
            style={{ width: 100 }}
            placeholder="Krajina"
            optionFilterProp="children"
            onChange={onSelectCountryFilter}
          >
            <Select.Option key={'X'} value={0}>
              Všetky
            </Select.Option>
            {countryData.map(country => {
              return (
                <Select.Option value={country.value} key={country.value}>
                  {country.name}
                </Select.Option>
              );
            })}
          </Select>,

          <Select
            key={'Zdroj'}
            showSearch
            style={{ width: 100 }}
            placeholder="Zdroj"
            optionFilterProp="children"
            onChange={onSourceChange}
            value={selectedSource}
          >
            <Select.Option key={"X"} value={0}>
              Všetko
            </Select.Option>
            {sources &&
              sources.map(source => {
                return (
                  <Select.Option value={source.value} key={source.value}>
                    {source.name}
                  </Select.Option>
                );
              })}
          </Select>,
          <DatePicker.RangePicker
            key={'Date'}
            locale={locale}
            ranges={{
              Dnes: [moment(), moment()],
              Včera: [
                moment().subtract(1, 'days'),
                moment().subtract(1, 'days'),
              ],
              'Tento mesiac': [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
              'Minulý mesiac': [
                moment()
                  .subtract(1, 'months')
                  .startOf('month'),
                moment()
                  .subtract(1, 'months')
                  .endOf('month'),
              ],
              'Posledných 7 dní': [moment().subtract(7, 'days'), moment()],
            }}
            value={[startDate, endDate]}
            onChange={onDateRangeChange}
            format={'DD.MM.YYYY'}
          />,
        ]}
      />

      <StatsSection>
        <ProductListCharts
          generalStats={generalStats}
          generalDailyStats={generalDailyStats}
          generalStatsFething={generalStatsFething}
        />
      </StatsSection>
      <MainContent>
        <Table
          loading={productsIsFetching}
          columns={[
            {
              title: __('Nazov'),
              dataIndex: 'name',
              sorter: true,
            },
            {
              title: __('Kategoria'),
              dataIndex: 'category_name',
              sorter: true,
            },
            {
              title: __('Impresie'),
              dataIndex: 'impressions',
              sorter: true,
            },
            {
              title: __('Prekliky'),
              dataIndex: 'clicks',
              sorter: true,
            },
            {
              title: __('CTR'),
              dataIndex: 'ctr',
            },
            {
              title: __('CPC'),
              dataIndex: 'cpc',
              sorter: true,
            },
            {
              title: __('Obrat'),
              dataIndex: 'spend',
              sorter: true,
              render: (value, data) => {
                return `${value} €`;
              },
            },
            {
              title: __('Aktivne ponuky'),
              dataIndex: 'active_offers',
              render: (value, data) => {
                const shop = data.active_shops;
                return `${value} (${shop})`;
              },
            },
          ]}
          dataSource={products}
          pagination={{
            total: total,
            current: pagination.current,
            pageSize: pagination.pageSize,
          }}
          onChange={(pagination, filters, sorters) => {
            onChangeTable(pagination, filters, sorters);
          }}
        />
      </MainContent>
    </>
  );
};

export default ProductStatsList;
