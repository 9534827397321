import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, ApplicationState } from "../../store";
import API from "../../services/API";
import { push } from "connected-react-router";
import { getValuesFromArray } from "../../utilities";
import moment from 'moment';

const NAMESPACE = 'affilStats';

interface GeneralStats {
    count: number,
    impressions: number,
    clicks: number,
    payment: number,
    widget_session_clicks: number,
    widget_session_widget_clicks: number,
    widget_session_payment: number,
    widget_session_payment_provision: number,
}

interface GeneralDailyStats {
    date: string,
    count: number,
    impressions: number,
    clicks: number,
    payment: number,
}

interface WidgetStat {
    _id: string,
    clicks: number,
    impressions: number,
    payment: number,
    content: string,
    type: string,
    ctr: number,
    cpc: number,
    affiliateName: string,
    categoryName: string,
    activeOffersCount: number, 
    activeShopsCount: number,
    tags: Array<string>
}
interface WidgetStats {
    total: number
    items: Array<WidgetStat>
}

interface AffilData {
    _id: string,
    name: string
}


interface AffilStatsState {
    activeFilters: {
        startDate: moment.Moment;
        endDate: moment.Moment;
        selectedAffiliateId: number;
        selectedAffiliateName: string;
        selectedTags: string[];
        categorySelected: { value: number}[],
    },
    filtersData: {
        generalStats: {
            isFetching: boolean,
            data: any
        },
        affiliateList: {
            isFetching: boolean,
            data: any
        },
        tagsList: {
            isFetching: boolean,
            data: string[]
        },
        category: {
            isFetching: boolean,
            data: any
        }
    },
    generalStats: {
       isFetching: boolean,
       data: GeneralStats
    },
    generalDailyStats: {
        isFetching: boolean,
        data: Array<GeneralDailyStats>
    },
    widgetStats: {
       isFetching: boolean,
       data: WidgetStats
    },
    affilName: string,
    categoryIds: number[],
    exportIsFething: boolean
}

const initialState : AffilStatsState = {
    activeFilters: {
        startDate: moment().subtract(7,"days"),
        endDate: moment(),
        selectedAffiliateId: null,
        selectedAffiliateName: null,
        selectedTags: null,
        categorySelected: [],
    },
    filtersData: {
        generalStats: {
            isFetching: false,
            data: []
        },
        affiliateList: {
            isFetching: false,
            data: []
        },
        tagsList: {
            isFetching: false,
            data: []
        },
        category: {
            isFetching: false,
            data: []
        }
    },
    generalStats: {
        isFetching: false,
        data: null
    },
    generalDailyStats: {
        isFetching: false,
        data: null
    },
    widgetStats: {
        isFetching: false,
        data:{
            total: 0,
            items: []
        }
    },
    affilName: null,
    categoryIds: [],
    exportIsFething: false
}

export const affilStatsSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },
        setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
        setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | string[] | number | null | moment.Moment}>) => {
            console.log("SETTINGS");
            console.log(action.payload.name);
            console.log(action.payload.value);

            state.activeFilters[action.payload.name] = action.payload.value;
        },
        setGeneralStats: (state, action: PayloadAction<GeneralStats> ) => {
            state.generalStats.data = action.payload
        },
        setGeneralDailyStats: (state, action: PayloadAction<Array<GeneralDailyStats>> ) => {
            state.generalDailyStats.data = action.payload
        },
        setWidgetStats: (state, action: PayloadAction<WidgetStats> ) => {
            state.widgetStats.data = action.payload
        },
        setGeneralStatsFething: (state, action: PayloadAction<boolean> ) => {
            state.generalStats.isFetching = action.payload
        },
        setGeneralDailyStatsFething: (state, action: PayloadAction<boolean> ) => {
            state.generalDailyStats.isFetching = action.payload
        },
        setWidgetStatsFething: (state, action: PayloadAction<boolean> ) => {
            state.widgetStats.isFetching = action.payload
        },
        setAffilName: (state, action: PayloadAction<string> ) => {
            state.affilName = action.payload
        },
        setCategoryIds: (state, action: PayloadAction<Array<number>> ) => {
            state.categoryIds = action.payload
        },
        setExportFething:(state, action: PayloadAction<boolean> ) => {
            state.exportIsFething = action.payload
        },
        resetState: state => initialState
    }
});

export const loadGeneralStats = () : AppThunk => async (dispatch, getState) => {
    dispatch(affilStatsSlice.actions.setGeneralStatsFething(true));   

    const startDate = startDateActiveFiltersSelector(getState());
    const endDate = endDateActiveFiltersSelector(getState());
    const selectedAffiliateId = selectedAffiliateIdSelector(getState());
    const selectedTags = selectedTagsSelector(getState());
    const selectedCategoryIds = getValuesFromArray<number>(categoryActiveFiltersSelector(getState()));
    
    const token = tokenSelector(getState());
    API.setToken(token);

    API.loadWidgetGeneralStats({
        startDate: startDate?startDate.format('YYYY-MM-DD'):undefined,
        endDate: endDate?endDate.format('YYYY-MM-DD'):undefined,
        affiliateIds: selectedAffiliateId?[selectedAffiliateId]:undefined,
        tags: selectedTags?selectedTags.join("<,>"):undefined,
        categoryIds: selectedCategoryIds?selectedCategoryIds:undefined
    }).then(response => {
        
        const generalStats:GeneralStats = {
           clicks: response.clicks,
           count: response.count,
           impressions: response.impressions,
           payment: response.payment, 
           widget_session_clicks: response.widget_session_clicks,
           widget_session_widget_clicks: response.widget_session_widget_clicks,
           widget_session_payment: response.widget_session_payment,
           widget_session_payment_provision: response.widget_session_payment_provision,
        }

        dispatch(affilStatsSlice.actions.setGeneralStats(generalStats));
        dispatch(affilStatsSlice.actions.setGeneralStatsFething(false));       
    });
}

export const loadGeneralDailyStats = () : AppThunk => async (dispatch, getState) => {
    dispatch(affilStatsSlice.actions.setGeneralDailyStatsFething(true));   

    const startDate = startDateActiveFiltersSelector(getState());
    const endDate = endDateActiveFiltersSelector(getState());
    const selectedAffiliateId = selectedAffiliateIdSelector(getState());
    const selectedTags = selectedTagsSelector(getState());
    const selectedCategoryIds = getValuesFromArray<number>(categoryActiveFiltersSelector(getState()));
    
    const token = tokenSelector(getState());
    API.setToken(token);

    API.loadWidgetGeneralDailyStats({
        startDate: startDate?startDate.format('YYYY-MM-DD'):undefined,
        endDate: endDate?endDate.format('YYYY-MM-DD'):undefined,
        affiliateIds: selectedAffiliateId?[selectedAffiliateId]:undefined,
        tags: selectedTags?selectedTags.join("<,>"):undefined,
        categoryIds: selectedCategoryIds?selectedCategoryIds:undefined
    }).then(response => {
        const generalDailyStats = response.items.map(item => {
            return {
                date: item.date,
                clicks: item.clicks,
                impressions: item.impressions,
                count: item.count,
                payment: item.payment,
                widget_session_clicks: item.widget_session_clicks,
                widget_session_widget_clicks: item.widget_session_widget_clicks,
                widget_session_payment: item.widget_session_payment,
                widget_session_payment_provision: item.widget_session_payment_provision,
            }
        }); 

        generalDailyStats.sort((a,b)=>{
            return a.date.localeCompare(b.date);
        });

        dispatch(affilStatsSlice.actions.setGeneralDailyStats(generalDailyStats));
        dispatch(affilStatsSlice.actions.setGeneralStatsFething(false));       
    });
}

export const loadWidgetStats = () : AppThunk => async (dispatch, getState) => {
    dispatch(affilStatsSlice.actions.setWidgetStatsFething(true));   

    const startDate = startDateActiveFiltersSelector(getState());
    const endDate = endDateActiveFiltersSelector(getState());
    const selectedAffiliateId = selectedAffiliateIdSelector(getState());
    const selectedTags = selectedTagsSelector(getState());
    const selectedCategoryIds = getValuesFromArray<number>(categoryActiveFiltersSelector(getState()));

    const token = tokenSelector(getState());
    API.setToken(token);

    API.loadWidgetStats({
        startDate: startDate?startDate.format('YYYY-MM-DD'):undefined,
        endDate: endDate?endDate.format('YYYY-MM-DD'):undefined,
        affiliateId: selectedAffiliateId?selectedAffiliateId:undefined,
        tags: selectedTags?selectedTags.join("<,>"):undefined,
        categoryIds: selectedCategoryIds?selectedCategoryIds:undefined,
        limit: 10000
    }).then(response => {
        const widgetStats:WidgetStats =  {
            total: response.total,
            items: response.items.map(item => {
               let content = "";
               if(item.widget && (item.widget.type === 'product' || item.widget.type === 'modal_product' || item.widget.type === 'button_product')){
                   if(item.widget.widget_product && item.widget.widget_product.product){
                       content = item.widget.widget_product.product.name
                   }
               }
               
               const aggregation = item.widget.widget_product.product.aggregations?item.widget.widget_product.product.aggregations.find(aggregation => aggregation.country_id === 1):null;
 
               return {
                   _id: item._id,
                   clicks: item.clicks,
                   impressions: item.impressions,
                   payment: item.payment,
                   content: content,
                   type: item.widget.type,
                   ctr: item.impressions?((item.clicks/item.impressions)*100):0,
                   cpc: item.clicks?(item.payment/item.clicks):0,
                   affiliateName: item.widget && item.widget.affiliate && item.widget.affiliate.name?item.widget.affiliate.name:null,
                   categoryName: item.widget && item.widget.widget_product && item.widget.widget_product.product && item.widget.widget_product.product.category?item.widget.widget_product.product.category.name:"",
                   activeOffersCount: aggregation?aggregation.active_offers_count:0,
                   activeShopsCount: aggregation?aggregation.active_shops_count:0,
                   tags: item.widget && item.widget.tags && item.widget.tags.length?item.widget.tags:[],
                   widget_session_clicks: item.widget_session_clicks,
                   widget_session_widget_clicks: item.widget_session_widget_clicks,
                   widget_session_payment: item.widget_session_payment,
                   widget_session_payment_provision: item.widget_session_payment_provision,
               }
            })
        }
        
        dispatch(affilStatsSlice.actions.setWidgetStats(widgetStats));
        dispatch(affilStatsSlice.actions.setWidgetStatsFething(false));   
    })

}

export const loadUserAffil = () : AppThunk => async (dispatch, getState) => {
    const token = tokenSelector(getState());
    API.setToken(token);

    API.loadUserAffils().then(response => {
       if(response.items && response.items.length) {
        dispatch(affilStatsSlice.actions.setAffilName(response.items[0].name));
       }
    })
}

export const loadAffilList = () : AppThunk => async (dispatch, getState) => {
    const token = tokenSelector(getState());
    API.setToken(token);

    API.getAffils({hasAssignedUser:true,sortType:'name',sortOrder:'ASC'}).then(response => {
        const affilsData = response.items.map(item => {
            return {
                _id: item._id,
                affiliate_id: item.affiliate_id,
                name: item.name
            }
        });
        dispatch(affilStatsSlice.actions.setFilterData({name:"affiliateList", value: affilsData}));
    })
}

export const setFilter = (filter: string, value: number[] | string | number | moment.Moment |null) => (dispatch, getState) => {
    dispatch(affilStatsSlice.actions.setActiveFilterValue({name: filter, value}));
}

export const setAffiliateNameFromId = (affiliateId) => (dispatch, getState) => {
    const affiliateList = affiliateListSelector(getState());
    for(const affiliate of affiliateList.data){
        if(affiliate.affiliate_id === affiliateId){
            dispatch(setFilter('selectedAffiliateName',affiliate.name));
            return;
        }
    }
    dispatch(setFilter('selectedAffiliateName',null));
}

export const handleWidgetDetailClick = (widgetId) =>  (dispatch, getState) => {
    dispatch(push(`/widgets/detail/${widgetId}`));
}

export const loadAvailableTags = (affiliateId:number) => (dispatch, getState) => {
    API.loadWidgetsTags({
        affiliateId:affiliateId?affiliateId:undefined
    }).then(response => {
        dispatch(affilStatsSlice.actions.setFilterData({name:"tagsList", value: response.tags.map(tag => tag.name ) }))
    })
}

export const setSelectedTags = (tags:Array<string>) => (dispatch, getState) => {
    dispatch(affilStatsSlice.actions.setActiveFilterValue({name: "selectedTags", value: tags }))
}

export const resetState = () => (dispatch,getState) => {
    dispatch(affilStatsSlice.actions.resetState());
}

export const loadCategoryFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(affilStatsSlice.actions.setIsFetchingFilterData({name: 'category', value: true}));
    const categoryIds = categoryIdsSelector(getState());
  
    API.loadCategories({
        categoryIds: categoryIds?categoryIds:undefined,
        query:value,
        limit:100
    }).then(response => {
      dispatch(affilStatsSlice.actions.setFilterData({name: 'category', value: 
        response.categories.map( category => {
   
          let categoryName;
          if(category && category.translations && category.translations.length){
              const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
              if(categoryTranslation){
                  categoryName = categoryTranslation.name;
              }
          }
  
          return {
            name: category.parents_names ? [...category.parents_names].reverse().filter((item, index) => index % 2 === 0).join(' < ') : categoryName,
            value: category.category_id
          }
        })
      }));
      
      dispatch(affilStatsSlice.actions.setIsFetchingFilterData({name: 'category', value: false}));
    });
}

export const loadCategoryIds = () : AppThunk => async (dispatch, getState) => {
    const selectedAffiliateId = selectedAffiliateIdSelector(getState());

    const token = tokenSelector(getState());
    API.setToken(token);

    API.loadWidgetsCategories({
        affiliateId: selectedAffiliateId?selectedAffiliateId:undefined
    }).then(response => {
        const categoryIds = response.categories.map(category => {
            return category.category_id
        });

        dispatch(affilStatsSlice.actions.setCategoryIds(categoryIds));
    });
}

export const exportWidgetStats = (window,document) : AppThunk => async (dispatch, getState) => {
    const startDate = startDateActiveFiltersSelector(getState());
    const endDate = endDateActiveFiltersSelector(getState());
    const selectedAffiliateId = selectedAffiliateIdSelector(getState());
    const selectedTags = selectedTagsSelector(getState());
    const selectedCategoryIds = getValuesFromArray<number>(categoryActiveFiltersSelector(getState()));
    const userRole = userRoleSelector(getState());

    dispatch(affilStatsSlice.actions.setExportFething(true));

    const token = tokenSelector(getState());
    API.setToken(token);
    
    API.loadWidgetStats({
        startDate: startDate?startDate.format('YYYY-MM-DD'):undefined,
        endDate: endDate?endDate.format('YYYY-MM-DD'):undefined,
        affiliateId: selectedAffiliateId?selectedAffiliateId:undefined,
        tags: selectedTags?selectedTags.join("<,>"):undefined,
        categoryIds: selectedCategoryIds?selectedCategoryIds:undefined,
        limit: 100000
    }).then(response => {
        const widgetStats:WidgetStats =  {
            total: response.total,
            items: response.items.map(item => {
               let content = "";
               if(item.widget && (item.widget.type === 'product' || item.widget.type === 'modal_product' || item.widget.type === 'button_product')){
                   if(item.widget.widget_product && item.widget.widget_product.product){
                       content = item.widget.widget_product.product.name
                   }
               }
               
               const aggregation = item.widget.widget_product.product.aggregations?item.widget.widget_product.product.aggregations.find(aggregation => aggregation.country_id === 1):null;
               
               return {
                   _id: item._id,
                   clicks: item.clicks,
                   impressions: item.impressions,
                   payment: item.payment,
                   content: content,
                   type: item.widget.type,
                   ctr: item.impressions?((item.clicks/item.impressions)*100):0,
                   cpc: item.clicks?(item.payment/item.clicks):0,
                   affiliateName: item.widget && item.widget.affiliate && item.widget.affiliate.name?item.widget.affiliate.name:null,
                   categoryName: item.widget && item.widget.widget_product && item.widget.widget_product.product && item.widget.widget_product.product.category?item.widget.widget_product.product.category.name:"",
                   activeOffersCount: aggregation?aggregation.active_offers_count:0,
                   activeShopsCount: aggregation?aggregation.active_shops_count:0,
                   tags: item.widget && item.widget.tags && item.widget.tags.length?item.widget.tags:[]
               }
            })
        }

        const csv =
        `obsah,eshopy,ponuky,tagy,kategória,typ,impresie,prekliky,ctr,cpc,obrat${userRole=== 'ADMIN'?",publisher":""}\n` +
        widgetStats.items
        .map(function(item) {
            let type = "Widget";
            if(item.type === 'product'){ type = 'Product'}
            else if(item.type === 'modal_product'){ type = "Pop-up"}
            else if(item.type === 'button_product'){ type = "Single";
            }
            return `"${item.content}","${item.activeShopsCount}","${item.activeOffersCount}","${item.tags.join(",")}","${item.categoryName}","${type}","${item.impressions}","${item.clicks}","${item.ctr.toFixed(2)}","${item.cpc.toFixed(2)}","${item.payment.toFixed(2)}"${userRole === 'ADMIN'?',"'+item.affiliateName+'"':""}`;
        })
        .join('\n');

        const url = window.URL.createObjectURL(
            new Blob(["\ufeff",csv],{type:"text/plain;charset=UTF-8"}),
        );

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `export.csv`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();
        link.parentNode.removeChild(link);

        dispatch(affilStatsSlice.actions.setExportFething(false));
    })
}

export const setDefaultValues = (locationSearch:string,callback) : AppThunk => async (dispatch, getState) => {
 
    const query = new URLSearchParams(locationSearch);

    if(query.get('affiliate_id')){
        dispatch(setFilter('selectedAffiliateId',parseInt(query.get('affiliate_id'))));
        dispatch(setAffiliateNameFromId(parseInt(query.get('affiliate_id'))));
    }
    if(query.get('start_date')){
        dispatch(setFilter('startDate',moment(parseInt(query.get('start_date')))));
    }
    if(query.get('end_date')){
        dispatch(setFilter('endDate',moment(parseInt(query.get('end_date')))));
    }

    callback();
}

export const startDateActiveFiltersSelector = (state: ApplicationState) => state.affilStats.activeFilters.startDate;
export const endDateActiveFiltersSelector = (state: ApplicationState) => state.affilStats.activeFilters.endDate;

export const generalStatsSelector = (state: ApplicationState) => state.affilStats.generalStats.data;
export const generalStatsFethingSelector = (state: ApplicationState) => state.affilStats.generalStats.isFetching;

export const generalDailyStatsSelector = (state: ApplicationState) => state.affilStats.generalDailyStats.data;
export const generalDailyStatsFethingSelector = (state: ApplicationState) => state.affilStats.generalDailyStats.isFetching;

export const widgetStatsSelector = (state: ApplicationState) => state.affilStats.widgetStats.data;
export const widgetStatsFethingSelector = (state: ApplicationState) => state.affilStats.widgetStats.isFetching;

export const affilNameSelector = (state: ApplicationState) => state.affilStats.affilName;

export const tokenSelector = (state: ApplicationState) => state.general.token;
export const userRoleSelector  = (state: ApplicationState) => state.general.tokeninfo.role;

export const affiliateListSelector = (state: ApplicationState) => state.affilStats.filtersData.affiliateList;
export const selectedAffiliateIdSelector = (state: ApplicationState) => state.affilStats.activeFilters.selectedAffiliateId;
export const selectedAffiliateNameSelector = (state: ApplicationState) => state.affilStats.activeFilters.selectedAffiliateName;

export const tagsListSelector = (state: ApplicationState) => state.affilStats.filtersData.tagsList;
export const selectedTagsSelector = (state: ApplicationState) => state.affilStats.activeFilters.selectedTags;

export const categoryActiveFiltersSelector = (state: ApplicationState) => state.affilStats.activeFilters.categorySelected;
export const categoryFiltersDataSelector = (state: ApplicationState) => state.affilStats.filtersData.category.data;
export const categoryFiltersDataIsFetching = (state: ApplicationState) => state.affilStats.filtersData.category.isFetching;

export const categoryIdsSelector = (state: ApplicationState) => state.affilStats.categoryIds;
export const exportFethingSelector = (state: ApplicationState) => state.affilStats.exportIsFething;

export default affilStatsSlice.reducer;