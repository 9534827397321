import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState, AppThunk } from "../../store";
import API from "../../services/API";
import { arrayMove } from "react-sortable-hoc";
import { message } from "antd";
import { getRandomString } from "../../utilities";
import { landingPagesSelector, loadCategoryLandingPages } from "../CategoryLandingPages/slice";

const NAMESPACE = 'categoryParameters';

interface CategoryParameterTranslation {
    language_id: number,
    index: boolean,
    show: boolean,
    expand: boolean
}

export interface CategoryParameter {
    _id: string,
    parameter_id: string,
    index: number,
    position: number,
    translations: CategoryParameterTranslation[]
}

interface Category {
    _id: string,
    category_id: number,
    parent_id: number,
    parameters: CategoryParameter[]
}

interface Parameter {
    _id: string,
    internal_name: string
}
interface CategoryParametersState {
    activeFilters: {
        parameterSelected: string;
    };
    filtersData: {
        parameters: {
          isFetching: boolean;
          data: any;
        }
    };
    category: Category,
    locale_id: number,
    parameters: Parameter[]
}

const initialState : CategoryParametersState = {
    activeFilters: {
        parameterSelected: null
    },
    filtersData: {
        parameters: {
          isFetching: false,
          data: []
        }
    }, 
    category:{
       _id: null, 
       category_id: null,
       parent_id: null,
       parameters: []
    },
    parameters: [],
    locale_id: 1 
}

export const categoryParametersSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setCategory: (state, action: PayloadAction<Category>) => {
            state.category = action.payload
        },
        setLocaleId: (state, action: PayloadAction<number>) => {
            state.locale_id = action.payload
        },
        setParameters: (state, action: PayloadAction<Parameter[]>) => {
            state.parameters = action.payload
        },
        setParameterTranslatedValue: (state, action: PayloadAction<{_id:string,languageId:number,dataIndex:string,value:any}>) => {
            const categoryParameters = state.category.parameters;
            const categoryParameter = categoryParameters.find(p => p._id === action.payload._id);
            if(categoryParameter){
                const categoryParameterTranslation = categoryParameter.translations.find(cpt => cpt.language_id === action.payload.languageId);
                if(categoryParameterTranslation){
                    categoryParameterTranslation[action.payload.dataIndex] = action.payload.value
                }
            }
            state.category.parameters = categoryParameters
        },
        updateCategoryParameterPositions: (state, action: PayloadAction<{oldIndex:number,newIndex:number}>) => {
            let categoryParameters = state.category.parameters;
            const newData = arrayMove([].concat(categoryParameters), action.payload.oldIndex, action.payload.newIndex).filter(el => !!el);
            categoryParameters = categoryParameters.map(categoryParameter => {
                return {...categoryParameter, position:categoryParameter.index+1}
            })
            state.category.parameters = newData;
        },
        setCorrectCategoryParametersPositions: (state) => {
            const categoryParameters = state.category.parameters;
            let position = 1;
            for(const value of categoryParameters){
                value.position = position;
                position++
            }
            state.category.parameters = categoryParameters;
        },
        removeCategoryParameter: (state, action: PayloadAction<{_id:string}>) => {
            state.category.parameters = state.category.parameters.filter(p => p._id !== action.payload._id)   
        },
        setIsFetchingFilterData: (state,action: PayloadAction<{ name: string; value: boolean }>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },
        setFilterData: (state,action: PayloadAction<{ name: string; value: any }>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
        createCategoryParameter: (state,action: PayloadAction<{categoryParameter:CategoryParameter}>) => {
            const categoryParameters = state.category.parameters;
            categoryParameters.push(action.payload.categoryParameter);
            state.category.parameters = categoryParameters
        },
        updateCategoryParameter: (state, action: PayloadAction<{_id:string,parameter_id?:string}>) => {
            const categoryParameters = state.category.parameters;
            const currentCategoryParameter = categoryParameters.find(pp => pp._id === action.payload._id);
            if(currentCategoryParameter){
              if(action.payload.parameter_id !== undefined) currentCategoryParameter.parameter_id = action.payload.parameter_id;
            }
            state.category.parameters = categoryParameters
        },
    }
});

export const loadCategory = (categoryId:number) : AppThunk => async (dispatch, getState) => {
    API.loadCategories({categoryIds:[categoryId]}).then(response => {
        const category = response.categories.length?response.categories[0]:null;
        if(category){
            
            let index = 0;

            const rCategory:Category = {
                _id: category._id,
                category_id: category.category_id,
                parent_id: category.parent_id,
                parameters: category.parameters?category.parameters.map(parameter => {
                    return {
                        _id: parameter._id,
                        parameter_id: parameter.parameter_id,
                        position: parameter.position,
                        index: index++,
                        translations: parameter.translations.map(translation => {
                            return {
                                language_id: translation.language_id,
                                show: translation.show,
                                index : translation.index,
                                expand: translation.expand
                            }
                        })
                    }
                }):[]
            }
            dispatch(categoryParametersSlice.actions.setCategory(rCategory))
            dispatch(loadParameters());
        }
    })
}

export const loadParameters = () : AppThunk => async (dispatch, getState) => {
    const categoryParameters = categoryParametersSelector(getState());

    API.loadParameters({filterParameterIds:categoryParameters.map(cp => cp.parameter_id)}).then((response => {
        const parameters:Parameter[] = response.items.map(item => {
            return {
                _id: item._id,
                internal_name: item.internal_name
            }
        })
        dispatch(categoryParametersSlice.actions.setParameters(parameters))
    }))
}

export const setLocaleId = (localeId:number): AppThunk => async (dispatch, getState) => {
    dispatch(categoryParametersSlice.actions.setLocaleId(localeId));
}

export const createLocaleCategoryParameters = (categoryParameters:CategoryParameter[]) => {
    
}

export const setParameterTranslatedValue = (_id:string,dataIndex, value) : AppThunk => async (dispatch, getState) => {
    const languageId = localeIdSelector(getState());
    dispatch(categoryParametersSlice.actions.setParameterTranslatedValue({_id,dataIndex,languageId,value}))
}

export const updateCategoryParameterPositions =  (oldIndex:number,newIndex:number) : AppThunk => async (dispatch, getState) => {
    dispatch(categoryParametersSlice.actions.setCorrectCategoryParametersPositions());
    dispatch(categoryParametersSlice.actions.updateCategoryParameterPositions({oldIndex,newIndex}));
}

export const removeCategoryParameter = (_id:string) => async(dispatch, getState) => {
    dispatch(categoryParametersSlice.actions.removeCategoryParameter({_id}));
}

export const loadSearchParameters = (text:string) => async(dispatch, getState) => {
    dispatch(categoryParametersSlice.actions.setIsFetchingFilterData({name: 'parameters', value: true}));
    API.loadParameters({filterInternalName: text,limit:100}).then(response => {
      dispatch(categoryParametersSlice.actions.setFilterData({name: 'parameters', value: 
        response.items.map(parameter => {
            return {
              name: parameter.internal_name,
              value: parameter._id
            } 
        })  
      }));
  
      dispatch(categoryParametersSlice.actions.setIsFetchingFilterData({name: 'parameters', value: false}));   
    });
}

export const updateCategoryParameter = (_id:string,parameter_id:string) => async(dispatch, getState) => {
    dispatch(categoryParametersSlice.actions.updateCategoryParameter({_id,parameter_id}));   
    dispatch(loadParameters());
}

export const createCategoryParameter = (data:CategoryParameter) => async(dispatch, getState) => {
    dispatch(categoryParametersSlice.actions.createCategoryParameter({categoryParameter:data}));   
}

export const inheritFromParent = () => async(dispatch, getState) => {
    const category = categorySelector(getState());
    const categoryParameters = categoryParametersSelector(getState());

    if(category.parent_id){
        API.loadCategories({categoryIds:[category.parent_id]}).then(response => {
            if(response.categories.length){
                const responseCategory = response.categories[0];

                let maxPosition = 1;
                for(const cp of categoryParameters){
                    if(cp.position > maxPosition) maxPosition = cp.position;
                }

                const categoryParametersIds = categoryParameters.map(cp => cp.parameter_id);

                const newCategoryParameters:CategoryParameter[] = responseCategory.parameters.map(parameter => {
                    return {
                        _id: "NEW_"+getRandomString(10),
                        parameter_id: parameter.parameter_id,
                        position: parameter.position,
                        index: parameter.position-1,
                        translations: parameter.translations.map(translation => {
                            return {
                                language_id: translation.language_id,
                                show: translation.show,
                                index : translation.index,
                                expand: translation.expand
                            }
                        })
                    }
                }).filter(ncp => !categoryParametersIds.includes(ncp.parameter_id))
                
                for(const ncp of newCategoryParameters){
                    dispatch(categoryParametersSlice.actions.createCategoryParameter({categoryParameter:ncp}));   
                }
                dispatch(loadParameters());
            }
        })
    }
}

export const addToChildren = () => async(dispatch, getState) => {
    const category = categorySelector(getState());
    const categoryParameters = categoryParametersSelector(getState());

    API.loadCategoriesTree({parentId:category.category_id,maxDepth:0}).then(response => {
        for(const responseCategory of response.categories){
            const responseParameters:CategoryParameter[] = responseCategory.parameters?responseCategory.parameters.map(parameter => {
                return {
                    _id: parameter._id,
                    parameter_id: parameter.parameter_id,
                    position: parameter.position,
                    index: parameter.position-1,
                    translations: parameter.translations.map(translation => {
                        return {
                            language_id: translation.language_id,
                            show: translation.show,
                            index : translation.index,
                            expand: translation.expand
                        }
                    })
                }
            }):[];

            
            for(const categoryParameter of categoryParameters){
                const responseParameter = responseParameters.filter(rp => rp.parameter_id ===  categoryParameter.parameter_id);
                if(!responseParameter.length){
                    responseParameters.push({...categoryParameter,_id:"NEW_"+getRandomString(10)})
                }
            }

            API.updateCategoryParameters(responseCategory._id,{},{
                parameters: responseParameters.map(parameter => {
                    return {
                        _id: parameter._id,
                        parameter_id: parameter.parameter_id,
                        position: parameter.position,
                        translations: parameter.translations.map(translation => {
                            return {
                                language_id: translation.language_id,
                                show: translation.show, 
                                index: translation.index,
                                expand: translation.expand
                            }
                        })
                    }
                })
            }).then(response => {
            })
        }

        message.success(`Parametre boli predané`);
    });
}

export const updateParameters = () => async(dispatch, getState) => {
    const category = categorySelector(getState());
    
    API.updateCategoryParameters(category._id,{},{
        parameters: category.parameters.map(parameter => {
            return {
                _id: parameter._id,
                parameter_id: parameter.parameter_id,
                position: parameter.position,
                translations: parameter.translations.map(translation => {
                    return {
                        language_id: translation.language_id,
                        show: translation.show, 
                        index: translation.index,
                        expand: translation.expand
                    }
                })
            }
        })
    }).then(response => {
        message.success(`Parametre boli uložené`);
    })
}

export const updateLandingPages = () => async(dispatch, getState) => {
    const category = categorySelector(getState());
    const landingPages = landingPagesSelector(getState());

    API.updateCategoryLandingPages(category._id,{},{
       landing_pages: landingPages.map(landingPage => {
           return {
               _id: landingPage._id,
               categories: landingPage.categories.map(category =>{
                   return category.category_id
               }),
               manufacturers: landingPage.manufacturers.map(manufacturer => {
                   return manufacturer.manufacturer_id
               }),
               parameters: landingPage.parameters.map(parameter => {
                    return {
                       parameter_id: parameter.parameter_id,
                       parameter_value_id: parameter.parameter_value_id
                    }
               }),
               sort: {
                   type: landingPage.sort.type,
                   order: "desc"
               },
               price_from: landingPage.price_from,
               price_to: landingPage.price_to,
               translations: landingPage.translations.map(translation => {
                    return {
                       active: translation.active,
                       title: translation.title,
                       header: translation.header,
                       short_description: translation.short_description,
                       description: translation.description,
                       language_id: translation.language_id
                    }
               })
           }
       })
    }).then(response => {
        message.success(`Landing pages boli uložené`);
        dispatch(loadCategoryLandingPages(category.category_id));
    })
}


export const categoryParametersSelector = (state: ApplicationState) => state.categoryParameters.category.parameters;
export const localeIdSelector = (state: ApplicationState) => state.categoryParameters.locale_id;
export const parametersSelector = (state: ApplicationState) => state.categoryParameters.parameters;
export const categorySelector = (state: ApplicationState) => state.categoryParameters.category;

export const parametersSearchSelector = (state: ApplicationState) => state.categoryParameters.filtersData.parameters.data

export default categoryParametersSlice.reducer;







