import { rem } from 'polished';
import React, { useState } from 'react';
import API from '../../services/API';
import ConnectedSingleSelect from './ConnectedSingleSelect';

const CategorySelect = props => {
    const { onSelect, width, onlyLast, disabled } = props;
  
    const [selectedValue, setSelectedValue] = useState(null);
    const [data, setData] = useState([]);
  
    const onChange = query => {
      API.loadCategories({
        query: query,
        limit: 10,
        isLast: onlyLast
      }).then(response => {
        const data = response.categories.map(category => {
            let categoryName;
            if(category && category.translations && category.translations.length){
                const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
                if(categoryTranslation){
                    categoryName = categoryTranslation.name;
                }
            }

            return {
               name: category.parents_names ? [...category.parents_names].reverse().filter((item, index) => index % 2 === 0).join(' < ') : categoryName,
               value: category.category_id
            };
        });
        setData(data);
      });
    };
  
    const onInternalSelect = selectedValue => {
      setSelectedValue(selectedValue);
      if (onSelect) onSelect(selectedValue);
    };
  
    return (
      <ConnectedSingleSelect
        value={selectedValue}
        data={data}
        selectWidth={width ? rem(width) : rem(200)}
        onChange={onChange}
        onSelect={onInternalSelect}
        disabled={disabled}
      />
    );
  };

  export default CategorySelect;