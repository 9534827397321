import React from 'react';
import {Button, Checkbox, Col, Input, Row, Select, Spin, Table, Tooltip} from 'antd';

import {CloseCircleFilled} from '@ant-design/icons';
import {__} from '../../utilities';
import {useDispatch, useSelector} from 'react-redux';
import {
  filterResultsIsFetchingSelector,
  filterResultsItemsSelector,
  filterResultsTotalSelector,
  loadItems,
  loadShopFilterData,
  mappingActionsSelector,
  paginationSelector,
  setFilter,
  setPagination,
  setSendingMappingAction,
  setSorter,
  shopActiveFiltersSelector,
  shopFiltersDataIsFetching,
  shopFiltersDataSelector,
  updateRecord,
  mappingActionsisSendingSelector, rejectMappingAction, setPageSize
} from './slice';
import {rem} from 'polished';
import {ApplicationState} from '../../store';
import H1 from '../../components/Headings/H1';
import MappingCategorySelect from "./MappingCategorySelect";
import API from "../../services/API";

const {Option} = Select;
// const {Text, Title} = Typography;
// const dateFormat = FORMAT_HUMANE_DATE;

const selectWidth = rem(175);

const MappingCategories = () => {
    function onCancelAllFilters() {
        dispatch(setFilter('categorySelected', null));
        dispatch(setFilter('categorySelected', null));
        dispatch(setFilter('since', null));
        dispatch(setFilter('categorySelected', null));
        dispatch(setFilter('query', null));
        dispatch(setFilter('onlyNotMapped', false));
        dispatch(setFilter('showRejected', false));
        dispatch(loadItems());
    }

    const dispatch = useDispatch();

    // Nahrada componentDidMount
    React.useEffect(() => {
        async function init() {
            // TODO IMPLEMENT
            dispatch(loadItems());
        }

        init();
    }, [dispatch]);
    
    /*
    const onSelectCategoryFilter = async value => {
        dispatch(setFilter('categorySelected', value));
        dispatch(loadItems());
    };
    */
    
    /*
    const onChangeSinceFilter = async value => {
        dispatch(setFilter('since', value));
        dispatch(loadItems());
    };
    */

    // const onChangeChangeDateFilter = async value => {
    //   dispatch(setFilter('changeDate', value));
    //   dispatch(loadProducts());
    // };

    const onSearchQuery = async value => {
        dispatch(setFilter('query', value));
        dispatch(loadItems());
    };

    const onChangeTable = async (pagination, filters, sorters) => {
        dispatch(setPageSize(pagination.pageSize));
        dispatch(setPagination(pagination.current));
        dispatch(setSorter(sorters.field, sorters.order));
        dispatch(loadItems());
    };

    const items = useSelector((state: ApplicationState) =>
        filterResultsItemsSelector(state)
    );

    const pagination = useSelector((state: ApplicationState) =>
        paginationSelector(state)
    );

    const total = useSelector((state: ApplicationState) =>
        filterResultsTotalSelector(state)
    );

    const isFetchingProducts = useSelector((state: ApplicationState) =>
        filterResultsIsFetchingSelector(state)
    );

    const isSendingActions = useSelector((state: ApplicationState) =>
        mappingActionsisSendingSelector(state)
    );

    const onChangeShopFilter = async value => {
        await dispatch(loadShopFilterData(value));
    };

    const isFetchingShopData = useSelector((state: ApplicationState) =>
        shopFiltersDataIsFetching(state)
    );

    const shopActiveFilters = useSelector((state: ApplicationState) =>
        shopActiveFiltersSelector(state)
    );

    const shopData = useSelector((state: ApplicationState) =>
        shopFiltersDataSelector(state)
    );

    const onSelectShopFilter = async value => {
        if (value) {
            dispatch(setFilter('shopSelected', [value]));
        } else {
            dispatch(setFilter('shopSelected', value));
        }
        dispatch(loadItems());
    };

    const rejectMapping = (shop_category, shop_id) => {
      const r = window.confirm('Naozaj chcete mapovanie zamietnuť?');
      if (r === true) {
        dispatch(rejectMappingAction(shop_id, shop_category));
      }
    };

    const columns = [
        {
            title: __('Názov kategórie v obchode'),
            dataIndex: 'shop_category',
        },
        {
            title: __('Názov kategórie v Pricemanii'),
            dataIndex: 'category',
            render: (category, obj) => (
                <MappingCategorySelect shop_category={obj.shop_category} category={category} category_mapping_id={obj.category_mapping_id} shop_id={obj.shop_id}>

                </MappingCategorySelect>
            ),
        },
        {
            title: __('Kopírovať'),
            dataIndex: 'name',
            render: name => (
                <Button
                    type="primary"
                    size={'small'}
                >{__('Kopírovať')}</Button>
            ),
        },
      {
        title: __('Zamietnuť'),
        dataIndex: 'name',
        render: (category, obj) => (
          <Button
            type="primary"
            size={'small'}
            onClick={() => {rejectMapping(obj.shop_category, obj.shop_id)}}
          >{__('Zamietnuť')}</Button>
        ),
      }
    ];

    const mappingActions = useSelector((state: ApplicationState) =>
        mappingActionsSelector(state)
    );

    const onClickSave = async e => {
        dispatch(setSendingMappingAction(true));

        // prejdeme akcie a vykoname ich
        for (let mappingAction of mappingActions) {
            switch (mappingAction.type) {
                case 'INSERT':
                    const response = await API.createCategoryMapping({}, {category_id: mappingAction.category_id, shop_id: mappingAction.shop_id, shop_category: mappingAction.shop_category});
                    const new_data = {
                        category_mapping_id: response.category_mapping_id,
                        category: {
                            category_id: mappingAction.category_id
                        }
                    };
                    dispatch(updateRecord(mappingAction.shop_category, new_data));
                    break;
                case 'UPDATE':
                    if (mappingAction.category_mapping_id) {
                        await API.updateCategoryMapping(mappingAction.category_mapping_id, {}, {category_id: mappingAction.category_id, shop_id: mappingAction.shop_id, shop_category: mappingAction.shop_category});
                    }
                    break;
                case 'DELETE':
                    if (mappingAction.category_mapping_id) {
                        await API.deleteCategoryMapping(mappingAction.category_mapping_id, {});
                        const new_data = {
                            category_mapping_id: null,
                            category: null
                        };
                        dispatch(updateRecord(mappingAction.shop_category, new_data));
                    }
                    break;
            }
        }

        dispatch(setSendingMappingAction(false));
    };

    const onChangeOnlyNotMapped = value => {
      dispatch(setFilter('onlyNotMapped', value.target.checked));
      dispatch(loadItems());
    };

    const onChangeShowRejected = value => {
      dispatch(setFilter('showRejected', value.target.checked));
      dispatch(loadItems());
    };

    return (
        <>
            <H1>{__('Mapovanie kategórií')}</H1>
            <Row>
                <Col className="gutter-row" span={24}>
                    <Row gutter={{lg: 10}}>
                        <Col>
                            <Select
                                showSearch
                                value={shopActiveFilters}
                                labelInValue
                                placeholder={__('Shop')}
                                notFoundContent={isFetchingShopData ? <Spin size="small" /> : null}
                                filterOption={false}
                                onChange={onSelectShopFilter}
                                onSearch={onChangeShopFilter}
                                style={{ width: 200 }}
                            >
                                {shopData.map(d => (
                                    <Option value={d.value} key={d.name}>
                                        {d.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col>
                            <Input.Search
                                style={{
                                    width: selectWidth + rem(50),
                                }}
                                placeholder={__('Vyhľadať')}
                                onSearch={onSearchQuery}
                                enterButton
                            />
                        </Col>
                      <Col>
                        <Checkbox onChange={onChangeOnlyNotMapped}>Iba nenamapované</Checkbox>
                      </Col>
                      <Col>
                        <Checkbox onChange={onChangeShowRejected}>Zobraziť aj zamietnuté</Checkbox>
                      </Col>
                      <Col>
                            <Tooltip title={__('Zrušiť filtre')}>
                                <CloseCircleFilled
                                    onClick={() => {
                                        onCancelAllFilters();
                                    }}
                                    style={{fontSize: '32px'}}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>

                <Col className="gutter-row" span={24}>
                    &nbsp;
                    {/*<Title level={4}>*/}
                    {/*  {__('Počet záznamov: ')}*/}
                    {/*  {total < pagination.pageSize ? total : pagination.pageSize} z{' '}*/}
                    {/*  {total}*/}
                    {/*</Title>*/}
                </Col>

                <Col className="gutter-row" span={24}>
                    {/* TODO Doplnit ROW SELECTION cez REDUX */}
                    <Table
                        columns={columns}
                        pagination={{
                            total: total,
                            current: pagination.current,
                            pageSize: pagination.pageSize,
                        }}
                        loading={isFetchingProducts || isSendingActions}
                        dataSource={items}
                        onChange={(pagination, filters, sorters) => {
                            onChangeTable(pagination, filters, sorters);
                        }}
                    />
                </Col>

                <Col className="gutter-row" span={24}>
                    <div style={{float: 'right'}}>
                        <Button type="primary" onClick={onClickSave}>
                            {__('Uložiť')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default MappingCategories;