import {Select} from "antd";
import React from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {setFilter, widgetTypeActiveFilterSelector} from "./slice";

const {Option} = Select;



const MainBoxContent = styled.div``

const WidgetTypeSelector = () => {

  const dispatch = useDispatch();

  const widgetTypeSelected = useSelector((state: ApplicationState) =>
    widgetTypeActiveFilterSelector(state)
  );

  const handleWidgetTypeChange = (value) => {
    dispatch(setFilter('widgetTypeSelected', value));
  }

  return (
        <MainBoxContent>
          <Select defaultValue="widget_type" value={widgetTypeSelected} style={{width: 300}}
                  onChange={handleWidgetTypeChange}>
            <Option value="widget_type">Typ widgetu</Option>
            <Option value="products">Produkty</Option>
            <Option value="modal_products">Produkty (pop-up)</Option>
            <Option value="button_products">Single</Option>
            <Option value="goods" disabled={true}>Ponuky (pripravujeme)</Option>
            <Option value="graph" disabled={true}>Vývoj ceny produktu (pripravujeme)</Option>
          </Select>
        </MainBoxContent>

  );
}

export default WidgetTypeSelector;