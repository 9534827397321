
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, ApplicationState } from "../../store";
import API from "../../services/API";
import { getRandomArbitrary, replaceAll } from "../../utilities";
import { tokenSelector } from "../App/slice";

const NAMESPACE = 'productCreate';

export enum WordReplacementState {
    ORIGINAL = "ORIGINAL",
    NEW = 'NEW',
    DELETED = 'DELETED',
    MODIFIED = 'MODIFIED'
}

export enum WordReplacementType {
    PHRASE = 'PHRASE',
    SINGLE_WORD = 'SINGLE-WORD',
    UNKNOWN = 'UNKNOWN'
}

export interface WordReplacement {
    key: number,
    replacementId: number;
    originalWord: string;
    replacementWord: string;
    manufacturerId: number;
    withManufacturer: boolean;
    state: WordReplacementState | null;
    type: WordReplacementType
}

export interface MainCategory {
    name: string,
    value: number,
    optionalParams: Array<{
        id: string;
        name: string;
    }>;
}

export interface MainProduct {
    categoryId: number,
    manufacturerId: number,
    name: string,
    image: string,
    price: number,
    description: string;
    feed_id: number
}

export interface CatalogProduct {
    key: number,
    name: string,
    imageUrl: string,
    shortDescription: string,
    categoryName: string
}

export interface ProductCreateEntity {
    createdAt: string;
    shopName: string;
    shopId: number;
    parsedProductCount: number;
    allErrorsCount: number;
    errors: Array<string>;
    key: string,
    isVisible: boolean
}

export interface ProductSuggestion {
    feed_id: number 
}

export interface ProductSuggestionNew {
    key: number,
    feed_id: number,
    name: string,
    price: number,
    internalId: string,
    partNumber: string,
    ean: string,
    shopName: string,
    matchTypes: Array<string>,
    catalogName: string,
    color: string,
    groupId: string,
    isVisible: boolean,
    url: string,
    imageUrl: string,
    shortDescription: string;
    countryId: number;
    medianPrice: number;
}

export interface ProductSuggestionGroupEntity {
    product: ProductSuggestion,
    matchTypes: Array<string>
}

export interface ProductSuggestionGroup {
    id: string,
    name: string,
    productEntities: Array<ProductSuggestionGroupEntity>
}

export interface ProductSuggestions {
    groups: Array<ProductSuggestionGroup>,
    unassignedProducts: Array<ProductSuggestion>
}

export interface Mask {

}

export interface ProductNamePartsInterface {
    model_name_prefix: ProductNamePartInterface,
    model_name: ProductNamePartInterface,
    model_name_suffix: ProductNamePartInterface,
    optional1_param: ProductNamePartInterface,
    optional2_param: ProductNamePartInterface,
    optional3_param: ProductNamePartInterface,
    optional4_param: ProductNamePartInterface,
    optional5_param: ProductNamePartInterface,
    optional6_param: ProductNamePartInterface
}

export interface ProductNamePartsSeparatorsInterface {
    optional1_2_sepator: string;
    optional2_3_sepator: string;
    optional3_4_sepator: string;
    optional4_5_sepator: string;
    optional5_6_sepator: string;
}
export interface ProductNamePartInterface {
    masks?: Array<Mask>,
    value?: string,
    selectedMaskRegex?: string;
    selectedMaskHumanRegex?: string;
}

export interface ProductPreviewModalData {
    name: string;
    imageUrl: string;
    shortDescription: string;
    categoryName: string;
}

export interface ForcedPmProducts {
    feed_id: number,
    name: string,
    shopName: string
}

const rowColors = ['#edb879','#e66d28','#c6e3de','#66aec4','#a8a7a2','#4b97de','#d365db','#db6565','#ebd581','#eb81b4','#8fed66','#fa5757','#57fa9b'];

interface ProductCreateState {
    activeFilters: {
        shopSelected: number,
        manufacturerSelected: number,
        categorySelected: number,
        mainManufacturerSelected: { name: string, value: number },
        mainCategorySelected: MainCategory,
        partNumber: string,
        ean: string,
        filter: string,
        changeManufacturerSelected: number,
        changeCategorySelected: number
    },
    filtersData: {
        shop: {
            isFetching: boolean,
            data: any
        },
        manufacturer: {
            isFetching: boolean,
            fullData: any,
            data: any
        },
        category: {
            isFetching: boolean,
            fullData: any,
            data: any
        },
        mainManufacturer: {
            isFetching: boolean,
            data: any
        },
        mainCategory: {
            isFetching: boolean,
            data: any
        },
        changeManufacturer: {
            isFetching: boolean,
            data: any
        },
        changeCategory: {
            isFetching: boolean,
            data: any
        }
    },
    filterResults: {
        offset: number
        isFetching: boolean,
        total: number;
        feedReports: ProductCreateEntity[];
    },
    modals: {
        wordPhraseReplacement: {
            isVisible: boolean,
        },
        wordSingleReplacement: {
            isVisible: boolean,
        },
        maskSettings: {
            isVisible: boolean,
            text: string;
            inputName: string;
            masks: string[]
        },
        changeCategory: {
            isVisible: boolean,
        },
        changeManufacturer: {
            isVisible: boolean,
        },
        productPreview : {
            isVisible: boolean,
            productData: ProductPreviewModalData
        },
        skip: {
            isVisible: boolean,
        },
        skipSuggestions: {
            isVisible: boolean,
        }
    },
    productNameParts: ProductNamePartsInterface,
    productNamePartsSeparators: ProductNamePartsSeparatorsInterface,
    mainProduct: MainProduct,
    catalogProducts: {
       items: Array<CatalogProduct>,
       averagePrice: number
    },  
    wordReplacements: {
        isFetching: boolean,
        isDisabled: boolean,
        data: Array<WordReplacement>
    },
    phraseReplacements: {
        isFetching: boolean,
        isDisabled: boolean,
        originalWorld: string,
        inputName: string,
        data: Array<WordReplacement>
    },
    productSuggestions: {
        filteredData: Array<ProductSuggestionNew>,
        data: Array<ProductSuggestionNew>,
        isFetching: boolean,
    }
    selectedSuggestions: Array<number>,
    removedSelectedSuggestions: Array<number>,  //number = feed_id
    secondarySelectedSuggestions: Array<number>,
    createButton: {
        isDisabled: boolean,
    },
    mainProductOffset: number,
    forcedPmProducts: ForcedPmProducts[],
    forceAssignedProducts: number[]
}

const initialProductNameParts:ProductNamePartsInterface = {
    model_name_prefix: { value:"", masks:[], selectedMaskRegex:null, selectedMaskHumanRegex:null},
    model_name: { value:"",masks:[], selectedMaskRegex:null, selectedMaskHumanRegex:null},
    model_name_suffix: { value:"",masks:[], selectedMaskRegex:null, selectedMaskHumanRegex:null},
    optional1_param: { value:"",masks:[], selectedMaskRegex:null, selectedMaskHumanRegex:null},
    optional2_param: { value:"",masks:[], selectedMaskRegex:null, selectedMaskHumanRegex:null},
    optional3_param: { value:"",masks:[], selectedMaskRegex:null, selectedMaskHumanRegex:null},
    optional4_param: { value:"",masks:[], selectedMaskRegex:null, selectedMaskHumanRegex:null},
    optional5_param: { value:"",masks:[], selectedMaskRegex:null, selectedMaskHumanRegex:null},
    optional6_param: { value:"",masks:[], selectedMaskRegex:null, selectedMaskHumanRegex:null},
}

const initialProductNamePartsSeparators:ProductNamePartsSeparatorsInterface = {
    optional1_2_sepator: " ",
    optional2_3_sepator: " ",
    optional3_4_sepator: " ",
    optional4_5_sepator: " ",
    optional5_6_sepator: " "
}

const initialState: ProductCreateState = {
    activeFilters: {
        shopSelected: null,
        manufacturerSelected: null,
        categorySelected: null,
        mainManufacturerSelected: null,
        mainCategorySelected: null,
        partNumber: null,
        ean: null,
        filter: "",
        changeManufacturerSelected: null,
        changeCategorySelected: null
    },
    filtersData: {
        shop: {
            isFetching: false,
            data: []
        },
        manufacturer: {
            isFetching: false,
            fullData: [],
            data: []
        },
        category: {
            isFetching: false,
            fullData: [],
            data: []
        },
        mainManufacturer: {
            isFetching: false,
            data: []
        },
        mainCategory: {
            isFetching: false,
            data: []
        },
        changeManufacturer: {
            isFetching: false,
            data: []
        },
        changeCategory: {
            isFetching: false,
            data: []
        }
    },
    filterResults: {
        isFetching: false,
        total: 0,
        offset: 0,
        feedReports: []
    },
    modals: {
        wordPhraseReplacement: {
            isVisible: false,
        },
        wordSingleReplacement: {
            isVisible: false
        },
        maskSettings: {
            isVisible: false,
            inputName: null,
            text: null,
            masks: []
        },
        changeCategory: {
            isVisible: false
        },
        changeManufacturer: {
            isVisible: false
        },
        productPreview: {
            isVisible: false,
            productData: null
        },
        skip :{
            isVisible: false,
        },
        skipSuggestions: {
            isVisible: false,
        }
    },
    productNameParts: initialProductNameParts, 
    productNamePartsSeparators: initialProductNamePartsSeparators,
    mainProduct: null,
    catalogProducts: null,
    wordReplacements: {
        isFetching: false,
        isDisabled: true,
        data: [
            { key: 1, replacementId: 1, type: WordReplacementType.SINGLE_WORD, state: WordReplacementState.ORIGINAL, replacementWord: "Eagle F0 Asymmetric", originalWord: "EA F0 Assymetric", manufacturerId: 20, withManufacturer: true },
            { key: 2, replacementId: 2, type: WordReplacementType.SINGLE_WORD, state: WordReplacementState.ORIGINAL, replacementWord: "Sklápačka", originalWord: "Vyklapacka", manufacturerId: 20, withManufacturer: true },
            { key: 3, replacementId: 3, type: WordReplacementType.SINGLE_WORD, state: WordReplacementState.ORIGINAL, replacementWord: "Vector 0 Seasons", originalWord: "Vector 0Season", manufacturerId: null, withManufacturer: false },
            { key: 4, replacementId: 4, type: WordReplacementType.SINGLE_WORD, state: WordReplacementState.ORIGINAL, replacementWord: "Technic Štvorkolka", originalWord: "technic Ctyrkolka", manufacturerId: 20, withManufacturer: true },
            { key: 5, replacementId: 5, type: WordReplacementType.SINGLE_WORD, state: WordReplacementState.ORIGINAL, replacementWord: "Winter Response", originalWord: "WINT RESPONSE", manufacturerId: 20, withManufacturer: true }
        ]
    },
    phraseReplacements: {
        isFetching: false,
        isDisabled: true,
        originalWorld: null,
        inputName: null,
        data: []
    },
    /*
    productSuggestions:{
        groups : [],
        unassignedProducts: []
    }
    */
    productSuggestions: {
        filteredData: [],
        data: [],
        isFetching: false
    },
    selectedSuggestions: [],
    removedSelectedSuggestions: [],
    secondarySelectedSuggestions: [],
    createButton: {
        isDisabled: false,
    },
    mainProductOffset: 0,
    forcedPmProducts: null,
    forceAssignedProducts: []
}

export const productCreateSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setIsFetchingFilterResults: (state, action: PayloadAction<{ value: boolean }>) => {
            state.filterResults.isFetching = action.payload.value
        },
        setFilterResults: (state, action: PayloadAction<{ total: number, feedReports: Array<ProductCreateEntity> }>) => {
            state.filterResults.total = action.payload.total;
            state.filterResults.feedReports = action.payload.feedReports;
        },
        setIsFetchingFilterData: (state, action: PayloadAction<{ name: string, value: boolean }>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },
        setFilterData: (state, action: PayloadAction<{ name: string, value: any }>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
        setFilterFullData: (state, action: PayloadAction<{ name: string, value: any }>) => {
            state.filtersData[action.payload.name].fullData = action.payload.value;
        },
        setActiveFilterValue: (state, action: PayloadAction<{ name: string, value: number[] | string | number | null | any }>) => {
            state.activeFilters[action.payload.name] = action.payload.value;
        },
        setMainProduct: (state, action: PayloadAction<MainProduct>) => {
            state.mainProduct = action.payload;
        },
        setCatalogProducts: (state, action: PayloadAction<{averagePrice: number, items: Array<CatalogProduct>}>) => {
            state.catalogProducts = action.payload;
        },
        setWordReplacements: (state, action: PayloadAction<Array<WordReplacement>>) => {
            state.wordReplacements.data = action.payload;
        },
        setModalVisibility: (state, action: PayloadAction<{ name: string, visible: boolean }>) => {
            state.modals[action.payload.name].isVisible = action.payload.visible;
        },
        setMainCategory: (state, action: PayloadAction<MainCategory>) => {
            state.activeFilters.mainCategorySelected = action.payload;
        },
        setSingleWordButtonState: (state, action: PayloadAction<boolean>) => {
            state.wordReplacements.isDisabled = action.payload;
        },
        setWordReplacementFetching: (state, action: PayloadAction<boolean>) => {
            state.wordReplacements.isFetching = action.payload;
        },
        setMaskSettingsModalInput: (state, action: PayloadAction<{text:string, inputName: string}>) => {
            state.modals.maskSettings.text = action.payload.text;
            state.modals.maskSettings.inputName = action.payload.inputName;
        },
        setProductSuggestions: (state, action: PayloadAction<Array<ProductSuggestionNew>>) => {
            state.productSuggestions.data = action.payload;
        },
        setFilteredProductSuggestions: (state, action: PayloadAction<Array<ProductSuggestionNew>>) => {
            state.productSuggestions.filteredData = action.payload;
        },
        setProductSuggestionsFething: (state, action: PayloadAction<boolean>) => {
            state.productSuggestions.isFetching = action.payload;
        },
        setSelectedSuggestions: (state, action: PayloadAction<Array<number>>) => {
            state.selectedSuggestions = action.payload;
        },
        setRemovedSelectedSuggestions: (state, action: PayloadAction<Array<number>>) => {
            state.removedSelectedSuggestions = action.payload;
        },
        setSecondarySelectedSuggestions : (state, action: PayloadAction<Array<number>>) => {
            state.secondarySelectedSuggestions = action.payload;
        },
        setProductNameParts: (state, action: PayloadAction<ProductNamePartsInterface>) => {
            state.productNameParts = action.payload;
        },
        setProductNamePart: (state, action: PayloadAction<{name:string,value:ProductNamePartInterface}>) => {
            state.productNameParts[action.payload.name].value                  =  action.payload.value.value !== undefined?action.payload.value.value:state.productNameParts[action.payload.name].value;
            state.productNameParts[action.payload.name].selectedMaskRegex      =  action.payload.value.selectedMaskRegex !== undefined?action.payload.value.selectedMaskRegex:state.productNameParts[action.payload.name].selectedMaskRegex;
            state.productNameParts[action.payload.name].selectedMaskHumanRegex = action.payload.value.selectedMaskRegex !== undefined?action.payload.value.selectedMaskHumanRegex:state.productNameParts[action.payload.name].selectedMaskHumanRegex;
            state.productNameParts[action.payload.name].masks                  =  action.payload.value.masks !== undefined?action.payload.value.masks:state.productNameParts[action.payload.name].masks;
        },
        setProductNamePartSeparator: (state, action: PayloadAction<{name:string,value:string}>) => {
            state.productNamePartsSeparators[action.payload.name] = action.payload.value
        },
        setPhraseReplacements: (state, action: PayloadAction<Array<WordReplacement>>) => {
            state.phraseReplacements.data = action.payload;
        },
        setPhraseReplacementFetching: (state, action: PayloadAction<boolean>) => {
            state.phraseReplacements.isFetching = action.payload;
        },
        setPhraseReplacementOriginalWorld: (state, action: PayloadAction<string>) => {
            state.phraseReplacements.originalWorld = action.payload
        },
        setPhraseReplacementInputName: (state, action: PayloadAction<string>) => {
            state.phraseReplacements.inputName = action.payload
        },
        setProductPreviewModalProductSuggestion: (state, action: PayloadAction<ProductSuggestionNew>) => {
            if(action.payload){
                state.modals.productPreview.productData = {
                    name: action.payload.name,
                    imageUrl: action.payload.imageUrl,
                    shortDescription: action.payload.shortDescription,
                    categoryName: null
                }
            }
            else{
                state.modals.productPreview.productData = null;
            }
        },
        setProductPreviewModalProductCatalog: (state,action: PayloadAction<CatalogProduct>) => {
            if(action.payload){
                state.modals.productPreview.productData = {
                    name: action.payload.name,
                    imageUrl: action.payload.imageUrl,
                    shortDescription: action.payload.shortDescription,
                    categoryName: action.payload.categoryName
                }
            }
            else{
                state.modals.productPreview.productData = null;
            }
        },         
        setCreateButtonDisabled: (state, action: PayloadAction<boolean>) => {
            state.createButton.isDisabled = action.payload;
        },
        setMainProductOffset: (state, action: PayloadAction<number>) => {
            state.mainProductOffset = action.payload;
        },
        setForcedPmProducts: (state,action: PayloadAction<Array<ForcedPmProducts>>) => {
            state.forcedPmProducts = action.payload;
        },
        addForceAssignedProducts: (state,action: PayloadAction<Array<number>>) => {
            const selectedFeedIds = state.productSuggestions.data.filter(data => action.payload.includes(data.key)).map(d => d.feed_id);
            let forceAssignedProducts = state.forceAssignedProducts;
            forceAssignedProducts = forceAssignedProducts.concat(selectedFeedIds);
            state.forceAssignedProducts = forceAssignedProducts;
        }
    }
});

export const loadShopFilterData = (value: string): AppThunk => async (dispatch, getState) => {
    dispatch(productCreateSlice.actions.setIsFetchingFilterData({ name: 'shop', value: true }));

    API.loadShops({ query: value, limit: 100 }).then(response => {
        dispatch(productCreateSlice.actions.setFilterData({
            name: 'shop', value:
                response.shops.map(shop => {
                    return {
                        name: shop.name+` (${shop.program_id})`,
                        value: shop.shop_id
                    }
                })
        }));

        dispatch(productCreateSlice.actions.setIsFetchingFilterData({ name: 'shop', value: false }));
    })
}

export const setFilter = (filter: string, value: number[] | string | number | null | { name: string, value: number }) => (dispatch, getState) => {
    dispatch(productCreateSlice.actions.setActiveFilterValue({ name: filter, value }));
    dispatch(setSingleWordButtonState());
}

export const setMainFilter = (filter: string, value: string) => (dispatch, getState) => {
    const productSuggestions = productSuggestionsSelector(getState());
    dispatch(productCreateSlice.actions.setActiveFilterValue({ name: filter, value }));

    const normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
    const newProductSuggestion = productSuggestions.filter(productSuggestion => {
        return productSuggestion.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().search(normalizedValue)>=0?true:false;
    });

    dispatch(productCreateSlice.actions.setFilteredProductSuggestions(newProductSuggestion));
}

export const setShopFilter = (filter: string, value: number) => (dispatch, getState) => {
    const shopId = value;

    if (shopId) {
        API.loadPmShopStats({ filterShopIds: [shopId], 
                              withManufacturerStats: true,
                              withCategoryStats: true, 
                              withNames: true, 
                              ignoreEmptyNames:true, 
                              filterNotCategoryIds: [null], 
                              filterNotManufacturerIds: [null],
                              ignoreUnassigned: true,
                              isNotSkipped:true 
                            }).then(response => {
            dispatch(productCreateSlice.actions.setFilterFullData({ name: "manufacturer", value: response.manufacturer_stats }));
            dispatch(productCreateSlice.actions.setFilterFullData({ name: "category", value: response.category_stats }));

            dispatch(productCreateSlice.actions.setActiveFilterValue({ name: "manufacturerSelected", value: null }));
            dispatch(productCreateSlice.actions.setActiveFilterValue({ name: "categorySelected", value: null }));
        }).catch(e => {
        }).finally(() => {
            dispatch(productCreateSlice.actions.setActiveFilterValue({ name: filter, value }));
            dispatch(loadManufacturerFilterData(""));
        });
    }
    else {
        dispatch(productCreateSlice.actions.setFilterData({ name: "category", value: [] }));
        dispatch(productCreateSlice.actions.setFilterData({ name: "manufacturer", value: [] }));
        dispatch(productCreateSlice.actions.setFilterFullData({ name: "manufacturer", value: [] }));
        dispatch(productCreateSlice.actions.setFilterFullData({ name: "category", value: [] }));
        dispatch(productCreateSlice.actions.setActiveFilterValue({ name: "manufacturerSelected", value: null }));
        dispatch(productCreateSlice.actions.setActiveFilterValue({ name: "categorySelected", value: null }));
        dispatch(productCreateSlice.actions.setActiveFilterValue({ name: filter, value }));
    }

    dispatch(setSingleWordButtonState());
}

export const setManufacturerFilter = (filter: string, value: number) => (dispatch, getState) => {
    const shopActiveFilterId = shopActiveFiltersSelector(getState());
    const manufacturerId = value;

    if (manufacturerId) {
        API.loadPmShopStats({
            filterShopIds: [shopActiveFilterId],
            filterManufacturerIds: [manufacturerId],
            withCategoryStats: true,
            withNames: true,
            ignoreEmptyNames: true,
            filterNotCategoryIds: [null],
            filterNotManufacturerIds: [null]
        }).then(response => {
            dispatch(productCreateSlice.actions.setFilterFullData({ name: "category", value: response.category_stats }));
            dispatch(loadCategoryFilterData(""));
        }).catch(e => {
        }).finally(() => {
            dispatch(productCreateSlice.actions.setActiveFilterValue({ name: "categorySelected", value: null }));
            dispatch(productCreateSlice.actions.setActiveFilterValue({ name: filter, value }));
        });
    }
    else {
        dispatch(productCreateSlice.actions.setActiveFilterValue({ name: "categorySelected", value: null }));
        dispatch(productCreateSlice.actions.setActiveFilterValue({ name: filter, value }));
    }
    dispatch(setSingleWordButtonState());
}

export const loadManufacturerFilterData = (value: string): AppThunk => async (dispatch, getState) => {
    const shopActiveFilterId = shopActiveFiltersSelector(getState());

    if (shopActiveFilterId) {
        const shopSelectedManufacturers = getState().productCreate.filtersData.manufacturer.fullData;
        const modifiedValue = value ? value.toLowerCase() : "";
        const filteredManufacturers = shopSelectedManufacturers.filter(shopSelectedManufacturer => { return shopSelectedManufacturer.name.toLowerCase().includes(modifiedValue) })
            .map(shopSelectedManufacturer => {
                return {
                    name: shopSelectedManufacturer.name + `(${shopSelectedManufacturer.count})`,
                    value: shopSelectedManufacturer.manufacturer_id,
                    count: shopSelectedManufacturer.count
                }
            });

        dispatch(productCreateSlice.actions.setFilterData({ name: 'manufacturer', value: filteredManufacturers }));
    }
    else {
        console.log("NOT IMPLEMENTED");
    }
}

export const loadCategoryFilterData = (value: string): AppThunk => async (dispatch, getState) => {
    const shopActiveFilterId = shopActiveFiltersSelector(getState());

    if (shopActiveFilterId) {
        const shopSelectedCategories = getState().productCreate.filtersData.category.fullData;
        const modifiedValue = value.toLowerCase();
        const filteredCategories = shopSelectedCategories.filter(shopSelectedCategory => { return shopSelectedCategory.name.toLowerCase().includes(modifiedValue) })
            .map(shopSelectedCategory => {
                return {
                    name: shopSelectedCategory.name + `(${shopSelectedCategory.count})`,
                    value: shopSelectedCategory.category_id,
                    count: shopSelectedCategory.count
                }
            });

        dispatch(productCreateSlice.actions.setFilterData({ name: 'category', value: filteredCategories }));

    } else {
        console.log("NOT IMPLEMENTED YET");
    }
}

export const loadNewMainProduct = (): AppThunk => async (dispatch, getState) => {
    dispatch(productCreateSlice.actions.setMainProductOffset(0));
    dispatch(loadMainProduct());  
}

export const loadMainProduct = (): AppThunk => async (dispatch, getState) => {
    const shopActiveFilterId = shopActiveFiltersSelector(getState());
    const manufacturerActiveFilterId = manufacturerActiveFiltersSelector(getState());
    const categoryActiveFilterId = categoryActiveFiltersSelector(getState());
    const mainProductOffset = mainProductOffsetSelector(getState());

    API.loadPMProducts({ filterCategoryId: categoryActiveFilterId?[categoryActiveFilterId]:undefined, 
                         filterManufacturerId: manufacturerActiveFilterId?[manufacturerActiveFilterId]:undefined, 
                         filterShopIds: [shopActiveFilterId],
                         filterNotCategoryIds: [null],
                         filterNotManufacturerIds: [null], 
                         limit: 1, 
                         offset: mainProductOffset,
                         searchStrategy: "standard", 
                         isNotSkipped: true,
                         filterWithoutEmptyNames:true })
        .then(response => {
            dispatch(handleMainPmProductsResponse(response));
        }).catch(e => {

        });  
}

export const loadForcedMainProduct = (feedId): AppThunk => async (dispatch, getState) => { 
    API.loadPMProducts({ filterFeedId: feedId,
                         limit: 1, 
                         searchStrategy: "standard", 
                         filterWithoutEmptyNames:true })
    .then(response => {
        dispatch(handleMainPmProductsResponse(response));
    }).catch(e => {

    });  
}

const handleMainPmProductsResponse = (response): AppThunk => async (dispatch, getState) => {
    const pmProduct = (response.pm_products && response.pm_products.length) ? response.pm_products[0] : null;
    if (pmProduct) {
        const mainProduct: MainProduct = { 
            name: pmProduct.name,
            image: pmProduct.image,
            price: pmProduct.price,
            categoryId: pmProduct.category_id,
            manufacturerId: pmProduct.manufacturer_id,
            description: pmProduct.description,
            feed_id: pmProduct.feed_id
        }

        dispatch(productCreateSlice.actions.setMainProduct(mainProduct));
        dispatch(productCreateSlice.actions.setActiveFilterValue({name: 'ean',value: pmProduct.ean}));
        dispatch(productCreateSlice.actions.setActiveFilterValue({name: 'partNumber', value: pmProduct.part_number}));

        // dispatch(loadCatalogProducts());
        dispatch(loadMainCategory());
        dispatch(loadMainManufacturerById(mainProduct.manufacturerId));

        dispatch(regenerate(mainProduct.manufacturerId));
        dispatch(productCreateSlice.actions.setRemovedSelectedSuggestions([]));
        dispatch(productCreateSlice.actions.setProductNameParts(initialProductNameParts));
    }
    else {
        dispatch(productCreateSlice.actions.setProductSuggestionsFething(false));
        dispatch(productCreateSlice.actions.setProductSuggestions([]));
        dispatch(productCreateSlice.actions.setFilteredProductSuggestions([]));

        dispatch(productCreateSlice.actions.setMainProduct(null));
    }
}

const doubleEscape = (str: string) => {
    str = replaceAll(str,"(","\\(");
    str = replaceAll(str,")","\\)");
    str = replaceAll(str,"+","\\+");
    return str;
}

export const regenerate = (mainManufacturerId?:number): AppThunk => async (dispatch, getState) => {
    // const manufacturerActiveFilterId = manufacturerActiveFiltersSelector(getState());
    const categoryActiveFilterId = categoryActiveFiltersSelector(getState());
    const mainManufacturer = mainManufacturerActiveFiltersSelector(getState());
    const ean = eanActiveFilterSelector(getState());
    const partNumber = partNumberActiveFilterSelector(getState());
    const productNameParts = productNamePartsSelector(getState());
    const productNamePartsSeparators = productNamePartsSeparatorSelector(getState());
    const mainProduct = mainProductSelector(getState());
    const removedSelectedSuggestions = removedSelectedSuggestionsSelector(getState());
    const forcedPmProducts = forcedPmProductsSelector(getState());
    const forceAssignToMainProductFeedIds = forceAssignedProductsSelector(getState());

    dispatch(productCreateSlice.actions.setProductSuggestionsFething(true));

    const modelNameParamRegex  = productNameParts.model_name.selectedMaskRegex?productNameParts.model_name.selectedMaskRegex.toLowerCase():undefined;
    const modelNamePrefix      = productNameParts.model_name_prefix?productNameParts.model_name_prefix.value:undefined;
    const modelNameSuffix      = productNameParts.model_name_suffix?productNameParts.model_name_suffix.value:undefined;

    const optional1ParamRegex  = productNameParts.optional1_param?productNameParts.optional1_param.selectedMaskRegex:undefined;
    const optional2ParamRegex  = productNameParts.optional1_param?productNameParts.optional2_param.selectedMaskRegex:undefined;
    const optional3ParamRegex  = productNameParts.optional1_param?productNameParts.optional3_param.selectedMaskRegex:undefined;
    const optional4ParamRegex  = productNameParts.optional1_param?productNameParts.optional4_param.selectedMaskRegex:undefined;
    const optional5ParamRegex  = productNameParts.optional1_param?productNameParts.optional5_param.selectedMaskRegex:undefined;
    const optional6ParamRegex  = productNameParts.optional1_param?productNameParts.optional6_param.selectedMaskRegex:undefined;

    const modelNameParam       = productNameParts.model_name?productNameParts.model_name.value:undefined;
    const optional1Param       = productNameParts.optional1_param?productNameParts.optional1_param.value:undefined;
    const optional2Param       = productNameParts.optional1_param?productNameParts.optional2_param.value:undefined;
    const optional3Param       = productNameParts.optional1_param?productNameParts.optional3_param.value:undefined;
    const optional4Param       = productNameParts.optional1_param?productNameParts.optional4_param.value:undefined;
    const optional5Param       = productNameParts.optional1_param?productNameParts.optional5_param.value:undefined;
    const optional6Param       = productNameParts.optional1_param?productNameParts.optional6_param.value:undefined;

    API.loadCreateSuggestions({ categoryId:categoryActiveFilterId?categoryActiveFilterId:undefined, 
                                manufacturerId:mainManufacturerId?mainManufacturerId:mainManufacturer?mainManufacturer.value:undefined,
                                mainManufacturerId: mainManufacturerId?mainManufacturerId:mainManufacturer?mainManufacturer.value:undefined,
                                modelNameParamRegex: modelNameParamRegex?doubleEscape(modelNameParamRegex):modelNameParamRegex,
                                ean:ean, 
                                partNumber: partNumber,
                                modelNamePrefix: modelNamePrefix,
                                modelNameSuffix: modelNameSuffix,
                            
                                optional1ParamRegex: optional1ParamRegex?optional1ParamRegex:undefined,
                                optional2ParamRegex: optional2ParamRegex?optional2ParamRegex:undefined,
                                optional3ParamRegex: optional3ParamRegex?optional3ParamRegex:undefined,
                                optional4ParamRegex: optional4ParamRegex?optional4ParamRegex:undefined,
                                optional5ParamRegex: optional5ParamRegex?optional5ParamRegex:undefined,
                                optional6ParamRegex: optional6ParamRegex?optional6ParamRegex:undefined, 
                                
                                modelNameParam: modelNameParam?modelNameParam:undefined,
                                optional1Param: optional1Param?optional1Param:undefined,
                                optional2Param: optional2Param?optional2Param:undefined,
                                optional3Param: optional3Param?optional3Param:undefined,
                                optional4Param: optional4Param?optional4Param:undefined,
                                optional5Param: optional5Param?optional5Param:undefined,
                                optional6Param: optional6Param?optional6Param:undefined,
                                removedSelectedSuggestions:removedSelectedSuggestions?removedSelectedSuggestions:[],
                                forcedFeedIds: forcedPmProducts && forcedPmProducts.length?forcedPmProducts.map(f=>f.feed_id):undefined,
                                forceAssignToMainProductFeedIds: forceAssignToMainProductFeedIds,

                                optional12Separator: productNamePartsSeparators.optional1_2_sepator,
                                optional23Separator: productNamePartsSeparators.optional2_3_sepator,
                                optional34Separator: productNamePartsSeparators.optional3_4_sepator,
                                optional45Separator: productNamePartsSeparators.optional4_5_sepator,
                                optional56Separator: productNamePartsSeparators.optional5_6_sepator

                            }
                            ).then(response => {

        const groupedProducts:Array<ProductSuggestionNew> = [];
        for(let i=0;i<response.productSuggestions.groups.length;i++){
            const group = response.productSuggestions.groups[i];
            for(const productEntity of group.productEntities) {
                groupedProducts.push({
                   //key: productEntity.product.feed_id+i,
                   key: getRandomArbitrary(0,Number.MAX_SAFE_INTEGER),
                   feed_id: productEntity.product.feed_id,
                   name: productEntity.product.name,
                   price: productEntity.product.price,
                   internalId: "",
                   partNumber: productEntity.product.part_number,
                   ean: productEntity.product.ean,
                   shopName: productEntity.product.shop[0].name+` (${productEntity.product.shop[0].program_id})`   ,
                   matchTypes: productEntity.matchTypes,
                   catalogName: group.name,
                   color: rowColors[i%13],
                   groupId: group.id,
                   isVisible: true,
                   url: productEntity.product.url,
                   imageUrl: productEntity.product.image,
                   shortDescription: productEntity.product.description?productEntity.product.description.substring(0,200):"",
                   countryId: productEntity.product.shop[0].country_id,
                   medianPrice: group.medianPrice
                })
            }
        }

        for(const unassignedProduct of response.productSuggestions.unassignedProducts) {
            groupedProducts.push({
                key: getRandomArbitrary(0,Number.MAX_SAFE_INTEGER),
                feed_id: unassignedProduct.feed_id,
                name: unassignedProduct.name,
                price: unassignedProduct.price,
                internalId: "",
                partNumber: unassignedProduct.part_number,
                ean: unassignedProduct.ean,
                shopName: unassignedProduct.shop[0].name+ ` (${unassignedProduct.shop[0].program_id})`,
                matchTypes: null,
                catalogName: "",
                color: "#ffffff",
                groupId: null,
                isVisible: true,
                url: unassignedProduct.url,
                imageUrl: unassignedProduct.image,
                shortDescription: unassignedProduct.description?unassignedProduct.description.substring(0,200):"",
                countryId: unassignedProduct.shop[0].country_id,
                medianPrice: null
            })
        }
        
        const selectedKeys = groupedProducts.filter(groupedProduct => (groupedProduct.matchTypes !== null && !removedSelectedSuggestions.includes(groupedProduct.feed_id))).map(groupedProduct => {
            return groupedProduct.key;
        });

        dispatch(productCreateSlice.actions.setProductSuggestions(groupedProducts));
        dispatch(productCreateSlice.actions.setFilteredProductSuggestions(groupedProducts));
        dispatch(productCreateSlice.actions.setSelectedSuggestions(selectedKeys));
        dispatch(productCreateSlice.actions.setProductSuggestionsFething(false));

        const catalogProductsItems: Array<CatalogProduct> = response.catalogProducts.map(product => {
            let categoryName = "";
            if(product.category && product.category.translations && product.category.translations.length){
                const categoryTranslation = product.category.translations.find(translation => translation.language_id === 1);
                if(categoryTranslation){
                    categoryName = categoryTranslation.name;
                }
            }

            return {
                key: product.product_id,
                name: product.name,
                imageUrl: `https://www.pricemania.sk/assets/product/${product.product_id}/detail_image.jpg`,
                shortDescription: product.productDescriptions && product.productDescriptions.length && product.productDescriptions[0].description?product.productDescriptions[0].description.substring(0, 1000):'',
                categoryName: categoryName
            }
        });

        let sumSkPrices = 0;
        let countSkPrices = 0;
        let averageSkPrice = 0;
        for(const productItem of response.catalogProducts) {
            const skAggregation = productItem.aggregations?productItem.aggregations.find(aggregation => aggregation.country_id === 1):null;
            if(skAggregation && skAggregation.average_price > 0){
                countSkPrices++;
                sumSkPrices += skAggregation.average_price;
            }
        }
        if(countSkPrices > 0) {
            averageSkPrice = sumSkPrices/countSkPrices;
        }
        
        dispatch(productCreateSlice.actions.setCatalogProducts({averagePrice: averageSkPrice, items: catalogProductsItems}));

        if(mainProduct && productNameParts.model_name && productNameParts.model_name.value){
            dispatch(productCreateSlice.actions.setCreateButtonDisabled(false));
        }
        else{
            dispatch(productCreateSlice.actions.setCreateButtonDisabled(true));
        }
    });

    console.log("REGENERATE SUGGESTIONS");
}

export const createProduct = (): AppThunk => async (dispatch, getState) => {
    const mainManufacturer = mainManufacturerActiveFiltersSelector(getState());
    const mainCategory     = mainCategoryActiveFiltersSelector(getState());
    const ean        = eanActiveFilterSelector(getState());
    const partNumber = partNumberActiveFilterSelector(getState());
    const productSuggestions = productSuggestionsSelector(getState());
    const selectedSuggestions = selectedSuggestionsSelector(getState());
    const productNameParts = productNamePartsSelector(getState());

    console.log(`Manufacturer ${mainManufacturer.value}`);
    console.log(`CategoryId ${mainCategory.value}`);
    console.log(`Ean ${ean}`);
    console.log(`PartNumber ${partNumber}`);
    console.log("Product suggestions");
    console.log(productSuggestions);
    console.log("Selected suggestions");
    console.log(selectedSuggestions);

    const selectedGroupedProducts =  productSuggestions.filter(productSuggestion => 
        productSuggestion.groupId !== null && selectedSuggestions.includes(productSuggestion.key)
    );

    const productGroups = Array.from(new Set(selectedGroupedProducts.map((item) => item.groupId))).map(uniqueGroup => {
        const productsInGroup =  selectedGroupedProducts.filter(selectedGroupedProduct => selectedGroupedProduct.groupId === uniqueGroup);

        return {
            name: productsInGroup[0].catalogName,
            pm_shop_product_ids: productsInGroup.map(productInGroup => {
               return productInGroup.feed_id
            })
        }
    });

    let mask = "[manufacturer_param] [model_name_param_prefix] [model_name_param] [model_name_param_suffix]"
    for(const optionalParam of mainCategory.optionalParams){
        mask += " ["+optionalParam.id+"]";
    }
    
    dispatch(productCreateSlice.actions.setCreateButtonDisabled(true));
    dispatch(productCreateSlice.actions.setProductSuggestionsFething(true));

    const token = tokenSelector(getState());
    API.setToken(token);

    API.createProduct({},{
        category_id: mainCategory.value,
        manufacturer_id: mainManufacturer.value,
        part_number: partNumber,
        ean: ean,
        product_groups: productGroups,
        model_name_suffix: productNameParts.model_name_suffix && productNameParts.model_name_suffix.value?productNameParts.model_name_suffix.value:null,
        model_name_prefix: productNameParts.model_name_prefix && productNameParts.model_name_prefix.value?productNameParts.model_name_prefix.value:null,
        mask: mask,
        model_name_original: productNameParts.model_name && productNameParts.model_name.value?productNameParts.model_name && productNameParts.model_name.value:null,
        model_name_regex: productNameParts.model_name && productNameParts.model_name.selectedMaskRegex?productNameParts.model_name.selectedMaskRegex:null,
        optional1_original: productNameParts.optional1_param && productNameParts.optional1_param.value?productNameParts.optional1_param.value:null,
        optional2_original: productNameParts.optional2_param && productNameParts.optional2_param.value?productNameParts.optional2_param.value:null,
        optional3_original: productNameParts.optional3_param && productNameParts.optional3_param.value?productNameParts.optional3_param.value:null,
        optional4_original: productNameParts.optional4_param && productNameParts.optional4_param.value?productNameParts.optional4_param.value:null,
        optional5_original: productNameParts.optional5_param && productNameParts.optional5_param.value?productNameParts.optional5_param.value:null,
        optional6_original: productNameParts.optional6_param && productNameParts.optional6_param.value?productNameParts.optional6_param.value:null,
        optional1_regex: productNameParts.optional1_param && productNameParts.optional1_param.value?productNameParts.optional1_param.selectedMaskRegex:null,
        optional2_regex: productNameParts.optional2_param && productNameParts.optional2_param.value?productNameParts.optional2_param.selectedMaskRegex:null,
        optional3_regex: productNameParts.optional3_param && productNameParts.optional3_param.value?productNameParts.optional3_param.selectedMaskRegex:null,
        optional4_regex: productNameParts.optional4_param && productNameParts.optional4_param.value?productNameParts.optional4_param.selectedMaskRegex:null,
        optional5_regex: productNameParts.optional5_param && productNameParts.optional5_param.value?productNameParts.optional5_param.selectedMaskRegex:null,
        optional6_regex: productNameParts.optional6_param && productNameParts.optional6_param.value?productNameParts.optional6_param.selectedMaskRegex:null,
    }).then(response => {
        dispatch(productCreateSlice.actions.setFilteredProductSuggestions([]));
        dispatch(productCreateSlice.actions.setProductSuggestions([]));
        dispatch(loadMainProduct()); 
    });
}

/*
export const loadCatalogProducts = (): AppThunk => async (dispatch, getState) => {
    const manufacturerActiveFilterId = manufacturerActiveFiltersSelector(getState());
    const categoryActiveFilterId = categoryActiveFiltersSelector(getState());

    API.loadProducts({ categoryIds: categoryActiveFilterId?[categoryActiveFilterId]:undefined, 
                       manufacturerIds: manufacturerActiveFilterId?[manufacturerActiveFilterId]:undefined, 
                       limit: 100 
                    }).then(response => {
        const catalogProducts: Array<CatalogProduct> = response.products.map(product => {
            return {
                name: product.name
            }
        });

        dispatch(productCreateSlice.actions.setCatalogProducts(catalogProducts));
    });
}
*/

export const loadMainCategory = (): AppThunk => async (dispatch, getState) => {
    const mainProduct = mainProductSelector(getState());
    if (mainProduct) {
        dispatch(loadMainCategoryByCategoryId(mainProduct.categoryId));
    }
    else {
        console.log("MAIN PRODUCT NOT FOUND");
        console.log(mainProduct);
    }
}

export const loadMainCategoryByCategoryId = (categoryId: number): AppThunk => async (dispatch, getState) => {

    API.loadCategories({ categoryIds: [categoryId] }).then(response => {
        if (response.categories.length) {
            const category = response.categories[0];
            let categoryName: string;
            if (category && category.translations && category.translations.length) {
                const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
                if (categoryTranslation) {
                    categoryName = categoryTranslation.name;
                }
            }

            const mainCategory: MainCategory = {
                value: category.category_id,
                name: categoryName,
                optionalParams: Object.keys(category).map(key => {
                    if (key.match(/optional[0-9]_param/)) {
                        return { id: key, name: category[key] };
                    }
                    return undefined;
                }).filter(obj => obj && obj.name)
            }

            dispatch(productCreateSlice.actions.setMainCategory(mainCategory));
        }
    });
}

export const loadMainManufacturerById = (manufacturerId: number): AppThunk => async (dispatch, getState) => {
    API.loadManufacturers({ manufacturerIds: [manufacturerId] }).then(response => {
        if (response.manufacturers.length) {
            const mainManufacturer = response.manufacturers[0];
            dispatch(setFilter("mainManufacturerSelected", { name: mainManufacturer.name, value: mainManufacturer.manufacturer_id }))
        }
    });
}

export const loadSingleWordReplacements = (): AppThunk => async (dispatch, getState) => {
    const mainCategoryActiveFilter = mainCategoryActiveFiltersSelector(getState());
    const mainManufacturerActiveFilter = mainManufacturerActiveFiltersSelector(getState());
    dispatch(productCreateSlice.actions.setWordReplacementFetching(true));
    API.listReplacementWords({
        categoryId: mainCategoryActiveFilter?[mainCategoryActiveFilter.value]:undefined,
        manufacturerId: mainManufacturerActiveFilter?[mainManufacturerActiveFilter.value]:undefined,
        withManufacturerNull:true
    }).then(response => {

        const wordReplacements: Array<WordReplacement> = response.items.map(item => {
            let type = WordReplacementType.UNKNOWN;
            switch (item.type) {
                case "phrase":
                    type = WordReplacementType.PHRASE;
                    break;
                case "single-word":
                    type = WordReplacementType.SINGLE_WORD;
                    break;
            }

            return {
                key: item.replacement_id,
                replacementId: item.replacement_id,
                originalWord: item.original_word,
                replacementWord: item.replace_word,
                manufacturerId: item.manufacturer_id,
                withManufacturer: item.manufacturer_id ? true : false,
                state: WordReplacementState.ORIGINAL,
                type: type
            }
        });

        dispatch(productCreateSlice.actions.setWordReplacements(wordReplacements));
        dispatch(productCreateSlice.actions.setWordReplacementFetching(false));
    });
}

export const loadPhraseWordReplacements = (inputName:string,inputvalue:string): AppThunk => async (dispatch, getState) => {
    
    const nameParts = productNamePartsSelector(getState());
    const modifiedInputName = inputName === 'model_name_param'?'model_name': inputName;

    const humanRegex = nameParts[modifiedInputName]?nameParts[modifiedInputName].selectedMaskHumanRegex:null;

    if(inputName === 'model_name_prefix' || inputName === 'model_name' || inputName === 'model_name_suffix'){
        inputName = 'model_name_param';
    }

    const mainCategoryActiveFilter = mainCategoryActiveFiltersSelector(getState());
    //const mainManufacturerActiveFilter = mainManufacturerActiveFiltersSelector(getState());
    dispatch(productCreateSlice.actions.setPhraseReplacementFetching(true));
    dispatch(productCreateSlice.actions.setPhraseReplacementOriginalWorld(inputvalue));
    dispatch(productCreateSlice.actions.setPhraseReplacementInputName(inputName));
    
    API.listReplacementPhrases({
        categoryId: [mainCategoryActiveFilter.value],
        // manufacturerId: [mainManufacturerActiveFilter.value],
        field: inputName,
        originalWord: inputvalue,
        withManufacturerNull:true,
        humanRegex: humanRegex
    }).then(response => {
        const wordReplacements: Array<WordReplacement> = response.items.map(item => {
            let type = WordReplacementType.UNKNOWN;
            switch (item.type) {
                case "phrase":
                    type = WordReplacementType.PHRASE;
                    break;
                case "single-word":
                    type = WordReplacementType.SINGLE_WORD;
                    break;
            }

            return {
                key: item.replacement_id,
                replacementId: item.replacement_id,
                originalWord: item.original_word,
                replacementWord: item.replace_word,
                manufacturerId: item.manufacturer_id,
                withManufacturer: item.manufacturer_id ? true : false,
                state: WordReplacementState.ORIGINAL,
                type: type
            }
        });

        dispatch(productCreateSlice.actions.setPhraseReplacements(wordReplacements));
        dispatch(productCreateSlice.actions.setPhraseReplacementFetching(false));
    })
}

export const setModalVisibility = (name: string, visible: boolean): AppThunk => async (dispatch, getState) => {
    dispatch(productCreateSlice.actions.setModalVisibility({ name, visible }));
}

export const assignProducts = (key:number): AppThunk => async (dispatch, getState) => {
    dispatch(productCreateSlice.actions.addForceAssignedProducts([key]));
    dispatch(regenerate());
}

export const setSingleWordButtonState = (): AppThunk => async (dispatch, getState) => {
    const manufacturerActiveFilterId = manufacturerActiveFiltersSelector(getState());
    const categoryActiveFilterId = categoryActiveFiltersSelector(getState());

    dispatch(productCreateSlice.actions.setSingleWordButtonState(manufacturerActiveFilterId && categoryActiveFilterId ? false : true));
}

export const loadChangeCategoryFilterData = (value): AppThunk => async (dispatch, getState) => {
    dispatch(productCreateSlice.actions.setIsFetchingFilterData({ name: 'changeCategory', value: true }));
    API.loadCategories({ query: value, limit: 100 }).then(response => {
        dispatch(productCreateSlice.actions.setFilterData({
            name: 'changeCategory', value:
                response.categories.map(category => {
                    return {
                        name: [...category.parents_names].reverse().filter((item, index) => index % 2 === 0).join(' < '),
                        value: category.category_id
                    }
                })
        }));
        dispatch(productCreateSlice.actions.setIsFetchingFilterData({ name: 'changeCategory', value: false }));
    });
}

export const loadChangeManufacturerFilterData = (value): AppThunk => async (dispatch, getState) => {
    dispatch(productCreateSlice.actions.setIsFetchingFilterData({ name: 'changeManufacturer', value: true }));
    API.loadManufacturers({ query: value, limit: 100 }).then(response => {
        dispatch(productCreateSlice.actions.setFilterData({
            name: 'changeManufacturer', value:
                response.manufacturers.map(manufacturer => {
                    return {
                        name: manufacturer.name,
                        value: manufacturer.manufacturer_id
                    }
                })
        }));
        dispatch(productCreateSlice.actions.setIsFetchingFilterData({ name: 'changeManufacturer', value: false }));
    });
}

export const loadMainManufacturerFilterData = (value): AppThunk => async (dispatch, getState) => {
    dispatch(productCreateSlice.actions.setIsFetchingFilterData({ name: 'mainManufacturer', value: true }));
    API.loadManufacturers({ query: value, limit: 100 }).then(response => {
        dispatch(productCreateSlice.actions.setFilterData({
            name: 'mainManufacturer', value:
                response.manufacturers.map(manufacturer => {
                    return {
                        name: manufacturer.name,
                        value: manufacturer.manufacturer_id
                    }
                })
        }));
        dispatch(productCreateSlice.actions.setIsFetchingFilterData({ name: 'mainManufacturer', value: false }));
    });
};

export const loadMainCategoryFilterData = (value): AppThunk => async (dispatch, getState) => {
    dispatch(productCreateSlice.actions.setIsFetchingFilterData({ name: 'mainCategory', value: true }));
    API.loadCategories({ query: value, limit: 100 }).then(response => {
        dispatch(productCreateSlice.actions.setFilterData({
            name: 'mainCategory', value:
                response.categories.map(category => {
                    let categoryName: string;
                    if (category && category.translations && category.translations.length) {
                        const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
                        if (categoryTranslation) {
                            categoryName = categoryTranslation.name;
                        }
                    }
                    return {
                        name: categoryName,
                        value: category.category_id
                    }
                })
        }));
        dispatch(productCreateSlice.actions.setIsFetchingFilterData({ name: 'mainCategory', value: false }));
    });
};

export const updateWordReplacement = (wordReplacement: WordReplacement): AppThunk => async (dispatch, getState) => {
    const mainManufacturer = mainManufacturerActiveFiltersSelector(getState());
    if (mainManufacturer && mainManufacturer.value) {
        API.updateReplacementWord(wordReplacement.replacementId, {}, {
            manufacturer_id: wordReplacement.withManufacturer ? mainManufacturer.value : null,
            original_word: wordReplacement.originalWord,
            replace_word: wordReplacement.replacementWord,
        }).then(response => {
            dispatch(loadSingleWordReplacements());
        });
    }
}

export const createWordReplacement = (wordReplacement: WordReplacement): AppThunk => async (dispatch, getState) => {
    const mainManufacturer = mainManufacturerActiveFiltersSelector(getState());
    const mainCategory = mainCategoryActiveFiltersSelector(getState());
    if (mainManufacturer && mainManufacturer.value && mainCategory && mainCategory.value) {
        API.createReplacementWord({}, {
            category_id: mainCategory.value,
            field: null,
            manufacturer_id: wordReplacement.withManufacturer ? mainManufacturer.value : null,
            original_word: wordReplacement.originalWord,
            replace_word: wordReplacement.replacementWord,
            rule_id: null
        }).then(response => {
            dispatch(loadSingleWordReplacements());
        });
    }
}

export const deleteWordReplacement = (replacementId: number): AppThunk => async (dispatch, getState) => {
    API.deleteReplacementWord(replacementId).then(response => {
        dispatch(loadSingleWordReplacements());
    });
}

export const updatePhraseReplacement = (wordReplacement: WordReplacement): AppThunk => async (dispatch, getState) => {
    const phraseReplacement = wordPhraseReplacementSelector(getState());
    const mainManufacturer = mainManufacturerActiveFiltersSelector(getState());

    if (mainManufacturer && mainManufacturer.value) {
        API.updateReplacementPhrase(wordReplacement.replacementId, {}, {
            manufacturer_id: wordReplacement.withManufacturer ? mainManufacturer.value : null,
            replace_word: wordReplacement.replacementWord,
        }).then(response => {
            dispatch(loadPhraseWordReplacements(phraseReplacement.inputName,phraseReplacement.originalWorld));
        });
    }
};

export const createPhraseReplacement = (wordReplacement: WordReplacement): AppThunk => async (dispatch, getState) => {
    const phraseReplacement = wordPhraseReplacementSelector(getState());
    const mainManufacturer = mainManufacturerActiveFiltersSelector(getState());
    const mainCategory = mainCategoryActiveFiltersSelector(getState());
    const nameParts = productNamePartsSelector(getState());
    
    let humanRegex = null;
    if(phraseReplacement.inputName === 'model_name_param'){
        humanRegex = nameParts['model_name'].selectedMaskHumanRegex 
    }
    
    if (mainManufacturer && mainManufacturer.value && mainCategory && mainCategory.value) {
        API.createReplacementPhrase({},{
            category_id: mainCategory.value,
            field: phraseReplacement.inputName,
            manufacturer_id: wordReplacement.withManufacturer ? mainManufacturer.value : null,
            original_word: phraseReplacement.originalWorld,
            replace_word: wordReplacement.replacementWord,
            rule_id: null,
            human_regex: humanRegex
        }).then(response => {
            dispatch(loadPhraseWordReplacements(phraseReplacement.inputName,phraseReplacement.originalWorld));
        });
    }
    
};

export const deletePhraseReplacement = (replacementId: number): AppThunk => async (dispatch, getState) => {
    const phraseReplacement = wordPhraseReplacementSelector(getState());
    API.deleteReplacementPhrase(replacementId).then(response => {
        dispatch(loadPhraseWordReplacements(phraseReplacement.inputName,phraseReplacement.originalWorld));
    });
};

export const setMaskSettingsModalInput = (inputName,text) => async (dispatch, getState) => {
    dispatch(productCreateSlice.actions.setMaskSettingsModalInput({inputName,text}));
}

const insertRegexWhitespaces = (originalText:string):string => {
    /*
    console.log("<<! ORIGINAL TEXT !>>");
    console.log(originalText);

    var re = new RegExp("(\\[(.*?)\\]{\\d+\\})","g");
    
    let replaceText = originalText.split(' ').map(text => {
       if(re.exec(text) == null){
            return text.split('').map(character => {
                if(character === 'i') return ['[ií]'];
                return character
            }).join('\\s*');
       }
       else{
           return text;
       }
    }).join('\\s* \\s*');

    console.log("<<! REPLACE TEXT !>>");
    console.log(replaceText);
    
    return replaceText;
    */
    
    
    console.log("<< !! ORIGINAL TEXT !!>>");
    console.log(originalText);

    var re = new RegExp("(\\[(.*?)\\]{\\d+\\})","g");
    let match;
    let replaceText = originalText;
    let lastIndex = 0;
    let offset = 0;
    while ((match = re.exec(originalText)) != null) {    
        console.log("<< !! AAA !!>>");
        console.log(lastIndex+offset);
        console.log(match.index+offset);
        console.log(replaceText);
        console.log("<< !! BBB !!>>");
        console.log(replaceText.substring(lastIndex+offset,match.index+offset));

        let textToReplace = replaceText.substring(lastIndex+offset,match.index+offset).split(' ').map(text => {
           return "\\s*"+text.split('').map(character => {
               
               const characterMap = ["Aaá","Ccč","Ddď","Eeé","Iií","Llľ","Nnň","Ooó","Ssš","Ttť","Uuú","Yyý","Zzž"];
               for(const itemCharacterMap of characterMap){
                   if(itemCharacterMap.includes(character)){
                       return "["+itemCharacterMap+"]";
                   }
               } 
               return character
            }).join('\\s*')+"\\s*";
        }).join(' ');
        
        const currentLength = replaceText.length;
        replaceText = replaceText.substring(0,lastIndex+offset) + textToReplace + replaceText.substring(match.index+offset,replaceText.length);

        lastIndex = match.index + match[0].length;
        offset+= replaceText.length - currentLength;
    }
    
    let textToReplace = replaceText.substring(lastIndex+offset,replaceText.length).split(' ').map(text => {
        return "\\s*" +text.split('').join("\\s*")+ "\\s*";
    }).join(' ');

    replaceText = replaceText.substring(0,lastIndex+offset) + textToReplace + replaceText.substring(lastIndex+offset+textToReplace.length,replaceText.length);
    //replaceText = '([\\s+,]|^)'+replaceText+'([\\s+,]|$)';

    while(replaceText.startsWith('\\s*')){
        replaceText = replaceText.slice(3);
    }
    
    while(replaceText.endsWith('\\s*')){
        replaceText = replaceText.substring(0, replaceText.length - 3);
    }

    replaceText = replaceText.trim();

    replaceText = replaceAll(replaceText,"\\s*+\\s*","\\s*[+]\\s*");

    return replaceText;
}

export const calculateMasks = (text) => {
    if (text) {
        let stringParts = text.match(/([\s.,;+-])+|([a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ()&ČčĎďŽžĹĺĽľŇňŔŕŠšŤťŽžĚěŘřŮů])+|([0-9])+/gi)
            .map((item, index, arr) => {
                let startPos = 0;

                arr.slice(0, index).forEach(item => {
                    startPos += item.length;
                });

                const endPos = startPos + item.length;
                let type:string;
                if (!isNaN(item) && item.trim() !== "") {
                    type = "number";
                }
                else if (/^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ()&ČčĎďŽžĹĺĽľŇňŔŕŠšŤťŽžĚěŘřŮů]+$/.test(item)) {
                    type = "string";
                }
                else {
                    type = "unknown";
                }
                
                return { value: item, startPos, endPos, length: item.length, type }
            });
        
        const stringPartsCombinations = powerset(stringParts.filter(stringPart => stringPart.type !== 'unknown'));
        let masks:any[] = [];

        console.log("String parts combinations"); 
        console.log(stringPartsCombinations);    
        
        for (const stringCombination of stringPartsCombinations) {
            let originalString:string = text;
            let regexString:string = text;
            let regexOffset = 0;
            let lowestNumberPosition  = 9999;
            for (const textPart of stringCombination) {
                let replacement      = null;
                let regexReplacement = null;
                let regexLastOffset  = regexOffset;

                if (textPart.type === 'number') {
                    replacement      = '0'.repeat(textPart.length);
                    regexReplacement = `[0-9]{${textPart.length}}`;
                    regexOffset     += regexReplacement.length - replacement.length;
                    if(lowestNumberPosition > textPart.startPos){
                        lowestNumberPosition = textPart.startPos;
                    }
                }
                else if (textPart.type === 'string') {
                    replacement      = '?'.repeat(textPart.length);
                    regexReplacement = `[a-zA-Z()]{${textPart.length}}`;
                    regexOffset     += regexReplacement.length - replacement.length;
                }
                /*
                else{
                    replacement       = textPart.length;
                    regexReplacement  = textPart.match(/.{0,1}/g).join("\\s*");
                    regexOffset      += regexReplacement.length - replacement.length;
                }
                */

                if (replacement) {
                    originalString = originalString.substring(0, textPart.startPos) + replacement + originalString.substring(textPart.endPos, originalString.length);
                    regexString    = regexString.substring(0,textPart.startPos + regexLastOffset) + regexReplacement + regexString.substring(textPart.endPos + regexLastOffset, regexString.length)
                }
            }
            
            regexString = insertRegexWhitespaces(regexString);
            
            masks.push({key: regexString, humanRegex: originalString, stringCombination, lowestNumberPosition, regex: regexString});
        }
        
        let defaultMask = null;
        let hasAnyMaskNumber = false;
        for(const mask of masks) {
            const numbersCount = mask.stringCombination.filter(stringCombination => stringCombination.type === 'number').length;
            const stringsCount = mask.stringCombination.filter(stringCombination => stringCombination.type === 'string').length;
            
            if(numbersCount > 0){
                hasAnyMaskNumber = true;
            }

            if(defaultMask === null){
                defaultMask = mask;
            }
            else{
                const defaultNumbersCount = defaultMask.stringCombination.filter(stringCombination => stringCombination.type === 'number').length;
                const defaultStringsCount = defaultMask.stringCombination.filter(stringCombination => stringCombination.type === 'string').length;

                /*
                console.log("==========================");
                console.log(defaultNumbersCount);
                console.log(defaultStringsCount);
                console.log(numbersCount);
                console.log(stringsCount);
                console.log(defaultMask);
                console.log(mask);
                console.log("==========================");
                */
                
                if((numbersCount < defaultNumbersCount && numbersCount > 0)  || defaultNumbersCount === 0) {
                    defaultMask = mask;
                }
                else if(numbersCount === defaultNumbersCount){
                    if(stringsCount < defaultStringsCount && stringsCount > 0){
                        defaultMask = mask;
                    }
                    else if(stringsCount === defaultStringsCount){
                        if(mask.lowestNumberPosition < defaultMask.lowestNumberPosition){
                            defaultMask = mask;
                        }
                    }
                }
            }
        }
        
        if(!hasAnyMaskNumber) {
            const exactMatch = masks.find(mask => mask.stringCombination.length === 0);
            if(exactMatch){
                defaultMask = exactMatch;
            }
        }

        return {masks,defaultMask};
    }
    else {
        return {masks:[],defaultMask:null};
    }
}

export const setMasksForInput = (inputName:string,inputString:string): AppThunk => async (dispatch, getState) => {
    const masks = calculateMasks(inputString);
    dispatch(productCreateSlice.actions.setProductNamePart({name:inputName,value:{
        masks:masks.masks,
        selectedMaskRegex: masks.defaultMask?masks.defaultMask.regex:null,
        selectedMaskHumanRegex: masks.defaultMask? masks.defaultMask.humanRegex:null
    }}))
}

const powerset = (array) => { // O(2^n)
    const results = [[]];
    for (const value of array) {
        const copy = [...results]; // See note below.
        for (const prefix of copy) {
            results.push(prefix.concat(value));
        }
    }
    return results;
};


export const handleShiftSelection = (startKey:number,lastKey:number) : AppThunk => async (dispatch, getState) => {
    let secondarySelectedSuggestions = [...secondarySelectedSuggestionsSelector(getState())]; 
    const filteredData = productFilteredSuggestionsSelector(getState());

    if(startKey && lastKey){
        const isStartEntitySelected = secondarySelectedSuggestions.includes(startKey);

        let testXX = false;
        for(const dataEntity of filteredData){
            if(dataEntity.key === startKey || dataEntity.key === lastKey) {
                testXX = !testXX;
                if(!isStartEntitySelected){
                    if(!secondarySelectedSuggestions.includes(dataEntity.key)){
                        secondarySelectedSuggestions.push(dataEntity.key);
                    }
                }
                else {
                    if(secondarySelectedSuggestions.includes(dataEntity.key)){
                        secondarySelectedSuggestions = secondarySelectedSuggestions.filter(item => ![dataEntity.key].includes(item))
                    }
                } 
            }
            else if(testXX){
                if(!isStartEntitySelected){
                    if(!secondarySelectedSuggestions.includes(dataEntity.key)){
                        secondarySelectedSuggestions.push(dataEntity.key);
                    }
                }
                else {
                    if(secondarySelectedSuggestions.includes(dataEntity.key)){
                        secondarySelectedSuggestions = secondarySelectedSuggestions.filter(item => ![dataEntity.key].includes(item))
                    }
                } 
            }
        }

        dispatch(productCreateSlice.actions.setSecondarySelectedSuggestions(secondarySelectedSuggestions));
    }
}

export const handleRowClick = (key:number): AppThunk => async (dispatch, getState) => {
    let secondarySelectedSuggestions = [...secondarySelectedSuggestionsSelector(getState())];
    
    if(!secondarySelectedSuggestions.includes(key)){
        secondarySelectedSuggestions.push(key);
    } else {
        secondarySelectedSuggestions = secondarySelectedSuggestions.filter(item => ![key].includes(item))
    }

    dispatch(productCreateSlice.actions.setSecondarySelectedSuggestions(secondarySelectedSuggestions));
}

export const updatePmManufacturers = (): AppThunk => async (dispatch, getState) => {
    const activeSecondariSuggestions = getActiveSecondarySuggestionsEntities(getState());
    const changeManufacturer = changeManufacturerActiveFiltersSelector(getState());
    dispatch(productCreateSlice.actions.setIsFetchingFilterData({ name: 'changeManufacturer', value: true }));
    
    API.updatePmProducts({},activeSecondariSuggestions.map(activeSecondariSuggestion => {
        return {
            feed_id: activeSecondariSuggestion.feed_id,
            manufacturer_id: changeManufacturer
        }
    })).then(response => {
        dispatch(productCreateSlice.actions.setIsFetchingFilterData({ name: 'changeManufacturer', value: false }));
        dispatch(productCreateSlice.actions.setModalVisibility({name: "changeManufacturer", visible:false }));
        dispatch(regenerate());
    });
}

export const updatePmCategories = (): AppThunk => async (dispatch, getState) => {
    const activeSecondariSuggestions = getActiveSecondarySuggestionsEntities(getState());
    const changeCategory = changeCategoryActiveFiltersSelector(getState()); 
    dispatch(productCreateSlice.actions.setIsFetchingFilterData({ name: 'changeCategory', value: true }));
    API.updatePmProducts({},activeSecondariSuggestions.map(activeSecondariSuggestion => {
        return {
            feed_id: activeSecondariSuggestion.feed_id,
            category_id: changeCategory
        }
    })).then(response => {
        dispatch(productCreateSlice.actions.setIsFetchingFilterData({ name: 'changeCategory', value: false }));
        dispatch(productCreateSlice.actions.setModalVisibility({name: "changeCategory", visible:false }));
        dispatch(regenerate());
    });
}

const getActiveSecondarySuggestionsEntities = (state) => {
    const secondarySelectedSuggestions = secondarySelectedSuggestionsSelector(state);
    const filteredProductSuggestions   = productFilteredSuggestionsSelector(state);
    
    return secondarySelectedSuggestions.map(secondarySelectedSuggestion => {
        const suggestionsEntity = filteredProductSuggestions.find(filteredProductSuggestion => filteredProductSuggestion.key === secondarySelectedSuggestion);
        return suggestionsEntity;
    }).filter(o=>o);
}

export const changeMainProduct = (key:number): AppThunk => async (dispatch, getState) => {
    const productSuggestions = productSuggestionsSelector(getState());
    const currentProductSuggestion = productSuggestions.find(productSuggestion =>  productSuggestion.key === key);
    if(currentProductSuggestion){
        API.loadPMProducts({ filterFeedId: [currentProductSuggestion.feed_id],
                             limit: 1, 
                             searchStrategy: "standard", 
                             filterWithoutEmptyNames:true })
        .then(response => {
            dispatch(handleMainPmProductsResponse(response));
        }).catch(e => {

        }); 
    }
}

export const setProductPreviewModalData = (key:number|null): AppThunk => async (dispatch, getState) => {
    const productSuggestions = productSuggestionsSelector(getState());
    const currentProductSuggestion = productSuggestions.find(productSuggestion =>  productSuggestion.key === key);
    dispatch(productCreateSlice.actions.setProductPreviewModalProductSuggestion(currentProductSuggestion?currentProductSuggestion:null));
}

export const setProductPreviewModalCatalogData = (key:number|null): AppThunk => async (dispatch, getState) => {
    const catalogProducts = catalogProductSelector(getState());
    const currentCatalogProduct = catalogProducts.items.find(catalogProduct => catalogProduct.key === key );
    dispatch(productCreateSlice.actions.setProductPreviewModalProductCatalog(currentCatalogProduct));
}

export const setSelectedSuggestions = (newSelectedSuggestions:Array<number>): AppThunk => async (dispatch, getState) => {
    const selectedSuggestions = selectedSuggestionsSelector(getState());
    const productSuggestions =  productSuggestionsSelector(getState());
    const removedSelectedSuggestions = [...removedSelectedSuggestionsSelector(getState())];

    let differenceKeys = selectedSuggestions.filter(x => !newSelectedSuggestions.includes(x));
    let differenceFeedIds = productSuggestions.filter(productSuggestion => differenceKeys.includes(productSuggestion.key)).map(productSuggestion => productSuggestion.feed_id);
    
    for(const differenctFeedId of differenceFeedIds){
        removedSelectedSuggestions.push(differenctFeedId);
    }

    const newFeedIds = productSuggestions.filter(productSuggestion =>  newSelectedSuggestions.includes(productSuggestion.key)).map(productSuggestion => productSuggestion.feed_id);
    
    for(const newFeedId of newFeedIds) {
        var index = removedSelectedSuggestions.indexOf(newFeedId);
        if(index !== -1){
            removedSelectedSuggestions.splice(index, 1);
        }
    }
    
    dispatch(productCreateSlice.actions.setRemovedSelectedSuggestions(removedSelectedSuggestions));
    dispatch(productCreateSlice.actions.setSelectedSuggestions(newSelectedSuggestions));
}

export const setProductNamePart = (name:string,value:ProductNamePartInterface): AppThunk => async (dispatch, getState) => {
    dispatch(productCreateSlice.actions.setProductNamePart({name,value}));
}

export const setProductNamePartSeparator = (name:string,value:string): AppThunk => async (dispatch, getState) => {
    dispatch(productCreateSlice.actions.setProductNamePartSeparator({name,value}));
}


export const loadNextMainProduct = (): AppThunk => async (dispatch, getState) => {
    const mainProductOffset = mainProductOffsetSelector(getState());
    const nextMainProductOffset = mainProductOffset + 1;
    
    dispatch(productCreateSlice.actions.setMainProductOffset(nextMainProductOffset));
    dispatch(loadMainProduct());
}

export const skipProduct = (skipNumber:number): AppThunk => async (dispatch, getState) => {
    const mainProduct = mainProductSelector(getState());
    if(mainProduct){
        API.updatePmProducts({},[{
            feed_id: mainProduct.feed_id,
            skipped: skipNumber
        }
        ]).then(response => {
            dispatch(loadMainProduct());
        })
    }
}

export const skipSuggestionProducts = (skipNumber:number): AppThunk => async (dispatch, getState) => {
    let secondarySelectedSuggestions = getActiveSecondarySuggestionsEntities(getState());
    API.updatePmProducts({},
        secondarySelectedSuggestions.map(item => {
            return {
                feed_id: item.feed_id,
                skipped: skipNumber
            }
        })
    ).then(response => {
        dispatch(regenerate());
    })
}

export const setForceIds = (forceIds:Array<number>): AppThunk => async (dispatch, getState) => {
    API.loadPMProducts({filterFeedId:forceIds,searchStrategy: "standard",limit:50}).then(response => {
        const forcedPmProducts:Array<ForcedPmProducts> = response.pm_products.map(pmProduct => {
           return {
              feed_id: pmProduct.feed_id,
              name: pmProduct.name,
              shopName: pmProduct.shop && pmProduct.shop.length?pmProduct.shop[0].name:""
           }
        });
        dispatch(productCreateSlice.actions.setForcedPmProducts(forcedPmProducts));
    });
}

export const shopActiveFiltersSelector = (state: ApplicationState) => state.productCreate.activeFilters.shopSelected;
export const shopFiltersDataSelector = (state: ApplicationState) => state.productCreate.filtersData.shop.data;
export const shopFiltersDataIsFetching = (state: ApplicationState) => state.productCreate.filtersData.shop.isFetching;

export const manufacturerActiveFiltersSelector = (state: ApplicationState) => state.productCreate.activeFilters.manufacturerSelected;
export const manufacturerFiltersDataSelector = (state: ApplicationState) => state.productCreate.filtersData.manufacturer.data;
export const manufacturerFiltersDataIsFetching = (state: ApplicationState) => state.productCreate.filtersData.manufacturer.isFetching;

export const mainManufacturerActiveFiltersSelector = (state: ApplicationState) => state.productCreate.activeFilters.mainManufacturerSelected;
export const mainManufacturerFiltersDataSelector = (state: ApplicationState) => state.productCreate.filtersData.mainManufacturer.data;
export const mainManufacturerFiltersDataIsFetching = (state: ApplicationState) => state.productCreate.filtersData.mainManufacturer.isFetching;

export const categoryActiveFiltersSelector = (state: ApplicationState) => state.productCreate.activeFilters.categorySelected;
export const categoryFiltersDataSelector = (state: ApplicationState) => state.productCreate.filtersData.category.data;
export const categoryFiltersDataIsFetching = (state: ApplicationState) => state.productCreate.filtersData.category.isFetching;

export const mainCategoryActiveFiltersSelector = (state: ApplicationState) => state.productCreate.activeFilters.mainCategorySelected;
export const mainCategoryFiltersDataSelector = (state: ApplicationState) => state.productCreate.filtersData.mainCategory.data;
export const mainCategoryFiltersDataIsFetching = (state: ApplicationState) => state.productCreate.filtersData.mainCategory.isFetching;

export const changeManufacturerActiveFiltersSelector = (state: ApplicationState) => state.productCreate.activeFilters.changeManufacturerSelected;
export const changeManufacturerFiltersDataSelector = (state: ApplicationState) => state.productCreate.filtersData.changeManufacturer.data;
export const changeManufacturerFiltersDataIsFetching = (state: ApplicationState) => state.productCreate.filtersData.changeManufacturer.isFetching;

export const changeCategoryActiveFiltersSelector = (state: ApplicationState) => state.productCreate.activeFilters.changeCategorySelected;
export const changeCategoryFiltersDataSelector = (state: ApplicationState) => state.productCreate.filtersData.changeCategory.data;
export const changeCategoryFiltersDataIsFetching = (state: ApplicationState) => state.productCreate.filtersData.changeCategory.isFetching;

export const mainProductSelector = (state: ApplicationState) => state.productCreate.mainProduct;
export const catalogProductSelector = (state: ApplicationState) => state.productCreate.catalogProducts;
export const mainCategorySelector = (state: ApplicationState) => state.productCreate.activeFilters.mainCategorySelected;
export const mainProductOffsetSelector = (state: ApplicationState) => state.productCreate.mainProductOffset;

export const wordPhraseReplacementModalSelector = (state: ApplicationState) => state.productCreate.modals.wordPhraseReplacement;
export const wordSingleReplacementModalSelector = (state: ApplicationState) => state.productCreate.modals.wordSingleReplacement;
export const maskSettingsModalSelector = (state: ApplicationState) => state.productCreate.modals.maskSettings;
export const changeCategoryModalSelector = (state: ApplicationState) => state.productCreate.modals.changeCategory;
export const changeManufactureModalSelector = (state: ApplicationState) => state.productCreate.modals.changeManufacturer;
export const productPreviewModalSelector = (state: ApplicationState) => state.productCreate.modals.productPreview;
export const skipModalSelector = (state: ApplicationState) => state.productCreate.modals.skip;
export const skipSuggestionsModalSelector = (state: ApplicationState) => state.productCreate.modals.skipSuggestions;


export const wordSingleReplacementSelector = (state: ApplicationState) => state.productCreate.wordReplacements;
export const wordPhraseReplacementSelector = (state: ApplicationState) => state.productCreate.phraseReplacements;

export const productSuggestionsSelector = (state: ApplicationState) => state.productCreate.productSuggestions.data;
export const productFilteredSuggestionsSelector = (state: ApplicationState) => state.productCreate.productSuggestions.filteredData;
export const productSuggestionsIsFetchingSelector = (state: ApplicationState) => state.productCreate.productSuggestions.isFetching;

export const selectedSuggestionsSelector = (state: ApplicationState) => state.productCreate.selectedSuggestions;
export const secondarySelectedSuggestionsSelector = (state: ApplicationState) => state.productCreate.secondarySelectedSuggestions;
export const removedSelectedSuggestionsSelector = (state: ApplicationState) => state.productCreate.removedSelectedSuggestions;

export const eanActiveFilterSelector        = (state: ApplicationState) => state.productCreate.activeFilters.ean;
export const partNumberActiveFilterSelector = (state: ApplicationState) => state.productCreate.activeFilters.partNumber;

export const productNamePartsSelector = (state: ApplicationState) => state.productCreate.productNameParts;
export const productNamePartsSeparatorSelector = (state: ApplicationState) => state.productCreate.productNamePartsSeparators;
export const createButtonSelector = (state: ApplicationState) => state.productCreate.createButton;

export const forcedPmProductsSelector = (state: ApplicationState) => state.productCreate.forcedPmProducts;
export const forceAssignedProductsSelector = (state: ApplicationState) => state.productCreate.forceAssignedProducts;

export default productCreateSlice.reducer;