import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import App from "../containers/App/App";

export interface ProtectedRouteProps extends RouteProps {
    isAuthenticated: boolean;
    isAllowed: boolean;
    //restrictedPath: string;
    authenticationPath: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
    let redirectPath = '';

    if (!props.isAuthenticated) {
        redirectPath = props.authenticationPath;
    }
    // if (props.isAuthenticated && !props.isAllowed) {
    //     redirectPath = props.restrictedPath;
    // }

    if (redirectPath) {
        return (
            <Route {...props} component={() => (
              <Redirect to={{ pathname: redirectPath }} />
            )} render={undefined} />
        );
    } else {
        return <App/>;
    }
};

export default ProtectedRoute;
