import { Button, PageHeader } from 'antd';
import React from 'react';
import styled from 'styled-components';
import ProductSelect from '../../components/Select/ProductSelect';
import { useDispatch, useSelector} from "react-redux";
import { loadMergeProduct, mergeProducts, product1Selector, product2Selector } from './slice';
import { ApplicationState } from '../../store';
import { rem } from 'polished';
import { __ } from '../../utilities';

const MainContent = styled.div`
  margin-top: 25px;
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
`

const HalfContent = styled.div`
  width: 50%; 
  background: #ffffff;
`

const ProductWrapper = styled.div`
  margin-top: ${rem(25)}
`

const MergeProducts = () => {

    const dispatch = useDispatch();

    const onSelectProduct = (product_id:number,type:number) => {
       dispatch(loadMergeProduct(product_id,type));
    }

    const product1 = useSelector((state: ApplicationState) =>
        product1Selector(state)
    );

    const product2 = useSelector((state: ApplicationState) =>
        product2Selector(state)
    );

    const renderProductData = (product) => {
        return <table>
            <tr>
                <td colSpan={2}> <img alt={""} src={product.image_link}/> </td>
            </tr>
            <tr>
                <td> {__("Názov")}: </td> <td> {product.name} </td>
            </tr> 
        </table>
    }

    const onMergeClick = () => {
        dispatch(mergeProducts());
    }

    return <>
            <PageHeader 
                title = {"Zlučovanie produktov"}
                ghost={false}
                extra={[
                    <Button key="saveButton" disabled={product1 && product1?false:true}  onClick={onMergeClick}> 
                        {__("Zlúčiť")}
                    </Button>,
                ]}
            > 
            </PageHeader>
            <MainContent>
                <HalfContent>
                    <ProductSelect onSelect = {(product_id) => onSelectProduct(product_id,1) }/>
                    {product1 && 
                        <ProductWrapper>
                            <b> Zlučovaný produkt </b>
                            {renderProductData(product1)}
                        </ProductWrapper>
                    }
                </HalfContent>
                <HalfContent>
                    <ProductSelect onSelect = {(product_id) => onSelectProduct(product_id,2) }/>
                    {product2 && 
                        <ProductWrapper>
                            <b> Produkt do ktorého sa zlučuje </b>
                            {renderProductData(product2)}
                        </ProductWrapper>
                    }
                </HalfContent>
            </MainContent>
    </>
};

export default MergeProducts;