import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, ApplicationState } from "../../store";
import API from "../../services/API";
import { getValuesFromArray } from "../../utilities";
import { push } from "connected-react-router";

const NAMESPACE = 'parameterList';

interface Parameter {
    key: string,
    _id: string,
    name: string,
    unit: string,
    index: boolean, 
    show: boolean, 
    expand: boolean,
    products_count: number
}

interface ParameterListState {
    activeFilters: {
        name: string,
        category: { value: number}[],
        show: boolean,
        index: boolean
    },
    filtersData: {
        category: {
            isFetching: boolean,
            data: any
        }
    },
    filterResults: {
        offset: number
        isFetching: boolean,
        total: number;
        parameters: Parameter[];
    },
    pagination: {
        pageSize: number;
        current: number
    },
    sorterData: {
        type: string,
        order: string;
    },
}

const initialState : ParameterListState = {
    activeFilters: {
        name: "",
        category: [],
        show: null,
        index: null
    },
    filtersData: {
        category: {
            isFetching: false,
            data: []
        }
    },
    filterResults: {
        isFetching: false,
        total: 0,
        offset: 0,
        parameters: []
    },
    pagination: {
        pageSize: 10,
        current: 1
    },
    sorterData: {
        type: null,
        order: 'asc'
    },
}

export const parameterListSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setFilterResults: (state, action: PayloadAction<{total: number, parameters: Array<Parameter>}>) => {
            state.filterResults.total       = action.payload.total;
            state.filterResults.parameters = action.payload.parameters;
        },
        setIsFetchingFilterResults: (state, action: PayloadAction<{value: boolean}>) => {
            state.filterResults.isFetching = action.payload.value
        },
        setPagination: (state, action: PayloadAction<{current: number}>) => {
            state.pagination.current = action.payload.current
        },
        setSorterData: (state, action: PayloadAction<{type: string, order: string}>) => {
            state.sorterData.type = action.payload.type;
            state.sorterData.order = action.payload.order;
        },
        setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | number | null | boolean}>) => {
            state.activeFilters[action.payload.name] = action.payload.value;
        },
        setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },
        setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
    }
});

export const setSorter = (field: string, orderName: string) => (dispatch, getState) => {
    const type:string  = orderName?field:undefined;
    const order:string = orderName === 'descend'?'desc':'asc';
    
    dispatch(parameterListSlice.actions.setSorterData({ type: type, order:order}));
}

export const loadParameters = () : AppThunk => async (dispatch, getState) => {
    const offset = (getState().parameterList.pagination.current-1) * getState().parameterList.pagination.pageSize;
    const categoryIds = getValuesFromArray(categoryActiveFiltersSelector(getState()));
    const name = activeFilterNameSelector(getState());
    const show = activeFilterShowSelector(getState());
    const index = activeFilterIndexSelector(getState());

    dispatch(parameterListSlice.actions.setIsFetchingFilterResults({value:true}));
    API.loadParameters({
        limit: getState().parameterList.pagination.pageSize,
        offset: offset,
        sortType: getState().parameterList.sorterData.type?getOrderTypeFromField(getState().parameterList.sorterData.type):undefined,
        sortOrder:  getState().parameterList.sorterData.order,
        languageId: 1,
        filterInternalName: name?name:undefined, 
        filterCategoryIds: categoryIds && categoryIds.length?categoryIds:undefined,
        filterIndex: index !==null?index:undefined,
        filterShow: show !== null?show:undefined
    }).then(response => {
        const parameters: Parameter[] = response.items.map(item => {
            let translation = null;
            if(item.translations && item.translations.length){
                translation = item.translations.find(i => i.language_id === 1);
                if(!translation){
                    translation = item.translations[0]
                }
            }
             
            return {
                _id: item._id,
                key: item._id,
                name: item.internal_name,
                unit: translation?translation.unit:"",
                products_count: item.products_count,
                index: item.index,
                expand: item.expand,
                show: item.show
            }
        })
        
        dispatch(parameterListSlice.actions.setFilterResults({total:response.total,parameters}));
        dispatch(parameterListSlice.actions.setIsFetchingFilterResults({value:false}));
    });
}

export const loadCategoryFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterListSlice.actions.setIsFetchingFilterData({name: 'category', value: true}));
  
    API.loadCategories({query:value,limit:100}).then(response => {
      dispatch(parameterListSlice.actions.setFilterData({name: 'category', value: 
        response.categories.map( category => {
   
          let categoryName;
          if(category && category.translations && category.translations.length){
              const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
              if(categoryTranslation){
                  categoryName = categoryTranslation.name;
              }
          }
  
          return {
            name: category.parents_names ? [...category.parents_names].reverse().filter((item, index) => index % 2 === 0).join(' < ') : categoryName,
            value: category.category_id
          }
        })
      }));
      
      dispatch(parameterListSlice.actions.setIsFetchingFilterData({name: 'category', value: false}));
    });
}

export const setPagination = (current: number) => (dispatch, getState) => {
    dispatch(parameterListSlice.actions.setPagination({ current:current}));
}

const getOrderTypeFromField = (field:string):string => {
    if(field === 'name') return 'internal_name';
    else if(field === 'unit') return 'current_translation.unit'
    return field
}

export const setFilter = (filter: string, value: number[] | string | number | null | boolean) => (dispatch, getState) => {
    dispatch(parameterListSlice.actions.setActiveFilterValue({name: filter, value}));
    dispatch(loadParameters());
}

export const onCreate = () => (dispatch, getState) => {
    API.createParameter({}).then(response => {
        dispatch(push(`/parameter/detail/${response.parameter._id}`));
        window.location.reload();
    })
}


export const parametersSelector = (state: ApplicationState) => state.parameterList.filterResults.parameters;
export const totalSelector = (state: ApplicationState) => state.parameterList.filterResults.total;
export const paginationSelector = (state: ApplicationState) => state.parameterList.pagination;

export const categoryActiveFiltersSelector = (state: ApplicationState) => state.parameterList.activeFilters.category;
export const categoryFiltersDataSelector = (state: ApplicationState) => state.parameterList.filtersData.category.data;
export const categoryFiltersDataIsFetching = (state: ApplicationState) => state.parameterList.filtersData.category.isFetching;

export const activeFilterShowSelector = (state: ApplicationState) => state.parameterList.activeFilters.show;
export const activeFilterIndexSelector = (state: ApplicationState) => state.parameterList.activeFilters.index;
export const activeFilterNameSelector = (state: ApplicationState) => state.parameterList.activeFilters.name;

export default parameterListSlice.reducer;







