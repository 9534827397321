import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState, AppThunk } from "../../store";
import API from "../../services/API";

const NAMESPACE = 'categoryTree';

interface TreeNode {
    key: string | number,
    title: string,
    isLeaf: boolean,
    children: TreeNode[],
    parameters: any []
}

interface CategoryTreeState {
    categoryTree: TreeNode[];
    localeId: number;
}

const initialState : CategoryTreeState = {
    categoryTree: [],
    localeId:1
}

export const categoryTreeSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setTopCategories: (state, action: PayloadAction<TreeNode[]>) => {
            state.categoryTree = action.payload
        },
        setChildCategories: (state, action: PayloadAction<{parentId:string | number,children: TreeNode[]}>) => {
            const categoryTree = state.categoryTree;
            const treeNode = findInTree(categoryTree,action.payload.parentId);
            if(treeNode){
                treeNode.children = action.payload.children;
            }

            function findInTree(categoryTree:TreeNode[],parentId):TreeNode {
                for(const node of categoryTree){
                    if(node.key === parentId) return node;
                    const childNode = findInTree(node.children,parentId);
                    if(childNode) return childNode;
                }
                return null;
            }
        },
        setLocaleId: (state, action: PayloadAction<number>) => {
            state.localeId = action.payload
        },
    }
});

export const loadTopCategories = () : AppThunk => async (dispatch, getState) => {
    API.loadCategoriesTree({maxDepth:1}).then(response => {
        const treeNodes:TreeNode[] = response.categories.map(category => {
            let categoryName;
            if(category && category.translations && category.translations.length){
                const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
                if(categoryTranslation){
                    categoryName = categoryTranslation.name;
                }
            }

            const parameterCount = category.parameters?category.parameters.length:0

            return {
                key: category.category_id,
                title: categoryName+`(${parameterCount})`,
                isLeaf: category.children && category.children.length?false:true,
                children: [],
                parameters: category.parameters?category.parameters:[]
            }
        })

        treeNodes.sort((a, b) => a.title.localeCompare(b.title));

        dispatch(categoryTreeSlice.actions.setTopCategories(treeNodes));
    });
}

export const loadChildrenCategories = (parentId:string | number) : AppThunk => async (dispatch, getState) => {
    const response = await API.loadCategoriesTree({parentId:parentId as number,maxDepth:1})

    const treeNodes:TreeNode[] = response.categories.map(category => {
        let categoryName;
        if(category && category.translations && category.translations.length){
            const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
            if(categoryTranslation){
                categoryName = categoryTranslation.name;
            }
        }

        const parameterCount = category.parameters?category.parameters.length:0

        return {
            key: category.category_id,
            title: categoryName+`(${parameterCount})`,
            isLeaf: category.children && category.children.length?false:true,
            children: [],
            parameters: category.parameters?category.parameters:[]
        }
    })

    treeNodes.sort((a, b) => a.title.localeCompare(b.title));

    dispatch(categoryTreeSlice.actions.setChildCategories({parentId:parentId,children:treeNodes}));
}

export const setLocaleId = (localeId:number): AppThunk => async (dispatch, getState) => {
    dispatch(categoryTreeSlice.actions.setLocaleId(localeId));
}


export const categoryTreeSelector = (state: ApplicationState) => state.categoryTree.categoryTree;
export const localeIdSelector = (state: ApplicationState) => state.categoryTree.localeId;

export default categoryTreeSlice.reducer;







