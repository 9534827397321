import React, { useState, useEffect, useRef } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import ResizeObserver from 'rc-resize-observer';
import classNames from 'classnames';
import { Table, Checkbox } from 'antd';
import styled from 'styled-components';


const TableCell = styled.div`
  margin: 10px;
  display: flex;
  justify-content: center;
`

export function VirtualTable(props) {
  const { columns, scroll, className } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const widthColumnCount = columns.filter(({ width }) => !width).length+1;
  let mergedColumns = columns.map(column => {
    if (column.width) {
      return column;
    }

    return { ...column, width: Math.floor(tableWidth / widthColumnCount) };
  });

  if(props.customCheckboxes){
    mergedColumns.unshift({
      title: "Akcia",
      dataIndex: "checkbox",
      width: 100,
      type: "checkbox"
    });
  }

  const gridRef = useRef();
  
  const [connectObject] = useState(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => null,
      set: scrollLeft => {
        const current:any = gridRef.current;
        if(current){
          current.scrollTo({
            scrollLeft
          })
        }
      },
    });
    return obj;
  });

  const resetVirtualGrid = () => {
    const current:any = gridRef.current;
    if(current){
      current.resetAfterIndices({
          columnIndex: 0,
          shouldForceUpdate: false,
      });
    }
  };

  useEffect(() => resetVirtualGrid, []);
  useEffect(() => resetVirtualGrid, [tableWidth]);

  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    ref.current = connectObject;

      

    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={mergedColumns.length}
        columnWidth={index => {
          const { width } = mergedColumns[index];
          return index === mergedColumns.length - 1 ? width - scrollbarSize - 1 : width;
        }}
        height={scroll.y}
        rowCount={rawData.length}
        rowHeight={() => 70}
        width={tableWidth}
        onScroll={({ scrollLeft }) => {
          onScroll({
            scrollLeft,
          });
        }}
      > 
        {({ columnIndex, rowIndex, style }) => (
          <>
            { mergedColumns[columnIndex].type === 'checkbox'
                ? <TableCell style={style}> 
                     <Checkbox checked={ props.customCheckboxes.selectedValues.includes(rawData[rowIndex][props.customCheckboxes.dataColumn])?true:false}  onChange={ (value) => { props.customCheckboxes.onChange(value,rawData[rowIndex])} } />
                  </TableCell> 
                : <TableCell
                    className={classNames('virtual-table-cell', {
                      'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
                    })}
                    style={style}
                  > 
                    {
                      mergedColumns[columnIndex].render? 
                        <div> { mergedColumns[columnIndex].render(rawData[rowIndex][mergedColumns[columnIndex].dataIndex])  } </div>
                      : rawData[rowIndex][mergedColumns[columnIndex].dataIndex]
        
                    }
                  </TableCell> 
            }
          </>  
        )}
      </Grid>
    );
  };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <Table
        {...props}
        className={classNames(className, 'virtual-table')}
        columns={mergedColumns}
        pagination={false}
        components={{
          body: renderVirtualList,
        }}
      />
    </ResizeObserver>
  );
} // Usage