import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, ApplicationState } from "../../store";
import API from "../../services/API";
import { formatDate, FORMAT_HUMANE_DATETIME } from "../../utilities";
import { getValuesFromArray } from "../../utilities";
import moment from 'moment';
import { tokenSelector } from "../App/slice";

const NAMESPACE = 'feedReportList';

export interface ListingFeedReport {
    createdAt: string;
    shopName: string;
    shopId: number;
    parsedProductCount: number;
    allErrorsCount: number;
    errors: Array<string>;
    key: string
}

interface FeedReportListState {
    activeFilters: {
        shopSelected: {value: number}[],
        onlyWithErrors: boolean,
    },
    filtersData: {
        shop: {
            isFetching: boolean,
            data: any
        }
    },
    sorterData: {
        type: string,
        order: string;
    },
    filterResults: {
        offset: number
        isFetching: boolean,
        total: number;
        feedReports: ListingFeedReport[];
    },
    pagination: {
        pageSize: number;
        current: number
    }
}

const initialState : FeedReportListState = {
    activeFilters: {
        shopSelected: [],
        onlyWithErrors: false,
    },
    filtersData: {
        shop: {
            isFetching: false,
            data: []
          }
    },
    sorterData: {
        type: null,
        order: 'asc'
    },
    filterResults: {
        isFetching: false,
        total: 0,
        offset: 0,
        feedReports: []
    },
    pagination: {
        pageSize: 20,
        current: 1
    }
}

export const feedReportListSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setIsFetchingFilterResults: (state, action: PayloadAction<{value: boolean}>) => {
            state.filterResults.isFetching = action.payload.value
        },
        setFilterResults: (state, action: PayloadAction<{total: number, feedReports: Array<ListingFeedReport>}>) => {
            state.filterResults.total       = action.payload.total;
            state.filterResults.feedReports = action.payload.feedReports;
        },
        setPagination: (state, action: PayloadAction<{current: number}>) => {
            state.pagination.current = action.payload.current
        },
        setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },
        setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
        setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | number | null}>) => {
            state.activeFilters[action.payload.name] = action.payload.value;
        },
    }
});


export const loadFeedReports = () : AppThunk => async (dispatch, getState) => {
    dispatch(feedReportListSlice.actions.setIsFetchingFilterResults({value:true}));
    
    const token = tokenSelector(getState());
    API.setToken(token);

    const offset = (getState().feedReportList.pagination.current-1) * getState().feedReportList.pagination.pageSize;
    const filterShopIds = getValuesFromArray<number>(shopActiveFiltersSelector(getState()));
    const onlyWithErrors = onlyWithErrorsSelector(getState());
    
    let errorsCountFrom = undefined;
    let errorsCountTo = undefined;
    
    if(onlyWithErrors){
        errorsCountFrom = 1;
        errorsCountTo = undefined;
    }

    API.loadFeedReport({
        withShop: true,
        limit: 20,
        offset: offset,
        shopIds: filterShopIds && filterShopIds.length?filterShopIds:undefined,
        errorsCountFrom: errorsCountFrom,
        errorsCountTo: errorsCountTo
    }).then(response => {
        const listingFeedReports: Array<ListingFeedReport> = response.feed_parser_reports.map(
            feed_parser_report => {
                return {
                    createdAt:  formatDate(moment(feed_parser_report.created_at),FORMAT_HUMANE_DATETIME),
                    shopName: feed_parser_report.shop.name +` (${feed_parser_report.shop.program_id})`,
                    shopId: feed_parser_report.shop.shop_id,
                    parsedProductCount: feed_parser_report.parsed_product_count,
                    allErrorsCount: feed_parser_report.all_errors_count,
                    key: feed_parser_report._id,
                    errors: feed_parser_report.errors.map(error => {
                        return error.message;
                    })
                }
            }
        );

        console.log("FEED PARSER REPORT");
        console.log(listingFeedReports);

        dispatch(feedReportListSlice.actions.setIsFetchingFilterResults({value:false}));
        dispatch(
            feedReportListSlice.actions.setFilterResults({
                total: 1000,
                feedReports: listingFeedReports,
            })
        );
    });
}

export const loadShopFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(feedReportListSlice.actions.setIsFetchingFilterData({name: 'shop', value: true}));

    API.loadShops({query:value,limit:100}).then(response => {
      dispatch(feedReportListSlice.actions.setFilterData({name: 'shop', value: 
      response.shops.map(shop => {
          return {
            name: shop.name+` (${shop.program_id})`,
            value: shop.shop_id
          }
        })
      }));
      
      dispatch(feedReportListSlice.actions.setIsFetchingFilterData({name: 'shop', value: false}));
    })  
}

export const startManualFeed = (shopId) : AppThunk => async (dispatch, getState) => {
    await API.saveShopSettings(shopId,{},{next_parse_time:"2000-01-01"});
}

export const setPagination = (current: number) => (dispatch, getState) => {
    dispatch(feedReportListSlice.actions.setPagination({ current:current}));
}

export const setFilter = (filter: string, value: number[] | string | number | null) => (dispatch, getState) => {
    dispatch(feedReportListSlice.actions.setActiveFilterValue({name: filter, value}));
}

export const paginationSelector                = (state: ApplicationState) => state.feedReportList.pagination;
export const filterResultsTotalSelector        = (state: ApplicationState) => state.feedReportList.filterResults.total;
export const filterResultsIsFetchingSelector   = (state: ApplicationState) => state.feedReportList.filterResults.isFetching;
export const filterResultsOffsetSelector       = (state: ApplicationState) => state.feedReportList.filterResults.offset;
export const filterResultsFeedReportsSelector  = (state: ApplicationState) => state.feedReportList.filterResults.feedReports;

export const shopActiveFiltersSelector = (state: ApplicationState) => state.feedReportList.activeFilters.shopSelected;
export const shopFiltersDataSelector = (state: ApplicationState) => state.feedReportList.filtersData.shop.data;
export const shopFiltersDataIsFetching = (state: ApplicationState) => state.feedReportList.filtersData.shop.isFetching;

export const onlyWithErrorsSelector = (state: ApplicationState) => state.feedReportList.activeFilters.onlyWithErrors;

export default feedReportListSlice.reducer;







