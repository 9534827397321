import { DatePicker, PageHeader, Select, Table, Tooltip, TreeSelect } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { __ } from '../../utilities';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/sk_SK';
import {
  setFilter,
  startDateActiveFiltersSelector,
  endDateActiveFiltersSelector,
  loadCategories,
  categoryListSelector,
  categoryListIsFethingSelector,
  paginationSelector,
  totalSelector,
  setPagination,
  setSorter,
  loadGeneralStats,
  loadGeneralDailyStats,
  generalStatsSelector,
  generalStatsFethingSelector,
  generalDailyStatsSelector,
  loadTopCategories,
  loadChildrenCategories,
  categoryTreeSelector,
  sourceActiveFiltersSelector
} from './slice';
import { ApplicationState } from '../../store';
import CategoryListCharts from '../../components/Category/CategoryListCharts';

const MainContent = styled.div`
  margin-top: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;
const StatsSection = styled.div`
  margin-top: 20px;
`;

const CategoryList = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function init() {
      dispatch(loadCategories());
      dispatch(loadGeneralStats());
      dispatch(loadGeneralDailyStats());
      dispatch(loadTopCategories());
    }
    init();
  }, [dispatch]);

  const loadData = () => {
    dispatch(loadCategories());
    dispatch(loadGeneralStats());
    dispatch(loadGeneralDailyStats());
  };

  const countryData = [
    { name: 'SK', value: 1 },
    { name: 'CZ', value: 2 },
  ];
  const sources = [
    { name: 'Pricemania', value: 1 },
    { name: 'PCN', value: 2 },
  ];
  const categories = useSelector((state: ApplicationState) =>
    categoryListSelector(state)
  );
  const categoriesIsFetching = useSelector((state: ApplicationState) =>
    categoryListIsFethingSelector(state)
  );
  const pagination = useSelector((state: ApplicationState) =>
    paginationSelector(state)
  );
  const total = useSelector((state: ApplicationState) => totalSelector(state));

  const onChangeTable = async (pagination, filters, sorters) => {
    dispatch(setPagination(pagination.current, pagination.pageSize));
    dispatch(setSorter(sorters.field, sorters.order));
    dispatch(loadCategories());
  };

  const onDateRangeChange = value => {
    if (value) {
      dispatch(setFilter('startDate', value[0]));
      dispatch(setFilter('endDate', value[1]));
    } else {
      dispatch(setFilter('startDate', null));
      dispatch(setFilter('endDate', null));
    }
    loadData();
  };

  const startDate = useSelector((state: ApplicationState) =>
    startDateActiveFiltersSelector(state)
  );

  const endDate = useSelector((state: ApplicationState) =>
    endDateActiveFiltersSelector(state)
  );

  const generalStatsFething = useSelector((state: ApplicationState) =>
    generalStatsFethingSelector(state)
  );
  const generalDailyStats = useSelector((state: ApplicationState) =>
    generalDailyStatsSelector(state)
  );
  const generalStats = useSelector((state: ApplicationState) =>
    generalStatsSelector(state)
  );
  const categoryTree = useSelector((state: ApplicationState) =>
    categoryTreeSelector(state)
  );
  const onLoadTreeData = async treeNode => {
    await dispatch(loadChildrenCategories(treeNode.key));
  };
  const onSelectCategory = selectedKeys => {
    dispatch(setFilter('category', selectedKeys));
    loadData();
  };
  const onSelectCountryFilter = async value => {
    dispatch(setFilter('country', value));
    loadData();
  };

  const onSourceChange = async value => {
    dispatch(setFilter('source', value));
    loadData();
  };
  const selectedSource = useSelector((state: ApplicationState) =>
    sourceActiveFiltersSelector(state)
  );

  return (
    <>
      <PageHeader
        title={'Kategórie'}
        ghost={false}
        extra={[
          <TreeSelect
            key={'category'}
            multiple
            showSearch={false}
            style={{ width: 300 }}
            dropdownStyle={{ maxHeight: 900, overflow: 'auto' }}
            placeholder="Kategorie"
            onChange={onSelectCategory}
            loadData={onLoadTreeData}
            treeData={categoryTree}
          />,
          <Select
          key={'Krajina'}
          showSearch
          style={{ width: 100 }}
          placeholder="Krajina"
          optionFilterProp="children"
          onChange={onSelectCountryFilter}
        >
          <Select.Option key={'X'} value={0}>
            Všetky
          </Select.Option>
          {countryData.map(country => {
            return (
              <Select.Option value={country.value} key={country.value}>
                {country.name}
              </Select.Option>
            );
          })}
        </Select>,
           <Select
           key={'Zdroj'}
           showSearch
           style={{ width: 100 }}
           placeholder="Zdroj"
           optionFilterProp="children"
           onChange={onSourceChange}
           value={selectedSource}
         >
           <Select.Option key={"X"} value={0}>
             Všetko
           </Select.Option>
           {sources &&
             sources.map(source => {
               return (
                 <Select.Option value={source.value} key={source.value}>
                   {source.name}
                 </Select.Option>
               );
             })}
         </Select>,
          <DatePicker.RangePicker
            key={'Date'}
            locale={locale}
            ranges={{
              Dnes: [moment(), moment()],
              Včera: [
                moment().subtract(1, 'days'),
                moment().subtract(1, 'days'),
              ],
              'Tento mesiac': [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
              'Minulý mesiac': [
                moment()
                  .subtract(1, 'months')
                  .startOf('month'),
                moment()
                  .subtract(1, 'months')
                  .endOf('month'),
              ],
              //'Posledných 7 dní': [moment().subtract(7, "days"), moment()],
            }}
            value={[startDate, endDate]}
            onChange={onDateRangeChange}
            format={'DD.MM.YYYY'}
          />,
        ]}
      />

      <StatsSection>
        <CategoryListCharts
          generalStatsFething={generalStatsFething}
          generalDailyStats={generalDailyStats}
          generalStats={generalStats}
        />
      </StatsSection>
      <MainContent>
        <Table
          loading={categoriesIsFetching}
          columns={[
            {
              title: __('Nazov'),
              dataIndex: 'name',
              sorter: true,
            },
            {
              title: __('Impresie'),
              dataIndex: 'impressions',
              sorter: true,
            },
            {
              title: __('Prekliky'),
              dataIndex: 'clicks',
              sorter: true,
            },
            {
              title: __('CTR'),
              dataIndex: 'ctr',
            },
            {
              title: __('Obrat'),
              dataIndex: 'spend',
              sorter: true,
              render: (value, data) => {
                return `${value} €`;
              },
            },
            {
              title: __('CPC'),
              dataIndex: 'cpc',
              sorter: true,
              render: (value) => {
                return `${(value).toFixed(2)} €`
              }
            },
            {
              title: __('Produkty'),
              dataIndex: 'total_products',
              sorter: true,
            },
            {
              title: __('Ponuky'),
              dataIndex: 'total_offers',
              sorter: true,
              render: (value, data) => { 
                return (<Tooltip title="Počet ponúk (počet eshopov)"><span>{value} ({ data.total_shops}) </span></Tooltip>);
              },
            },
            {
              title: __('Priemer'),
              dataIndex: 'avg_offers_to_product',
              sorter: true,
              render: (value,data) => {
                const avg = parseInt((data.total_products / data.total_shops).toFixed(2));
                const a = avg ? avg : 0
                return (<Tooltip title="Priemerný počet ponúk na produkt (ponúk na eshop)"><span>{value} ({a})</span></Tooltip>);
              }
            },
          ]}
          dataSource={categories}
          pagination={{
            total: total,
            current: pagination.current,
            pageSize: pagination.pageSize,
          }}
          onChange={(pagination, filters, sorters) => {
            onChangeTable(pagination, filters, sorters);
          }}
        />
      </MainContent>
    </>
  );
};

export default CategoryList;
