import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, ApplicationState } from "../../store";
import API from "../../services/API";

const NAMESPACE = 'affilStatsPublishers';

interface GeneralPublisherStats {
    affiliate_name: string,
    affiliate_id: number,
    count: number,
    impressions: number,
    clicks: number,
    payment: number,
    ctr: number
}

interface AffilStatsState {
    activeFilters: {
        startDate: moment.Moment;
        endDate: moment.Moment;
    },
    generalPublishersStats: {
       isFetching: boolean,
       data: Array<GeneralPublisherStats>
    },
}

const initialState : AffilStatsState = {
    activeFilters: {
        startDate: null,
        endDate: null,
    },
    generalPublishersStats: {
        isFetching: false,
        data: null
    },
}

export const affilStatsPublishersSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | string[] | number | null | moment.Moment}>) => {
            state.activeFilters[action.payload.name] = action.payload.value;
        },
        setGeneralPublishersStats:(state, action:PayloadAction<Array<GeneralPublisherStats>>) => {
            state.generalPublishersStats.data = action.payload
        },
        setGeneralPublishersStatsFething: (state, action: PayloadAction<boolean> ) => {
            state.generalPublishersStats.isFetching = action.payload
        },
        resetState: state => initialState
    }
});

export const setFilter = (filter: string, value: number[] | string | number | null) => (dispatch, getState) => {
    dispatch(affilStatsPublishersSlice.actions.setActiveFilterValue({name: filter, value}));
}

export const loadGeneralPublishersStats = () : AppThunk => async (dispatch, getState) => {
    
    dispatch(affilStatsPublishersSlice.actions.setGeneralPublishersStatsFething(true));       
    const token = tokenSelector(getState());
    API.setToken(token);

    const startDate = startDateActiveFiltersSelector(getState());
    const endDate = endDateActiveFiltersSelector(getState());

    API.loadWidgetGeneralAffiliatesStats({
        startDate: startDate?startDate.format('YYYY-MM-DD'):undefined,
        endDate: endDate?endDate.format('YYYY-MM-DD'):undefined,
    }).then(response => {
        const data:Array<GeneralPublisherStats> = response.items.map(item => {
            return {
               affiliate_id: item.affiliate_id,
               affiliate_name: item.affiliate_name,
               clicks: item.clicks,
               count: item.count,
               impressions: item.impressions,
               payment: Math.round((item.payment + Number.EPSILON) * 100) / 100,
               ctr: Math.round(((item.impressions?(item.clicks/item.impressions)*100:0) + Number.EPSILON) * 100) / 100,
            }
        })

        dispatch(affilStatsPublishersSlice.actions.setGeneralPublishersStats(data));       
        dispatch(affilStatsPublishersSlice.actions.setGeneralPublishersStatsFething(false));       
    });
}

export const startDateActiveFiltersSelector = (state: ApplicationState) => state.affilStatsPublishers.activeFilters.startDate;
export const endDateActiveFiltersSelector = (state: ApplicationState) => state.affilStatsPublishers.activeFilters.endDate;

export const tokenSelector = (state: ApplicationState) => state.general.token;
export const userRoleSelector  = (state: ApplicationState) => state.general.tokeninfo.role;

export const generalPublishersStatsSelector = (state: ApplicationState) => state.affilStatsPublishers.generalPublishersStats.data
export const generalPublishersStatsFethingSelector = (state: ApplicationState) => state.affilStatsPublishers.generalPublishersStats.isFetching

export default affilStatsPublishersSlice.reducer;