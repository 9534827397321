import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, ApplicationState } from "../../store";
import API from "../../services/API";
import { message } from "antd";

const NAMESPACE = 'parameterFeedMapping';

interface Parameter {
    key: string, 
    name: string,
    value: string
}

interface FeedParameterMappingItem {
    _id: string,
    shop_parameter_name: string;
    parameter_id: string;
    parameter_name: string;
}

interface FeedParameterMapping {
    key: string,
    _id: string,
    shop_id: number,
    category_id: number,
    category_name: string,
    shop_name: string,
    mapping: FeedParameterMappingItem[]
}

interface ParameterFeedMappingState {
    activeFilters: {
        shopSelected: number,
        categorySelected: number,
    },
    filtersData: {
        shop: {
            isFetching: boolean,
            data: any
        },
        category: {
            isFetching: boolean,
            data: any
        }
    },
    filterResults: {
        offset: number,
        isFetching: boolean,
        total: number;
        feedParameterMappings: FeedParameterMapping[]
    },
    parameters: Parameter[],
    pagination: {
        currentPage: number
    },
}

const initialState : ParameterFeedMappingState = {
    activeFilters: {
        shopSelected: null,
        categorySelected: null,
    },
    filtersData: {
        shop: {
            isFetching: false,
            data: []
        },
        category: {
            isFetching: false,
            data: []
        }
    },
    filterResults: {
        offset: 0,
        isFetching: false,
        total: 0,
        feedParameterMappings: []
    },
    pagination: {
        currentPage: 1
    },
    parameters: []
}

export const parameterFeedMappingSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setIsFetchingFilterResults: (state, action: PayloadAction<{value: boolean}>) => {
            state.filterResults.isFetching = action.payload.value
        },
        setFilterResults: (state, action: PayloadAction<{total: number, feedParameterMappings: Array<FeedParameterMapping>}>) => {
            state.filterResults.total       = action.payload.total;
            state.filterResults.feedParameterMappings = action.payload.feedParameterMappings;
        },
        setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },
        setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
        setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | number | null}>) => {
            state.activeFilters[action.payload.name] = action.payload.value;
        },
        setParameters: (state, action: PayloadAction<Parameter[]>) => {
            state.parameters = action.payload;
        },
        updateMappingParameter: (state, action: PayloadAction<{feedParameterMappingId:string,mappingId:string,parameterId:string}>) => {
            const feedParameterMappings = state.filterResults.feedParameterMappings;
            const feedParameterMapping = feedParameterMappings.find(fpm => fpm._id === action.payload.feedParameterMappingId);
            if(feedParameterMapping){
                const mapping = feedParameterMapping.mapping.find(m => m._id === action.payload.mappingId);
                if(mapping){
                    mapping.parameter_id = action.payload.parameterId
                }
            }
        },
        setCurrentPage: (state, action: PayloadAction<number>) => {
            state.pagination.currentPage = action.payload
        }
    }
});

export const loadCategoryFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterFeedMappingSlice.actions.setIsFetchingFilterData({name: 'category', value: true}));
  
    API.loadCategories({query:value,limit:100}).then(response => {
      dispatch(parameterFeedMappingSlice.actions.setFilterData({name: 'category', value: 
        response.categories.map( category => {
   
          let categoryName;
          if(category && category.translations && category.translations.length){
              const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
              if(categoryTranslation){
                  categoryName = categoryTranslation.name;
              }
          }

          return {
            name: category.parents_names ? [...category.parents_names].reverse().filter((item, index) => index % 2 === 0).join(' < ') : categoryName,
            value: category.category_id
          }
        })
      }));
      
      dispatch(parameterFeedMappingSlice.actions.setIsFetchingFilterData({name: 'category', value: false}));
    });
}

export const loadShopFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterFeedMappingSlice.actions.setIsFetchingFilterData({name: 'shop', value: true}));

    API.loadShops({query:value,limit:100}).then(response => {
      dispatch(parameterFeedMappingSlice.actions.setFilterData({name: 'shop', value: 
      response.shops.map(shop => {
          return {
            name: shop.name+` (${shop.program_id})`,
            value: shop.shop_id
          }
        })
      }));
      
      dispatch(parameterFeedMappingSlice.actions.setIsFetchingFilterData({name: 'shop', value: false}));
    })  
}

export const setFilter = (filter: string, value: number[] | string | number | null) => (dispatch, getState) => {
    dispatch(parameterFeedMappingSlice.actions.setActiveFilterValue({name: filter, value}));
}

export const loadMappings = () : AppThunk => async (dispatch, getState) => {
    const currentPage = currentPageSelector(getState());

    dispatch(parameterFeedMappingSlice.actions.setIsFetchingFilterResults({value:true}));

    API.loadParameterFeedMappings({
       limit: 10,
       offset: (currentPage-1)*10
    }).then(async response => {

        const categoryIds = response.items.map(i => i.category_id).filter(o=>o);
        const shopIds = response.items.map(i => i.shop_id).filter(o=>o);
        const parameterIds = [];
        for(const item of response.items){
            if(item.mapping){
                for(const mapping of item.mapping){
                    if(mapping.parameter_id){
                        parameterIds.push(mapping.parameter_id);
                    }
                }
            }
        }

        const categories = await API.loadCategories({categoryIds:categoryIds,limit:1000});
        const shops = await API.loadShops({shopIds:shopIds});
        const parameters = await API.loadParameters({filterParameterIds:parameterIds});

        const feedParameterMappings: FeedParameterMapping[] = response.items.map(item => {

            const category = categories.categories.find(c => c.category_id === item.category_id);
            const shop = shops.shops.find(s => s.shop_id === item.shop_id);
            const parameter = parameters.items.find(i => i._id === item._id);

            let categoryName = "";
            if (
            category
            ) {
                const categoryTranslation = category.translations.find(
                    translation => translation.language_id === 1
                );
                if (categoryTranslation) {
                    categoryName = categoryTranslation.name;
                }
            }

            return {
                key: item._id,
                _id: item._id,
                category_id: item.category_id,
                shop_id: item.shop_id,
                category_name: categoryName,
                shop_name: shop?shop.name:"",
                mapping: item.mapping?item.mapping.map(mapping => {
                    return {
                        _id: mapping._id,
                        shop_parameter_name: mapping.shop_parameter_name,
                        parameter_id: mapping.parameter_id,
                        parameter_name: parameter?parameter.internal_name:null
                    }
                }):[]
            }
        })

        dispatch(parameterFeedMappingSlice.actions.setFilterResults({total: response.total, feedParameterMappings: feedParameterMappings }));
        dispatch(parameterFeedMappingSlice.actions.setIsFetchingFilterResults({value:false}));
    })
}

export const createMapping = () => (dispatch, getState) => {
    const filterShopId = shopActiveFiltersSelector(getState());
    const filterCategoryId = categoryActiveFiltersSelector(getState());
    
    if(filterShopId && filterCategoryId){
        API.createParameterMapping({},{
            shop_id: filterShopId,
            category_id: filterCategoryId
        }).then(result =>{
            console.log("===>");
        })
    }
}

export const loadParameters = () => (dispatch, getState) => {
    API.loadParameters({limit:10000}).then(response => {
        const parameters: Parameter[] = response.items.map(item => {
            return {
                key: item._id,
                name: item.internal_name,
                value: item._id
            }
        });

        dispatch(parameterFeedMappingSlice.actions.setParameters(parameters));
    })
}

export const changeMappingParameter = (feedParameterMappingId:string,mappingId:string,parameterId:string) => (dispatch, getState) => {
    dispatch(parameterFeedMappingSlice.actions.updateMappingParameter({feedParameterMappingId,mappingId,parameterId}));
}

export const onSave = (feedParameterMappingId:string) => (dispatch, getState) => {
    const parameterFeedMappings = parameterFeedMappingSelector(getState());
    const parameterFeedMapping = parameterFeedMappings.find(pfm => pfm._id === feedParameterMappingId);

    if(parameterFeedMapping){
        API.updateParameterFeedMappings(feedParameterMappingId,{},{
          mapping: parameterFeedMapping.mapping.map(m => {
              return {
                  _id: m._id,
                  parameter_id: m.parameter_id,
                  shop_parameter_name: m.shop_parameter_name
              }
          })
        })
    }
}

export const onRecalculate = (feedParameterMappingId:string, shopId:number) => async (dispatch, getState) => {
    await API.createScheduledTask({},{
        type: "RECALCULATE_FEED_PARAMETER_MAPPING",
        shop_id: shopId,
        data:{
            feed_parameter_mapping_id: feedParameterMappingId
        }
    });
    message.success('Úloha bola zadaná do systému');
}

export const setCurrentPage = (currentPage: number) => async (dispatch, getState) => {
    dispatch(parameterFeedMappingSlice.actions.setCurrentPage(currentPage))
}

export const shopActiveFiltersSelector = (state: ApplicationState) => state.parameterFeedMapping.activeFilters.shopSelected;
export const shopFiltersDataSelector = (state: ApplicationState) => state.parameterFeedMapping.filtersData.shop.data;
export const shopFiltersDataIsFetching = (state: ApplicationState) => state.parameterFeedMapping.filtersData.shop.isFetching;

export const categoryActiveFiltersSelector = (state: ApplicationState) => state.parameterFeedMapping.activeFilters.categorySelected;
export const categoryFiltersDataSelector = (state: ApplicationState) => state.parameterFeedMapping.filtersData.category.data;
export const categoryFiltersDataIsFetching = (state: ApplicationState) => state.parameterFeedMapping.filtersData.category.isFetching;

export const parameterFeedMappingSelector   = (state: ApplicationState) => state.parameterFeedMapping.filterResults.feedParameterMappings;
export const parametersSelector             = (state: ApplicationState) => state.parameterFeedMapping.parameters;
export const parameterFeedMappingIsFetching = (state: ApplicationState) => state.parameterFeedMapping.filterResults.isFetching;
export const parameterMappingTotalSelector  = (state: ApplicationState) => state.parameterFeedMapping.filterResults.total;

export const currentPageSelector = (state: ApplicationState) => state.parameterFeedMapping.pagination.currentPage;

export default parameterFeedMappingSlice.reducer;







