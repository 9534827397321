import React, {useState} from 'react';
import {
    Table, Popconfirm, Form, InputNumber, Input, Button
  } from 'antd';
import styled from 'styled-components';

const EditableTable = (props) => {

    const { columns, dataSource, onCreate, onUpdate, onDelete, defaultItem, loading, trans = {}, ebabledAddRowButton, rowSelection, showHeader } = props;

    const [editingKey, setEditingKey] = useState('');
    const [newRows, setNewRows] = useState([]);
    const [fullData, setFullData] = useState([]);

    React.useEffect(()=> {
        setFullData(dataSource.concat(newRows)); 
    },[dataSource,newRows])

    const isEditing = record => record.key === editingKey;
    const [form] = Form.useForm();

    const RowButton = styled(Button)`
        margin-right: 5px;
    `

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        renderEdit,
        rules,
        valuePropName,
        record,
        index,
        children,
        ...restProps
      }) => {
        let inputNode;
        
        if(renderEdit){
          inputNode = renderEdit();
        }
        else{
          switch(inputType) {
            case 'number':
              inputNode = <InputNumber />
              break; 
            default:
              inputNode = <Input />
          }
        }


        //const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

        return (
          <td {...restProps}>
            {editing ? (
              <Form.Item
                name={dataIndex}
                style={{
                  margin: 0,
                }}
                rules={rules}
                valuePropName= {valuePropName}
              >
                {inputNode}
              </Form.Item>
            ) : (
              children
            )}
          </td>
        );
      };

      const onSave = async key => {
            try { 
                const row = await form.validateFields();
                const newData = [...dataSource];
                
                const index = newData.findIndex(item => key === item.key);
                if (index > -1) {
                    const item = newData[index];
                    const newItem = { ...item, ...row };
                    newData.splice(index, 1, newItem );
                    onUpdate(newItem);
                    setEditingKey('');
                } else {
                    const row = await form.validateFields();
                    onCreate(row);
                    
                    setNewRows(
                        newRows.filter(newRow => newRow.key !== key)
                    );

                    setEditingKey('');
                }
            } 
            catch (errInfo) {
                console.log('Validate Failed:', errInfo);
            }
      };

      const onCancel = (record) => {
        const selectedNewRow = newRows.find((nr) => nr.key === record.key);
        if(selectedNewRow){
            setNewRows(
                newRows.filter(newRow => newRow.key !== selectedNewRow.key)
            )
        }

        setEditingKey('');
      };

      const onEdit = record => {
            form.setFieldsValue({
                name: '',
                age: '',
                address: '',
                ...record,
            }); 

            setEditingKey(record.key);
      };

      const onDeleteRow = record => {
          const selectedNewRow = newRows.find((nr) => nr.key === record.key);
          if(selectedNewRow){
              setNewRows(
                newRows.filter(newRow => newRow.key !== selectedNewRow.key)
              )
          }
          else{
            onDelete(record)
          }
      }

      const handleAdd = () => {
        let minRowId = 0;
        for(const newRow of newRows){
            if(newRow.key < minRowId){
                minRowId = newRow.key;
            }
        }
        minRowId -= 1;

        const newDefaultItem = { ...defaultItem, key: minRowId }
        const newNewRows = [ ...newRows, newDefaultItem];
        setNewRows(newNewRows);
        setEditingKey(newDefaultItem.key);
      };

      const mergedColumns = columns.map(col => {
        if(col.operations && col.operations.length) {
           col = {
               ...col,
               render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                <span>
                    <RowButton
                    onClick={() => onSave(record.key)}   
                    >
                      { trans.saveText?trans.saveText:"Save" }
                    </RowButton>
                    <Popconfirm okText={ trans.okPopConfirmText } cancelText={ trans.cancelPopConfirmText }  title= { trans.cancelConfirmText?trans.cancelConfirmText:"Sure to cancel?"} onConfirm={() => onCancel(record) }>
                        <RowButton> { trans.cancelText?trans.cancelText:"Cancel" } </RowButton>
                    </Popconfirm>

                </span>
                ) : (
                <>    
                    <RowButton onClick={() => onEdit(record)}>
                        { trans.editText?trans.editText:"Edit" }
                    </RowButton>
                    <Popconfirm okText={ trans.okPopConfirmText } cancelText={ trans.cancelPopConfirmText } title= { trans.deleteConfirmText?trans.deleteConfirmText:"Sure to Delete?" }  onConfirm={() => onDeleteRow(record)}>
                        <RowButton> { trans.deleteText?trans.deleteText:"Delete" } </RowButton>
                    </Popconfirm>
                </>
                );
            },
           }
        } 

        if (!col.editable) {
          return col;
        }
    
        return {
          ...col,
          onCell: record => ({
            record,
            inputType: col.inputType,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            renderEdit : col.renderEdit,
            rules: col.rules,
            valuePropName: col.valuePropName
          }),
        };
    });
   
    return  <Form form={form} component={false}>
        {ebabledAddRowButton !== false?
            <Button
              onClick={handleAdd}
              type="primary"
              style={{
                marginBottom: 16,
              }}
            > 
              { trans.addRowText?trans.addRowText:"Add a row" }
            </Button>
        :null}

        <Table 
            components={{
                body: {
                cell: EditableCell,
                },
            }}
            bordered
            columns = {mergedColumns}
            dataSource = { fullData }
            pagination= {false}
            showHeader = { showHeader }
            loading = {loading}
            rowSelection = {rowSelection}
        />
        </Form>
}

export default EditableTable;