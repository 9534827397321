import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  PageHeader,
  Table,
  Button,
  Modal,
  Col,
  Popover,
  Radio,
  Select,
} from 'antd';
import { ApplicationState } from '../../store';
import {
  widgetDataSelector,
  loadWidget,
  saveItemSort,
  saveNewItem,
  countryIdSelector,
  setCountryId,
  loadShopData,
  shopFiltersDataSelector,
  setFilterOnSelect,
  loadManufacturersData,
  manufacturerFiltersDataSelector,
  loadCategoryData,
  categoryrFiltersDataSelector
} from './slice';
import { useHistory } from 'react-router-dom';
import { prop } from '../../utilities';
import { MenuOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import ProductSelect from '../../components/Select/ProductSelect';

const MainContent = styled.div`
  margin-top: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;
const InfoText = styled(Col)`
  text-align: center;
  padding-bottom: 20px;
`;
const AddButtonStyledCol = styled(Col)`
  padding: 15px 25px 10px 25px;
  text-align: end;
  background: #fafafa;
`;
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableCon = SortableContainer(props => <tbody {...props} />);
const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
));

const CheckOutlinedStyled = styled(CheckOutlined)`
  visibility: visible;
`;

const SortableConStyled = styled(SortableCon)`
  background: #fafafa;
  border: 1px solid #ccc;
  > td {
    padding: 16px;
  }
`;
const DragVisible = styled.span`
  visibility: visible;
`;

const WebWidgetDetail = ({ id }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function init() {
      dispatch(loadWidget(id));
    }
    init();
  }, [dispatch, id]);

  const history = useHistory();
  function handleNavigation() {
    history.push('/web-widgets');
  }

  const widgetData = useSelector((state: ApplicationState) =>
    widgetDataSelector(state)
  );
  // const widgetIsFetching = useSelector((state: ApplicationState) =>
  //   widgetIsFetchingSelector(state)
  // );

  let updated = prop(widgetData, '0.updated', new Date());
  const date = new Date(updated);

  const widget_name = prop(widgetData, '0.widget_name', '');
  const widget_data = prop(widgetData, '0.widget_data', []);

  const onRemovePromotion = async value => {
    await dispatch(saveNewItem(value.key, id, 'DELETE'));
  };

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      render: () => (
        <DragVisible>
          {' '}
          <DragHandle />{' '}
        </DragVisible>
      ),
    },
    {
      title: 'Poradie',
      dataIndex: 'index',
      width: 30,
      align: 'center' as 'center',
      render: data => {
        const index = data + 1;
        return <DragVisible> {index} </DragVisible>;
      },
    },
    {
      title: 'Názov',
      dataIndex: 'name',
      sorter: false,
      className: 'drag-visible',
      render: (name, data) => {
        return (
          <DragVisible>
            <Popover
              placement="right"
              content={
                <img
                  alt={data.name}
                  style={{ maxHeight: '100px', width: 'auto' }}
                  src={data.img}
                />
              }
            >
              {name}
            </Popover>
          </DragVisible>
        );
      },
    },
    {
      title: 'Topované',
      dataIndex: 'userDefined',
      sorter: false,
      width: 30,
      align: 'center' as 'center',
      render: data => {
        if (data) {
          return <CheckOutlinedStyled />;
        } else {
          return '';
        }
      },
    },
    {
      title: 'Odstrániť',
      dataIndex: 'userDefined',
      sorter: false,
      width: 30,
      align: 'center' as 'center',
      render: (data, row) => {
        if (data) {
          return <CloseOutlined onClick={() => onRemovePromotion(row)} />;
        }
      },
    },
  ];

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const foundedItem = widget_data.find(item => item.index === oldIndex);
    const widget_id = parseInt(id);

    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(widget_data),
        oldIndex,
        newIndex
      ).filter(el => !!el);

      await dispatch(saveItemSort(foundedItem, newIndex, widget_id, newData));
    }
  };

  const DraggableContainer = props => (
    <SortableConStyled
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = widget_data.findIndex(
      x => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [itemId, setItemID] = useState(0);
  const [itemType, setItemType] = useState(null);

  const onSelectProduct = async (product_id: number, type: number) => {
    setItemID(product_id);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (itemId) {
      await dispatch(saveNewItem(itemId, id, 'NEW', itemType));
      setIsModalVisible(false);
      setItemID(0);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setItemID(0);
  };
  const localeId = useSelector((state: ApplicationState) =>
    countryIdSelector(state)
  );
  const onLocaleClick = async e => {
    dispatch(setCountryId(e.target.value));
    await dispatch(loadWidget(id));
  };

  const shopData = useSelector((state: ApplicationState) =>
    shopFiltersDataSelector(state)
  );
  const onSelectShopFilter = async value => {
    dispatch(setFilterOnSelect('shop', value));
    setItemID(value.value);
    setItemType('shop');
  };

  const onChangeShopFilter = async value => {
    await dispatch(loadShopData(value));
  };

  const manufacturerData = useSelector((state: ApplicationState) =>
    manufacturerFiltersDataSelector(state)
  );
  const onSelectManufacturerFilter = async value => {
    dispatch(setFilterOnSelect('manufacturer', value));
    setItemID(value.value);
    setItemType('manufacturer');
  };

  const onChangeManufacturerFilter = async value => {
    await dispatch(loadManufacturersData(value));
  };

  const categoryData = useSelector((state: ApplicationState) =>
  categoryrFiltersDataSelector(state)
  );
  const onSelectCategoryFilter = async value => {
    dispatch(setFilterOnSelect('category', value));
    setItemID(value.value);
  };

  const onChangeCategoryFilter = async value => {
    await dispatch(loadCategoryData(value));
  };

  return (
    <>
      <PageHeader
        title={'Správa widgetov'}
        ghost={false}
        extra={[
          <Radio.Group
            key="selectLocale"
            value={localeId}
            buttonStyle="solid"
            onChange={onLocaleClick}
          >
            <Radio.Button value={1}>SK</Radio.Button>
            <Radio.Button value={2}>CZ</Radio.Button>
          </Radio.Group>,
        ]}
        subTitle={widget_name ? widget_name : ''}
        onBack={handleNavigation}
      >
        Posledná aktulizácia: {date.toLocaleString('sk-SK')}
      </PageHeader>

      <MainContent>
        <AddButtonStyledCol>
          <Button key="1" type="primary" onClick={showModal}>
            Pridať
          </Button>
        </AddButtonStyledCol>
        <Table
          pagination={false}
          dataSource={widget_data}
          columns={columns}
          rowKey="index"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </MainContent>
      <Modal
        title="Pridanie položky do widgetu"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText={'Zavrieť'}
        okText={'Uložiť'}
      >
        <InfoText>Položka sa zaradí na prve miesto v tabulke</InfoText>
        {parseInt(id) !== 2 ? (
          parseInt(id) === 4 || parseInt(id) === 5 ? (
            <Col>
              {' '}
              {parseInt(id) === 4 ? (
                <Select
                  labelInValue
                  showSearch
                  placeholder={'Eshop'}
                  filterOption={false}
                  onChange={onSelectShopFilter}
                  onSearch={onChangeShopFilter}
                  style={{ width: 470, marginTop: 10, marginBottom: 10 }}
                >
                  {shopData.map(d => (
                    <Select.Option value={d.value} key={d.value + d.name}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              ) : null}
              {parseInt(id) === 5 ? (
                <Select
                  placeholder={'Výrobcovia'}
                  labelInValue
                  showSearch
                  filterOption={false}
                  onChange={onSelectManufacturerFilter}
                  onSearch={onChangeManufacturerFilter}
                  style={{ width: 470, marginTop: 10, marginBottom: 20 }}
                >
                  {manufacturerData.map(d => (
                    <Select.Option value={d.value} key={d.value + d.name}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              ) : null}
            </Col>
          ) : (
            <Col>
              {' '}
              <ProductSelect
                width={470}
                onSelect={product_id => onSelectProduct(product_id, 1)}
              />
            </Col>
          )
        ) : (
          <Col>
            {' '}
            <Select
                  placeholder={'Kategórie'}
                  labelInValue
                  showSearch
                  filterOption={false}
                  onChange={onSelectCategoryFilter}
                  onSearch={onChangeCategoryFilter}
                  style={{ width: 470, marginTop: 10, marginBottom: 20 }}
                >
                  {categoryData.map(d => (
                    <Select.Option value={d.value} key={d.value + d.name}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
          </Col>
        )}
      </Modal>
    </>
  );
};

export default WebWidgetDetail;
