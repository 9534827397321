
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import { Provider } from 'react-redux'
import store from './store';
import MainRoutes from "./Routes/mainRoutes";
import API from './services/API';
import OauthAPI from "./services/OauthAPI";
import { ConnectedRouter } from 'connected-react-router'
import {history} from "./store";

API.setBaseUrl(process.env.REACT_APP_API_BASE_URL);
OauthAPI.setBaseUrl(process.env.REACT_APP_OAUTH_APIURL);

ReactDOM.render(<Provider store={store}><ConnectedRouter history={history}><MainRoutes/></ConnectedRouter></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
