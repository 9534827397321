import React from 'react';
import {
    Modal
  } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { setModalVisibility, changeCategoryModalSelector, changeCategoryFiltersDataIsFetching, changeCategoryActiveFiltersSelector, changeCategoryFiltersDataSelector, loadChangeCategoryFilterData, setFilter, updatePmCategories } from './slice';
import { __ } from '../../utilities';
import ConnectedSingleSelect from '../../components/Select/ConnectedSingleSelect';

const ChangeCategoryModal = (props) => {

    const dispatch = useDispatch();
    
    const changeCategoryModal = useSelector((state: ApplicationState) =>
        changeCategoryModalSelector(state)
    );

    const isFetching = useSelector((state: ApplicationState) =>
       changeCategoryFiltersDataIsFetching(state)
    );

    const activeFilters = useSelector((state: ApplicationState) =>
       changeCategoryActiveFiltersSelector(state)
    );

    const data = useSelector((state: ApplicationState) =>
       changeCategoryFiltersDataSelector(state)
    );
    
    const onChange = async value => {
        dispatch(loadChangeCategoryFilterData(value));
    }

    const onSelect = async value => {
        dispatch(setFilter("changeCategorySelected",value));
    }
    
    const handleClose = () => {
        dispatch(setModalVisibility("changeCategory",false));
    }

    const onOkButton = () => {
        dispatch(updatePmCategories());
        console.log("ON OK BUTTON");
    }

    return <Modal visible = {changeCategoryModal.isVisible} 
                  onCancel={handleClose}
                  onOk={onOkButton}
                  okText="Zmeniť"
                  cancelText="Zrušiť"
                  title="Zmena kategórie"
           > 
                
                <ConnectedSingleSelect
                    name={__('Kategórie')}
                    isFetching={isFetching}
                    value={activeFilters}
                    data={data}
                    selectWidth="100%"
                    onChange={onChange}
                    onSelect={onSelect}
                /> 


           </Modal>
}

export default ChangeCategoryModal;