import { createSlice, PayloadAction} from "@reduxjs/toolkit"
import {ApplicationState, AppThunk} from "../../store";
import OauthAPI from "../../services/OauthAPI";
import { push } from 'connected-react-router';
import * as cookies from 'react-cookies';
import API from "../../services/API";
import {env} from "../../utilities";

const NAMESPACE = 'general';

interface GeneralState {
    token: string | null
    tokeninfo:
      {
        email: string
        name: string
        role:string
      } | null

    isFetching: boolean;
    userHasAffil: boolean;

}

const initialState : GeneralState = {
  token: null,
  tokeninfo: null,
  isFetching: true,
  userHasAffil: false
}

export const generalSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
      setIsFetching: (state, action: PayloadAction<boolean>) => {state.isFetching = action.payload},
      setToken: (state, action: PayloadAction<string>) => {state.token = action.payload},
      setTokenInfo: (state, action: PayloadAction<any>) => {state.tokeninfo = action.payload},
      setUserHasAffil: (state, action: PayloadAction<boolean>) => {state.userHasAffil = action.payload}
    }
  })

export const cookiesExpiresDate = () => {
  // expires in 1 year
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
};


export const loginUser = (accessToken: string, redirect = true): AppThunk => async (dispatch, getState) => {
  // ak nemam acceess token jedna sa o situaciu ze nie je v cookies
  if(accessToken){
    let tokenInfo = null;
    try{
       tokenInfo = await OauthAPI.tokenInfo({accessToken, appSpace: "5e469176c016cb59e1d9c9e7"});
    } catch(e){
      dispatch(logOut());
      dispatch(push('/prihlasenie'));
    }

    if(tokenInfo){
      dispatch(generalSlice.actions.setIsFetching(true));
      dispatch(generalSlice.actions.setToken(accessToken));
      dispatch(generalSlice.actions.setTokenInfo(tokenInfo));

      cookies.save('USER', accessToken, {
        path: '/',
        expires: cookiesExpiresDate(),
      });
      
      API.setToken(accessToken);
      const userAffils = await API.loadUserAffils();
      if(userAffils.items.length){
        dispatch(generalSlice.actions.setUserHasAffil(true));
      }
      else{
        dispatch(generalSlice.actions.setUserHasAffil(false));
      }

      if(redirect){
        if(userAffils.items.length){
          if(tokenInfo.role === 'AFFIL_USER'){
            dispatch(push('/top50'));
          }
          else{
            dispatch(push('/affil/stats'));
          }
        } else {
          dispatch(push('/'));
        }
      }
      
    }
  }
  
  dispatch(generalSlice.actions.setIsFetching(false));
}
export  const logOut = () => async (dispatch, getState) => {
    dispatch(generalSlice.actions.setToken(null))
    dispatch(generalSlice.actions.setTokenInfo(null))
    cookies.remove('USER');

}

export const impersonate = (userId) => async (dispatch, getState) => {
    const token = tokenSelector(getState());
    API.setToken(token); 
    const response:any = await OauthAPI.impersonate({user:userId,redirectUri:env<string>('REACT_APP_OAUTH_RURL')});
    window.location = response.redirectUriFinal;
}

export const tokenSelector = (state: ApplicationState) => state.general.token;
export const tokenInfoSelector = (state: ApplicationState) => state.general.tokeninfo;
export const tokenIsFetchingSelector = (state: ApplicationState) => state.general.isFetching;
export const userHasAffilSelector = (state: ApplicationState) => state.general.userHasAffil;

export default generalSlice.reducer;
