import React from "react";
import {Button, Popover, Switch, Table} from "antd";
import {InteractionOutlined, UserSwitchOutlined} from "@ant-design/icons"
import {__} from "../../utilities";
import {ApplicationState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {
  loadUsers,
  paginationSelector,
  resetPassword,
  setEnabled,
  totalSelector,
  userListSelector
} from "../UsersList/slice";
import {setPagination} from "../UsersList/slice";
import UserInvite from "../UserInvite/UserInvite";
import H1 from "../../components/Headings/H1";
import { impersonate } from "../App/slice";


const UsersList = () => {
  const columns = [
    {
      title: __('Email'),
      dataIndex: 'email',
    },
    {
      title: __('Meno'),
      dataIndex: 'name',
      sorter: true
    },
    {
      title: __('Priezvisko'),
      dataIndex: 'surname',
      sorter: true
    },
    {
      title: __('Rola'),
      dataIndex: 'role',
    },
    {
      title: __('Aktívny'),
      dataIndex: 'enabled',
      render: (activeState, record) => (
        <Switch
          onChange={(checked) => {
            dispatch(setEnabled(record._id))
          }}
          checkedChildren={__('áno')}
          unCheckedChildren={__('nie')}
          checked={record.enabled}
        />
      )
    },
    {
      title: __(''),
      dataIndex: 'resetPassword',
      render: (activeState, record) => {
        return (
          <Popover content={'resetovať heslo'}>
            <Button
              type="primary"
              icon={<InteractionOutlined/>}
              size={'small'}
              onClick={() => dispatch(resetPassword(record._id))}
            />
          </Popover>

        );
      },
    },
    {
      title: __(''),
      dataIndex: 'impersonate',
      render: (activeState, record) => {
        return (
          <Popover content={'Impersonate'}>
            <Button
              type="primary"
              icon={<UserSwitchOutlined />}
              size={'small'}
              onClick={() => dispatch(impersonate(record._id))}
            />
          </Popover>

        );
      },
    },
  ]


  const dispatch = useDispatch();


  const pagination = useSelector((state: ApplicationState) =>
    paginationSelector(state)
  );
  const total = useSelector((state: ApplicationState) =>
    totalSelector(state)
  );

  const userList = useSelector((state: ApplicationState) => userListSelector(state));

  React.useEffect(() => {
    async function init() {
      dispatch(loadUsers());
    }

    init();
  }, [dispatch]);

  const onChangeTable = (pagination) => {
    dispatch(setPagination(pagination.current));
    dispatch(loadUsers());

  }

  return (
    <>
      <H1>{__('Používatelia')}</H1>
      <Table
        columns={columns}
        pagination={{
          total: total,
          current: pagination.current,
          pageSize: pagination.pageSize,
        }}
        dataSource={userList}
        onChange={(pagination,) => {
          onChangeTable(pagination);
        }}
      />
      <UserInvite></UserInvite>
    </>
  );
}
export default UsersList;