import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, ApplicationState } from '../../store';
import API from '../../services/API';
import { getValuesFromArray } from '../../utilities';
import moment from 'moment';

const NAMESPACE = 'shopList';

interface Company {
  _id: string;
  company_id: number;
  address_country_id: number;
  city_id: number;
  contact_person_email: string;
  contact_person_name: string;
  contact_person_phone: string;
  contact_person_position_id: number;
  contact_person_surname: string;
  created: Date;
  created_by: number;
  credit: string;
  credit_currency_id: number;
  enabled: boolean;
  identification_number: string;
  merchant_id: number;
  name: string;
  notification_date: Date;
  notification_id: number;
  other_information: string;
  postal_city_id: number;
  postal_code: string;
  postal_company_name: string;
  postal_country_id: number;
  postal_postal_code: string;
  postal_street: string;
  street: string;
  tax_number: string;
  vat_number: string;
  vat_payer: boolean;
}

interface Shop {
  _id: string;
  key: number;
  shop_id: number;
  name: string;
  company: any;
}
interface GeneralStats {
  count: number;
  impressions: number;
  clicks: number;
  payment: number;
}

interface GeneralDailyStats {
  date: string;
  count: number;
  impressions: number;
  clicks: number;
  payment: number;
}

interface ShopListState {
  activeFilters: {
    shop: { value: number }[];
    startDate: moment.Moment;
    endDate: moment.Moment;
    company: { value: number }[];
    country: number;
    source: number;
    program_id: Array<number>,
    has_backlink: number
  };
  filtersData: {
    shop: {
      isFetching: boolean;
      data: any;
    };
    company: {
      isFetching: boolean;
      data: any;
    };
    generalStats: {
      isFetching: boolean;
      data: any;
    };
  };
  generalStats: {
    isFetching: boolean;
    data: GeneralStats;
  };
  generalDailyStats: {
    isFetching: boolean;
    data: Array<GeneralDailyStats>;
  };
  filterResults: {
    offset: number;
    isFetching: boolean;
    total: number;
    shops: Shop[];
  };
  pagination: {
    pageSize: number;
    current: number;
  };
  sorterData: {
    type: string;
    order: string;
  };
}

const initialState: ShopListState = {
  activeFilters: {
    startDate: moment().subtract(7,"days"),
    endDate: moment(),
    shop: [],
    company: [],
    country: null,
    source: null,
    program_id: [],
    has_backlink: null
  },
  filtersData: {
    shop: {
      isFetching: false,
      data: [],
    },
    company: {
      isFetching: false,
      data: [],
    },
    generalStats: {
      isFetching: false,
      data: [],
    },
  },
  generalStats: {
    isFetching: false,
    data: null,
  },
  generalDailyStats: {
    isFetching: false,
    data: null,
  },
  filterResults: {
    isFetching: false,
    total: 0,
    offset: 0,
    shops: [],
  },
  pagination: {
    pageSize: 20,
    current: 1,
  },
  sorterData: {
    type: null,
    order: 'asc',
  },
};

export const shopListSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setFilterResults: (
      state,
      action: PayloadAction<{ total: number; shops: Array<Shop> }>
    ) => {
      state.filterResults.total = action.payload.total;
      state.filterResults.shops = action.payload.shops;
    },
    setIsFetchingFilterResults: (
      state,
      action: PayloadAction<{ value: boolean }>
    ) => {
      state.filterResults.isFetching = action.payload.value;
    },
    setPagination: (state, action: PayloadAction<{ current: number, pageSize: number }>) => {
      state.pagination.current = action.payload.current;
      state.pagination.pageSize = action.payload.pageSize;
    },
    setSorterData: (
      state,
      action: PayloadAction<{ type: string; order: string }>
    ) => {
      state.sorterData.type = action.payload.type;
      state.sorterData.order = action.payload.order;
    },
    setActiveFilterValue: (
      state,
      action: PayloadAction<{
        name: string;
        value: number[] | string | number | null | boolean;
      }>
    ) => {
      state.activeFilters[action.payload.name] = action.payload.value;
    },
    setIsFetchingFilterData: (
      state,
      action: PayloadAction<{ name: string; value: boolean }>
    ) => {
      state.filtersData[action.payload.name].isFetching = action.payload.value;
    },
    setIsFetchingGeneralStats: (
      state,
      action: PayloadAction<{ value: boolean }>
    ) => {
      state.generalStats.isFetching = action.payload.value;
    },
    setFilterData: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      state.filtersData[action.payload.name].data = action.payload.value;
    },
    setGeneralStats: (state, action: PayloadAction<GeneralStats>) => {
      state.generalStats.data = action.payload;
    },
    setGeneralDailyStats: (
      state,
      action: PayloadAction<Array<GeneralDailyStats>>
    ) => {
      state.generalDailyStats.data = action.payload;
    },
  },
});

export const setSorter = (field: string, orderName: string) => (
  dispatch,
  getState
) => {
  const type: string = orderName ? field : undefined;
  const order: string = orderName === 'descend' ? 'desc' : 'asc';

  dispatch(shopListSlice.actions.setSorterData({ type: type, order: order }));
};

export const loadShops = (): AppThunk => async (dispatch, getState) => {
  const isFetching = getState().shopList.filterResults.isFetching;
  const token = tokenSelector(getState());
  API.setToken(token);
  if (isFetching) {
    dispatch(
      shopListSlice.actions.setIsFetchingFilterResults({ value: false })
    );
  }
  const offset =
    (getState().shopList.pagination.current - 1) *
    getState().shopList.pagination.pageSize;
  const shopIds = getValuesFromArray(shopActiveFiltersSelector(getState()));
  const companyIds = getValuesFromArray(
    companyActiveFiltersSelector(getState())
  );
  const country = getState().shopList.activeFilters.country;
  const startDate = startDateActiveFiltersSelector(getState());
  const endDate = endDateActiveFiltersSelector(getState());
  const source = getState().shopList.activeFilters.source;
  const program_ids = getState().shopList.activeFilters.program_id;
  const backlink = getState().shopList.activeFilters.has_backlink;

  dispatch(shopListSlice.actions.setIsFetchingFilterResults({ value: true }));
  API.loadShopsList({
    limit: getState().shopList.pagination.pageSize,
    offset: offset,
    shopIds: shopIds && shopIds.length ? shopIds : undefined,
    companyIds: companyIds ?  [].concat.apply([], companyIds) : undefined,
    countryId: country ? country : undefined,
    startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
    endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
    sortType: getState().shopList.sorterData.type? getState().shopList.sorterData.type:undefined,
    sortOrder:  getState().shopList.sorterData.order,
    source: Number(source),
    programIds: program_ids ? program_ids : [],
    backlinkFilter: backlink ? backlink : undefined,
  }).then(response => {
    const shops: Shop[] = response.shops.map(shop => {
      const cpc = (shop.payment / shop.clicks)
      return {
        _id: shop._id,
        key: shop.shop && shop.shop[0].shop_id,
        shop_id: shop.shop && shop.shop[0].shop_id,
        name: shop.shop && shop.shop[0].name,
        country_id: shop.shop && shop.shop[0].country_id,
        company: shop.company && shop.company[0].name ? shop.company[0].name : undefined,
        program_id:shop.shop && shop.shop[0].program_id,
        state: shop.shop && shop.shop[0].state,
        credit_final: shop.company && shop.company[0].credit_final ? shop.company[0].credit_final : 0,
        impressions: shop.impressions,
        clicks: shop.clicks,
        products_count_active: (shop.products_active || shop.products_total_count )&& `${shop.products_active.toLocaleString('sk-SK')} (${shop.products_total_count.toLocaleString('sk-SK')})`,
        payment: shop.payment,
        cpc: cpc ? cpc.toFixed(2) : 0,
        has_backlink: shop.shop && shop.shop[0].has_backlink,
      };
    });
    const isFetching = getState().shopList.filterResults.isFetching;
    if (!isFetching) {
      return;
    }

    dispatch(
      shopListSlice.actions.setFilterResults({ total: response.total, shops })
    );
    dispatch(
      shopListSlice.actions.setIsFetchingFilterResults({ value: false })
    );
  });
};

export const loadShopFilterData = (value: string): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(
    shopListSlice.actions.setIsFetchingFilterData({ name: 'shop', value: true })
  );
  const token = tokenSelector(getState());
  API.setToken(token);
  const country = getState().shopList.activeFilters.country;
  API.loadShops({
    query: value,
    limit: 100,
    countryId: country ? country : undefined,
  }).then(response => {
    dispatch(
      shopListSlice.actions.setFilterData({
        name: 'shop',
        value: response.shops.map(shop => {
          return {
            name: shop.name + ` (${shop.program_id})`,
            value: shop.shop_id,
          };
        }),
      })
    );
    dispatch(
      shopListSlice.actions.setIsFetchingFilterData({
        name: 'shop',
        value: false,
      })
    );
  });
};
export const loadCompanyFilterData = (value: string): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(
    shopListSlice.actions.setIsFetchingFilterData({
      name: 'company',
      value: true,
    })
  );
  const token = tokenSelector(getState());
  API.setToken(token);
  const country = getState().shopList.activeFilters.country;
  API.loadCompanies({
    query: value,
    limit: 100,
    countryId: country ? country : undefined,
  }).then(response => {
    dispatch(
      shopListSlice.actions.setFilterData({
        name: 'company',
        value: response.companies.map(company => {
          return {
            name: company.name,
            value: company.shop_data && company.shop_data ? company.shop_data.map(shop => shop.shop_id): company.company_id
          };
        }),
      })
    );

    dispatch(
      shopListSlice.actions.setIsFetchingFilterData({
        name: 'company',
        value: false,
      })
    );
  });
};
export const loadGeneralStats = (): AppThunk => async (dispatch, getState) => {
  dispatch(shopListSlice.actions.setIsFetchingGeneralStats({ value: true }));

  const startDate = startDateActiveFiltersSelector(getState());
  const endDate = endDateActiveFiltersSelector(getState());

  const country = getState().shopList.activeFilters.country;
  const company = getValuesFromArray(getState().shopList.activeFilters.company);
  const shop = getValuesFromArray(getState().shopList.activeFilters.shop);
  const program_ids = getState().shopList.activeFilters.program_id;
  const soruce = getState().shopList.activeFilters.source;
  const backlink = getState().shopList.activeFilters.has_backlink;

  const token = tokenSelector(getState());
  API.setToken(token);

  API.loadGeneralShopListStats({
    startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
    endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
    shopIds: shop && shop.length ? shop : undefined,
    companyIds: company && company.length ? [].concat.apply([], company) : undefined,
    soruce: soruce && soruce ? soruce : undefined,
    country: Number(country),
    programIds: program_ids ? program_ids : [],
    backlinkFilter: backlink ? backlink : undefined,
  }).then(response => {
    const generalStats: GeneralStats = {
      clicks: response.clicks,
      count: response.count,
      impressions: response.impressions,
      payment: response.payment,
    };
    dispatch(shopListSlice.actions.setGeneralStats(generalStats));
    dispatch(shopListSlice.actions.setIsFetchingGeneralStats({ value: false }));
  });
};
export const loadGeneralDailyStats = (): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(shopListSlice.actions.setIsFetchingGeneralStats({ value: true }));

  const startDate = startDateActiveFiltersSelector(getState());
  const endDate = endDateActiveFiltersSelector(getState());

  const country = getState().shopList.activeFilters.country;
  const soruce = getState().shopList.activeFilters.source;

  const company = getValuesFromArray(getState().shopList.activeFilters.company);
  const shop = getValuesFromArray(getState().shopList.activeFilters.shop);
  const backlink = getState().shopList.activeFilters.has_backlink;
  const program_ids = getState().shopList.activeFilters.program_id;

  console.log(backlink)

  const token = tokenSelector(getState());
  API.setToken(token);

  API.loadGeneraDailyShopListStats({
    startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
    endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
    shopIds: shop && shop.length ? shop : undefined,
    companyIds: company && company.length ? [].concat.apply([], company)  : undefined,
    soruce: soruce && soruce ? soruce : undefined,
    country: Number(country),
    programIds: program_ids ? program_ids : [],
    backlinkFilter: backlink ? backlink : undefined,
  }).then(response => {
    const generalDailyStats: GeneralDailyStats[] = response.items.map(item => {
      return {
        date: item.date,
        clicks: item.clicks,
        impressions: item.impressions,
        count: item.count,
        payment: item.payment,
      };
    });

    generalDailyStats.sort((a, b) => {
      return a.date.localeCompare(b.date);
    });
    dispatch(shopListSlice.actions.setGeneralDailyStats(generalDailyStats));
    dispatch(shopListSlice.actions.setIsFetchingGeneralStats({ value: false }));
  });
};

export const setPagination = (current: number, pageSize: number) => (dispatch, getState) => {
  dispatch(shopListSlice.actions.setPagination({ current: current, pageSize: pageSize }));
};

export const setFilter = (
  filter: string,
  value: number[] | string | number | null | boolean
) => (dispatch, getState) => {
  dispatch(shopListSlice.actions.setActiveFilterValue({ name: filter, value }));
};

export const shopsIsFethingSelector = (state: ApplicationState) =>
  state.shopList.filterResults.isFetching;
export const shopsSelector = (state: ApplicationState) =>
  state.shopList.filterResults.shops;
export const totalSelector = (state: ApplicationState) =>
  state.shopList.filterResults.total;
export const paginationSelector = (state: ApplicationState) =>
  state.shopList.pagination;

export const shopActiveFiltersSelector = (state: ApplicationState) =>
  state.shopList.activeFilters.shop;
export const shopFiltersDataSelector = (state: ApplicationState) =>
  state.shopList.filtersData.shop.data;
export const shopFiltersDataIsFetching = (state: ApplicationState) =>
  state.shopList.filtersData.shop.isFetching;

export const startDateActiveFiltersSelector = (state: ApplicationState) =>
  state.shopList.activeFilters.startDate;
export const endDateActiveFiltersSelector = (state: ApplicationState) =>
  state.shopList.activeFilters.endDate;

export const companyActiveFiltersSelector = (state: ApplicationState) =>
  state.shopList.activeFilters.company;
export const companyFiltersDataSelector = (state: ApplicationState) =>
  state.shopList.filtersData.company.data;
export const companyFiltersDataIsFetching = (state: ApplicationState) =>
  state.shopList.filtersData.company.isFetching;

export const sourceActiveFiltersSelector = (state: ApplicationState) =>
  state.shopList.activeFilters.source;

export const tokenSelector = (state: ApplicationState) => state.general.token;

export const generalStatsSelector = (state: ApplicationState) =>
  state.shopList.generalStats.data;
export const generalStatsFethingSelector = (state: ApplicationState) =>
  state.shopList.generalStats.isFetching;
export const generalDailyStatsSelector = (state: ApplicationState) =>
  state.shopList.generalDailyStats.data;
export const generalStatsFethingDailySelector = (state: ApplicationState) =>
  state.shopList.generalDailyStats.isFetching;

export default shopListSlice.reducer;


