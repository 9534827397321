import React, { useState, useCallback } from 'react';
import {
  Table, Row, Col, Dropdown, Menu, Modal, Input, Button, Spin
} from 'antd';
import { __, round } from '../../utilities';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import styled from 'styled-components';
import ConnectedMultiSelect from '../../components/Select/ConnectedMultiSelect';
import {
  loadAutomaticPairingForProduct,
  filterResultsIsFetchingSelector,
  filterResultsItemsSelector,
  loadShopFilterData,
  shopFiltersDataIsFetching,
  shopActiveFiltersSelector,
  shopFiltersDataSelector,
  setFilter,
  manufacturerFiltersDataIsFetching,
  manufacturerActiveFiltersSelector,
  manufacturerFiltersDataSelector,
  loadManufacturerFilterData,
  categoryFiltersDataIsFetching,
  categoryActiveFiltersSelector,
  categoryFiltersDataSelector,
  loadCategoryFilterData,
  matchTypeActiveFiltersSelector,
  pairAutomaticPairingItems,
  loadAutomaticPairingProducts,
  AutomaticPairingProductEntity,
  setLoadingRecords,
  loadingRecordsSelector,
  recordsForProductSelector,
  rejectAutomaticPairingItems
} from './slice';

const CustomTable = styled(Table)`
  .ant-table-row.selected {
    background: #9dc7c3;
  }
`

const TableRow = styled(Row)`
  margin-bottom: 15px; 
`

const FitlerRow = styled(Row)`
  margin-bottom: 15px; 
`;

const CheckColumn: any = styled.div`
  background-color: ${(props: any) => props.isValid ? "unset" : "red"} 
`

const EmptyDiv: any = styled.div`
  position: fixed;
  top: ${(props: any) => props.top + "px"} ;
  left: ${(props: any) => props.left + "px"};
`

const ModalContent = styled.div`
  display:flex; 
`

const ModalProduct = styled.div`
  flex: 1;
  padding: 1rem;  
`

const ModalProductName = styled.p`
  line-height: 1.5em; 
  height: 3em; 
  overflow: hidden;
  font-weight: bold; 
`

const ModalProductPicture = styled.img`
  margin-top: 30px;
  height: 200px;
`

const ModalProductEan = styled.p`
  margin-top: 0px;
`

const ModalProductPartNumber = styled.p`
  margin-top: 0px;
`

const ModalProductCategory = styled.p`
  margin-top: 0px;
`

const ModalProductManufacturer = styled.p`
  margin-top: 30px;
`

const ModalProductPrice = styled.p`
  margin-top: 0px;
`

const ModalProductDescription = styled.p`
  margin-top: 10px;
`

const AutomaticPairingProduct = () => {

  const dispatch = useDispatch();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [dropDownTop, setDropdownTop] = useState(0);
  const [dropDownLeft, setDropdownLeft] = useState(0);
  const [dropdowClickRecord, setDropdownClickRecord] = useState(-1);

  const [isModalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentCatalogProductId, setCurrentCatalogProductId] = useState(-1);
  const [currentDataSource, setCurrentDataSource] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [childrenSelectedRows, setChildrenSelectedRows] = useState([]); 
  //const [lastClickedIndex, setLastClickedIndex] = useState(-1);

  React.useEffect(() => {
    async function init() {
      dispatch(loadAutomaticPairingProducts());
    }
    init();
  }, [dispatch]);

  const recordsForProduct = useSelector((state: ApplicationState) =>
     recordsForProductSelector(state)
  );

  const keyHandleFunction = useCallback((event) => {

    if (event.keyCode === 38) {
      const currentRecordForProduct = recordsForProduct[currentCatalogProductId];

      // array up
      if (currentIndex > 0) {
        const nextIndex = currentIndex - 1;
        if (currentRecordForProduct && currentRecordForProduct.length > 0) {
          setCurrentIndex(nextIndex);
          //setLastClickedIndex(nextIndex);
          setModalFromRecord(currentRecordForProduct[nextIndex]);
        }
      }
    }
    else if (event.keyCode === 40) {
      const currentRecordForProduct = recordsForProduct[currentCatalogProductId];

      // arrow down
      if (currentIndex >= 0) {
        const nextIndex = currentIndex + 1;
        if (currentRecordForProduct && currentRecordForProduct.length > nextIndex) {
          setCurrentIndex(nextIndex);
          //setLastClickedIndex(nextIndex);
          setModalFromRecord(currentRecordForProduct[nextIndex]);
        }
      }
    }
  }, [currentIndex, currentCatalogProductId, recordsForProduct]); 

  React.useEffect(() => {
    document.addEventListener("keydown", keyHandleFunction, false);

    return () => {
      document.removeEventListener("keydown", keyHandleFunction, false);
    };
  }, [currentIndex, currentDataSource, keyHandleFunction]);

  const onDropdownMenuClick = (item) => {
    let currentSelectedRows = selectedRows.filter(item => item).filter(item => currentDataSource.map(item => item.key).includes(item.key));
    if (!currentSelectedRows.length) {
      currentSelectedRows = [dropdowClickRecord];
    }

    const currentSelectedRowsKeys = currentSelectedRows.filter(item => item).map(item => item.key);
    const compareIds = [];
    for(const currentSelectedRowKey of currentSelectedRowsKeys){
      if(childrenSelectedRows[currentSelectedRowKey] && childrenSelectedRows[currentSelectedRowKey].length){
        for(const childrenSelectedRow of childrenSelectedRows[currentSelectedRowKey]){
          compareIds.push(childrenSelectedRow.key);
        }  
      }
    }

    if (item.key === 'pair') {
      dispatch(pairAutomaticPairingItems(compareIds));
    }
    else if(item.key === 'reject'){
      setSelectedRows([]);
      dispatch(rejectAutomaticPairingItems(compareIds));
    }
    /*
    else if (item.key === 'reject') {
      const r = window.confirm(__('Naozaj chcete zamietnuť párovanie?'));
      if (r === true) {
        dispatch(rejectAutomaticPairingItems(currentSelectedRowsKeys));
      }
    }
    */

    return true;
  }

  const dropDownmenu = (
    <Menu onClick={(item) => { onDropdownMenuClick(item) }}>
      <Menu.Item key="pair">Spárovať</Menu.Item>
      <Menu.Item key="reject">Zamietnuť</Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: __('Názov produktu'),
      dataIndex: 'name'
    },
    {
      title: __('Počet ponúk'),
      dataIndex: 'count'
    }
    // {
    //   title: __('Názov shop'),
    //   dataIndex: 'shop_product_name',
    //   sorter: (a, b) => a.shop_product_name.localeCompare(b.shop_product_name),
    // },
    // {
    //   title: __('Kategória'),
    //   dataIndex: 'category_name',
    //   sorter: (a, b) => a.category_name.localeCompare(b.category_name),
    // },
    // {
    //   title: __('Obchod'),
    //   dataIndex: 'shop_name',
    //   sorter: (a, b) => a.shop_name.localeCompare(b.shop_name),
    // },
    // {
    //   title: __('Typ'),
    //   dataIndex: 'match_type',
    //   sorter: (a, b) => a.match_type.localeCompare(b.match_type),
    // },
    // {
    //   title: __('Nazov'),
    //   dataIndex: 'name_check',
    //   render: (nameCheck,data) => (
    //     <CheckColumn isValid = {nameCheck}>
    //       { nameCheck?`OK(${data.name_check_score.toFixed(2)})`:`NOK(${data.name_check_score.toFixed(2)})` }
    //     </CheckColumn>
    //   ),
    //   sorter: (a, b) => (a.name_check?1:0 > b.name_check?1:0)?1:-1,
    // },
    // {
    //   title: __('Cena'),
    //   dataIndex: 'price_check',
    //   render: (priceCheck,data) => (
    //     <CheckColumn isValid = {priceCheck}>
    //         {round(data.product_price,2)}/{round(data.pm_product_price,2)}
    //     </CheckColumn>
    //   ),
    //   sorter: (a, b) => (a.price_check?1:0 > b.price_check?1:0)?1:-1,
    // },
    // {
    //   title: __('Kategória'),
    //   dataIndex: 'category_check',
    //   render: (categoryCheck,data) => (
    //     <CheckColumn isValid = {categoryCheck}>
    //       { categoryCheck?"OK":"NOK" }
    //     </CheckColumn>
    //   ),
    //   sorter: (a, b) => (a.category_check?1:0 > b.category_check?1:0)?1:-1,
    // },
    // {
    //   title: __('Výrobca'),
    //   dataIndex: 'manufacturer_check',
    //   render: (manufacturerCheck,data) => (
    //     <CheckColumn isValid = {manufacturerCheck}>
    //       { manufacturerCheck?"OK":"NOK" }
    //     </CheckColumn>
    //   ),
    //   sorter: (a, b) => (a.manufacturer_check?1:0 > b.manufacturer_check?1:0)?1:-1,
    // }
  ];

  const isFetchingAutomaticPairing = useSelector((state: ApplicationState) =>
    filterResultsIsFetchingSelector(state)
  );

  const automaticPairingItems = useSelector((state: ApplicationState) =>
    filterResultsItemsSelector(state)
  );

  const onChangeShopFilter = async value => {
    await dispatch(loadShopFilterData(value));
  };

  const isFetchingShopData = useSelector((state: ApplicationState) =>
    shopFiltersDataIsFetching(state)
  );

  const shopActiveFilters = useSelector((state: ApplicationState) =>
    shopActiveFiltersSelector(state)
  );

  const shopData = useSelector((state: ApplicationState) =>
    shopFiltersDataSelector(state)
  );

  const loadingRecords = useSelector((state: ApplicationState) =>
    loadingRecordsSelector(state)
  );

  const onSelectShopFilter = async value => {
    dispatch(setFilter('shopSelected', value));
    dispatch(loadAutomaticPairingProducts());
  };

  const isFetchingManufacturerData = useSelector((state: ApplicationState) =>
    manufacturerFiltersDataIsFetching(state)
  );

  const manufacturerActiveFilters = useSelector((state: ApplicationState) =>
    manufacturerActiveFiltersSelector(state)
  );

  const manufacturerData = useSelector((state: ApplicationState) =>
    manufacturerFiltersDataSelector(state)
  );

  const onChangeManufacturerFilter = async value => {
    await dispatch(loadManufacturerFilterData(value));
  };

  const onSelectManufacturerFilter = async value => {
    dispatch(setFilter('manufacturerSelected', value));
    dispatch(loadAutomaticPairingProducts());
  };

  const isFetchingCategoryData = useSelector((state: ApplicationState) =>
    categoryFiltersDataIsFetching(state)
  );

  const categoryActiveFilters = useSelector((state: ApplicationState) =>
    categoryActiveFiltersSelector(state)
  );

  const categoryData = useSelector((state: ApplicationState) =>
    categoryFiltersDataSelector(state)
  );

  const onChangeCategoryFilter = async value => {
    await dispatch(loadCategoryFilterData(value));
  };

  const onSelectCategoryFilter = async value => {
    dispatch(setFilter('categorySelected', value));
    dispatch(loadAutomaticPairingProducts());
  };

  const onRow = (record, index) => ({
    onContextMenu: event => {
      event.preventDefault();
      setDropdownTop(event.clientY);
      setDropdownLeft(event.clientX);
      setDropdownVisible(true);
      setDropdownClickRecord(record);
      return true;
    }
  });

  const onChildrenRow = (record, index) => ({
    onClick: event => {
      setCurrentCatalogProductId(record.catalog_product_id);
      setCurrentIndex(index);
      //setLastClickedIndex(index);
      setModalFromRecord(record);
    }
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: record => {
      return {
        disabled: recordsForProduct[record.product_id]?false:true
      }
    }
  };

  const rowChildrenSelection = (productId) => ({
    onChange: (selectedRowKeys, selectedRows) => {
      childrenSelectedRows[productId] = selectedRows;
      setChildrenSelectedRows(childrenSelectedRows);
      console.log(`CHILDREN selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    }
  })

  const handleModalCancel = () => {
    setCurrentIndex(-1);
    setModalVisible(false);
  };

  const onTableChange = (pagination, filters, sorter, extra: { currentDataSource: any[] }) => {
    setCurrentDataSource(extra.currentDataSource);
  };

  const setModalFromRecord = (record) => {
    setModalData({
      product: {
        name: record.catalog_product_name,
        image_url: `https://pricemania.sk/assets/product/${record.catalog_product_id}/detail_image.jpg`,
        description: record.catalog_product_description,
        ean: record.ean,
        part_number: record.part_number,
        manufacturer: record.manufacturer,
        category: record.category,
        price: record.product_price
      },
      pm_product: {
        name: record.shop_product_name,
        image_url: record.pm_product_image_url,
        description: record.pm_product_description,
        ean: record.pm_product_ean,
        part_number: record.pm_product_part_number,
        manufacturer: record.pm_product_manufacturer,
        category: record.pm_product_category,
        price: record.pm_product_price
      }
    });
    setModalVisible(true);
  };

  React.useEffect(() => {
    setCurrentDataSource(automaticPairingItems);
  }, [automaticPairingItems])

  const onSearchQuery = async value => {
    dispatch(setFilter('query', value));
    //setLastClickedIndex(-1);
    dispatch(loadAutomaticPairingProducts());
  };

  const onSelectMatchTypeFilter = async value => {
    dispatch(setFilter('matchTypeSelected', value));
    //setLastClickedIndex(-1);
    dispatch(loadAutomaticPairingProducts());
  };

  const matchTypeActiveFilters = useSelector((state: ApplicationState) =>
    matchTypeActiveFiltersSelector(state)
  );
  
  /*
  const onChangeShowPartners = async value => {
    dispatch(setFilter('showPartners', value.target.checked));
    //setLastClickedIndex(-1);
    dispatch(loadAutomaticPairingProducts());
  };
  */
  /*
  const handleModalReject = () => {
    const item = automaticPairingItems[currentIndex];
    dispatch(rejectAutomaticPairingItems([item.key]));

    if (automaticPairingItems.length > currentIndex + 1) {
      setCurrentIndex(currentIndex);
      //setLastClickedIndex(currentIndex);
      setModalFromRecord(automaticPairingItems[currentIndex + 1]);
    } else {
      setCurrentIndex(-1);
      setModalVisible(false);
    }
  };

  const handleModalPair = () => {
    const item = automaticPairingItems[currentIndex];
    dispatch(pairAutomaticPairingItems([item.key]));

    if (automaticPairingItems.length > currentIndex + 1) {
      setCurrentIndex(currentIndex);
      //setLastClickedIndex(currentIndex);
      setModalFromRecord(automaticPairingItems[currentIndex + 1]);
    } else {
      setCurrentIndex(-1);
      setModalVisible(false);
    }
  };
  */

  const onTableRowExpand = (expanded, record) => {
    dispatch(setLoadingRecords(record.product_id, true));
    dispatch(loadAutomaticPairingForProduct(record.product_id));
  };


  return (
    <>
      <TableRow>
        <Col className="gutter-row" span={24}>
          <FitlerRow gutter={{ lg: 5 }}>
            <Col>
              <ConnectedMultiSelect
                name={__('Shop')}
                isFetching={isFetchingShopData}
                value={shopActiveFilters}
                data={shopData}
                selectWidth={200}
                onChange={onChangeShopFilter}
                onSelect={onSelectShopFilter}
              />
            </Col>

            <Col>
              <ConnectedMultiSelect
                name={__('Výrobca')}
                isFetching={isFetchingManufacturerData}
                value={manufacturerActiveFilters}
                data={manufacturerData}
                selectWidth={200}
                onChange={onChangeManufacturerFilter}
                onSelect={onSelectManufacturerFilter}
              />
            </Col>

            <Col>
              <ConnectedMultiSelect
                name={__('Kategória')}
                isFetching={isFetchingCategoryData}
                value={categoryActiveFilters}
                data={categoryData}
                selectWidth={500}
                onChange={onChangeCategoryFilter}
                onSelect={onSelectCategoryFilter}
              />
            </Col>

            <Col>
              <ConnectedMultiSelect
                name={__('Typ zhody')}
                value={matchTypeActiveFilters}
                data={[{ name: "EAN", value: "EAN" }, { name: "PN", value: "PN" }, { name: "RULE", value: "RULE" }]}
                selectWidth={200}
                onSelect={onSelectMatchTypeFilter}
              />
            </Col>

            <Col>
              <Input.Search
                placeholder={__('Vyhľadať')}
                onSearch={onSearchQuery}
                enterButton
              />
            </Col>
            {/* 
            <Col>
              <Checkbox onChange={onChangeShowPartners}>Zobraziť aj Partner eshopy</Checkbox>
            </Col>
            */}

          </FitlerRow>
        </Col>
      </TableRow>

      <CustomTable
        expandable={{
          expandedRowRender: (product: AutomaticPairingProductEntity) => <>
            {loadingRecords[product.product_id] ? <Spin size="large" /> : null}
            {recordsForProduct[product.product_id] && recordsForProduct[product.product_id].length > 0 
              ? <Table
                  columns = {[
                    {
                      title: __('NÃ¡zov katalog'),
                      dataIndex: 'catalog_product_name',
                      //sorter: (a, b) => a.catalog_product_name.localeCompare(b.catalog_product_name),
                    },
                    {
                      title: __('NÃ¡zov shop'),
                      dataIndex: 'shop_product_name',
                      //sorter: (a, b) => a.shop_product_name.localeCompare(b.shop_product_name),
                    },
                    {
                      title: __('KategÃ³ria'),
                      dataIndex: 'category_name',
                      //sorter: (a, b) => a.category_name.localeCompare(b.category_name),
                    },
                    {
                      title: __('Obchod'),
                      dataIndex: 'shop_name',
                      //sorter: (a, b) => a.shop_name.localeCompare(b.shop_name),
                    },
                    {
                      title: __('Typ'),
                      dataIndex: 'match_type',
                      //sorter: (a, b) => a.match_type.localeCompare(b.match_type),
                    },
                    {
                      title: __('Nazov'),
                      dataIndex: 'name_check',
                      render: (nameCheck,data) => (
                        <CheckColumn isValid = {nameCheck}>
                          { nameCheck?`OK(${data.name_check_score.toFixed(2)})`:`NOK(${data.name_check_score.toFixed(2)})` }
                        </CheckColumn>
                      ),
                      //sorter: (a, b) => ((a.name_check?1:0) > (b.name_check?1:0))?1:-1,
                    },
                    {
                      title: __('Cena'),
                      dataIndex: 'price_check',
                      render: (priceCheck,data) => (
                        <CheckColumn isValid = {priceCheck}>
                            {round(data.product_price,2)}/{round(data.pm_product_price,2)}
                        </CheckColumn>
                      ),
                      //sorter: (a, b) => ((a.price_check?1:0) > (b.price_check?1:0))?1:-1,
                    },
                    {
                      title: __('KategÃ³ria'),
                      dataIndex: 'category_check',
                      render: (categoryCheck,data) => (
                        <CheckColumn isValid = {categoryCheck}>
                          { categoryCheck?"OK":"NOK" }
                        </CheckColumn>
                      ),
                      //sorter: (a, b) => ((a.category_check?1:0) > (b.category_check?1:0))?1:-1,
                    },
                    {
                      title: __('VÃ½robca'),
                      dataIndex: 'manufacturer_check',
                      render: (manufacturerCheck,data) => (
                        <CheckColumn isValid = {manufacturerCheck}>
                          { manufacturerCheck?"OK":"NOK" }
                        </CheckColumn>
                      ),
                      //sorter: (a, b) => ((a.manufacturer_check?1:0) > (b.manufacturer_check?1:0))?1:-1,
                    }
                  ]}
                  dataSource = {recordsForProduct[product.product_id]}
                  onRow = {onChildrenRow}
                  rowSelection = {rowChildrenSelection(product.product_id)}
                  pagination={false} 
                >
                </Table> 
              : null}
          </>,
          rowExpandable: product => true,
        }}
        columns={columns}
        loading={isFetchingAutomaticPairing}
        dataSource={automaticPairingItems}
        onRow={onRow}
        rowSelection={rowSelection}
        onChange={onTableChange}
        //rowClassName={(record,index) =>  index === currentIndex || index === lastClickedIndex ? "selected" : undefined }
        pagination={{
          pageSize: 1000,
        }}
        onExpand={onTableRowExpand}
      />

      <Dropdown overlay={dropDownmenu}
        visible={isDropdownVisible}
        onVisibleChange={(visible) => { setDropdownVisible(visible) }}
      >
        <EmptyDiv top={dropDownTop} left={dropDownLeft} />
      </Dropdown>

      <Modal
        visible={isModalVisible}
        onCancel={handleModalCancel}
        width={1000}
        footer={[
          /*
          <Button key="back" onClick={handleModalReject}>
            {__('Zamietnuť')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleModalPair}>
            {__('Schváliť')}
          </Button>,
          */
         <Button key="back" onClick={handleModalCancel}>
             {__('Zatvoriť')}
         </Button>,

        ]}
      >
        {modalData ?
          <div>
            <ModalContent>
              <ModalProduct>
                <ModalProductName> {modalData.product.name}  </ModalProductName>
                <ModalProductPicture src={modalData.product.image_url} />
                <ModalProductManufacturer>
                  <strong>Manufacturer:</strong> {modalData.product.manufacturer ? modalData.product.manufacturer.name : ''}
                </ModalProductManufacturer>
                <ModalProductCategory>
                  <strong>Category:</strong> {modalData.product.category ? <a rel="noopener noreferrer" target="_blank" href={"https://www.pricemania.sk/" + modalData.product.category.slugs[1].slug}> {modalData.product.category.translations[1].name} </a> : ''}
                </ModalProductCategory>
                <ModalProductEan>
                  <strong>EAN:</strong> {modalData.product.ean}
                </ModalProductEan>
                <ModalProductPartNumber>
                  <strong>Part number:</strong> {modalData.product.part_number}
                </ModalProductPartNumber>
                <ModalProductPrice>
                  <strong>Price:</strong> {modalData.product.price}
                </ModalProductPrice>
                <ModalProductDescription> {modalData.product.description ? modalData.product.description.substring(0, 700) : ""} </ModalProductDescription>
              </ModalProduct>

              <ModalProduct>
                <ModalProductName> {modalData.pm_product.name}  </ModalProductName>
                <ModalProductPicture src={modalData.pm_product.image_url} />
                <ModalProductManufacturer>
                  <strong>Manufacturer:</strong> {modalData.pm_product.manufacturer ? modalData.pm_product.manufacturer.name : ''}
                </ModalProductManufacturer>
                <ModalProductCategory>
                  <strong>Category:</strong> {modalData.pm_product.category ? <a rel="noopener noreferrer" target="_blank" href={"https://www.pricemania.sk/" + modalData.pm_product.category.slugs[1].slug}> {modalData.pm_product.category.translations[1].name} </a> : ''}
                </ModalProductCategory>
                <ModalProductEan>
                  <strong>EAN:</strong> {modalData.pm_product.ean}
                </ModalProductEan>
                <ModalProductPartNumber>
                  <strong>Part number:</strong> {modalData.pm_product.part_number}
                </ModalProductPartNumber>
                <ModalProductPrice>
                  <strong>Price:</strong> {modalData.pm_product.price}
                </ModalProductPrice>
                <ModalProductDescription> {modalData.pm_product.description ? modalData.pm_product.description.substring(0, 700) : ""} </ModalProductDescription>
              </ModalProduct>
            </ModalContent>
          </div>
          : null
        }
      </Modal>



    </>
  )
}

export default AutomaticPairingProduct;