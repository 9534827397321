import React, { useEffect } from "react";
import { CompactPicker } from 'react-color';
import { ApplicationState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Row,
  Col,
  Select,
  Input,
} from 'antd';
import {
  setPrimaryColorValueAction,
  setSecondaryColorValueAction,
  postSettingsAction,
  loadValues,
  setShowWidgetHeaderAction,
  setStatsEmailsFrequencyAction,
  regenerateApiKey,
  setMaxShops,
  setHideWithoutOfferAction,
  setLinkToPricemaniaWithoutOfferAction,
  setCountryIdAction
} from "./slice";
import styled from "styled-components";


const APIKeyWrapper = styled.div`
  display: flex;
`

const RegenerateButton = styled(Button)`
  margin-left: 20px;
`

const AffilSettings = () => { 
  const dispatch = useDispatch();
  const colorsSelector = (state: ApplicationState) => state.AffilSettings.data.colors;
  //const linksSelector = (state: ApplicationState) => state.AffilSettings.data.links;
  //const imgPathSelector = (state: ApplicationState) => state.AffilSettings.data.imagePath;
  const widgetSelector      = (state: ApplicationState) => state.AffilSettings.data.widget;
  const statsEmailsSelector = (state: ApplicationState) => state.AffilSettings.data.statsEmails;
  const userSelector        = (state: ApplicationState) => state.AffilSettings.user;

  const colors = useSelector((state: ApplicationState) => colorsSelector(state));
  //const imgPath = useSelector((state: ApplicationState) => imgPathSelector(state))
  //const links = useSelector((state: ApplicationState) => linksSelector(state))
  const widget = useSelector((state: ApplicationState) => widgetSelector(state));
  const statsEmails = useSelector((state: ApplicationState) => statsEmailsSelector(state));
  const user = useSelector((state: ApplicationState) => userSelector(state));

  useEffect(() => {
    async function init() {
      await dispatch(loadValues());
    }
    init();
  }, [dispatch])
  


  const setPrimaryColor = (color, event) => {
    dispatch(setPrimaryColorValueAction(color.hex));
  }

  const setSecondaryColor = (color, event) => {
    dispatch(setSecondaryColorValueAction(color.hex));
  }

  const setCountryId = (value, event) => {
    dispatch(setCountryIdAction(value));
  }

  const setShowWidgetHeader = (value, event) => {
    dispatch(setShowWidgetHeaderAction(value));
  }

  const setHideWithoutOffer = (value, event) => {
    dispatch(setHideWithoutOfferAction(value));
  }

  const setLinkToPricemaniaWithoutOffer = (value, event) => {
    dispatch(setLinkToPricemaniaWithoutOfferAction(value));
  }

  const setStatsEmailsFrequency = (value, event) => {
    dispatch(setStatsEmailsFrequencyAction(value))
  }
  /*
  const setLink = (event:any) => {
    dispatch(setLinkValueAction(event.target.value,event.target.id));
  }
  */

  const postSettings = (event:any) => {
    dispatch(postSettingsAction());
  }
  /*
  const handleImage = (event:any) => {
    const file = event.target.files[0];
    const data = {'file': file};
    dispatch(uploadLogo(data));

  }
  */
  
  /*
  let imageView  = 
     imgPath ? (
      <Col span={7}>
          <img src={imgPath} alt='logo' style={{width: "100%"}}></img>
      </Col>
    ) : null
    */
  
  const onApiKeyRegenerate = (event:any) => {  
    dispatch(regenerateApiKey());
  }

  return (
    <>
      {/*
      <Row
        justify="start"
        align='top'
        gutter={[16, 16]}
      >
        <Col span={3}>
          Logo:
        </Col>
        <Col span={5}>
            <input type='file' name='logo' id='logo' onChange={handleImage}/>
        </Col>
        {imageView}
      </Row>
       */}
      <Row
        justify="start"
        align='top'
        gutter={[16, 16]}
      >
        <Col span={3}>
          Primárna farba:
              </Col>
        <Col span={12}>
          <CompactPicker
            color={colors.primary ?? '#fff'}
            onChange={setPrimaryColor}
          />
        </Col>
      </Row>
      <Row
        justify="start"
        align='top'
        gutter={[16, 16]}
      >
        <Col span={3}>
          Sekundárna farba:
        </Col>
        <Col span={12}>
          <CompactPicker
            color={colors.secondary ?? '#000'}
            onChange={setSecondaryColor}
          />
        </Col>
      </Row>

      <Row
        justify="start"
        align='top'
        gutter={[16, 16]}
      >
        <Col span={3}>
          Krajina:
        </Col>
        <Col span={12}>
          <Select value={widget.countryId?widget.countryId:"1"} style={{ width: 120 }} onChange={setCountryId}>
            <Select.Option value="1">Slovensko</Select.Option>
            <Select.Option value="2">Česko</Select.Option>
          </Select>
        </Col>
      </Row>

      <Row
        justify="start"
        align='top'
        gutter={[16, 16]}
      >
        <Col span={3}>
          Zobrazovať hlavičku widgetu:
        </Col>
        <Col span={12}>
          <Select value={widget.showHeader?"1":"0"} style={{ width: 120 }} onChange={setShowWidgetHeader}>
            <Select.Option value="1">Áno</Select.Option>
            <Select.Option value="0">Nie</Select.Option>
          </Select>
        </Col>
      </Row>
      
      <Row
        justify="start"
        align='top'
        gutter={[16, 16]}
      >
        <Col span={3}>
          Skrývať neaktívne widgety
        </Col>
        <Col span={12}>
          <Select value={widget.hideWithoutOffer?"1":"0"} style={{ width: 120 }} onChange={setHideWithoutOffer}>
            <Select.Option value="1">Áno</Select.Option>
            <Select.Option value="0">Nie</Select.Option>
          </Select>
        </Col>
      </Row>

      <Row
        justify="start"
        align='top'
        gutter={[16, 16]}
      >
        <Col span={3}>
          Exspirovaný widget smerovať na Pricemania
        </Col>
        <Col span={12}>
          <Select value={widget.linkToPricemaniaWithoutOffer?"1":"0"} style={{ width: 120 }} onChange={setLinkToPricemaniaWithoutOffer}>
            <Select.Option value="1">Áno</Select.Option>
            <Select.Option value="0">Nie</Select.Option>
          </Select>
        </Col>
      </Row>

      <Row
        justify="start"
        align='top'
        gutter={[16, 16]}
      >
        <Col span={3}>
            Maximálny počet obchodov:
        </Col>

        <Col span={12}>
            <Select value={widget.maxShops??5} style={{ width: 120 }} onChange={(value) => dispatch(setMaxShops(value))} >
                <Select.Option value={3}>3</Select.Option>
                <Select.Option value={4}>4</Select.Option>
                <Select.Option value={5}>5</Select.Option>
                <Select.Option value={6}>6</Select.Option>
                <Select.Option value={7}>7</Select.Option>
                <Select.Option value={8}>8</Select.Option>
                <Select.Option value={9}>9</Select.Option>
                <Select.Option value={10}>10</Select.Option>        
            </Select>
        </Col>
      </Row>

      <Row
        justify="start"
        align='top'
        gutter={[16, 16]}
      >
        <Col span={3}>
          Frekvencia notifikačných emailov
        </Col>
        <Col span={12}>
          <Select value={statsEmails?statsEmails.frequency:null} style={{ width: 120 }} onChange={setStatsEmailsFrequency}>
            <Select.Option value="never">Nikdy</Select.Option>
            <Select.Option value="daily">Denne</Select.Option>
            <Select.Option value="weekly">Týždenne</Select.Option>
            <Select.Option value="monthly">Mesačne</Select.Option>
          </Select>
        </Col>
      </Row>
       
      {user && user.apiKey?
        <>
          <Row
            justify="start"
            align='top'
            gutter={[16, 16]}
          >
            <Col span={3}>
              API klúč
            </Col>
            <Col span={12}>
              <APIKeyWrapper>
                <Input style={{maxWidth:250}} value={user.apiKey} disabled={true}/>
                <RegenerateButton type="primary" onClick={onApiKeyRegenerate}>
                  Pregenerovať
                </RegenerateButton>
              </APIKeyWrapper>
            </Col>
          </Row>

          <Row
            justify="start"
            align='top'
            gutter={[16, 16]}
          >
            <Col span={3}>
              
            </Col>
            <Col span={12}>
              <a href="https://www.pricemania.sk/documentation/univerzalny_skript_a_generovanie_cez_API.pdf"> Tu nájdete kompletnú dokumentáciu k API </a>
            </Col>
          </Row>
        </>
      :null
      }

      {/*
      <Row
        justify="start"
        align='top'
        gutter={[16, 16]}
      >
        <Col span={3}>
          Link 1:
        </Col>
        <Col span={12}>
          <Input 
            id="link1"
            onChange={setLink}
            value={links.link1 ?? ""}
          />
        </Col>
      </Row>
      <Row
        justify="start"
        align='top'
        gutter={[16, 16]}
      >
        <Col span={3}>
          Link 2:
        </Col>
        <Col span={12}>
          <Input 
            id="link2"
            onChange={setLink}
            value={links.link2 ?? ""}
          />
        </Col>
      </Row>
      <Row
        justify="start"
        align='top'
        gutter={[16, 16]}
      >
        <Col span={3}>
          Link 3:
        </Col>
        <Col span={12}>
          <Input 
            id="link3"
            onChange={setLink}
            value={links.link3 ?? ""}
          />
        </Col>
      </Row>
      <Row
        justify="start"
        align='top'
        gutter={[16, 16]}
      >
        <Col span={3}>
          Link 4:
        </Col>
        <Col span={12}>
          <Input 
            id="link4"
            onChange={setLink}
            value={links.link4 ?? ""}
          />
        </Col>
      </Row>
      <Row
        justify="start"
        align='top'
        gutter={[16, 16]}
      >
        <Col span={3}>
          Link 5:
        </Col>
        <Col span={12}>
          <Input 
            id="link5"
            onChange={setLink}
            value={links.link5 ?? ""}
          />
        </Col>
      </Row>
      */}
      <Row
        justify="start"
        align='middle'
        gutter={[16, 16]}
      >
        <Col span={3} offset={6} >
          <Button type="primary" block onClick={postSettings}>
            Uložiť
          </Button>
        </Col>
      </Row>
    </>
  );

}

export default AffilSettings;