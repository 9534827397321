import React from "react";
import {Input, Form, Button, Select} from "antd";
import H1 from "../../components/Headings/H1";
import {__} from "../../utilities";
import {useDispatch} from "react-redux";
import {inviteUser} from "./slice";

const {Option} = Select;

const UserInvite = () => {
  const dispatch = useDispatch();
  let role = 'USER'

  const sendInvite = values => {
    values.role = role
    dispatch(inviteUser(values));
  }

  const handleSelect = (e) => {
    role = e;
  }

  return (
    <>
      <H1>{__('Pozvať')}</H1>
      <Form
        layout={"inline"}
        onFinish={sendInvite}
      >
        <Form.Item name={'name'}
                   label="Meno"
                   rules={[
                     {
                       required: true,
                       message: 'Vyplňte toto pole prosím!',
                     },
                   ]}>
          <Input placeholder="meno"
          />
        </Form.Item>
        <Form.Item name={'surname'}
                   label="Priezvisko"
                   rules={[
                     {
                       required: true,
                       message: 'Vyplňte toto pole prosím!',
                     },
                   ]}>
          <Input placeholder="priezvisko"/>
        </Form.Item>
        <Form.Item name='email'
                   label="Email"
                   rules={[
                     {
                       required: true,
                       message: 'Vyplňte toto pole prosím!',
                     },
                   ]}>
          <Input placeholder="email"/>
        </Form.Item>
        <Form.Item name={'role'} label="Rola">
          <Select style={{ width: '200px' }} defaultValue="USER" onChange={handleSelect}>
            <Option value="ADMIN">ADMIN</Option>
            <Option value="USER">USER</Option>
            <Option value="GUEST">GUEST</Option>
            <Option value="AFFIL_ADMIN">AFFIL_ADMIN</Option>
            <Option value="AFFIL_USER">AFFIL_USER</Option>
            <Option value="PAROVAC">PAROVAC</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType={'submit'}>Pozvať</Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default UserInvite;