import { Modal, Row, Col, Typography,} from 'antd';
import React from 'react';
import { rem } from 'polished';
import ConnectedSingleSelect from '../../components/Select/ConnectedSingleSelect';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import {
    manufacturerFiltersDataIsFetching,
    loadManufacturerFilterData,
    manufacturerFiltersDataSelector,
    setFilter,
    manufacturerActiveFiltersSelector,
    updateProductManufacturer
  } from '../../containers/ProductDetail/slice';

const { Text } = Typography;

const ManufacturerModal = (props) => {
    const { isVisible, onClose, title } = props; 
    const dispatch = useDispatch();
    
    const isFetchingManufacturerData = useSelector((state: ApplicationState) =>
        manufacturerFiltersDataIsFetching(state)
    );

    const manufacturerData = useSelector((state: ApplicationState) =>
        manufacturerFiltersDataSelector(state)
    );

    const onChangeManufacturerFilter = async value => {
        await dispatch(loadManufacturerFilterData(value));
    };

    const onSelectManufacturerFilter = async value => {
        dispatch(setFilter('manufacturerSelected', value));
    };

    const manufacturerActiveFilter = useSelector((state: ApplicationState) =>
        manufacturerActiveFiltersSelector(state)
    );

    const handleOk = () => {
        dispatch(updateProductManufacturer(manufacturerActiveFilter));  
        onClose();
    }

    const onCancelClick = () => {
        onClose();
    }

    // 7393638

    console.log("manufacturerActiveFilter");
    console.log(manufacturerActiveFilter);

    return (
        <Modal
                title = {title}
                visible = {isVisible}
                onOk = {handleOk}
                onCancel = {onCancelClick}
            >  
            
            <Row justify="space-around" align="middle">
                <Col>
                    <Text>
                        Vybrať výrobcu:
                    </Text>         
                </Col>
                <Col>
                    <ConnectedSingleSelect
                        isFetching={isFetchingManufacturerData}
                        value={manufacturerActiveFilter}
                        data={manufacturerData}
                        selectWidth={rem(200)}
                        onChange={onChangeManufacturerFilter}
                        onSelect={onSelectManufacturerFilter}
                    />
                </Col>
            </Row>
               
        </Modal>
    )
}

export default ManufacturerModal;