import { createSlice, PayloadAction} from "@reduxjs/toolkit"
import {ApplicationState, AppThunk} from "../../store";
import API from '../../services/API';
import { getValuesFromArray, replaceAll } from "../../utilities";
import { selectedFeedIdsSelector, loadPmProducts } from "./pmSlice";
import { setExportActive } from "../Top50/slice";
import { tokenSelector } from "../App/slice";
const NAMESPACE = 'productSearchList';

export interface ListingProduct {
  imageUrl: string;
  name: string;
  manufacturer_name: string;
  category_name: string;
  active_shops_count: number;
  lowest_price: number;
  productUrl: string;
  productId: number;
  ean: string;
  pn: string;
  cpc: any
}

interface ProductSearchListState {
    activeFilters: {
      manufacturerSelected: {value: number}[],
      categorySelected: {value: number, name?: string}[],
      category: number[],
      since: string,
      changeDate: string,
      lang: number,
      query: string 
    },
    filtersData: {
      manufacturer: {
        isFetching: boolean,
        data: any
      },
      category: {
        isFetching: boolean,
        data: any
      }
    },
    sorterData: {
      type: string,
      order: string;
    },
    filterResults: {
      offset: number
      isFetching: boolean,
      total: number;
      products: any[];
    },
    pagination: {
      pageSize: number;
      current: number
    },
    selectedProdutIds: number[]
}

const initialState : ProductSearchListState = {
  activeFilters: {
    manufacturerSelected: [],
    categorySelected: [],
    category: [],
    since: null,
    changeDate: null,
    lang: 1,
    query: null
  },
  filtersData: {
    manufacturer: {
      isFetching: false,
      data: []
    },
    category: {
      isFetching: false,
      data: []
    }
  },
  sorterData: {
    type: null,
    order: 'asc'
  },
  filterResults: {
    isFetching: false,
    total: 0,
    offset: 0,
    products: []
  },
  pagination: {
    pageSize: 20,
    current: 1
  },
  selectedProdutIds: []
}

export const productSearchListSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
      setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  any}>) => {
        state.activeFilters[action.payload.name] = action.payload.value;
      },
      setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
        state.filtersData[action.payload.name].data = action.payload.value;
      },
      setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
        state.filtersData[action.payload.name].isFetching = action.payload.value;
      },
      
      setIsFetchingFilterResults: (state, action: PayloadAction<{value: boolean}>) => {
        state.filterResults.isFetching = action.payload.value
      },
      setFilterResults: (state, action: PayloadAction<{total: number, products: Array<ListingProduct>}>) => {
        state.filterResults.total    = action.payload.total;
        state.filterResults.products = action.payload.products;
      },
      setSorterData: (state, action: PayloadAction<{type: string, order: string}>) => {
        state.sorterData.type = action.payload.type;
        state.sorterData.order = action.payload.order;
      },
      setPagination: (state, action: PayloadAction<{current: number}>) => {
        state.pagination.current = action.payload.current
      },
      setSelectedProductIds: (state, action: PayloadAction<{selectedProductIds: number[]}>) => {
        state.selectedProdutIds = action.payload.selectedProductIds
      }
    }
});

export const addSelectedProductIds = (productId:number) => (dispatch, getState) => {

  let selectedProductIds = [...selectedProdutIdsSelector(getState())];
  
  console.log("<====>");
  console.log(selectedProductIds);
  console.log(productId);

  selectedProductIds.push(productId);
  dispatch(productSearchListSlice.actions.setSelectedProductIds({selectedProductIds}))
}

export const removeSelectedProductIds = (productId:number) => (dispatch, getState) => {
  let selectedProductIds = [...selectedProdutIdsSelector(getState())];
  var index = selectedProductIds.indexOf(productId);
  if (index > -1) {
    selectedProductIds.splice(index, 1);
  }
  dispatch(productSearchListSlice.actions.setSelectedProductIds({selectedProductIds}))
}

export const clearSelectedProductIds = () => (dispatch, getState) => {
  dispatch(productSearchListSlice.actions.setSelectedProductIds({selectedProductIds:[]}))
}

export const setFilter = (filter: string, value: any) => (dispatch, getState) => {
  dispatch(productSearchListSlice.actions.setActiveFilterValue({name: filter, value}));
}

export const setSorter = (field: string, orderName: string) => (dispatch, getState) => {
  const type:string  = orderName?field:undefined;
  const order:string = orderName === 'descend'?'desc':'asc';
  
  dispatch(productSearchListSlice.actions.setSorterData({ type: type, order:order}));
}

export const paginationSelector = (state: ApplicationState) => state.productSearchList.pagination;

export const setPagination = (current: number) => (dispatch, getState) => {
  dispatch(productSearchListSlice.actions.setPagination({ current:current}));
}

export const loadManufacturerFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
  dispatch(productSearchListSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: true}));

  API.loadManufacturers({query:value,limit:100}).then(response => {
    dispatch(productSearchListSlice.actions.setFilterData({name: 'manufacturer', value: 
      response.manufacturers.map( manufacturer => {
          return {
            name: manufacturer.name,
            value: manufacturer.manufacturer_id
          } 
      })  
    }));

    dispatch(productSearchListSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: false}));   
  });
}



export const manufacturerActiveFiltersSelector = (state: ApplicationState) => state.productSearchList.activeFilters.manufacturerSelected;
export const manufacturerFiltersDataSelector = (state: ApplicationState) => state.productSearchList.filtersData.manufacturer.data;
export const manufacturerFiltersDataIsFetching = (state: ApplicationState) => state.productSearchList.filtersData.manufacturer.isFetching;

export const sinceActiveFilterSelector = (state: ApplicationState) => state.productSearchList.activeFilters.since;
export const changeDateActiveFilterSelector = (state: ApplicationState) => state.productSearchList.activeFilters.changeDate;

export const queryActiveSelector = (state: ApplicationState) => state.productSearchList.activeFilters.query;

export const loadCategoryFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
  dispatch(productSearchListSlice.actions.setIsFetchingFilterData({name: 'category', value: true}));

  API.loadCategories({query:value,limit:100}).then(response => {
    dispatch(productSearchListSlice.actions.setFilterData({name: 'category', value: 
      response.categories.map( category => {
 
        let categoryName;
        if(category && category.translations && category.translations.length){
            const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
            if(categoryTranslation){
                categoryName = categoryTranslation.name;
            }
        }

        return {
          name: categoryName,
          value: category.category_id
        }
      })
    }));
    
    dispatch(productSearchListSlice.actions.setIsFetchingFilterData({name: 'category', value: false}));
  });
}

export const categoryActiveFiltersSelector = (state: ApplicationState) => state.productSearchList.activeFilters.categorySelected;
export const categoryFiltersDataSelector = (state: ApplicationState) => state.productSearchList.filtersData.category.data;
export const categoryFiltersDataIsFetching = (state: ApplicationState) => state.productSearchList.filtersData.category.isFetching;

export const loadProducts = () : AppThunk => async (dispatch, getState) => {
  dispatch(productSearchListSlice.actions.setIsFetchingFilterResults({value:true}));
  const filterCategoryIds = getValuesFromArray<number>(
      categoryActiveFiltersSelector(getState())
  );
  const filterManufacturerIds = getValuesFromArray<number>(
      manufacturerActiveFiltersSelector(getState())
  );

  // const offset = (getState().productSearchList.pagination.current-1) * getState().productSearchList.pagination.pageSize;   
  
  API.loadProducts({
      countryId: getState().productSearchList.activeFilters.lang,
      withCategory: true,
      limit: 20,
      offset: 0,
      categoryIds: filterCategoryIds?filterCategoryIds:undefined,
      manufacturerIds: filterManufacturerIds?filterManufacturerIds:undefined,
      sortType: getState().productSearchList.sorterData.type?getState().productSearchList.sorterData.type:undefined,
      sortOrder:  getState().productSearchList.sorterData.order,
      createdFrom: getState().productSearchList.activeFilters.since?getState().productSearchList.activeFilters.since:undefined,
      query: getState().productSearchList.activeFilters.query? getState().productSearchList.activeFilters.query:undefined,
      includeMainSubcategories: true,
      withSlugs: true,
      queryType: "admin"
  }).then(response => {
      const listingProducts: Array<ListingProduct> = response.products.map(
          product => {
              const countryId = getState().productSearchList.activeFilters.lang;
              let categoryName;
              if (
                  product.category &&
                  product.category.translations &&
                  product.category.translations.length
              ) {
                  const categoryTranslation = product.category.translations.find(
                      translation => translation.language_id === 1
                  );
                  if (categoryTranslation) {
                      categoryName = categoryTranslation.name;
                  }
              }

              let lowestPrice;
              let active_shops_count;
              if (product.aggregations) {
                  const productAggregation = product.aggregations.find(
                      aggregation => aggregation.country_id === 1
                  );
                  if (
                      productAggregation &&
                      productAggregation.min_price !== 9999999
                  ) {
                      lowestPrice = productAggregation.min_price;
                      active_shops_count = productAggregation.active_shops_count;
                  }
              }
              
              let productUrl = null; 
              if(product.category.slugs.length && product.slugs.length){
                  let categorySlug = product.category.slugs.find(slug => slug.language_id === countryId);
                  let productSlug  = product.slugs.find(slug => slug.language_id === countryId);
                  let baseUrl      = countryId === 1?"https://www.pricemania.sk":"https://www.pricemania.cz";
                  
                  if(!productSlug){
                    productSlug = product.slugs[0];
                  } 
                  
                  if(categorySlug && productSlug){
                    productUrl = baseUrl+"/"+categorySlug.slug+"/"+productSlug.slug;
                  }
              }

              let eans = product.goods.map(good => {
                if(good.ean){
                  return good.ean.ean;
                }
                return "";
              });

              eans = eans.filter(function(item, pos) {
                return eans.indexOf(item) === pos;
              }).filter(item => item);

              return {
                  productId: product.product_id,
                  name: product.name,
                  imageUrl: `https://www.pricemania.sk/assets/product/${product.product_id}/detail_image.jpg`,
                  manufacturer_name: product.manufacturer
                      ? product.manufacturer.name
                      : '',
                  category_name: categoryName,
                  active_shops_count: active_shops_count ? active_shops_count : 0,
                  lowest_price: lowestPrice,
                  productUrl: productUrl,
                  ean: eans.join(','),
                  pn: "",
                  cpc: 0
              };
          }
      );
      dispatch(
          productSearchListSlice.actions.setIsFetchingFilterResults({ value: false })
      );
      dispatch(
          productSearchListSlice.actions.setFilterResults({
              total: response.total,
              products: listingProducts,
          })
      );
      dispatch(clearSelectedProductIds());
  });
}

export const getTop50Products = async (getState, categoryId = null) => {
  let filterCategoryIds = getValuesFromArray<number>(
    categoryActiveFiltersSelector(getState())
  );
  const filterManufacturerIds = getValuesFromArray<number>(
    manufacturerActiveFiltersSelector(getState())
  );

  if(!filterCategoryIds || filterCategoryIds.length === 0){
    filterCategoryIds = categoryId
  }

  const token = tokenSelector(getState());
  API.setToken(token);
  
  const response = await API.loadProducts({
    countryId: getState().productSearchList.activeFilters.lang,
    withCategory: true,
    limit: 50,
    offset: 0,
    categoryIds: filterCategoryIds?filterCategoryIds:undefined,
    manufacturerIds: filterManufacturerIds?filterManufacturerIds:undefined,
    sortType: 'base_impressions-created',
    sortOrder:  getState().productSearchList.sorterData.order,
    createdFrom: getState().productSearchList.activeFilters.since?getState().productSearchList.activeFilters.since:undefined,
    query: getState().productSearchList.activeFilters.query? getState().productSearchList.activeFilters.query:undefined,
    includeMainSubcategories: true,
    withSlugs: true,
    queryType: "admin",
    showInComparision: true,
    prepareForDetail: true,
    clickType: 149
  });

  const listingProducts: Array<ListingProduct> = response.products.map(
    product => {
      let categoryName;
      if (
        product.category &&
        product.category.translations &&
        product.category.translations.length
      ) {
        const categoryTranslation = product.category.translations.find(
          translation => translation.language_id === 1
        );
        if (categoryTranslation) {
          categoryName = categoryTranslation.name;
        }
      }

      let lowestPrice;
      let active_shops_count;
      let cpc;
      if (product.aggregations) {
        const productAggregation = product.aggregations.find(
          aggregation => aggregation.country_id === 1
        );
        if (
          productAggregation &&
          productAggregation.min_price !== 9999999
        ) {
          lowestPrice = productAggregation.min_price;
          active_shops_count = productAggregation.active_shops_count;
          cpc = productAggregation.cpc;
        }
      }

      let showWidgetButton = false;
      if(product.goods.find(good => good.show_in_autocomplete === true)){
        showWidgetButton = true;
      }

      return {
        productId: product.product_id,
        name: product.name,
        imageUrl: `https://www.pricemania.sk/assets/product/${product.product_id}/detail_image.jpg`,
        manufacturer_name: product.manufacturer
          ? product.manufacturer.name
          : '',
        category_name: categoryName,
        active_shops_count: active_shops_count ? active_shops_count : 0,
        lowest_price: lowestPrice,
        productUrl: "",
        ean: "",
        pn: "",
        rank: "TOP " + (response.products.indexOf(product) +1),
        cpc: cpc?(Math.round(cpc * 100) / 100):'N/A',
        show_widget_button: showWidgetButton
      };
    }
  )

  return {listingProducts, total: response.total};
}

export const exportTop50Products = (window,document) : AppThunk => async (dispatch, getState) => {
  dispatch(setExportActive(true));

  const {listingProducts} = await getTop50Products(getState);
  
  const csv =
        `nazov,kategoria,top,cpc\n` +
  listingProducts.map(listingProduct => {
    return `"${replaceAll(listingProduct.name,'"'," ")}","${listingProduct.category_name}","${listingProducts.indexOf(listingProduct) +1}","${listingProduct.cpc}"` 
  }).join('\n');
  
  const url = window.URL.createObjectURL(
      new Blob(["\ufeff",csv],{type:"text/plain;charset=UTF-8"}),
  );

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
      'download',
      `export.csv`,
  );

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();
  link.parentNode.removeChild(link);

  dispatch(setExportActive(false));
}

export const loadTop50Products = (categoryId = null) : AppThunk => async (dispatch, getState) => {
  
  if(categoryId){
    const categoryResponse = await API.loadCategories({
       categoryIds: [categoryId]
    });
    
    if(categoryResponse.categories && categoryResponse.categories.length){
      const category  = categoryResponse.categories[0];
    
      let categoryName;
      if(category && category.translations && category.translations.length){
          const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
          if(categoryTranslation){
              categoryName = categoryTranslation.name;
          }
      }

      dispatch(setFilter('categorySelected', [{value: categoryId, label:categoryName}]));
    }
  }


  dispatch(productSearchListSlice.actions.setIsFetchingFilterResults({value:true}));
  const {listingProducts, total} = await getTop50Products(getState, categoryId);
  
  dispatch(
    productSearchListSlice.actions.setIsFetchingFilterResults({ value: false })
  );
  dispatch(
    productSearchListSlice.actions.setFilterResults({
      total: total,
      products: listingProducts,
    })
  );
  dispatch(clearSelectedProductIds());
}

export const pairProducts = () : AppThunk => async (dispatch, getState) => {
  const productIds = selectedProdutIdsSelector(getState());
  const feedIds    = selectedFeedIdsSelector(getState());
  
  if(productIds.length === 1 && feedIds.length > 0){
    API.pairPmProducts(productIds[0],{},{feedIds:feedIds}).then(response => {
      dispatch(loadProducts());
      dispatch(loadPmProducts());
    });
  }
};

export const filterResultsTotalSelector      = (state: ApplicationState) => state.productSearchList.filterResults.total;
export const filterResultsProductsSelector   = (state: ApplicationState) => state.productSearchList.filterResults.products;
export const filterResultsIsFetchingSelector = (state: ApplicationState) => state.productSearchList.filterResults.isFetching;
export const filterResultsOffsetSelector     = (state: ApplicationState) => state.productSearchList.filterResults.offset;

export const selectedProdutIdsSelector       = (state: ApplicationState) => state.productSearchList.selectedProdutIds;

export default productSearchListSlice.reducer;
