import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState, AppThunk } from "../../store";
import API from "../../services/API";
import { getRandomString } from "../../utilities";

const NAMESPACE = 'categoryLandingPages';

interface CategoryLandingPageParameter {
    parameter_id: string;
    parameter_value_id: string,
    name: string,
    value_name: string
}

interface CategoryLandingPageSort {
    type: string;
}

interface CategoryLandingPageTranslation {
    active: boolean;
    title: string;
    header: string;
    short_description: string;
    description: string;
    language_id: number;
}

interface CategoryLandingPageManufacturer {
    manufacturer_id: number,
    name: string
}

interface CategoryLandingPageCategory {
    category_id: number,
    name: string
}

interface CategoryLandingPage {
    _id: string,
    parameters: CategoryLandingPageParameter[];
    manufacturers: CategoryLandingPageManufacturer[];
    categories: CategoryLandingPageCategory[],
    sort: CategoryLandingPageSort,
    translations: CategoryLandingPageTranslation[],
    price_from: number,
    price_to: number 
}

interface Category {
    _id: string,
    category_id: number,
    landing_pages: CategoryLandingPage[]
}

interface CategoryLandingPagesState {
    locale_id: number,
    category: Category,
}

const initialState : CategoryLandingPagesState = {
    locale_id: 1,
    category:{
        _id: null, 
        category_id: null,
        landing_pages: []
    },
}

export const categoryLandingPagesSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setCategory: (state, action: PayloadAction<Category>) => {
            state.category = action.payload
        },
        setLocaleId: (state, action: PayloadAction<number>) => {
            state.locale_id = action.payload
        },
        fillNames: (state, action: PayloadAction<{manufacturers,categories,parameters}>) => {
            const landingPages = state.category.landing_pages;
            for(const landingPage of landingPages){
                for(const manufacturer of landingPage.manufacturers){
                    const currentManufacturer = action.payload.manufacturers.find(m=>m.manufacturer_id === manufacturer.manufacturer_id);
                    if(currentManufacturer){
                        manufacturer.name = currentManufacturer.name;
                    }
                }
                for(const category of landingPage.categories){
                    const currentCategory = action.payload.categories.find(c=>c.category_id === category.category_id);
                    if(currentCategory){
                        let categoryName;
                        if(currentCategory && currentCategory.translations && currentCategory.translations.length){
                            const categoryTranslation = currentCategory.translations.find(translation => translation.language_id === 1);
                            if(categoryTranslation){
                                categoryName = categoryTranslation.name;
                            }
                        }
                        category.name = categoryName;
                    }
                }
                for(const parameter of landingPage.parameters){
                    const currentParameter = action.payload.parameters.find(p=>p._id === parameter.parameter_id);
                    if(currentParameter){
                        parameter.name = currentParameter.internal_name;
                        const currentParameterValue = currentParameter.values.find(v => v._id === parameter.parameter_value_id);
                        if(currentParameterValue){
                            parameter.value_name = currentParameterValue.internal_name;
                        }
                    }
                }
            }
        },
        addManufacturer: (state, action: PayloadAction<{landingPageId:string,manufacturerId:number}>) => {
            const landingPages = state.category.landing_pages;
            const landingPage = landingPages.find(l => l._id === action.payload.landingPageId);
            if(landingPage && !landingPage.manufacturers.find(m => m.manufacturer_id === action.payload.manufacturerId)){
                landingPage.manufacturers.push({
                    manufacturer_id: action.payload.manufacturerId,
                    name: null
                })
            }
        },
        removeManufacturer: (state, action: PayloadAction<{landingPageId:string,manufacturerId:number}>) => {
            const landingPages = state.category.landing_pages;
            const landingPage = landingPages.find(l => l._id === action.payload.landingPageId);
            if(landingPage){
                landingPage.manufacturers = landingPage.manufacturers.filter(m => m.manufacturer_id !== action.payload.manufacturerId);
            }
        },
        addCategory: (state, action: PayloadAction<{landingPageId:string,categoryId:number}>) => {
            const landingPages = state.category.landing_pages;
            const landingPage = landingPages.find(l => l._id === action.payload.landingPageId);
            if(landingPage && !landingPage.categories.find(c => c.category_id === action.payload.categoryId)){
                landingPage.categories.push({
                    category_id: action.payload.categoryId,
                    name: null
                })
            }
        },
        removeCategory: (state, action: PayloadAction<{landingPageId:string,categoryId:number}>) => {
            const landingPages = state.category.landing_pages;
            const landingPage = landingPages.find(l => l._id === action.payload.landingPageId);
            if(landingPage){
                landingPage.categories = landingPage.categories.filter(c => c.category_id !== action.payload.categoryId);
            }
        },
        addParameter: (state, action: PayloadAction<{landingPageId:string,parameterId:string,parameterValueId:string}>) => {
            const landingPages = state.category.landing_pages;
            const landingPage = landingPages.find(l => l._id === action.payload.landingPageId);
            if(landingPage){
                landingPage.parameters.push({
                    parameter_id: action.payload.parameterId,
                    parameter_value_id: action.payload.parameterValueId,
                    name: null, 
                    value_name: null
                })
            }
        },
        removeParameter: (state, action: PayloadAction<{landingPageId:string,parameterId:string,parameterValueId:string}>) => {
            const landingPages = state.category.landing_pages;
            const landingPage = landingPages.find(l => l._id === action.payload.landingPageId);

            if(landingPage){
                landingPage.parameters = landingPage.parameters.filter(p => { 
                    return !(p.parameter_id === action.payload.parameterId && p.parameter_value_id === action.payload.parameterValueId);
                })
            }
        },
        setSorting: (state, action: PayloadAction<{landingPageId:string,type:string}>) => {
            const landingPages = state.category.landing_pages;
            const landingPage = landingPages.find(l => l._id === action.payload.landingPageId);

            if(landingPage){
                landingPage.sort.type = action.payload.type
            }
        },
        addLandingPage: (state, action: PayloadAction<{landingPage:CategoryLandingPage}>) => {
            const landingPages = state.category.landing_pages;
            landingPages.push(action.payload.landingPage);
        },
        changeTranslatedValue: (state, action: PayloadAction<{landingPageId:string,localeId:number,dataIndex:string,value:string}>) => {
            const landingPages = state.category.landing_pages;
            const landingPage = landingPages.find(l => l._id === action.payload.landingPageId);

            if(landingPage){
                const translation = landingPage.translations.find(translation => translation.language_id === action.payload.localeId);
                if(translation){
                    translation[action.payload.dataIndex] = action.payload.value;
                }
            }
        },
        updateLangingPageField: (state, action: PayloadAction<{landingPageId:string,dataIndex:string,value:string}>) => {
            const landingPages = state.category.landing_pages;
            const landingPage = landingPages.find(l => l._id === action.payload.landingPageId);
            if(landingPage){
                landingPage[action.payload.dataIndex] = action.payload.value
            }
        }
    }
});


export const setLocaleId = (localeId:number): AppThunk => async (dispatch, getState) => {
    dispatch(categoryLandingPagesSlice.actions.setLocaleId(localeId));
}

export const loadCategoryLandingPages = (categoryId:number) : AppThunk => async (dispatch, getState) => {
    API.loadCategories({categoryIds:[categoryId]}).then(response => {
        const category = response.categories.length?response.categories[0]:null;
        if(category){
            const rCategory:Category = {
                _id: category._id,
                category_id: category.category_id,
                landing_pages: category.landing_pages?category.landing_pages.map(landingPage => {
                    return {
                        _id: landingPage._id,
                        categories: landingPage.categories?landingPage.categories.map(category => {
                           return {
                               category_id: category,
                               name: null
                           }
                        }):[],
                        manufacturers: landingPage.manufacturers?landingPage.manufacturers.map(manufacturer => {
                           return {
                               manufacturer_id: manufacturer,
                               name: null
                           }
                        }):[],
                        parameters: landingPage.parameters?landingPage.parameters.map(parameter => {
                           return {
                               parameter_id: parameter.parameter_id,
                               parameter_value_id: parameter.parameter_value_id,
                               name: null, 
                               value_name: null
                           } 
                        }):[],
                        sort: landingPage.sort?{ type: landingPage.sort.type, order: landingPage.sort.order }:null,
                        price_from: landingPage.price_from,
                        price_to: landingPage.price_to,
                        translations: landingPage.translations?landingPage.translations.map(translation => {
                            return {
                                active: translation.active?translation.active:false,
                                title: translation.title,
                                header: translation.header,
                                short_description: translation.short_description,
                                description: translation.description,
                                language_id: translation.language_id
                            }
                        }):[]
                    }
                }):[]
            }
            dispatch(categoryLandingPagesSlice.actions.setCategory(rCategory))
            dispatch(fillNames())
        }
    })
}

export const fillNames = () :AppThunk => async (dispatch, getState) => {
    const landingPages = landingPagesSelector(getState());

    const manufacturerIDs = [];
    const categoryIds = [];
    const parameterIds = [];
    const parameterValueIds = [];

    for(const landingPage of landingPages){
        manufacturerIDs.push(...landingPage.manufacturers.map(m => m.manufacturer_id));
        categoryIds.push(...landingPage.categories.map(c=>c.category_id));
        parameterIds.push(...landingPage.parameters.map(p=>p.parameter_id));
        parameterValueIds.push(...landingPage.parameters.map(p=>p.parameter_value_id));
    } 

    const manufacturersResponse = await API.loadManufacturers({manufacturerIds:manufacturerIDs,limit:1000});
    const categoriesResponse = await API.loadCategories({categoryIds:categoryIds,limit:1000});
    const parametersResponse = await API.loadParameters({filterParameterIds:parameterIds});
    
    dispatch(categoryLandingPagesSlice.actions.fillNames({manufacturers:manufacturersResponse.manufacturers,categories:categoriesResponse.categories,parameters:parametersResponse.items}));
}

export const changeTranslatedName = (landingPageId:string, localeId:number,dataIndex:string,value:string) :AppThunk => async (dispatch, getState) => {
    dispatch(categoryLandingPagesSlice.actions.changeTranslatedValue({landingPageId,localeId,dataIndex,value}));
}

export const addManufacturer = (landingPageId:string,manufacturerId:number) :AppThunk => async (dispatch, getState) => {
    dispatch(categoryLandingPagesSlice.actions.addManufacturer({landingPageId,manufacturerId}));
    dispatch(fillNames());
}

export const removeManufacturer = (landingPageId:string,manufacturerId:number) :AppThunk => async (dispatch, getState) => {
    dispatch(categoryLandingPagesSlice.actions.removeManufacturer({landingPageId,manufacturerId}))
}

export const addCategory = (landingPageId:string,categoryId:number) :AppThunk => async (dispatch, getState) => {
    dispatch(categoryLandingPagesSlice.actions.addCategory({landingPageId,categoryId}));
    dispatch(fillNames());
}

export const removeCategory = (landingPageId:string,categoryId:number) :AppThunk => async (dispatch, getState) => {
    dispatch(categoryLandingPagesSlice.actions.removeCategory({landingPageId,categoryId}))
}

export const addParameter = (landingPageId:string,parameterId:string,parameterValueId:string) :AppThunk => async (dispatch, getState) => {
    dispatch(categoryLandingPagesSlice.actions.addParameter({landingPageId,parameterId,parameterValueId}));
    dispatch(fillNames());
}

export const removeParameter = (landingPageId:string,parameterId:string,parameterValueId:string) :AppThunk => async (dispatch, getState) => {
    dispatch(categoryLandingPagesSlice.actions.removeParameter({landingPageId,parameterId,parameterValueId}));
}

export const setSorting = (landingPageId:string,type:string) :AppThunk => async (dispatch, getState) => {
    dispatch(categoryLandingPagesSlice.actions.setSorting({landingPageId,type}));
}

export const updateLangingPageField = (landingPageId:string,dataIndex:string,value:string) :AppThunk => async (dispatch, getState) => {
    dispatch(categoryLandingPagesSlice.actions.updateLangingPageField({landingPageId,dataIndex,value}));
}

export const createEmptyLandingPage = () :AppThunk => async (dispatch, getState) => {
    const landingPage:CategoryLandingPage = {
        _id: "NEW_"+getRandomString(10),
        categories: [],
        manufacturers: [],
        parameters: [],
        sort:{
            type: null,
        },
        price_from: null, 
        price_to: null,
        translations:[
            {
                active: false,
                short_description: null,
                description: null,
                header: null,
                title: null,
                language_id:1
            },
            {
                active: false,
                short_description: null,
                description: null,
                header: null,
                title: null,
                language_id:2,
            }
        ]
    }
    dispatch(categoryLandingPagesSlice.actions.addLandingPage({landingPage:landingPage}));
}

export const categorySelector     = (state: ApplicationState) => state.categoryLandingPages.category;
export const localeIdSelector     = (state: ApplicationState) => state.categoryLandingPages.locale_id;
export const landingPagesSelector = (state: ApplicationState) => state.categoryLandingPages.category.landing_pages;

export default categoryLandingPagesSlice.reducer;







