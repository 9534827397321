import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, ApplicationState } from "../../store";
import API from "../../services/API";
import { getRandomString, getValuesFromArray } from "../../utilities";
import { productParametersSelector } from "../ProductDetail/slice";

const NAMESPACE = 'productParameterBulkEdit';

export interface ProductParameter {
    _id: string,
    parameter_id: string,
    parameter_value_id: string,
    value: string | number,
}

interface Product {
    key: number,
    product_id: number, 
    name: string,
    category_id: number,
    manufacturer_id: number,
    category_name: string;
    manufacturer_name: string;
    parameters: ProductParameter[]
}

interface ParameterListState {
    activeFilters: {
        name: string,
        category: { value: number}[],
        manufacturer: { value: number}[],
        withoutParameter: { value: string}[],
        show: boolean,
        index: boolean
    },
    filtersData: {
        category: {
            isFetching: boolean,
            data: any
        },
        manufacturer: {
            isFetching: boolean,
            data: any
        },
        withoutParameter: {
            isFetching: boolean,
            data: any
        },
    },
    filterResults: {
        offset: number
        isFetching: boolean,
        total: number;
        products: Product[];
    },
    pagination: {
        pageSize: number;
        current: number
    },
    sorterData: {
        type: string,
        order: string;
    },
    selectedKeys: number[],
    updating: boolean
}

const initialState : ParameterListState = {
    activeFilters: {
        name: "",
        category: [],
        manufacturer: [],
        withoutParameter: [],
        show: null,
        index: null
    },
    filtersData: {
        category: {
            isFetching: false,
            data: []
        },
        manufacturer: {
            isFetching: false,
            data: []
        },
        withoutParameter:{
            isFetching: false,
            data: []
        }
    },
    filterResults: {
        isFetching: false,
        total: 0,
        offset: 0,
        products: []
    },
    pagination: {
        pageSize: 1000,
        current: 1
    },
    sorterData: {
        type: null,
        order: 'asc'
    },
    selectedKeys: [],
    updating:false
}

export const productParameterBulkEditSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setFilterResults: (state, action: PayloadAction<{total: number, products: Array<Product>}>) => {
            state.filterResults.total       = action.payload.total;
            state.filterResults.products = action.payload.products;
        },
        setIsFetchingFilterResults: (state, action: PayloadAction<{value: boolean}>) => {
            state.filterResults.isFetching = action.payload.value
        },
        setPagination: (state, action: PayloadAction<{current: number}>) => {
            state.pagination.current = action.payload.current
        },
        setSorterData: (state, action: PayloadAction<{type: string, order: string}>) => {
            state.sorterData.type = action.payload.type;
            state.sorterData.order = action.payload.order;
        },
        setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | number | null | boolean}>) => {
            state.activeFilters[action.payload.name] = action.payload.value;
        },
        setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },
        setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
        setSelectedKeys: (state, action: PayloadAction<number[]>) => {
            state.selectedKeys = action.payload;
        },
        setUpdating: (state, action: PayloadAction<boolean>) => {
            state.updating = action.payload;
        }
    }
});

export const setSorter = (field: string, orderName: string) => (dispatch, getState) => {
    const type:string  = orderName?field:undefined;
    const order:string = orderName === 'descend'?'desc':'asc';
    
    dispatch(productParameterBulkEditSlice.actions.setSorterData({ type: type, order:order}));
}

export const loadProducts = () : AppThunk => async (dispatch, getState) => {
    dispatch(productParameterBulkEditSlice.actions.setIsFetchingFilterResults({value:true}));

    const filterCategoryIds = getValuesFromArray<number>(categoryActiveFiltersSelector(getState()));
    const filterManufacturIds = getValuesFromArray<number>(manufacturerActiveFiltersSelector(getState()));
    const filterWithoutParameterIds = getValuesFromArray<string>(withoutParameterActiveFiltersSelector(getState()));
    const filterName = activeNameSelector(getState());

    API.loadProducts({
        limit: 200,
        categoryIds: filterCategoryIds?filterCategoryIds:undefined,
        manufacturerIds: filterManufacturIds?filterManufacturIds:undefined,
        projectType: 'parameters',
        queryType: 'parameters',
        showInComparision: true,
        query: filterName?filterName:undefined,
        includeMainSubcategories: true,
        filterWithoutParameters: filterWithoutParameterIds?filterWithoutParameterIds:undefined
    }).then(async response => {
        const categoryIds = response.products.map(product => product.category_id).filter(o=>o).filter((v, i, a) => a.indexOf(v) === i);
        const manufacturerIds = response.products.map(product => product.manufacturer_id).filter(o=>o).filter((v, i, a) => a.indexOf(v) === i);
        
        const categories = categoryIds.length?(await API.loadCategories({categoryIds:categoryIds, limit: 1000})).categories:[];
        const manufacturers = manufacturerIds.length?(await API.loadManufacturers({manufacturerIds: manufacturerIds, limit: 1000})).manufacturers:[];


        const products:Product[] = response.products.map(product => {
            const category = categories.find(c => c.category_id === product.category_id);
            let categoryName = '---';
            if(category && category.translations && category.translations.length){
                const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
                if(categoryTranslation){
                    categoryName = categoryTranslation.name;
                }
            }

            const manufacturer = manufacturers.find(m => m.manufacturer_id === product.manufacturer_id);

            return {
                key: product.product_id,
                product_id: product.product_id,
                name: product.name,
                category_id: product.category_id,
                category_name: categoryName,
                manufacturer_id: product.manufacturer_id,
                manufacturer_name: manufacturer?manufacturer.name:"---",
                parameters: product.parameters?product.parameters.map(parameter => {
                    return {
                      _id: parameter._id,
                      parameter_id: parameter.parameter_id,
                      parameter_value_id: parameter.parameter_value_id,
                      value: parameter.value as any
                    }
                }):[]
            }
        });

        dispatch(productParameterBulkEditSlice.actions.setFilterResults({total:response.total,products:products}));
        dispatch(productParameterBulkEditSlice.actions.setIsFetchingFilterResults({value:false}));
    })
}

export const loadCategoryFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(productParameterBulkEditSlice.actions.setIsFetchingFilterData({name: 'category', value: true}));
  
    API.loadCategories({query:value,limit:100}).then(response => {
      dispatch(productParameterBulkEditSlice.actions.setFilterData({name: 'category', value: 
        response.categories.map( category => {
   
          let categoryName;
          if(category && category.translations && category.translations.length){
              const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
              if(categoryTranslation){
                  categoryName = categoryTranslation.name;
              }
          }
  
          return {
            name: category.parents_names ? [...category.parents_names].reverse().filter((item, index) => index % 2 === 0).join(' < ') : categoryName,
            value: category.category_id
          }
        })
      }));
      
      dispatch(productParameterBulkEditSlice.actions.setIsFetchingFilterData({name: 'category', value: false}));
    });
}

export const loadManufacturerFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(productParameterBulkEditSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: true}));
  
    API.loadManufacturers({query:value,limit:500}).then(response => {
      dispatch(productParameterBulkEditSlice.actions.setFilterData({name: 'manufacturer', value: 
        response.manufacturers.map( manufacturer => {
            return {
              name: manufacturer.name,
              value: manufacturer.manufacturer_id
            } 
        })  
      }));
  
      dispatch(productParameterBulkEditSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: false}));   
    });
}

export const loadParameterFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(productParameterBulkEditSlice.actions.setIsFetchingFilterData({name: 'withoutParameter', value: true}));
        API.loadParameters({filterInternalName:value}).then(response => {
            dispatch(productParameterBulkEditSlice.actions.setFilterData({name: 'withoutParameter', value: 
            response.items.map( item => {
                return {
                    name: item.internal_name,
                    value: item._id
                } 
            })  
        }));

        dispatch(productParameterBulkEditSlice.actions.setIsFetchingFilterData({name: 'withoutParameter', value: false}));
    })
}

export const setPagination = (current: number) => (dispatch, getState) => {
    dispatch(productParameterBulkEditSlice.actions.setPagination({ current:current}));
}

export const setFilter = (filter: string, value: number[] | string | number | null | boolean) => (dispatch, getState) => {
    dispatch(productParameterBulkEditSlice.actions.setActiveFilterValue({name: filter, value}));
    //dispatch(loadProducts());
}

export const setSelectedKeys = (selectedKeys:number[]) => (dispatch, getState) => {
    dispatch(productParameterBulkEditSlice.actions.setSelectedKeys(selectedKeys));
}

export const updateParameters = () => async (dispatch, getState) => {
    const parameters = productParametersSelector(getState());
    const selectedKeys = selectedKeysSelector(getState());
    const products = productsSelector(getState());

    const parametersToUpdate = products.filter(p => selectedKeys.includes(p.product_id)).map(product => {
        return {
            product_id: product.product_id,
            parameters: parameters.map(parameter => {
                const productParameter = product.parameters.find(pp => pp.parameter_id === parameter.parameter_id);
                return {
                    _id: productParameter?productParameter._id:"NEW_"+getRandomString(10),
                    parameter_id: parameter.parameter_id,
                    parameter_value_id: parameter.parameter_value_id,
                    value: parameter.value
                }
            })
        }
    });
    
    const promises = parametersToUpdate.map(ptu => {
        return API.updateProductParameters(ptu.product_id,{},{
            update_mode: 'update',
            parameters: ptu.parameters
        })
    });
    
    dispatch(productParameterBulkEditSlice.actions.setUpdating(true));
    await Promise.all(promises);
    dispatch(productParameterBulkEditSlice.actions.setUpdating(false));
    await dispatch(loadProducts());
}

export const productsSelector = (state: ApplicationState) => state.productParameterBulkEdit.filterResults.products;
export const totalSelector = (state: ApplicationState) => state.productParameterBulkEdit.filterResults.total;
export const paginationSelector = (state: ApplicationState) => state.productParameterBulkEdit.pagination;
export const productIsFetchingSelector = (state: ApplicationState) => state.productParameterBulkEdit.filterResults.isFetching;

export const categoryActiveFiltersSelector = (state: ApplicationState) => state.productParameterBulkEdit.activeFilters.category;
export const categoryFiltersDataSelector = (state: ApplicationState) => state.productParameterBulkEdit.filtersData.category.data;
export const categoryFiltersDataIsFetching = (state: ApplicationState) => state.productParameterBulkEdit.filtersData.category.isFetching;

export const manufacturerActiveFiltersSelector = (state: ApplicationState) => state.productParameterBulkEdit.activeFilters.manufacturer;
export const manufacturerFiltersDataSelector = (state: ApplicationState) => state.productParameterBulkEdit.filtersData.manufacturer.data;
export const manufacturerFiltersDataIsFetching = (state: ApplicationState) => state.productParameterBulkEdit.filtersData.manufacturer.isFetching;

export const withoutParameterActiveFiltersSelector = (state: ApplicationState) => state.productParameterBulkEdit.activeFilters.withoutParameter;
export const withoutParameterFiltersDataSelector = (state: ApplicationState) => state.productParameterBulkEdit.filtersData.withoutParameter.data;
export const withoutParameterFiltersDataIsFetching = (state: ApplicationState) => state.productParameterBulkEdit.filtersData.withoutParameter.isFetching;

export const activeNameSelector = (state: ApplicationState) => state.productParameterBulkEdit.activeFilters.name;

export const activeFilterShowSelector = (state: ApplicationState) => state.productParameterBulkEdit.activeFilters.show;
export const activeFilterIndexSelector = (state: ApplicationState) => state.productParameterBulkEdit.activeFilters.index;
export const activeFilterNameSelector = (state: ApplicationState) => state.productParameterBulkEdit.activeFilters.name;

export const selectedKeysSelector = (state: ApplicationState) => state.productParameterBulkEdit.selectedKeys;
export const updatingSelector = (state: ApplicationState) => state.productParameterBulkEdit.updating;

export default productParameterBulkEditSlice.reducer;







