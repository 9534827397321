import React  from 'react';
import {
  Select,
  DatePicker,
  Button,
  Tooltip,
  Typography,
  Input,
  Row,
  Col,
  Checkbox,
  Popover,
  notification
} from 'antd';

import { ReloadOutlined } from '@ant-design/icons';
import { __, FORMAT_HUMANE_DATE, formatDate, FORMAT_HUMANE_DATETIME } from '../../utilities';
import { useDispatch, useSelector } from 'react-redux';
import ConnectedMultiSelect from '../../components/Select/ConnectedMultiSelect';
import {
  loadManufacturerFilterData,
  manufacturerActiveFiltersSelector,
  manufacturerFiltersDataIsFetching,
  manufacturerFiltersDataSelector,
  setFilter,
  setPagination,
  filterResultsProductsSelector,
  loadCategoryFilterData,
  categoryActiveFiltersSelector,
  categoryFiltersDataIsFetching,
  categoryFiltersDataSelector,
  loadProducts,
  setSorter,
  filterResultsIsFetchingSelector,
  addSelectedProductIds,
  removeSelectedProductIds,
  selectedProdutIdsSelector,
  pairProducts,
  clearSelectedProductIds,
} from './slice';
import { rem } from 'polished';
import { ApplicationState } from '../../store';
import H1 from '../../components/Headings/H1';
import { ArrowRightOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";
import { VirtualTable } from './VirtualTable';
import styled from 'styled-components';
import moment from 'moment';
import { loadPmProducts, filterPmResultsProductsSelector, setPmFilter, filterPmResultsIsFetchingSelector, pmManufacturerFiltersDataSelector, pmManufacturerActiveFiltersSelector, pmManufacturerFiltersDataIsFetching, loadPmCategoryFilterData, loadPmManufacturerFilterData, pmCategoryActiveFiltersSelector, pmCategoryFiltersDataSelector, pmCategoryFiltersDataIsFetching, setPmSorter, addSelectedFeedId, removeSelectedFeedId, selectedFeedIdsSelector, clearSelectedFeedIds, pmShopFiltersDataIsFetching, pmShopActiveFiltersSelector, pmShopFiltersDataSelector, loadPmShopFilterData } from './pmSlice';

const { Option } = Select;
const { Text } = Typography;
const dateFormat = FORMAT_HUMANE_DATE;

const FitlerRow = styled(Row)`
  margin-bottom: 15px; 
`;

const TableRow = styled(Row)`
  margin-bottom: 15px; 
`

const DescriptionTooltip = styled(Tooltip)`
  margin-right: 15px; 
`

const ButtonsRow = styled(Row)`
  display: block;
`

const selectWidth = rem(175);

const columns = [
  {
    title: 'Img',
    dataIndex: 'imageUrl',
    render: imageUrl => (
      <Popover content={<img
            alt=""
            style={{ maxHeight: '200px ', width: '200px' }}
            src={imageUrl}
          />    }>
            <img
              alt=""
              style={{ maxHeight: '50px ', width: '50px' }}
              src={imageUrl}
            />      
      </Popover>
    )
  },
  {
    title: __('Názov Produktu'),
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: __('Výrobca'),
    dataIndex: 'manufacturer_name',
    sorter: true,
  },
  {
    title: __('Kategória'),
    dataIndex: 'category_name',
    sorter: true,
  },
  {
    title: __('Cena'),
    dataIndex: 'lowest_price',
    sorter: true,
  },
  {
    title: __('Link'),
    dataIndex: 'productUrl',
    render: productUrl => (
      <Button
        type="primary"
        icon={<ArrowRightOutlined />}
        size={'small'}
        href={productUrl}
        target="_blank"
      />
    ),
  },
  {
    title: __('Editacia'),
    dataIndex: 'productId',
    sorter: true,
    render: productId => (
      <Link to={`/produkt-detail/${productId}`}>Edit</Link>
    ),
  },
  {
    title: __('Počet ponúk'),
    dataIndex: 'active_shops_count',
    sorter: true,
  },
  {
    title: __('Ean'),
    dataIndex: 'ean',
    render: ean => (
      <Tooltip title={ean}>
        <span> { ean?ean.substring(0,20):"" }</span>
      </Tooltip>
    )
  }
];

const pmColumns = [
  {
    title: 'Img',
    dataIndex: 'imageUrl',
    render: imageUrl => (
      <Popover content={<img
            alt=""
            style={{ maxHeight: '200px ', width: '200px' }}
            src={imageUrl}
          />    }>
            <img
              alt=""
              style={{ maxHeight: '50px ', width: '50px' }}
              src={imageUrl}
            />      
      </Popover>
    ),
  },
  {
    title: __('Názov Produktu'),
    dataIndex: 'name',
    sorter: true,
    render: name => (
      <Tooltip title={name}>
        <span> { name?name.substring(0,50):"" }</span>
      </Tooltip>
    )
  },
  {
    title: __('Výrobca'),
    dataIndex: 'manufacturer',
    sorter: true,
  },
  {
    title: __('Kategória'),
    dataIndex: 'category',
    sorter: true,
    render: category => (
      <Tooltip title={category}>
        <span> { category?category.substring(0,50):"" }</span>
      </Tooltip>
    )
  },
  {
    title: __('Price'),
    dataIndex: 'price',
    sorter: true,
  },
  {
    title: __('Link'),
    dataIndex: 'url',
    render: url => (
      <Button
        type="primary"
        icon={<ArrowRightOutlined />}
        size={'small'}
        href={url}
        target="_blank"
      />
    )
  },
  {
    title: __('Description'),
    dataIndex: 'description',
    render: description => (
      <DescriptionTooltip title={description?description:""}>
        <span> { description?description.substring(0,50):"" }</span>
      </DescriptionTooltip>
    )
  },
  {
    title: __('Shop'),
    dataIndex: 'shopName',
    sorter: true,
  },
  {
    title: __('EAN'),
    dataIndex: 'ean',
    sorter: false,
  },
  {
    title: __('PN'),
    dataIndex: 'partNumber',
    sorter: false,
  },
  {
    title: __('Dátum vytvorenia'),
    dataIndex: 'created',
    sorter: true,
    render: created => (
      <> {formatDate(moment(created),FORMAT_HUMANE_DATETIME)}</>    
    )

  }

];

const ProductSearch = () => {
  /*
  function onCancelAllFilters() {
    dispatch(setFilter('categorySelected', []));
    dispatch(setFilter('manufacturerSelected',[]));
    dispatch(setFilter('since', null));
    dispatch(setFilter('query', null));
    dispatch(loadProducts());
  }
  */
  /*
  function onCancelAllPmFilters() {
    dispatch(setPmFilter('categorySelected', []));
    dispatch(setPmFilter('manufacturerSelected', []));
    dispatch(setPmFilter('query', null));
    dispatch(setPmFilter('minPrice', null));
    dispatch(setPmFilter('maxPrice', null));
    dispatch(setPmFilter('onlyActiveShops', null));
    dispatch(setPmFilter('onlyNotPaired', null));
    dispatch(loadPmProducts());
  }
  */

  const dispatch = useDispatch();

  React.useEffect(() => {
    async function init() {
      dispatch(loadProducts());
      dispatch(loadPmProducts());
    }
    init();
  }, [dispatch]);

  const onChangeManufacturerFilter = async value => {
    await dispatch(loadManufacturerFilterData(value));
  };
  
  const onChangePmManufacturerFilter = async value => {
    console.log("OLP");

    await dispatch(loadPmManufacturerFilterData(value));
  };
  
  const onSelectManufacturerFilter = async value => {
    dispatch(setFilter('manufacturerSelected', value));
    dispatch(loadProducts());
  };

  const onSelectPmManufacturerFilter = async value => {
    dispatch(setPmFilter('manufacturerSelected', value));
    dispatch(loadPmProducts());
  };

  const onChangeCategoryFilter = async value => {
    await dispatch(loadCategoryFilterData(value));
  };
  
  const onChangePmCategoryFilter = async value => {
    await dispatch(loadPmCategoryFilterData(value));
  };

  const onChangePmShopFilter = async value => {
    await dispatch(loadPmShopFilterData(value));
  };

  const onSelectCategoryFilter = async value => {
    dispatch(setFilter('categorySelected', value));
    dispatch(loadProducts());
  };
  
  const onSelectPmCategoryFilter = async value => {
    dispatch(setPmFilter('categorySelected', value));
    dispatch(loadPmProducts());
  };

  const onSelectPmShopFilter = async value => {
    dispatch(setPmFilter('shopSelected', value));
    dispatch(loadPmProducts());
  };

  const onChangeSinceFilter = async value => {
    dispatch(setFilter('since', value));
    dispatch(loadProducts());
  };

  // const onChangeChangeDateFilter = async value => {
  //   dispatch(setFilter('changeDate', value));
  //   dispatch(loadProducts());
  // };

  const onSearchQuery = async value => {
    dispatch(setFilter('query', value));
    dispatch(loadProducts());
  };

  const onSearchPmQuery = async value => {
    dispatch(setPmFilter('query', value));
    dispatch(loadPmProducts());
  }

  const onChangePmMinPrice = async value => {
    dispatch(setPmFilter('minPrice', value));
    dispatch(loadPmProducts());
  }

  const onChangePmMaxPrice = async value => {
    dispatch(setPmFilter('maxPrice', value));
    dispatch(loadPmProducts());
  }

  const onChangeTable = async (pagination, filters, sorters) => {
    dispatch(setPagination(pagination.current));
    dispatch(setSorter(sorters.field, sorters.order));
    dispatch(loadProducts());
  };

  const onChangePmTable = async (pagination, filters, sorters) => {
    dispatch(setPmSorter(sorters.field, sorters.order));
    dispatch(loadPmProducts());
  };

  const onChangePmShopActive = async value => {
    dispatch(setPmFilter('onlyActiveShops', value.target.checked));
    dispatch(loadPmProducts());
  };
  
  /*
  const onChangePmOnlyNotPaired = async value => {
    dispatch(setPmFilter('onlyNotPaired', value.target.checked));
    dispatch(loadPmProducts());
  }
  */

  const onChangeProductCheckbox = async (value,rowData) => {
    if(value.target.checked){
      dispatch(addSelectedProductIds(rowData.productId));
    }
    else{
      dispatch(removeSelectedProductIds(rowData.productId));
    }
  }

  const onChangePmProductCheckbox = async (value,rowData) => {
    if(value.target.checked){
      dispatch(addSelectedFeedId(rowData.feedId));
    }
    else{
      dispatch(removeSelectedFeedId(rowData.feedId));
    }
  }

  const onPairButtonClick = async (value) => {
    dispatch(pairProducts());
    notification.open({
      message: 'Párovanie',
      description:
        'Produkty boli spárované',
    });
    dispatch(clearSelectedProductIds());
    dispatch(clearSelectedFeedIds());
  }
  
  const onCreateProductsClick = async (value) => {
      (window as any).location = window.location.origin+"/product/create?force_ids="+selectedFeedIds.join(',');
  }
   
  const onPmRefreshClick = async (value) => {
    dispatch(loadPmProducts());
  }

  const onRefreshClick = async (value) => {
    dispatch(loadProducts());
  }

  const manufacturerActiveFilters = useSelector((state: ApplicationState) =>
    manufacturerActiveFiltersSelector(state)
  );

  const pmManufacturerActiveFilters = useSelector((state: ApplicationState) =>
    pmManufacturerActiveFiltersSelector(state)
  );

  const manufacturerData = useSelector((state: ApplicationState) =>
    manufacturerFiltersDataSelector(state)
  );

  const pmManufacturerData = useSelector((state: ApplicationState) =>
    pmManufacturerFiltersDataSelector(state)
  );

  const isFetchingManufacturerData = useSelector((state: ApplicationState) =>
    manufacturerFiltersDataIsFetching(state)
  );
  
  const pmIsFetchingManufacturerData = useSelector((state: ApplicationState) =>
    pmManufacturerFiltersDataIsFetching(state)
  );

  const categoryActiveFilters = useSelector((state: ApplicationState) =>
    categoryActiveFiltersSelector(state)
  );

  const pmCategoryActiveFilters = useSelector((state: ApplicationState) =>
    pmCategoryActiveFiltersSelector(state)
  );

  const categoryData = useSelector((state: ApplicationState) =>
    categoryFiltersDataSelector(state)
  );

  const pmCategoryData = useSelector((state: ApplicationState) =>
    pmCategoryFiltersDataSelector(state)
  );

  const isFetchingCategoryData = useSelector((state: ApplicationState) =>
    categoryFiltersDataIsFetching(state)
  );

  const isFetchingPmCategoryData = useSelector((state: ApplicationState) =>
    pmCategoryFiltersDataIsFetching(state)
  );

  const products = useSelector((state: ApplicationState) =>
    filterResultsProductsSelector(state)
  );

  const pmProducts = useSelector((state: ApplicationState) =>
    filterPmResultsProductsSelector(state)
  );

  const isFetchingProducts = useSelector((state: ApplicationState) =>
    filterResultsIsFetchingSelector(state)
  );

  const isFetchingPmProducts = useSelector((state: ApplicationState) =>
    filterPmResultsIsFetchingSelector(state)
  );

  const selectedProductIds = useSelector((state: ApplicationState) =>
    selectedProdutIdsSelector(state)
  );

  const selectedFeedIds = useSelector((state: ApplicationState) => 
    selectedFeedIdsSelector(state)
  );
  /*
  const pmQuery = useSelector((state:ApplicationState)  => 
    pmQueryActiveFilterSelector(state)
  );
  */

  const isFetchingPmShopData = useSelector((state: ApplicationState) =>
    pmShopFiltersDataIsFetching(state)
  );

  const pmShopActiveFilters = useSelector((state: ApplicationState) =>
    pmShopActiveFiltersSelector(state)
  );

  const pmShopData = useSelector((state: ApplicationState) =>
    pmShopFiltersDataSelector(state)
  );

  return (
    <>
      <H1>{__('Search')}</H1>
      <TableRow>
        {/*todo samostatny comp*/}
        <Col className="gutter-row" span={24}>
          <FitlerRow gutter={{ lg: 5 }}>
            <Col>
              <ConnectedMultiSelect  
                name={__('Výrobca')}
                isFetching={isFetchingManufacturerData}
                value={manufacturerActiveFilters}
                data={manufacturerData}
                selectWidth={selectWidth}
                onChange={onChangeManufacturerFilter}
                onSelect={onSelectManufacturerFilter}
              />
            </Col>
            <Col>
              <ConnectedMultiSelect
                name={__('Kategória')}
                isFetching={isFetchingCategoryData}
                value={categoryActiveFilters}
                data={categoryData}
                selectWidth={selectWidth}
                onChange={onChangeCategoryFilter}
                onSelect={onSelectCategoryFilter}
              />
            </Col>
            <Col>
              <Text style={{ marginLeft: 10 }}>{__('V listingu od:')}</Text>

              <DatePicker
                style={{ width: 125, marginLeft: 5 }}
                onChange={onChangeSinceFilter}
                format={dateFormat}
              />
            </Col>
            {/*
                        <Col>
                            <Text style={{ marginLeft: 10 }}>Dátum zmeny:</Text>

                            <DatePicker
                                style={{ width: 125, marginLeft: 5 }}
                                onChange={onChangeChangeDateFilter}
                                format={dateFormat}
                            />
                        </Col>
                        */}
            <Col>
              <Select
                style={{ width: 100 }}
                placeholder={__('Krajina')}
                defaultValue="1"
                disabled
              >
                <Option value="1">SK</Option>
                <Option value="2">CZ</Option>
              </Select>
            </Col>
            <Col>
              <Input.Search
                style={{
                  width: selectWidth + rem(50),
                }}
                placeholder={__('Vyhľadať')}
                onSearch={onSearchQuery}
                enterButton
              />
            </Col>

            <Col>
                <Button type="primary" 
                        icon={<ReloadOutlined />} 
                        onClick={onRefreshClick}
                />
            </Col>

            {/*
            <Col>
              <Tooltip title={__('Zrušiť filtre')}>
                <CloseCircleFilled
                  onClick={() => {
                    onCancelAllFilters();
                  }}
                  style={{ fontSize: '32px' }}
                />
              </Tooltip>
            </Col>
            */}
          </FitlerRow>
        </Col>

        <Col className="gutter-row" span={24}>
          <VirtualTable
            columns={columns}
            loading={isFetchingProducts}
            dataSource={products}
            onChange={(pagination, filters, sorters) => {
              onChangeTable(pagination, filters, sorters);
            }}
            scroll={{
              y: 300,
              x: '100vw',
            }}
            customCheckboxes={ {  onChange: onChangeProductCheckbox,
                                  dataColumn: "productId",
                                  selectedValues: selectedProductIds
                               }
                             }
          />
        </Col>

      </TableRow>
      
      <TableRow>
        <Col className="gutter-row" span={24}>
          <FitlerRow gutter={{ lg: 5 }}>
            
            <Col>
              <ConnectedMultiSelect
                name={__('Výrobca')}
                isFetching={pmIsFetchingManufacturerData}
                value={pmManufacturerActiveFilters}
                data={pmManufacturerData}
                selectWidth={selectWidth}
                onChange={onChangePmManufacturerFilter}
                onSelect={onSelectPmManufacturerFilter}
              />
            </Col>

            <Col>
              <ConnectedMultiSelect
                name={__('Kategória')}
                isFetching={isFetchingPmCategoryData}
                value={pmCategoryActiveFilters}
                data={pmCategoryData}
                selectWidth={selectWidth}
                onChange={onChangePmCategoryFilter}
                onSelect={onSelectPmCategoryFilter}
              />
            </Col>

            <Col>
              <ConnectedMultiSelect
                name={__('Shop')}
                isFetching={isFetchingPmShopData}
                value={pmShopActiveFilters}
                data={pmShopData}
                selectWidth={selectWidth}
                onChange={onChangePmShopFilter}
                onSelect={onSelectPmShopFilter}
              />
            </Col>

            <Col>
                <Input.Search
                  style={{
                    width: "100px",
                  }}
                  placeholder={__('Cena od')}
                  onSearch={onChangePmMinPrice}
                />
            </Col>

            <Col>
                <Input.Search
                  style={{
                    width: "100px",
                  }}
                  placeholder={__('Cena do')}
                  onSearch={onChangePmMaxPrice}
                />
            </Col>

            <Col>
                <Input.Search
                  style={{
                    width: selectWidth + rem(50),
                  }}
                  placeholder={__('Vyhľadať')}
                  onSearch={onSearchPmQuery}
                />
            </Col>

            <Col>
                <Checkbox onChange={onChangePmShopActive}>Iba aktívne</Checkbox>
            </Col>  
            
            <Col>
                <Button type="primary" 
                        icon={<ReloadOutlined />} 
                        onClick={onPmRefreshClick}
                />
            </Col>

            {/*
            <Col>
                <Checkbox onChange={onChangePmOnlyNotPaired}>Iba nespárované</Checkbox>
            </Col>
            */}
            
            {/*
            <Col>
              <Tooltip title={__('Zrušiť filtre')}>
                <CloseCircleFilled
                  onClick={() => {
                    onCancelAllPmFilters();
                  }}
                  style={{ fontSize: '32px' }}
                />
              </Tooltip>
            </Col> 
            */}

          </FitlerRow>  
        </Col>

        <Col className="gutter-row" span={24}>
          <VirtualTable
            columns={pmColumns}
            loading={isFetchingPmProducts}
            dataSource={pmProducts}
            onChange={(pagination, filters, sorters) => {
              onChangePmTable(pagination, filters, sorters);
            }}
            scroll={{
              y: 600,
              x: '100vw',
            }}
            customCheckboxes={{ onChange: onChangePmProductCheckbox,
                                dataColumn: "feedId",
                                selectedValues: selectedFeedIds
                              } 
                             }
          />
        </Col>
      </TableRow>
      
      <ButtonsRow>
        <Col className="gutter-row" span={24}>
          <div style={{ float: 'right' }}>
            <Button disabled={selectedProductIds.length === 1 && selectedFeedIds.length > 0?false:true}  
                    type="primary"
                    onClick={onPairButtonClick}
                    >
                {__('Párovať')}
            </Button>
          </div>
        </Col>

        <Col className="gutter-row" span={24}>
          <div style={{ float: 'right', marginRight:'15px' }}>
            <Button disabled={selectedFeedIds.length > 0?false:true}  
                    type="primary"
                    onClick={onCreateProductsClick}
                    >
                {__('Vytvoriť nové produkty')}
            </Button>
          </div>
        </Col> 
      </ButtonsRow>
    </>
  );
};

export default ProductSearch;
