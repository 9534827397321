import React from "react";
import { Editor } from '@tinymce/tinymce-react';

interface Props {
  showEditor: boolean,
  description: string,
  onChange: any
}

export default ({showEditor, description, onChange}: Props) => {
  if(showEditor){
    return (
      <Editor
        apiKey="0aa99lmi1dxe7mbvv36oayfucm8gy2evp2f4qjy6bjisauw1"
        initialValue={description}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            //'advlist autolink lists link image charmap print preview anchor',
            //'searchreplace visualblocks code fullscreen',
            //'insertdatetime media table paste code help wordcount'
          ],
          toolbar: ''//'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
        }}
        onEditorChange={(e) => {onChange(e)}}
      />
    )
  } else {
    return (
      <>
        <div dangerouslySetInnerHTML={{__html: description }}/>
      </>
    )
  }

}
