import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {ApplicationState, AppThunk} from "../../store";
import API from '../../services/API';
import {getValuesFromArray} from "../../utilities";

const NAMESPACE = 'categoryMappingList';

export interface MappingCategoryEntity {
  shop_category?: string;
  shop_id?: number;
  category_id?: number;
  category?: any;
  category_name?: string;
  category_mapping_id?: number;
}

export interface MappingCategoryAction {
  shop_category: string;
  shop_id: number;
  category_id: number;
  type: string;
  category_mapping_id: number;
}

interface MappingCategorysState {
  activeFilters: {
    shopSelected: { value: number }[],
    query: string,
    onlyNotMapped: boolean,
    showRejected: boolean
  },
  filtersData: {
    shop: {
      isFetching: boolean,
      data: any[];
    }
  },
  sorterData: {
    type: string,
    order: string;
  },
  filterResults: {
    offset: number
    isFetching: boolean,
    total: number;
    items: any[];
  },
  pagination: {
    pageSize: number;
    current: number
  },
  mappingActions: Array<MappingCategoryAction>,
  sendingMappingAction: boolean
}

const initialState: MappingCategorysState = {
  activeFilters: {
    shopSelected: [],
    query: null,
    onlyNotMapped: false,
    showRejected: false
  },
  filtersData: {
    shop: {
      isFetching: false,
      data: []
    }
  },
  sorterData: {
    type: null,
    order: 'asc'
  },
  filterResults: {
    isFetching: false,
    total: 0,
    offset: 0,
    items: []
  },
  pagination: {
    pageSize: 20,
    current: 1
  },
  mappingActions: [],
  sendingMappingAction: false
};

export const mappingCategorySlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    addMappingAction: (state, action: PayloadAction<MappingCategoryAction>) => {
      state.mappingActions.push(action.payload);
    },
    removeMappingAction: (state, action: PayloadAction<string>) => {
      state.mappingActions = state.mappingActions.filter(a => {
        return a.shop_category !== action.payload
      });
    },
    setActiveFilterValue: (state, action: PayloadAction<{ name: string, value: number[] | string | number | boolean | null }>) => {
      state.activeFilters[action.payload.name] = action.payload.value;
    },
    setFilterData: (state, action: PayloadAction<{ name: string, value: any }>) => {
      state.filtersData[action.payload.name].data = action.payload.value;
    },
    setIsFetchingFilterData: (state, action: PayloadAction<{ name: string, value: boolean }>) => {
      state.filtersData[action.payload.name].isFetching = action.payload.value;
    },

    setIsFetchingFilterResults: (state, action: PayloadAction<{ value: boolean }>) => {
      state.filterResults.isFetching = action.payload.value
    },
    setFilterResults: (state, action: PayloadAction<{ total: number, items: Array<MappingCategoryEntity> }>) => {
      state.filterResults.total = action.payload.total;
      state.filterResults.items = action.payload.items;
    },
    updateRecord: (state, action: PayloadAction<{ shop_category: string, new_data: any }>) => {
      state.filterResults.items = state.filterResults.items.map(item => {
        if (item.shop_category === action.payload.shop_category) {
          return {...item, ...action.payload.new_data}
        } else {
          return item;
        }
      });
    },
    setSorterData: (state, action: PayloadAction<{ type: string, order: string }>) => {
      state.sorterData.type = action.payload.type;
      state.sorterData.order = action.payload.order;
    },
    setPagination: (state, action: PayloadAction<{ current: number }>) => {
      state.pagination.current = action.payload.current
    },
    setPageSize: (state, action: PayloadAction<{ pageSize: number }>) => {
      state.pagination.pageSize = action.payload.pageSize
    },
    setSendingMappingAction: (state, action: PayloadAction<boolean>) => {
      state.sendingMappingAction = action.payload
    }
  }
});

export const updateRecord = (shop_category: string, new_data: any) => (dispatch, getState) => {
  dispatch(mappingCategorySlice.actions.updateRecord({shop_category, new_data}));
};

export const setFilter = (filter: string, value: number[] | string | number | boolean | null) => (dispatch, getState) => {
  dispatch(mappingCategorySlice.actions.setActiveFilterValue({name: filter, value}));
};

export const addMappingAction = (action: MappingCategoryAction) => (dispatch, getState) => {
  dispatch(mappingCategorySlice.actions.addMappingAction(action));
};

export const removeMappingAction = (shop_category: string) => (dispatch, getState) => {
  dispatch(mappingCategorySlice.actions.removeMappingAction(shop_category));
};

export const mappingActionsSelector = (state: ApplicationState) => state.categoryMappingList.mappingActions;

export const setSendingMappingAction = (sending: boolean) => (dispatch, getState) => {
  dispatch(mappingCategorySlice.actions.setSendingMappingAction(sending));
};

export const mappingActionsisSendingSelector = (state: ApplicationState) => state.categoryMappingList.sendingMappingAction;

export const setSorter = (field: string, orderName: string) => (dispatch, getState) => {
  const type: string = orderName ? field : undefined;
  const order: string = orderName === 'descend' ? 'desc' : 'asc';

  dispatch(mappingCategorySlice.actions.setSorterData({type: type, order: order}));
};

export const paginationSelector = (state: ApplicationState) => state.categoryMappingList.pagination;

export const setPagination = (current: number) => (dispatch, getState) => {
  dispatch(mappingCategorySlice.actions.setPagination({current: current}));
};

export const setPageSize = (pageSize: number) => (dispatch, getState) => {
  dispatch(mappingCategorySlice.actions.setPageSize({pageSize: pageSize}));
};

export const loadShopFilterData = (value: string): AppThunk => async (dispatch, getState) => {
  dispatch(mappingCategorySlice.actions.setIsFetchingFilterData({name: 'shop', value: true}));

  API.loadShops({query: value, limit: 100}).then(response => {
    dispatch(mappingCategorySlice.actions.setFilterData({
      name: 'shop', value:
        response.shops.map(shop => {
          return {
            name: shop.name+` (${shop.program_id})`,
            value: shop.shop_id
          }
        })
    }));

    dispatch(mappingCategorySlice.actions.setIsFetchingFilterData({name: 'shop', value: false}));
  })
};

export const shopActiveFiltersSelector = (state: ApplicationState) => state.categoryMappingList.activeFilters.shopSelected;
export const shopFiltersDataSelector = (state: ApplicationState) => state.categoryMappingList.filtersData.shop.data;
export const shopFiltersDataIsFetching = (state: ApplicationState) => state.categoryMappingList.filtersData.shop.isFetching;

export const queryActiveSelector = (state: ApplicationState) => state.categoryMappingList.activeFilters.query;

export const loadItems = (): AppThunk => async (dispatch, getState) => {
  dispatch(mappingCategorySlice.actions.setIsFetchingFilterResults({value: true}));
  const filterShopIds = getValuesFromArray<number>(
    shopActiveFiltersSelector(getState())
  );

  const offset = (getState().categoryMappingList.pagination.current - 1) * getState().categoryMappingList.pagination.pageSize;

  API.listCategoriesMapping({
    shopIds: filterShopIds && filterShopIds.length ? filterShopIds : undefined,
    limit: getState().categoryMappingList.pagination.pageSize,
    offset: offset,
    query: getState().categoryMappingList.activeFilters.query ? getState().categoryMappingList.activeFilters.query : undefined,
    onlyNotMapped: getState().categoryMappingList.activeFilters.onlyNotMapped,
    showRejected: getState().categoryMappingList.activeFilters.showRejected
  }).then(response => {
    const items: Array<MappingCategoryEntity> = response.items.map(item => {
      let categoryName;
      if (item.category && item.category.translations && item.category.translations.length) {
        const categoryTranslation = item.category.translations.find(translation => translation.language_id === 1);
        if (categoryTranslation) {
          categoryName = categoryTranslation.name;
        }
      }
      return {...item, category_name: (item.category ? categoryName : null), key: item.shop_category}
    });
    dispatch(
      mappingCategorySlice.actions.setIsFetchingFilterResults({value: false})
    );
    dispatch(
      mappingCategorySlice.actions.setFilterResults({
        total: response.total,
        items: items,
      })
    );
  });
};

export const rejectMappingAction = (shop_id:number, shop_category: string) => async (dispatch, getState) => {
  const listingAutomaticPairingItems = getState().categoryMappingList.filterResults.items;
  await API.rejectCategoryMapping({}, {shop_id: shop_id, shop_category: shop_category});

  const newListingAutomaticPairingItems = listingAutomaticPairingItems.filter(item => !(item.shop_id === shop_id && item.shop_category === shop_category));
  dispatch(
    mappingCategorySlice.actions.setFilterResults({
      total: 1000,
      items: newListingAutomaticPairingItems,
    })
  );
};

export const filterResultsTotalSelector = (state: ApplicationState) => state.categoryMappingList.filterResults.total;
export const filterResultsItemsSelector = (state: ApplicationState) => state.categoryMappingList.filterResults.items;
export const filterResultsIsFetchingSelector = (state: ApplicationState) => state.categoryMappingList.filterResults.isFetching;
export const filterResultsOffsetSelector = (state: ApplicationState) => state.categoryMappingList.filterResults.offset;

export default mappingCategorySlice.reducer;
