import { createSlice, PayloadAction} from "@reduxjs/toolkit"
import {ApplicationState, AppThunk} from "../../store";
import API from "../../services/API";
import { getValuesFromArray } from "../../utilities";
const NAMESPACE = 'pmProductList';

export interface ListingPmProduct {
    imageUrl: string;
    name: string;
    description: string;
    url: string;
    manufacturer: string;
    category: string;
    shopName: string;
    feedId: number;
    ean: string;
    partNumber: string;
    created: string;
}

interface PmProductListState {
    activeFilters: {
        manufacturerSelected: {value: number}[],
        categorySelected: {value: number}[],
        shopSelected: {value: number}[],
        query: string,
        minPrice: number,
        maxPrice: number,
        onlyActiveShops: boolean,
        onlyNotPaired: boolean, 
    },
    filterResults: {
        offset: number
        isFetching: boolean,
        total: number;
        pmProducts: any[];
    },
    filtersData: {
        manufacturer: {
            isFetching: boolean,
            data: any
        },
        category: {
            isFetching: boolean,
            data: any
        },
        shop: {
            isFetching: boolean,
            data: any
        }
    },
    sorterData: {
        type: string,
        order: string;
    },
    selectedFeedIds: number[]
}

const initialState : PmProductListState = {
    activeFilters: {
      manufacturerSelected: [],
      categorySelected: [],
      shopSelected: [],
      query: null,
      minPrice: null,
      maxPrice: null,
      onlyActiveShops: false,
      onlyNotPaired: false
    },
    filterResults: {
      isFetching: false,
      total: 0,
      offset: 0,
      pmProducts: []
    },
    filtersData: {
        manufacturer: {
          isFetching: false,
          data: []
        },
        category: {
          isFetching: false,
          data: []
        },
        shop: {
          isFetching: false,
          data: []
        }
    },
    sorterData: {
        type: null,
        order: 'asc'
    },
    selectedFeedIds: []
}

export const pmProductListSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {  
      setIsFetchingFilterResults: (state, action: PayloadAction<{value: boolean}>) => {
        state.filterResults.isFetching = action.payload.value
      },
      setFilterResults: (state, action: PayloadAction<{total: number, products: Array<ListingPmProduct>}>) => {
        state.filterResults.total      = action.payload.total;
        state.filterResults.pmProducts = action.payload.products;
      },
      setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | number | null}>) => {
        state.activeFilters[action.payload.name] = action.payload.value;
      },
      setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
        state.filtersData[action.payload.name].isFetching = action.payload.value;
      },
      setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
        state.filtersData[action.payload.name].data = action.payload.value;
      },
      setSorterData: (state, action: PayloadAction<{type: string, order: string}>) => {
        state.sorterData.type = action.payload.type;
        state.sorterData.order = action.payload.order;
      },
      setSelectedFeedIds: (state, action: PayloadAction<{selectedFeedIds: number[]}>) => {
        state.selectedFeedIds = action.payload.selectedFeedIds;
      },
    }
});

export const loadPmProducts = () : AppThunk => async (dispatch, getState) => {
    dispatch(pmProductListSlice.actions.setIsFetchingFilterResults({value:true}));

    const filterCategoryIds = getValuesFromArray<number>(
        pmCategoryActiveFiltersSelector(getState())
    );
    const filterManufacturerIds = getValuesFromArray<number>(
        pmManufacturerActiveFiltersSelector(getState())
    );

    const filterShopIds = getValuesFromArray<number>(
        pmShopActiveFiltersSelector(getState())
    );

    const minPrice = pmMinPriceActiveFiltersSelector(getState());
    const maxPrice = pmMaxPriceActiveFiltersSelector(getState());

    const onlyActiveShops = pmOnlyActiveShopsActiveFiltersSelector(getState());
    const onlyNotPaired   = pmOnlyNotPairedActiveFiltersSelector(getState());

    let sortType = getState().pmProductList.sorterData.type?getState().pmProductList.sorterData.type:undefined;
    if(sortType === 'manufacturer'){
        sortType = 'manufacturer_name'
    }
    else if(sortType === 'category'){
        sortType = 'category_name'
    }
    else if(sortType === 'shopName'){
        sortType = 'shop_name'
    }
    
    API.loadPMProducts({
      limit:1000,
      query: getState().pmProductList.activeFilters.query? getState().pmProductList.activeFilters.query:undefined,
      //filterManufacturerName: filterManufacturerNames?filterManufacturerNames:undefined,
      //filterCategoryName: filterCategoryNames?filterCategoryNames:undefined,
      filterMinPrice: minPrice?minPrice:undefined,
      filterMaxPrice: maxPrice?maxPrice:undefined,
      filterShopState: onlyActiveShops?'active':undefined,
      filterIsPaired: onlyNotPaired?false:undefined,
      sortType: undefined,//sortType?sortType:'created',
      sortOrder:  "desc",//getState().pmProductList.sorterData.order,
      filterShopIds: filterShopIds?filterShopIds:undefined,
      filterCategoryId: filterCategoryIds?filterCategoryIds:undefined,
      filterManufacturerId: filterManufacturerIds?filterManufacturerIds:undefined,
      queryType: "admin"
    }).then(response => { 
       dispatch(pmProductListSlice.actions.setFilterResults({
          total: response.total,
          products: response.pm_products.map( pmProduct => {
            return {
              imageUrl: pmProduct.image,
              name: pmProduct.name,
              url: pmProduct.url,
              description: pmProduct.description,
              category: pmProduct.category,
              manufacturer: pmProduct.manufacturer,
              price: pmProduct.price,
              shopName: pmProduct.shop && pmProduct.shop.length > 0?(pmProduct.shop[0].name + ` ${pmProduct.shop[0].program_id}` ):"",
              feedId: pmProduct.feed_id,
              ean: pmProduct.ean,
              partNumber: pmProduct.part_number,
              created: pmProduct.created
            }
          })
       }));

       dispatch(pmProductListSlice.actions.setIsFetchingFilterResults({value:false}));
       dispatch(clearSelectedFeedIds());
    });
}

export const loadPmManufacturerFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(pmProductListSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: true}));

    API.loadManufacturers({query:value,limit:100}).then(response => {
      dispatch(pmProductListSlice.actions.setFilterData({name: 'manufacturer', value: 
        response.manufacturers.map( manufacturer => {
            return {
              name: manufacturer.name,
              value: manufacturer.manufacturer_id
            } 
        })  
      }));
  
      dispatch(pmProductListSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: false}));   
    });
}

export const loadPmCategoryFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(pmProductListSlice.actions.setIsFetchingFilterData({name: 'category', value: true}));
    
    API.loadCategories({query:value,limit:100}).then(response => {
      dispatch(pmProductListSlice.actions.setFilterData({name: 'category', value: 
        response.categories.map( category => {
   
          let categoryName;
          if(category && category.translations && category.translations.length){
              const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
              if(categoryTranslation){
                  categoryName = categoryTranslation.name;
              }
          }
  
          return {
            name: categoryName,
            value: category.category_id
          }
        })
      }));
      
      dispatch(pmProductListSlice.actions.setIsFetchingFilterData({name: 'category', value: false}));
    });
}

export const loadPmShopFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(pmProductListSlice.actions.setIsFetchingFilterData({name: 'shop', value: true}));

    API.loadShops({query:value,limit:100}).then(response => {
      dispatch(pmProductListSlice.actions.setFilterData({name: 'shop', value: 
      response.shops.map(shop => {
          return {
            name: shop.name+` (${shop.program_id})`,
            value: shop.shop_id
          }
        })
      }));
      
      dispatch(pmProductListSlice.actions.setIsFetchingFilterData({name: 'shop', value: false}));
    })  
}

export const setPmFilter = (filter: string, value: number[] | string | number | null) => (dispatch, getState) => {
    dispatch(pmProductListSlice.actions.setActiveFilterValue({name: filter, value}));
}

export const setPmSorter = (field: string, orderName: string) => (dispatch, getState) => {
    const type:string  = orderName?field:undefined;
    const order:string = orderName === 'descend'?'desc':'asc';
    
    dispatch(pmProductListSlice.actions.setSorterData({ type: type, order:order}));
}

export const addSelectedFeedId = (feed_id:number) => (dispatch, getState) => {
    let selectedFeedIds = [...selectedFeedIdsSelector(getState())];
    selectedFeedIds.push(feed_id);
    dispatch(pmProductListSlice.actions.setSelectedFeedIds({selectedFeedIds}))
}

export const removeSelectedFeedId = (feed_id:number) => (dispatch, getState) => {
    let selectedFeedIds = [...selectedFeedIdsSelector(getState())];
    var index = selectedFeedIds.indexOf(feed_id);
    if (index > -1) {
        selectedFeedIds.splice(index, 1);
    }
    dispatch(pmProductListSlice.actions.setSelectedFeedIds({selectedFeedIds}))
}

export const clearSelectedFeedIds = () => (dispatch, getState) => {
    dispatch(pmProductListSlice.actions.setSelectedFeedIds({selectedFeedIds:[]}))
}

export const filterPmResultsProductsSelector = (state: ApplicationState) => state.pmProductList.filterResults.pmProducts;
export const filterPmResultsIsFetchingSelector = (state: ApplicationState) => state.pmProductList.filterResults.isFetching;

export const pmManufacturerActiveFiltersSelector = (state: ApplicationState) => state.pmProductList.activeFilters.manufacturerSelected;
export const pmManufacturerFiltersDataSelector = (state: ApplicationState) => state.pmProductList.filtersData.manufacturer.data;
export const pmManufacturerFiltersDataIsFetching = (state: ApplicationState) => state.pmProductList.filtersData.manufacturer.isFetching;

export const pmCategoryActiveFiltersSelector = (state: ApplicationState) => state.pmProductList.activeFilters.categorySelected;
export const pmCategoryFiltersDataSelector = (state: ApplicationState) => state.pmProductList.filtersData.category.data;
export const pmCategoryFiltersDataIsFetching = (state: ApplicationState) => state.pmProductList.filtersData.category.isFetching;

export const pmMinPriceActiveFiltersSelector = (state: ApplicationState) => state.pmProductList.activeFilters.minPrice;
export const pmMaxPriceActiveFiltersSelector = (state: ApplicationState) => state.pmProductList.activeFilters.maxPrice;

export const pmOnlyActiveShopsActiveFiltersSelector = (state: ApplicationState) => state.pmProductList.activeFilters.onlyActiveShops;
export const pmOnlyNotPairedActiveFiltersSelector = (state: ApplicationState) => state.pmProductList.activeFilters.onlyNotPaired;

export const selectedFeedIdsSelector       = (state: ApplicationState) => state.pmProductList.selectedFeedIds;

export const pmQueryActiveFilterSelector   = (state: ApplicationState) => state.pmProductList.activeFilters.query;

export const pmShopActiveFiltersSelector = (state: ApplicationState) => state.pmProductList.activeFilters.shopSelected;
export const pmShopFiltersDataSelector = (state: ApplicationState) => state.pmProductList.filtersData.shop.data;
export const pmShopFiltersDataIsFetching = (state: ApplicationState) => state.pmProductList.filtersData.shop.isFetching;



export default pmProductListSlice.reducer;