import React, {useState} from 'react';
import {Select, Spin} from "antd";
import {__} from "../../utilities";
import API from "../../services/API";
import {addMappingAction, removeMappingAction} from "./slice";
import {useDispatch} from "react-redux";

const {Option} = Select;

const MappingManufacturerSelect = (props) => {
    const {manufacturer, shop_manufacturer, manufacturer_mapping_id, shop_id, newManufacturer} = props;
    const [value, setValue] = useState([]);
    const [manufacturerData, setManufacturerData] = useState([]);
    const [isFetchingManufacturerData, setFetchingManufacturerData] = useState(false);
    const [selectUsed, setSelectUsed] = useState(false);
    const dispatch = useDispatch();

    const onSelectManufacturerFilter = (value) => {
        setValue(value);
        setSelectUsed(true);

        console.log("MAPPING ACTION");
        console.log({
            shop_manufacturer: shop_manufacturer,
            shop_id: shop_id,
            manufacturer_id: value ? value.value : null,
            type: manufacturer ? (value ? 'UPDATE' : 'DELETE') : (value ? 'INSERT' : null),
            manufacturer_mapping_id: manufacturer_mapping_id
        });


        // pridame akciu do zoznamu. Po stlaceni ulozit sa vsetky akcie vykonaju
        dispatch(removeMappingAction(shop_manufacturer));
        dispatch(addMappingAction({
            shop_manufacturer: shop_manufacturer,
            shop_id: shop_id,
            manufacturer_id: value ? value.value : null,
            type: manufacturer ? (value ? 'UPDATE' : 'DELETE') : (value ? 'INSERT' : null),
            manufacturer_mapping_id: manufacturer_mapping_id
        }));
    };

    let manufacturerValue = [];
    if (manufacturer) {
        manufacturerValue = [{
            key: manufacturer.manufacturer_id,
            value: manufacturer.manufacturer_id,
            label: manufacturer.name
        }]
    }

    if (newManufacturer) {
      manufacturerValue = [{
        key: newManufacturer.key,
        value: newManufacturer.value,
        label: newManufacturer.label
      }];
    }

    const onChangeManufacturerFilter = (value) => {
        setFetchingManufacturerData(true);

        API.loadManufacturers({query: value, limit: 100}).then(response => {
            const manufacturerData = response.manufacturers.map(manufacturer => {
                return {
                    name: manufacturer.name,
                    value: manufacturer.manufacturer_id
                }
            });

            setManufacturerData(manufacturerData);
            setFetchingManufacturerData(false);
        })
    };

    return (
        <Select
            labelInValue
            allowClear={true}
            value={selectUsed ? value : manufacturerValue}
            placeholder={__('Manufacturer')}
            notFoundContent={isFetchingManufacturerData ? <Spin size="small"/> : null}
            filterOption={false}
            showSearch
            onChange={onSelectManufacturerFilter}
            onSearch={onChangeManufacturerFilter}
            style={{width: 200}}
        >
            {manufacturerData.map(d => (
                <Option value={d.value} key={d.name}>
                    {d.name}
                </Option>
            ))}
        </Select>
    )
};

export default MappingManufacturerSelect;