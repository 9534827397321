import { Button, Col, Input, Modal, PageHeader, Row, Spin} from 'antd';
import { rem } from 'polished';
import React, { useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ConnectedMultiSelect from '../../components/Select/ConnectedMultiSelect';
import { ApplicationState } from '../../store';
import { __ } from '../../utilities';
import { ProductDetailParameters } from '../ProductDetail/ProductDetailParameters';
import ProductParametersTable from './ProductParametersTable';
import { categoryActiveFiltersSelector, categoryFiltersDataIsFetching, categoryFiltersDataSelector, loadCategoryFilterData, loadManufacturerFilterData, loadParameterFilterData, loadProducts, manufacturerActiveFiltersSelector, manufacturerFiltersDataIsFetching, manufacturerFiltersDataSelector, selectedKeysSelector, setFilter, updateParameters, updatingSelector, withoutParameterActiveFiltersSelector, withoutParameterFiltersDataIsFetching, withoutParameterFiltersDataSelector } from './slice';

const MainContent = styled.div`
  margin-top: 25px;
  padding: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`

const AddParameterButton = styled(Button)`
  width: ${rem(200)};
  margin-bottom: 20px
`

const ProductParameterBulkEdit = () => {

    const dispatch = useDispatch();
    const [parametersModalVisible,setParametersModalVisible] = useState(false);

    const onChangeNameFilter = (e) => {
        dispatch(setFilter("name",e.target.value));
    }

    const isUpdating = useSelector((state: ApplicationState) =>
      updatingSelector(state)
    );

    const isFetchingCategoryData = useSelector((state: ApplicationState) =>
      categoryFiltersDataIsFetching(state)
    );

    const categoryActiveFilters = useSelector((state: ApplicationState) =>
      categoryActiveFiltersSelector(state)
    );

    const categoryData = useSelector((state: ApplicationState) =>
      categoryFiltersDataSelector(state)
    );

    const onChangeCategoryFilter = async value => {
        await dispatch(loadCategoryFilterData(value));
    };
    
    const onSelectCategoryFilter = async value => {
        dispatch(setFilter('category', value));
    };
    
    const isFetchingManufacturerData = useSelector((state: ApplicationState) =>
        manufacturerFiltersDataIsFetching(state)
    );

    const manufacturerActiveFilters = useSelector((state: ApplicationState) =>
        manufacturerActiveFiltersSelector(state)
    );

    const manufacturerData = useSelector((state: ApplicationState) =>
        manufacturerFiltersDataSelector(state)
    );

    const onChangeManufacturerFilter = async value => {
        await dispatch(loadManufacturerFilterData(value));
    };

    const onSelectManufacturerFilter = async value => {
        dispatch(setFilter('manufacturer', value));
    };


    const isFetchingWithoutParameterData = useSelector((state: ApplicationState) =>
        withoutParameterFiltersDataIsFetching(state)
    );

    const withoutParameterActiveFilters = useSelector((state: ApplicationState) =>
        withoutParameterActiveFiltersSelector(state)
    );

    const withoutParameterData = useSelector((state: ApplicationState) =>
        withoutParameterFiltersDataSelector(state)
    );

    const onChangeWithoutParameterFilter = async value => {
        await dispatch(loadParameterFilterData(value));
    };

    const onSelectWithoutParameterFilter = async value => {
        dispatch(setFilter('withoutParameter', value));
    };

    const onAddParameterClick = async value => {
        setParametersModalVisible(true);
    };

    const selectedRows = useSelector((state: ApplicationState) =>
        selectedKeysSelector(state)
    );

    const onUpdateParametersClick = async value => {
        if(!isUpdating){
            await dispatch(updateParameters());
            setParametersModalVisible(false);
        }
    };

    const onCancelParameterModal = async value => {
        setParametersModalVisible(false);
    };

    const onClickSearch = async value => {
        dispatch(loadProducts());
    }

    return <>
        <PageHeader 
            title = {"Hromadná editácia parametrov produktov"}
            ghost={false}
            extra={[
            
            ]}>

            <Row gutter={16}>
                <Col span={4}>
                    <Input placeholder="Názov" onChange={onChangeNameFilter}/>
                </Col>
                <Col span={4}>
                    <ConnectedMultiSelect
                      name={__('Kategória')}
                      isFetching={isFetchingCategoryData}
                      value={categoryActiveFilters}
                      data={categoryData}
                      selectWidth={250}
                      onChange={onChangeCategoryFilter}
                      onSelect={onSelectCategoryFilter}
                    />
                </Col>

                <Col span={4}>
                    <ConnectedMultiSelect
                        name={__('Výrobca')}
                        isFetching={isFetchingManufacturerData}
                        value={manufacturerActiveFilters}
                        data={manufacturerData}
                        selectWidth={250}
                        onChange={onChangeManufacturerFilter}
                        onSelect={onSelectManufacturerFilter}
                    />
                </Col>

                <Col span={4}>
                    <ConnectedMultiSelect
                        name={__('Bez parametru')}
                        isFetching={isFetchingWithoutParameterData}
                        value={withoutParameterActiveFilters}
                        data={withoutParameterData}
                        selectWidth={250}
                        onChange={onChangeWithoutParameterFilter}
                        onSelect={onSelectWithoutParameterFilter}
                    />
                </Col>

                <Col span={4}>
                    <Button onClick={onClickSearch}> {__("Vyhľadať")} </Button>
                </Col>
           </Row> 

        </PageHeader>
        <MainContent>
            <AddParameterButton disabled={selectedRows && selectedRows.length?false:true} onClick = {onAddParameterClick} style={{maxWidth:200}}> {__("Pridať parameter")} </AddParameterButton>
            <ProductParametersTable/>
        </MainContent>
       
            <Modal  
                    visible = {parametersModalVisible}
                    width = {800}
                    onOk={onUpdateParametersClick}
                    onCancel={onCancelParameterModal}
                    confirmLoading={isUpdating}
            >   
                <Spin spinning={isUpdating}>
                    <ProductDetailParameters/>
                </Spin>
            </Modal>
        
    </>
}

export default ProductParameterBulkEdit