import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import API, {ThenArg} from '../../services/API';
import { AppThunk, ApplicationState } from '../../store';

const NAMESPACE = 'productDetail';

export interface good {
  imageUrl: string;
  name: string;
  manufacturer_name: string;
  lowest_price: number;
  shop: ThenArg<typeof API.loadShops>['shops'][0]
}

export interface galleryImage {
  name: string;
  url: string;
  file: any;
}


export interface detailImageFile {
  url: string;
  file: any
}

export interface ProductParameter {
  _id: string,
  parameter_id: string,
  parameter_value_id: string,
  value: string | number,
}
interface ParameterValue {
  _id: string,
  internal_name: string,
}
export interface Parameter {
  _id: string,
  internal_name: string,
  type: string,
  values: ParameterValue[]
}

export interface product {
  name: string;
  manufacturerName: string;
  categoryName: string | null;
  lowestPrice: number | null;
  created: string | null;
  description: string | null;
  productUrl: string | null;
  imageUrl: string | null;
  goods: any[];
  goodsFiltered: any[];
  assetInfo: any;
  productId: number;
  gallery: Array<galleryImage>,
  detailImageFile: detailImageFile;
  model3d: Array<{url:string}>;
  lock: boolean;
  parameters: ProductParameter[];
}

interface productDetailState {
  activeFilters: {
    countrySelected: number,
    shopSelected: { value: number }[];
    query: string;
    manufacturerSelected: string;
    parameterSelected: string;
  };
  filtersData: {
    category: {
      isFetching: boolean;
      data: any;
    };
    shops: {
      isFetching: boolean;
      data: any;
    };
    manufacturer: {
      isFetching: boolean;
      data: any;
    },
    parameters: {
      isFetching: boolean;
      data: any;
    }
  };
  productData: product;
  pagination: {
    pageSize: number;
    current: number;
  };
  parameters: Parameter[];
}

const initialState: productDetailState = {
  activeFilters: {
    countrySelected: 1,
    shopSelected: [],
    query: null,
    manufacturerSelected: null,
    parameterSelected: null
  },
  filtersData: {
    category: {
      isFetching: false,
      data: [],
    },
    shops: {
      isFetching: false,
      data: [],
    },
    manufacturer: {
      isFetching: false,
      data: [],
    },
    parameters: {
      isFetching: false,
      data: []
    }
  },
  productData: {
    name: null,
    manufacturerName: null,
    categoryName: null,
    lowestPrice: null,
    created: null,
    description: null,
    productUrl: null,
    imageUrl: null,
    goods: [],
    goodsFiltered: [],
    assetInfo: null,
    productId: null,
    gallery:[],
    detailImageFile:null,
    model3d:[],
    lock: false,
    parameters: []
  },
  pagination: {
    pageSize: 20,
    current: 1,
  },
  parameters: []
};


/** SELECTORS **/

export const productActiveCountrySelector = (state: ApplicationState) => state.productDetail.activeFilters.countrySelected;

export const productShopActiveFiltersSelector = (state: ApplicationState) =>
  state.productDetail.activeFilters.shopSelected;
export const shopFiltersDataSelector = (state: ApplicationState) =>
  state.productDetail.filtersData.shops.data;
export const shopFiltersDataIsFetching = (state: ApplicationState) =>
  state.productDetail.filtersData.shops.isFetching;
export const productDataSelector = (state: ApplicationState) =>
  state.productDetail.productData;
export const productQuerySelector = (state: ApplicationState) =>
  state.productDetail.activeFilters.query;
export const manufacturerFiltersDataIsFetching = (state: ApplicationState) => 
  state.productDetail.filtersData.manufacturer.isFetching;
export const manufacturerFiltersDataSelector = (state: ApplicationState) => 
  state.productDetail.filtersData.manufacturer.data;
export const manufacturerActiveFiltersSelector = (state: ApplicationState) => 
  state.productDetail.activeFilters.manufacturerSelected;
export const productParametersSelector = (state: ApplicationState) => 
  state.productDetail.productData.parameters
export const parametersSelector = (state: ApplicationState) => 
  state.productDetail.parameters
export const parametersSearchSelector = (state: ApplicationState) => 
  state.productDetail.filtersData.parameters.data

export const productDetailSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setActiveFilterValue: (
      state,
      action: PayloadAction<{
        name: string;
        value: number[] | string | number | null;
      }>
    ) => {
      state.activeFilters[action.payload.name] = action.payload.value;
    },
    setFilterData: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      state.filtersData[action.payload.name].data = action.payload.value;
    },
    setQuery: (
      state,
      action: PayloadAction<string>
    ) => {
      state.activeFilters.query = action.payload
    },
    setIsFetchingFilterData: (
      state,
      action: PayloadAction<{ name: string; value: boolean }>
    ) => {
      state.filtersData[action.payload.name].isFetching = action.payload.value;
    },
    setProductData: (state, action: PayloadAction<product>) => {
      state.productData = action.payload;
    },
    setFilteredGoods: (state, action: PayloadAction<any[]>) => {
      state.productData.goodsFiltered = action.payload;
    },
    setAssetInfo: (state, action: PayloadAction<any[]>) => {
      state.productData.assetInfo = action.payload
    },
    setProductActiveCountry: (state, action: PayloadAction<number>) => {
      state.activeFilters.countrySelected = action.payload;
    },
    cleanAllFilters: (state) => {
      state.activeFilters.shopSelected = [];
      state.activeFilters.query = null;
    },
    changeVideo: (state, action: PayloadAction<{video:any,i:number}>) => {
      state.productData.assetInfo.video[action.payload.i] = action.payload.video;
    },
    setVideos:  (state, action: PayloadAction<{videos:any}>) => {
      state.productData.assetInfo.video = action.payload.videos;
    },
    changeModel3d: (state, action: PayloadAction<{model3d:any,i:number}>) => {
      state.productData.model3d[action.payload.i] = action.payload.model3d;
    },
    setModel3d:  (state, action: PayloadAction<{model3d:any}>) => {
      state.productData.model3d = action.payload.model3d;
    },
    setGallery:  (state, action: PayloadAction<{gallery:any}>) => {
      state.productData.gallery =  action.payload.gallery;
    },
    setDetailImageFile: (state, action: PayloadAction<{detailImageFile:any}>) => {
      state.productData.detailImageFile = action.payload.detailImageFile
    },
    setParameters: (state, action: PayloadAction<{parameters:Parameter[]}>) => {
      state.parameters= action.payload.parameters
    },
    updateProductParameter: (state, action: PayloadAction<{_id:string,parameterId?:string,parameterValueId?:string,value?:string}>) => {
      const productParameters = state.productData.parameters;
      const currentProductParameter = productParameters.find(pp => pp._id === action.payload._id);
      if(currentProductParameter){
        if(action.payload.parameterId !== undefined) currentProductParameter.parameter_id = action.payload.parameterId;
        if(action.payload.parameterValueId !== undefined) currentProductParameter.parameter_value_id = action.payload.parameterValueId;
        if(action.payload.value !== undefined) currentProductParameter.value = action.payload.value
      }
      state.productData.parameters = productParameters;
    },
    removeProductParameter: (state, action: PayloadAction<{_id:string}>) => {
      state.productData.parameters = state.productData.parameters.filter(p => p._id !== action.payload._id)   
    },
    createProductParameter: (state, action: PayloadAction<{productParameter:ProductParameter}>) => {
      const productParameters = state.productData.parameters;
      const productParameter:ProductParameter = {
        _id: action.payload.productParameter._id,
        parameter_id: action.payload.productParameter.parameter_id?action.payload.productParameter.parameter_id:null,
        parameter_value_id: action.payload.productParameter.parameter_value_id?action.payload.productParameter.parameter_value_id:null, 
        value: action.payload.productParameter.value?action.payload.productParameter.value:null
      }
      productParameters.push(productParameter);
      state.productData.parameters = productParameters;
    },
    setProductDescription: (state, action: PayloadAction<{description:string}>) => {
      state.productData.description = action.payload.description
    }
  },
});

export const cleanAllGoodsFilters = ()=> (dispatch) => {
  dispatch(
    productDetailSlice.actions.cleanAllFilters()
  );
};


export const setProductActiveCountry = (countryId: number)=> (dispatch, getState) => {
  dispatch(
    productDetailSlice.actions.setProductActiveCountry(countryId)
  );
};

export const setProductActiveQuery = (query: string)=> (dispatch) => {
  dispatch(
    productDetailSlice.actions.setQuery(query)
  );
};

export const setFilter = (
  filter: string,
  value: number[] | string | number | null
) => (dispatch, getState) => {
  dispatch(
    productDetailSlice.actions.setActiveFilterValue({ name: filter, value })
  );
};


export const loadShopFilterData = (query): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(
    productDetailSlice.actions.setIsFetchingFilterData({
      name: 'shops',
      value: true,
    })
  );

  API.loadShops({ query: query, limit: 100 }).then(response => {
    dispatch(
      productDetailSlice.actions.setFilterData({
        name: 'shops',
        value: response.shops.map(shop => {
          return {
            name: shop.name,
            value: shop.shop_id,
          };
        }),
      })
    );

    dispatch(
      productDetailSlice.actions.setIsFetchingFilterData({
        name: 'shops',
        value: false,
      })
    );
  });
};

export const loadGoods = (
  product_id: number,
): AppThunk => async (dispatch, getState) => {
  const language = productActiveCountrySelector(getState());

  const response  = await API.loadProducts({
    countryId: language,
    filterProductIds: [product_id],
    withAssetInfo: true,
    query: getState().productList.activeFilters.query
      ? getState().productList.activeFilters.query
      : undefined,
  });

  const detailProduct = response.products.map(product => {
      return product.goods;
  });

  const shopIds = response.products[0].goods.map(good => {
    return good.shop_id;
  });


  const shopData = await API.loadShops({
    shopIds: shopIds
  });


    const detailProductInfo = response.products && response.products[0];

    let goods: Array<good> = detailProduct.flat().map(good => {
      const shop = shopData.shops.find((shop) => {
        return shop.shop_id === good.shop_id
      });
      const object = {
        imageUrl: good.image,
        name: good.name,
        manufacturer_name: detailProductInfo.manufacturer.name,
        lowest_price: good.price,
        shop
      };

      if(shop && shop.country_id === language){
        return object;
      }

      return null;
    }).filter(a => a);

    let lowestPrice;
    // let active_shops_count;
    if (detailProductInfo.aggregations) {
      const productAggregation = detailProductInfo.aggregations.find(
        aggregation => aggregation.country_id === 1
      );
      if (productAggregation && productAggregation.min_price !== 9999999) {
        lowestPrice = productAggregation.min_price;
        // active_shops_count = productAggregation.active_shops_count;
      }
    }

    let categoryName;
    if (
      detailProductInfo.category &&
      detailProductInfo.category.translations &&
      detailProductInfo.category.translations.length
    ) {
      const categoryTranslation = detailProductInfo.category.translations.find(
        translation => translation.language_id === language
      );
      if (categoryTranslation) {
        categoryName = categoryTranslation.name;
      }
    }

    let productUrl;
    if (
      detailProductInfo.category &&
      detailProductInfo.category.slugs &&
      detailProductInfo.category.slugs.length &&
      detailProductInfo.slugs &&
      detailProductInfo.slugs.length
    ) {
      let categorySlug = detailProductInfo.category.slugs.find(
        slug => slug.language_id === language
      );
      let productSlug = detailProductInfo.slugs.find(
        slug => slug.language_id === language
      );
      let baseUrl =
        language === 1
          ? 'https://www.pricemania.sk'
          : 'https://www.pricemania.cz';

      if (!productSlug) {
        productSlug = detailProductInfo.slugs[0];
      }

      if (categorySlug && productSlug) {
        productUrl = baseUrl + '/' + categorySlug.slug + '/' + productSlug.slug;
      }
    }
    let description;
    if (
      detailProductInfo.productDescriptions &&
      detailProductInfo.productDescriptions.length
    ) {
      const descriptionTranslation = detailProductInfo.productDescriptions.find(
        translation => translation.language_id === language
      );
      if (descriptionTranslation) {
        description = descriptionTranslation.description;
      }
    }

    dispatch(
      productDetailSlice.actions.setProductData({
        name: detailProductInfo.name,
        manufacturerName: detailProductInfo.manufacturer.name,
        categoryName: categoryName,
        lowestPrice: lowestPrice,
        created: detailProductInfo.created,
        imageUrl: `https://www.pricemania.sk/assets/product/${detailProductInfo.product_id}/detail_image.jpg`,
        productUrl: productUrl,
        description: description,
        goods: goods,
        goodsFiltered: goods,
        assetInfo: detailProductInfo.assetInfo,
        productId: detailProductInfo.product_id,
        gallery: detailProductInfo.assetInfo.image.gallery.map(galleryImage => {
           return {
             name: galleryImage,
             url: `https://pricemania.sk/assets/product/${detailProductInfo.product_id}/${galleryImage}`,
             file: null
           }
        }),
        detailImageFile: {
          url: `https://www.pricemania.sk/assets/product/${detailProductInfo.product_id}/detail_image.jpg`,
          file: null
        },
        model3d: detailProductInfo.assetInfo.model3d.map(model3d => {
          return {
            url: model3d
          }
        }),
        lock: detailProductInfo.lock,
        parameters: detailProductInfo.parameters?detailProductInfo.parameters.map(parameter => {
          return {
            _id: parameter._id,
            parameter_id: parameter.parameter_id,
            parameter_value_id: parameter.parameter_value_id,
            value: parameter.value as any
          }
        }):[]
      })
    );

    dispatch(  
      productDetailSlice.actions.setActiveFilterValue({name: "manufacturerSelected", value:detailProductInfo.manufacturer.name})
    )
 
    dispatch(regenerateParameters());
};


export const loadManufacturerFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
  dispatch(productDetailSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: true}));

  API.loadManufacturers({query:value,limit:100}).then(response => {
    dispatch(productDetailSlice.actions.setFilterData({name: 'manufacturer', value: 
      response.manufacturers.map( manufacturer => {
          return {
            name: manufacturer.name,
            value: manufacturer.manufacturer_id
          } 
      })  
    }));

    dispatch(productDetailSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: false}));   
  });
}


export const filterGoods = () => (dispatch, getState) => {
  const query = productQuerySelector(getState());
  const activeShops = productShopActiveFiltersSelector(getState());

  console.log(activeShops);
  const filtered = productDataSelector(getState()).goods.filter((good) => {
    let shouldShow = true;

    if(query){
      shouldShow = good.name.toLowerCase().includes(query.toLowerCase());
    }

    if(shouldShow && activeShops.length){
      const ids = activeShops.map((s) => s.value);
      shouldShow = ids.includes(good.shop.shop_id);
    }

    return shouldShow;
  })
  dispatch(productDetailSlice.actions.setFilteredGoods(filtered));
};

export const setAssetInfo = (assetInfo) => (dispatch, getState) => {
  dispatch(productDetailSlice.actions.setAssetInfo(assetInfo));
}

export const changeVideoId = (value,i) => (dispatch, getState) => {
  let video = {...getState().productDetail.productData.assetInfo.video[i]};
  video.id = value;
  dispatch(productDetailSlice.actions.changeVideo({video,i}));
}

export const changeVideoUrl = (value,i) => (dispatch, getState) => {
  let video = {...getState().productDetail.productData.assetInfo.video[i]};
  video.url = value;
  dispatch(productDetailSlice.actions.changeVideo({video,i}));
}

export const removeVideo = (i) => (dispatch, getState) => {
  let videos = [...getState().productDetail.productData.assetInfo.video];
  videos.splice(i, 1);
  dispatch(productDetailSlice.actions.setVideos({videos}));
}

export const addEmptyVideo = () => (dispatch, getState) => {
  const videos = [...getState().productDetail.productData.assetInfo.video];
  videos.push({id:"",url:""});
  dispatch(productDetailSlice.actions.setVideos({videos}));
}

export const changeModel3dUrl = (value,i) => (dispatch, getState) => {
  let model3d = {...getState().productDetail.productData.model3d[i]};
  model3d.url = value;
  dispatch(productDetailSlice.actions.changeModel3d({model3d,i}));
}

export const removeModel3d = (i) => (dispatch, getState) => {
  let model3d = [...getState().productDetail.productData.model3d];
  model3d.splice(i, 1);
  dispatch(productDetailSlice.actions.setModel3d({model3d}));
}

export const addEmptyModel3d = () => (dispatch, getState) => {
  const model3d = [...getState().productDetail.productData.model3d];
  model3d.push({url:""});
  dispatch(productDetailSlice.actions.setModel3d({model3d}));
}

export const addPictureToGallery = (file:any) => (dispatch, getState) => {
  const gallery = [...getState().productDetail.productData.gallery];
  const nextNumber = gallery.length +1;

  getBase64(file, imageUrl =>
    { 
      gallery.push({
        name: (('0'+nextNumber).slice(-2))+".jpg",
        url: imageUrl,
        file: file
      });
      dispatch(productDetailSlice.actions.setGallery({gallery}));
    }
  );
}

export const removePictureFromGallery = (i:number) => (dispatch, getState) => {
  const gallery = [...getState().productDetail.productData.gallery];
  gallery.splice(i, 1);
  dispatch(productDetailSlice.actions.setGallery({gallery}));
}

export const setDetailImageFile = (file:any) => (dispatch, getState) => {
  getBase64(file, imageUrl =>
    { 
      const detailImageFile = {
        url: imageUrl,
        file: file
      }
      dispatch(productDetailSlice.actions.setDetailImageFile({detailImageFile}));
    });
}

export const updateAssetInfo = () => (dispatch, getState) => {
  const productData = getState().productDetail.productData;

  const requestBody = {
    detailImageBase64: productData.detailImageFile.url,
    gallery: productData.gallery.map(galleryImage  => { 
      return {
        name: galleryImage.name,
        base64: galleryImage.url.startsWith("data:")?galleryImage.url:""
      }
    }),
    videos: productData.assetInfo.video,
    model3d: productData.model3d
  }

  API.updateAssetInfo(productData.productId,{},requestBody);
}

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export const updateProductManufacturer = (manufacturerId) => async (dispatch, getState) => {
  const productData = getState().productDetail.productData;
  await API.updateProduct(productData.productId,{},{ manufacturer_id: manufacturerId});
  dispatch(loadGoods(productData.productId));
}

export const updateProductLock = (lock:boolean) => async(dispatch, getState) => {
  const productData = getState().productDetail.productData;
  await API.updateProduct(productData.productId,{},{ lock: lock});
  dispatch(loadGoods(productData.productId));
}

// ================= parameters ===================
export const regenerateParameters = () => async(dispatch, getState) => {
  const productParameters = productParametersSelector(getState());
  API.loadParameters({
    filterParameterIds: productParameters.map(p => p.parameter_id)
  }).then(response => {
    const parameters:Parameter[] = response.items.map(item => {
      return {
        _id: item._id,
        internal_name: item.internal_name,
        type: item.type,
        values: item.values.map(v => {
          return {
            _id: v._id,
            internal_name: v.internal_name
          }
        })
      }
    });
    dispatch(productDetailSlice.actions.setParameters({parameters}))  
  });  
}

export const updateProductParameter = (data:{_id:string,parameterId?:string,parameterValueId?:string,value?:string}) => async(dispatch, getState) => {
  dispatch(productDetailSlice.actions.updateProductParameter(data));
  dispatch(regenerateParameters());
}

export const loadSearchParameters = (text:string) => async(dispatch, getState) => {
  dispatch(productDetailSlice.actions.setIsFetchingFilterData({name: 'parameters', value: true}));
  API.loadParameters({filterInternalName: text,limit:100}).then(response => {
    dispatch(productDetailSlice.actions.setFilterData({name: 'parameters', value: 
      response.items.map(parameter => {
          return {
            name: parameter.internal_name,
            value: parameter._id
          } 
      })  
    }));

    dispatch(productDetailSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: false}));   
  });
}

export const removeParameter = (_id:string) => async(dispatch, getState) => {
  dispatch(productDetailSlice.actions.removeProductParameter({_id}));   
}

export const createParameter = (data:ProductParameter) => async(dispatch, getState) => {
  dispatch(productDetailSlice.actions.createProductParameter({productParameter:data}));   
}

export const updateProductParameters = () => async(dispatch, getState) => {
  const productData = getState().productDetail.productData;
  API.updateProductParameters(productData.productId,{},{parameters:productData.parameters});
}

export const setProductDescription = (description) => async(dispatch, getState) => {
  dispatch(productDetailSlice.actions.setProductDescription({description}));   
}

export default productDetailSlice.reducer;
