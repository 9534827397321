import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, ApplicationState } from '../../store';
import API from '../../services/API';
import { message } from 'antd';
import { __ } from '../../utilities';
import moment from "moment"

const NAMESPACE = 'shopDetail';


interface ConnectedShopProducts {
  shop_id?: number;
  match_properties?: string[];
}

interface Shop {
  shop_id: number;
  name: string;
  company?: string;
  program?: number;
  country?: string;
  url?: string;
  description?: string;
  connected_shop_products?: ConnectedShopProducts,
  rule_pairing_enabled: boolean,
  pn_pairing_enabled: boolean,
  original_pn_pairing_enabled: boolean,
  trusted_level: string;
}
interface ShopStats {
  impresie: {
    name: string;
    prop: string;
    key: any;
  };
  ctr: {
    name: string;
    prop: string;
    key: any;
  };
  spend: {
    name: string;
    prop: string;
    key: any;
  };
  kredit: {
    name: string;
    prop: string | number;
    key: any;
  };
  total_product: {
    name: string;
    prop: string;
    key: any;
  };
  total_product_feed: {
    name: string;
    prop: string;
    key: any;
  };
  total_manufacturers: {
    name: string;
    prop: string;
    key: any;
  };
  total_categories: {
    name: string;
    prop: string;
    key: any;
  };
}

interface ShopDetailState {
  activeFilters: {
    startDate: moment.Moment;
    endDate: moment.Moment;
    source: number;
  };
  shop: {
    isFetching: boolean;
    data: Shop;
  };
  shopStats: {
    isFetching: boolean;
    data: ShopStats;
  };
  uploadImage: {
    uploading: boolean;
  }
}

const initialState: ShopDetailState = {
  activeFilters: {
    startDate: moment().subtract(7,"days"),
    endDate: moment(),
    source: null,
  },
  shop: {
    isFetching: false,
    data: null,
  },
  shopStats: {
    isFetching: false,
    data: null,
  },
  uploadImage: {
    uploading: false,
  }
};

export const shopDetailSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setShop: (state, action: PayloadAction<Shop>) => {
      state.shop.data = action.payload;
    },

    setFilterResults: (state, action: PayloadAction<ShopStats>) => {
      state.shopStats.data = action.payload;
    },
    setIsFetchingFilterResults: (
      state,
      action: PayloadAction<{ value: boolean }>
    ) => {
      state.shopStats.isFetching = action.payload.value;
    },
    // setPagination: (state, action: PayloadAction<{current: number}>) => {
    //     state.pagination.current = action.payload.current
    // },
    // setSorterData: (state, action: PayloadAction<{type: string, order: string}>) => {
    //     state.sorterData.type = action.payload.type;
    //     state.sorterData.order = action.payload.order;
    // },
    setActiveFilterValue: (
      state,
      action: PayloadAction<{
        name: string;
        value: number[] | string | number | null | boolean;
      }>
    ) => {
      state.activeFilters[action.payload.name] = action.payload.value;
    },
    // setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
    //     state.filtersData[action.payload.name].isFetching = action.payload.value;
    // },
    // setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
    //     state.filtersData[action.payload.name].data = action.payload.value;
    // },
    setUploadingImage: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.uploadImage.uploading = action.payload
    },

    changeConnectedShopProducts: (
      state,
      action: PayloadAction<number>
    ) => {
      if(state.shop.data.connected_shop_products){
        state.shop.data.connected_shop_products.shop_id = action.payload;
      }
      else{
        state.shop.data.connected_shop_products = {
          shop_id: action.payload,
          match_properties: ['original_id']
        }
      }

      if(!state.shop.data.connected_shop_products.match_properties || !state.shop.data.connected_shop_products.match_properties.length){
        state.shop.data.connected_shop_products.match_properties = ['original_id'];
      }
    },
    changeRuleEnabled:(
      state,
      action: PayloadAction<boolean>
    ) => {
      state.shop.data.rule_pairing_enabled = action.payload
    },
    changePnPairingEnabled:(
      state,
      action: PayloadAction<boolean>
    ) => {
      state.shop.data.pn_pairing_enabled = action.payload
    },
    changeOriginalPnPairingEnabled:(
      state,
      action: PayloadAction<boolean>
    ) => {
      state.shop.data.original_pn_pairing_enabled = action.payload
    },
    changeTrustedLevel:(
      state,
      action: PayloadAction<string>
    ) => {
      state.shop.data.trusted_level = action.payload
    }

  },
});

export const loadShop = (shopId: number): AppThunk => async (
  dispatch,
  getState
) => {
  API.loadShops({ shopIds: [shopId], withCompany: true }).then(response => {
    const dbShop = response.shops.length ? response.shops[0] : null;

    if (dbShop) {
      const shop: Shop = {
        shop_id: dbShop.shop_id,
        name: dbShop.name,
        company: dbShop.company[0].name,
        country: dbShop.country_id === 1 ? 'Slovensko' : 'Česko',
        program: dbShop.program_id,
        url: dbShop.url,
        description: dbShop.description,
        connected_shop_products: dbShop.connected_shop_products,
        rule_pairing_enabled: dbShop.rule_pairing_enabled?true:false,
        pn_pairing_enabled: dbShop.pn_pairing_enabled,
        original_pn_pairing_enabled: dbShop.pn_pairing_enabled,
        trusted_level: dbShop.trusted_shop && !dbShop.trusted_level?'silver':dbShop.trusted_level
      };
      dispatch(shopDetailSlice.actions.setShop(shop));
    }
  });
};
export const loadShopStats = (shopId: number): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(shopDetailSlice.actions.setIsFetchingFilterResults({ value: true }));
  const startDate = startDateActiveFiltersSelector(getState());
  const endDate = endDateActiveFiltersSelector(getState());
  const source = getState().shopDetail.activeFilters.source;
  API.loadShopsList({
    withManufacturers: true,
    withCategories: true,
    shopIds: [shopId],
    startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
    endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
    source: Number(source),
  }).then(response => {
    const shop: ShopStats[] = response.shops.map(shop => {
      return {
        impresie: {
          name: 'Impresie',
          prop: shop.impressions.toLocaleString('sk-SK'),
          key: 'impressions',
        },
        ctr: {
          name: 'CTR',
          prop: shop.impressions
            ? `${((shop.clicks / shop.impressions) * 100).toFixed(2)} %`
            : '0 %',
          key: 'crt',
        },
        spend: {
          name: 'Spend',
          prop: `${shop.payment.toFixed(2)} €`,
          key: 'spend',
        },
        kredit: {
          name: 'Kredit',
          prop:
            shop.company && shop.company[0].credit_final
              ? shop.company[0].credit_final
              : 0,
          key: 'credit',
        },
        total_product: {
          name: 'Počet zaradených produktov',
          prop: shop.products_active
            ? shop.products_active.toLocaleString('sk-SK')
            : '0',
          key: 'products',
        },
        total_product_feed: {
          name: 'Počet produktov vo feede',
          prop: shop.products_total_count
            ? shop.products_total_count.toLocaleString('sk-SK')
            : '0',
          key: 'feed',
        },
        total_manufacturers: {
          name: 'Počet napárovaných výrobcov',
          prop: shop.manufacturersCount
            ? shop.manufacturersCount.toLocaleString('sk-SK')
            : '0',
          key: 'manufact',
        },
        total_categories: {
          name: 'Počet napárovaných kategórií',
          prop: shop.categoriesCount
            ? shop.categoriesCount.toLocaleString('sk-SK')
            : '0',
          key: 'cat',
        },
      };
    });

    dispatch(shopDetailSlice.actions.setFilterResults(shop[0]));
    dispatch(
      shopDetailSlice.actions.setIsFetchingFilterResults({ value: false })
    );
  });
};

export const processShop = (): AppThunk => async (dispatch, getState) => {
  const shop = shopSelector(getState());

  for (const taskType of [
    'PROCESS_ESHOP_FEED',
    'MAP_MANUFACTURERS_FOR_ESHOP',
    'MAP_CATEGORIES_FOR_ESHOP',
    'ASSIGN_MAPPED_CATEGORIES_FOR_ESHOP',
    'ASSIGN_MAPPED_MANUFACTURERS_FOR_ESHOP',
    'PROCESS_AUTOMATIC_ASSIGNMENTS_FOR_ESHOP',
    'PROCESS_AUTOR_FOR_ESHOP',
  ]) {
    await API.createScheduledTask(
      {},
      {
        type: taskType,
        shop_id: shop.shop_id,
        data: {
          shop_id: shop.shop_id,
        },
      }
    );
  }

  message.success('Úloha bola zadaná do systému');
};

export const runRulePairing = (): AppThunk => async (dispatch, getState) => {
  const shop = shopSelector(getState());

  for (const taskType of [
    'RULE_PAIRING_FOR_ESHOP',
  ]) {
    await API.createScheduledTask(
      {},
      {
        type: taskType,
        shop_id: shop.shop_id,
        data: {
          shop_id: shop.shop_id,
        },
      }
    );
  }

  message.success('Úloha bola zadaná do systému');
}

export const updateShop = (data: number): AppThunk => async (
  dispatch,
  getState
) => {
  const shop = shopSelector(getState());

  API.saveShopSettings(shop.shop_id, {}, { programId: data }).then(response => {
    if (response.success) {
      dispatch(loadShop(shop.shop_id));
    }
  });
};
export const setFilter = (
  filter: string,
  value: number[] | string | number | null | boolean
) => (dispatch, getState) => {
  dispatch(
    shopDetailSlice.actions.setActiveFilterValue({ name: filter, value })
  );
  dispatch(loadShopStats(shopSelector(getState()).shop_id));
};

export const uploadNewShopImage = (images) => async (
  dispatch,
  getState
) => {
  dispatch(shopDetailSlice.actions.setUploadingImage(true));
  const shop = shopSelector(getState());
  API.uploadShopLogo({shopId: shop.shop_id},images).then(response => {
    if(response){
      dispatch(shopDetailSlice.actions.setUploadingImage(false));
      dispatch(loadShop(shop.shop_id));
    }
  }).catch((e) => {
    dispatch(shopDetailSlice.actions.setUploadingImage(false));
  })
};

export const changeConnectedShopProducts = (shopId:number) => async (dispatch,getState) => {
  dispatch(shopDetailSlice.actions.changeConnectedShopProducts(shopId))
}

export const changeRuleEnabled = (ruleEnabled:boolean) => async (dispatch,getState) => {
  dispatch(shopDetailSlice.actions.changeRuleEnabled(ruleEnabled))
}

export const changePnPairingEnabled = (pnPairingEnabled:boolean) => async (dispatch,getState) => {
  dispatch(shopDetailSlice.actions.changePnPairingEnabled(pnPairingEnabled));
}

export const changeTrustedLevel = (trustedLevel:string) => async (dispatch,getState) => {
  dispatch(shopDetailSlice.actions.changeTrustedLevel(trustedLevel));
}

export const updateSettings = () => async (dispatch,getState) => {
  const shop = shopSelector(getState());

  API.saveShopSettings(shop.shop_id,{},{
    connected_shop_products: shop.connected_shop_products,
    rule_pairing_enabled: shop.rule_pairing_enabled,
    pn_pairing_enabled: shop.pn_pairing_enabled,
    trusted_level: shop.trusted_level
  }).then(async response => {
    message.success(__("Nastavenia boli uložené"));

    if(shop.original_pn_pairing_enabled === true && shop.pn_pairing_enabled === false){
      for (const taskType of [
        'REMOVE_PN_FROM_AUTOMATIC_ASSIGNMENTS_FOR_ESHOP'
      ]) {
        await API.createScheduledTask(
          {},
          {
            type: taskType,
            shop_id: shop.shop_id,
            data: {
              shop_id: shop.shop_id,
            },
          }
        );
      }
    }

    dispatch(shopDetailSlice.actions.changeOriginalPnPairingEnabled(shop.pn_pairing_enabled)); 
  })
}

export const shopSelector = (state: ApplicationState) =>
  state.shopDetail.shop.data;
export const shopStats = (state: ApplicationState) =>
  state.shopDetail.shopStats.data;

export const startDateActiveFiltersSelector = (state: ApplicationState) =>
  state.shopDetail.activeFilters.startDate;
export const endDateActiveFiltersSelector = (state: ApplicationState) =>
  state.shopDetail.activeFilters.endDate;

export const shopsDataIsFetching = (state: ApplicationState) =>
  state.shopDetail.shopStats.isFetching;

export const sourceActiveFiltersSelector = (state: ApplicationState) =>
  state.shopDetail.activeFilters.source;
export const uploadingImageSelector = (state: ApplicationState) =>
  state.shopDetail.uploadImage.uploading;

export default shopDetailSlice.reducer;
