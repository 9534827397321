import { Col, Modal, Row, Typography } from "antd";
import { rem } from "polished";
import React, { useState } from "react";
import API from "../../services/API";
import ConnectedSingleSelect from "../Select/ConnectedSingleSelect";

export const ParameterNumberSelectModal = (props) => {

    const { isVisible=true, onCancel, title, onOk } = props;
    
    const [parameterActiveFilter,setParameterActiveFilter] = useState(null as number); 

    const [parameterData, setParameterData] = useState([]);

    const onChangeParameterFilter = (value) => {
        API.loadParameters({filterInternalName:value,limit:100}).then(response => {
            const parameters = response.items.map(parameter => {
                return {
                    name: parameter.internal_name,
                    value: parameter._id,
                    internal_values: parameter.values.map(value => {
                        return {
                            name: value.internal_name,
                            value: value._id
                        }
                    })
                } 
            });
            setParameterData(parameters);  
        });
    }

    const onSelectParameterFilter = (value) => {
        setParameterActiveFilter(value);
    }

    return (
        <Modal
                title = {title}
                visible = {isVisible}
                onOk = {() => {onOk(parameterActiveFilter)}}
                onCancel = {onCancel}
            >  
            
            <Row justify="space-around" align="middle">
                <Col>
                    <Typography.Text>
                        Vybrať parameter:
                    </Typography.Text>         
                </Col>
                <Col>
                    <ConnectedSingleSelect
                        value={parameterActiveFilter}
                        data={parameterData}
                        selectWidth={rem(200)}
                        onChange={onChangeParameterFilter}
                        onSelect={onSelectParameterFilter}
                    />
                </Col>
            </Row> 
        </Modal>
    )
}