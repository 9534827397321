import { Button, Divider, Select, Switch } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ApplicationState } from "../../store";
import { changeMaxShops, changeMaxShopsState, changePrimaryColor, changePrimaryColorState, changeShowHeader, changeShowHeaderState, loadWidgetSettings, updateSettings, widgetSettingsSelector, widgetSettingsStateSelector } from "./slice";
import { CompactPicker } from 'react-color';


const DividerSwitch = styled(Switch)`
   margin-right: 15px;
`

const SettingName = styled.div`
   display: inline-block;
   margin-right: 15px;
`

const SettingValue = styled.div`
   display: inline-block;
`

const ConfirmWrapper = styled.div`
   text-align: center
`

const WidgetSettings = (props) => {
    const dispatch = useDispatch();
    const { widgetId, updateCallback } = props;

    React.useEffect(() => {
        dispatch(loadWidgetSettings(widgetId))
    },[widgetId,dispatch]);

    const widgetSettings = useSelector((state: ApplicationState) => 
        widgetSettingsSelector(state)
    );

    const widgetSettingsState = useSelector((state: ApplicationState) => 
        widgetSettingsStateSelector(state)
    );
    
    return (
        <>
            <Divider orientation="left" plain={true}> <DividerSwitch checked={widgetSettingsState.colors.primary} onChange={(value) => dispatch(changePrimaryColorState(value))} /> Primárna farba </Divider>
            <CompactPicker
                color={widgetSettings.colors.primary}
                onChange={(value) => dispatch(changePrimaryColor(value))}
            />

            <Divider orientation="left" plain={true}> <DividerSwitch checked={widgetSettingsState.show_header} onChange={(value) => dispatch(changeShowHeaderState(value))} /> Hlavička widgetu </Divider>
            <div>
                <SettingName>
                    Zobrazovať hlavičku widgetu:
                </SettingName>
                
                <SettingValue> 
                    <Select value={widgetSettings.show_header?1:0} style={{ width: 120 }} onChange={(value) => dispatch(changeShowHeader(value))} >
                        <Select.Option value={1}>Áno</Select.Option>
                        <Select.Option value={0}>Nie</Select.Option>
                    </Select>
                </SettingValue>
            </div>

            <Divider orientation="left" plain={true}> <DividerSwitch checked={widgetSettingsState.max_shops} onChange={(value) => dispatch(changeMaxShopsState(value))} /> Počet obchodov </Divider>
            <div>
                <SettingName>
                    Maximálny počet obchodov:
                </SettingName>

                <SettingValue>
                    <Select value={widgetSettings.max_shops} style={{ width: 120 }} onChange={(value) => dispatch(changeMaxShops(value))} >
                        <Select.Option value={3}>3</Select.Option>
                        <Select.Option value={4}>4</Select.Option>
                        <Select.Option value={5}>5</Select.Option>
                        <Select.Option value={6}>6</Select.Option>
                        <Select.Option value={7}>7</Select.Option>
                        <Select.Option value={8}>8</Select.Option>
                        <Select.Option value={9}>9</Select.Option>
                        <Select.Option value={10}>10</Select.Option>        
                    </Select>
                </SettingValue>
            </div>
            <ConfirmWrapper>
                <Button type="primary" 
                        size="large" 
                        style={{minWidth: "200px"}}
                        onClick={() => dispatch(updateSettings(widgetId,updateCallback))}
                >
                    Uložiť
                </Button>
            </ConfirmWrapper>
        </>
    )
}

export default WidgetSettings;