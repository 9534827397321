import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {ApplicationState, AppThunk} from "../../store";
import API from '../../services/API';
import { message } from "antd";
import { tokenSelector } from "../App/slice";

const NAMESPACE = 'mergeProducts';

interface Product {
    product_id: number,
    name: string
    image_link: string;
}

interface MergeProductsState {
    product1: Product,
    product2: Product
}

const initialState: MergeProductsState = {
    product1: null,
    product2: null
};

export const mergeProductsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setProduct1: (state, action: PayloadAction<{product:Product}>) => {
       state.product1 = action.payload.product
    },
    setProduct2: (state, action: PayloadAction<{product:Product}>) => {
       state.product2 = action.payload.product
    },
  }
});

export const loadMergeProduct = (product_id,type:number) : AppThunk => async (dispatch, getState) => {
    API.loadProducts({
        filterProductIds: [product_id],
        limit:1
    }).then(response => {
        const responseProduct = response.products.length?response.products[0]:null;
        if(responseProduct){
            const product:Product = {
                product_id: responseProduct.product_id,
                name: responseProduct.name,
                image_link: `https://www.pricemania.sk/assets/product/${responseProduct.product_id}/detail_image.jpg`
            }
            if(type === 1){
                dispatch(mergeProductsSlice.actions.setProduct1({product}));
            } else if(type === 2){
                dispatch(mergeProductsSlice.actions.setProduct2({product}));
            }
        }
   })
}

export const mergeProducts = () : AppThunk => async (dispatch, getState) => {
    const product1 =  product1Selector(getState());
    const product2 =  product2Selector(getState());
 
    if(product1 && product2){
        const token = tokenSelector(getState());
        API.setToken(token);
        
        API.mergeProducts(product1.product_id,product2.product_id).then(response => {
           message.success("Produkty boli zlúčené")
        })
    }
}

export const product1Selector = (state: ApplicationState) => state.mergeProducts.product1;
export const product2Selector = (state: ApplicationState) => state.mergeProducts.product2;

export default  mergeProductsSlice.reducer;
