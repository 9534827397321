import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApplicationState, AppThunk} from "../../store";
import API from "../../services/API";
import {__, env} from "../../utilities";
import { tokenSelector } from "../App/slice";

const NAMESPACE = 'usersList';

export interface User {
  _id?: string
  user_id?: number
  confirmed?: boolean
  email?: string
  enabled?: boolean
  imported_migration?: number
  name?: string
  surname?: string
  token?: string
  role?: string
}

export interface UsersListState {
  data: User[]
  total: number
  pagination: {
    pageSize: number,
    current: number
  }
}

const initialState: UsersListState = {
  data: [],
  total: 0,
  pagination: {
    pageSize: 20,
    current: 1
  }
}

export const userListSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<User[]>) => {
      state.data = action.payload
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload
    },
    setPagination: (state, action: PayloadAction<{ current: number }>) => {
      state.pagination.current = action.payload.current
    },
    setEnabled: (state, action: PayloadAction<string>) => {
      state.data.forEach((user) => {
        if (user._id === action.payload) {
          user.enabled = !user.enabled;
        }
      })
    }
  }
});

export const userListSelector = (state: ApplicationState) => state.usersList.data
export const paginationSelector = (state: ApplicationState) => state.usersList.pagination
export const totalSelector = (state: ApplicationState) => state.usersList.total


export const loadUsers = (): AppThunk => async (dispatch, getState) => {
  const offset = (getState().usersList.pagination.current - 1) * getState().usersList.pagination.pageSize;

  const token = tokenSelector(getState());
  API.setToken(token);

  const response = await API.getUsers({
      limit: getState().productList.pagination.pageSize,
      offset: offset,
      appSpaceId: env<string>('REACT_APP_API_APP_SPACE')
    }
  );
  dispatch(
    userListSlice.actions.setData(response.users)
  );
  dispatch(
    userListSlice.actions.setTotal(response.total)
  )

}

export const setPagination = (current: number) => (dispatch, getState) => {
  dispatch(
    userListSlice.actions.setPagination({current: current})
  )
}

export const setEnabled = (userId: string): AppThunk => async (dispatch, getState) => {

  const token = tokenSelector(getState());
  API.setToken(token);
  
  const apiResponse = await API.activateUser(userId,{})
  if (apiResponse.success) {
    dispatch(
      userListSlice.actions.setEnabled(userId)
    )
  }else{
    alert(__("Vyskytla sa chyba pri zmene aktívneho stavu"))
  }
}

export const resetPassword = (userId:string):AppThunk => async (dispatch, getState) => {
  const  apiResponse =  await API.resetPassword(userId)
  if (apiResponse.success) {
   alert(__("Heslo bolo úspešne resetované. Nové heslo bolo zaslané na mail používateľa"))
  }else{
    alert(__("Vyskytla sa chyba pri resetovaní hesla"))
  }
}

export default userListSlice.reducer

