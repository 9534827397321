import { createSlice, PayloadAction} from "@reduxjs/toolkit"
import {ApplicationState, AppThunk} from "../../store";
import API from '../../services/API';
import { getValuesFromArray } from "../../utilities";
const NAMESPACE = 'productList';

export interface ListingProduct {
  imageUrl: string;
  name: string;
  manufacturer_name: string;
  category_name: string;
  active_shops_count: number;
  lowest_price: number;
  productUrl: string;
}

interface ProductListState {
    activeFilters: {
      manufacturerSelected: {value: number}[],
      categorySelected: {value: number}[],
      category: number[],
      since: string,
      changeDate: string,
      lang: number,
      query: string 
    },
    filtersData: {
      manufacturer: {
        isFetching: boolean,
        data: any
      },
      category: {
        isFetching: boolean,
        data: any
      }
    },
    sorterData: {
      type: string,
      order: string;
    },
    filterResults: {
      offset: number
      isFetching: boolean,
      total: number;
      products: any[];
    },
    pagination: {
      pageSize: number;
      current: number
    }
}

const initialState : ProductListState = {
  activeFilters: {
    manufacturerSelected: [],
    categorySelected: [],
    category: [],
    since: null,
    changeDate: null,
    lang: 1,
    query: null
  },
  filtersData: {
    manufacturer: {
      isFetching: false,
      data: []
    },
    category: {
      isFetching: false,
      data: []
    }
  },
  sorterData: {
    type: null,
    order: 'asc'
  },
  filterResults: {
    isFetching: false,
    total: 0,
    offset: 0,
    products: []
  },
  pagination: {
    pageSize: 20,
    current: 1
  }
}

export const productListSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
      setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | number | null}>) => {
        state.activeFilters[action.payload.name] = action.payload.value;
      },
      setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
        state.filtersData[action.payload.name].data = action.payload.value;
      },
      setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
        state.filtersData[action.payload.name].isFetching = action.payload.value;
      },
      
      setIsFetchingFilterResults: (state, action: PayloadAction<{value: boolean}>) => {
        state.filterResults.isFetching = action.payload.value
      },
      setFilterResults: (state, action: PayloadAction<{total: number, products: Array<ListingProduct>}>) => {
        state.filterResults.total    = action.payload.total;
        state.filterResults.products = action.payload.products;
      },
      setSorterData: (state, action: PayloadAction<{type: string, order: string}>) => {
        state.sorterData.type = action.payload.type;
        state.sorterData.order = action.payload.order;
      },
      setPagination: (state, action: PayloadAction<{current: number}>) => {
        state.pagination.current = action.payload.current
      }
    }
});

export const setFilter = (filter: string, value: number[] | string | number | null) => (dispatch, getState) => {
  dispatch(productListSlice.actions.setActiveFilterValue({name: filter, value}));
}

export const setSorter = (field: string, orderName: string) => (dispatch, getState) => {
  const type:string  = orderName?field:undefined;
  const order:string = orderName === 'descend'?'desc':'asc';
  
  dispatch(productListSlice.actions.setSorterData({ type: type, order:order}));
}

export const paginationSelector = (state: ApplicationState) => state.productList.pagination;

export const setPagination = (current: number) => (dispatch, getState) => {
  dispatch(productListSlice.actions.setPagination({ current:current}));
}

export const loadManufacturerFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
  dispatch(productListSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: true}));

  API.loadManufacturers({query:value,limit:100}).then(response => {
    dispatch(productListSlice.actions.setFilterData({name: 'manufacturer', value: 
      response.manufacturers.map( manufacturer => {
          return {
            name: manufacturer.name,
            value: manufacturer.manufacturer_id
          } 
      })  
    }));

    dispatch(productListSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: false}));   
  });
}

export const manufacturerActiveFiltersSelector = (state: ApplicationState) => state.productList.activeFilters.manufacturerSelected;
export const manufacturerFiltersDataSelector = (state: ApplicationState) => state.productList.filtersData.manufacturer.data;
export const manufacturerFiltersDataIsFetching = (state: ApplicationState) => state.productList.filtersData.manufacturer.isFetching;

export const sinceActiveFilterSelector = (state: ApplicationState) => state.productList.activeFilters.since;
export const changeDateActiveFilterSelector = (state: ApplicationState) => state.productList.activeFilters.changeDate;

export const queryActiveSelector = (state: ApplicationState) => state.productList.activeFilters.query;

export const loadCategoryFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
  dispatch(productListSlice.actions.setIsFetchingFilterData({name: 'category', value: true}));

  API.loadCategories({query:value,limit:100}).then(response => {
    dispatch(productListSlice.actions.setFilterData({name: 'category', value: 
      response.categories.map( category => {
 
        let categoryName;
        if(category && category.translations && category.translations.length){
            const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
            if(categoryTranslation){
                categoryName = categoryTranslation.name;
            }
        }

        return {
          name: categoryName,
          value: category.category_id
        }
      })
    }));
    
    dispatch(productListSlice.actions.setIsFetchingFilterData({name: 'category', value: false}));
  });
}

export const categoryActiveFiltersSelector = (state: ApplicationState) => state.productList.activeFilters.categorySelected;
export const categoryFiltersDataSelector = (state: ApplicationState) => state.productList.filtersData.category.data;
export const categoryFiltersDataIsFetching = (state: ApplicationState) => state.productList.filtersData.category.isFetching;

export const loadProducts = () : AppThunk => async (dispatch, getState) => {
  dispatch(productListSlice.actions.setIsFetchingFilterResults({value:true}));
  const filterCategoryIds = getValuesFromArray<number>(
      categoryActiveFiltersSelector(getState())
  );
  const filterManufacturerIds = getValuesFromArray<number>(
      manufacturerActiveFiltersSelector(getState())
  );

  const offset = (getState().productList.pagination.current-1) * getState().productList.pagination.pageSize;   

  API.loadProducts({
      countryId: getState().productList.activeFilters.lang,
      withCategory: true,
      limit: getState().productList.pagination.pageSize,
      offset: offset,
      categoryIds: filterCategoryIds?filterCategoryIds:undefined,
      manufacturerIds: filterManufacturerIds?filterManufacturerIds:undefined,
      sortType: getState().productList.sorterData.type?getState().productList.sorterData.type:undefined,
      sortOrder:  getState().productList.sorterData.order,
      createdFrom: getState().productList.activeFilters.since?getState().productList.activeFilters.since:undefined,
      query: getState().productList.activeFilters.query? getState().productList.activeFilters.query:undefined,
      includeMainSubcategories: true,
      withSlugs: true
  }).then(response => {
      const listingProducts: Array<ListingProduct> = response.products.map(
          product => {
              const countryId = getState().productList.activeFilters.lang;
              let categoryName;
              if (
                  product.category &&
                  product.category.translations &&
                  product.category.translations.length
              ) {
                  const categoryTranslation = product.category.translations.find(
                      translation => translation.language_id === 1
                  );
                  if (categoryTranslation) {
                      categoryName = categoryTranslation.name;
                  }
              }

              let lowestPrice;
              let active_shops_count;
              if (product.aggregations) {
                  const productAggregation = product.aggregations.find(
                      aggregation => aggregation.country_id === 1
                  );
                  if (
                      productAggregation &&
                      productAggregation.min_price !== 9999999
                  ) {
                      lowestPrice = productAggregation.min_price;
                      active_shops_count = productAggregation.active_shops_count;
                  }
              }
              
              let productUrl = null; 
              if(product.category.slugs.length && product.slugs.length){
                  let categorySlug = product.category.slugs.find(slug => slug.language_id === countryId);
                  let productSlug  = product.slugs.find(slug => slug.language_id === countryId);
                  let baseUrl      = countryId === 1?"https://www.pricemania.sk":"https://www.pricemania.cz";
                  
                  if(!productSlug){
                    productSlug = product.slugs[0];
                  } 
                  
                  if(categorySlug && productSlug){
                    productUrl = baseUrl+"/"+categorySlug.slug+"/"+productSlug.slug;
                  }
              }

              return {
                  productId: product.product_id,
                  name: product.name,
                  imageUrl: `https://www.pricemania.sk/assets/product/${product.product_id}/detail_image.jpg`,
                  manufacturer_name: product.manufacturer
                      ? product.manufacturer.name
                      : '',
                  category_name: categoryName,
                  active_shops_count: active_shops_count ? active_shops_count : 0,
                  lowest_price: lowestPrice,
                  productUrl: productUrl
              };
          }
      );
      dispatch(
          productListSlice.actions.setIsFetchingFilterResults({ value: false })
      );
      dispatch(
          productListSlice.actions.setFilterResults({
              total: response.total,
              products: listingProducts,
          })
      );
  });
}

export const filterResultsTotalSelector      = (state: ApplicationState) => state.productList.filterResults.total;
export const filterResultsProductsSelector   = (state: ApplicationState) => state.productList.filterResults.products;
export const filterResultsIsFetchingSelector = (state: ApplicationState) => state.productList.filterResults.isFetching;
export const filterResultsOffsetSelector     = (state: ApplicationState) => state.productList.filterResults.offset;


export default productListSlice.reducer;
