import React from "react";
import {__} from "../../utilities";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import {
  TagsOutlined,
  UserOutlined,
  IdcardOutlined,
  SettingOutlined
} from '@ant-design/icons';
import {tokenInfoSelector, userHasAffilSelector} from "./slice"
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";

const {SubMenu} = Menu;

const NavigationMenu = () => {
  const tokenInfo = useSelector((state: ApplicationState) =>
    tokenInfoSelector(state)
  );

  const hasAffils = useSelector((state: ApplicationState) =>
    userHasAffilSelector(state)
  );

  let adminOnlyNav = null;

  if(tokenInfo && (tokenInfo.role === "ADMIN" || tokenInfo.role === "PAROVAC")){
    adminOnlyNav =
    <SubMenu
      key="sub2"
      title={
        <span>
            <UserOutlined style={{fontSize: '18px'}}/>
          {__('Používatelia')}
          </span>
      }
    > 
      { tokenInfo && tokenInfo.role === "ADMIN"? 
        <Menu.Item key="/">
          {__('Zoznam')}
          <Link to="/users"/>
        </Menu.Item> :null   
      }
      <Menu.Item key="/stats">
        {__('Štatistiky')}
        <Link to="/cms-stats"/>
      </Menu.Item>
    </SubMenu>
  }

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={['3']}
      defaultOpenKeys={['sub1','sub3','sub2']}
      style={{height: '100%', borderRight: 0}}
    > 
      {tokenInfo && (tokenInfo.role === "ADMIN" || tokenInfo.role === "PAROVAC" )?
        <SubMenu
          key="sub1"
          title={
            <span>
              <TagsOutlined style={{fontSize: '18px'}}/>
              {__('Produkty')}
            </span>
          }
        >
          <Menu.Item key="/">
            {__('Nové produkty')}
            <Link to="/"/>
          </Menu.Item>
          <Menu.Item key="/search">
            {__('Párovanie')}
            <Link to="/search"/>
          </Menu.Item>
          <Menu.Item key="/feed_report">
            {__('Feed report')}
            <Link to="/feed_report"/>
          </Menu.Item>
          <Menu.Item key="/automatic_pairing">
            {__('Automatické párovanie')}
            <Link to="/automatic_pairing"/>
          </Menu.Item>
          <Menu.Item key="/automatic_pairing_product">
            {__('Hromadné automatické párovanie')}
            <Link to="/automatic_pairing_product"/>
          </Menu.Item>
          <Menu.Item key="/mapping/manufacturers">
            {__('Mapovanie výrobcov')}
            <Link to="/mapping/manufacturers"/>
          </Menu.Item>
          <Menu.Item key="/mapping/categories">
            {__('Mapovanie kategórií')}
            <Link to="/mapping/categories"/>
          </Menu.Item>
          <Menu.Item key="/product/create">
            {__('Vytváranie nových produktov')}
            <Link to="/product/create"/>
          </Menu.Item>
          <Menu.Item key="/unique_products">
            {__('Unikáty')}
            <Link to="/unique_products"/>
          </Menu.Item>
          <Menu.Item key="/product/bulk_create">
            {__('Hromadne vytváranie produktov')}
            <Link to="/product/bulk_create"/>
          </Menu.Item>
          <Menu.Item key="/product/stats">
            {__('Štatistiky')}
            <Link to="/product/stats"/>
          </Menu.Item>
          <Menu.Item key="/product/merge_products">
            {__('Zlučovanie produktov')}
            <Link to="/product/merge_products"/>
          </Menu.Item>
        </SubMenu>
      :null}

      {hasAffils && 
        <SubMenu
          key="sub3"
          title={
            <span>
                <IdcardOutlined style={{fontSize: '18px'}}/>
              {__('Affiliate')}
              </span>
          }
        >
          <Menu.Item key="/widgets/create">
            {__('Vytvorenie widgetu')}
            <Link to="/widgets/create"/>
          </Menu.Item>
          {tokenInfo && (tokenInfo.role === "ADMIN" || tokenInfo.role === "AFFIL_ADMIN" )?
              <Menu.Item key="/affil/stats">
                {__('Štatistiky')}
                <Link to="/affil/stats"/>
              </Menu.Item> 
          :null }

          {tokenInfo && (tokenInfo.role === "ADMIN")?  
            <Menu.Item key="/affil/stats/publishers">
              {__('Štatistiky publisherov')}
              <Link to="/affil/stats/publishers"/>
            </Menu.Item> 
          :null}

          {tokenInfo && (tokenInfo.role === "ADMIN" || tokenInfo.role === "AFFIL_ADMIN" )?
            <Menu.Item key="/affil-settings">
              {__('Nastavenia')}
              <Link to="/affil-settings"/>
            </Menu.Item> 
          :null }
        
        
        <Menu.Item key="/top50">
          {__('TOP Produkty' )}
          <Link to="/top50"/>
        </Menu.Item>

        <Menu.Item key="/top-categories">
          {__('TOP Kategórie' )}
          <Link to="/top-categories"/>
        </Menu.Item>
             
        </SubMenu>
      }

      {tokenInfo && (tokenInfo.role === "ADMIN" || tokenInfo.role === "PAROVAC" )?
         <SubMenu
            key="sub4"
            title={
              <span>
                  <IdcardOutlined style={{fontSize: '18px'}}/>
                {__('Parametre')}
                </span>
            }
          >
            <Menu.Item key="/parameters/list">
              {__('Zoznam')}
              <Link to="/parameters/list"/>
            </Menu.Item> 

            <Menu.Item key="/parameters/product-bulk-edit">
              {__('Hromadná editácia parametrov')}
              <Link to="/parameters/product-bulk-edit"/>
            </Menu.Item> 

            <Menu.Item key="/parameters/feed-mapping">
              {__('Mapovanie feedov')}
              <Link to="/parameters/feed-mapping"/>
            </Menu.Item> 

            <Menu.Item key="/parameters/icecat-mapping">
              {__('Mapovanie Icecat feedov')}
              <Link to="/parameters/icecat-mapping"/>
            </Menu.Item>

          </SubMenu>
      :null}

      {tokenInfo && (tokenInfo.role === "ADMIN")?
         <SubMenu
            key="sub5"
            title={
              <span>
                  <IdcardOutlined style={{fontSize: '18px'}}/>
                {__('Eshopy')}
                </span>
            }
          >
            <Menu.Item key="/shops">
              {__('Zoznam')}
              <Link to="/shops"/>
            </Menu.Item> 
          </SubMenu>
      :null}

      {tokenInfo && (tokenInfo.role === "ADMIN")?
         <SubMenu
            key="sub6"
            title={
              <span>
                  <SettingOutlined style={{fontSize: '18px'}}/>
                {__('System')}
                </span>
            }
          >
            <Menu.Item key="/scheduled_tasks">
              {__('Naplánované úlohy')}
              <Link to="/scheduled_tasks"/>
            </Menu.Item> 
            <Menu.Item key="/web-widgets">
              {__('Správa widgetov')}
              <Link to="/web-widgets"/>
            </Menu.Item>
          </SubMenu>
      :null}

      {tokenInfo && (tokenInfo.role === "ADMIN")?
         <SubMenu
            key="sub7"
            title={
              <span>
                  <IdcardOutlined style={{fontSize: '18px'}}/>
                {__('Kategórie')}
                </span>
            }
          >
            <Menu.Item key="/category-tree">
              {__('Strom kategórií')}
              <Link to="/category-tree"/>
            </Menu.Item> 
            <Menu.Item key="/category-list">
              {__('Štatistiky')}
              <Link to="/category-list"/>
            </Menu.Item> 
          </SubMenu>
      :null}
      {tokenInfo && (tokenInfo.role === "ADMIN")?
         <SubMenu
            key="sub8"
            title={
              <span>
                  <IdcardOutlined style={{fontSize: '18px'}}/>
                {__('Výrobcovia')}
                </span>
            }
          >
            <Menu.Item key="/manufacturers-list">
              {__('Zoznam výrobcov')}
              <Link to="/manufacturers-list"/>
            </Menu.Item> 
          </SubMenu>
      :null}



      {adminOnlyNav}
    </Menu>
  )
};

export default NavigationMenu;