import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, ApplicationState } from "../../store";
import API from "../../services/API";
import { getValuesFromArray, getRandomArbitrary } from "../../utilities";
import { tokenSelector } from "../App/slice";
import { message } from "antd";

const NAMESPACE = 'automaticPairingList';

export interface ListingAutomaticPairingItem {
    key: number;
    compare_id: number,
    catalog_product_name: string;
    shop_product_name: string;
    category_name: string;
    shop_name: string;
    name_check: boolean,
    price_check: boolean,
    category_check: boolean,
    manufacturer_check:boolean,
    match_type: string,
    product_price: number,
    pm_product_price: number,
    conflict: boolean
}

interface AutomaticPairingListState {
    activeFilters: {
        shopSelected: {value: number}[],
        manufacturerSelected: {value: number}[],
        categorySelected: { value: number}[],
        matchTypeSelected: { value: string}[],
        query: string,
        showPartners: boolean
    },
    filtersData: {
        shop: {
            isFetching: boolean,
            data: any
        },
        manufacturer: {
            isFetching: boolean,
            data: any
        },
        category: {
            isFetching: boolean,
            data: any
        }
    },
    sorterData: {
        type: string,
        order: string;
    },
    filterResults: {
        offset: number
        isFetching: boolean,
        total: number;
        items: ListingAutomaticPairingItem[];
    },
    pagination: {
        pageSize: number;
        current: number
    }
}

const initialState : AutomaticPairingListState = {
    activeFilters: {
        shopSelected: [],
        manufacturerSelected: [],
        categorySelected: [],
        matchTypeSelected: [],
        query: "",
        showPartners: false
    },
    filtersData: {
        shop: {
            isFetching: false,
            data: []
        },
        manufacturer: {
            isFetching: false,
            data: []
        },
        category: {
            isFetching: false,
            data: []
        }
    },
    sorterData: {
        type: null,
        order: 'asc'
    },
    filterResults: {
        isFetching: false,
        total: 0,
        offset: 0,
        items: [],
    },
    pagination: {
        pageSize: 20,
        current: 1
    }
}

export const automaticPairingSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setIsFetchingFilterResults: (state, action: PayloadAction<{value: boolean}>) => {
            state.filterResults.isFetching = action.payload.value
        },
        setFilterResults: (state, action: PayloadAction<{total: number, items: Array<ListingAutomaticPairingItem>}>) => {
            state.filterResults.total       = action.payload.total;
            state.filterResults.items       = action.payload.items;
        },
        setFilterResultItems: ( state, action: PayloadAction<{items: Array<ListingAutomaticPairingItem>}>) => {
            state.filterResults.items       = action.payload.items;
        },
        setPagination: (state, action: PayloadAction<{current: number}>) => {
            state.pagination.current = action.payload.current
        },
        setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },
        setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
        setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | number | null}>) => {
            state.activeFilters[action.payload.name] = action.payload.value;
        },
    }
});


export const loadAutomaticPairing = () : AppThunk => async (dispatch, getState) => {
    dispatch(automaticPairingSlice.actions.setIsFetchingFilterResults({value:true}));
    const filterShopIds = getValuesFromArray<number>(shopActiveFiltersSelector(getState()));
    const filterManufacturerIds = getValuesFromArray<number>(manufacturerActiveFiltersSelector(getState()));
    const filterCategoryIds = getValuesFromArray<number>(categoryActiveFiltersSelector(getState()));
    const filterMatchTypes  = getValuesFromArray<string>(matchTypeActiveFiltersSelector(getState()));
    const query = queryActiveFiltersSelector(getState());
    const showPartners = showPartnersSelector(getState());
    
    if(!filterShopIds && !filterManufacturerIds && !filterCategoryIds && !query && !filterMatchTypes){
        dispatch(automaticPairingSlice.actions.setIsFetchingFilterResults({value:false}));
        dispatch(
            automaticPairingSlice.actions.setFilterResults({
                total: 1000,
                items: []
            })
        );

        return;
    }

    API.searchPairing({
        limit: 200,
        filterShopIds: filterShopIds && filterShopIds.length?filterShopIds:undefined,
        filterManufacturerIds: filterManufacturerIds && filterManufacturerIds.length?filterManufacturerIds:undefined,
        filterCategoryIds: filterCategoryIds && filterCategoryIds.length?filterCategoryIds:undefined,
        filterMatchTypes: filterMatchTypes && filterMatchTypes.length?filterMatchTypes:undefined,
        searchName: query && query.length?query:undefined,
        includeChildrenCategories: true,
        filterShopProgramIds:showPartners?undefined:[2,3],
        maxVsScore: 0.9
    }).then(response => {
        const listingAutomaticPairingItems: Array<ListingAutomaticPairingItem> = response.items.map(
            feed_parser_report => {

                let productDescription = null;
                productDescription = feed_parser_report.product.productDescriptions.find( productDescription => productDescription.language_id === feed_parser_report.shop.country_id)
                if(!productDescription && feed_parser_report.product && feed_parser_report.product.productDescriptions) {
                    productDescription = feed_parser_report.product.productDescriptions[0];
                }

                let categoryName;
                if(feed_parser_report.product.category && feed_parser_report.product.category.translations && feed_parser_report.product.category.translations.length){
                    const categoryTranslation = feed_parser_report.product.category.translations.find(translation => translation.language_id === 1);
                    if(categoryTranslation){
                        categoryName = categoryTranslation.name;
                    }
                }
                
                const conflictItems = response.items.filter(item => item.pm_shops_product.feed_id === feed_parser_report.pm_shops_product.feed_id);
                
                const shop:any = feed_parser_report.shop;
                const pmShopsProduct:any = feed_parser_report.pm_shops_product;
                
                console.log(feed_parser_report); 
                 
                return {
                    //key: feed_parser_report.compare_id,  
                    key: getRandomArbitrary(0,Number.MAX_SAFE_INTEGER),
                    compare_id: feed_parser_report.compare_id,
                    catalog_product_id: feed_parser_report.product.product_id,
                    catalog_product_name: feed_parser_report.product.name,
                    catalog_product_description: productDescription?productDescription.description:"",
                    shop_product_name: feed_parser_report.pm_shops_product.name,
                    category_name: categoryName,
                    category: feed_parser_report.product.category,
                    manufacturer: feed_parser_report.product.manufacturer,
                    shop_name: feed_parser_report.shop.name + ` (${feed_parser_report.shop.program_id})` ,
                    ean: feed_parser_report.ean,
                    part_number: feed_parser_report.part_number,
                    name_check: feed_parser_report.name_check,
                    name_check_score: feed_parser_report.name_check_score,
                    category_check: feed_parser_report.category_check,
                    manufacturer_check: feed_parser_report.manufacturer_check,
                    price_check: feed_parser_report.price_check,
                    match_type: feed_parser_report.match_type,
                    product_price: feed_parser_report.product.current_aggregation.assignment_average_price,
                    //product_price: feed_parser_report.product.current_aggregation.average_price,
                    pm_product_price: feed_parser_report.pm_shops_product.converted_price,
                    pm_product_image_url: feed_parser_report.pm_shops_product.image,
                    pm_product_description: feed_parser_report.pm_shops_product.description,
                    pm_product_ean: feed_parser_report.pm_shops_product.ean,
                    pm_product_part_number: feed_parser_report.pm_shops_product.part_number,
                    pm_product_category: feed_parser_report.pm_shops_product.category,
                    pm_product_manufacturer: feed_parser_report.pm_shops_product.manufacturer,
                    conflict: conflictItems.length > 1 ? true:false,
                    feed_id: feed_parser_report.pm_shops_product.feed_id,
                    visualsearch_score: feed_parser_report.visualsearch_score?feed_parser_report.visualsearch_score.toFixed(2):-1,
                    shop_id: shop.shop_id,
                    pm_product_category_id: pmShopsProduct.category_id,
                    pm_product_manufacturer_id: pmShopsProduct.manufacturer_id,
                    pm_product_shop_manufacturer: pmShopsProduct.shop_manufacturer,
                    pm_product_shop_category: pmShopsProduct.shop_category
                }
            }
        );

        dispatch(automaticPairingSlice.actions.setIsFetchingFilterResults({value:false}));
        dispatch(
            automaticPairingSlice.actions.setFilterResults({
                total: 1000,
                items: listingAutomaticPairingItems,
            })
        );
    })
}

export const loadShopFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(automaticPairingSlice.actions.setIsFetchingFilterData({name: 'shop', value: true}));

    API.loadShops({query:value,limit:100}).then(response => {
      dispatch(automaticPairingSlice.actions.setFilterData({name: 'shop', value: 
      response.shops.map(shop => {
          return {
            name: shop.name+` (${shop.program_id})`,
            value: shop.shop_id
          }
        })
      }));
      
      dispatch(automaticPairingSlice.actions.setIsFetchingFilterData({name: 'shop', value: false}));
    })  
}

export const loadCategoryFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(automaticPairingSlice.actions.setIsFetchingFilterData({name: 'category', value: true}));
  
    API.loadCategories({query:value,limit:100}).then(response => {
      dispatch(automaticPairingSlice.actions.setFilterData({name: 'category', value: 
        response.categories.map( category => {
   
          let categoryName;
          if(category && category.translations && category.translations.length){
              const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
              if(categoryTranslation){
                  categoryName = categoryTranslation.name;
              }
          }
  
          return {
            name: category.parents_names ? [...category.parents_names].reverse().filter((item, index) => index % 2 === 0).join(' < ') : categoryName,
            value: category.category_id
          }
        })
      }));
      
      dispatch(automaticPairingSlice.actions.setIsFetchingFilterData({name: 'category', value: false}));
    });
}

export const loadManufacturerFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(automaticPairingSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: true}));
  
    API.loadManufacturers({query:value,limit:100}).then(response => {
      dispatch(automaticPairingSlice.actions.setFilterData({name: 'manufacturer', value: 
        response.manufacturers.map( manufacturer => {
            return {
              name: manufacturer.name,
              value: manufacturer.manufacturer_id
            } 
        })  
      }));
  
      dispatch(automaticPairingSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: false}));   
    });
}

export const pairAutomaticPairingItems = (keysToReduce:Array<number>) => async (dispatch, getState) => {
    const listingAutomaticPairingItems = getState().automaticPairingList.filterResults.items; 
    
    let conflictItemsKeys = [];

    for(const keyToReduce of keysToReduce) {
        const currentItem = listingAutomaticPairingItems.find(item => item.key === keyToReduce);
        if(currentItem){
            if(currentItem.conflict === true) {
                conflictItemsKeys = conflictItemsKeys.concat(listingAutomaticPairingItems.filter(item => item.feed_id === currentItem.feed_id).map(item => {
                   return item.key;
                }));
            }
            
            const token = tokenSelector(getState());
            API.setToken(token);
            await API.updateAutomaticAssignment(currentItem.compare_id,{},{force_assign:'manual'});
        }
    }
   
    const newListingAutomaticPairingItems = listingAutomaticPairingItems.filter(item => !keysToReduce.includes(item.key)).filter(item => !conflictItemsKeys.includes(item.key));

    if (newListingAutomaticPairingItems.length > 0) {
      dispatch(
        automaticPairingSlice.actions.setFilterResults({
            total: 1000,
            items: newListingAutomaticPairingItems,
        })
      );
    } else {
      // ak ostalo 0 produktov, nacitame dalsie
      dispatch(loadAutomaticPairing());
    }
};

export const rejectAutomaticPairingItems = (keysToReduce:Array<number>) => async (dispatch, getState) => {
    const listingAutomaticPairingItems = getState().automaticPairingList.filterResults.items; 
    for(const keyToReduce of keysToReduce){
        const currentItem = listingAutomaticPairingItems.find(item => item.key === keyToReduce);
        if(currentItem){
            await API.updateAutomaticAssignment(currentItem.compare_id,{},{rejected: true});
        }
    }

    const newListingAutomaticPairingItems = listingAutomaticPairingItems.filter(item => !keysToReduce.includes(item.key));
    dispatch(
        automaticPairingSlice.actions.setFilterResults({
            total: 1000,
            items: newListingAutomaticPairingItems,
        })
    );
}

export const setFilter = (filter: string, value: number[] | string | number | null) => (dispatch, getState) => {
    dispatch(automaticPairingSlice.actions.setActiveFilterValue({name: filter, value}));
}

export const setFilterResultsItems = (items: ListingAutomaticPairingItem[]) => (dispatch, getState) => {
    dispatch(automaticPairingSlice.actions.setFilterResultItems({items:items}));
}

export const mapCategory = (shopId: number, shopCategory:string, mappedCategoryId: number) => (dispatch, getState) => {
    API.createCategoryMapping({},{shop_id: shopId, shop_category: shopCategory, category_id: mappedCategoryId}).then(response => {
        for (const taskType of [
            'PROCESS_ESHOP_FEED',
            'MAP_MANUFACTURERS_FOR_ESHOP',
            'MAP_CATEGORIES_FOR_ESHOP',
            'ASSIGN_MAPPED_CATEGORIES_FOR_ESHOP',
            'ASSIGN_MAPPED_MANUFACTURERS_FOR_ESHOP',
            'PROCESS_AUTOMATIC_ASSIGNMENTS_FOR_ESHOP',
            'PROCESS_AUTOR_FOR_ESHOP',
          ]) {
            API.createScheduledTask(
              {},
              {
                type: taskType,
                shop_id: shopId,
                data: {
                  shop_id: shopId,
                },
              }
            );
        }

        message.success('Kategória bola namapovaná');
    });
}

export const mapManufacturer = (shopId: number, shopManufactuer:string, mappedManufactuerId: number) => (dispatch, getState) => {
    API.createManufacturerMapping({},{shop_id: shopId, shop_manufacturer:shopManufactuer, manufacturer_id: mappedManufactuerId}).then(response => {
        for (const taskType of [
            'PROCESS_ESHOP_FEED',
            'MAP_MANUFACTURERS_FOR_ESHOP',
            'MAP_CATEGORIES_FOR_ESHOP',
            'ASSIGN_MAPPED_CATEGORIES_FOR_ESHOP',
            'ASSIGN_MAPPED_MANUFACTURERS_FOR_ESHOP',
            'PROCESS_AUTOMATIC_ASSIGNMENTS_FOR_ESHOP',
            'PROCESS_AUTOR_FOR_ESHOP',
          ]) {
            API.createScheduledTask(
              {},
              {
                type: taskType,
                shop_id: shopId,
                data: {
                  shop_id: shopId,
                },
              }
            );
        }

        message.success('Výrobca bol namapovaný');
    })
}

export const paginationSelector                = (state: ApplicationState) => state.automaticPairingList.pagination;
export const filterResultsTotalSelector        = (state: ApplicationState) => state.automaticPairingList.filterResults.total;
export const filterResultsIsFetchingSelector   = (state: ApplicationState) => state.automaticPairingList.filterResults.isFetching;
export const filterResultsOffsetSelector       = (state: ApplicationState) => state.automaticPairingList.filterResults.offset;
export const filterResultsItemsSelector        = (state: ApplicationState) => state.automaticPairingList.filterResults.items;

export const shopActiveFiltersSelector = (state: ApplicationState) => state.automaticPairingList.activeFilters.shopSelected;
export const shopFiltersDataSelector = (state: ApplicationState) => state.automaticPairingList.filtersData.shop.data;
export const shopFiltersDataIsFetching = (state: ApplicationState) => state.automaticPairingList.filtersData.shop.isFetching;

export const matchTypeActiveFiltersSelector = (state: ApplicationState) => state.automaticPairingList.activeFilters.matchTypeSelected;

export const queryActiveFiltersSelector = (state: ApplicationState) => state.automaticPairingList.activeFilters.query;

export const manufacturerActiveFiltersSelector = (state: ApplicationState) => state.automaticPairingList.activeFilters.manufacturerSelected;
export const manufacturerFiltersDataSelector = (state: ApplicationState) => state.automaticPairingList.filtersData.manufacturer.data;
export const manufacturerFiltersDataIsFetching = (state: ApplicationState) => state.automaticPairingList.filtersData.manufacturer.isFetching;

export const categoryActiveFiltersSelector = (state: ApplicationState) => state.automaticPairingList.activeFilters.categorySelected;
export const categoryFiltersDataSelector = (state: ApplicationState) => state.automaticPairingList.filtersData.category.data;
export const categoryFiltersDataIsFetching = (state: ApplicationState) => state.automaticPairingList.filtersData.category.isFetching;

export const showPartnersSelector = (state: ApplicationState) => state.automaticPairingList.activeFilters.showPartners;

export default automaticPairingSlice.reducer;






