import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import {
    Modal, Radio
  } from 'antd';
import { setModalVisibility, skipSuggestionProducts, skipSuggestionsModalSelector } from './slice';

const SkipSuggestionsModal = (props) => {
    const dispatch = useDispatch();
    const [radioValue, setRadioValue] = useState(0);

    const skipSuggestionsModal = useSelector((state: ApplicationState) =>
        skipSuggestionsModalSelector(state)
    );

    const handleClose = () => {
        dispatch(setModalVisibility("skipSuggestions",false));
        //dispatch(setProductPreviewModalData(null));
    }

    const handleOk = () => {
        dispatch(skipSuggestionProducts(radioValue));
        dispatch(setModalVisibility("skipSuggestions",false));
    }

    const onRadioChange = (e) => {
        console.log('radio checked', e.target.value);
        setRadioValue(e.target.value);
    }

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    return <Modal visible = {skipSuggestionsModal.isVisible}
                  onCancel={handleClose}
                  onOk={handleOk}
                  title="Preskočenie produktu"
           >    

            <div>
                <Radio.Group onChange={onRadioChange} value={radioValue}>
                    <Radio style={radioStyle} value={1}>
                       Nejednoznačný názov a popis výrobku
                    </Radio>
                    <Radio style={radioStyle} value={2}>
                       Neexistujúca kategória
                    </Radio>
                    <Radio style={radioStyle} value={3}>
                       Neexistujúci výrobca
                    </Radio>
                    <Radio style={radioStyle} value={4}>
                       Zlá cena produktu
                    </Radio>
                    <Radio style={radioStyle} value={5}>
                       Chyba v programe
                    </Radio>
                </Radio.Group>
            </div>    
               
           </Modal>
}

export default SkipSuggestionsModal;