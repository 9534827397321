import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, ApplicationState } from "../../store";
import API from "../../services/API";
import { getRandomString } from "../../utilities";

const NAMESPACE = 'widgetCreate';
interface HtmlCode {
    width?: number,
    code?: string
}

interface WidgetCreateState {
    activeFilters: {
        productSelected: number,
        widgetTypeSelected: string
    },
    filtersData: {
        product: {
            isFetching: boolean,
            data: any
        }
    },
    htmlCodes: HtmlCode[],
    actualWidgetId: string,
    affilName: string,
    countryId: any,
    loadHash: string
}

const initialState : WidgetCreateState = {
    activeFilters: {
        productSelected: null,
        widgetTypeSelected: 'widget_type'
    },
    filtersData: {
        product: {
            isFetching: false,
            data: []
          }
    },
    htmlCodes: [],
    actualWidgetId: null,
    affilName: null,
    countryId: 1,
    loadHash: ''
}

let timeout;
let currentValue;

export const widgetCreateSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },
        setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
        setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | number | null}>) => {
            state.activeFilters[action.payload.name] = action.payload.value;
        },
        setHtmlCodes: (state, action: PayloadAction<HtmlCode[]>) => {
            state.htmlCodes = action.payload;
        },
        setActualWidgetId: (state, action: PayloadAction<string>) => {
            state.actualWidgetId = action.payload;
        },
        setCountryId: (state, action: PayloadAction<number> ) => {
            state.countryId = action.payload
        },
        setAffilName: (state, action: PayloadAction<string> ) => {
            state.affilName = action.payload
        },
        setLoadHash: (state, action: PayloadAction<string> ) => {
            state.loadHash = action.payload
        },
        resetState: state => initialState
    }
});

export const loadProductFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    console.log(value)
    const actualLoadHash = getRandomString(20);
    dispatch(widgetCreateSlice.actions.setLoadHash(actualLoadHash));
    dispatch(widgetCreateSlice.actions.setIsFetchingFilterData({name: 'product', value: true}));

    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    
    currentValue = value;

    const countryId = countryIdSelector(getState());

    timeout = setTimeout(() => {
        API.loadProducts({countryId: countryId,
                          query:currentValue.trim(),
                          limit:10,
                          projectType: 'detail',
                          showInAutocomplete: true, 
                          showInComparision: true,
                          sortType:'impressions'
            }).then(async response => {
                const stateLoadHash = loadHashSelector(getState());
                if(stateLoadHash === actualLoadHash){
                    const userAffils = await API.loadUserAffils({});
                    const affiliateId = userAffils.items.length?userAffils.items[0]._id:null;

                    const shopIds = [];
                    for(const product of response.products){
                        for(const good of product.goods){
                            if(good.state === 'active' && !shopIds.includes(good.shop_id)){
                               shopIds.push(good.shop_id);
                            }
                        }
                    }
                    
                    const loadShopsResponse  = await API.loadShops({shopIds:shopIds,limit:1000});
                    const shops = loadShopsResponse.shops?loadShopsResponse.shops:[];
                    
                    dispatch(widgetCreateSlice.actions.setFilterData({name: 'product', value: 
                    response.products.map(product => {
                        let shopsIds = [];
                        for(const good of product.goods){
                            const shop = shops.find(s=> s.shop_id === good.shop_id);
                            if(shop){
                                const clickCoeficient = shop.affiliate_click_coeficients?shop.affiliate_click_coeficients.find(afc => afc.affiliate_id === affiliateId):null;
                                const showInWidgets = shop.show_in_widgets === false?false:true;    
                                
                                if(shop.state === 'active' && good.state === 'active' && shop.program_id !== 1 && shop.country_id === countryId && (!clickCoeficient || clickCoeficient.coeficient > 0) && showInWidgets){
                                    console.log("IN!!!!");
                                    
                                    if(!shopsIds.includes(shop.shop_id)){
                                        shopsIds.push(shop.shop_id);
                                    }
                                }
                            }
                        }

                        const shopsCount = shopsIds.length;
                       
                        if(shopsCount > 0){
                            let name = product.name;
                            const productAggregation = product.aggregations.find(aggregation => aggregation.country_id = countryId);
                            if(productAggregation){
                                name += ` (${shopsCount} e-shopov)`
                            }
                        
                            return {
                                name: name,
                                value: product.product_id
                            }
                        }
                        else{
                            return null;
                        }
                    }).filter(o=>o)}));
                }
                else{
                    console.log("IGNORED");
                }
            dispatch(widgetCreateSlice.actions.setIsFetchingFilterData({name: 'product', value: false}));
        })
    }, 1000);
}

export const setFilter = (filter: string, value: number[] | string | number | null) => (dispatch, getState) => {
    dispatch(widgetCreateSlice.actions.setActiveFilterValue({name: filter, value}));
}

export const generateProductWidget = () => (dispatch, getState) => {
    const activeProduct = productActiveFiltersSelector(getState());
    const countryId = countryIdSelector(getState());
    
    const token = tokenSelector(getState());
    API.setToken(token);

    API.createProductWidget({},{
        product_id: activeProduct,
        country_id: countryId
    }).then(response => {
        dispatch(widgetCreateSlice.actions.setHtmlCodes(response.html_codes));   
        dispatch(widgetCreateSlice.actions.setActualWidgetId(response._id));     
    });
}

export const generateModalProductWidget = () => (dispatch, getState) => {
    const activeProduct = productActiveFiltersSelector(getState());
    const countryId = countryIdSelector(getState());

    const token = tokenSelector(getState());
    API.setToken(token);
    
    API.createModalProductWidget({},{
        product_id: activeProduct,
        country_id: countryId
    }).then(response => {
        dispatch(widgetCreateSlice.actions.setHtmlCodes(response.html_codes));   
        dispatch(widgetCreateSlice.actions.setActualWidgetId(response._id));     
    })
}

export const generateButtonProductWidget = () => (dispatch, getState) => {
    const activeProduct = productActiveFiltersSelector(getState());
    const countryId = countryIdSelector(getState());

    const token = tokenSelector(getState());
    API.setToken(token);

    API.createButtonProductWidget({},{
        product_id: activeProduct,
        country_id: countryId
    }).then(response => {
        dispatch(widgetCreateSlice.actions.setHtmlCodes(response.html_codes)); 
        dispatch(widgetCreateSlice.actions.setActualWidgetId(response._id));       
    });

}

export const resetState = () => (dispatch,getState) => {
    dispatch(widgetCreateSlice.actions.resetState());
};

export const createFromLink = (product:any,type:string) => async (dispatch,getState) => {
  dispatch(loadProductFilterData(product['name']))
  dispatch(setFilter('productSelected', product['productId']));
  switch (type){
    case 'products':
      dispatch(generateProductWidget())
      break;
    case 'modal_products':
      dispatch(generateModalProductWidget())
      break;
    case 'button_products':
      dispatch(generateButtonProductWidget())
      break;
  }
};


export const loadUserAffil = () : AppThunk => async (dispatch, getState) => {
    const token = tokenSelector(getState());
    API.setToken(token);

    API.loadUserAffils({}).then(response => {
       if(response.items && response.items.length) {
        dispatch(widgetCreateSlice.actions.setAffilName(response.items[0].name));
       }
    })
}

export const loadAffilSettings = () : AppThunk => async (dispatch, getState) => {
    const response:any = await API.getAffilUserSettings();
    
    if(response){
        dispatch(widgetCreateSlice.actions.setCountryId(parseInt(response.widget.countryId?response.widget.countryId:1)));
    }
}

export const changeCountryIdAction = (countryId:string) : AppThunk => async (dispatch, getState) => {
    dispatch(widgetCreateSlice.actions.setCountryId(parseInt(countryId)));
}

export const productActiveFiltersSelector = (state: ApplicationState) => state.widgetCreate.activeFilters.productSelected;
export const productFiltersDataSelector = (state: ApplicationState) => state.widgetCreate.filtersData.product.data;
export const productFiltersDataIsFetching = (state: ApplicationState) => state.widgetCreate.filtersData.product.isFetching;

export const widgetTypeActiveFilterSelector = (state: ApplicationState) => state.widgetCreate.activeFilters.widgetTypeSelected;

export const htmlCodesSelector = (state: ApplicationState) => state.widgetCreate.htmlCodes;
export const actualWidgetIdSelector = (state: ApplicationState) => state.widgetCreate.actualWidgetId;
export const affilNameSelector = (state: ApplicationState) => state.widgetCreate.affilName;
export const countryIdSelector = (state: ApplicationState) => state.widgetCreate.countryId;

export const loadHashSelector = (state: ApplicationState) => state.widgetCreate.loadHash;

export const tokenSelector = (state: ApplicationState) => state.general.token;

export default widgetCreateSlice.reducer;