import { Button, Col, Collapse, PageHeader, Pagination, Row, Spin } from 'antd';
import React  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ConnectedSingleSelect from '../../components/Select/ConnectedSingleSelect';
import { ApplicationState } from '../../store';
import { __ } from '../../utilities';
import { categoryActiveFiltersSelector, categoryFiltersDataIsFetching, categoryFiltersDataSelector, changeMappingParameter, createMapping, loadCategoryFilterData, loadMappings, loadParameters, loadShopFilterData, onRecalculate, onSave, parameterFeedMappingIsFetching, parameterFeedMappingSelector, parameterMappingTotalSelector, parametersSelector, setCurrentPage, setFilter, shopActiveFiltersSelector, shopFiltersDataIsFetching, shopFiltersDataSelector } from './slice';

const MainContent = styled.div`
  margin-top: 25px;
  padding: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`

const CreateContent = styled(MainContent)`
  flex-direction: inherit;
`

const CustomCollapse = styled(Collapse)`
  margin-bottom: 15px;
`

const CustomRow = styled(Row)`
  margin-bottom: 5px;
`

const CustomExtraButton = styled(Button)`
  margin-left: 10px; 
`

const PaginationWrapper = styled.div`
  text-align: right;
`

const ParameterFeedMapping = () => {

    const dispatch = useDispatch();

    React.useEffect(() => {
        async function init() {
          await dispatch(loadParameters());
          dispatch(loadMappings());
        }
        init();
    }, [dispatch]);

    const isFetchingShopData = useSelector((state: ApplicationState) =>
      shopFiltersDataIsFetching(state)
    );

    const shopActiveFilters = useSelector((state: ApplicationState) =>
      shopActiveFiltersSelector(state)
    );

    const shopData = useSelector((state: ApplicationState) =>
      shopFiltersDataSelector(state)
    );

    const onChangeShopFilter = async value => {
        await dispatch(loadShopFilterData(value));
    };

    const onSelectShopFilter = async value => {
      dispatch(setFilter('shopSelected', value));
    };

    const isFetchingCategoryData = useSelector((state: ApplicationState) =>
        categoryFiltersDataIsFetching(state)
    );

    const categoryActiveFilters = useSelector((state: ApplicationState) =>
        categoryActiveFiltersSelector(state)
    );

    const categoryData = useSelector((state: ApplicationState) =>
        categoryFiltersDataSelector(state)
    );

    const onChangeCategoryFilter = async value => {
        await dispatch(loadCategoryFilterData(value));
    };

    const onSelectCategoryFilter = async value => {
        dispatch(setFilter('categorySelected', value));
    };

    const onCreateButtonClick = async value => {
        dispatch(createMapping());
    }

    const parameterFeedMappings = useSelector((state: ApplicationState) =>
        parameterFeedMappingSelector(state)
    );

    const parameters = useSelector((state: ApplicationState) =>
        parametersSelector(state)
    );

    const onChangeParameter = async (feedParameterMappingId,mappingId,parameterId) => {  
        dispatch(changeMappingParameter(feedParameterMappingId,mappingId,parameterId));
    }

    const onSaveClick = async(feedParameterMappingId) => {
        dispatch(onSave(feedParameterMappingId));
    }

    const onRecalculateClick = async(feedParameterMappingId,shopId) => { 
        dispatch(onRecalculate(feedParameterMappingId,shopId));
    }

    const onPaginationChange = async (currentPage) => {
        dispatch(setCurrentPage(currentPage));
        dispatch(loadMappings());
    }

    const isFetchingFeedMappings = useSelector((state: ApplicationState) =>
        parameterFeedMappingIsFetching(state)
    );

    const totalSelector = useSelector((state: ApplicationState) =>
        parameterMappingTotalSelector(state)
    );

    return <>
    <PageHeader 
            title = {"Mapovanie feed parametrov"}
            ghost={false}
            extra={[]}
    />

    <CreateContent>
        <ConnectedSingleSelect
            name={__('Shop')}
            isFetching={isFetchingShopData}
            value={shopActiveFilters}
            data={shopData}
            selectWidth={200}
            onChange={onChangeShopFilter}
            onSelect={onSelectShopFilter}
        />

        <ConnectedSingleSelect
            name={__('Kategória')}
            isFetching={isFetchingCategoryData}
            value={categoryActiveFilters}
            data={categoryData}
            selectWidth={500}
            onChange={onChangeCategoryFilter}
            onSelect={onSelectCategoryFilter}
        />

        <Button onClick={onCreateButtonClick}> {__("Vytvoriť")} </Button>

    </CreateContent>

    <MainContent>
        <Spin spinning={isFetchingFeedMappings}>
        { parameterFeedMappings.map(parameterFeedMapping => {
            return  <CustomCollapse>
                        <Collapse.Panel header={parameterFeedMapping.shop_name + ' -> ' + parameterFeedMapping.category_name} key={parameterFeedMapping.key}
                            extra={[
                              <CustomExtraButton onClick={(e) => {e.preventDefault();onRecalculateClick(parameterFeedMapping._id, parameterFeedMapping.shop_id)} }  > {__("Prepočítať")} </CustomExtraButton>,
                              <CustomExtraButton onClick={(e) => {e.preventDefault();onSaveClick(parameterFeedMapping._id)} } > {__("Uložiť")} </CustomExtraButton>,
                            ]}
                        >
                            {parameterFeedMapping.mapping.map(mapping => {
                                return <CustomRow gutter={16}>
                                    <Col className="gutter-row" span={4}> {mapping.shop_parameter_name} </Col>
                                    <Col className="gutter-row" span={4}> 
                                        <ConnectedSingleSelect
                                            name={__('Parameter')}
                                            value={mapping.parameter_id}     
                                            data={parameters}
                                            selectWidth={500}
                                            onSelect={(value) => onChangeParameter(parameterFeedMapping._id, mapping._id,value) }
                                        />  
                                    </Col>
                                </CustomRow>
                            })}
                        </Collapse.Panel>
                    </CustomCollapse>
          })
        } 
        <PaginationWrapper>
            <Pagination defaultCurrent={1} total={totalSelector} pageSize={10} onChange={onPaginationChange} />
        </PaginationWrapper>  
        </Spin>

    </MainContent>

    </> 

}

export default ParameterFeedMapping