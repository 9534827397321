import { Button, Col, PageHeader, Row, Table } from 'antd';
import moment from 'moment';
import React  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ConnectedMultiSelect from '../../components/Select/ConnectedMultiSelect';
import { ApplicationState } from '../../store';
import { __ } from '../../utilities';
import { isFetchingSelector, loadScheduledTasks, loadShopFilterData, paginationSelector, ScheduledTask, scheduledTasksSelector, setFilter, setPagination, setSorter, shopActiveFiltersSelector, shopFiltersDataIsFetching, shopFiltersDataSelector, totalSelector } from './slice';

const MainContent = styled.div`
  margin-top: 25px;
  padding: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`

const ScheduledTasksList = () => {
    const dispatch = useDispatch();
    
    React.useEffect(() => {
        async function init() {
          await dispatch(loadScheduledTasks());
        }
        init();
    }, [dispatch]);
    
    const isFetchingShopData = useSelector((state: ApplicationState) =>
      shopFiltersDataIsFetching(state)
    );

    const shopActiveFilters = useSelector((state: ApplicationState) =>
      shopActiveFiltersSelector(state)
    );

    const shopData = useSelector((state: ApplicationState) =>
      shopFiltersDataSelector(state)
    );

    const onSelectShopFilter = async value => {
      dispatch(setFilter('shop', value));
      dispatch(loadScheduledTasks());
    };

    const onChangeShopFilter = async value => {
        await dispatch(loadShopFilterData(value));
    };

    const scheduledTasks = useSelector((state: ApplicationState) =>
        scheduledTasksSelector(state)
    );

    const total = useSelector((state: ApplicationState) =>
        totalSelector(state)
    );

    const pagination = useSelector((state: ApplicationState) =>
        paginationSelector(state)
    );

    const isFetching = useSelector((state: ApplicationState) =>
        isFetchingSelector(state)
    );

    const onChangeTable = async (pagination, filters, sorters) => {
        dispatch(setPagination(pagination.current));
        dispatch(setSorter(sorters.field, sorters.order));
        dispatch(loadScheduledTasks());
    };

    const onRefreshClick = () => {
        dispatch(loadScheduledTasks());
    }

    const taskResponseToText = (scheduledTask:ScheduledTask) => {
        if(scheduledTask.type === 'PROCESS_ESHOP_FEED'){
            return <> 
               {scheduledTask.response && scheduledTask.response.validFeedProductsCount !== undefined?<p> Počet validných produktov: {scheduledTask.response.validFeedProductsCount} </p>:<></> }
               {scheduledTask.response && scheduledTask.response.invalidFeedProductsCount !== undefined?<p> Počet nevalidných produktov: {scheduledTask.response.invalidFeedProductsCount} </p>:<></> }
            </>
        }
        else if(scheduledTask.type === 'MAP_MANUFACTURERS_FOR_ESHOP') {
            return <> 
               {scheduledTask.response && scheduledTask.response.mappedCount !== undefined?<p> Počet namapovaní:{scheduledTask.response.mappedCount} </p>:<></>}
            </>
        }
        else if(scheduledTask.type === 'MAP_CATEGORIES_FOR_ESHOP') {
            return <> 
               {scheduledTask.response && scheduledTask.response.mappedCount !== undefined?<p> Počet namapovaní:{scheduledTask.response.mappedCount} </p>:<></>}
            </>
        }
        else if(scheduledTask.type === 'PROCESS_AUTOMATIC_ASSIGNMENTS_FOR_ESHOP') {
            return <> 
               {scheduledTask.response && scheduledTask.response.eanCount !== undefined && scheduledTask.response.pnCount !== undefined?<p> Počet zaradených produktov do 100viek: {scheduledTask.response.eanCount+scheduledTask.response.pnCount} </p>:<></>}
            </>
        }
        else if(scheduledTask.type === 'PROCESS_AUTOR_FOR_ESHOP') {
            return <> 
               {scheduledTask.response && scheduledTask.response.assignedCount !== undefined?<p> Počet zaradených produktov: {scheduledTask.response.assignedCount} </p>:<></>}
            </>
        }
        else if(scheduledTask.type === 'ASSIGN_MAPPED_CATEGORIES_FOR_ESHOP') {
            return <> 
               {scheduledTask.response && scheduledTask.response.assignedCount !== undefined?<p> Počet priradených kategórií: {scheduledTask.response.assignedCount} </p>:<></>}
            </>
        }
        else if(scheduledTask.type === 'ASSIGN_MAPPED_MANUFACTURERS_FOR_ESHOP') {
            return <> 
               {scheduledTask.response && scheduledTask.response.assignedCount !== undefined?<p> Počet priradených výrobcov: {scheduledTask.response.assignedCount} </p>:<></>}
            </>
        }
        else if(scheduledTask.type === 'ICECAT_PARAMETER_MAPPING') {
            return <>
                {scheduledTask.response && scheduledTask.response.createdValueParametersCount !== undefined?<p> Počet vytvorených hodnotových parametrov: {scheduledTask.response.createdValueParametersCount} </p>:<></>}
                {scheduledTask.response && scheduledTask.response.createdNumberParameterCount !== undefined?<p> Počet vytvorených číselných parametrov: {scheduledTask.response.createdNumberParameterCount} </p>:<></>}
                {scheduledTask.response && scheduledTask.response.notFoundProductsCount !== undefined?<p> Počet nenájdených produktov v icecat databáze: {scheduledTask.response.notFoundProductsCount} </p>:<></>}

            </>
        }
        else if(scheduledTask.type === 'REMOVE_PN_FROM_AUTOMATIC_ASSIGNMENTS_FOR_ESHOP') {
            return <>
                {scheduledTask.response && scheduledTask.response.count !== undefined?<p> Počet odstránených 100viek: {scheduledTask.response.count} </p>:<></>}
            </>
        }
        else if(scheduledTask.type === 'RULE_PAIRING_FOR_ESHOP'){
            return <>
                {scheduledTask.response && scheduledTask.response.count !== undefined?<p> Počet zaradených produktov do 100viek: {scheduledTask.response.count} </p>:<></>}
            </>
        }
        else if(scheduledTask.type === 'CHECK_FOR_ID_CONVERSION'){
            return <>
                {scheduledTask.response && scheduledTask.response.converted !== undefined?<p> Nastala zmena: {scheduledTask.response.converted?'Áno':'Nie'} </p>:<></>}
            </>
        }

        return <></>
    }

    return <>
        <PageHeader 
            title = {"Naplánované úlohy"}
            ghost={false}
            extra={[
                <Button key="refreshButton" onClick={onRefreshClick}> 
                    Refreshovať
                </Button>
            ]}
        >   
            <Row gutter={16}>
                <Col span={8}>
                    <ConnectedMultiSelect
                        name={__('Shop')}
                        isFetching={isFetchingShopData}
                        value={shopActiveFilters}
                        data={shopData}
                        selectWidth={200}
                        onChange={onChangeShopFilter}
                        onSelect={onSelectShopFilter}
                        />
                </Col>
            </Row> 
        </PageHeader>

        <MainContent>
            <Table 
                loading = {isFetching}
                columns = {[
                    /*
                    {
                        title: __('_id'),
                        dataIndex: '_id',
                        sorter: false
                    },
                    */
                    {
                        title: __('Typ'),
                        dataIndex: 'type',
                        sorter: false,
                        render: (_id,data) => {
                            if(data.type === 'PROCESS_ESHOP_FEED'){
                                return "Spracovanie eshopu feederom"
                            }
                            else if(data.type === 'MAP_MANUFACTURERS_FOR_ESHOP'){
                                return "Mapovanie výrobcov pre eshop"
                            }
                            else if(data.type === 'MAP_CATEGORIES_FOR_ESHOP'){
                                return "Mapovanie kategórií pre eshop"
                            }
                            else if(data.type === 'PROCESS_AUTOMATIC_ASSIGNMENTS_FOR_ESHOP'){
                                return "Zaradenie do 100viek pre eshop"
                            }
                            else if(data.type === 'PROCESS_AUTOR_FOR_ESHOP'){
                                return "Spracovanie autora pre eshop"
                            }
                            else if(data.type === 'ASSIGN_MAPPED_CATEGORIES_FOR_ESHOP'){
                                return "Priradenie namapovaných kategórií produktom pre eshop"
                            }
                            else if(data.type === 'ASSIGN_MAPPED_MANUFACTURERS_FOR_ESHOP'){
                                return "Priradenie namapovaných výrobcov produktom pre eshop"
                            }
                            else if(data.type === 'RECALCULATE_FEED_PARAMETER_MAPPING'){
                                return "Prepočítanie parametrov z feedu pre eshop"
                            }
                            else if(data.type === 'ICECAT_PARAMETER_MAPPING'){
                                return "Mapovanie icecat parametrov"
                            } else if(data.type === 'REMOVE_PN_FROM_AUTOMATIC_ASSIGNMENTS_FOR_ESHOP'){
                                return "Odstránenie PN zo 100viek" 
                            } else if(data.type === 'UPDATE_SHOW_IN_WIDGETS_FOR_ESHOP'){
                                return "Aktualizácia zobrazenia widgetov pre eshop"
                            } else if(data.type === 'RULE_PAIRING_FOR_ESHOP'){
                                return "Párovanie podľa rule pre eshop"
                            } else if(data.type === 'CHECK_FOR_ID_CONVERSION'){
                                return "Zmena parovania na ID"
                            }
                            else{
                                return __("Neznámy")
                            }
                        }
                    },
                    {
                        title: __('Eshop'),
                        dataIndex: 'shop_name',
                        sorter: false
                    },
                    {
                        title: __('Vytvorená'),
                        dataIndex: 'created_at',
                        sorter: false,
                        render: (_id,data) => {
                            return moment(data.created_at).format("DD.MM.YYYY HH:mm");
                        }
                    },
                    {
                        title: __('Začatá'),
                        dataIndex: 'started_at',
                        sorter: false,
                        render: (_id,data) => {
                            if(data.started_at){
                                return moment(data.started_at).format("DD.MM.YYYY HH:mm");
                            }
                            else {
                                return "---"
                            }
                        }
                    },
                    {
                        title: __('Ukončená'),
                        dataIndex: 'finished_at',
                        sorter: false,
                        render: (_id,data) => {
                            if(data.finished_at){
                                return moment(data.finished_at).format("DD.MM.YYYY HH:mm");
                            }
                            else {
                                return "---"
                            }
                        }
                    },
                    {
                        title: __('Stav'),
                        dataIndex: 'status',
                        sorter: false,
                        render: (_id,data) => {
                            if(data.status === 'WAITING'){
                                return "Čakajúca";
                            }
                            else if(data.status === 'RUNNING'){
                                return "Bežiaca";
                            }
                            else if(data.status === 'FINISHED'){
                                return "Ukončená";
                            }
                            else if(data.status === 'ERROR'){
                                return "Chyba";
                            }
                            else{
                                return __("Neznámy")
                            }
                        }
                    },
                ]}
                dataSource = {scheduledTasks}
                pagination={{
                    total: total,
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                }}
                onChange={(pagination, filters, sorters) => {
                    onChangeTable(pagination, filters, sorters);
                }}
                expandable={{
                    expandedRowRender: record => <p> {taskResponseToText(record)} </p>,
                    rowExpandable: record => record.response?true:false
                }}
                
                size="small"
            />
        </MainContent>
    </>
}

export default ScheduledTasksList
    