import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ApplicationState, AppThunk } from "../../store";
import API from '../../services/API';
import { getValuesFromArray } from "../../utilities";

const NAMESPACE = 'manufacturerMappingList';

export interface MappingManufacturerEntity {
    shop_manufacturer?: string;
    shop_id?: number;
    manufacturer_id?: number;
    manufacturer?: any;
    manufacturer_name?: string;
    manufacturer_mapping_id?: number;
}

export interface MappingManufacturerAction {
    shop_manufacturer: string;
    shop_id: number;
    manufacturer_id: number;
    type: string;
    manufacturer_mapping_id: number;
}

export interface ManufacturerProduct {
    name: string;
    url: string;
}

interface MappingManufacturersState {
    activeFilters: {
        shopSelected: { value: number }[],
        query: string,
        onlyNotMapped: boolean
    },
    filtersData: {
        shop: {
            isFetching: boolean,
            data: any[];
        }
    },
    sorterData: {
        type: string,
        order: string;
    },
    filterResults: {
        offset: number
        isFetching: boolean,
        total: number;
        items: any[];
    },
    pagination: {
        pageSize: number;
        current: number
    },
    mappingActions: Array<MappingManufacturerAction>,
    sendingMappingAction: boolean,
    manufacturerProducts: {
        products: Array<ManufacturerProduct>,
        isFetching: boolean,
        isVisible: boolean
    }
}

const initialState: MappingManufacturersState = {
    activeFilters: {
        shopSelected: [],
        query: null,
        onlyNotMapped: false
    },
    filtersData: {
        shop: {
            isFetching: false,
            data: []
        }
    },
    sorterData: {
        type: null,
        order: 'asc'
    },
    filterResults: {
        isFetching: false,
        total: 0,
        offset: 0,
        items: []
    },
    pagination: {
        pageSize: 20,
        current: 1
    },
    mappingActions: [],
    sendingMappingAction: false,
    manufacturerProducts: {
        products: [],
        isFetching: false,
        isVisible: false
    }
};

export const mappingManufacturerSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        addMappingAction: (state, action: PayloadAction<MappingManufacturerAction>) => {
            state.mappingActions.push(action.payload);
        },
        removeMappingAction: (state, action: PayloadAction<string>) => {
            state.mappingActions = state.mappingActions.filter(a => {
                return a.shop_manufacturer !== action.payload
            });
        },
        setActiveFilterValue: (state, action: PayloadAction<{ name: string, value: number[] | string | number | boolean | null }>) => {
            state.activeFilters[action.payload.name] = action.payload.value;
        },
        setFilterData: (state, action: PayloadAction<{ name: string, value: any }>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
        setIsFetchingFilterData: (state, action: PayloadAction<{ name: string, value: boolean }>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },

        setIsFetchingFilterResults: (state, action: PayloadAction<{ value: boolean }>) => {
            state.filterResults.isFetching = action.payload.value
        },
        setFilterResults: (state, action: PayloadAction<{ total: number, items: Array<MappingManufacturerEntity> }>) => {
            state.filterResults.total = action.payload.total;
            state.filterResults.items = action.payload.items;
        },
        updateRecord: (state, action: PayloadAction<{ shop_manufacturer: string, new_data: any }>) => {
            state.filterResults.items = state.filterResults.items.map(item => {
                if (item.shop_manufacturer === action.payload.shop_manufacturer) {
                    return { ...item, ...action.payload.new_data }
                } else {
                    return item;
                }
            });
        },
        setSorterData: (state, action: PayloadAction<{ type: string, order: string }>) => {
            state.sorterData.type = action.payload.type;
            state.sorterData.order = action.payload.order;
        },
        setPagination: (state, action: PayloadAction<{ current: number }>) => {
            state.pagination.current = action.payload.current
        },
        setSendingMappingAction: (state, action: PayloadAction<boolean>) => {
            state.sendingMappingAction = action.payload
        },
        setManufacturerProducts: (state, action: PayloadAction<Array<ManufacturerProduct>>) => {
            state.manufacturerProducts.products = action.payload;
        },
        setManufacturerProductsIsFetching: (state, action: PayloadAction<boolean>) => {
            state.manufacturerProducts.isFetching = action.payload;
        },
        setManufacturerProductsIsVisible: (state, action: PayloadAction<boolean>) => {
            state.manufacturerProducts.isVisible = action.payload;
        }
    }
});

export const updateRecord = (shop_manufacturer: string, new_data: any) => (dispatch, getState) => {
    dispatch(mappingManufacturerSlice.actions.updateRecord({ shop_manufacturer, new_data }));
};

export const setFilter = (filter: string, value: number[] | string | number | boolean | null) => (dispatch, getState) => {
    dispatch(mappingManufacturerSlice.actions.setActiveFilterValue({ name: filter, value }));
};

export const addMappingAction = (action: MappingManufacturerAction) => (dispatch, getState) => {
    dispatch(mappingManufacturerSlice.actions.addMappingAction(action));
};

export const removeMappingAction = (shop_manufacturer: string) => (dispatch, getState) => {
    dispatch(mappingManufacturerSlice.actions.removeMappingAction(shop_manufacturer));
};

export const mappingActionsSelector = (state: ApplicationState) => state.manufacturerMappingList.mappingActions;

export const setSendingMappingAction = (sending: boolean) => (dispatch, getState) => {
    dispatch(mappingManufacturerSlice.actions.setSendingMappingAction(sending));
};

export const mappingActionsisSendingSelector = (state: ApplicationState) => state.manufacturerMappingList.sendingMappingAction;

export const setSorter = (field: string, orderName: string) => (dispatch, getState) => {
    const type: string = orderName ? field : undefined;
    const order: string = orderName === 'descend' ? 'desc' : 'asc';

    dispatch(mappingManufacturerSlice.actions.setSorterData({ type: type, order: order }));
};

export const paginationSelector = (state: ApplicationState) => state.manufacturerMappingList.pagination;

export const setPagination = (current: number) => (dispatch, getState) => {
    dispatch(mappingManufacturerSlice.actions.setPagination({ current: current }));
};

export const loadShopFilterData = (value: string): AppThunk => async (dispatch, getState) => {
    dispatch(mappingManufacturerSlice.actions.setIsFetchingFilterData({ name: 'shop', value: true }));

    API.loadShops({ query: value, limit: 100 }).then(response => {
        dispatch(mappingManufacturerSlice.actions.setFilterData({
            name: 'shop', value:
                response.shops.map(shop => {
                    return {
                        name: shop.name + ` (${shop.program_id})`,
                        value: shop.shop_id
                    }
                })
        }));

        dispatch(mappingManufacturerSlice.actions.setIsFetchingFilterData({ name: 'shop', value: false }));
    })
};

export const shopActiveFiltersSelector = (state: ApplicationState) => state.manufacturerMappingList.activeFilters.shopSelected;
export const shopFiltersDataSelector = (state: ApplicationState) => state.manufacturerMappingList.filtersData.shop.data;
export const shopFiltersDataIsFetching = (state: ApplicationState) => state.manufacturerMappingList.filtersData.shop.isFetching;

export const queryActiveSelector = (state: ApplicationState) => state.manufacturerMappingList.activeFilters.query;

export const loadItems = (): AppThunk => async (dispatch, getState) => {
    dispatch(mappingManufacturerSlice.actions.setIsFetchingFilterResults({ value: true }));
    const filterShopIds = getValuesFromArray<number>(
        shopActiveFiltersSelector(getState())
    );

    const offset = (getState().manufacturerMappingList.pagination.current - 1) * getState().manufacturerMappingList.pagination.pageSize;

    API.listManufacturersMapping({
        shopId: filterShopIds && filterShopIds.length ? filterShopIds : undefined,
        limit: getState().manufacturerMappingList.pagination.pageSize,
        offset: offset,
        onlyNotMapped: getState().manufacturerMappingList.activeFilters.onlyNotMapped,
        query: getState().manufacturerMappingList.activeFilters.query ? getState().manufacturerMappingList.activeFilters.query : undefined,
    }).then(response => {
        const items: Array<MappingManufacturerEntity> = response.items.map(item => {
            return { ...item, manufacturer_name: (item.manufacturer ? item.manufacturer.name : null), key: item.shop_manufacturer }
        });
        
        dispatch(
            mappingManufacturerSlice.actions.setIsFetchingFilterResults({ value: false })
        );
        dispatch(
            mappingManufacturerSlice.actions.setFilterResults({
                total: response.total,
                items: items,
            })
        );
    });
};

export const loadManufacturerProducts = (key: string): AppThunk => async (dispatch, getState) => {
    dispatch(mappingManufacturerSlice.actions.setManufacturerProductsIsFetching(true));
    const manufacturers = filterResultsItemsSelector(getState());
    const manufacturer = manufacturers.find(manufacturer => manufacturer.key === key);
    
    if (manufacturer) {
        API.loadPMProducts({
            filterShopIds: [manufacturer.shop_id],
            //filterManufacturerId: [manufacturer.manufacturer_id],
            filterManufacturerName: [manufacturer.shop_manufacturer],
            searchStrategy: 'standard',
            limit: 20
        }).then(response => {
            const manufacturerProducts: Array<ManufacturerProduct> = response.pm_products.map(pmProduct => {
                return {
                    name: pmProduct.name,
                    url: pmProduct.url
                }
            });

            dispatch(mappingManufacturerSlice.actions.setManufacturerProducts(manufacturerProducts));
        }).finally(() => {
            dispatch(mappingManufacturerSlice.actions.setManufacturerProductsIsFetching(false));
        })
    }
}

export const setManufacturerProductsIsVisible = (isVisible: boolean): AppThunk => async (dispatch, getState) => {
    dispatch(mappingManufacturerSlice.actions.setManufacturerProductsIsVisible(isVisible));
}


export const filterResultsTotalSelector = (state: ApplicationState) => state.manufacturerMappingList.filterResults.total;
export const filterResultsItemsSelector = (state: ApplicationState) => state.manufacturerMappingList.filterResults.items;
export const filterResultsIsFetchingSelector = (state: ApplicationState) => state.manufacturerMappingList.filterResults.isFetching;
export const filterResultsOffsetSelector = (state: ApplicationState) => state.manufacturerMappingList.filterResults.offset;

export const manufacturerProductsSelector = (state: ApplicationState) => state.manufacturerMappingList.manufacturerProducts;

export default mappingManufacturerSlice.reducer;
