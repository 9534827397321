import {useDispatch, useSelector} from "react-redux";
import React from "react";
import styled from 'styled-components';
import {ApplicationState} from "../../store";
import {DatePicker, Table} from "antd";
import locale from "antd/es/date-picker/locale/sk_SK";
import moment from "moment";
import {
  endDateActiveFiltersSelector,
  generalStatsSelector, intervalStatsSelector,
  loadGeneralStats, loadStatsDiff, loadUsersStats,
  setFilter,
  startDateActiveFiltersSelector, userStatsSelector
} from "./slice";
import {__} from "../../utilities";
import { userRoleSelector } from "../AffilStats/slice";

const HeaderSection = styled.div`
   display: flex;
   padding-bottom: 20px;
`

const DateRangeWrapper = styled.div`
   margin-left: auto;
`

const StatsSection = styled.div`
   display: grid;
   grid-template-columns: auto auto;
   row-gap: 20px;
   margin-bottom: 20px;

`

const StatsSectionColumnTitle = styled.div`
   font-size: 25px;
`

const StatsSectionColumnValue = styled.div`
   font-size: 20px;
`
const MainTitle = styled.div`
   font-size: 30px;
   font-weight: bold;
`

const StatsSectionColumn = styled.div`
   
`

const CMSStats = () => {
  
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function init() {
      dispatch(loadGeneralStats());
      dispatch(loadUsersStats());
    }

    init();
  }, [dispatch]);

  const columns = [
    {
      title: __('Meno'),
      dataIndex: 'name',
    },
    {
      title: __('nové produkty'),
      dataIndex: 'products',
    },
    {
      title: __('nové ponuky'),
      dataIndex: 'goods',
    }
  ]


  const onDateRangeChange = (value) => {
    dispatch(setFilter('startDate', value[0]));
    dispatch(setFilter('endDate', value[1]));
    dispatch(loadStatsDiff());
  }

  const startDate = useSelector((state: ApplicationState) =>
    startDateActiveFiltersSelector(state)
  );

  const endDate = useSelector((state: ApplicationState) =>
    endDateActiveFiltersSelector(state)
  );
  const generalStats = useSelector((state: ApplicationState) =>
    generalStatsSelector(state)
  );
  const intervalStats = useSelector((state: ApplicationState) =>
    intervalStatsSelector(state)
  );
  const userStats = useSelector((state: ApplicationState) =>
    userStatsSelector(state)
  );

  const userRole:string = useSelector((state: ApplicationState) =>
        userRoleSelector(state)
  );


  const onChangeTable = (pagination: any) => {

  }

  return (
    <>
      <HeaderSection>
        <div>
          <MainTitle>
            Štatistiky
          </MainTitle>
          {(startDate || endDate) ?
            <>
              Za
              obdobie {startDate ? "od " + startDate.format('DD.MM.YYYY') : ""} {endDate ? "do " + endDate.format('DD.MM.YYYY') : ""}
            </>
            : null
          }
        </div>

        <DateRangeWrapper>
          <DatePicker.RangePicker
            locale={locale}
            ranges={{
              'Dnes': [moment(), moment()],
              'Posledných 7 dní': [moment().subtract(7, "days"), moment()],
              'Posledných 30 dní': [moment().subtract(30, "days"), moment()],
            }}
            onChange={onDateRangeChange}
            format={"DD.MM.YYYY"}
          />
        </DateRangeWrapper>
      </HeaderSection>
      
      { userRole === 'ADMIN'?  
         <StatsSection>
          <StatsSectionColumn>
            <div>
              <StatsSectionColumnTitle>
                Celkovo
              </StatsSectionColumnTitle>
              <StatsSectionColumnValue>
                Produktov: {generalStats.products}
              </StatsSectionColumnValue>
              <StatsSectionColumnValue>
                Ponúk: {generalStats.goods}
              </StatsSectionColumnValue>
              <StatsSectionColumnValue>
                Platiacich eshopov: {generalStats.paying_shops}
              </StatsSectionColumnValue>
              <StatsSectionColumnValue>
                Aktívnych eshopov: {generalStats.active_shops}
              </StatsSectionColumnValue>
            </div>
          </StatsSectionColumn>
          <StatsSectionColumn>
            <div>
              <StatsSectionColumnTitle>
                Zmena za vybraný čas:
              </StatsSectionColumnTitle>
              <StatsSectionColumnValue>
                Nových produktov: {intervalStats.products}
              </StatsSectionColumnValue>
              <StatsSectionColumnValue>
                Nových ponúk: {intervalStats.goods}
              </StatsSectionColumnValue>
              <StatsSectionColumnValue>
                Platiacich eshopov: {(intervalStats.paying_shops > 0 ? '+' : '') + intervalStats.paying_shops}
              </StatsSectionColumnValue>
              <StatsSectionColumnValue>
                Aktívnych eshopov: {(intervalStats.active_shops > 0 ? '+' : '') + intervalStats.active_shops}
              </StatsSectionColumnValue>
            </div>
          </StatsSectionColumn>
        </StatsSection> 
        :null
      }
      
      <StatsSectionColumnTitle>{__('Používatelia')}</StatsSectionColumnTitle>
      <Table
        columns={columns}
        dataSource={userStats}
        onChange={(pagination,) => {
          onChangeTable(pagination);
        }}
      />
    </>
  )

}

export default CMSStats;