import React from 'react';
import {
    Modal
  } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { changeManufactureModalSelector, changeManufacturerActiveFiltersSelector, changeManufacturerFiltersDataIsFetching, changeManufacturerFiltersDataSelector, loadChangeManufacturerFilterData, setFilter, setModalVisible, updateManufacturers } from './slice';
import ConnectedSingleSelect from '../../components/Select/ConnectedSingleSelect';
import { __ } from '../../utilities';

const ChangeManufacturerModal = (props) => {

    const dispatch = useDispatch();
    
    const changeManufacturerModal = useSelector((state: ApplicationState) =>
        changeManufactureModalSelector(state)
    );

    const isFetching = useSelector((state: ApplicationState) =>
       changeManufacturerFiltersDataIsFetching(state)
    );

    const activeFilters = useSelector((state: ApplicationState) =>
       changeManufacturerActiveFiltersSelector(state)
    );

    const data = useSelector((state: ApplicationState) =>
       changeManufacturerFiltersDataSelector(state)
    );
    
    const handleClose = () => {
        dispatch(setModalVisible("changeManufacturer",false));
    };

    const onChange = async value => {
        dispatch(loadChangeManufacturerFilterData(value));
        console.log("ON CHANGE");
    }

    const onSelect = async value => {
        dispatch(setFilter("changeManufacturerSelected",value));
        console.log("ON SELECT"); 
    }

    const onOkButton = () => {
        dispatch(updateManufacturers());
        dispatch(setModalVisible("changeManufacturer",false));
    }

    return <Modal visible = { changeManufacturerModal.isVisible } 
                  onCancel={handleClose}
                  onOk={onOkButton}
                  okText="Zmeniť"
                  cancelText="Zrušiť"
                  title="Zmena výrobcu"
           > 
                <ConnectedSingleSelect
                    name={__('Výrobca')}
                    isFetching={isFetching}
                    value={activeFilters}
                    data={data}
                    selectWidth={200}
                    onChange={onChange}
                    onSelect={onSelect}
                />

           </Modal>
}

export default ChangeManufacturerModal;