import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import {
    Modal
  } from 'antd';
import { productPreviewModalSelector, setModalVisibility, setProductPreviewModalData } from './slice';
import styled from 'styled-components';
import { rem } from 'polished';

const ModalProduct = styled.div`
  flex: 1;
  padding: 1rem;  
`

const ModalProductName = styled.p`
`

const ModalProductImage = styled.img`
   max-width: 100%;
   max-height: 250px;
`

const ModalProductDescription = styled.p`
   
`

const ModalProductCategory = styled.p`
   margin-top: ${rem(30)};
`

const ProductPreviewModal = (props) => {

    const dispatch = useDispatch();

    const productPreviewModal = useSelector((state: ApplicationState) =>
       productPreviewModalSelector(state)
    );

    const handleClose = () => {
        dispatch(setModalVisibility("productPreview",false));
        dispatch(setProductPreviewModalData(null));
    }

    return <Modal visible = {productPreviewModal.isVisible}
                  onCancel={handleClose}
                  onOk={handleClose}
                  title="Náhľad produktu"
           >    
                {productPreviewModal.productData? 
                    <ModalProduct> 
                        <ModalProductName> {productPreviewModal.productData.name} </ModalProductName>
                        <ModalProductImage src={productPreviewModal.productData.imageUrl} />
                        <ModalProductCategory> <b> Kategória: </b>{productPreviewModal.productData.categoryName} </ModalProductCategory>
                        <br/>
                        <ModalProductDescription> {productPreviewModal.productData.shortDescription} </ModalProductDescription>  
                    </ModalProduct>   
                :null} 
               
           </Modal>
}

export default ProductPreviewModal;