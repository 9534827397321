import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import EditableTable from "../../containers/ParameterDetail/EditableTable";
import { ApplicationState } from "../../store";
import { getRandomString, __ } from "../../utilities";
import { CategoryListingLink, createListingLink, listingLinksSelector, loadCategoryListingLinks, localeIdSelector, removeListingLink, setLocaleId, updateListingLinkValue, updatePositions} from "./slice";

const MainSection = styled.div`

`

const ButtonSection = styled.div`
   
`

const CategoryListingLinks = ({categoryId,defaultLocaleId}) => {

   const dispatch = useDispatch();

   React.useEffect(() => {
      async function init() {
        if(categoryId){
          await dispatch(loadCategoryListingLinks(categoryId))
        }  
      }
      init();
   }, [dispatch, categoryId]);

   React.useEffect(() => {
      async function init() {
        await dispatch(setLocaleId(defaultLocaleId))
      }
      init();
   }, [dispatch, defaultLocaleId]);

   const localeId = useSelector((state: ApplicationState) =>
        localeIdSelector(state)
   );

   const listingLinks = useSelector((state: ApplicationState) =>
        listingLinksSelector(state)
   );

   const onListingLinkRemove = (_id) => {
      dispatch(removeListingLink(_id)); 
   }


   const defaultTableItem:CategoryListingLink = { 
      _id: "NEW_"+getRandomString(10),
      language_id: localeId,
      position: 99999,
      name: "",
      url: null,
      index: 9999
   }

   return <> 
      <ButtonSection>
      </ButtonSection>

      <MainSection>
         <EditableTable 
            rowKey = "_id"
            onUpdate = {(data,dataIndex) => {
               dispatch(updateListingLinkValue(dataIndex,data)); 
            }}
            onCreate = {(data) => {
               dispatch(createListingLink(data));
            }}
            onUpdatePositions = {(data => {
               dispatch(updatePositions(data.oldIndex,data.newIndex));
            })}
            columns = {[
               {
                   title: __('name'),
                   dataIndex: 'name',
                   sorter: false,
                   inputType: 'text',
                   editable: true,
               },
               {
                  title: __('url'),
                  dataIndex: 'url',
                  sorter: false,
                  inputType: 'text',
                  editable: true,
               },
               {
                  title: __('Akcie'),
                  dataIndex: 'actions',
                  sorter: false,
                  editable: false,
                  render: (_id,data) => {
                      return <Button type="primary"
                                    size="small"
                                    icon={<DeleteOutlined />}
                                    onClick={() => {
                                      onListingLinkRemove(data._id)
                                    }} 
                             />
                              
                  }
               }
            ]}
            dataSource = {listingLinks.filter(listingLink => listingLink.language_id === localeId)}
            trans={{
               addRowText: __("Pridať nový odkaz")
            }}
            defaultItem = {defaultTableItem}
            isSortable = {true}
         >

         </EditableTable>
      </MainSection>   
   </>
}

export default CategoryListingLinks