import { rem } from 'polished';
import React, { useState } from 'react';
import API from '../../services/API';
import ConnectedSingleSelect from './ConnectedSingleSelect';

const ShopSelect = (props) => {
    
    const {onSelect, defaultValue} = props;

    const [selectedValue,setSelectedValue] = useState(null);
    const [data,setData] = useState([]);
    
    React.useEffect(() => {
        async function init() {
          if(defaultValue){
            API.loadShops({
                shopId: defaultValue
            }).then(response => {
              const data = response.shops.map(shop => {
                  return {
                      name: shop.name,
                      value: shop.shop_id
                  }
              })
              setData(data);
              setSelectedValue(defaultValue);
            })
          } 
        }
        init();
     }, [defaultValue]);

    const onChange = (query) => {
        API.loadShops({
            query: query,
            limit: 10
        }).then((response => {
            const data = response.shops.map(shop => {
                return {
                    name: shop.name,
                    value: shop.shop_id
                }
            })
            setData(data)
        }))
    }

    const onInternalSelect = (selectedValue) => {
        setSelectedValue(selectedValue);
        if(onSelect) onSelect(selectedValue);
    }

    return <ConnectedSingleSelect
        value={selectedValue}
        data={data}
        selectWidth={rem(200)}
        onChange={onChange}
        onSelect={onInternalSelect}
    />
}

export default ShopSelect;