import { rem } from 'polished';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConnectedSingleSelect from '../../components/Select/ConnectedSingleSelect';
import { ApplicationState } from '../../store';
import { loadSearchParameters, parametersSearchSelector, updateCategoryParameter } from './slice';


export const ParameterSelect = ({_id,currentParameter}) => {
    const dispatch = useDispatch();

    const parametersSearch = useSelector((state: ApplicationState) =>
        parametersSearchSelector(state)
    )

    const onParameterChange = (searchtext:string) => {
        dispatch(loadSearchParameters(searchtext))
    }

    const onParameterSelect = (parameter_id) => {
        dispatch(updateCategoryParameter(_id,parameter_id));
    }

    return <ConnectedSingleSelect
        isFetching={false}
        value={currentParameter?currentParameter.internal_name:""}
        data={parametersSearch?parametersSearch:[]}
        selectWidth={rem(200)}    
        onChange={onParameterChange}
        onSelect={onParameterSelect}                      
    />
}