import React, { useState, useCallback } from 'react';
import {
  Table, Row, Col, Button, Card, Divider, Modal, Input, Form, Menu, Dropdown
} from 'antd';
import {
  LinkOutlined,
  SearchOutlined
} from '@ant-design/icons';

import styled from 'styled-components';
import { __ } from '../../utilities';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { shopFiltersDataIsFetching, shopFiltersDataSelector, shopActiveFiltersSelector, setFilter, loadShopFilterData, setShopFilter, manufacturerFiltersDataIsFetching, manufacturerActiveFiltersSelector, manufacturerFiltersDataSelector, loadManufacturerFilterData, categoryFiltersDataIsFetching, categoryActiveFiltersSelector, categoryFiltersDataSelector, loadCategoryFilterData, setManufacturerFilter, mainProductSelector, catalogProductSelector, setModalVisibility, wordPhraseReplacementModalSelector, mainCategorySelector, loadSingleWordReplacements, wordSingleReplacementSelector, mainManufacturerFiltersDataIsFetching, mainManufacturerActiveFiltersSelector, mainManufacturerFiltersDataSelector, loadMainManufacturerFilterData, mainCategoryFiltersDataIsFetching, mainCategoryActiveFiltersSelector, mainCategoryFiltersDataSelector, loadMainCategoryFilterData, loadMainManufacturerById, loadMainCategoryByCategoryId, regenerate, setSelectedSuggestions, selectedSuggestionsSelector, eanActiveFilterSelector, partNumberActiveFilterSelector, setProductNamePart, productNamePartsSelector, setMaskSettingsModalInput, setMasksForInput, loadPhraseWordReplacements, productSuggestionsIsFetchingSelector, createProduct, setMainFilter, productFilteredSuggestionsSelector, handleRowClick, secondarySelectedSuggestionsSelector, changeMainProduct, setProductPreviewModalData, createButtonSelector, loadNewMainProduct, handleShiftSelection, wordSingleReplacementModalSelector, setProductPreviewModalCatalogData, setForceIds, forcedPmProductsSelector, loadForcedMainProduct, assignProducts, setProductNamePartSeparator, productNamePartsSeparatorSelector } from './slice';
import ConnectedSingleSelect from '../../components/Select/ConnectedSingleSelect';
import SingleWordReplacementModal from './SingleWordReplacementModal';
import MaskSettingsModal from './MaskSettingsModal';
import PhraseWordReplacementModal from './PhraseWordReplacementModal';
import ChangeCategoryModal from './ChangeCategoryModal';
import ChangeManufacturerModal from './ChangeManufacturerModal';
import ProductPreviewModal from './ProductPreviewModal';
import SkipModal from './SkipModal';
import SkipSuggestionsModal from './SkipSuggestionsModal';
import { useLocation } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex
`

const TableRow = styled(Row)`
  margin-bottom: 15px; 
`

const FitlerRow = styled(Row)`
  margin-bottom: 15px; 
`;

const RightSection = styled(Col)`
  max-width: 400px;
`
const LeftSection = styled(Col)`
  margin-right: 15px
`

const MainProductImage = styled.img`
  max-height: 300px
`

const EmptyDiv: any = styled.div`
  position: absolute;
  top: ${(props: any) => props.top + "px"} ;
  left: ${(props: any) => props.left + "px"};
`

const FilterButton = styled(Button)`
  margin-right: 5px
`

const SelectProductFilterButton = styled(FilterButton)`
  margin-right: 5px;
  margin-left: 5px;
`

const ContentWrapper: any = styled.div`
  display: ${(props: any) => props.visible ? "block" : "none"} ;
`

const FormItem = styled(Form.Item)`
  margin-bottom: 0px;
`

const RowActionButtons = styled.div`
  display: flex;
`

const CustomLinkOutlined = styled(LinkOutlined)`
  margin-left: 10px;
  cursor: pointer;
`

const ProductCreate = () => {
  const dispatch = useDispatch();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const [isSecondaryDropdownVisible, setSecondaryDropdownVisible] = useState(false);
  const [secondaryDropdownPosition, setSecondaryDropdownPosition] = useState({ top: 0, left: 0 });

  const [contextInputSelectedName, setContextInputSelectedName] = useState(null);
  const [secondaryClickedKey, setSecondaryClickedKey] = useState(null);
  const [lastSecondaryClickedKey, setLastSecondaryClickedKey ] = useState(null);
  const [selectedForcedPmProductFeedId,setSelectedForcedPmProductFeedId] =  useState(null);

  let location = useLocation();

  const [pressedKeys,setPressedKeys] = useState([]);

  const [form] = Form.useForm();
  
  const wordSingleReplacementModal = useSelector((state: ApplicationState) =>
      wordSingleReplacementModalSelector(state)
  );

  React.useEffect(() => {
    const forceIdsString = new URLSearchParams(location.search).get('force_ids');
    if(forceIdsString){
      const forceIds =  forceIdsString.split(",").map(forceId => {
         return parseInt(forceId) 
      });
      dispatch(setForceIds(forceIds))
    }
    else{
      dispatch(setForceIds(null))
    }

  }, [location,dispatch]);

  const keyDownHandleFunction = useCallback((e) => {
    if(e.keyCode === 16 && !wordSingleReplacementModal.isVisible){
      console.log(wordSingleReplacementModal)
      const newPressedKeys = [...pressedKeys];
      newPressedKeys[e.keyCode] = true;
      setPressedKeys(newPressedKeys);
    }
    return false;
    // eslint-disable-next-line
  },[wordSingleReplacementModal]);

  const keyUpHandlerFunction = useCallback((e) => {
    if(e.keyCode === 16 && !wordSingleReplacementModal.isVisible){
      const newPressedKeys = [...pressedKeys];
      newPressedKeys[e.keyCode] = false;
      setPressedKeys(newPressedKeys);
    }
    return false;
    // eslint-disable-next-line  
  },[wordSingleReplacementModal]);

  React.useEffect(() => {
    document.addEventListener("keydown", keyDownHandleFunction, false);
    document.addEventListener("keyup", keyUpHandlerFunction, false);

    return () => {
      document.removeEventListener("keydown", keyDownHandleFunction, false);
      document.removeEventListener("keyUp", keyUpHandlerFunction, false);
    };
  }, [keyDownHandleFunction,keyUpHandlerFunction]);

  const backgroundSecondarySelectedColor = '#756bff';

  const isFetchingShopData = useSelector((state: ApplicationState) =>
    shopFiltersDataIsFetching(state)
  );

  const shopActiveFilters = useSelector((state: ApplicationState) =>
    shopActiveFiltersSelector(state)
  );

  const shopData = useSelector((state: ApplicationState) =>
    shopFiltersDataSelector(state)
  );

  const onChangeShopFilter = async value => {
    console.log("CHANGED SHOP FILTER");
    await dispatch(loadShopFilterData(value));
  };

  const onSelectShopFilter = async value => {
    dispatch(setShopFilter('shopSelected', value));
    console.log("ON SELECT SHOP FILTER");
    // dispatch(loadFeedReports());
  };

  const isFetchingManufacturerData = useSelector((state: ApplicationState) =>
    manufacturerFiltersDataIsFetching(state)
  );

  const manufacturerActiveFilters = useSelector((state: ApplicationState) =>
    manufacturerActiveFiltersSelector(state)
  );

  const manufacturerData = useSelector((state: ApplicationState) =>
    manufacturerFiltersDataSelector(state)
  );

  const onChangeManufacturerFilter = async value => {
    dispatch(loadManufacturerFilterData(value));
  };

  const onFocusManufacturerFilter = async value => {
    dispatch(loadManufacturerFilterData(""));
  };

  const onSelectManufacturerFilter = async value => {
    dispatch(setManufacturerFilter('manufacturerSelected', value));
    console.log("MANUFACTURER SELECTED");
    //dispatch(loadAutomaticPairing());
  };

  const isFetchingCategoryData = useSelector((state: ApplicationState) =>
    categoryFiltersDataIsFetching(state)
  );

  const categoryActiveFilters = useSelector((state: ApplicationState) =>
    categoryActiveFiltersSelector(state)
  );

  const categoryData = useSelector((state: ApplicationState) =>
    categoryFiltersDataSelector(state)
  );

  const onChangeCategoryFilter = async value => {
    dispatch(loadCategoryFilterData(value));
  };

  const onFocusCategoryFilter = async value => {
    dispatch(loadCategoryFilterData(""))
  }

  const onSelectCategoryFilter = async value => {
    dispatch(setFilter('categorySelected', value));
    dispatch(loadNewMainProduct());
    // dispatch(loadAutomaticPairing());
  };

  const mainProduct = useSelector((state: ApplicationState) =>
    mainProductSelector(state)
  );

  const catalogProducts = useSelector((state: ApplicationState) =>
    catalogProductSelector(state)
  );

  // PHASE WORD REPLACEMENT
  const handlePhaseReplacementModalCancel = () => {
    dispatch(setModalVisibility("wordPhraseReplacement", false));
  };

  const handlePhaseReplacementModalSave = () => {
    dispatch(setModalVisibility("wordPhraseReplacement", false));
  };

  const wordPhaseReplacementModal = useSelector((state: ApplicationState) =>
    wordPhraseReplacementModalSelector(state)
  );

  // SINGLE WORD REPLACEMENT
  const handleSingleWordReplacementButtonClick = () => {
    dispatch(setModalVisibility("wordSingleReplacement", true));
    dispatch(loadSingleWordReplacements());
  }

  const wordSingleReplacement = useSelector((state: ApplicationState) =>
    wordSingleReplacementSelector(state)
  );

  const mainCategory = useSelector((state: ApplicationState) =>
    mainCategorySelector(state)
  );

  // main manufacturer
  const isFetchingMainManufacturerData = useSelector((state: ApplicationState) =>
    mainManufacturerFiltersDataIsFetching(state)
  );

  const mainManufacturerActiveFilters = useSelector((state: ApplicationState) =>
    mainManufacturerActiveFiltersSelector(state)
  );

  const mainManufacturerData = useSelector((state: ApplicationState) =>
    mainManufacturerFiltersDataSelector(state)
  );

  const onChangeMainManufacturerFilter = async value => {
    dispatch(loadMainManufacturerFilterData(value));
  };

  const onFocusMainManufacturerFilter = async value => {
    dispatch(loadMainManufacturerFilterData(""));
  };

  const onSelectMainManufacturerFilter = async value => {
    dispatch(loadMainManufacturerById(value));
  };

  // main category
  const isFetchingMainCategoryData = useSelector((state: ApplicationState) =>
    mainCategoryFiltersDataIsFetching(state)
  );

  const mainCategoryActiveFilters = useSelector((state: ApplicationState) =>
    mainCategoryActiveFiltersSelector(state)
  );

  const mainCategoryData = useSelector((state: ApplicationState) =>
    mainCategoryFiltersDataSelector(state)
  );

  const onChangeMainCategoryFilter = async value => {
    dispatch(loadMainCategoryFilterData(value));
  };

  const onFocusMainCategoryFilter = async value => {
    dispatch(loadMainCategoryFilterData(""));
  };

  const onSelectMainCategoryFilter = async value => {
    dispatch(loadMainCategoryByCategoryId(value));
  };

  const productSuggestions = useSelector((state: ApplicationState) =>
    productFilteredSuggestionsSelector(state)
  );

  const productSuggestionsIsFetching = useSelector((state: ApplicationState) =>
    productSuggestionsIsFetchingSelector(state)
  );

  const createButton = useSelector((state: ApplicationState) =>
    createButtonSelector(state)
  );

  const forcedPmProducts = useSelector((state: ApplicationState) =>
      forcedPmProductsSelector(state) 
  );

  const onSelectForcedPmProductFilter = async value => {
      setSelectedForcedPmProductFeedId(value)
  }; 

  let dropdownMenu = (
    <Menu onClick={(item) => { onDropdownMenuClick(item) }}>
      <Menu.Item key="mask_settings">Nastavenie masky</Menu.Item>
      <Menu.Item key="word_replacement">Zámena slova</Menu.Item>
    </Menu>
  );

  let secondaryDropdownMenu = (
    <Menu onClick={(item) => { onSecondaryDropdownMenuClick(item) }}>
      <Menu.Item key="force_main_product"> Nastaviť ako hlavný produkt </Menu.Item>
      <Menu.Item key="change_category">Zmena kategórie</Menu.Item>
      <Menu.Item key="change_manufacturer">Zmena výrobcov</Menu.Item>
      <Menu.Item key="skip_products">Preskočiť produkty</Menu.Item>
      <Menu.Item key="assign_products">Priradiť produkt</Menu.Item>
    </Menu>
  )

  const onSecondaryDropdownMenuClick = async (item) => {
    if (item.key === 'change_category') {
      dispatch(setModalVisibility("changeCategory", true));
      console.log("CHANGE CATEGORY CLICK");
    }
    else if (item.key === 'change_manufacturer') {
      dispatch(setModalVisibility("changeManufacturer", true));
      console.log("CHANGE MANUFACTURER CLICK");
    }
    else if (item.key === 'force_main_product') {
      if (secondaryClickedKey) {
        dispatch(changeMainProduct(secondaryClickedKey));
      }
    }
    else if(item.key === 'skip_products') {
      dispatch(setModalVisibility("skipSuggestions", true));
    }
    else if(item.key === 'assign_products'){
      if (secondaryClickedKey) {
        dispatch(assignProducts(secondaryClickedKey));
      }
    } 
  }

  const onDropdownMenuClick = async (item) => {
    const row = await form.validateFields();
    const value = row[contextInputSelectedName];

    if (item.key === 'mask_settings') {
      dispatch(setModalVisibility("maskSettings", true));
      dispatch(setMaskSettingsModalInput(contextInputSelectedName, value));
    }
    else if (item.key === 'word_replacement') {
      dispatch(setModalVisibility("wordPhraseReplacement", true));
      dispatch(loadPhraseWordReplacements(contextInputSelectedName, value));
      console.log("WORD REPLACEMENT CLICK");
    }
  }

  const onContextName = (inputName, event) => {
    event.preventDefault();
    setDropdownVisible(true);
    setDropdownPosition({ top: event.clientY, left: event.clientX });
    setContextInputSelectedName(inputName);
  }

  const handleRegenerateClick = async () => {
    dispatch(regenerate());
  }

  const handleSkipButton = async () => {
    dispatch(setModalVisibility("skip", true));
    //dispatch(loadNextMainProduct());
    //console.log("HANDLE SKIP BUTTON");
  }

  const onSelectChange = selectedRowKeys => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    dispatch(setSelectedSuggestions(selectedRowKeys));
  };

  const selectedSuggestions = useSelector((state: ApplicationState) =>
    selectedSuggestionsSelector(state)
  );

  const secondarySelectedSuggestions = useSelector((state: ApplicationState) =>
    secondarySelectedSuggestionsSelector(state)
  );

  const ean = useSelector((state: ApplicationState) =>
    eanActiveFilterSelector(state)
  );

  const partNumber = useSelector((state: ApplicationState) =>
    partNumberActiveFilterSelector(state)
  );

  const productNameParts = useSelector((state: ApplicationState) =>
    productNamePartsSelector(state)
  );

  const productNamePartsSeparators = useSelector((state: ApplicationState) =>
    productNamePartsSeparatorSelector(state)
  );

  const handleCreateButtonClick = () => {
    console.log("CREATE BUTTON CLICK");
    dispatch(createProduct());
  }

  const test = () => {
    console.log("X");
    console.log("TEST");
    console.log(mainCategoryActiveFilters);
  }

  const onRow = (record, index) => ({
    onContextMenu: event => {
      event.preventDefault();
      setSecondaryDropdownVisible(true);
      setSecondaryDropdownPosition({ top: event.clientY + window.pageYOffset, left: event.clientX + window.pageXOffset });
      setSecondaryClickedKey(record.key);
      return true;
    },

    onClick: event => {
      if(pressedKeys[16]){
        dispatch(handleShiftSelection(record.key,lastSecondaryClickedKey));
      }
      else{
        dispatch(handleRowClick(record.key));
      }
       
      setLastSecondaryClickedKey(record.key);
    }
  });

  const onSelectPmProduct = () => {
    dispatch(loadForcedMainProduct(selectedForcedPmProductFeedId));
  }

  test();

  return (
    <>
      <Wrapper>
        <LeftSection flex={4}>
          <Form form={form}
            component={false}
            onFieldsChange={(changedFields, allFields) => {
              console.log("ON FIELD CHANGE");

              for (const changeField of changedFields) {
                console.log(changeField.name.toString());
                if (changeField.name.toString() === 'filter') {
                  dispatch(setMainFilter('filter', changeField.value));
                }
                else if (changeField.name.toString() === 'ean') {
                  dispatch(setFilter('ean', changeField.value));
                }
                else if (changeField.name.toString() === 'part_number') {
                  dispatch(setFilter('partNumber', changeField.value));
                }
                else if (changeField.name.toString() === 'model_name' ||
                  changeField.name.toString() === 'model_name_prefix' ||
                  changeField.name.toString() === 'model_name_suffix' ||
                  changeField.name.toString() === 'optional1_param' ||
                  changeField.name.toString() === 'optional2_param' ||
                  changeField.name.toString() === 'optional3_param' ||
                  changeField.name.toString() === 'optional4_param' ||
                  changeField.name.toString() === 'optional5_param' ||
                  changeField.name.toString() === 'optional6_param'
                ) {
                  dispatch(setMasksForInput(changeField.name.toString(), changeField.value));
                  dispatch(setProductNamePart(changeField.name.toString(), { value: changeField.value }))
                }
                else if(changeField.name.toString() === 'optional1_2_sepator' ||
                        changeField.name.toString() === 'optional2_3_sepator' ||
                        changeField.name.toString() === 'optional3_4_sepator' ||
                        changeField.name.toString() === 'optional4_5_sepator' ||
                        changeField.name.toString() === 'optional5_6_sepator'
                ){
                  dispatch(setProductNamePartSeparator(changeField.name.toString(), changeField.value))
                }
              }
            }}
            fields={[
              {
                name: 'ean',
                value: ean
              },
              {
                name: 'part_number',
                value: partNumber
              },
              {
                name: 'model_name',
                value: productNameParts.model_name.value
              },
              {
                name: 'model_name_prefix',
                value: productNameParts.model_name_prefix.value
              },
              {
                name: 'model_name_suffix',
                value: productNameParts.model_name_suffix.value
              },
              {
                name: 'optional1_param',
                value: productNameParts.optional1_param.value
              },
              {
                name: 'optional2_param',
                value: productNameParts.optional2_param.value
              },
              {
                name: 'optional3_param',
                value: productNameParts.optional3_param.value
              },
              {
                name: 'optional4_param',
                value: productNameParts.optional4_param.value
              },
              {
                name: 'optional5_param',
                value: productNameParts.optional5_param.value
              },
              {
                name: 'optional6_param',
                value: productNameParts.optional6_param.value
              },
              {
                name: 'optional1_2_sepator',
                value: productNamePartsSeparators.optional1_2_sepator
              },
              {
                name: 'optional2_3_sepator',
                value: productNamePartsSeparators.optional2_3_sepator
              },
              {
                name: 'optional3_4_sepator',
                value: productNamePartsSeparators.optional3_4_sepator
              },
              {
                name: 'optional4_5_sepator',
                value: productNamePartsSeparators.optional4_5_sepator
              },
              {
                name: 'optional5_6_sepator',
                value: productNamePartsSeparators.optional5_6_sepator
              }
            ]}

          >
            <TableRow>
              <Col className="gutter-row" span={24}>
                {(forcedPmProducts && forcedPmProducts.length)?
                     <FitlerRow gutter={{ lg: 5 }}>
                        <ConnectedSingleSelect
                          name={__('Produkt')}
                          value={selectedForcedPmProductFeedId}
                          data={forcedPmProducts.map(fpp => {
                            return {
                              name: fpp.name + (fpp.shopName?" ("+fpp.shopName+")":""),
                              value: fpp.feed_id
                            }
                          })  }
                          selectWidth={500}
                          onSelect={onSelectForcedPmProductFilter}
                        />

                        <SelectProductFilterButton type="primary"
                            disabled={selectedForcedPmProductFeedId?false:true}
                            onClick={onSelectPmProduct}
                          >
                            Vybrať
                        </SelectProductFilterButton>   
                    </FitlerRow>
                    
                :
                  <FitlerRow gutter={{ lg: 5 }}>
                    <Col>
                      <ConnectedSingleSelect
                        name={__('Shop')}
                        isFetching={isFetchingShopData}
                        value={shopActiveFilters}
                        data={shopData}
                        selectWidth={200}
                        onChange={onChangeShopFilter}
                        onSelect={onSelectShopFilter}
                      />
                    </Col>

                    <Col>
                      <ConnectedSingleSelect
                        name={__('Výrobca')}
                        isFetching={isFetchingManufacturerData}
                        value={manufacturerActiveFilters}
                        data={manufacturerData}
                        selectWidth={200}
                        onFocus={onFocusManufacturerFilter}
                        onChange={onChangeManufacturerFilter}
                        onSelect={onSelectManufacturerFilter}
                      />
                    </Col>

                    <Col>
                      <ConnectedSingleSelect
                        name={__('Kategória')}
                        isFetching={isFetchingCategoryData}
                        value={categoryActiveFilters}
                        data={categoryData}
                        selectWidth={200}
                        onFocus={onFocusCategoryFilter}
                        onChange={onChangeCategoryFilter}
                        onSelect={onSelectCategoryFilter}
                      />
                    </Col>
                    
                    {mainProduct? 
                      <Col>
                        <FilterButton type="primary"
                          disabled={false}
                          onClick={handleSkipButton}
                        >
                          Preskočiť
                        </FilterButton>  
                      </Col>
                    :""}
                  </FitlerRow>
                }

                <ContentWrapper visible={mainProduct ? true : false}>
                  <FitlerRow gutter={{ lg: 5 }}>
                    <Col>
                      <FormItem
                        name="part_number">
                        <Input placeholder="Part number" />
                      </FormItem>
                    </Col>

                    <Col>
                      <FormItem
                        name="ean"
                      >
                        <Input placeholder="Ean" />
                      </FormItem>
                    </Col>
                  </FitlerRow>

                  <FitlerRow gutter={{ lg: 5 }}>
                    <Col>
                      <ConnectedSingleSelect
                        name={__('Výrobca')}
                        isFetching={isFetchingMainManufacturerData}
                        value={mainManufacturerActiveFilters ? mainManufacturerActiveFilters.name : null}
                        data={mainManufacturerData}
                        selectWidth={200}
                        onFocus={onFocusMainManufacturerFilter}
                        onChange={onChangeMainManufacturerFilter}
                        onSelect={onSelectMainManufacturerFilter}
                      />
                    </Col>

                    <Col>
                      <ConnectedSingleSelect
                        name={__('Kategória')}
                        isFetching={isFetchingMainCategoryData}
                        value={mainCategoryActiveFilters ? mainCategoryActiveFilters.name : null}
                        data={mainCategoryData}
                        selectWidth={400}
                        onFocus={onFocusMainCategoryFilter}
                        onChange={onChangeMainCategoryFilter}
                        onSelect={onSelectMainCategoryFilter}
                      />
                    </Col>
                    <Col>
                      <FormItem
                        name="model_name_prefix" >
                        <Input placeholder="Modelový názov"
                          onContextMenu={(event) => { onContextName("model_name_prefix", event) }}
                        />
                      </FormItem>
                    </Col>

                    <Col>
                      <FormItem
                        name="model_name"
                      >
                        <Input style={{ background: '#ffe299' }} placeholder="Modelový názov"
                          onContextMenu={(event) => { onContextName("model_name", event) }}
                        />
                      </FormItem>
                    </Col>

                    <Col>
                      <FormItem
                        name="model_name_suffix"
                      >
                        <Input placeholder="Modelový názov"
                          onContextMenu={(event) => { onContextName("model_name_suffix", event) }}
                        />
                      </FormItem>
                    </Col>
                  </FitlerRow>

                  <FitlerRow gutter={{ lg: 5 }}>
                    {mainCategory && mainCategory.optionalParams.map((optionalParam,index) => {
                      return (
                        <>
                          <Col>
                            <FormItem
                              name={optionalParam.id}
                            >
                              <Input placeholder={optionalParam.name}
                                onContextMenu={(event) => { onContextName(optionalParam.id, event) }}
                              />
                            </FormItem>
                          </Col>
                          {(index+1 !== mainCategory.optionalParams.length) &&  
                            <Col>
                              <FormItem
                                name={`optional${index+1}_${index+2}_sepator`}
                              >
                                <Input style={{maxWidth:20, paddingRight: 5, paddingLeft: 5}} />
                              </FormItem>
                            </Col>
                          }
                        </>
                      )
                    })
                    }

                    <FormItem
                      name='filter'
                    >
                      <Input placeholder={'Filter'}
                        style={{ background: '#feb8ff' }}
                      />
                    </FormItem>

                  </FitlerRow>

                  <FitlerRow>
                    <FilterButton type="primary"
                      disabled={false}
                      onClick={handleRegenerateClick}
                    >
                      Pregenerovať
                  </FilterButton>

                  <FilterButton type="primary"
                      disabled={wordSingleReplacement.isDisabled}
                      onClick={handleSingleWordReplacementButtonClick}>
                      Zámena slova
                  </FilterButton>

                    <FilterButton type="primary"
                      disabled={ createButton.isDisabled }
                      onClick={handleCreateButtonClick}>
                      Vytvoriť
                  </FilterButton>

                  </FitlerRow>
                </ContentWrapper>
              </Col>
            </TableRow>
            <TableRow>
              <Table
                loading={productSuggestionsIsFetching}
                onRow={onRow}
                columns={[
                  {
                    title: "Feed id",
                    dataIndex: 'feed_id',
                    render: (text, record) => {
                      return {
                        props: {
                          style: { background: secondarySelectedSuggestions.includes(record.key) ? backgroundSecondarySelectedColor : record.color },
                        },
                        children: <div>{text}</div>,
                      };
                    }
                  },
                  {
                    title: "Názov produktu v obchode",
                    dataIndex: 'name',
                    width: '500px',
                    render: (text, record) => {
                      return {
                        props: {
                          style: { background: secondarySelectedSuggestions.includes(record.key) ? backgroundSecondarySelectedColor : record.color },
                        },
                        children: <div>{text}</div>,
                      };
                    }
                  },
                  {
                    title: "Cena",
                    dataIndex: 'price',
                    width: '150px',
                    render: (text, record) => {
                      let priceConflict = false;
                      if(record.medianPrice){
                        let normalizedPrice = record.price;
                        if(record.countryId === 2){
                          normalizedPrice = record.price*0.038;
                        }
                        
                        if(normalizedPrice < record.medianPrice*0.5 || normalizedPrice > record.medianPrice*2) {
                          priceConflict = true;
                        }
                      }
                  
                      return {
                        props: {
                          style: { background: priceConflict?('red'):(secondarySelectedSuggestions.includes(record.key) ? backgroundSecondarySelectedColor : record.color) },
                        },
                        children: <div>{text} {record.countryId === 1?"€":"CZK"}</div>,
                      };
                    }
                  },
                  {
                    title: "Part number",
                    dataIndex: 'partNumber',
                    render: (text, record) => {
                      return {
                        props: {
                          style: { background: secondarySelectedSuggestions.includes(record.key) ? backgroundSecondarySelectedColor : record.color },
                        },
                        children: <div>{text}</div>,
                      };
                    }
                  },
                  {
                    title: "Ean",
                    dataIndex: 'ean',
                    render: (text, record) => {
                      return {
                        props: {
                          style: { background: secondarySelectedSuggestions.includes(record.key) ? backgroundSecondarySelectedColor : record.color },
                        },
                        children: <div>{text}</div>,
                      };
                    }
                  },
                  {
                    title: "Názov obchodu",
                    dataIndex: 'shopName',
                    render: (text, record) => {
                      return {
                        props: {
                          style: { background: secondarySelectedSuggestions.includes(record.key) ? backgroundSecondarySelectedColor : record.color },
                        },
                        children: <div>{text}</div>,
                      };
                    }
                  },
                  {
                    title: "Zhoda",
                    dataIndex: "matchTypes",
                    render: (matchTypes, record) => {
                      return {
                        props: {
                          style: { background: secondarySelectedSuggestions.includes(record.key) ? backgroundSecondarySelectedColor : record.color },
                        },
                        children: <div> {matchTypes ? matchTypes.join(', ') : ""} </div>
                      };
                    }
                  },
                  {
                    title: "Katalógový názov",
                    dataIndex: "catalogName",
                    render: (text, record) => {
                      return {
                        props: {
                          style: { background: secondarySelectedSuggestions.includes(record.key) ? backgroundSecondarySelectedColor : record.color },
                        },
                        children: <div>{text}</div>,
                      };
                    }
                  },
                  {
                    title: "",
                    render: (text, record) => {
                      return {
                        props: {
                          style: { background: secondarySelectedSuggestions.includes(record.key) ? backgroundSecondarySelectedColor : record.color },
                        },
                        children: <RowActionButtons>
                                    <SearchOutlined 
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        dispatch(setProductPreviewModalData(record.key));
                                        dispatch(setModalVisibility("productPreview", true));
                                      }}
                                    />
                                    <CustomLinkOutlined
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        window.open(record.url, "_blank");
                                      }}
                                    />
                                  </RowActionButtons>
                      }
                    }
                  }
                ]}
                dataSource={productSuggestions}
                pagination={false}
                rowSelection={{
                  selectedRowKeys: selectedSuggestions,
                  onChange: onSelectChange,
                }}
              />
            </TableRow>
          </Form>
        </LeftSection>
        <RightSection flex={1}>
          {mainProduct ?
            <Card title={<span style={{whiteSpace: 'normal', height: 'auto'}}>{mainProduct.name}</span>}>  
              <MainProductImage src={mainProduct.image} />
              <Divider />
              <p> Cena {mainProduct.price} </p>
            </Card>
            : ""
          }

          {catalogProducts ?
            <Card>
              <Table columns={
                [
                  {
                    title: "Názov",
                    dataIndex: 'name',
                    key: 'name',
                  },
                  {
                    title: "",
                    width: '50px',
                    render: (text, record) => {
                      return {
                        children: <RowActionButtons>
                                    <SearchOutlined 
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        dispatch(setProductPreviewModalCatalogData(record.key));
                                        dispatch(setModalVisibility("productPreview", true));
                                      }}
                                    />
                                  </RowActionButtons>
                      }
                    }
                  }
                ]
              }
                dataSource={catalogProducts.items}
                pagination={false}
                scroll={{ y: 300 }}

              />
            </Card>
            : ""}
          
          {mainProduct ?
           <Card
              title = "Popis"
           >
              {mainProduct.description}
           </Card>
           : ""
          }


        </RightSection>
      </Wrapper>

      <Modal
        visible={wordPhaseReplacementModal.isVisible}
        onCancel={handlePhaseReplacementModalCancel}
        onOk={handlePhaseReplacementModalSave}
        title="Zámena názvu"
        width={1000}
      >
        <div>
          XX1
            </div>
      </Modal>

      <SingleWordReplacementModal />
      <PhraseWordReplacementModal />
      <MaskSettingsModal />
      <ChangeCategoryModal />
      <ChangeManufacturerModal />
      <ProductPreviewModal />
      <SkipModal />
      <SkipSuggestionsModal />

      <Dropdown overlay={dropdownMenu}
        visible={isDropdownVisible}
        onVisibleChange={(visible) => { setDropdownVisible(visible) }}
      >
        <EmptyDiv top={dropdownPosition.top} left={dropdownPosition.left} />
      </Dropdown>

      <Dropdown overlay={secondaryDropdownMenu}
        visible={isSecondaryDropdownVisible}
        onVisibleChange={(visible) => { setSecondaryDropdownVisible(visible) }}
      >
        <EmptyDiv top={secondaryDropdownPosition.top} left={secondaryDropdownPosition.left} />
      </Dropdown>

    </>
  )
}

export default ProductCreate;
