import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import API from '../../services/API';
import { ApplicationState, AppThunk } from '../../store';
import { prop } from '../../utilities';

const NAMESPACE = 'webWidgetSlice';

interface TreeNode {
  key?: string | number;
  title?: string;
  isLeaf?: boolean;
  children?: TreeNode[];
  parameters?: any[];
  value?: string | number;
}

interface webWidgetState {
  widgets: {
    data: any;
    isFetching: boolean;
  };
  widget: {
    data: any;
    isFetching: boolean;
  };
  data: {
    shop: {
      isFetching: boolean;
      data: any;
      shop: { value: number }[];
    };
    manufacturer: {
      isFetching: boolean;
      data: any;
      manufacturer: { value: number }[];
    };
    category: {
      isFetching: boolean;
      data: any;
      category: { value: number }[];
    };
  };
  countryId: number;
}

const initialState: webWidgetState = {
  widgets: {
    data: [],
    isFetching: false,
  },
  widget: {
    data: [],
    isFetching: false,
  },
  data: {
    shop: {
      isFetching: false,
      data: [],
      shop: [],
    },
    manufacturer: {
      isFetching: false,
      data: [],
      manufacturer: [],
    },
    category: {
      isFetching: false,
      data: [],
      category: [],
    },
  },
  countryId: 1,
};

export const webWidgetSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setIsFetchingWidgetsData: (state, action: PayloadAction<boolean>) => {
      state.widgets.isFetching = action.payload;
    },
    setWidgetsData: (state, action: PayloadAction<any>) => {
      state.widgets.data = action.payload;
    },
    setIsFetchingWidgetData: (state, action: PayloadAction<boolean>) => {
      state.widget.isFetching = action.payload;
    },
    setWidgetDataIsFetching: (
      state,
      action: PayloadAction<{ name: string; value: boolean }>
    ) => {
      state.data[action.payload.name].isFetching = action.payload;
    },
    setWidgetDataFilters: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      state.data[action.payload.name].data = action.payload.value;
    },
    setOnSelectWidgetDataFilters: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      state.data[action.payload.name][action.payload.name] = action.payload.value;
    },
    setWidgetData: (state, action: PayloadAction<any>) => {
      state.widget.data = action.payload;
    },
    setCountryId: (state, action: PayloadAction<number>) => {
      state.countryId = action.payload;
    },
  },
});

export const loadWidgets = () => (dispatch, getState) => {
  dispatch(webWidgetSlice.actions.setIsFetchingWidgetsData(true));
  const countryId = countryIdSelector(getState());
  API.loadWebWidgets({ countryId }).then(response => {
    const result = response.items.map(data => {
      return data;
    });

    dispatch(webWidgetSlice.actions.setWidgetsData(result));
    dispatch(webWidgetSlice.actions.setIsFetchingWidgetsData(false));
  });
};

export const loadWidget = (widget_id = []) => (dispatch, getState) => {
  dispatch(webWidgetSlice.actions.setIsFetchingWidgetData(true));
  const countryId = countryIdSelector(getState());
  API.loadWebWidgets({
    widgetIds: widget_id,
    countryId,
  }).then(response => {
    const result = response.items.map(data => {
      const widget_data = data.widget_data.map(item => {
        const name = prop(item, 'name', prop(item, 'nameSk', ''));
        return {
          key: item['id'],
          name: name,
          img: item.itemImgSk,
          userDefined: item.userDefined,
          index: item.index,
        };
      });
      return {
        ...data,
        widget_data,
      };
    });

    dispatch(webWidgetSlice.actions.setWidgetData(result));
    dispatch(webWidgetSlice.actions.setIsFetchingWidgetData(false));
  });
};

export const saveItemSort = (item, index, widget_id, newArray): AppThunk => (
  dispatch,
  getState
) => {
  dispatch(webWidgetSlice.actions.setIsFetchingWidgetData(true));
  const countryId = countryIdSelector(getState());
  newArray = newArray.reduce((acc, m) => {
    acc.push({
      key: m.key,
      name: m.name,
      userDefined: m.userDefined,
      index: m.index,
      id: m.id,
    });
    return acc;
  }, []);

  const extra = JSON.stringify(newArray);
  API.updateItemToWidget({
    widgetId: widget_id,
    newIndex: index,
    itemId: prop(item, 'key', undefined),
    extra,
    countryId,
  }).then(response => {
    const result = response.items.map(data => {
      const widget_data = data.widget_data.map(item => {
        const name = prop(item, 'name', prop(item, 'nameSk', ''));
        return {
          key: item['id'],
          name: name,
          img: item.itemImgSk,
          userDefined: item.userDefined,
          index: item.index,
        };
      });
      return {
        ...data,
        widget_data,
      };
    });
    dispatch(webWidgetSlice.actions.setWidgetData(result));
    dispatch(webWidgetSlice.actions.setIsFetchingWidgetData(false));
  });
};

export const saveNewItem = (item_id, widget_id, extra, itemType = null): AppThunk => (
  dispatch,
  getState
) => {
  const countryId = countryIdSelector(getState());
  if (item_id) {
    if(itemType){
      extra = itemType;
    }
    API.updateItemToWidget({
      widgetId: widget_id,
      newIndex: 0,
      itemId: item_id,
      extra,
      countryId,
    }).then(response => {
      const result = response.items.map(data => {
        const widget_data = data.widget_data.map(item => {
          const name = prop(item, 'name', prop(item, 'nameSk', ''));
          return {
            key: item['id'],
            name: name,
            img: item.itemImgSk,
            userDefined: item.userDefined,
            index: item.index,
          };
        });
        return {
          ...data,
          widget_data,
        };
      });
      dispatch(webWidgetSlice.actions.setWidgetData(result));
      dispatch(webWidgetSlice.actions.setIsFetchingWidgetData(false));
    });
  }
};

export const setCountryId = (countryId: number): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(webWidgetSlice.actions.setCountryId(countryId));
};

export const loadShopData = (value: string): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(
    webWidgetSlice.actions.setWidgetDataIsFetching({
      name: 'shop',
      value: true,
    })
  );
  API.loadShops({
    query: value,
    limit: 100,
  }).then(response => {
    dispatch(
      webWidgetSlice.actions.setWidgetDataFilters({
        name: 'shop',
        value: response.shops.map(shop => {
          return {
            name: shop.name,
            value: shop.shop_id,
          };
        }),
      })
    );
    dispatch(
      webWidgetSlice.actions.setWidgetDataIsFetching({
        name: 'shop',
        value: false,
      })
    );
  });
};
export const loadManufacturersData = (value: string): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(
    webWidgetSlice.actions.setWidgetDataIsFetching({
      name: 'manufacturer',
      value: true,
    })
  );

  API.loadManufacturers({
    query: value,
    limit: 100,
  }).then(response => {
    dispatch(
      webWidgetSlice.actions.setWidgetDataFilters({
        name: 'manufacturer',
        value: response.manufacturers.map(manufacturer => {
          return {
            name: manufacturer.name,
            value: manufacturer.manufacturer_id
          };
        }),
      })
    );
    dispatch(
      webWidgetSlice.actions.setWidgetDataIsFetching({
        name: 'manufacturer',
        value: false,
      })
    );
  });
};

export const setFilterOnSelect = (
  filter: string,
  value: number[] | string | number | null | boolean
) => (dispatch, getState) => {
  dispatch(webWidgetSlice.actions.setOnSelectWidgetDataFilters({ name: filter, value }));
};


export const loadCategoryData = (value: string) : AppThunk => async (dispatch, getState) => {
  dispatch(webWidgetSlice.actions.setWidgetDataIsFetching({name: 'category', value: true}));

  API.loadCategories({query:value,limit:100}).then(response => {
    dispatch( webWidgetSlice.actions.setWidgetDataFilters({name: 'category', value: 
      response.categories.map( category => {
        let categoryName;
        if(category && category.translations && category.translations.length){
            const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
            if(categoryTranslation){
                categoryName = categoryTranslation.name;
            }
        }

        return {
          name: category.parents_names ? [...category.parents_names].reverse().filter((item, index) => index % 2 === 0).join(' < ') : categoryName,
          value: category.category_id
        }
      })
    }));
    
    dispatch(webWidgetSlice.actions.setWidgetDataIsFetching({name: 'category', value: false}));
  });
}

export const widgetsDataSelector = (state: ApplicationState) =>
  state.webWidget.widgets.data;
export const widgetsIsFetchingSelector = (state: ApplicationState) =>
  state.webWidget.widgets.isFetching;
export const widgetDataSelector = (state: ApplicationState) =>
  state.webWidget.widget.data;
export const widgetIsFetchingSelector = (state: ApplicationState) =>
  state.webWidget.widget.isFetching;
export const countryIdSelector = (state: ApplicationState) =>
  state.webWidget.countryId;

export const shopActiveFiltersSelector = (state: ApplicationState) =>
  state.webWidget.data.shop.shop;
export const shopFiltersDataSelector = (state: ApplicationState) =>
  state.webWidget.data.shop.data;
export const shopFiltersDataIsFetching = (state: ApplicationState) =>
  state.webWidget.data.shop.isFetching;

export const manufacturerActiveFiltersSelector = (state: ApplicationState) =>
  state.webWidget.data.manufacturer.manufacturer;
export const manufacturerFiltersDataSelector = (state: ApplicationState) =>
  state.webWidget.data.manufacturer.data;
export const manufacturerFiltersDataIsFetching = (state: ApplicationState) =>
  state.webWidget.data.manufacturer.isFetching;

export const categoryActiveFiltersSelector = (state: ApplicationState) =>
  state.webWidget.data.category.category;
export const categoryrFiltersDataSelector = (state: ApplicationState) =>
  state.webWidget.data.category.data;
export const categoryFiltersDataIsFetching = (state: ApplicationState) =>
  state.webWidget.data.category.isFetching;

export default webWidgetSlice.reducer;
