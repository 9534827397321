import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import API from "../../services/API";
import {ApplicationState, AppThunk} from "../../store";

const NAMESPACE = 'TopCategories';

interface CategoryItem {
    category_id: number,
    image: string, 
    name: string, 
    cpc: any
}

interface ProductSearchListState {
    activeFilters: {
        categorySelected: number[],
    },
    filterResults: {
        offset: number
        isFetching: boolean,
        total: number;
        categories: CategoryItem[];
    },
}

const initialState : ProductSearchListState = {
    activeFilters: {
        categorySelected: []
    },
    filterResults: {
        offset: 0,
        isFetching: false,
        total: 0,
        categories: []
    },
}

export const topCategoriesSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<{categories: CategoryItem[]}>) => {
        state.filterResults.categories = action.payload.categories;
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => {
        state.filterResults.isFetching = action.payload;
    },
    setCategorySelected: (state, action: PayloadAction<number>) => {
        state.activeFilters.categorySelected = [action.payload];
    }
  }
});

export const getTopCategories = async (getState):Promise<{categories: CategoryItem[]}> => {
    
    const categoryIds = categorySelectedSelector(getState());

    const subcategoriesIds = [];
    if(categoryIds && categoryIds.length){
        const resp = await API.loadCategoriesTreeList({
            parentId: categoryIds[0],
            maxDepth: 1000
        });

        if(resp && resp.categoryTreeList && resp.categoryTreeList.length){
            for(const categoryTree of resp.categoryTreeList){
                subcategoriesIds.push(categoryTree.lastCategoryId);
            }
        }
    }

    const response = await API.loadCategories({
        limit: 50,
        categoryIds: subcategoriesIds && subcategoriesIds.length?subcategoriesIds:undefined,
        sortType: 'impressions',
        sortOrder: 'desc',
        isLast: true
    });

    const categories:CategoryItem[] = [];

    for(const category of response.categories){
        let categoryName;
        if (category && category.translations && category.translations.length) {
            const categoryTranslation = category.translations.find(
            translation => translation.language_id === 1
            );
            if (categoryTranslation) {
            categoryName = categoryTranslation.name;
            }
        }
        
        let averageCpc:any = 0;
        if(category && category.info && category.info.length){
            const categoryInfo = category.info.find(item => item.country_id === 1);
            if(categoryInfo){
                averageCpc = categoryInfo.average_ppc?categoryInfo.average_ppc:0;
            }
        } 

        categories.push({
            category_id: category.category_id,
            name: categoryName,
            image: `https://y1.sk/t/c/200x200/${category.category_id}.jpg`,
            cpc: averageCpc?(Math.round(averageCpc * 100) / 100):'N/A'
        });
    }

    return {categories:categories};
}

export const loadTopCategories = ():AppThunk => async (dispatch, getState)  => {

    dispatch(topCategoriesSlice.actions.setIsFetching(true));
    const {categories} = await getTopCategories(getState);
    dispatch(topCategoriesSlice.actions.setIsFetching(false));

    dispatch(topCategoriesSlice.actions.setCategories({categories}));
}

export const setCategorySelected = (categoryId):AppThunk => async (dispatch, getState)  => {
    dispatch(topCategoriesSlice.actions.setCategorySelected(categoryId));
    dispatch(loadTopCategories());
}

export const topCategoriesSelector = (state: ApplicationState) => state.topCategories.filterResults.categories;
export const isFetchingSelector = (state: ApplicationState) => state.topCategories.filterResults.isFetching;
export const categorySelectedSelector = (state: ApplicationState) => state.topCategories.activeFilters.categorySelected;

export default topCategoriesSlice.reducer;