import { useDispatch, useSelector } from "react-redux";
import { manufacturerProductsSelector, setManufacturerProductsIsVisible } from "./slice";
import { ApplicationState } from "../../store";
import React from "react";
import Modal from "antd/lib/modal/Modal";


const ManufacturerProductsModal = (props) => {

    const dispatch = useDispatch();

    const manufacturerProducts = useSelector((state: ApplicationState) =>
         manufacturerProductsSelector(state)
    );

    const handleClose = () => {
        dispatch(setManufacturerProductsIsVisible(false));
    }

    return <Modal visible = {manufacturerProducts.isVisible}
                  onCancel={handleClose}
                  onOk={handleClose} 
                  title="Zoznam produktov"
           >    
                <div>
                    { manufacturerProducts.products && manufacturerProducts.products.length?manufacturerProducts.products.map(product => {
                        return <div> {product.name} </div>
                    }):<div> Bez produktov </div>  }  
                </div>

           </Modal>
}

export default ManufacturerProductsModal;