import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState, AppThunk } from "../../store";
import API from "../../services/API";
import { checkNullOrUndefined } from "../../utilities";

const NAMESPACE = 'widgetSettings';

interface WidgetSettingsItem {
    colors:{
        primary: string
    },
    show_header: boolean,
    max_shops: number
}

interface WidgetSettingsItemState {
    colors:{
        primary: boolean
    },
    show_header: boolean,
    max_shops: boolean
}

interface WidgetGeneralSettings {

}

interface WidgetSettingsState {
    loading: boolean;
    settings: WidgetSettingsItem;
    settingsState: WidgetSettingsItemState;
    test: string;
    widgetName: string;
    widgetType: string;
    widgetAffiliateId: number;
}

const initialState : WidgetSettingsState = {
    loading: false,
    settings: {
        colors:{
            primary: "#fff"
        },
        show_header: false, 
        max_shops: 5
    },
    settingsState: {
        colors:{
            primary: false
        },
        show_header: false, 
        max_shops: false
    },
    widgetName: null,
    widgetType: null,
    widgetAffiliateId: null,

    test: "AAA"
}

export const widgetSettingsSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setSettings: (state, action: PayloadAction<WidgetSettingsItem>) => {
            state.settings = action.payload
        },
        setSettingsState: (state, action: PayloadAction<WidgetSettingsItemState>) => {
            state.settingsState =  action.payload
        },
        setWidgetName: (state, action: PayloadAction<string>) => {
            state.widgetName = action.payload
        },
        setWidgetType: (state, action: PayloadAction<string>) => {
            state.widgetType = action.payload
        },
        setWidgetAffiliateId: (state, action: PayloadAction<number>) => {
            state.widgetAffiliateId = action.payload
        },
        // ------------------ states -----------------------
        setPrimaryColorState: (state, action: PayloadAction<boolean>) => {
            state.settingsState.colors.primary = action.payload
        },
        setShowHeaderState: (state, action: PayloadAction<boolean>) => {
            state.settingsState.show_header = action.payload
        },
        setMaxShopsState: (state, action: PayloadAction<boolean>) => {
            state.settingsState.max_shops = action.payload
        }, 
        // -------------------------------------------------
        // ----------------- settings ----------------------<
        setPrimaryColor: (state, action: PayloadAction<string>) => {
            state.settings.colors.primary = action.payload
        },
        setShowHeader: (state, action: PayloadAction<boolean>) => {
            state.settings.show_header = action.payload
        },
        setMaxShops: (state, action: PayloadAction<number>) => {
            state.settings.max_shops = action.payload
        },
        // -------------------------------------------------
        resetState: state => initialState
    }
});

export const loadWidgetSettings = (widgetId: string): AppThunk => async (dispatch, getState) => {

    const currentWidgetSettings = widgetSettingsSelector(getState());

    dispatch(widgetSettingsSlice.actions.setLoading(true));
    if(widgetId){

        API.loadWidgets({
            widgetIds: [widgetId]
        }).then(response => {
            let widget   = response.items[0];
            let settings = response.items[0] && response.items[0].widget_product && response.items[0].widget_product.settings;
            let productId = (response.items[0] && response.items[0].widget_product && response.items[0].widget_product.product_id)?response.items[0].widget_product.product_id:null;
            if(settings){
                dispatch(widgetSettingsSlice.actions.setSettings({
                colors:{
                    primary: settings.colors && settings.colors.primary?settings.colors.primary:currentWidgetSettings.colors.primary
                },
                max_shops: settings.max_shops?settings.max_shops:currentWidgetSettings.max_shops,
                show_header: settings.show_header?settings.show_header:currentWidgetSettings.show_header
                }))

                dispatch(widgetSettingsSlice.actions.setSettingsState({
                colors:{
                    primary: !settings.colors || checkNullOrUndefined(settings.colors.primary)?false:true
                },
                max_shops:  checkNullOrUndefined(settings.max_shops)?false:true,
                show_header: checkNullOrUndefined(settings.show_header)?false:true
                }))
            }

            if(productId){
                API.loadProducts({
                    filterProductIds: [productId]
                }).then(response => {
                    if(response.products && response.products.length){
                        const product = response.products[0];
                        dispatch(widgetSettingsSlice.actions.setWidgetName(product.name));
                    } 
                })
            }
            if(widget){
                dispatch(widgetSettingsSlice.actions.setWidgetAffiliateId(widget.affiliate_id));
                dispatch(widgetSettingsSlice.actions.setWidgetType(widget.type));
            } 

            /*
            else{
                dispatch(widgetSettingsSlice.actions.setSettings({
                    colors:{
                        primary: null
                    },
                    max_shops: null,
                    show_header: null
                }))
            }
            */

            dispatch(widgetSettingsSlice.actions.setLoading(false));
        })
    }
}

// ------------------------------------ state --------------------------------------
export const changePrimaryColorState = (state: boolean): AppThunk => async (dispatch, getState) => {
    dispatch(widgetSettingsSlice.actions.setPrimaryColorState(state));  
}

export const changeShowHeaderState = (state: boolean): AppThunk => async (dispatch, getState) => {
    dispatch(widgetSettingsSlice.actions.setShowHeaderState(state));
}

export const changeMaxShopsState = (state: boolean): AppThunk => async (dispatch, getState) => {
    dispatch(widgetSettingsSlice.actions.setMaxShopsState(state));
}
// ----------------------------------------------------------------------------------
// ---------------------------------- settings --------------------------------------
export const changePrimaryColor = (state: {hex:string}) : AppThunk => async (dispatch, getState) => {
    dispatch(widgetSettingsSlice.actions.setPrimaryColor(state.hex));
}

export const changeShowHeader = (state: number) : AppThunk => async (dispatch, getState) => {
    dispatch(widgetSettingsSlice.actions.setShowHeader(state?true:false));
}

export const changeMaxShops = (state: number) : AppThunk => async (dispatch, getState) => {
    dispatch(widgetSettingsSlice.actions.setMaxShops(state));
}
// --------------------------------------------------------------------------------

export const updateSettings = (widgetId: string, callback = null): AppThunk => async (dispatch, getState) => {
    const widgetSettings      = widgetSettingsSelector(getState());
    const widgetSettingsState = widgetSettingsStateSelector(getState());

    const widgetsSettingsUpdate = {
        colors:{
            primary: widgetSettingsState.colors.primary?widgetSettings.colors.primary:null
        },
        max_shops: widgetSettingsState.max_shops?widgetSettings.max_shops:null,
        show_header: widgetSettingsState.show_header?widgetSettings.show_header:null
    }

    API.updateProductWidgetSettings(widgetId,{},widgetsSettingsUpdate)
    .then(response => {   
        if(callback){
            callback();
        }
    });

}

export const widgetSettingsSelector = (state: ApplicationState) => state.widgetSettings.settings;
export const widgetSettingsStateSelector = (state: ApplicationState) => state.widgetSettings.settingsState;
export const widgetNameSelector = (state: ApplicationState) =>  state.widgetSettings.widgetName;
export const widgetTypeSelector = (state: ApplicationState) =>  state.widgetSettings.widgetType;
export const widgetAffiliateIdSelector = (state: ApplicationState) => state.widgetSettings.widgetAffiliateId;

export default widgetSettingsSlice.reducer;



