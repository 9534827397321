import {AppThunk} from "../../store";
import API from "../../services/API";
import {env} from "../../utilities";


export const inviteUser =
  (values:
     {
       name: string,
       surname: string,
       email: string,
       role: string
     })
    :
    AppThunk => async (dispatch, getState) => {
    const appSpaceId = env<string>('REACT_APP_API_APP_SPACE')
    try {
      const response = await API.inviteUser({appSpaceId: appSpaceId}, values)
      if (response.success) {
          alert("Používateľ úspešne vytvorený")
      }
    } catch (e) {
      console.error(e.details.description)
      alert("Vyskytla sa chyba pri pridávaní používateľa: \n" + e.message)
    }


  }

