import React from 'react';
import {
    Modal, Table 
  } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { maskSettingsModalSelector, setModalVisibility, productNamePartsSelector, setProductNamePart } from './slice';

const MaskSettingsModal = (props) => {

    const dispatch = useDispatch();
    
    const maskSettingsModal = useSelector((state: ApplicationState) =>
        maskSettingsModalSelector(state)
    );

    const productNameParts = useSelector((state: ApplicationState) =>
        productNamePartsSelector(state)
    );
    
    const handleClose = () => {
        dispatch(setModalVisibility("maskSettings",false));
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          const selectedMask = productNameParts[maskSettingsModal.inputName].masks.find( mask => mask.key === selectedRowKeys[0]);
          dispatch(setProductNamePart(maskSettingsModal.inputName,{selectedMaskRegex:selectedRowKeys[0],selectedMaskHumanRegex:selectedMask.humanRegex}));
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        }
    };

    return <Modal visible = {maskSettingsModal.isVisible} 
                  onCancel={handleClose}
                  onOk={handleClose}
           > 
                
                <Table
                    rowSelection={{
                        type: 'radio',
                        selectedRowKeys:productNameParts[maskSettingsModal.inputName]?[productNameParts[maskSettingsModal.inputName].selectedMaskRegex]:[],
                        ...rowSelection
                    }}
                    columns={
                        [
                            {   
                                title: 'Maska',
                                dataIndex: 'humanRegex'
                            }
                        ]
                    }
                    pagination = {false}
                    dataSource = {productNameParts[maskSettingsModal.inputName]?productNameParts[maskSettingsModal.inputName].masks:[]}
                    scroll={{ y: 600 }}
                    />
           </Modal>
}

export default MaskSettingsModal;