import { Button, Dropdown, Input, Menu, Modal, PageHeader, Space, Table } from 'antd';
import React, { useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ConnectedSingleSelect from '../../components/Select/ConnectedSingleSelect';
import { ApplicationState } from '../../store';
import { __ } from '../../utilities';
import { approveProducts, loadGroupCategories, loadMainTable, loadShopFilterData, mainTableDataSelector, mainTableIsFetchingSelector, mainTableOptionalParamsSelector, modalsCategoriesIsVisible, pmCategoriesGroupDataSelector, pmCategoriesGroupIsFetchingSelector, removedKeysSelector, removeProducts, setCurrentSelectedRows, setFilter, setModalVisible, setProductPreviewModalData, shopActiveFiltersSelector, shopFiltersDataIsFetching, shopFiltersDataSelector, uniqueWordsSelector, updateAllLower, updateAllUpper, updateDisabledUniqueWords, updateFirstUpper, updateMainTable, updateUniqueWord } from './slice';
import UniqueEditableTable from './UniqueEditableTable';
import {
    LinkOutlined,
    SearchOutlined
  } from '@ant-design/icons';
import ProductPreviewModal from './ProductPreviewModal';
import ChangeCategoryModal from './ChangeCategoryModal';
import ChangeManufacturerModal from './ChangeManufacturerModal';


const ModalContent = styled.div`
  display:flex; 
  flex-direction: column;
`

const PmCategoryShowProductsButton = styled(Button)`
  margin-top: 25px;
`

const MainContent = styled.div`
  margin-top: 25px;
  padding: 25px;
  background: #ffffff;
  display: flex;
`

const MainContentMainTableWrapper = styled.div`
  flex-grow: 1;
  margin-right: 25px;
`

const MainContentWordsTableWrapper = styled.div`
  width: 400px;
`

const ProductImage = styled.img`
  max-width:50px;
  max-height: 50px;
`

const RowActionButtons = styled.div`
  display: flex;
`

const CustomLinkOutlined = styled(LinkOutlined)`
  margin-left: 10px;
  cursor: pointer;
`

const EmptyDiv:any = styled.div`
  position: fixed;
  top: ${(props:any) => props.top + "px"} ;
  left: ${(props:any) => props.left + "px" };
`

const UniqueProducts = () => {

    const dispatch = useDispatch();
    const [selectedCategoryId,setSelectedCategoryId] = useState(null);
    const [selectedProductKeys,setSelectedProductKeys] = useState([]);

    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [dropDownTop, setDropdownTop]   = useState(0);
    const [dropDownLeft, setDropdownLeft] = useState(0);
    const [dropdowClickRecord, setDropdownClickRecord] = useState(null);

    const [searchInput,setSearchInput] = useState(null);

    React.useEffect(() => {
        async function init() {
          //dispatch(loadMainTable());
        }
        init();
    }, [dispatch]);
    
    // shop
    const isFetchingShopData = useSelector((state: ApplicationState) =>
      shopFiltersDataIsFetching(state)
    );

    const shopActiveFilters = useSelector((state: ApplicationState) =>
      shopActiveFiltersSelector(state)
    );

    const shopData  = useSelector((state: ApplicationState) =>
      shopFiltersDataSelector(state)
    );

    const onChangeShopFilter = (value) => {  
      dispatch(loadShopFilterData(value));
    }

    const onSelectShopFilter = (value) => {
      dispatch(setFilter('shopSelected', value));
    }

    // category modal
    const onCategoryButtonClick = (value) => {
      dispatch(loadGroupCategories());
      dispatch(setModalVisible("categories",true));
    }

    const onCategoryModalClose = (value) => {
      dispatch(setModalVisible("categories",false));
    }

    const isCategoryModalVisible = useSelector((state: ApplicationState) => 
        modalsCategoriesIsVisible(state)
    )

    // pm categories
    const isPmCategoriesFetching = useSelector((state: ApplicationState) => 
        pmCategoriesGroupIsFetchingSelector(state)
    )

    const pmCategoriesData = useSelector((state: ApplicationState) => 
        pmCategoriesGroupDataSelector(state)
    )
    
    const onPmCategoriesShowProductsClick = (value) => {
        dispatch(setFilter('categorySelected', selectedCategoryId));
        dispatch(setModalVisible("categories",false));
        dispatch(loadMainTable());
    }

    // main table
    const isMainTableFetching = useSelector((state: ApplicationState) => 
        mainTableIsFetchingSelector(state)
    )

    const optionalParams = useSelector((state: ApplicationState) => 
        mainTableOptionalParamsSelector(state)
    )

    const mainTableData = useSelector((state:ApplicationState) => 
        mainTableDataSelector(state)
    )

    // unique words
    const uniqueWords = useSelector((state:ApplicationState) => 
        uniqueWordsSelector(state)
    )

    // unique words
    const removedKeys = useSelector((state:ApplicationState) => 
        removedKeysSelector(state)
    )

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
     }
 
     const handleReset = (clearFilters) => {
        clearFilters();
     }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
              <Input
                ref={node => {
                  setSearchInput(node);
                }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
              </Space>
            </div>
          ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput?searchInput.select():null, 100);
            }
        },
    });

    const onRow = (record,index) => ({  
        onContextMenu: event => {  
          event.preventDefault();
          setDropdownTop(event.clientY);
          setDropdownLeft(event.clientX);
          setDropdownVisible(true);
          setDropdownClickRecord(record);
          console.log("ON ROW CLICK");
          return true;
        }
    });

    const onDropdownMenuClick = (item) => {
        let currentSelectedRows = mainTableData.filter(item => selectedProductKeys.includes(item.key));
        if(!currentSelectedRows.length){
            currentSelectedRows = [dropdowClickRecord];
        }

        if(item.key === 'approve_products'){
            dispatch(approveProducts(currentSelectedRows));
        }
        else if(item.key === 'change_manufacturer'){
            dispatch(setCurrentSelectedRows(currentSelectedRows));
            dispatch(setModalVisible('changeManufacturer',true));
        }
        else if(item.key === 'change_category'){
            dispatch(setCurrentSelectedRows(currentSelectedRows));
            dispatch(setModalVisible('changeCategory',true));
        }
        else if(item.key === 'first_upper'){
            dispatch(updateFirstUpper(currentSelectedRows));
        }
        else if(item.key === 'all_upper'){
            dispatch(updateAllUpper(currentSelectedRows));
        }
        else if(item.key === 'all_lower'){
            dispatch(updateAllLower(currentSelectedRows));
        }
        else if(item.key === 'remove_product'){
            dispatch(removeProducts(currentSelectedRows));
        }
        else if(item.key === 'reject_product'){
            //dispatch(rejectProducts(currentSelectedRows));
        }

        return true;
      }

    const dropDownmenu = (
        <Menu onClick={ (item) => { onDropdownMenuClick(item) }}>
          <Menu.Item key="approve_products">Schváliť produkty</Menu.Item>
          <Menu.Item key="change_manufacturer">Zmena výrobcu</Menu.Item>
          <Menu.Item key="change_category">Zmena kategórie</Menu.Item>
          <Menu.Item key="first_upper">Prvé veľké písmeno</Menu.Item>
          <Menu.Item key="all_upper">Všetky veľké</Menu.Item>
          <Menu.Item key="all_lower">Všetky malé</Menu.Item>
          <Menu.Item key="remove_product">Odstrániť</Menu.Item>
          {/*<Menu.Item key="reject_product">Zamietnuť</Menu.Item>*/}
        </Menu>
    );

    return <>
        
        <PageHeader 
            title = {"Unikáty"}
            ghost={false}
            extra={[
                <ConnectedSingleSelect
                    name={__('Shop')}
                    isFetching={isFetchingShopData}
                    value={shopActiveFilters}
                    data={shopData}
                    selectWidth={200}
                    onChange={onChangeShopFilter}
                    onSelect={onSelectShopFilter}
                />,
                <Button onClick={onCategoryButtonClick} disabled={shopActiveFilters?false:true}> 
                    Vybrať kategóriu 
                </Button>
            ]}
        />
        

        <MainContent>
            <MainContentMainTableWrapper>
                <UniqueEditableTable 
                   loading = {isMainTableFetching}
                   onRow={onRow}
                   onUpdate = {(data,dataIndex) => {
                        dispatch(updateMainTable(data.key,data,dataIndex));
                   }}
                   columns = {[
                    {
                        title: "",
                        dataIndex: 'picture',
                        render: (value) =>{
                            return <ProductImage src={value}/>
                        }
                    },
                    {
                        title: "Názov produktu v obchode",
                        dataIndex: 'shop_product_name',
                        sorter: (a, b) => a.shop_product_name.localeCompare(b.shop_product_name),
                        onFilter: (value, record) => record.shop_product_name.includes(value),
                        ...getColumnSearchProps('shop_product_name')
                    },
                    {
                        title: "Výrobca",
                        dataIndex: 'manufacturer_param',
                        sorter: (a, b) => a.manufacturer_param.localeCompare(b.manufacturer_param),
                        ...getColumnSearchProps('manufacturer_param')
                    },
                    {
                        title: "Prefix",
                        dataIndex: 'model_name_param_prefix',
                        editable: true,
                        inputType: 'text',
                        sorter: (a, b) => a.model_name_param_prefix.localeCompare(b.model_name_param_prefix),
                        ...getColumnSearchProps('model_name_param_prefix')
                    },
                    {
                        title: "Modelový názov",
                        dataIndex: 'model_name_param',
                        editable: true,
                        inputType: 'text',
                        sorter: (a, b) => a.model_name_param.localeCompare(b.model_name_param),
                        ...getColumnSearchProps('model_name_param')
                    },
                    {
                        title: "Suffix",
                        dataIndex: 'model_name_param_suffix',
                        editable: true,
                        inputType: 'text',
                        sorter: (a, b) => a.model_name_param_suffix.localeCompare(b.model_name_param_suffix),
                        ...getColumnSearchProps('model_name_param_suffix')
                    },
                    ...(optionalParams && optionalParams.length)?optionalParams.map(optionalParam => {
                        
                        return {
                            title: optionalParam.paramNameValue,
                            dataIndex: optionalParam.paramName,
                            editable: true,
                            inputType: 'text',
                            sorter: (a, b) => a[optionalParam.paramName].localeCompare(b[optionalParam.paramName]),
                            //...getColumnSearchProps(optionalParam.paramName)
                        }
                    }):[],
                    {
                        title: "Akcie",
                        render: (value,record) =>{
                            return  <RowActionButtons>
                                        
                                        <SearchOutlined 
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            dispatch(setProductPreviewModalData(record.key));
                                            dispatch(setModalVisible("productPreview", true));
                                        }}
                                        />
                                        
                                        <CustomLinkOutlined
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            window.open(record.url, "_blank");
                                        }}
                                        />
                                    </RowActionButtons>
                        }
                    }
                   ]}
                    dataSource= {mainTableData.filter(o => !removedKeys.includes(o.key))}
                    pagination = {false}
                    rowSelection = {{
                        selectedRowKeys: selectedProductKeys,
                        onChange: (selectedRowKeys, selectedRows) => {
                            setSelectedProductKeys(selectedRowKeys)
                        }
                    }}
                    ebabledAddRowButton = {false}
                />
            </MainContentMainTableWrapper>
            <MainContentWordsTableWrapper>
                <UniqueEditableTable
                    loading = {isMainTableFetching}
                    onUpdate = {(data) => {
                        dispatch(updateUniqueWord(data.key,data.replace));
                    }}
                    columns = {[
                        {
                            title: "Slovo",
                            dataIndex: 'word',
                            sorter: (a, b) => a.word.localeCompare(b.word),
                            inputType: 'text',
                            ...getColumnSearchProps('word')
                        },
                        {
                            title: "Nahradit",
                            dataIndex: 'replace',
                            sorter: (a, b) => a.replace.localeCompare(b.replace),
                            editable: true,
                            inputType: 'text',
                            ...getColumnSearchProps('replace')
                        },
                        {
                            title: "Výskyt",
                            dataIndex: 'count',
                            sorter: (a, b) => a.count-b.count,
                        }
                    ]}
                    dataSource={uniqueWords?uniqueWords:[]}
                    pagination = {false}
                    rowSelection = {{
                        selectedRowKeys:uniqueWords.filter(uniqueWord => uniqueWord.disabled).map(uniqueWord => uniqueWord.key),
                        onChange: (selectedRowKeys, selectedRows) => {
                            dispatch(updateDisabledUniqueWords(selectedRowKeys));
                        }
                    }}
                    ebabledAddRowButton = {false}
                />
            </MainContentWordsTableWrapper>
        </MainContent>
            
        {isCategoryModalVisible?
                <Modal
                    visible={isCategoryModalVisible}
                    onCancel={onCategoryModalClose}
                    width = {1000}
                    footer={[]}
                >
                    <ModalContent>
                        <Table 
                            loading = {isPmCategoriesFetching}
                            style={{width:"100%"}}
                            columns = {[
                                {
                                    title: ('Por.'),
                                    dataIndex: 'order',
                                    sorter: (a, b) => a.order-b.order
                                },
                                {
                                    title: ('Nazov kategórie'),
                                    dataIndex: 'category_name',
                                    sorter: (a, b) => a.category_name.localeCompare(b.category_name),
                                },
                                {
                                    title: ('Počet'),
                                    dataIndex: 'count',
                                    sorter: (a, b) => a.count - b.count
                                }
                            ]}
                            dataSource={pmCategoriesData}
                            pagination = {false}
                            rowSelection = {{
                                type:'radio',
                                onChange: (selectedRowKeys, selectedRows) => {
                                    setSelectedCategoryId(selectedRowKeys[0]);
                                }
                            }}
                        />
                        
                        {!isPmCategoriesFetching?
                            <PmCategoryShowProductsButton
                               disabled = {selectedCategoryId?false:true}
                               onClick = {onPmCategoriesShowProductsClick}
                            >
                                Zobraziť produkty
                            </PmCategoryShowProductsButton>
                        :null
                        }

                    </ModalContent> 
                </Modal>
           :null
        }

        <ProductPreviewModal/>
        <ChangeCategoryModal
           selectedProductKeys = {selectedProductKeys}
        />
        <ChangeManufacturerModal
           selectedProductKeys = {selectedProductKeys}
        />

        <Dropdown overlay={dropDownmenu} 
                    visible={isDropdownVisible}
                    onVisibleChange={ (visible) => { setDropdownVisible(visible) } }
          >
            <EmptyDiv top={dropDownTop} left={dropDownLeft} />
        </Dropdown>

    </>
}

export default UniqueProducts;