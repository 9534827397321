import React, { useState } from 'react';
import { Button, Checkbox, Col, Input, Row, Select, Spin, Table, Tooltip, Dropdown, Menu } from 'antd';

import { CloseCircleFilled } from '@ant-design/icons';
import { __ } from '../../utilities';
import { useDispatch, useSelector } from 'react-redux';
import {
    filterResultsIsFetchingSelector,
    filterResultsItemsSelector,
    filterResultsTotalSelector,
    loadItems,
    loadShopFilterData,
    mappingActionsSelector,
    paginationSelector,
    setFilter,
    setPagination,
    setSendingMappingAction,
    setSorter,
    shopActiveFiltersSelector,
    shopFiltersDataIsFetching,
    shopFiltersDataSelector,
    updateRecord,
    mappingActionsisSendingSelector, addMappingAction, loadManufacturerProducts, setManufacturerProductsIsVisible
} from './slice';
import { rem } from 'polished';
import { ApplicationState } from '../../store';
import H1 from '../../components/Headings/H1';
import MappingManufacturerSelect from "./MappingManufacturerSelect";
import API from "../../services/API";
import styled from 'styled-components';
import ManufacturerProductsModal from './ManufacturerProductsModal';

const { Option } = Select;
// const {Text, Title} = Typography;
// const dateFormat = FORMAT_HUMANE_DATE;

const selectWidth = rem(175);

const EmptyDiv: any = styled.div`
  position: absolute;
  top: ${(props: any) => props.top + "px"} ;
  left: ${(props: any) => props.left + "px"};
`

const MappingManufacturers = () => {
    const [newManufacturers, setNewManufacturers] = useState({});
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
    const [contextClickedKey, setContextClickedKey] = useState(null);

    function onCancelAllFilters() {
        dispatch(setFilter('categorySelected', null));
        dispatch(setFilter('manufacturerSelected', null));
        dispatch(setFilter('since', null));
        dispatch(setFilter('manufacturerSelected', null));
        dispatch(setFilter('query', null));
        dispatch(setFilter('onlyNotMapped', false));
        dispatch(loadItems());
    }

    const dispatch = useDispatch();

    // Nahrada componentDidMount
    React.useEffect(() => {
        async function init() {
            // TODO IMPLEMENT
            dispatch(loadItems());
        }

        init();
    }, [dispatch]);

    /*
    const onSelectManufacturerFilter = async value => {
        dispatch(setFilter('manufacturerSelected', value));
        dispatch(loadItems());
    };
    */

    /*
    const onSelectCategoryFilter = async value => {
        dispatch(setFilter('categorySelected', value));
        dispatch(loadItems());
    };
    */

    /*
    const onChangeSinceFilter = async value => {
        dispatch(setFilter('since', value));
        dispatch(loadItems());
    };
    */

    // const onChangeChangeDateFilter = async value => {
    //   dispatch(setFilter('changeDate', value));
    //   dispatch(loadProducts());
    // };

    const onSearchQuery = async value => {
        dispatch(setFilter('query', value));
        dispatch(loadItems());
    };

    const onChangeTable = async (pagination, filters, sorters) => {
        dispatch(setPagination(pagination.current));
        dispatch(setSorter(sorters.field, sorters.order));
        dispatch(loadItems());
    };

    const items = useSelector((state: ApplicationState) =>
        filterResultsItemsSelector(state)
    );

    const pagination = useSelector((state: ApplicationState) =>
        paginationSelector(state)
    );

    const total = useSelector((state: ApplicationState) =>
        filterResultsTotalSelector(state)
    );

    const isFetchingProducts = useSelector((state: ApplicationState) =>
        filterResultsIsFetchingSelector(state)
    );

    const isSendingActions = useSelector((state: ApplicationState) =>
        mappingActionsisSendingSelector(state)
    );

    const onChangeShopFilter = async value => {
        await dispatch(loadShopFilterData(value));
    };

    const isFetchingShopData = useSelector((state: ApplicationState) =>
        shopFiltersDataIsFetching(state)
    );

    const shopActiveFilters = useSelector((state: ApplicationState) =>
        shopActiveFiltersSelector(state)
    );

    const shopData = useSelector((state: ApplicationState) =>
        shopFiltersDataSelector(state)
    );

    const onClickAddManufacturer = async (shop_manufacturer, shop_id, manufacturer, manufacturer_mapping_id) => {
        const newManufacturerName = window.prompt(__('Zadajte názov výrobcu'),shop_manufacturer);
        // spravime noveho vyrobcu a rovno aj mapovanie
        const response = await API.createNewManufacturer({}, { name: newManufacturerName });

        newManufacturers[shop_manufacturer] = {
            key: response.manufacturer_id,
            value: response.manufacturer_id,
            label: newManufacturerName
        };

        setNewManufacturers(newManufacturers);

        dispatch(addMappingAction({
            shop_manufacturer: shop_manufacturer,
            shop_id: shop_id,
            manufacturer_id: response.manufacturer_id,
            type: manufacturer ? 'UPDATE' : 'INSERT',
            manufacturer_mapping_id: manufacturer_mapping_id
        }));
    };

    const onSelectShopFilter = async value => {
        if (value) {
            dispatch(setFilter('shopSelected', [value]));
        } else {
            dispatch(setFilter('shopSelected', value));
        }
        dispatch(loadItems());
    };

    const columns = [
        {
            title: __('Názov výrobcu v obchode'),
            dataIndex: 'shop_manufacturer',
        },
        {
            title: __('Názov výrobcu v Pricemanii'),
            dataIndex: 'manufacturer',
            render: (manufacturer, obj) => (
                <MappingManufacturerSelect shop_manufacturer={obj.shop_manufacturer}
                    manufacturer={manufacturer}
                    manufacturer_mapping_id={obj.manufacturer_mapping_id}
                    shop_id={obj.shop_id}
                    newManufacturer={newManufacturers[obj.shop_manufacturer]}>
                </MappingManufacturerSelect>
            ),
        },
        {
            title: __('Vytvoriť'),
            dataIndex: 'shop_manufacturer',
            render: (shop_manufacturer, obj) => (
                <Button
                    onClick={e => { onClickAddManufacturer(shop_manufacturer, obj.shop_id, obj.manufacturer, obj.manufacturer_mapping_id) }}
                    type="primary"
                    size={'small'}
                >{__('Vytvoriť')}</Button>
            ),
        },
        {
            title: __('Kopírovať'),
            dataIndex: 'name',
            render: name => (
                <Button
                    type="primary"
                    size={'small'}
                >{__('Kopírovať')}</Button>
            ),
        }
    ];

    const mappingActions = useSelector((state: ApplicationState) =>
        mappingActionsSelector(state)
    );

    const onClickSave = async e => {
        dispatch(setSendingMappingAction(true));

        // prejdeme akcie a vykoname ich
        for (let mappingAction of mappingActions) {
            switch (mappingAction.type) {
                case 'INSERT':
                    const response = await API.createManufacturerMapping({}, { manufacturer_id: mappingAction.manufacturer_id, shop_id: mappingAction.shop_id, shop_manufacturer: mappingAction.shop_manufacturer });
                    const new_data = {
                        manufacturer_mapping_id: response.manufacturer_mapping_id,
                        manufacturer: {
                            manufacturer_id: mappingAction.manufacturer_id
                        }
                    };
                    dispatch(updateRecord(mappingAction.shop_manufacturer, new_data));
                    break;
                case 'UPDATE':
                    if (mappingAction.manufacturer_mapping_id) {
                        await API.updateManufacturerMapping(mappingAction.manufacturer_mapping_id, {}, { manufacturer_id: mappingAction.manufacturer_id, shop_id: mappingAction.shop_id, shop_manufacturer: mappingAction.shop_manufacturer });
                    }
                    break;
                case 'DELETE':
                    if (mappingAction.manufacturer_mapping_id) {
                        await API.deleteManufacturerMapping(mappingAction.manufacturer_mapping_id, {});
                        const new_data = {
                            manufacturer_mapping_id: null,
                            manufacturer: null
                        };
                        dispatch(updateRecord(mappingAction.shop_manufacturer, new_data));
                    }
                    break;
            }
        }

        dispatch(setSendingMappingAction(false));
    };

    const onChangeOnlyNotMapped = value => {
        dispatch(setFilter('onlyNotMapped', value.target.checked));
        dispatch(loadItems());
    };

    const onRow = (record, index) => ({
        onContextMenu: event => {
            event.preventDefault();
            setDropdownVisible(true);
            setDropdownPosition({ top: event.clientY + window.pageYOffset, left: event.clientX + window.pageXOffset });
            setContextClickedKey(record.key);
            
            console.log("RECORD");
            console.log(record);

            return true;
        },
    });

    let dropdownMenu = (
        <Menu onClick={(item) => { onDropdownMenuClick(item) }}>
            <Menu.Item key="shop_manufacturer_products"> Zobraziť produkty </Menu.Item>
        </Menu>
    )

    const onDropdownMenuClick = async (item) => { 
        if (item.key === 'shop_manufacturer_products') {
            dispatch(loadManufacturerProducts(contextClickedKey));
            dispatch(setManufacturerProductsIsVisible(true));
        }
    }

    return (
        <>
            <H1>{__('Mapovanie výrobcov')}</H1>
            <Row>
                <Col className="gutter-row" span={24}>
                    <Row gutter={{ lg: 10 }}>
                        <Col>
                            <Select
                                showSearch
                                value={shopActiveFilters}
                                labelInValue
                                placeholder={__('Shop')}
                                notFoundContent={isFetchingShopData ? <Spin size="small" /> : null}
                                filterOption={false}
                                onChange={onSelectShopFilter}
                                onSearch={onChangeShopFilter}
                                style={{ width: 200 }}
                            >
                                {shopData.map(d => (
                                    <Option value={d.value} key={d.name}>
                                        {d.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col>
                            <Input.Search
                                style={{
                                    width: selectWidth + rem(50),
                                }}
                                placeholder={__('Vyhľadať')}
                                onSearch={onSearchQuery}
                                enterButton
                            />
                        </Col>
                        <Col>
                            <Checkbox onChange={onChangeOnlyNotMapped}>Iba nenamapované</Checkbox>
                        </Col>
                        <Col>
                            <Tooltip title={__('Zrušiť filtre')}>
                                <CloseCircleFilled
                                    onClick={() => {
                                        onCancelAllFilters();
                                    }}
                                    style={{ fontSize: '32px' }}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                </Col>

                <Col className="gutter-row" span={24}>
                    &nbsp;
                    {/*<Title level={4}>*/}
                    {/*  {__('Počet záznamov: ')}*/}
                    {/*  {total < pagination.pageSize ? total : pagination.pageSize} z{' '}*/}
                    {/*  {total}*/}
                    {/*</Title>*/}
                </Col>

                <Col className="gutter-row" span={24}>
                    {/* TODO Doplnit ROW SELECTION cez REDUX */}
                    <Table
                        columns={columns}
                        pagination={{
                            total: total,
                            current: pagination.current,
                            pageSize: pagination.pageSize,
                        }}
                        loading={isFetchingProducts || isSendingActions}
                        dataSource={items}
                        onChange={(pagination, filters, sorters) => {
                            onChangeTable(pagination, filters, sorters);
                        }}
                        onRow={onRow}
                    />
                </Col>

                <Col className="gutter-row" span={24}>
                    <div style={{ float: 'right' }}>
                        <Button type="primary" onClick={onClickSave}>
                            {__('Uložiť')}
                        </Button>
                    </div>
                </Col>
            </Row>
            <Dropdown overlay={dropdownMenu}
                    visible={isDropdownVisible}
                    onVisibleChange={(visible) => { setDropdownVisible(visible) }}
                >
                <EmptyDiv top={dropdownPosition.top} left={dropdownPosition.left} />     
            </Dropdown>
            <ManufacturerProductsModal /> 
        </>
    );
};

export default MappingManufacturers;