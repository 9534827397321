import { Button, Modal, PageHeader, Radio, Tree } from "antd";
import { EventDataNode } from "antd/lib/tree";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import CategoryParameters from "../../components/CategoryParameters/CategoryParameters";
import { updateParameters } from "../../components/CategoryParameters/slice";
import { ApplicationState } from "../../store";
import { __ } from "../../utilities";
import { categoryTreeSelector, loadChildrenCategories, loadTopCategories, localeIdSelector, setLocaleId } from "./slice";

const MainContent = styled.div`
  margin-top: 25px;
  padding: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`

const CategoryTree = () => {
    const dispatch = useDispatch();
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    React.useEffect(() => {
        async function init() {
          await dispatch(loadTopCategories()) 
        }
        init();
    }, [dispatch]);

    const categoryTree = useSelector((state: ApplicationState) =>
        categoryTreeSelector(state)
    );

    const onLoadTreeData = async (treeNode:EventDataNode) => {
        await dispatch(loadChildrenCategories(treeNode.key));
    }

    const localeId = useSelector((state: ApplicationState) =>
        localeIdSelector(state)
    );

    const onLocaleClick = (e) => {
        dispatch(setLocaleId(e.target.value))
    }

    const onSelectLeaf = (selectedKeys) => {
        if(selectedKeys && selectedKeys.length){
            const selectedKey = selectedKeys[0];
            setSelectedCategoryId(selectedKey);
        }
    }

    const onCancelParametersModal = () => {
        setSelectedCategoryId(null);
    }

    const onSaveParameters = () => {
        dispatch(updateParameters())
    }
    
    return <> 
        <PageHeader 
            title = {"Strom kategórií"}
            ghost={false}
            extra={[
                <Radio.Group
                    key="selectLocale"
                    value={localeId}
                    buttonStyle="solid"
                    style={{ width: '100%' }}
                    onChange={onLocaleClick}
                >
                    <Radio.Button value={1}>SK</Radio.Button>
                    <Radio.Button value={2}>CZ</Radio.Button>
                </Radio.Group>
            ]}
        /> 
        
        <MainContent>
            <Tree onSelect={onSelectLeaf}  loadData={onLoadTreeData} treeData={categoryTree} />
        </MainContent>

        <Modal
            visible={selectedCategoryId?true:false}
            onCancel={onCancelParametersModal}
            footer={[
                <Button key="submit" type="primary" onClick={onSaveParameters}>
                  {__('Uložiť')}
                </Button>,
              ]}
            width = {1000}
        >
           <CategoryParameters categoryId={selectedCategoryId} defaultLocaleId={localeId} />
        </Modal>
    </>
}

export default CategoryTree