import { PageHeader, Table } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { __ } from '../../utilities';
import {
  loadManufacturersList,
  paginationSelector,
  totalSelector,
  setPagination,
  setSorter,
  manufacturersListIsFethingSelector,
  manufacturersListSelector,
  manufaturersFiltersDataIsFetching,
  manufaturersFiltersActiveFiltersSelector,
  loadManufaturersFilterData,
  manufaturersFiltersData,
  setFilter,
} from './slice';
import { ApplicationState } from '../../store';
import { Link } from 'react-router-dom';
import ConnectedMultiSelect from '../../components/Select/ConnectedMultiSelect';
import { FormOutlined } from '@ant-design/icons';

const MainContent = styled.div`
  margin-top: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;

const ManufacturersList = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function init() {
      dispatch(loadManufacturersList());
    }
    init();
  }, [dispatch]);

  const pagination = useSelector((state: ApplicationState) =>
    paginationSelector(state)
  );
  const manufacturers = useSelector((state: ApplicationState) =>
    manufacturersListSelector(state)
  );
  const isFetching = useSelector((state: ApplicationState) =>
    manufacturersListIsFethingSelector(state)
  );
  const total = useSelector((state: ApplicationState) => totalSelector(state));

  const onChangeTable = async (pagination, filters, sorters) => {
    dispatch(setPagination(pagination.current, pagination.pageSize));
    dispatch(setSorter(sorters.field, sorters.order));
    dispatch(loadManufacturersList());
  };
  const isFetchingManufacturersFiltersData = useSelector(
    (state: ApplicationState) => manufaturersFiltersDataIsFetching(state)
  );

  const manufacturersActiveFilters = useSelector((state: ApplicationState) =>
    manufaturersFiltersActiveFiltersSelector(state)
  );

  const manufacturersFilterData = useSelector((state: ApplicationState) =>
    manufaturersFiltersData(state)
  );
  const onSelectManufacturersFilter = async value => {
    dispatch(setFilter('manufacturers', value));
    dispatch(loadManufacturersList());
  };

  const onChangeManufacturersFilter = async value => {
    await dispatch(loadManufaturersFilterData(value));
  };

  return (
    <>
      <PageHeader
        title={'Výrobcovia'}
        ghost={false}
        extra={[
          <ConnectedMultiSelect
            key={'trerers'}
            name={__('Vyhľadavanie')}
            isFetching={isFetchingManufacturersFiltersData}
            value={manufacturersActiveFilters}
            data={manufacturersFilterData}
            selectWidth={200}
            onChange={onChangeManufacturersFilter}
            onSelect={onSelectManufacturersFilter}
          />,
        ]}
      />
      <MainContent>
        <Table
          loading={isFetching}
          columns={[
            {
              title: __('Nazov'),
              dataIndex: 'name',
              sorter: false,
              render: (name, data) => {
                return (
                  <Link
                    className="ant-dropdown-link"
                    to={`/manufacturers-list/${data.id}`}
                  >
                    {data.name}
                  </Link>
                );
              },
            },
            {
              title: __('Detail'),
              dataIndex: '',
              render: (name, data) => {
                return (
                  <Link
                    className="ant-dropdown-link"
                    to={`/manufacturers-list/${data.id}`}
                  >
                    <FormOutlined />
                  </Link>
                );
              },
            },
          ]}
          dataSource={manufacturers}
          pagination={{
            total: total,
            current: pagination.current,
            pageSize: pagination.pageSize,
          }}
          onChange={(pagination, filters, sorters) => {
            onChangeTable(pagination, filters, sorters);
          }}
        />
      </MainContent>
    </>
  );
};

export default ManufacturersList;
