import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button, PageHeader, Card, message, Select } from 'antd';
import { __ } from '../../utilities';
import { useDispatch, useSelector } from 'react-redux';
import {
  productFiltersDataIsFetching,
  productFiltersDataSelector,
  productActiveFiltersSelector,
  loadProductFilterData,
  setFilter,
  generateProductWidget,
  htmlCodesSelector,
  widgetTypeActiveFilterSelector,
  resetState,
  loadUserAffil,
  affilNameSelector,
  actualWidgetIdSelector,
  generateModalProductWidget,
  generateButtonProductWidget,
  createFromLink,
  loadAffilSettings,
  changeCountryIdAction
} from './slice';
import { ApplicationState } from '../../store';
import ConnectedSingleSelect from '../../components/Select/ConnectedSingleSelect';
import { Input } from 'antd';
import WidgetSettings from '../../components/WidgetSettings/WidgetSettings';
import WidgetTagsSettings from '../../components/WidgetTagsSettings/WidgetTagsSettings';
import {productSelector} from "../Top50/slice";
import WidgetTypeSelector from "./WidgetTypeSelector";
import { countryIdSelector } from '../WebWidget/slice';

const MainBox = styled.div`
  margin-bottom: 30px;
`
/*
const MainBoxTitle = styled.h2`
  font-size: 30px;
`
*/

const ChooseProductWrapper = styled.div`
  display: flex;
`

const ChooseProductWrapperLeft = styled.div`
    flex: 0 1 50%;
    min-height: 100%;
    max-width: 50%;
    margin-right: 10px;
`

const ChooseProductWrapperRight = styled.div`
    flex: 0 1 50%;
    display: flex;
    height: 100%;
    max-width: 50%;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: 10px;
`

const MainBoxContent = styled.div`
`
/*
const MainText = styled.h1`
  margin-bottom: 2px;
  font-size: 23px;
`

const MainTextFor = styled.h3`
  
`
*/

const HtmlCodeWrapper = styled.div`
  margin-bottom: 20px;
`
/*
const HtmlCodeTitle = styled.h2`
  
`
*/

const HtmlCode = styled(Input.TextArea)`

`

const CreateNewButtonWrapper = styled.div`
    text-align: center
`


const PreviewWrapper = styled.div`
    width: 300px;
`

const CodeWrapper = styled.div`
    background: #f1f1f1;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 3px;
`


const WidgetCreate = () => {
    const dispatch = useDispatch();
    const [showSettings,setShowSettings] = useState(false);
    const [showTutorial,setShowTutorial] = useState(false);

    const product = useSelector((state:ApplicationState) => productSelector(state))

    const widgetTypeSelected = useSelector((state: ApplicationState) =>
        widgetTypeActiveFilterSelector(state)
    ); 

    React.useEffect(() => {
        async function init() {
          await dispatch(loadAffilSettings());
        }
        init();
    }, [dispatch]);

    React.useEffect(() => {
        async function init() {
          await dispatch(loadUserAffil());
          if(product){
            dispatch(createFromLink(product,widgetTypeSelected))
          }
        }
        init();
    }, [dispatch,product,widgetTypeSelected]);

    const htmlCodes = useSelector((state: ApplicationState) =>
        htmlCodesSelector(state)
    );

    const actualWidgetId = useSelector((state: ApplicationState) =>
        actualWidgetIdSelector(state)
    );

    const updatePreview = useCallback(() => { 
        document.getElementById('preview-wrapper').innerHTML = `<div class="pma-widget" data-id='${actualWidgetId}'></div>`;

        const script = document.createElement('script');  
        script.src = `https://public.pricemania.sk/v1/widget/product/script.js?w_id=${actualWidgetId}&force_init=true`;
        document.getElementById('preview-wrapper').appendChild(script);
    }, [actualWidgetId])

    React.useEffect(() => {
       if(actualWidgetId && document.getElementById('preview-wrapper') && (widgetTypeSelected === 'products' || widgetTypeSelected ==='modal_products')){       
            updatePreview();
       } 
    },[actualWidgetId,widgetTypeSelected,updatePreview]);



    const isProductFetching = useSelector((state: ApplicationState) =>
        productFiltersDataIsFetching(state)
    );

    const productData = useSelector((state: ApplicationState) =>
        productFiltersDataSelector(state)
    );

    const productActiveFilters = useSelector((state: ApplicationState) =>
        productActiveFiltersSelector(state)
    );

    const handleCountryChange = (value) => {
        dispatch(changeCountryIdAction(value))
    }
    
    const handleProductChange = (value) => {
        dispatch(loadProductFilterData(value));
    }

    const handleProductSelect = (value) => {
        dispatch(setFilter('productSelected', value));
    }

    const handleGenerateProductClick = (value) => {
        dispatch(generateProductWidget());
    }

    const handleGenerateModalProductClick = (value) => {
        dispatch(generateModalProductWidget());
    }

    const handleGenerateButtonProductClick = (value) => {
        dispatch(generateButtonProductWidget());
    }

    const handleGenerateNewButtonClick = (value) => {
        dispatch(resetState());
    }

    const affilName = useSelector((state: ApplicationState) =>
        affilNameSelector(state)
    );

    const countryId = useSelector((state: ApplicationState) =>
        countryIdSelector(state)
    );

    const injectHtmlCode = () => {
        var elements = document.getElementsByClassName("xxx");
        Array.from(elements).forEach(function(element) {
            console.log(element.id);
        });
    }

    const updateCallback = () => {
        updatePreview();
        message.success("Widget bol aktualizovaný");
    }

    injectHtmlCode();
    
    return (
        <>
            <MainBox>
                <PageHeader
                    title={`Vytvorenie widgetu`}
                    ghost={false}
                    extra={[
                        <Select style={{ width: 200 }} placeholder="Krajina" defaultValue={countryId.toString()} onChange={handleCountryChange}>
                            <Select.Option value="1" key="1">SK</Select.Option>
                            <Select.Option value="2" key="2">CZ</Select.Option>
                        </Select>
                    ]}
                >
                  {affilName?"pre "+affilName:""}
                </PageHeader> 
            </MainBox>

            <MainBox>
                <Card title="Typ widgetu">
                <WidgetTypeSelector/>
                </Card>
            </MainBox>


            {widgetTypeSelected === 'products' ? (
                <MainBox>
                    <Card title="Zvoľte produkt">
                        <MainBoxContent>
                            <ChooseProductWrapper>
                                <ChooseProductWrapperLeft>
                                    <ConnectedSingleSelect
                                        name={__('Produkty')}
                                        isFetching={isProductFetching}
                                        value={productActiveFilters}
                                        data={productData}
                                        selectWidth="100%"
                                        onChange={handleProductChange}
                                        onSelect={handleProductSelect}
                                    />
                                </ChooseProductWrapperLeft>
                                <ChooseProductWrapperRight>
                                    <Button type="primary" onClick={handleGenerateProductClick} > Generovať </Button>
                                </ChooseProductWrapperRight>
                            </ChooseProductWrapper>
                        </MainBoxContent>
                    </Card>    
                </MainBox>
            ) : null}

            {widgetTypeSelected === 'modal_products' ? (
                <MainBox>
                    <Card title="Zvoľte produkt">
                        <MainBoxContent>
                            <ChooseProductWrapper>
                                <ChooseProductWrapperLeft>
                                    <ConnectedSingleSelect
                                        name={__('Produkty')}
                                        isFetching={isProductFetching}
                                        value={productActiveFilters}
                                        data={productData}
                                        selectWidth="100%"
                                        onChange={handleProductChange}
                                        onSelect={handleProductSelect}
                                    />
                                </ChooseProductWrapperLeft>
                                <ChooseProductWrapperRight>
                                    <Button type="primary" onClick={handleGenerateModalProductClick} > Generovať </Button>
                                </ChooseProductWrapperRight>
                            </ChooseProductWrapper>
                        </MainBoxContent>
                    </Card>    
                </MainBox>
            ): null}

            {widgetTypeSelected === 'button_products' ? (
                <MainBox>
                    <Card title="Zvoľte produkt">
                        <MainBoxContent>
                            <ChooseProductWrapper>
                                <ChooseProductWrapperLeft>
                                    <ConnectedSingleSelect
                                        name={__('Produkty')}
                                        isFetching={isProductFetching}
                                        value={productActiveFilters}
                                        data={productData}
                                        selectWidth="100%"
                                        onChange={handleProductChange}
                                        onSelect={handleProductSelect}
                                    />
                                </ChooseProductWrapperLeft>
                                <ChooseProductWrapperRight>
                                    <Button type="primary" onClick={handleGenerateButtonProductClick} > Generovať </Button>
                                </ChooseProductWrapperRight>
                            </ChooseProductWrapper>
                        </MainBoxContent>
                    </Card>    
                </MainBox>
            ): null}

            {htmlCodes.length ?
                (<>
                    <MainBox>
                        <Card title="Zdrojový kód" extra={<Button onClick={()=>{setShowTutorial(!showTutorial)}} > {showTutorial?"Skryť":"Zobraziť"} návod na implementáciu skriptu </Button>}>
                            <MainBoxContent>
                                {htmlCodes.map(htmlCode => {
                                    return (
                                        <>
                                            <HtmlCodeWrapper>
                                                <HtmlCode readOnly={true} value={htmlCode.code} />
                                            </HtmlCodeWrapper>
                                        </>
                                    )
                                })
                                }
                            </MainBoxContent>
                            {showTutorial?
                               <MainBoxContent>
                                    <h4>Návod na implementáciu skriptu</h4>
                                    {widgetTypeSelected === 'products'? 
                                        <p> Vygenerovaný skript vložte do zdrojového kódu stránky/článku na miesto, kde ho chcete zobrazovať. Žiadne ďalšie akcie nie sú potrebné, widget sa začne automaticky po uložení zobrazovať.</p>
                                    :null}
                                    
                                    {widgetTypeSelected === 'modal_products'? 
                                        <>
                                            <p> Vygenerovaný skript je potrebné zavolať niektorým elementom na vašom webe (typicky button, obrázok, URL), pričom implementácia môže vyzerať napríklad nasledovne:</p>

                                            <CodeWrapper>
                                                {`<script async src='https://public.pricemania.sk/v1/widget/modal_product/script.js?w_id=ID_widgetu'></script>`} <br/>
                                                {`<button class="pma-modal-widget-button" data-id='ID_widgetu'> Najlepšie ponuky </button>`}
                                            </CodeWrapper>

                                            <p> 
                                                Pri vkladaní widgetu je potrebné správne nastaviť class a data-ID. <br/>
                                                Class = "pma-modal-widget-button" <br/>
                                                data-ID = rovnaké ako je v skripte <br/>
                                            </p> 
                                        </> 
                                    :null}

                                    {widgetTypeSelected === 'button_products'? 
                                      <> 
                                           <p> Vygenerovaný skript je potrebné zavolať niektorým elementom na vašom webe (typicky button, obrázok, URL), pričom implementácia môže vyzerať napríklad nasledovne: </p>
                                           <CodeWrapper>
                                                {`<script async src='https://public.pricemania.sk/v1/widget/button_product/script.js?w_id=ID_widgetu'></script>`} <br/>
                                                {`<button class="pma-widget" data-id='ID_widgetu'> Najlepšia ponuka </button>`}
                                           </CodeWrapper>
                                           <p>
                                                Pri vkladaní widgetu je potrebné správne nastaviť class a data-ID. <br/>
                                                Class = "pma-widget" <br/>
                                                data-ID = rovnaké ako je v skripte <br/>
                                           </p> 
                                      </>
                                      :null 
                                    }

                                </MainBoxContent>
                            :null}
                        </Card>    
                    </MainBox>
                    
                    <MainBox>
                        <Card title="Nastavenie tagov">
                            <MainBoxContent>
                                <WidgetTagsSettings widgetId={actualWidgetId}/>
                            </MainBoxContent>
                        </Card>
                    </MainBox>
                    { widgetTypeSelected === 'products' || widgetTypeSelected ==='modal_products'?
                       <MainBox>
                            <Card title="Nastavenia" extra={
                                !showSettings? <Button onClick={()=>{setShowSettings(true)}} > Zobraziť </Button> 
                                            : <Button onClick={()=>{setShowSettings(false)}}> Skryť </Button> 
                            }
                            bodyStyle = {
                                !showSettings?{
                                    padding: 0
                                }:{}
                            } 
                            >   {showSettings?
                                    <MainBoxContent>
                                        <p>Zmeniť východzie hodnoty</p>
                                        <WidgetSettings widgetId={actualWidgetId} updateCallback={updateCallback} />
                                    </MainBoxContent>  : null     
                                }        
                            </Card>     
                        </MainBox>
                        :null
     
                    }

                    { widgetTypeSelected === 'products' || widgetTypeSelected ==='modal_products'?
                        <MainBox>
                            <Card title="Náhľad">
                                <MainBoxContent>
                                    <PreviewWrapper id='preview-wrapper'>
                                    
                                    </PreviewWrapper>
                                </MainBoxContent>    
                            </Card>      
                        </MainBox>
                    :null
                    }
                
                    <MainBox>
                        <CreateNewButtonWrapper>
                            <Button type="primary" onClick={handleGenerateNewButtonClick} > Vytvoriť další widget </Button>
                        </CreateNewButtonWrapper> 
                    </MainBox>
                </>
                )

                : null
            }            
        </>
    )
}

export default WidgetCreate;