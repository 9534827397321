import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState, AppThunk } from "../../store";
import API from "../../services/API";
import { arrayMove } from "react-sortable-hoc";
import { message } from "antd";

const NAMESPACE = 'categoryListingLinks';

export interface CategoryListingLink {
    _id: string;
    index: number;
    language_id: number;
    position: number;
    name: string;
    url: string;
}

interface Category {
    _id: string,
    category_id: number,
    listing_links: CategoryListingLink[]
}

interface CategoryListingLinksState {
    locale_id: number,
    category: Category,
}

const initialState : CategoryListingLinksState = {
    locale_id: 1,
    category:{
        _id: null, 
        category_id: null,
        listing_links: []
    },
}

export const categoryListingLinksSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setCategory: (state, action: PayloadAction<Category>) => {
            state.category = action.payload
        },
        setLocaleId: (state, action: PayloadAction<number>) => {
            state.locale_id = action.payload
        },
        updatePositions: (state, action: PayloadAction<{oldIndex:number,newIndex:number}>) => {
            const listingLinks = state.category.listing_links;
            const newData = arrayMove([].concat(listingLinks), action.payload.oldIndex, action.payload.newIndex).filter(el => !!el);

            // rebuild indexes
            let indexCounter = 0;
            for(const listingLink of newData){
                listingLink.index = indexCounter;
                indexCounter++;
            } 

            state.category.listing_links = newData;
        },
        updateListingLinkValue: (state, action: PayloadAction<{_id:string,dataIndex:string,value:any }>) => {
            const listingLinks = state.category.listing_links;
            const value = listingLinks.find(listingLink => listingLink._id === action.payload._id);
            value[action.payload.dataIndex] = action.payload.value;
            state.category.listing_links = listingLinks;
        },
        createListingLink: (state, action: PayloadAction<{listingLink:CategoryListingLink}>) => {
            const listingLinks = state.category.listing_links;
            listingLinks.push(action.payload.listingLink);
            state.category.listing_links = listingLinks;
        },
        removeListingLink: (state, action: PayloadAction<{_id:string}>) => {
            console.log(state.category.listing_links);  
            console.log(action.payload._id);
 
            state.category.listing_links = state.category.listing_links.filter(listingLink => { 
                console.log(listingLink._id);
                console.log(action.payload._id);
                return listingLink._id !== action.payload._id
            });
        }
    }
});

export const loadCategoryListingLinks = (categoryId:number) : AppThunk => async (dispatch, getState) => {
    API.loadCategories({categoryIds:[categoryId]}).then(response => {
        const category = response.categories.length?response.categories[0]:null;
        let index = 0;
        if(category){
            const rCategory:Category = {
                _id: category._id,
                category_id: category.category_id,
                listing_links: category.listing_links?category.listing_links.map(listingLink => {
                    index++;
                    return {
                        _id: listingLink.language_id + listingLink.name,
                        language_id: listingLink.language_id,
                        position: listingLink.position,
                        name: listingLink.name,
                        url: listingLink.url,
                        index: index-1
                    }
                }):[]
            }

            dispatch(categoryListingLinksSlice.actions.setCategory(rCategory))
        }
    });
}

export const setLocaleId = (localeId:number): AppThunk => async (dispatch, getState) => {
    dispatch(categoryListingLinksSlice.actions.setLocaleId(localeId));
}

export const createSortableListingLinks = (listingLinks: CategoryListingLink[]) => {
    let index = 0;
    return listingLinks.map(listingLink => {
        index++;
        return {...listingLink,index:index-1}
    })
}

export const removeListingLink = (_id) : AppThunk => async (dispatch, getState) => {
   dispatch(categoryListingLinksSlice.actions.removeListingLink({_id}));
}

export const updatePositions = (oldIndex:number,newIndex:number) : AppThunk => async (dispatch, getState) => {
    const localeId = localeIdSelector(getState());
    const listingLinks = listingLinksSelector(getState());

    // convert to real index
    const localeListingLinks = listingLinks.filter(listingLink => listingLink.language_id === localeId);
    const realOldIndex = localeListingLinks[oldIndex].index;
    const realNewIndex = localeListingLinks[newIndex].index;

    dispatch(categoryListingLinksSlice.actions.updatePositions({oldIndex:realOldIndex,newIndex:realNewIndex})); 
}

export const updateListingLinkValue = (dataIndex:string,data:CategoryListingLink) : AppThunk => async (dispatch, getState) => {
    const value = data[dataIndex];
    dispatch(categoryListingLinksSlice.actions.updateListingLinkValue({_id:data._id,dataIndex,value}));
}

export const createListingLink = (data:CategoryListingLink): AppThunk => async (dispatch, getState) => {
    const listingLinks = listingLinksSelector(getState());
    let maxPosition = 0;
    let maxIndex = -1;
    for(const listingLink of listingLinks){
        if(maxPosition < listingLink.position){
          maxPosition = listingLink.position
        }
        if(maxIndex < listingLink.index){
          maxIndex = listingLink.index;
        }
    }
    
    const listingLink:CategoryListingLink = {
        _id: data._id,
        language_id: data.language_id,
        name: data.name,
        url: data.url,
        index: maxIndex+1,
        position: maxPosition+1
    }

    dispatch(categoryListingLinksSlice.actions.createListingLink({listingLink}));
}

export const updateListingLinks = () => async(dispatch, getState) => {
    const category = categorySelector(getState());
    const listingLinks = listingLinksSelector(getState());

    API.updateCategoryListingLinks(category._id,{},{
        listing_links: listingLinks.map(listingLink => {
            return {
                language_id: listingLink.language_id,
                name: listingLink.name,
                position: listingLink.position,
                url: listingLink.url
            }
        })
    }).then(response => {
        message.success(`Listing odkazy boli uložené`);
        dispatch(loadCategoryListingLinks(category.category_id));
    });
}

export const categorySelector     = (state: ApplicationState) => state.categoryListingLinks.category;
export const localeIdSelector     = (state: ApplicationState) => state.categoryListingLinks.locale_id;
export const listingLinksSelector = (state: ApplicationState) => state.categoryListingLinks.category.listing_links;

export default categoryListingLinksSlice.reducer;







