import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, ApplicationState } from '../../store';
import API from '../../services/API';
import moment from "moment";

const NAMESPACE = 'categoryList';

interface Category {
  avg_offers_to_product: string;
  clicks: number;
  ctr: string;
  impressions: number;
  key: string;
  name: string;
  spend: string;
  total_offers: number;
  total_products: number;
  total_shops: number;
}
interface GeneralStats {
  count: number;
  impressions: number;
  clicks: number;
  payment: number;
}

interface GeneralDailyStats {
  date: string;
  impressions: number;
  clicks: number;
  payment: number;
}

interface TreeNode {
  key?: string | number;
  title?: string;
  isLeaf?: boolean;
  children?: TreeNode[];
  parameters?: any[];
  value?: string | number;
}

interface CategoryListState {
  activeFilters: {
    startDate: moment.Moment;
    endDate: moment.Moment;
    category: number[];
    country: number;
    source: number;
  };
  filterResults: {
    offset: number;
    isFetching: boolean;
    total: number;
    categories: Category[];
  };
  generalStats: {
    isFetching: boolean;
    data: GeneralStats;
  };
  generalDailyStats: {
    isFetching: boolean;
    data: Array<GeneralDailyStats>;
  };
  pagination: {
    pageSize: number;
    current: number;
  };
  sorterData: {
    type: string;
    order: string;
  };
  categoryTree: {
    categoryTree: TreeNode[];
    localeId: number;
  };
}

const initialState: CategoryListState = {
  activeFilters: {
    startDate: moment().subtract(7,"days"),
    endDate: moment(),
    category: null,
    country: null,
    source: null
  },
  filterResults: {
    isFetching: false,
    total: 0,
    offset: 0,
    categories: [],
  },
  generalStats: {
    isFetching: false,
    data: null,
  },
  generalDailyStats: {
    isFetching: false,
    data: null,
  },
  pagination: {
    pageSize: 20,
    current: 1,
  },
  sorterData: {
    type: null,
    order: 'asc',
  },
  categoryTree: {
    categoryTree: [],
    localeId: 1,
  },
};

export const categoryListSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setCategory: (
      state,
      action: PayloadAction<{ total: number; categories: Array<Category> }>
    ) => {
      state.filterResults.total = action.payload.total;
      state.filterResults.categories = action.payload.categories;
    },
    setIsFetchingCategoryList: (state, action: PayloadAction<boolean>) => {
      state.filterResults.isFetching = action.payload;
    },
    setActiveFilterValue: (
      state,
      action: PayloadAction<{
        name: string;
        value: number[] | string | number | null | boolean;
      }>
    ) => {
      state.activeFilters[action.payload.name] = action.payload.value;
    },
    setPagination: (
      state,
      action: PayloadAction<{ current: number; pageSize: number }>
    ) => {
      state.pagination.current = action.payload.current;
      state.pagination.pageSize = action.payload.pageSize;
    },
    setSorterData: (
      state,
      action: PayloadAction<{ type: string; order: string }>
    ) => {
      state.sorterData.type = action.payload.type;
      state.sorterData.order = action.payload.order;
    },
    setIsFetchingGeneralStats: (
      state,
      action: PayloadAction<{ value: boolean }>
    ) => {
      state.generalStats.isFetching = action.payload.value;
    },
    setGeneralStats: (state, action: PayloadAction<GeneralStats>) => {
      state.generalStats.data = action.payload;
    },
    setGeneralDailyStats: (
      state,
      action: PayloadAction<Array<GeneralDailyStats>>
    ) => {
      state.generalDailyStats.data = action.payload;
    },
    setTopCategories: (state, action: PayloadAction<TreeNode[]>) => {
      state.categoryTree.categoryTree = action.payload;
    },
    setChildCategories: (
      state,
      action: PayloadAction<{ parentId: string | number; children: TreeNode[] }>
    ) => {
      const categoryTree = state.categoryTree.categoryTree;
      const treeNode = findInTree(categoryTree, action.payload.parentId);
      if (treeNode) {
        treeNode.children = action.payload.children;
      }

      function findInTree(categoryTree: TreeNode[], parentId): TreeNode {
        for (const node of categoryTree) {
          if (node.key === parentId) return node;
          const childNode = findInTree(node.children, parentId);
          if (childNode) return childNode;
        }
        return null;
      }
    },
  },
});

export const loadCategories = (): AppThunk => async (dispatch, getState) => {
  dispatch(categoryListSlice.actions.setIsFetchingCategoryList(true));
  const startDate = startDateActiveFiltersSelector(getState());
  const endDate = endDateActiveFiltersSelector(getState());
  const offset =
    (getState().categoryList.pagination.current - 1) *
    getState().categoryList.pagination.pageSize;
  const category_ids = getState().categoryList.activeFilters.category;
  const source = sourceActiveFiltersSelector(getState());

  const country = getState().categoryList.activeFilters.country;

  API.loadCategoryList({
    startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
    endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
    offset: offset,
    limit: getState().categoryList.pagination.pageSize,
    sortType: getState().categoryList.sorterData.type
      ? getState().categoryList.sorterData.type
      : undefined,
    sortOrder: getState().categoryList.sorterData.order,
    categoryIds: category_ids ? category_ids : [],
    country: country ? country : 0,
    source: source ? source : undefined
  }).then(response => {
    if (response.items) {
      const CategoryList = response.items.map(category => {
        return {
          key: category._id,
          name: category.category_name,
          impressions: category.impressions && category.impressions,
          clicks: category.clicks && category.clicks,
          ctr:
            category.impressions &&
            category.clicks &&
            ((category.clicks / category.impressions)).toFixed(2),
          spend: category.payment && category.payment.toFixed(2),
          total_products:
            category.total_category_products &&
            category.total_category_products,
          total_offers:
            category.active_total_offers && category.active_total_offers,
          total_shops:
            category.active_total_shops && category.active_total_shops,
          avg_offers_to_product:
            category.avg_offers_product &&
            category.avg_offers_product &&
            (
              category.active_total_offers / category.total_category_products
            ).toFixed(2),
          cpc: category.cpc && category.cpc,
        };
      });
      dispatch(
        categoryListSlice.actions.setCategory({
          total: response.total,
          categories: CategoryList,
        })
      );
      dispatch(categoryListSlice.actions.setIsFetchingCategoryList(false));
    }
  });
};

export const loadGeneralStats = (): AppThunk => async (dispatch, getState) => {
  dispatch(
    categoryListSlice.actions.setIsFetchingGeneralStats({ value: true })
  );
  const country = getState().categoryList.activeFilters.country;

  const startDate = startDateActiveFiltersSelector(getState());
  const endDate = endDateActiveFiltersSelector(getState());
  const category_ids = getState().categoryList.activeFilters.category;
  const source = sourceActiveFiltersSelector(getState());
  API.loadGeneralCategoryListStats({
    startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
    endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
    categoryIds: category_ids ? category_ids : [],
    country: country ? country : 0,
    source: source ? source : undefined
  }).then(response => {
    const generalStats: GeneralStats = {
      clicks: response.clicks,
      count: response.count,
      impressions: response.impressions,
      payment: response.payment,
    };
    dispatch(categoryListSlice.actions.setGeneralStats(generalStats));
  });
};
export const loadGeneralDailyStats = (): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(
    categoryListSlice.actions.setIsFetchingGeneralStats({ value: true })
  );
  const country = getState().categoryList.activeFilters.country;
  const startDate = startDateActiveFiltersSelector(getState());
  const endDate = endDateActiveFiltersSelector(getState());
  const category_ids = getState().categoryList.activeFilters.category;
  const source = sourceActiveFiltersSelector(getState());
  API.loadGeneralDailyCategoryListStats({
    startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
    endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
    categoryIds: category_ids ? category_ids : [],
    country: country ? country : 0,
    source: source ? source : undefined
  }).then(response => {
    const generalDailyStats: GeneralDailyStats[] = response.items.map(item => {
      return {
        date: item.date,
        clicks: item.clicks,
        impressions: item.impressions,
        payment: item.payment,
        total_category_products: item.total_category_products,
        shops_avg: item.shops_avg,
        active_total_offers: item.active_total_offers,
      };
    });

    generalDailyStats.sort((a, b) => {
      return a.date.localeCompare(b.date);
    });
    dispatch(categoryListSlice.actions.setGeneralDailyStats(generalDailyStats));
    dispatch(
      categoryListSlice.actions.setIsFetchingGeneralStats({ value: false })
    );
  });
};

export const loadTopCategories = (): AppThunk => async (dispatch, getState) => {
  API.loadCategoriesTree({ maxDepth: 1 }).then(response => {
    const treeNodes: TreeNode[] = response.categories.map(category => {
      let categoryName;
      if (category && category.translations && category.translations.length) {
        const categoryTranslation = category.translations.find(
          translation => translation.language_id === 1
        );
        if (categoryTranslation) {
          categoryName = categoryTranslation.name;
        }
      }
      return {
        title: categoryName,
        isLeaf: category.children && category.children.length ? false : true,
        children: [],
        parameters: category.parameters ? category.parameters : [],
        key: category.category_id,
        value: category.category_id,
      };
    });
   
    treeNodes.sort((a, b) => {
      return a.title.localeCompare(b.title)
    });
    dispatch(categoryListSlice.actions.setTopCategories(treeNodes));
  });
};

export const loadChildrenCategories = (
  parentId: string | number
): AppThunk => async (dispatch, getState) => {
  const response = await API.loadCategoriesTree({
    parentId: parentId as number,
    maxDepth: 1,
  });
  const treeNodes: TreeNode[] = response.categories.map(category => {
    let categoryName;
    if (category && category.translations && category.translations.length) {
      const categoryTranslation = category.translations.find(
        translation => translation.language_id === 1
      );
      if (categoryTranslation) {
        categoryName = categoryTranslation.name;
      }
    }

    return {
      key: category.category_id,
      title: categoryName,
      isLeaf: category.children && category.children.length ? false : true,
      children: [],
      parameters: category.parameters ? category.parameters : [],
      value: category.category_id,
    };
  });

  treeNodes.sort((a, b) => {
    return a.title.localeCompare(b.title)
  });

  dispatch(
    categoryListSlice.actions.setChildCategories({
      parentId: parentId,
      children: treeNodes,
    })
  );
};

export const setFilter = (
  filter: string,
  value: number[] | string | number | null | boolean
) => (dispatch, getState) => {
  dispatch(
    categoryListSlice.actions.setActiveFilterValue({ name: filter, value })
  );
};

export const setPagination = (current: number, pageSize: number) => (
  dispatch,
  getState
) => {
  dispatch(
    categoryListSlice.actions.setPagination({
      current: current,
      pageSize: pageSize,
    })
  );
};

export const setSorter = (field: string, orderName: string) => (
  dispatch,
  getState
) => {
  const type: string = orderName ? field : undefined;
  const order: string = orderName === 'descend' ? 'desc' : 'asc';

  dispatch(
    categoryListSlice.actions.setSorterData({ type: type, order: order })
  );
};

export const categoryListIsFethingSelector = (state: ApplicationState) =>
  state.categoryList.filterResults.isFetching;
export const categoryListSelector = (state: ApplicationState) =>
  state.categoryList.filterResults.categories;
export const totalSelector = (state: ApplicationState) =>
  state.categoryList.filterResults.total;
export const paginationSelector = (state: ApplicationState) =>
  state.categoryList.pagination;

export const startDateActiveFiltersSelector = (state: ApplicationState) =>
  state.categoryList.activeFilters.startDate;
export const endDateActiveFiltersSelector = (state: ApplicationState) =>
  state.categoryList.activeFilters.endDate;

export const generalStatsSelector = (state: ApplicationState) =>
  state.categoryList.generalStats.data;
export const generalStatsFethingSelector = (state: ApplicationState) =>
  state.categoryList.generalStats.isFetching;
export const generalDailyStatsSelector = (state: ApplicationState) =>
  state.categoryList.generalDailyStats.data;

export const categoryTreeSelector = (state: ApplicationState) =>
  state.categoryList.categoryTree.categoryTree;

  export const sourceActiveFiltersSelector = (state: ApplicationState) =>
  state.categoryList.activeFilters.source;

export default categoryListSlice.reducer;
