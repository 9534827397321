import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, ApplicationState } from '../store';

const NAMESPACE = 'routes';

interface navigationState {
  breadCrumb: {
    name: string;
    url: string;
  }[];
}

const initialState: navigationState = {
  breadCrumb: [],
};

export const navigationSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setBreadCrumb: (
      state,
      action: PayloadAction<{ name: string; url: string }[]>
    ) => {
      state.breadCrumb = action.payload;
    },
  },
});

export const loadBreadCrumb = (): AppThunk => async (dispatch, getState) => {
  console.log('Load token');
};

export const activeBreadCrumb = (state: ApplicationState) =>
  state.navigation.breadCrumb;

export const setBreadCrumb = (value: { name: string; url: string }[]) => (
  dispatch,
  getState
) => {
  dispatch(navigationSlice.actions.setBreadCrumb(value));
};

export default navigationSlice.reducer;
