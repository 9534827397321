import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit'
import rootReducer from './containers/App/slice';
import productListReducer from './containers/ProductList/slice';
import pmProductListReducer from './containers/ProductSearch/pmSlice';
import productSearchList from './containers/ProductSearch/slice';
import navigationReducer from './Routes/slice';
import productDetailReducer from './containers/ProductDetail/slice';
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import thunkMiddleware from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import feedReportReducer from './containers/FeedReport/slice';
import automaticProductListReducer from './containers/AutomaticPairingProduct/slice'
import automaticListReducer from './containers/AutomaticPairing/slice'
import manufacturerMappingListReducer from './containers/MappingManufacturers/slice';
import categoryMappingListReducer from './containers/MappingCategories/slice';
import productCreateReducer from './containers/ProductCreate/slice';
import usersListReducer from './containers/UsersList/slice';
import widgetCreateReducer from './containers/WidgetCreate/slice';
import affilStatsReducer from './containers/AffilStats/slice';
import affilStatsPublishersReducer from './containers/AffilStatsPublishers/slice'
import affilSettingsReducer from './containers/AffilSettings/slice';
import CMSStatsReducer from './containers/CMSStats/slice';
import WidgetSettingsReducer from './components/WidgetSettings/slice';
import WidgetTagsSettingsReducer from './components/WidgetTagsSettings/slice';
import topProductSearchList from './containers/Top50/slice';
import uniqueProductsReducer from './containers/UniqueProducts/slice';
import productCreateBulkReducer from './containers/ProductCreateBulk/slice';
import parameterDetailReducer from './containers/ParameterDetail/slice';
import parameterListReducer from './containers/ParameterList/slice';
import categoryDetailReducer from './containers/CategoryDetail/slice';
import shopListReducer from './containers/ShopList/slice';
import shopDetailReducer from './containers/ShopDetail/slice';
import scheduledTasksListReducer from './containers/ScheduledTasksList/slice';
import categoryTreeReducer from './containers/CategoryTree/slice';
import categoryParametersReducer from './components/CategoryParameters/slice';
import productParameterBulkEditReducer from './containers/ProductParametersBulkEdit/slice';
import parameterFeedMappingReducer from './containers/ParameterFeedMapping/slice';
import categoryLandingPagesReducer from './components/CategoryLandingPages/slice';
import categoryListReducer from "./containers/CategoryList/slice"
import mergeProductsReducer from './containers/MergeProducts/slice';
import parameterIcecatMappingReducer from './containers/ParameterIcecatMapping/slice';
import productListStats from "./containers/ProductStatsList/slice"
import categoryListingLinksReducer from './components/CategoryListingLinks/slice'
import webWidgetReducer from "./containers/WebWidget/slice";
import manufacturersListReducer from "./containers/ManufacturersList/slice";
import manufacturersDetailReducer from "./containers/ManufacturersList/ManufacturerDetail/slice";
import topCategoriesReducer from "./containers/TopCategories/slice";

export const history = createBrowserHistory();

const applicationReducers = combineReducers({
    router: connectRouter(history),
    general: rootReducer,
    productList: productListReducer, 
    productSearchList: productSearchList,
    productListStats: productListStats,
    pmProductList: pmProductListReducer,
    navigation: navigationReducer,
    productDetail: productDetailReducer,
    feedReportList: feedReportReducer,
    automaticPairingProductList: automaticProductListReducer,
    automaticPairingList: automaticListReducer,
    manufacturerMappingList: manufacturerMappingListReducer,
    categoryMappingList: categoryMappingListReducer,
    productCreate: productCreateReducer,
    usersList: usersListReducer,
    widgetCreate: widgetCreateReducer,
    affilStats: affilStatsReducer,
    AffilSettings: affilSettingsReducer,
    CMSStats: CMSStatsReducer,
    widgetSettings: WidgetSettingsReducer,
    widgetTagsSettings: WidgetTagsSettingsReducer,
    affilStatsPublishers:affilStatsPublishersReducer,
    topProductsSearchList: topProductSearchList,
    uniqueProducts: uniqueProductsReducer,
    productCreateBulk: productCreateBulkReducer,
    parameterDetail: parameterDetailReducer,
    parameterList: parameterListReducer,
    categoryDetail: categoryDetailReducer,
    categoryList: categoryListReducer,
    shopList: shopListReducer,
    shopDetail: shopDetailReducer,
    scheduledTasksList: scheduledTasksListReducer,
    categoryTree: categoryTreeReducer,
    categoryParameters: categoryParametersReducer,
    productParameterBulkEdit: productParameterBulkEditReducer,
    parameterFeedMapping: parameterFeedMappingReducer,
    categoryLandingPages: categoryLandingPagesReducer,
    mergeProducts: mergeProductsReducer,
    parameterIcecatMapping: parameterIcecatMappingReducer,
    categoryListingLinks: categoryListingLinksReducer,
    webWidget: webWidgetReducer,
    manufacturersList:manufacturersListReducer,
    manufacturersDetail: manufacturersDetailReducer,
    topCategories: topCategoriesReducer
});


const store = configureStore({
  reducer: applicationReducers,
  middleware: [thunkMiddleware, routerMiddleware(history)]
});

export type ApplicationState = ReturnType<typeof applicationReducers>
export type AppThunk = ThunkAction<void, ApplicationState, unknown, Action<string>>;


export default store
