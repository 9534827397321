import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, ApplicationState } from "../../store";
import API from "../../services/API";
import { getValuesFromArray } from "../../utilities";

const NAMESPACE = 'scheduledTasksList';

export interface ScheduledTask {
    key: string,
    _id: string,
    type: string,
    status: string,
    created_at: string,
    started_at: string,
    finished_at: string,
    shop_id: number
    data: any, 
    response:any,
    shop_name: string
}

interface ScheduledTasksListState {
    activeFilters: {
        shop: { value: number}[]
    },
    filtersData: {
        shop: {
            isFetching: boolean,
            data: any
        }
    },
    filterResults: {
        offset: number
        isFetching: boolean,
        total: number;
        scheduled_tasks: ScheduledTask[];
    },
    pagination: {
        pageSize: number;
        current: number
    },
    sorterData: {
        type: string,
        order: string;
    },
}

const initialState : ScheduledTasksListState = {
    activeFilters: {
        shop: [],
    },
    filtersData: {
        shop: {
            isFetching: false,
            data: []
        }
    },
    filterResults: {
        isFetching: false,
        total: 0,
        offset: 0,
        scheduled_tasks: []
    },
    pagination: {
        pageSize: 100,
        current: 1
    },
    sorterData: {
        type: null,
        order: 'asc'
    },
}

export const scheduledTasksListSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setFilterResults: (state, action: PayloadAction<{total: number, scheduled_tasks: Array<ScheduledTask>}>) => {
            state.filterResults.total       = action.payload.total;
            state.filterResults.scheduled_tasks = action.payload.scheduled_tasks;
        },
        setIsFetchingFilterResults: (state, action: PayloadAction<{value: boolean}>) => {
            state.filterResults.isFetching = action.payload.value
        },
        setPagination: (state, action: PayloadAction<{current: number}>) => {
            state.pagination.current = action.payload.current
        },
        setSorterData: (state, action: PayloadAction<{type: string, order: string}>) => {
            state.sorterData.type = action.payload.type;
            state.sorterData.order = action.payload.order;
        },
        setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | number | null | boolean}>) => {
            state.activeFilters[action.payload.name] = action.payload.value;
        },
        setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },
        setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
    }
});

export const setSorter = (field: string, orderName: string) => (dispatch, getState) => {
    const type:string  = orderName?field:undefined;
    const order:string = orderName === 'descend'?'desc':'asc';
    
    dispatch(scheduledTasksListSlice.actions.setSorterData({ type: type, order:order}));
}

export const loadScheduledTasks = () : AppThunk => async (dispatch, getState) => {
    dispatch(scheduledTasksListSlice.actions.setIsFetchingFilterResults({value:true}));
    const offset = (getState().scheduledTasksList.pagination.current-1) * getState().scheduledTasksList.pagination.pageSize;
    const filterShopIds = getValuesFromArray<number>(shopActiveFiltersSelector(getState()));

    API.loadScheduledTasks({ 
        limit: getState().scheduledTasksList.pagination.pageSize,
        offset: offset,
        filterShopIds:  filterShopIds &&  filterShopIds.length? filterShopIds:undefined
    }).then(response => {
        const shopIds = response.items.map(item => {return item.shop_id}).filter(o=>o);
        API.loadShops({
            shopIds: shopIds,
            limit: 1000
        }).then(loadShopsResponse => {
            const scheduledTasks:ScheduledTask[] = response.items.map(item => {
                const shop = loadShopsResponse.shops.find(s => s.shop_id === item.shop_id);
                console.log("SHOP");
                console.log(shop);
                return {
                    key: item._id,
                    _id: item._id,
                    type: item.type,
                    created_at: item.created_at,
                    started_at: item.started_at,
                    finished_at: item.finished_at,
                    data: item.data,
                    response: item.response,
                    shop_id: item.shop_id,
                    status: item.status,
                    shop_name: shop?shop.name:"---"
                }
            })
    
            dispatch(scheduledTasksListSlice.actions.setFilterResults({total:response.total,scheduled_tasks:scheduledTasks}));
            dispatch(scheduledTasksListSlice.actions.setIsFetchingFilterResults({value:false})); 

        })
    })
}

export const loadShopFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(scheduledTasksListSlice.actions.setIsFetchingFilterData({name: 'shop', value: true}));

    API.loadShops({query:value,limit:100}).then(response => {
      dispatch(scheduledTasksListSlice.actions.setFilterData({name: 'shop', value: 
      response.shops.map(shop => {
          return {
            name: shop.name+` (${shop.program_id})`,
            value: shop.shop_id
          }
        })
      }));
      
      dispatch(scheduledTasksListSlice.actions.setIsFetchingFilterData({name: 'shop', value: false}));
    })  
}

export const setPagination = (current: number) => (dispatch, getState) => {
    dispatch(scheduledTasksListSlice.actions.setPagination({ current:current}));
}

export const setFilter = (filter: string, value: number[] | string | number | null | boolean) => (dispatch, getState) => {
    dispatch(scheduledTasksListSlice.actions.setActiveFilterValue({name: filter, value}));
    dispatch(loadScheduledTasks());
}

export const scheduledTasksSelector = (state: ApplicationState) => state.scheduledTasksList.filterResults.scheduled_tasks;
export const totalSelector = (state: ApplicationState) => state.scheduledTasksList.filterResults.total;
export const paginationSelector = (state: ApplicationState) => state.scheduledTasksList.pagination;
export const isFetchingSelector = (state: ApplicationState) => state.scheduledTasksList.filterResults.isFetching;

export const shopActiveFiltersSelector = (state: ApplicationState) => state.scheduledTasksList.activeFilters.shop;
export const shopFiltersDataSelector = (state: ApplicationState) => state.scheduledTasksList.filtersData.shop.data;
export const shopFiltersDataIsFetching = (state: ApplicationState) => state.scheduledTasksList.filtersData.shop.isFetching;

export default scheduledTasksListSlice.reducer;







