import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Input, PageHeader, Switch } from 'antd';
import React, { useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { CategorySelectModal } from '../../components/Modals/CategorySelectModal';
import { ParameterNumberSelectModal } from '../../components/Modals/ParameterNumberSelectModal';
import { ParameterValueSelectModal } from '../../components/Modals/ParameterValueSelectModal';
import { ApplicationState } from '../../store';
import { __ } from '../../utilities';
import { addCategory, addNumberParameter, addValueParameter, createIcecatMapping, loadIcecatMappings, parameterIcecatMappingsSelector, recalculateIcecatMapping, removeCategory, removeNumberParameter, removeValueParameter, updateCreateParametersEnabled, updateIcecatIdNumberParameter, updateIcecatIdValueParameter, updateIcecatIdValueParameterValue, updateIcecatMapping } from './slice';

const MainContent = styled.div`
  margin-top: 25px;
  padding: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`

const CustomCollapse = styled(Collapse)`
  margin-bottom: 15px;
`

const CustomExtraButton = styled(Button)`
  margin-left: 10px; 
`

const LpRow = styled.tr`
  
`

const LpColumn = styled.td`
  margin: 10px;
  padding: 15px;
`

const CraeteParamText = styled.div`
  display: inline;
  margin-right: 10px;
`

const ParameterIcecatMapping = () => {

    const dispatch = useDispatch();

    const [isCategorySelectModalActive,setIsCategorySelectModalActive] = useState(false);
    const [isValueParameterSelectModalActive,setIsValueParameterSelectModalActive] = useState(false);
    const [isNumberParameterSelectModalActive,setIsNumberParameterSelectModalActive] = useState(false);

    const [currentIcecatMappingId,setCurrentIcecatMappingId] = useState(null);

    React.useEffect(() => {
        async function init() {
          await dispatch(loadIcecatMappings());
        }
        init();
    }, [dispatch]);

    const parameterIcecatMappings = useSelector((state: ApplicationState) =>
       parameterIcecatMappingsSelector(state)
    );

    const onSaveClick = async(icecatParameterMappingId) => {
        dispatch(updateIcecatMapping(icecatParameterMappingId));
    }

    const onCreateClick = async() => {
        dispatch(createIcecatMapping());
    }

    const onRecalculateClick = async(icecatParameterMappingId) => { 
        dispatch(recalculateIcecatMapping(icecatParameterMappingId));
    }

    const onClickAddCategory = async(icecatCategoryMappingId) => { 
        setIsCategorySelectModalActive(true);
        setCurrentIcecatMappingId(icecatCategoryMappingId);
    }

    const onClickRemoveCategory = async(icecatCategoryMappingId, categoryId) => { 
        dispatch(removeCategory(icecatCategoryMappingId, categoryId));
    }

    const onClickAddValueParameter = async(icecatCategoryMappingId) => {
        setIsValueParameterSelectModalActive(true);
        setCurrentIcecatMappingId(icecatCategoryMappingId);
    }

    const onClickRemoveValueParameter = async(icecatCategoryMappingId, parameterId, parameterValueId) => { 
        dispatch(removeValueParameter(icecatCategoryMappingId, parameterId, parameterValueId));
    }

    const onClickAddNumberParameter = async(icecatCategoryMappingId) => { 
        setIsNumberParameterSelectModalActive(true);
        setCurrentIcecatMappingId(icecatCategoryMappingId);
    }

    const onClickRemoveNumberParameter = async(icecatCategoryMappingId, parameterId) => { 
        dispatch(removeNumberParameter(icecatCategoryMappingId, parameterId));
    }

    const onChangeIcecatIdValueParameter = async(icecatCategoryMappingId, icecatId, parameterId, parameterValueId) => { 
        dispatch(updateIcecatIdValueParameter(icecatCategoryMappingId, icecatId, parameterId, parameterValueId));
    }

    const onChangeIcecatIdValueParameterValue = async(icecatCategoryMappingId, icecatId, parameterId, parameterValueId) => { 
        dispatch(updateIcecatIdValueParameterValue(icecatCategoryMappingId, icecatId, parameterId, parameterValueId));
    }
    
    const onChangeIcecatIdNumberParameter = async(icecatCategoryMappingId, icecatId, parameterId) => { 
        dispatch(updateIcecatIdNumberParameter(icecatCategoryMappingId, icecatId, parameterId))
    }

    const onChangeCreateParametersEnabled = async(icecatCategoryMappingId, value) => { 
        dispatch(updateCreateParametersEnabled(icecatCategoryMappingId,value));
    }

    return <> 
        <PageHeader 
            title = {"Mapovanie Icecat parameterov"}
            ghost={false}
            extra={[
                <Button onClick={()=>onCreateClick()}> {__("Vytvoriť") } </Button>
            ]}
        />

        <MainContent>
           { parameterIcecatMappings.map(parameterIcecatMapping => {

               const header = parameterIcecatMapping.categories.map(category => {
                   return category.name?category.name:null
               }).filter(o=>o).join(', '); 


               return <CustomCollapse>
                    <Collapse.Panel key={parameterIcecatMapping._id} header={header}
                        extra={[
                            <CustomExtraButton onClick={(e) => {e.preventDefault();onRecalculateClick(parameterIcecatMapping._id)} } > {__("Prepočítať")} </CustomExtraButton>,
                            <CustomExtraButton onClick={(e) => {e.preventDefault();onSaveClick(parameterIcecatMapping._id)} } > {__("Uložiť")} </CustomExtraButton>,
                        ]}
                    >

                    <div>
                       <CraeteParamText> {__("Povoliť vytváranie nových parametrov")} </CraeteParamText> <Switch checked={parameterIcecatMapping.create_parameters_enabled} onChange={(value) => onChangeCreateParametersEnabled(parameterIcecatMapping._id,value) }/>
                    </div>    

                    <Divider orientation="left" plain> Kategórie <PlusOutlined onClick={() => onClickAddCategory(parameterIcecatMapping._id)}/> </Divider>

                    {parameterIcecatMapping.categories.map(category => {
                        return <LpRow> 
                            <LpColumn> {category.name} </LpColumn>
                            <LpColumn> <Button onClick={() => onClickRemoveCategory(parameterIcecatMapping._id,category.category_id)} icon={<DeleteOutlined/>}></Button> </LpColumn>
                        </LpRow>
                    })}

                    <Divider orientation="left" plain> Hodnotové parametre <PlusOutlined onClick={() => onClickAddValueParameter(parameterIcecatMapping._id)}/> </Divider>

                    {parameterIcecatMapping.value_parameters.map(valueParameter => {
                        return valueParameter.values.map(value => {
                            return <LpRow> 
                                <LpColumn> {valueParameter.name} </LpColumn>
                                <LpColumn> {value.name} </LpColumn>

                                <LpColumn> <Input defaultValue={valueParameter.icecat_id} onBlur={e => onChangeIcecatIdValueParameter(parameterIcecatMapping._id,e.target.value,valueParameter.parameter_id,value.parameter_value_id)}></Input> </LpColumn>
                                <LpColumn> <Input defaultValue={value.icecat_id} onBlur={e => onChangeIcecatIdValueParameterValue(parameterIcecatMapping._id,e.target.value,valueParameter.parameter_id,value.parameter_value_id)}></Input> </LpColumn>

                                <LpColumn> <Button onClick={() => onClickRemoveValueParameter(parameterIcecatMapping._id,valueParameter.parameter_id,value.parameter_value_id)} icon={<DeleteOutlined/>}></Button> </LpColumn>
                            </LpRow>
                        })

                    })}

                    <Divider orientation="left" plain> Číslené parametre <PlusOutlined onClick={() => onClickAddNumberParameter(parameterIcecatMapping._id)}/> </Divider>

                    {parameterIcecatMapping.number_parameters.map(numberParameter => {
                        return <LpRow> 
                            <LpColumn> {numberParameter.name} </LpColumn>
                            <LpColumn> <Input defaultValue={numberParameter.icecat_id} onBlur={e => onChangeIcecatIdNumberParameter(parameterIcecatMapping._id,e.target.value,numberParameter.parameter_id)}></Input> </LpColumn>

                            <LpColumn> <Button onClick={() => onClickRemoveNumberParameter(parameterIcecatMapping._id,numberParameter.parameter_id)} icon={<DeleteOutlined/>}></Button> </LpColumn>
                        </LpRow>
                    })}

                    </Collapse.Panel>
               </CustomCollapse>
           })}
        </MainContent>
        
        {isCategorySelectModalActive &&  
            <CategorySelectModal 
                title="Výber kategórie"
                onOk={categoryId => {
                if(categoryId){
                    dispatch(addCategory(currentIcecatMappingId,categoryId));
                    setIsCategorySelectModalActive(false)
                }
                }}
                onCancel={() => {
                    setIsCategorySelectModalActive(false)
                }}
            />
        }

        {isValueParameterSelectModalActive && 
         <ParameterValueSelectModal
            title="Výber parametru"
            onOk={(parameterId,parameterValueId) => {
               if(parameterId && parameterValueId){
                  dispatch(addValueParameter(currentIcecatMappingId,parameterId,parameterValueId))
                  setIsValueParameterSelectModalActive(false)
               }
            }}
            onCancel={() => {
                setIsValueParameterSelectModalActive(false)
            }}
         />
      }

      {isNumberParameterSelectModalActive && 
         <ParameterNumberSelectModal
            title="Výber parametru"
            onOk={(parameterId) => {
               if(parameterId){
                  dispatch(addNumberParameter(currentIcecatMappingId,parameterId))
                  setIsNumberParameterSelectModalActive(false)
               }
            }}
            onCancel={() => {
                setIsNumberParameterSelectModalActive(false)
            }}
         />
      }
    </>
}

export default ParameterIcecatMapping;