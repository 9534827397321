import React  from 'react';
import {
    Table, Row, Col, Checkbox, Button, message
  } from 'antd';
import {RedoOutlined } from '@ant-design/icons';
import { __ } from '../../utilities';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import styled from 'styled-components';
import { startManualFeed, filterResultsTotalSelector, paginationSelector, filterResultsIsFetchingSelector, filterResultsFeedReportsSelector, loadFeedReports, setPagination, loadShopFilterData, shopFiltersDataIsFetching, shopActiveFiltersSelector, shopFiltersDataSelector, setFilter } from './slice';
import ConnectedMultiSelect from '../../components/Select/ConnectedMultiSelect';

const TableRow = styled(Row)`
  margin-bottom: 15px; 
`

const FitlerRow = styled(Row)`
  margin-bottom: 15px; 
`;

const FeedReport = () => {
   
    const dispatch = useDispatch();

    React.useEffect(() => {
      async function init() {
        dispatch(loadFeedReports());
      }
      init();
    }, [dispatch]);
    
    const columns = [
        {
            title: __('Čas'),
            dataIndex: 'createdAt',
            sorter: false,
        },
        {
            title: __('Shop'),
            dataIndex: 'shopName',
            sorter: false,
        },
        {
            title: __('OK'),
            dataIndex: 'parsedProductCount',
            sorter: false,
        },
        {
            title: __('ERROR'),
            dataIndex: 'allErrorsCount',
            sorter: false
        },
        {
            title: __('Akcia'),
            dataIndex: 'shopId',
            render: (shopId,data) => {
              return <Button type="primary"
                            size="small"
                            icon={<RedoOutlined />}
                            onClick={() => {
                              onManualFeedClick(shopId,data.shopName);
                            }} 
                     />
                      
            }
        }
    ];

    const onManualFeedClick = (shopId:number, shopName:string) => {
      dispatch(startManualFeed(shopId));
      message.success(`Feed pre obchod ${shopName} manuálne spustený`);
    }

    const total = useSelector((state: ApplicationState) =>
      filterResultsTotalSelector(state)
    );

    const pagination = useSelector((state: ApplicationState) =>
      paginationSelector(state)
    );

    const isFetchingFeedReports= useSelector((state: ApplicationState) =>
      filterResultsIsFetchingSelector(state)
    );

    const feedReports = useSelector((state: ApplicationState) =>
      filterResultsFeedReportsSelector(state)
    );

    const onChangeTable = async (pagination, filters, sorters) => {
      dispatch(setPagination(pagination.current));
      dispatch(loadFeedReports());
    };

    const onChangeShopFilter = async value => {
      await dispatch(loadShopFilterData(value));
    };

    const isFetchingShopData = useSelector((state: ApplicationState) =>
      shopFiltersDataIsFetching(state)
    );

    const shopActiveFilters = useSelector((state: ApplicationState) =>
      shopActiveFiltersSelector(state)
    );

    const shopData = useSelector((state: ApplicationState) =>
      shopFiltersDataSelector(state)
    );

    const onSelectShopFilter = async value => {
      dispatch(setPagination(1));
      dispatch(setFilter('shopSelected', value));
      dispatch(loadFeedReports());
    };

    const onChangeOnlyWithErrors = async value => {
      dispatch(setFilter('onlyWithErrors', value.target.checked));
      dispatch(loadFeedReports());
    };

    return (
        <>
          <TableRow>
            <Col className="gutter-row" span={24}>
              <FitlerRow gutter={{ lg: 5 }}>
                <Col>
                    <ConnectedMultiSelect
                      name={__('Shop')}
                      isFetching={isFetchingShopData}
                      value={shopActiveFilters}
                      data={shopData}
                      selectWidth={200}
                      onChange={onChangeShopFilter}
                      onSelect={onSelectShopFilter}
                      disabled = {isFetchingFeedReports?true:false}
                    />
                </Col>

                <Col>
                    <Checkbox onChange={onChangeOnlyWithErrors} disabled={isFetchingFeedReports?true:false} >Iba s chybami</Checkbox>
                </Col>   

              </FitlerRow>
            </Col>  

          </TableRow>

          <Table
            columns={columns}
            expandable={{
              expandedRowRender: record => <> { record.errors.map(error => ( <p style={{ margin: 5 }}>{error}</p>) ) } </>,
              rowExpandable: record => record.errors.length?true:false,
            }}
            pagination={{
              total: total,
              current: pagination.current,
              pageSize: pagination.pageSize,
            }}
            loading={isFetchingFeedReports}
            dataSource={feedReports}
            onChange={(pagination, filters, sorters) => {
              onChangeTable(pagination, filters, sorters);
            }}></Table>
        </>
    )
}

export default FeedReport;