
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Collapse, Divider, Form, Input, InputNumber, Switch } from "antd";
import { rem } from "polished";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ApplicationState } from "../../store";
import { __ } from "../../utilities";
import { CategorySelectModal } from "../Modals/CategorySelectModal";
import { ManufacturerSelectModal } from "../Modals/ManufacturerSelectModal";
import { ParameterValueSelectModal } from "../Modals/ParameterValueSelectModal";
import ConnectedSingleSelect from "../Select/ConnectedSingleSelect";
import { addCategory, addManufacturer, addParameter, changeTranslatedName, createEmptyLandingPage, landingPagesSelector, loadCategoryLandingPages, localeIdSelector, removeCategory, removeManufacturer, removeParameter, setLocaleId, setSorting, updateLangingPageField } from "./slice";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddNewLandingPageButton = styled(Button)`
   margin-bottom: 10px;
   width: 200px;
`

const ButtonSection = styled.div`
   
`

const MainSection = styled.div`

`

const LandingPageWrapper = styled.div`
  width: 100%;
  border: 1px solid #f0f0f0;
  margin-bottom: 10px;
`

const LandingPageBlockTranslations = styled.div`
  padding: 10px;
`

const LandingPageBlock = styled.table`
  padding: 10px;
  margin-left:30px;
`

const LpRow = styled.tr`
  
`

const LpColumn = styled.td`
  margin: 10px;
  padding: 15px;
`

const SortLandingPageBlock = styled(LandingPageBlock)`
  margin-bottom: 25px;
`

const CustomCollapse = styled(Collapse)`
  margin-bottom: 15px;
`

const CategoryLandingPages = ({categoryId,defaultLocaleId}) => {
   
   const dispatch = useDispatch();
   const [isManufacturerSelectModalActive,setIsManufacturerSelectModalActive] = useState(false);
   const [isCategorySelectModalActive,setIsCategorySelectModalActive] = useState(false);
   const [isParameterSelectModalActive,setIsParameterSelectModalActive] = useState(false);
   const [currentLandingPageId,setCurrentLandingPageId] = useState(null as string);
   
   React.useEffect(() => {
      async function init() {
        if(categoryId){
          await dispatch(loadCategoryLandingPages(categoryId))
        }  
      }
      init();
   }, [dispatch, categoryId]);

   React.useEffect(() => {
      async function init() {
        await dispatch(setLocaleId(defaultLocaleId))
      }
      init();
   }, [dispatch, defaultLocaleId]);

   const localeId = useSelector((state: ApplicationState) =>
        localeIdSelector(state)
   );

   const landingPages = useSelector((state: ApplicationState) =>
       landingPagesSelector(state)
   );

   const onClickAddNewLandingPage = () => {
      dispatch(createEmptyLandingPage());
   }

   const onClickAddManufacturer = (landingPageId:string) => {
      setIsManufacturerSelectModalActive(true);
      setCurrentLandingPageId(landingPageId);
   }

   const onClickRemoveManufacturer = (landingPageId:string,manufacturerId:number) => {
      dispatch(removeManufacturer(landingPageId,manufacturerId))
   }

   const onClickAddCategory = (landingPageId:string) => {
      setIsCategorySelectModalActive(true);
      setCurrentLandingPageId(landingPageId);
   }

   const onClickRemoveCategory = (landingPageId:string,categoryId:number) => {
      dispatch(removeCategory(landingPageId,categoryId));
   }

   const onClickAddParameter = (landingPageId:string) => {
      setIsParameterSelectModalActive(true);
      setCurrentLandingPageId(landingPageId);
   }
   
   const onClickRemoveParameter = (landingPageId:string,parameterId:string,parameterValueId:string) => {
      dispatch(removeParameter(landingPageId,parameterId,parameterValueId));
   }

   return <> 
      <ButtonSection>
         <AddNewLandingPageButton onClick={onClickAddNewLandingPage}> {__("Pridať novú landing page")} </AddNewLandingPageButton>
      </ButtonSection>
      <MainSection>
         {landingPages.map(landingPage =>{
            
            //const currentTranslation = landingPage.translations.find(t => t.language_id == localeId);

            return<CustomCollapse>
                  <Collapse.Panel key={landingPage._id} header={landingPage.translations && landingPage.translations.length && landingPage.translations[0].title?landingPage.translations[0].title:__("Bez názvu")}
                        extra={[]}
                  >
                  <LandingPageWrapper>
                  <Divider orientation="left" plain>Preklady</Divider>
                  <LandingPageBlockTranslations>
                     {[1,2].map(mapLocaleId => {
                        const currentTranslation = landingPage.translations.find(t => t.language_id === mapLocaleId);
                        
                        return <Form
                           id={mapLocaleId.toString()}
                           labelCol={{
                           span: 2,
                              }}
                           wrapperCol={{
                              span: 14,
                           }}
                           layout="horizontal"
                           style={mapLocaleId===localeId?{display:'block'}:{display:'none'}}
                           onFieldsChange={(_, allFields) => {
                              if(_ && _.length){
                              const dataIndex = _[0].name[0];
                              const value = _[0].value;
                              dispatch(changeTranslatedName(landingPage._id,mapLocaleId,dataIndex,value ));
                              } 
                        }}
                        >
                           <Form.Item 
                                    label="Active"
                                    name="active"
                                    initialValue={currentTranslation?currentTranslation.active:true}
                                    rules={[]}
                              >
                                    <Switch defaultChecked={currentTranslation?currentTranslation.active:true}/>
                           </Form.Item>
                           <Form.Item 
                                    label="Title"
                                    name="title"
                                    initialValue={currentTranslation?currentTranslation.title:""}
                                    rules={[]}
                              >
                                    <Input />
                           </Form.Item>
                           <Form.Item 
                                    label="Header"
                                    name="header"
                                    initialValue={currentTranslation?currentTranslation.header:""}
                                    rules={[]}
                              >
                                    <Input />
                           </Form.Item>
                           <Form.Item 
                                    label="Short description"
                                    name="short_description"
                                    initialValue={(currentTranslation && currentTranslation.short_description)?currentTranslation.short_description:""}
                                    rules={[]}
                           >       
                                    <ReactQuill theme="snow"/>
                           </Form.Item>
                           <Form.Item 
                                    label="Description"
                                    name="description"
                                    initialValue={(currentTranslation && currentTranslation.description)?currentTranslation.description:""}
                                    rules={[]}
                           >       
                                    <ReactQuill theme="snow"/>
                           </Form.Item>
                        </Form>
                     })}
                  </LandingPageBlockTranslations>
                  <Divider orientation="left" plain>Výrobcovia<PlusOutlined onClick={() => onClickAddManufacturer(landingPage._id)}/> </Divider>
                  <LandingPageBlock>
                     {landingPage.manufacturers.map(manufacturer => {
                        return <LpRow> 
                           <LpColumn> {manufacturer.name} </LpColumn>      
                           <LpColumn> <Button onClick={() => onClickRemoveManufacturer(landingPage._id,manufacturer.manufacturer_id)} icon={<DeleteOutlined/>}></Button> </LpColumn>
                        </LpRow>
                     })}
                  </LandingPageBlock>

                  <Divider orientation="left" plain>Kategórie<PlusOutlined onClick={() => onClickAddCategory(landingPage._id)}/> </Divider>
                  <LandingPageBlock>
                     {landingPage.categories.map(category => {
                        return <LpRow> 
                           <LpColumn> {category.name} </LpColumn>      
                           <LpColumn> <Button onClick={() => onClickRemoveCategory(landingPage._id,category.category_id) } icon={<DeleteOutlined/>}></Button> </LpColumn>
                        </LpRow> 
                     })}
                  </LandingPageBlock>

                  <Divider orientation="left" plain>Parametre<PlusOutlined onClick={() => onClickAddParameter(landingPage._id)}/> </Divider>
                  <LandingPageBlock>
                     {landingPage.parameters.map(parameter => {
                        return <LpRow> 
                           <LpColumn> {parameter.name}({parameter.value_name}) </LpColumn>     
                           <LpColumn> <Button onClick={() => onClickRemoveParameter(landingPage._id,parameter.parameter_id,parameter.parameter_value_id)} icon={<DeleteOutlined/>}></Button> </LpColumn> 
                        </LpRow> 
                     })}
                  </LandingPageBlock>

                  <Divider orientation="left" plain>Cena</Divider>
                  <LandingPageBlock>
                     <div>
                     <Form
                        onFieldsChange={(_, allFields) => {
                           if(_ && _.length){
                           const dataIndex = _[0].name[0];
                           const value = _[0].value;
                              dispatch(updateLangingPageField(landingPage._id,dataIndex,value ));
                           }
                        }} 
                     >
                        <Form.Item 
                                       label="Cena od"
                                       name="price_from"
                                       initialValue={landingPage.price_from}
                                       rules={[]}
                                 >
                                       <InputNumber/>
                        </Form.Item>

                        <Form.Item 
                                       label="Cena do"
                                       name="price_to"
                                       initialValue={landingPage.price_to}
                                       rules={[]}
                                 >
                                       <InputNumber />
                        </Form.Item>

                     </Form>
                     </div>
                  </LandingPageBlock>

                  <Divider orientation="left" plain>Sorting</Divider>
                  <SortLandingPageBlock>
                     <ConnectedSingleSelect
                        value={landingPage.sort.type}
                        data={[
                           {
                              name: __("Žiadny"),
                              value: null
                           },
                           {
                              name: __("Obľubenosti"),
                              value: "FAVORITES"
                           },
                           {
                              name: __("Najnižšej ceny"),
                              value: "PRICE_ASC"
                           },
                           {
                              name: __("Najvyššej ceny"),
                              value: "PRICE_DESC"
                           }
                        ]}
                        selectWidth={rem(200)}
                        onSelect={(value) => {
                           dispatch(setSorting(landingPage._id,value))
                        }}
                     />
                  </SortLandingPageBlock>

               </LandingPageWrapper>
               </Collapse.Panel>
            </CustomCollapse> 
         })}
      </MainSection>

      {isManufacturerSelectModalActive && 
         <ManufacturerSelectModal  
            title="Výber výrobcu"
            onOk={manufacturerId => {
               if(manufacturerId){
                  dispatch(addManufacturer(currentLandingPageId,manufacturerId));
                  setIsManufacturerSelectModalActive(false)
               }
            }}
            onCancel={() => {
               setIsManufacturerSelectModalActive(false)
            }}
         />
      }

      {isCategorySelectModalActive &&  
         <CategorySelectModal 
            title="Výber kategórie"
            onOk={categoryId => {
               if(categoryId){
                  dispatch(addCategory(currentLandingPageId,categoryId))
               }
            }}
            onCancel={() => {
               setIsCategorySelectModalActive(false)
            }}
         />
      }

      {isParameterSelectModalActive && 
         <ParameterValueSelectModal
            title="Výber parametru"
            onOk={(parameterId,parameterValueId) => {
               if(parameterId && parameterValueId){
                  dispatch(addParameter(currentLandingPageId,parameterId,parameterValueId))
                  setIsParameterSelectModalActive(false)
               }
            }}
            onCancel={() => {
               setIsParameterSelectModalActive(false)
            }}
         />
      } 



   </>
}

export default CategoryLandingPages