import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState, AppThunk } from "../../store";
import API from "../../services/API";
import { tokenSelector } from "../../containers/App/slice";

const NAMESPACE = 'widgetTagsSettings';

interface WidgetTagsSettingsState {
    loading: boolean,
    selectedTags: string[],
    availableTags: string[]
}

const initialState : WidgetTagsSettingsState = {
    loading: false,
    selectedTags: [],
    availableTags: []
}

export const widgetTagsSettingsSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setAvailableTags: (state, action: PayloadAction<Array<string>>) => {
            state.availableTags = action.payload
        },
        setSelectedTags: (state, action: PayloadAction<Array<string>>) => {
            state.selectedTags = action.payload
        },
        // -------------------------------------------------
        resetState: state => initialState
    }
});

export const loadTags = (widgetId: string): AppThunk => async (dispatch, getState) => {
    dispatch(widgetTagsSettingsSlice.actions.setLoading(true));

    const token = tokenSelector(getState());
    API.setToken(token);
    
    if(widgetId){
        API.loadWidgets({
            widgetIds: [widgetId]
        }).then(response => {
           const widget = response.items[0];
           if(widget){
                API.loadWidgetsTags({
                    affiliateId:widget.affiliate_id
                }).then(response => {
                    dispatch(widgetTagsSettingsSlice.actions.setLoading(false));      
                    dispatch(widgetTagsSettingsSlice.actions.setAvailableTags( response.tags.map(tag => tag.name ) ))
                    dispatch(widgetTagsSettingsSlice.actions.setSelectedTags(widget.tags)) 
                })
           }
        }) 
    }
}

export const updateWidgetTags = (widgetId,tags:Array<string>): AppThunk => async (dispatch, getState) => {
    dispatch(widgetTagsSettingsSlice.actions.setLoading(true));
    
    const token = tokenSelector(getState());
    API.setToken(token);
    
    API.updateWidgetTags(widgetId,{},{
        tags: tags
    }).then(response => {
        dispatch(setSelectedTags(tags));
        dispatch(widgetTagsSettingsSlice.actions.setLoading(false));    
    })
}

export const setSelectedTags = (tags:Array<string>): AppThunk => async (dispatch, getState) => {
    dispatch(widgetTagsSettingsSlice.actions.setSelectedTags(tags));      
}

export const availableTagsSelector = (state: ApplicationState) => state.widgetTagsSettings.availableTags;
export const selectedTagsSelector = (state: ApplicationState) => state.widgetTagsSettings.selectedTags;

export default widgetTagsSettingsSlice.reducer;