import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, ApplicationState } from "../../store";
import API from "../../services/API";
import { tokenSelector } from "../App/slice";
import moment from "moment";

const NAMESPACE = 'CMSStats';

interface GeneralStats {
  products?: number,
  goods?: number,
  paying_shops?: number,
  active_shops?: number
}

interface IntervalStats {
  products?: number,
  goods?: number,
  paying_shops?: number,
  active_shops?: number
}

interface User {
  user_id?: number,
  goods?: number,
  products?: number,

}

interface CMSStatsState {
  activeFilters: {
    startDate: moment.Moment;
    endDate: moment.Moment
  },
  filtersData: {
    generalStats: {
      isFetching: boolean,
      data: any
    }
  },
  generalStats: GeneralStats
  intervalStats: IntervalStats
  users: User[]
}

const initialState : CMSStatsState = {
  activeFilters: {
    startDate: moment().subtract(7,"days"),
    endDate: moment(),
  },
  filtersData: {
    generalStats: {
      isFetching: false,
      data: []
    }
  },
  generalStats: {
    products: 0,
    goods: 0,
    paying_shops: 0,
    active_shops: 0
  },
  intervalStats: {
    products: 0,
    goods: 0,
    paying_shops: 0,
    active_shops: 0
  },
  users:[]
}

export const CMSStatsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
      state.filtersData[action.payload.name].isFetching = action.payload.value;
    },
    setGeneralStats: (state,action:PayloadAction<GeneralStats>) => {
      state.generalStats = action.payload
    },
    setIntervalStats: (state,action:PayloadAction<GeneralStats>) => {
      state.intervalStats = action.payload
    },
    setUsersStats: (state,action:PayloadAction<User[]>) => {
      state.users = action.payload
    },
    setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
      state.filtersData[action.payload.name].data = action.payload.value;
    },
    setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | number | null | moment.Moment}>) => {
      state.activeFilters[action.payload.name] = action.payload.value;
    },
    resetState: state => initialState
  }
});

export const loadGeneralStats = () : AppThunk => async (dispatch, getState) => {

  const token = tokenSelector(getState());
  API.setToken(token);

  const apiResponse  = await API.generalStats()
  dispatch(CMSStatsSlice.actions.setGeneralStats(apiResponse))
}

export const loadUsersStats = () : AppThunk => async (dispatch, getState) => {
  const token = tokenSelector(getState());
  API.setToken(token);

  const apiResponse  = await API.userStats()

  dispatch(CMSStatsSlice.actions.setUsersStats(apiResponse))

}
export const loadStatsDiff = () : AppThunk => async (dispatch, getState) => {
  const startDate = startDateActiveFiltersSelector(getState());
  const endDate = endDateActiveFiltersSelector(getState());

  const token = tokenSelector(getState());
  API.setToken(token); 

  const apiResponse  = await API.statsDiff({startDate:startDate.format(),endDate:endDate.format()});

  dispatch(CMSStatsSlice.actions.setUsersStats(apiResponse.users));
  dispatch(CMSStatsSlice.actions.setIntervalStats(apiResponse.general));
}

export const setFilter = (filter: string, value: number[] | string | number | null) => (dispatch, getState) => {
  dispatch(CMSStatsSlice.actions.setActiveFilterValue({name: filter, value}));
}

export const resetState = () => (dispatch,getState) => {
  dispatch(CMSStatsSlice.actions.resetState());
}

export const startDateActiveFiltersSelector = (state: ApplicationState) => state.CMSStats.activeFilters.startDate;
export const endDateActiveFiltersSelector = (state: ApplicationState) => state.CMSStats.activeFilters.endDate;
export const generalStatsSelector = (state: ApplicationState) => state.CMSStats.generalStats;
export const intervalStatsSelector = (state: ApplicationState) => state.CMSStats.intervalStats;
export const userStatsSelector = (state: ApplicationState) => state.CMSStats.users;

export default CMSStatsSlice.reducer;