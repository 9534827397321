import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Modal, PageHeader, Radio, Row, Switch, Upload, Form } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import CategoryLandingPages from "../../components/CategoryLandingPages/CategoryLandingPages";
import CategoryListingLinks from "../../components/CategoryListingLinks/CategoryListingLinks";
import { updateListingLinks } from "../../components/CategoryListingLinks/slice";
import CategoryParameters from "../../components/CategoryParameters/CategoryParameters";
import { updateLandingPages, updateParameters } from "../../components/CategoryParameters/slice";
import { ApplicationState } from "../../store";
import { __ } from "../../utilities";
import ReactQuill from 'react-quill';
import { loadCategory, localeIdSelector, setLocaleId,categorySelector,uploadingImageSelector,uploadNewCategoryImage, changePnPairingEnabled, updateSettings, changeTranslatedName} from "./slice";

const MainContent = styled.div`
  margin-top: 25px;
  padding: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`
const ImageWrapper = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const StyledImg = styled.img`
  width: 130px;
`;

const StyledCol = styled(Col)`
  padding-bottom: 25px;
  margin-right: 10px;
`;

const CategoryDetail = ({categoryId}) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        async function init() {
          await dispatch(loadCategory(categoryId))
        }
        init();
    }, [dispatch, categoryId]);
    
    const localeId = useSelector((state: ApplicationState) =>
        localeIdSelector(state)
    );
    const category = useSelector((state: ApplicationState) =>
        categorySelector(state)
    );

    const onLocaleClick = (e) => {
        dispatch(setLocaleId(e.target.value))
    }

    const history = useHistory();
    function handleNavigation() {
        history.push('/category-tree');
    }

    const onSaveClick = () => {
        //dispatch(updateCategory());
        dispatch(updateSettings());
        dispatch(updateParameters());
        dispatch(updateLandingPages());
        dispatch(updateListingLinks());
    }

    const uploadingImage = useSelector((state: ApplicationState) =>
    uploadingImageSelector(state)
    );

    const [imageState, imageStateAction] = useState({
        modalVisible: false,
        fileList: [],
      });

    const showModal = () => {
        imageStateAction({
          ...imageState,
          modalVisible: !imageState.modalVisible,
        });
      };

    const uploadProps = {
        onRemove: file => {
          imageStateAction(state => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
              ...state,
              fileList: newFileList,
            };
          });
        },
        beforeUpload: file => {
          imageStateAction(state => ({
            ...state,
            fileList: [...state.fileList, file],
          }));
          return false;
        },
    };
    
    const handleUpload = async () => {
        const { fileList } = imageState;
        const file = fileList[0];
        const data = { file: file };
        await dispatch(uploadNewCategoryImage(data));
        if (!uploadingImage) {
          imageStateAction({
            ...imageState,
            modalVisible: false,
            fileList: [],
          });
        }
    };

    const onChangePnPairingEnabled = async (pnPairingEnabled) => {
      dispatch(changePnPairingEnabled(pnPairingEnabled))
    }
    
    return <> 
        <PageHeader 
            title = {"Detail kategórie"}
            ghost={false}
            subTitle={category ? category.name : ''}
            onBack={handleNavigation}
            extra={[
                <Button key="saveButton" onClick={onSaveClick}> 
                    Uložiť 
                </Button>,
                <Radio.Group
                    key="selectLocale"
                    value={localeId}
                    buttonStyle="solid"
                    style={{ width: '100%' }}
                    onChange={onLocaleClick}
                >
                    <Radio.Button value={1}>SK</Radio.Button>
                    <Radio.Button value={2}>CZ</Radio.Button>
                </Radio.Group>
            ]}
        /> 

        <MainContent>
            <CategoryParameters categoryId={categoryId} defaultLocaleId={localeId} />
        </MainContent>

        <MainContent>
            <CategoryLandingPages categoryId={categoryId} defaultLocaleId={localeId} />
        </MainContent>

        <MainContent>
            <CategoryListingLinks categoryId={categoryId} defaultLocaleId={localeId} />
        </MainContent>

        <MainContent>
            <Row>
            <ImageWrapper span={6}>
            {category && (
              <StyledImg
                src={`https://y1.sk/t/c/200x200/${
                    category.category_id
                }.jpg?v=${new Date().getTime()}`}
              />
            )}
            <Button
              style={{ marginTop: 40 }}
              type="default"
              onClick={showModal}
            >
              Zmena loga
            </Button>
          </ImageWrapper>
            </Row>
        </MainContent>

        <MainContent>
            <Row justify={'start'}>
              <h2>{__("Texty")}</h2>
            </Row>
            {category.category_id? 
              [1,2].map(mapLocaleId => {
                const currentTranslation = category.translations.find(t => t.language_id === mapLocaleId);

                return <Form
                   id={mapLocaleId.toString()}
                   labelCol={{
                   span: 2,
                      }}
                   wrapperCol={{
                      span: 14,
                   }}
                   layout="horizontal"
                   style={mapLocaleId===localeId?{display:'block'}:{display:'none'}}
                   onFieldsChange={(_, allFields) => {
                      if(_ && _.length){
                      const dataIndex = _[0].name[0];
                      const value = _[0].value;
                      dispatch(changeTranslatedName(mapLocaleId,dataIndex,value ));
                      } 
                }}
                > 
                  <Form.Item 
                            label="Krátky popis"
                            name="short_description"
                            initialValue={(currentTranslation && currentTranslation.short_description)?currentTranslation.short_description:""}
                            rules={[]}
                   >       
                            <ReactQuill theme="snow"/>
                  </Form.Item>

                  <Form.Item 
                            label="Popis"
                            name="description"
                            initialValue={(currentTranslation && currentTranslation.description)?currentTranslation.description:""}
                            rules={[]}
                   >       
                            <ReactQuill theme="snow"/>
                   </Form.Item>

                </Form>
              })
            :""}
        </MainContent>

        <MainContent>
          <Row justify={'start'}>
              <h2>{__("Nastavenia")}</h2>
          </Row>
          <Row justify={'start'}>
            <StyledCol>
              {__("Párovanie podľa PN")}:
            </StyledCol>
            <StyledCol>
              <Switch checked={category && category.pn_pairing_enabled?true:false} onChange={onChangePnPairingEnabled} />
            </StyledCol>
          </Row>
        </MainContent>

        <Modal
        visible={imageState.modalVisible}
        title="Nastavenie nového loga eshopu"
        onCancel={showModal}
        footer={[
          <Button key="back" onClick={showModal}>
            Späť
          </Button>,
          <Button
            key="upload"
            type="primary"
            onClick={handleUpload}
            disabled={imageState.fileList.length === 0}
            loading={uploadingImage}
            style={{ marginTop: 16 }}
          >
            {uploadingImage ? 'Nahrávanie' : 'Nahrať'}
          </Button>,
        ]}
      >
        <ImageWrapper>
          {' '}
          <Upload
            {...uploadProps}
            accept={'image/png, image/jpeg'}
          >
            <Button  icon={<UploadOutlined />} disabled={imageState.fileList.length === 1}>Vybrať logo</Button>
          </Upload>
        </ImageWrapper>
      </Modal>
    </>
}

export default CategoryDetail