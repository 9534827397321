import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {Error404} from '../containers/Alerts/Alerts';
import ProductList from '../containers/ProductList/ProductList';
import ProductDetail from '../containers/ProductDetail/ProductDetail';
import ProductSearch from '../containers/ProductSearch/ProductSearch';
import AutomaticPairingProduct from '../containers/AutomaticPairingProduct/AutomaticPairingProduct';

import {prop} from "../utilities";
import FeedReport from '../containers/FeedReport/FeedReport';
import MappingManufacturers from "../containers/MappingManufacturers/MappingManufacturers";
import MappingCategories from "../containers/MappingCategories/MappingCategories";
import ProductCreate from '../containers/ProductCreate/ProductCreate';
import AutomaticPairing from '../containers/AutomaticPairing/AutomaticPairing';
import UsersList from "../containers/UsersList/UsersList";
import {useSelector} from "react-redux";
import {ApplicationState} from "../store";
import {tokenInfoSelector} from "../containers/App/slice";
import ChangePassword from "../containers/ChangePassword/ChangePassword";
import WidgetCreate from "../containers/WidgetCreate/WidgetCreate";
import AffilStats from '../containers/AffilStats/AffilStats';
import AffilSettings from "../containers/AffilSettings/AffilSettings";
import Dashboard from '../containers/Dashboard/Dashboard';
import CMSStats from "../containers/CMSStats/CMSStats";
import WidgetDetail from '../containers/WidgetDetail/WidgetDetail';
import AffilStatsPublishers from '../containers/AffilStatsPublishers/AffilStatsPublishers';
import Top50 from "../containers/Top50/Top50";
import UniqueProducts from "../containers/UniqueProducts/UniqueProducts";
import ProductCreateBulk from '../containers/ProductCreateBulk/ProductCreateBulk';
import ParameterDetail from '../containers/ParameterDetail/ParameterDetail';
import ParameterList from '../containers/ParameterList/ParameterList';
import CategoryDetail from '../containers/CategoryDetail/CategoryDetail';
import ShopList from '../containers/ShopList/ShopList';
import ShopDetail from '../containers/ShopDetail/ShopDetail';
import ScheduledTasksList from '../containers/ScheduledTasksList/ScheduledTasksList';
import CategoryTree from '../containers/CategoryTree/CategoryTree';
import CategoryList from "../containers/CategoryList/CategoryList";
import ProductParameterBulkEdit from '../containers/ProductParametersBulkEdit/ProductParametersBulkEdit';
import ParameterFeedMapping from '../containers/ParameterFeedMapping/ParameterFeedMapping';
import ProductStatsList from '../containers/ProductStatsList/ProductStatsList';
import MergeProducts from '../containers/MergeProducts/MergeProducts';
import ParameterIcecatMapping from '../containers/ParameterIcecatMapping/ParameterIcecatMapping';
import WebWidget from '../containers/WebWidget/WebWidget';
import WebWidgetDetail from '../containers/WebWidget/WebWidgetDetail';
import ManufacturersList from "../containers/ManufacturersList/ManufacturersList"
import ManufacturerDetail from "../containers/ManufacturersList/ManufacturerDetail/ManufacturerDetail"
import TopCategories from '../containers/TopCategories/TopCategories';

export default () => {
  const tokenInfo = useSelector((state: ApplicationState) => tokenInfoSelector(state));

  //funkcia pre pridanie routy ktoru je mozne zobrazit iba pouzivatelom prihlasenym ako ADMIN v opacnom pripade
  // presmerujeme na hlavnu stranku
  
  const protectedRoute = (roles:Array<string>,exact:boolean,path:string, component:any) => {
    return (
      (tokenInfo && roles.includes(tokenInfo.role))
        ?
          <Route exact={exact} path={path} component={component}/>
        :
          null
    )
  }

  return (
    <>
      <Switch>
        <Route exact={true} path="/">
          <Dashboard/>
        </Route>
        
              <Route exact={true} path="/produkty">
                <ProductList/>
              </Route>
              <Route exact={true} path="/search">
                <ProductSearch/>
              </Route>
              <Route exact={true} path="/feed_report">
                <FeedReport/>
              </Route>
              <Route exact={true} path="/automatic_pairing_product">
                <AutomaticPairingProduct/>
              </Route>
              <Route exact={true} path="/automatic_pairing">
                <AutomaticPairing/>
              </Route>
              <Route exact={true} path="/mapping/manufacturers">
                <MappingManufacturers/>
              </Route>
              <Route exact={true} path="/mapping/categories">
                <MappingCategories/>
              </Route>
              <Route exact={true} path="/product/create">
                <ProductCreate/>
              </Route>
              <Route exact={true} path="/unique_products">
                <UniqueProducts/>
              </Route>
              <Route exact={true} path="/product/bulk_create">
                <ProductCreateBulk/>
              </Route>
              <Route exact={true} path="/product/merge_products">
                <MergeProducts/>
              </Route>
              <Route exact={true} path={"/top50"}>
                <Top50></Top50>
              </Route>
              <Route exact={true} path={"/top-categories"}>
                <TopCategories></TopCategories>
              </Route>
              <Route exact={true} path={"/product/stats"}>
                <ProductStatsList></ProductStatsList>
              </Route>
          
        <Route exact={true} path="/zmenahesla">
          <ChangePassword/>
        </Route>
        <Route exact={true} path="/produkt-detail/:productId"
               render={({match}) => <ProductDetail productId={prop(match, 'params.productId')}/>}/>
        
        <Route exact={true} path="/widgets/create">
          <WidgetCreate />
        </Route>

        <Route exact={true} path="/widgets/detail/:widgetId"
          render={({match}) => <WidgetDetail widgetId={prop(match, 'params.widgetId')}/>}/>
       
        <Route exact={true} path="/affil/stats">
          <AffilStats />
        </Route>

        <Route exact={true} path="/affil-settings"
                       render={({match}) => <AffilSettings/>}
        ></Route>

        <Route exact={true} path="/parameter/detail/:parameterId"
               render={({match}) => <ParameterDetail parameterId={prop(match, 'params.parameterId')}/>}/>
        
        <Route exact={true} path="/parameters/list"
                       render={({match}) => <ParameterList/>}
        ></Route>


        <Route exact={true} path="/category/detail/:categoryId"
               render={({match}) => <CategoryDetail categoryId={prop(match, 'params.categoryId')}/>}/>

        <Route exact={true} path="/shops"
               render={({match}) => <ShopList/>}
        ></Route>

        <Route exact={true} path="/shop/detail/:shopId"
               render={({match}) => <ShopDetail shopId={prop(match, 'params.shopId')}/>}/>
        
        <Route exact={true} path="/scheduled_tasks"
               render={({match}) => <ScheduledTasksList/>}
        ></Route>
        
        <Route exact={true} path="/category-tree"
               render={({match}) => <CategoryTree/>}
        ></Route>
        <Route exact={true} path="/category-list"
               render={({match}) => <CategoryList/>}
        ></Route>

        <Route exact={true} path="/parameters/product-bulk-edit"
               render={({match}) => <ProductParameterBulkEdit/>}
        ></Route>

        <Route exact={true} path="/parameters/feed-mapping"
               render={({match}) => <ParameterFeedMapping/>}
        ></Route>

        <Route exact={true} path="/parameters/icecat-mapping"
               render={({match}) => <ParameterIcecatMapping/>}
        ></Route>

        <Route exact={true} path="/web-widgets"
               render={({match}) => <WebWidget/>}
        ></Route>
         <Route exact={true} path="/web-widgets/:id"
               render={({match}) => <WebWidgetDetail id={prop(match, 'params.id')} />}
        ></Route>
         <Route exact={true} path="/manufacturers-list"
               render={({match}) => <ManufacturersList  />}
        ></Route>
               <Route exact={true} path="/manufacturers-list/:id"
               render={({match}) => <ManufacturerDetail id={prop(match, 'params.id')} />}
        ></Route>

        {protectedRoute(['ADMIN'],true,"/affil/stats/publishers",AffilStatsPublishers)}
        {protectedRoute(['ADMIN'],true,"/users",UsersList)}
        {protectedRoute(["ADMIN","PAROVAC"],true,"/cms-stats",CMSStats)}
        <Route>
          <Error404/>
        </Route>
      </Switch>
    </>
  );
};
