import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, ApplicationState } from "../../store";
import API from "../../services/API";
import { message } from "antd";
import { chunk } from "../../utilities";

const NAMESPACE = 'parameterIcecatMapping';

interface ParameterIcecatMappingNumberParameter{
    icecat_id: string | number;
    parameter_id: string;
    name: string;
}

interface ParameterIcecatMappingValueParameterValue{
    icecat_id: string | number;
    parameter_value_id: string;
    name: string;
}

interface ParameterIcecatMappingValueParameter{
    icecat_id: string | number;
    parameter_id: string;
    name: string;
    values: ParameterIcecatMappingValueParameterValue[]
}

interface ParameterIcecatMappingCategory {
    category_id: number,
    name: string;
}

interface ParameterIcecatMapping {
    _id: string;
    categories: ParameterIcecatMappingCategory[];
    value_parameters: ParameterIcecatMappingValueParameter[];
    number_parameters: ParameterIcecatMappingNumberParameter[];
    create_parameters_enabled: boolean;
}

interface ParameterIcecatMappingState {
    filterResults: {
        offset: number,
        isFetching: boolean,
        total: number;
        icecatParameterMappings: ParameterIcecatMapping[]
    },
}

const initialState : ParameterIcecatMappingState = {
    filterResults: {
        offset: 0,
        isFetching: false,
        total: 0,
        icecatParameterMappings: []
    },
}

export const parameterIcecatMappingSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setIcecatParameterMappings: (state, action: PayloadAction<{icecatParameterMappints: ParameterIcecatMapping[]}>) => {
            state.filterResults.icecatParameterMappings = action.payload.icecatParameterMappints
        },
        removeNumberParameter: (state, action: PayloadAction<{icecatCategoryMappingId, parameterId}>) => {
            state.filterResults.icecatParameterMappings = state.filterResults.icecatParameterMappings.map(o => {
                return o._id !== action.payload.icecatCategoryMappingId?o:{...o,number_parameters: o.number_parameters.filter(np => np.parameter_id !== action.payload.parameterId)}; 
            });
        },
        removeValueParameter: (state, action: PayloadAction<{icecatCategoryMappingId, parameterId, parameterValueId}>) => {
            state.filterResults.icecatParameterMappings = state.filterResults.icecatParameterMappings.map(o => {
                return o._id !== action.payload.icecatCategoryMappingId?o:{...o,value_parameters: o.value_parameters.map(vp => {
                    return {...vp,values: vp.values.filter(vpv => vpv.parameter_value_id !== action.payload.parameterValueId)}
                })}
            })
        },
        removeCategory: (state, action: PayloadAction<{icecatCategoryMappingId, categoryId:number}>) => {
            state.filterResults.icecatParameterMappings = state.filterResults.icecatParameterMappings.map(o => {
                return o._id !== action.payload.icecatCategoryMappingId?o:{...o,categories: o.categories.filter(c => c.category_id !== action.payload.categoryId)}
            })
        },
        addCategory: (state, action: PayloadAction<{icecatCategoryMappingId, categoryId:number}>) => {
            state.filterResults.icecatParameterMappings = state.filterResults.icecatParameterMappings.map(o => {
                return o._id !== action.payload.icecatCategoryMappingId?o:{...o,categories: [...o.categories,{ category_id: action.payload.categoryId,name:""}]}
            })
        },
        addValueParameter: (state, action: PayloadAction<{icecatCategoryMappingId, parameterId:string,parameterValueId:string}>) => {
            state.filterResults.icecatParameterMappings = state.filterResults.icecatParameterMappings.map(o => {
                if(o._id === action.payload.icecatCategoryMappingId){
                    let valueParameter:ParameterIcecatMappingValueParameter = o.value_parameters.find(vp => vp.parameter_id === action.payload.parameterId);
                    
                    if(!valueParameter){
                        valueParameter = {
                            icecat_id: null,
                            parameter_id: action.payload.parameterId,
                            name: "",
                            values: []
                        }
                        o.value_parameters.push(valueParameter)
                    }

                    valueParameter.values.push({icecat_id: null,name:"",parameter_value_id:action.payload.parameterValueId});
                }
                
                return o;
            });
        },
        addNumberParameter: (state, action: PayloadAction<{icecatCategoryMappingId, parameterId:string}>) => {
            state.filterResults.icecatParameterMappings = state.filterResults.icecatParameterMappings.map(o => {
                return o._id !== action.payload.icecatCategoryMappingId?o:{...o,number_parameters:[...o.number_parameters,{
                    icecat_id: null, 
                    name: "",
                    parameter_id: action.payload.parameterId
                }]}
            })
        },
        updateIcecatIdValueParameter: (state, action: PayloadAction<{icecatCategoryMappingId, icecatId, parameterId, parameterValueId}>) => {
            const ipm = state.filterResults.icecatParameterMappings.find(o => o._id === action.payload.icecatCategoryMappingId);
            if(ipm){
                const impps = ipm.value_parameters.filter(o => o.parameter_id === action.payload.parameterId);
                for(const impp of impps){
                    impp.icecat_id = action.payload.icecatId
                }
            }
        },
        updateIcecatIdValueParameterValue: (state, action: PayloadAction<{icecatCategoryMappingId, icecatId, parameterId, parameterValueId}>) => {
            const ipm = state.filterResults.icecatParameterMappings.find(o => o._id === action.payload.icecatCategoryMappingId);
            if(ipm){
                const impps = ipm.value_parameters.filter(o => o.parameter_id === action.payload.parameterId);
                for(const impp of impps){
                    const imppvss = impp.values.filter(o => o.parameter_value_id === action.payload.parameterValueId);
                    for(const imppvs of imppvss){
                        imppvs.icecat_id = action.payload.icecatId
                    }
                }
            }
        },
        updateIcecatIdNumberParameter: (state, action: PayloadAction<{icecatCategoryMappingId, icecatId, parameterId}>) => {
            const ipm = state.filterResults.icecatParameterMappings.find(o => o._id === action.payload.icecatCategoryMappingId);
            if(ipm){
                const ipmnps = ipm.number_parameters.filter(o => o.parameter_id === action.payload.parameterId);
                for(const ipmnp of ipmnps){
                    ipmnp.icecat_id = action.payload.icecatId
                }
            }
        },
        updateCreateParametersEnabled: (state, action: PayloadAction<{icecatCategoryMappingId, value}>) => {
            const ipm = state.filterResults.icecatParameterMappings.find(o => o._id === action.payload.icecatCategoryMappingId);
            if(ipm){
                ipm.create_parameters_enabled = action.payload.value;
            }
        },
        updateNames: (state, action: PayloadAction<{parameters, categories}>) => {
            const parameters = action.payload.parameters;
            const categories = action.payload.categories;

            for(const icecatParameterMapping of state.filterResults.icecatParameterMappings){
                for(const valueParameter of icecatParameterMapping.value_parameters){
                    if(!valueParameter.name){
                        const cp = parameters.find(p => p._id === valueParameter.parameter_id);
                        if(cp){
                            valueParameter.name = cp.internal_name
                        }
                    }
                    for(const value of valueParameter.values){
                        if(!value.name){
                            const cp = parameters.find(p => p._id === valueParameter.parameter_id);
                            if(cp){
                                const cpv = cp.values.find(cpValue => cpValue._id === value.parameter_value_id);
                                if(cpv){
                                    value.name = cpv.internal_name
                                }
                            }
                        }
                    }
                }

                for(const numberParameter of icecatParameterMapping.number_parameters){
                    if(!numberParameter.name){
                        const cp = parameters.find(p => p._id === numberParameter.parameter_id);
                        if(cp){
                            numberParameter.name = cp.internal_name
                        }
                    }
                } 

                for(const category of icecatParameterMapping.categories){
                    if(!category.name){
                        const currentCategory = categories.find(c => c.category_id === category.category_id);
                        if(currentCategory){
                            let categoryName = "";
                            if(currentCategory && currentCategory.translations && currentCategory.translations.length){
                                const categoryTranslation = currentCategory.translations.find(translation => translation.language_id === 1);
                                if(categoryTranslation){
                                    categoryName = categoryTranslation.name;
                                }
                            } 

                            category.name = categoryName ;
                        }
                    }
                }

            }
        }
    }
});


export const loadIcecatMappings = () : AppThunk => async (dispatch, getState) => {
    API.loadParameterIcecatMappings().then(response => {

        const lState: ParameterIcecatMapping[] = response.items.map(item => {
            return {
                _id: item._id,
                categories: item.category_ids.map(categoryId => {
                    return {
                        category_id: categoryId,
                        name: ""
                    }
                }),
                value_parameters: item.value_parameters.map(valueParameter => {
                    return {
                        icecat_id: valueParameter.icecat_id,
                        parameter_id: valueParameter.parameter_id,
                        name: "",
                        values: valueParameter.values.map(value => {
                            return {
                                icecat_id: value.icecat_id,
                                parameter_value_id: value.parameter_value_id,
                                name: ""
                            }
                        })
                    }
                }),
                number_parameters: item.number_parameters.map(numberParameter => {
                    return {
                        icecat_id: numberParameter.icecat_id,
                        parameter_id: numberParameter.parameter_id,
                        name: ""
                    }
                }),
                create_parameters_enabled: item.create_parameters_enabled?true:false
            }     
        });

        dispatch(parameterIcecatMappingSlice.actions.setIcecatParameterMappings({icecatParameterMappints:lState}))
        dispatch(updateNames());
    })
}

export const addCategory = (icecatCategoryMappingId, categoryId) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterIcecatMappingSlice.actions.addCategory({icecatCategoryMappingId, categoryId}));
    dispatch(updateNames());
}

export const addValueParameter = (icecatCategoryMappingId, parameterId, parameterValueId) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterIcecatMappingSlice.actions.addValueParameter({icecatCategoryMappingId, parameterId, parameterValueId}));
    dispatch(updateNames());
}

export const addNumberParameter = (icecatCategoryMappingId, parameterId) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterIcecatMappingSlice.actions.addNumberParameter({icecatCategoryMappingId, parameterId}));
    dispatch(updateNames());
}

export const removeNumberParameter = (icecatCategoryMappingId, parameterId) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterIcecatMappingSlice.actions.removeNumberParameter({icecatCategoryMappingId, parameterId}));
}

export const removeValueParameter = (icecatCategoryMappingId, parameterId, parameterValueId) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterIcecatMappingSlice.actions.removeValueParameter({icecatCategoryMappingId, parameterId, parameterValueId}));
}

export const removeCategory = (icecatCategoryMappingId, categoryId) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterIcecatMappingSlice.actions.removeCategory({icecatCategoryMappingId, categoryId}));
}

export const updateIcecatIdValueParameter = (icecatCategoryMappingId, icecatId, parameterId, parameterValueId) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterIcecatMappingSlice.actions.updateIcecatIdValueParameter({icecatCategoryMappingId, icecatId, parameterId, parameterValueId}))
}

export const updateIcecatIdValueParameterValue = (icecatCategoryMappingId, icecatId, parameterId, parameterValueId) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterIcecatMappingSlice.actions.updateIcecatIdValueParameterValue({icecatCategoryMappingId, icecatId, parameterId, parameterValueId}))
}

export const updateIcecatIdNumberParameter = (icecatCategoryMappingId, icecatId, parameterId) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterIcecatMappingSlice.actions.updateIcecatIdNumberParameter({icecatCategoryMappingId, icecatId, parameterId}))
}

export const updateCreateParametersEnabled = (icecatCategoryMappingId, value) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterIcecatMappingSlice.actions.updateCreateParametersEnabled({icecatCategoryMappingId,value}));
}

export const updateNames = (): AppThunk => async (dispatch, getState) => {
    const parameterIcecatMappings = parameterIcecatMappingsSelector(getState());
    const parameterIdsToLoad:string[] = [];
    const categoryIdsToLoad: number[] = [];
    
    for(const parameterIcecatMapping of parameterIcecatMappings){
        for(const valueParameter of parameterIcecatMapping.value_parameters){
            if(!valueParameter.name || valueParameter.values.filter(value => !value.name).length) {
                parameterIdsToLoad.push(valueParameter.parameter_id);
            }
        }
        for(const numberParameter of parameterIcecatMapping.number_parameters){
            if(!numberParameter.name){
                parameterIdsToLoad.push(numberParameter.parameter_id);
            }
        }
        for(const category of parameterIcecatMapping.categories){
            if(!category.name){
                categoryIdsToLoad.push(category.category_id);
            }
        }
    }
    

    const loadParametersResponseItems = [];
    const parameterIdsToLoadFiltered = parameterIdsToLoad.filter(function(elem, pos) {return parameterIdsToLoad.indexOf(elem) === pos});
    for(const chunkedpParameterIdsToLoadFiltered of chunk(parameterIdsToLoadFiltered,20)){
        const loadParametersResponse = await API.loadParameters({filterParameterIds:chunkedpParameterIdsToLoadFiltered});
        loadParametersResponseItems.push(...loadParametersResponse.items);
    }
    
    const loadCategoriesResponse = await API.loadCategories({categoryIds:categoryIdsToLoad})

    dispatch(parameterIcecatMappingSlice.actions.updateNames({parameters:loadParametersResponseItems, categories: loadCategoriesResponse.categories}));
}

export const updateIcecatMapping = (icecatMappingId): AppThunk => async (dispatch, getState) => {
    const parameterIcecatMappings = parameterIcecatMappingsSelector(getState());
    const parameterIcecatMapping = parameterIcecatMappings.find(pim => pim._id === icecatMappingId);

    if(parameterIcecatMapping){
        const updateObject = {
            _id: parameterIcecatMapping._id,
            category_ids: parameterIcecatMapping.categories.map(category => {
                return category.category_id
            }),
            value_parameters: parameterIcecatMapping.value_parameters.map(valueParameter => {
                return {
                    icecat_id: valueParameter.icecat_id,
                    parameter_id: valueParameter.parameter_id,
                    values: valueParameter.values.map(value => {
                        return {
                            icecat_id: value.icecat_id,
                            parameter_value_id: value.parameter_value_id
                        }
                    })
                }
            }),
            number_parameters: parameterIcecatMapping.number_parameters.map(numberParameter => {
                return {
                    icecat_id: numberParameter.icecat_id,
                    parameter_id: numberParameter.parameter_id
                }
            }),
            create_parameters_enabled: parameterIcecatMapping.create_parameters_enabled
        }

        API.updateParameterIcecatMapping(icecatMappingId,{},updateObject).then(response => {
            message.success("Mapovanie bolo uložené")
        })

    }
}

export const createIcecatMapping = (): AppThunk => async (dispatch, getState) => {
    await API.createParameterIcecatMappings();
    dispatch(loadIcecatMappings())
}

export const recalculateIcecatMapping = (icecatParameterMappingId:string) => async (dispatch, getState) => {
    await API.createScheduledTask({},{
        type: "ICECAT_PARAMETER_MAPPING",
        data:{
            icecat_parameter_mapping_id: icecatParameterMappingId
        }
    });
    message.success('Úloha bola zadaná do systému');
}

export const parameterIcecatMappingsSelector = (state: ApplicationState) => state.parameterIcecatMapping.filterResults.icecatParameterMappings;

export default parameterIcecatMappingSlice.reducer;