import React, {useState} from 'react';
import {Select, Spin} from "antd";
import {__} from "../../utilities";
import API from "../../services/API";
import {addMappingAction, removeMappingAction} from "./slice";
import {useDispatch} from "react-redux";

const {Option} = Select;

const MappingCategorySelect = (props) => {
  const {category, shop_category, category_mapping_id, shop_id} = props;
  const [value, setValue] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [isFetchingCategoryData, setFetchingCategoryData] = useState(false);
  const [selectUsed, setSelectUsed] = useState(false);
  const dispatch = useDispatch();

  const onSelectCategoryFilter = (value) => {
    setValue(value);
    setSelectUsed(true);

    // pridame akciu do zoznamu. Po stlaceni ulozit sa vsetky akcie vykonaju
    dispatch(removeMappingAction(shop_category));
    dispatch(addMappingAction({
      shop_category: shop_category,
      shop_id: shop_id,
      category_id: value ? value.value : null,
      type: category ? (value ? 'UPDATE' : 'DELETE') : (value ? 'INSERT' : null),
      category_mapping_id: category_mapping_id
    }));
  };

  let categoryValue = [];

  if (category) {
    categoryValue = [{
      key: category.category_id,
      value: category.category_id,
      label: category.parents_names ? [...category.parents_names].reverse().filter((item, index) => index % 2 === 0).join(' < ') : ''
    }]
  }

  const onChangeCategoryFilter = (value) => {
    setFetchingCategoryData(true);

    API.loadCategories({query: value, limit: 100}).then(response => {
      const categoryData = response.categories.map(category => {
        let categoryName;

        // if (category && category.translations && category.translations.length) {
        //   const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
        //   if (categoryTranslation) {
        //     categoryName = categoryTranslation.name;
        //   }
        // }

        categoryName = [...category.parents_names].reverse().filter((item, index) => index % 2 === 0).join(' < ');

        return {
          name: categoryName,
          value: category.category_id
        }
      });

      setCategoryData(categoryData);
      setFetchingCategoryData(false);
    })
  };

  return (
    <Select
      labelInValue
      allowClear={true}
      value={selectUsed ? value : categoryValue}
      placeholder={__('Category')}
      notFoundContent={isFetchingCategoryData ? <Spin size="small"/> : null}
      filterOption={false}
      showSearch
      onChange={onSelectCategoryFilter}
      onSearch={onChangeCategoryFilter}
      style={{width: 600}}
    >
      {categoryData.map(d => (
        <Option value={d.value} key={d.name}>
          {d.name}
        </Option>
      ))}
    </Select>
  )
};

export default MappingCategorySelect;