import { rem } from 'polished';
import React, { useState } from 'react';
import API from '../../services/API';
import ConnectedSingleSelect from './ConnectedSingleSelect';

const ProductSelect = props => {
  const { onSelect, width } = props;

  const [selectedValue, setSelectedValue] = useState(null);
  const [data, setData] = useState([]);

  const onChange = query => {
    API.loadProducts({
      query: query,
      projectType: 'autocomplete',
      limit: 10,
    }).then(response => {
      const data = response.products.map(product => {
        return {
          name: product.name,
          value: product.product_id,
        };
      });
      setData(data);
    });
  };

  const onInternalSelect = selectedValue => {
    setSelectedValue(selectedValue);
    if (onSelect) onSelect(selectedValue);
  };

  return (
    <ConnectedSingleSelect
      value={selectedValue}
      data={data}
      selectWidth={width ? rem(width) : rem(200)}
      onChange={onChange}
      onSelect={onInternalSelect}
    />
  );
};

export default ProductSelect;
