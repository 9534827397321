import React, {useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Login from "../containers/Login/Login";
import App from "../containers/App/App";
import ProtectedRoute, { ProtectedRouteProps } from "./PrivateRoute";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../store";
import {loginUser, tokenInfoSelector, tokenIsFetchingSelector} from "../containers/App/slice";
import * as cookie from 'react-cookies';

const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated: false,
    authenticationPath: '/prihlasenie',
    isAllowed: true
};

export default () => {

  const dispatch = useDispatch();
  useEffect(() => {
    const init = async () => {
      await dispatch(loginUser(cookie.load('USER'), false));
    }
    init();
  }, [dispatch]);


  const tokenInfo = useSelector((state: ApplicationState) =>
    tokenInfoSelector(state)
  );

  const isAppFetching = useSelector((state: ApplicationState) => tokenIsFetchingSelector(state));

  defaultProtectedRouteProps.isAuthenticated = process.env.NODE_ENV === 'development' || tokenInfo !== null;

  if(isAppFetching){
    return null;
  }

    return (
        <Router>
            <Switch>
                <Route exact={true} path="/prihlasenie">
                    <Login />
                </Route>
                <ProtectedRoute
                    isAuthenticated={tokenInfo}
                    {...defaultProtectedRouteProps}
                    exact={true}
                    component={App}
                />
            </Switch>
        </Router>
    );
}
