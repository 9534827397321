import { Card, Row, Col} from 'antd';
import { Line } from '@ant-design/charts';
import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
interface Props {
  generalStatsFething: any;
  generalDailyStats: any;
  generalStats: any;
}
const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

const CustomCard = styled(Card)`
  height: 100%;
`;
const ClickableDiv = styled.div`
  cursor: pointer;
`;

const ProductListCharts = (props: Props) => {
  const { generalStatsFething, generalDailyStats, generalStats } = props;

  const [cpcGraphActiveNumber, setCpcGraphActiveNumber] = useState(1);

  return (
    <>
      <Row justify="start" gutter={[16, 16]}>
        <Col span={12}>
          <CustomCard
            title="CTR"
            bordered={false}
            loading={generalStatsFething}
          >
            <Card.Grid style={{ width: '50%', height: '148px' }}>
              <CardGrid>
                {generalStats ? (
                  <>
                    <div>
                      <div>Celkové CTR:</div>
                    </div>
                    <div>
                      <div>
                        {' '}
                        {generalStats.impressions
                          ? (
                              (generalStats.clicks / generalStats.impressions) *
                              100
                            ).toFixed(2)
                          : 0}{' '}
                        %{' '}
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </CardGrid>
            </Card.Grid>
            <Card.Grid style={{ width: '50%' }}>
              <Line
                {...{
                  data: generalDailyStats
                    ? generalDailyStats.map(generalDailyStat => {
                        return {
                          date: generalDailyStat.date,
                          value: generalDailyStat.impressions
                            ? parseFloat(
                                (
                                  (generalDailyStat.clicks /
                                    generalDailyStat.impressions) *
                                  100
                                ).toFixed(2)
                              )
                            : 0,
                        };
                      })
                    : [],
                  height: 100,
                  autoFit: true,
                  xField: 'date',
                  yField: 'value',
                  smooth: true,
                  padding: 'auto',
                  point: {
                    size: 3,
                    shape: 'circle',
                  },
                  xAxis: {
                    type: 'timeCat',
                    tickCount: 5,
                    label: {
                      formatter: function formatter(v) {
                        return moment(v).format('DD.MM.YYYY');
                      },
                    },
                  },
                  yAxis: {
                    label: {
                      formatter: function formatter(v) {
                        return v + ' %';
                      },
                    },
                  },
                  tooltip: {
                    formatter: (value: any) => {
                      return { name: 'CTR', value: value.value + '%' };
                    },
                  },
                }}
              />
            </Card.Grid>
          </CustomCard>
        </Col>
        <Col span={12}>
          <CustomCard
            title="Obrat"
            bordered={false}
            loading={generalStatsFething}
          >
            <Card.Grid style={{ width: '50%', height: '148px' }}>
              <CardGrid>
                {generalStats ? (
                  <>
                    <div>
                      <ClickableDiv
                        onClick={() => {
                          setCpcGraphActiveNumber(1);
                        }}
                      >
                        Celková hodnota preklikov:
                      </ClickableDiv>
                      <ClickableDiv
                        onClick={() => {
                          setCpcGraphActiveNumber(2);
                        }}
                      >
                        CPC:
                      </ClickableDiv>
                    </div>
                    <div>
                      <div>{generalStats.payment.toFixed(2)} €</div>
                      <div>
                        {generalStats.clicks
                          ? parseFloat(
                              (
                                generalStats.payment / generalStats.clicks
                              ).toFixed(2)
                            )
                          : 0}{' '}
                        €
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </CardGrid>
            </Card.Grid>
            <Card.Grid style={{ width: '50%' }}>
              {cpcGraphActiveNumber === 1 ? (
                <Line
                  {...{
                    data: generalDailyStats
                      ? generalDailyStats.map(generalDailyStat => {
                          return {
                            date: generalDailyStat.date,
                            value: generalDailyStat.payment
                              ? parseFloat(generalDailyStat.payment.toFixed(2))
                              : 0,
                          };
                        })
                      : [],
                    height: 100,
                    autoFit: true,
                    xField: 'date',
                    yField: 'value',
                    smooth: true,
                    padding: 'auto',
                    point: {
                      size: 3,
                      shape: 'circle',
                    },
                    xAxis: {
                      type: 'timeCat',
                      tickCount: 5,
                      label: {
                        formatter: function formatter(v) {
                          return moment(v).format('DD.MM.YYYY');
                        },
                      },
                    },
                    yAxis: {
                      label: {
                        formatter: function formatter(v) {
                          return v + ' €';
                        },
                      },
                    },
                    tooltip: {
                      formatter: (value: any) => {
                        return {
                          name: 'Hodnota preklikov',
                          value: value.value + '€',
                        };
                      },
                    },
                    color: '#3CB371',
                  }}
                />
              ) : null}

              {cpcGraphActiveNumber === 2 ? (
                <Line
                  {...{
                    data: generalDailyStats
                      ? generalDailyStats.map(generalDailyStat => {
                          return {
                            date: generalDailyStat.date,
                            value: generalDailyStat.clicks
                              ? parseFloat(
                                  (
                                    generalDailyStat.payment /
                                    generalDailyStat.clicks
                                  ).toFixed(2)
                                )
                              : 0,
                          };
                        })
                      : [],
                    height: 100,
                    autoFit: true,
                    xField: 'date',
                    yField: 'value',
                    smooth: true,
                    padding: 'auto',
                    point: {
                      size: 3,
                      shape: 'circle',
                    },
                    xAxis: {
                      type: 'timeCat',
                      tickCount: 5,
                      label: {
                        formatter: function formatter(v) {
                          return moment(v).format('DD.MM.YYYY');
                        },
                      },
                    },
                    yAxis: {
                      label: {
                        formatter: function formatter(v) {
                          return v + ' €';
                        },
                      },
                    },
                    tooltip: {
                      formatter: (value: any) => {
                        return { name: 'CPC', value: value.value + '€' };
                      },
                    },
                    color: '#3CB371',
                  }}
                />
              ) : null}
            </Card.Grid>
          </CustomCard>
        </Col>
      </Row>

      <Row justify="start" gutter={[16, 16]}>
        <Col span={12}>
          <CustomCard
            title="Impresie"
            bordered={false}
            loading={generalStatsFething}
          >
            <Card.Grid style={{ width: '50%', height: '148px' }}>
              <CardGrid>
                {generalStats ? (
                  <>
                    <div>
                      <div>Celkový počet impresií:</div>
                    </div>
                    <div>
                      <div>
                        {generalStats.impressions.toLocaleString('sk-SK')}
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </CardGrid>
            </Card.Grid>
            <Card.Grid style={{ width: '50%' }}>
              <Line
                {...{
                  data: generalDailyStats
                    ? generalDailyStats.map(generalDailyStat => {
                        return {
                          date: generalDailyStat.date,
                          value: Number(generalDailyStat.impressions),
                        };
                      })
                    : [],
                  height: 100,
                  autoFit: true,
                  xField: 'date',
                  yField: 'value',
                  smooth: true,
                  padding: 'auto',
                  point: {
                    size: 3,
                    shape: 'circle',
                  },
                  xAxis: {
                    type: 'timeCat',
                    tickCount: 5,
                    label: {
                      formatter: function formatter(v) {
                        return moment(v).format('DD.MM.YYYY');
                      },
                    },
                  },
                  tooltip: {
                    formatter: (value: any) => {
                      return { name: 'Impresie', value: value.value };
                    },
                  },
                  color: '#000000',
                }}
              />
            </Card.Grid>
          </CustomCard>
        </Col>
        <Col span={12}>
          <CustomCard
            title="Prekliky"
            bordered={false}
            loading={generalStatsFething}
          >
            <Card.Grid style={{ width: '50%', height: '148px' }}>
              <CardGrid>
                {generalStats ? (
                  <>
                    <div>
                      <div>Celkový počet preklikov:</div>
                    </div>
                    <div>
                      <div>{generalStats.clicks.toLocaleString('sk-SK')}</div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </CardGrid>
            </Card.Grid>

            <Card.Grid style={{ width: '50%' }}>
              <Line
                {...{
                  data: generalDailyStats
                    ? generalDailyStats.map(generalDailyStat => {
                        return {
                          date: generalDailyStat.date,
                          value: Number(generalDailyStat.clicks),
                        };
                      })
                    : [],
                  height: 100,
                  autoFit: true,
                  xField: 'date',
                  yField: 'value',
                  smooth: true,
                  padding: 'auto',
                  point: {
                    size: 3,
                    shape: 'circle',
                  },
                  xAxis: {
                    type: 'timeCat',
                    tickCount: 5,
                    label: {
                      formatter: function formatter(v) {
                        return moment(v).format('DD.MM.YYYY');
                      },
                    },
                  },
                  tooltip: {
                    formatter: (value: any) => {
                      return { name: 'Prekliky', value: value.value };
                    },
                  },
                  color: '#f6921e',
                }}
              />
            </Card.Grid>
          </CustomCard>
        </Col>
      </Row>

      {/* <Row justify="start" gutter={[16, 16]}>
        <Col span={12}>
          <CustomCard
            title="Ponuky"
            bordered={false}
            loading={generalStatsFething}
          >
            <Card.Grid style={{ width: '50%', height: '148px' }}>
              <CardGrid>
                {generalDailyStats && generalDailyStats.length ? (
                  <>
                    <div>
                      <ClickableDiv
                        onClick={() => {
                          settotalOffers(1);
                        }}
                      >
                        <Tooltip title="Počet ponúk k poslednému dňu v kalednári"><span> Ponuky:</span></Tooltip> 
                       
                      </ClickableDiv>
                      <ClickableDiv
                        onClick={() => {
                          settotalOffers(2);
                        }}
                      >
                        Ponuky - priemer:
                      </ClickableDiv>
                    </div>
                    <div>
                      <div>
                        { generalDailyStats[
                          generalDailyStats.length - 1
                        ].active_total_offers.toLocaleString('sk-SK')}
                      </div>
                      <div>{ (average(productsAverate) / average(shopsAverage)).toFixed(2)}</div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </CardGrid>
            </Card.Grid>
            <Card.Grid style={{ width: '50%' }}>
              {totalOffers === 1 ? (
                <Line
                  {...{
                    data: generalDailyStats
                      ? generalDailyStats.map(generalDailyStat => {
                          return {
                            date: generalDailyStat.date,
                            value: Number(generalDailyStat.active_total_offers),
                          };
                        })
                      : [],
                    height: 100,
                    autoFit: true,
                    xField: 'date',
                    yField: 'value',
                    smooth: true,
                    padding: 'auto',
                    point: {
                      size: 3,
                      shape: 'circle',
                    },
                    xAxis: {
                      type: 'timeCat',
                      tickCount: 5,
                      label: {
                        formatter: function formatter(v) {
                          return moment(v).format('DD.MM.YYYY');
                        },
                      },
                    },
                    tooltip: {
                      formatter: (value: any) => {
                        return {
                          name: 'Ponuky',
                          value: value.value,
                        };
                      },
                    },
                    color: '#a316e9',
                  }}
                />
              ) : null}
              {totalOffers === 2 ? (
                <Line
                  {...{
                    data: generalDailyStats
                      ? generalDailyStats.map(generalDailyStat => {
                          return {
                            date: generalDailyStat.date,
                            value:  generalDailyStat.shops_avg
                            ? parseFloat(
                                (
                                  generalDailyStat.total_category_products /
                                  generalDailyStat.shops_avg
                                ).toFixed(2)
                              )
                            : 0,
                          };
                        })
                      : [],
                    height: 100,
                    autoFit: true,
                    xField: 'date',
                    yField: 'value',
                    smooth: true,
                    padding: 'auto',
                    point: {
                      size: 3,
                      shape: 'circle',
                    },
                    xAxis: {
                      type: 'timeCat',
                      tickCount: 5,
                      label: {
                        formatter: function formatter(v) {
                          return moment(v).format('DD.MM.YYYY');
                        },
                      },
                    },
                    tooltip: {
                      formatter: (value: any) => {
                        return {
                          name: 'Ponuky',
                          value: value.value.toFixed(2),
                        };
                      },
                    },
                    color: '#a316e9',
                  }}
                />
              ) : null}
            </Card.Grid>
          </CustomCard>
        </Col>

        <Col span={12}>
          <CustomCard
            title="Produkty"
            bordered={false}
            loading={generalStatsFething}
          >
            <Card.Grid style={{ width: '50%', height: '148px' }}>
              <CardGrid>
                {generalDailyStats && generalDailyStats.length ? (
                  <>
                    <div>
                      <div> <Tooltip title="Počet produktov k poslednému dňu v kalednári"><span>Počet produktov:</span></Tooltip> </div>
                    </div>
                    <div>
                      <div>
                        {' '}
                        {generalDailyStats[
                          generalDailyStats.length - 1
                        ].total_category_products.toLocaleString('sk-SK')}
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </CardGrid>
            </Card.Grid>

            <Card.Grid style={{ width: '50%' }}>
              <Line
                {...{
                  data: generalDailyStats
                    ? generalDailyStats.map(generalDailyStat => {
                        return {
                          date: generalDailyStat.date,
                          value: Number(
                            generalDailyStat.total_category_products
                          ),
                        };
                      })
                    : [],
                  height: 100,
                  autoFit: true,
                  xField: 'date',
                  yField: 'value',
                  smooth: true,
                  padding: 'auto',
                  point: {
                    size: 3,
                    shape: 'circle',
                  },
                  xAxis: {
                    type: 'timeCat',
                    tickCount: 5,
                    label: {
                      formatter: function formatter(v) {
                        return moment(v).format('DD.MM.YYYY');
                      },
                    },
                  },
                  tooltip: {
                    formatter: (value: any) => {
                      return { name: 'Produkty', value: value.value };
                    },
                  },
                  color: '#ff0000',
                }}
              />
            </Card.Grid>
          </CustomCard>
        </Col>
      </Row>
    */}
     </>
  );
};

export default ProductListCharts;
