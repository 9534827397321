import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  PageHeader,
  Row,
  Col,
  Typography,
  Tag,
  Select,
  Table,
  DatePicker,
  Upload,
  Modal,
  Switch,
} from 'antd';
import locale from 'antd/es/date-picker/locale/sk_SK';
import moment from 'moment';
import { UploadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ApplicationState } from '../../store';
import {
  loadShop,
  processShop,
  shopSelector,
  loadShopStats,
  shopStats,
  setFilter,
  startDateActiveFiltersSelector,
  endDateActiveFiltersSelector,
  shopsDataIsFetching,
  sourceActiveFiltersSelector,
  updateShop,
  uploadNewShopImage,
  uploadingImageSelector,
  updateSettings,
  changeConnectedShopProducts,
  changeRuleEnabled,
  changePnPairingEnabled,
  runRulePairing,
  changeTrustedLevel,
} from './slice';
import { __ } from '../../utilities';
import ShopSelect from '../../components/Select/ShopSelect';

const ShopDetail = ({ shopId }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function init() {
      await dispatch(loadShop(shopId));
      await dispatch(loadShopStats(shopId));
    }
    init();
  }, [dispatch, shopId]);

  const shop = useSelector((state: ApplicationState) => shopSelector(state));
  const shopStat = useSelector((state: ApplicationState) => shopStats(state));

  const [imageState, imageStateAction] = useState({
    modalVisible: false,
    fileList: [],
  });

  const history = useHistory();
  function handleNavigation() {
    history.push('/shops');
  }

  const onProcessShopClick = () => {
    dispatch(processShop());
  };

  const onRulePairingClick = () => {
    dispatch(runRulePairing());
  }

  const onDateRangeChange = value => {
    if (value) {
      dispatch(setFilter('startDate', value[0]));
      dispatch(setFilter('endDate', value[1]));
    } else {
      dispatch(setFilter('startDate', null));
      dispatch(setFilter('endDate', null));
    }
  };
  const startDate = useSelector((state: ApplicationState) =>
    startDateActiveFiltersSelector(state)
  );

  const endDate = useSelector((state: ApplicationState) =>
    endDateActiveFiltersSelector(state)
  );
  const isFetchingShopStats = useSelector((state: ApplicationState) =>
    shopsDataIsFetching(state)
  );
  const onSourceChange = async value => {
    dispatch(setFilter('source', value));
  };
  const handleChangeShopProgram = async value => {
    await dispatch(updateShop(parseInt(value)));
  };
  const selectedSource = useSelector((state: ApplicationState) =>
    sourceActiveFiltersSelector(state)
  );
  const uploadingImage = useSelector((state: ApplicationState) =>
    uploadingImageSelector(state)
  );
  const showModal = () => {
    imageStateAction({
      ...imageState,
      modalVisible: !imageState.modalVisible,
    });
  };
  const uploadProps = {
    onRemove: file => {
      imageStateAction(state => {
        const index = state.fileList.indexOf(file);
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        return {
          ...state,
          fileList: newFileList,
        };
      });
    },
    beforeUpload: file => {
      imageStateAction(state => ({
        ...state,
        fileList: [...state.fileList, file],
      }));
      return false;
    },
  };

  const handleUpload = async () => {
    const { fileList } = imageState;
    const file = fileList[0];
    const data = { file: file };
    await dispatch(uploadNewShopImage(data));
    if (!uploadingImage) {
      imageStateAction({
        ...imageState,
        modalVisible: false,
        fileList: [],
      });
    }
  };

  const onChangeConnectedShopProducts = async (shopId) => {
    dispatch(changeConnectedShopProducts(shopId));
  };

  const onChangeRuleEnabled = async (ruleEnabled) => {
    dispatch(changeRuleEnabled(ruleEnabled))
  }

  const onChangePnPairingEnabled = async (pnPairingEnabled) => {
    dispatch(changePnPairingEnabled(pnPairingEnabled))
  }

  const onChangeTrustedLevel = async (trustedLevel) => {
    dispatch(changeTrustedLevel(trustedLevel))
  }

  const onSaveSettingsButtonClick = async() => {
    dispatch(updateSettings());
  }

  const sources = [
    { name: 'SEARCH', value: 5 },
    { name: 'PRODUCT', value: 8 },
    { name: 'SHOP', value: 11 },
    { name: 'BANNER', value: 143 },
    { name: 'WIDGET', value: 149 },
  ];

  const programData = [
    { data: <Tag color="green">Partner</Tag>, value: '1' },
    { data: <Tag color="blue">Promo</Tag>, value: '2' },
    { data: <Tag color="gold">Premium</Tag>, value: '3' },
  ];
  const shopBasicData = [
    {
      name: 'Program:',
      prop: (
        <Select
          value={shop && shop.program && shop.program.toString()}
          style={{ width: 120, marginLeft: -15 }}
          bordered={false}
          dropdownStyle={{ padding: 0 }}
          onChange={handleChangeShopProgram}
        >
          {programData &&
            programData.map(program => {
              return (
                <Select.Option value={program.value} key={program.value}>
                  {program.data}
                </Select.Option>
              );
            })}
        </Select>
      ),
      key: 'program',
    },
    {
      name: 'Krajina:',
      prop: shop && shop.country,
      key: 'country',
    },
    {
      name: 'Firma:',
      prop: shop && shop.company,
      key: 'company',
    },
    {
      name: 'Eshop:',
      prop: shop && (
        <a className="ant-dropdown-link" href={`${shop.url}`}>
          {shop.url}
        </a>
      ),
      key: 'eshop',
    },
  ];

  const shopBasicColumns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Dnes',
      dataIndex: 'prop',
      key: 'prop',
    },
  ];
  return (
    <>
      <PageHeader
        title={'Detail eshopu'}
        onBack={handleNavigation}
        subTitle={shop ? shop.name : ''}
        ghost={false}
        extra={[
          <Button key="1" type="primary" onClick={onProcessShopClick}>
            {' '}
            Spracovať eshop{' '}
          </Button>,
          <Button key="1" type="primary" onClick={onRulePairingClick}>
            {' '}
            Spustiť rule párovanie{' '}
          </Button>
        ]}
      >
        {' '}
        <TitleWrapper>
          {' '}
          <Typography.Title level={2}>{shop ? shop.name : ''}</Typography.Title>
        </TitleWrapper>{' '}
      </PageHeader>
      <MainContent style={{ paddingLeft: 0 }}>
        <Row>
          <ImageWrapper span={6}>
            {shop && (
              <StyledImg
                src={`https://y1.sk/t/s/130x50/${
                  shop.shop_id
                }.jpg?v=${new Date().getTime()}`}
              />
            )}
            <Button
              style={{ marginTop: 40 }}
              type="default"
              onClick={showModal}
            >
              Zmena loga
            </Button>
          </ImageWrapper>
          <Col span={9}>
            <Table
              dataSource={shopBasicData}
              showHeader={false}
              columns={shopBasicColumns}
              pagination={false}
              size="small"
            />
          </Col>
          <Col span={9}>
            <Paragraph>{shop && shop.description} </Paragraph>{' '}
          </Col>
        </Row>
      </MainContent>
      <MainContent>
        <Row justify={'start'}>
          <StyledCol>
            {' '}
            <Select
              showSearch
              style={{ width: 100 }}
              placeholder="Zdroj"
              optionFilterProp="children"
              onChange={onSourceChange}
              value={selectedSource}
            >
              <Select.Option value={null}>Zdroj</Select.Option>
              {sources &&
                sources.map(source => {
                  return (
                    <Select.Option value={source.value} key={source.value}>
                      {source.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </StyledCol>
          <StyledCol>
            <DatePicker.RangePicker
              locale={locale}
              ranges={{
                Dnes: [moment(), moment()],
                Včera: [
                  moment().subtract(1, 'days'),
                  moment().subtract(1, 'days'),
                ],
                'Tento mesiac': [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
                'Minulý mesiac': [
                  moment()
                    .subtract(1, 'months')
                    .startOf('month'),
                  moment()
                    .subtract(1, 'months')
                    .endOf('month'),
                ],
                //'Posledných 7 dní': [moment().subtract(7, "days"), moment()],
              }}
              value={[startDate, endDate]}
              onChange={onDateRangeChange}
              format={'DD.MM.YYYY'}
            />
          </StyledCol>
          <StyledCol>
            {' '}
            <Select
              showSearch
              style={{ width: 130 }}
              placeholder="Kategoria"
              optionFilterProp="children"
              disabled
              // onChange={onSourceChange}
              // value={selectedSource}
            >
              <Select.Option value={null}>Zdroj</Select.Option>
              {sources &&
                sources.map(source => {
                  return (
                    <Select.Option value={source.value} key={source.value}>
                      {source.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </StyledCol>
        </Row>
        <StyledTable
          loading={isFetchingShopStats}
          dataSource={shopStat && Object.values(shopStat)}
          columns={shopBasicColumns}
          pagination={false}
          size="middle"
          showHeader={false}
        />
      </MainContent>

      <MainContent>
        <Row justify={'start'}>
            <h2>{__("Nastavenia")}</h2>
            <RowRight> <Button onClick={onSaveSettingsButtonClick} > {__("Uložiť")} </Button> </RowRight>
        </Row>

        <Row justify={'start'}>
          <StyledCol>
            {__("Overený obchod")}:
          </StyledCol>
          <StyledCol>
            <Select value={shop && shop.trusted_level?shop.trusted_level:"none"} style={{ width: 120 }} onChange={(value) => onChangeTrustedLevel(value)} >
                <Select.Option value={'none'}>{__("Žiadny")}</Select.Option>
                <Select.Option value={'silver'}>{__("Silver")}</Select.Option>
                <Select.Option value={'gold'}>{__("Gold")}</Select.Option>
            </Select>
          </StyledCol>
        </Row>

        <Row justify={'start'}>
          <StyledCol>
            {__("Prepojený eshop")}:
          </StyledCol>
          <StyledCol>
            <ShopSelect onSelect={onChangeConnectedShopProducts} defaultValue={shop && shop.connected_shop_products?shop.connected_shop_products.shop_id:null} />
          </StyledCol>
        </Row>
        
        <Row justify={'start'}>
          <StyledCol>
            {__("Párovanie podľa RULE")}:
          </StyledCol>
          <StyledCol>
            <Switch checked={shop && shop.rule_pairing_enabled?true:false} onChange={onChangeRuleEnabled} />
          </StyledCol>
        </Row>

        <Row justify={'start'}>
          <StyledCol>
            {__("Párovanie podľa PN")}:
          </StyledCol>
          <StyledCol>
            <Switch checked={shop && shop.pn_pairing_enabled?true:false} onChange={onChangePnPairingEnabled} />
          </StyledCol>
        </Row>

      </MainContent>

      <Modal
        visible={imageState.modalVisible}
        title="Nastavenie nového loga eshopu"
        onCancel={showModal}
        footer={[
          <Button key="back" onClick={showModal}>
            Späť
          </Button>,
          <Button
            key="upload"
            type="primary"
            onClick={handleUpload}
            disabled={imageState.fileList.length === 0}
            loading={uploadingImage}
            style={{ marginTop: 16 }}
          >
            {uploadingImage ? 'Nahrávanie' : 'Nahrať'}
          </Button>,
        ]}
      >
        <ImageWrapper>
          {' '}
          <Upload
            {...uploadProps}
            accept={'image/png, image/jpeg'}
          >
            <Button  icon={<UploadOutlined />} disabled={imageState.fileList.length === 1}>Vybrať logo</Button>
          </Upload>
        </ImageWrapper>
      </Modal>
    </>
  );
};

const MainContent = styled.div`
  margin-top: 25px;
  padding: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;
const StyledTable = styled(Table)``;
const StyledCol = styled(Col)`
  padding-bottom: 25px;
  margin-right: 10px;
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Paragraph = styled.p`
  padding: 1em 2em;
`;
const StyledImg = styled.img`
  width: 130px;
`;

const ImageWrapper = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const RowRight = styled.div` 
  margin-left: auto;
  order: 2;
`

export default ShopDetail;
