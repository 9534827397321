import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  PageHeader,
  Row,
  Col,
  Upload,
  Modal,
  Typography,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ApplicationState } from '../../../store';
import {
  loadManufacturers,
  manufacturersDataDetailSelector,
  // manufacturersDetailIsFethingSelector,
  uploadingImageSelector,
  uploadNewManufacturerImage,
} from './slice';

const ManufacturerDetail = ({ id }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function init() {
      await dispatch(loadManufacturers(id));
    }
    init();
  }, [dispatch, id]);

  const [imageState, imageStateAction] = useState({
    modalVisible: false,
    fileList: [],
  });

  const history = useHistory();
  function handleNavigation() {
    history.push('/manufacturers-list');
  }

  const data = useSelector((state: ApplicationState) =>
    manufacturersDataDetailSelector(state)
  );

  const uploadingImage = useSelector((state: ApplicationState) =>
    uploadingImageSelector(state)
  );
  const showModal = () => {
    imageStateAction({
      ...imageState,
      modalVisible: !imageState.modalVisible,
    });
  };
  const uploadProps = {
    onRemove: file => {
      imageStateAction(state => {
        const index = state.fileList.indexOf(file);
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        return {
          ...state,
          fileList: newFileList,
        };
      });
    },
    beforeUpload: file => {
      imageStateAction(state => ({
        ...state,
        fileList: [...state.fileList, file],
      }));
      return false;
    },
  };

  const handleUpload = async () => {
    const { fileList } = imageState;
    const file = fileList[0];
    const data = { file: file };
    await dispatch(uploadNewManufacturerImage(data));
    if (!uploadingImage) {
      imageStateAction({
        ...imageState,
        modalVisible: false,
        fileList: [],
      });
    }
  };

  return (
    <>
      <PageHeader
        title={'Detail výrobcu'}
        onBack={handleNavigation}
        subTitle={data ? data.name : ''}
        ghost={false}
        extra={[]}
      >
        {' '}
        <TitleWrapper>
          {' '}
          <Typography.Title level={2}>{data ? data.name : ''}</Typography.Title>
        </TitleWrapper>{' '}
      </PageHeader>
      <MainContent style={{ paddingLeft: 0 }}>
        <Row>
          <ImageWrapper span={6}>
            {id && (
              <StyledImg
                src={`https://y1.sk/t/m/200e80/${id}.jpg?v=${new Date().getTime()}`}
              />
            )}
            <Button
              style={{ marginTop: 40 }}
              type="default"
              onClick={showModal}
            >
              Zmena loga
            </Button>
          </ImageWrapper>
          <Col span={9}></Col>
          <Col span={9}></Col>
        </Row>
      </MainContent>

      <Modal
        visible={imageState.modalVisible}
        title="Nastavenie nového loga eshopu"
        onCancel={showModal}
        footer={[
          <Button key="back" onClick={showModal}>
            Späť
          </Button>,
          <Button
            key="upload"
            type="primary"
            onClick={handleUpload}
            disabled={imageState.fileList.length === 0}
            loading={uploadingImage}
            style={{ marginTop: 16 }}
          >
            {uploadingImage ? 'Nahrávanie' : 'Nahrať'}
          </Button>,
        ]}
      >
        <ImageWrapper>
          {' '}
          <Upload {...uploadProps} accept={'image/png, image/jpeg'}>
            <Button
              icon={<UploadOutlined />}
              disabled={imageState.fileList.length === 1}
            >
              Vybrať logo
            </Button>
          </Upload>
        </ImageWrapper>
      </Modal>
    </>
  );
};

const MainContent = styled.div`
  margin-top: 25px;
  padding: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.img`
  width: 130px;
`;

const ImageWrapper = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default ManufacturerDetail;
