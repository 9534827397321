import React from 'react';
import AppRoutes from '../../Routes/appRoutes';

import {connect, useDispatch, useSelector} from 'react-redux';
// import { loadToken } from './slice';

import {Layout, Breadcrumb, Typography, Tooltip} from 'antd';
import {
  UserOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import {ApplicationState} from '../../store';
import BreadcrumbItem from 'antd/lib/breadcrumb/BreadcrumbItem';
import {Link} from "react-router-dom";
import NavigationMenu from "./NavigationMenu";
import styled from 'styled-components';
import { logOut } from './slice'; 

const {Text} = Typography;
const {Header, Content, Footer, Sider} = Layout;

const CustomUserOutlined = styled(UserOutlined)`
  color: #1890ff;
  cursor: pointer;
`

export const breadCrumbSelector = (state: ApplicationState) =>
  state.navigation.breadCrumb;

const App = () => {

  const dispatch = useDispatch();

  /*
  dispatch(
    setBreadCrumb([
      {name: 'Produkty', url: '/produkty'},
      {name: 'Nové produkty', url: '/produkt-detail'},
    ])
  );
  */
  const breadCrumb = useSelector((state: ApplicationState) =>
    breadCrumbSelector(state)
  );

  /*
  const onMenuClick = (e) => {
    dispatch(push(e.key));
  }
  */

  const onLogoutClick = () => {
    if (window.confirm('Naozaj sa chcete odhlásiť?')){
      dispatch(logOut());
      window.location = '/prihlasenie' as any;
    }
  }

  return (
    <Layout>
      <Header className="header">
        <div className="logo" style={{float: 'left', marginRight: 20}}>
          <Link to="/">
            <img
              src="https://www.pricemania.sk/bundles/app/img/logo-white.svg"
              alt="Pricemania.sk"
              width="255"
              height="24"
            />
          </Link>
        </div>
        <div className="userHeader" style={{float: 'right', marginRight: 20}}>
          <Tooltip placement={'bottom'} title={"Prihlásenie/Odhlásenie"}>
              <CustomUserOutlined style={{fontSize: '30px'}} onClick={onLogoutClick}></CustomUserOutlined>
          </Tooltip>
          <Tooltip placement={'bottom'} title={'Zmena hesla'}>
            <Link to={"/zmenahesla"}>
              <RetweetOutlined style={{fontSize: '30px', marginLeft: '15px'}}/>
            </Link>
          </Tooltip>
        </div>
      </Header>
      <Layout>
        <Sider width={200} className="site-layout-background">
          <NavigationMenu/>
        </Sider>
        <Layout style={{padding: '0 24px 24px'}}>
          <Breadcrumb style={{margin: '16px 0'}}>
            {breadCrumb.map(item => {
              return <BreadcrumbItem>{item.name}</BreadcrumbItem>;
            })}
          </Breadcrumb>
          <Content
            className="site-layout-background"
            style={{
              margin: 0,
              minHeight: 280,
            }}
          >
            <AppRoutes/>
          </Content>
        </Layout>
      </Layout>
      <Footer style={{textAlign: 'center'}}>
        <Text type="secondary"> Pricemania.sk ©2020 </Text>
      </Footer>
    </Layout>
  );
};

export default connect()(App);
