import React, {useEffect, useState} from 'react';
import {Row, Card, Form, Input, Button} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {env} from "../../utilities";
import {
  useLocation
} from "react-router-dom";
import {UrlHashService} from "../../services/HashService";
import {useDispatch, useSelector} from "react-redux";
import {loginUser, tokenInfoSelector} from "../App/slice";
import {ApplicationState} from "../../store";
import { useHistory } from "react-router-dom";

const LoginButton = styled(Button)`
    width: 100%;
`;

const ErrorMessageWrapper = styled.div`
    padding: 10px;
    background-color: #ff5454bf;
    margin-bottom: 25px;
`

const Login = () => {

  const [error,setError] = useState(null);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const tokeninfo = useSelector((state: ApplicationState) =>
    tokenInfoSelector(state)
  );

  useEffect(() => {
    const {hash} = location;
    const urlHashService = new UrlHashService(hash.substr(1));
    const accessToken = urlHashService.get('access_token');
    if (accessToken) {
      dispatch(loginUser(accessToken));
    }

    let error = urlHashService.get('error');
    if(error){
      error = decodeURI(error);
      if(error === 'Bad credentials'){
        error = 'Nesprávne prihlasovacie údaje'
      }

      setError(error);
    }
    else{
      setError(null);
    }
    
    /*
    if (tokeninfo !== null) {
      if (window.confirm('Naozaj sa chcete odhlásiť?')){
      dispatch(logOut())
    }else{
        history.goBack();
      }
    }
    */

  }, [location, dispatch, tokeninfo, history]);

  return (
    <Row justify="center" align="middle" style={{minHeight: '100vh'}}>
      <Card bordered={true} style={{width: 300}}>
        <form
          action={`${env<string>('REACT_APP_OAUTH_APIURL')}/oauth/authorize?client_id=${env<string>('REACT_APP_OAUTH_CLIENTID')}&redirect_uri=${env<string>('REACT_APP_OAUTH_RURL')}&scope=read&response_type=${env<string>('REACT_APP_OAUTH_FLOW')}`}
          method="POST"
          name="normal_login"
          className="login-form"
        > 

          { error ? 
            <ErrorMessageWrapper> {error} </ErrorMessageWrapper>
          : null} 

          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >

            <Input
              name="username"
              prefix={<UserOutlined className="site-form-item-icon"/>}
              placeholder="Username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input
              name="password"
              prefix={<LockOutlined className="site-form-item-icon"/>}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          {/*
                <Form.Item>
                  
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>
                  
                  <a className="login-form-forgot" href="/">
                    Forgot password
                  </a>
                
                </Form.Item>
                */}

          <Form.Item>
            <Row justify="center">
              <LoginButton
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </LoginButton>
            </Row>
            {/*
                  <a href="/">register now!</a>
                  */}
          </Form.Item>
        </form>
      </Card>
    </Row>
  );
};

export default Login;
