import React from 'react';
import Alert from 'antd/lib/alert';
import { __ } from '../../utilities';

interface Props {
    errorCode: number;
    message: string;
    description: string;
    type: any;
    showIcon: boolean;
}

export default class ModularAlert extends React.PureComponent<Props> {
    render() {
        return (
            <>
                <Alert
                    message={this.props.errorCode + ' ' + this.props.message}
                    description={this.props.description}
                    type={this.props.type}
                    showIcon={this.props.showIcon}
                />
            </>
        );
    }
}

export class Error404 extends React.PureComponent {
    render() {
        return (
            <>
                <ModularAlert
                    errorCode={404}
                    message={__('Nenájdené')}
                    description={__('Stránka sa nenašla')}
                    type={'error'}
                    showIcon={true}
                />
            </>
        );
    }
}
