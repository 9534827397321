/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class API
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class API {
  protected baseUrl: string = '';
  protected token: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        let param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }

        let value = [
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];

        if (Array.isArray(parameters[p])) {
          param = parameters[p] as Array<string>;
          value = param.map(x => {
            return `${encodeURIComponent(p)}=${encodeURIComponent(String(x))}`;
          });
        }

        return [...acc, ...value];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setToken(token: string) {
    this.token = token;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      return response.json();
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   * TEST API PATH
   * @method
   * @name API#test
   */
  test(test: string, parameters: {} = {}): Promise<{}> {
    let path = '/test/{test}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{test}', test.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Test Error Action. Action invokes error 500.
   * @method
   * @name API#testError
   */
  testError(parameters: {} = {}): Promise<{}> {
    let path = '/test/error';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Shows actual memory usage of API process
   * @method
   * @name API#heap
   */
  heap(
    parameters: {} = {},
  ): Promise<{
    description?: string;

    name?: string;

    payload?: {};

    statusCode?: number;

    userinfo?: string;
  }> {
    let path = '/status/heap';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
* Loads status of API. If everything is OK shows status: green.
This link is used in whole system of monitoring API (called by Elastic Heartbeat)
Actually checks:
* MongoDB connection
* Elastic connection
* Redis connection

* @method
* @name API#status
*/
  status(
    parameters: {} = {},
  ): Promise<{
    description?: string;

    name?: string;

    payload?: {};

    statusCode?: number;

    userinfo?: string;
  }> {
    let path = '/status';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
* Loads users from API. Should be used for user management purposes only.

* @method
* @name API#getUsers
*/
  getUsers(parameters: {
    limit?: number;
    offset?: number;
    appSpaceId: string;
  }): Promise<{
    users?: Array<{
      _id?: string;

      user_id?: number;

      confirmed?: boolean;

      email?: string;

      enabled?: boolean;

      imported_migration?: number;

      name?: string;

      surname?: string;

      token?: string;

      role?: string;
    }>;

    total?: number;
  }> {
    let path = '/users';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['appSpaceId'] !== 'undefined') {
      queryParameters['appSpaceId'] = parameters['appSpaceId'];
    }

    queryParameters['appSpaceId'] = this.transformParameter(
      queryParameters['appSpaceId'],
    );

    if (typeof parameters['appSpaceId'] === 'undefined') {
      throw new Error('Missing required parameter: appSpaceId');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads user affils
   * @method
   * @name API#loadUserAffils
   */
  loadUserAffils(
    parameters: {} = {},
  ): Promise<{
    items?: Array<{
      _id?: string;

      affiliate_id?: number;

      name?: string;
    }>;
  }> {
    let path = '/users/affils';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads user
   * @method
   * @name API#loadUser
   */
  loadUser(
    id: string,
    parameters: {} = {},
  ): Promise<{
    api_key?: string;
  }> {
    let path = '/user/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * RegenerateApiKey
   * @method
   * @name API#regenerateApiKey
   */
  regenerateApiKey(
    id: string,
    parameters: {} = {},
  ): Promise<{
    api_key?: string;
  }> {
    let path = '/user/{id}/api_key/regenerate';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Flips value of enabled flag of user
   * @method
   * @name API#activateUser
   */
  activateUser(
    userId: string,
    parameters: {} = {},
  ): Promise<{
    success?: boolean;
  }> {
    let path = '/users/activate/{userId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{userId}', userId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * creates new user in given appSpace and sends him generated password
   * @method
   * @name API#inviteUser
   */
  inviteUser(
    parameters: {
      appSpaceId: string;
    },
    body: {
      name?: string;

      surname?: string;

      email?: string;

      role?: string;
    },
  ): Promise<{
    success?: boolean;
  }> {
    let path = '/users/invite';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['appSpaceId'] !== 'undefined') {
      queryParameters['appSpaceId'] = parameters['appSpaceId'];
    }

    queryParameters['appSpaceId'] = this.transformParameter(
      queryParameters['appSpaceId'],
    );

    if (typeof parameters['appSpaceId'] === 'undefined') {
      throw new Error('Missing required parameter: appSpaceId');
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Resets password of given user and sends email with new password.
   * @method
   * @name API#resetPassword
   */
  resetPassword(
    userId: string,
    parameters: {} = {},
  ): Promise<{
    success?: boolean;
  }> {
    let path = '/users/resetPassword/{userId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{userId}', userId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Changes password of given user.
   * @method
   * @name API#changePassword
   */
  changePassword(
    id: string,
    parameters: {} = {},
    body: {
      password?: string;
    },
  ): Promise<{
    success?: boolean;
  }> {
    let path = '/users/changePassword/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
* Loads general CMS statistics

* @method
* @name API#generalStats
*/
  generalStats(
    parameters: {} = {},
  ): Promise<{
    products?: number;

    goods?: number;

    active_shops?: number;

    paying_shops?: number;
  }> {
    let path = '/cms/general_stats';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
* Loads users CMS statistics

* @method
* @name API#userStats
*/
  userStats(
    parameters: {} = {},
  ): Promise<
    Array<{
      user_id?: number;

      goods?: number;

      products?: number;
    }>
  > {
    let path = '/cms/user_stats';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
* Loads general CMS statistics

* @method
* @name API#statsDiff
*/
  statsDiff(parameters: {
    startDate: string;
    endDate: string;
  }): Promise<{
    general?: {
      products?: number;

      goods?: number;

      active_shops?: number;

      paying_shops?: number;
    };

    users?: Array<{
      user_id?: number;

      goods?: number;

      products?: number;
    }>;
  }> {
    let path = '/cms/stats_diff';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['startDate'] !== 'undefined') {
      queryParameters['startDate'] = parameters['startDate'];
    }

    queryParameters['startDate'] = this.transformParameter(
      queryParameters['startDate'],
    );

    if (typeof parameters['startDate'] === 'undefined') {
      throw new Error('Missing required parameter: startDate');
    }

    if (typeof parameters['endDate'] !== 'undefined') {
      queryParameters['endDate'] = parameters['endDate'];
    }

    queryParameters['endDate'] = this.transformParameter(
      queryParameters['endDate'],
    );

    if (typeof parameters['endDate'] === 'undefined') {
      throw new Error('Missing required parameter: endDate');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Save impression for product
   * @method
   * @name API#saveImpressionProduct
   */
  saveImpressionProduct(
    productId: string,
    parameters: {
      countryId: string;
      wsid?: string;
    },
    extraHeaders?: {
      xOriginalUserAgent?: string;
    },
  ): Promise<{}> {
    let path = '/impression/product/{product_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalUserAgent'] !== 'undefined'
    ) {
      headers.append(
        'x-original-user-agent',
        extraHeaders['xOriginalUserAgent']!,
      );
    }

    path = path.replace('{product_id}', productId.toString());

    if (typeof parameters['countryId'] !== 'undefined') {
      queryParameters['country_id'] = parameters['countryId'];
    }

    queryParameters['country_id'] = this.transformParameter(
      queryParameters['country_id'],
    );

    if (typeof parameters['countryId'] === 'undefined') {
      throw new Error('Missing required parameter: countryId');
    }

    if (typeof parameters['wsid'] !== 'undefined') {
      queryParameters['wsid'] = parameters['wsid'];
    }

    queryParameters['wsid'] = this.transformParameter(queryParameters['wsid']);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Save impression for listing
   * @method
   * @name API#saveImpressionListing
   */
  saveImpressionListing(
    categoryId: string,
    parameters: {
      countryId: string;
      wsid?: string;
    },
    extraHeaders?: {
      xOriginalUserAgent?: string;
    },
  ): Promise<{}> {
    let path = '/impression/listing/{category_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalUserAgent'] !== 'undefined'
    ) {
      headers.append(
        'x-original-user-agent',
        extraHeaders['xOriginalUserAgent']!,
      );
    }

    path = path.replace('{category_id}', categoryId.toString());

    if (typeof parameters['countryId'] !== 'undefined') {
      queryParameters['country_id'] = parameters['countryId'];
    }

    queryParameters['country_id'] = this.transformParameter(
      queryParameters['country_id'],
    );

    if (typeof parameters['countryId'] === 'undefined') {
      throw new Error('Missing required parameter: countryId');
    }

    if (typeof parameters['wsid'] !== 'undefined') {
      queryParameters['wsid'] = parameters['wsid'];
    }

    queryParameters['wsid'] = this.transformParameter(queryParameters['wsid']);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Save impression for product widget
   * @method
   * @name API#saveImpressionWidgetProduct
   */
  saveImpressionWidgetProduct(
    productId: number,
    widgetId: number,
    parameters: {
      countryId: number;
      wsid?: string;
    },
    body: {
      shops?: Array<{
        shop_id?: number;

        exit_hash?: string;

        position?: number;

        currency_id?: number;
      }>;
    },
    extraHeaders?: {
      xOriginalUserAgent?: string;
      xOriginalIpAddress: string;
    },
  ): Promise<{}> {
    let path = '/impression/widget/product/{product_id}/{widget_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalUserAgent'] !== 'undefined'
    ) {
      headers.append(
        'x-original-user-agent',
        extraHeaders['xOriginalUserAgent']!,
      );
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalIpAddress'] !== 'undefined'
    ) {
      headers.append(
        'x-original-ip-address',
        extraHeaders['xOriginalIpAddress']!,
      );
    }

    path = path.replace('{product_id}', productId.toString());

    path = path.replace('{widget_id}', widgetId.toString());

    if (typeof parameters['countryId'] !== 'undefined') {
      queryParameters['country_id'] = parameters['countryId'];
    }

    queryParameters['country_id'] = this.transformParameter(
      queryParameters['country_id'],
    );

    if (typeof parameters['countryId'] === 'undefined') {
      throw new Error('Missing required parameter: countryId');
    }

    if (typeof parameters['wsid'] !== 'undefined') {
      queryParameters['wsid'] = parameters['wsid'];
    }

    queryParameters['wsid'] = this.transformParameter(queryParameters['wsid']);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Save impression for product fulltext
   * @method
   * @name API#saveImpressionFulltextProduct
   */
  saveImpressionFulltextProduct(
    feedId: number,
    parameters: {
      countryId: number;
      shopId?: number;
      wsid?: string;
    },
    extraHeaders?: {
      xOriginalUserAgent: string;
      xOriginalIpAddress?: string;
    },
  ): Promise<{}> {
    let path = '/impression/fulltext/product/{feed_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalUserAgent'] !== 'undefined'
    ) {
      headers.append(
        'x-original-user-agent',
        extraHeaders['xOriginalUserAgent']!,
      );
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalIpAddress'] !== 'undefined'
    ) {
      headers.append(
        'x-original-ip-address',
        extraHeaders['xOriginalIpAddress']!,
      );
    }

    path = path.replace('{feed_id}', feedId.toString());

    if (typeof parameters['countryId'] !== 'undefined') {
      queryParameters['country_id'] = parameters['countryId'];
    }

    queryParameters['country_id'] = this.transformParameter(
      queryParameters['country_id'],
    );

    if (typeof parameters['countryId'] === 'undefined') {
      throw new Error('Missing required parameter: countryId');
    }

    if (typeof parameters['shopId'] !== 'undefined') {
      queryParameters['shop_id'] = parameters['shopId'];
    }

    queryParameters['shop_id'] = this.transformParameter(
      queryParameters['shop_id'],
    );

    if (typeof parameters['wsid'] !== 'undefined') {
      queryParameters['wsid'] = parameters['wsid'];
    }

    queryParameters['wsid'] = this.transformParameter(queryParameters['wsid']);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads products information
   * @method
   * @name API#loadProducts
   */
  loadProducts(
    parameters: {
      countryId?: number;
      query?: string;
      limit?: number;
      offset?: number;
      withCategory?: boolean;
      withSlugs?: boolean;
      projectType?: string;
      showInAutocomplete?: boolean;
      showInComparision?: boolean;
      categoryIds?: Array<number>;
      filterMinPrice?: number;
      filterMaxPrice?: number;
      filterCategoryIds?: Array<number>;
      filterManufacturerIds?: Array<number>;
      withAggregateMinPrice?: boolean;
      withAggregateMaxPrice?: boolean;
      withAggregateCategories?: boolean;
      withAggregateManufacturers?: boolean;
      withTop20Products?: boolean;
      sortType?: string;
      sortOrder?: string;
      withSingleGoodExitData?: boolean;
      clickType?: number;
      withPmProducts?: boolean;
      manufacturerIds?: Array<number>;
      createdFrom?: string;
      createdTo?: string;
      includeMainSubcategories?: boolean;
      filterProductIds?: Array<number>;
      withAssetInfo?: boolean;
      queryType?: string;
      prepareForDetail?: boolean;
      widgetId?: number;
      withTopPosition?: boolean;
      goodSortType?: string;
      filterEans?: Array<string>;
      filterWithoutParameters?: Array<string>;
      filterParameterValueIds?: Array<string>;
      filterParameterValueCustomIds?: Array<number>;
      filterParameterValueRanges?: Array<string>;
      withAggregateParametersValueIds?: Array<string>;
      withAggregateParametersValues?: Array<string>;
      withAggregateParametersRanges?: Array<string>;
      withQuatroData?: boolean;
    } = {},
    extraHeaders?: {
      xOriginalUserAgent?: string;
      xOriginalIpAddress?: string;
      xOriginalSessionId?: string;
      xOriginalReferer?: string;
    },
  ): Promise<{
    products?: Array<{
      _id?: string;

      aggregation_update?: string;

      aggregations?: Array<{
        active_shops_count?: number;

        average_price?: number;

        country_id?: number;

        min_price?: number;

        show_in_autocomplete?: boolean;

        show_in_comparision?: boolean;

        sum_price?: number;

        cpc?: number;
      }>;

      approved?: string;

      approved_by?: number;

      category?: {
        _id?: string;

        accessories_param?: boolean;

        category_id?: number;

        category_merging?: boolean;

        created?: string;

        created_by?: number;

        description?: string;

        edited?: string;

        edited_by?: number;

        enabled?: boolean;

        filter_name?: string;

        info?: Array<{
          average_ppc?: number;

          average_price?: number;

          category_id?: number;

          category_info_id?: number;

          clickthroughs?: number;

          country_id?: number;

          highest_price?: number;

          impressions?: number;

          lowest_price?: number;

          num_products?: number;

          updated?: string;
        }>;

        is_adult?: boolean;

        is_filter?: boolean;

        manufacturer_merging?: boolean;

        manufacturer_param?: boolean;

        manufacturer_state?: boolean;

        match_merging?: boolean;

        model_name_param?: boolean;

        model_name_state?: boolean;

        optional1_param?: string;

        optional1_state?: boolean;

        optional2_param?: string;

        optional2_state?: boolean;

        optional3_param?: string;

        optional3_state?: boolean;

        optional4_param?: string;

        optional4_state?: boolean;

        optional5_param?: string;

        optional5_state?: boolean;

        optional6_param?: string;

        optional6_state?: boolean;

        parent_id?: number;

        price_difference?: number;

        product_count?: {};

        slugs?: Array<{
          _id?: string;

          article_id?: number;

          branch_id?: number;

          carrier_id?: number;

          category_id?: number;

          company_id?: number;

          created?: string;

          group?: number;

          language_id?: number;

          manufacturer_id?: number;

          old_slug?: string;

          product_id?: number;

          shop_id?: number;

          slug?: string;

          slug_id?: number;

          updated?: string;

          user_id?: number;
        }>;

        translations?: Array<{
          category_data_id?: number;

          category_id?: number;

          description?: string;

          full_path?: string;

          language_id?: number;

          name?: string;

          name_short?: string;

          name_singular?: string;

          name_singular_short?: string;

          slug?: string;
        }>;

        tree_left?: number;

        tree_level?: number;

        tree_right?: number;

        use_automatic_assigns?: boolean;
      };

      category_id?: number;

      checked_pid?: string;

      created?: string;

      created_by?: number;

      edited?: string;

      edited_by?: string;

      goods?: Array<{
        affiliate_id?: {};

        availability?: number;

        checked?: string;

        created?: string;

        created_by?: number;

        description?: string;

        ean?: {
          disabled?: string;

          ean?: string;

          ean_id?: number;
        };

        ean_id?: string;

        good_id?: string;

        group_hash?: string;

        image?: string;

        image_hash?: string;

        internal_id?: {};

        launched?: string;

        name?: string;

        original_id?: string;

        original_name?: string;

        part_number_id?: string;

        price?: number;

        product_id?: string;

        rule_id?: number;

        rule_type?: string;

        shipping?: string;

        shop_id?: number;

        shop_reviews_count?: number;

        show_in_autocomplete?: boolean;

        show_in_comparision?: boolean;

        state?: string;

        supplier_id?: {};

        updated?: string;

        url?: string;

        url_hash?: string;

        product_detail_data?: {
          exit_hash?: string;

          recommended_exit_hash?: string;

          dynamic_banner_exit_hash?: string;

          shop_name?: string;

          shop_slug?: string;

          is_recommended?: boolean;

          trusted_shop?: boolean;

          trusted_level?: string;

          partnerships?: Array<{
            name?: string;

            type?: string;
          }>;
        };

        quatro_level?: number;
      }>;

      impressions_count_last_30_day?: number;

      manufacturer?: {
        created?: string;

        created_by?: number;

        edited?: {};

        edited_by?: {};

        enabled?: boolean;

        manufacturer_id?: number;

        monitoring?: boolean;

        name?: string;
      };

      manufacturer_id?: number;

      name?: string;

      parameters?: Array<{
        _id?: string;

        parameter_id?: string;

        parameter_value_id?: string;

        value?: {};
      }>;

      productDescriptions?: Array<{
        created?: string;

        description?: string;

        language_id?: number;

        product_description_id?: string;

        product_id?: string;

        score?: number;

        shop_id?: number;
      }>;

      productReviews?: Array<string>;

      productTrends?: Array<{
        average_price?: number;

        country_id?: number;

        date?: string;

        lowest_price?: number;

        product_id?: number;

        product_trend_id?: string;
      }>;

      product_id?: number;

      show_in_autocomplete?: boolean;

      slugs?: Array<{
        _id?: string;

        article_id?: number;

        branch_id?: number;

        carrier_id?: number;

        category_id?: number;

        company_id?: number;

        created?: string;

        group?: number;

        language_id?: number;

        manufacturer_id?: number;

        old_slug?: string;

        product_id?: number;

        shop_id?: number;

        slug?: string;

        slug_id?: number;

        updated?: string;

        user_id?: number;
      }>;

      state?: string;

      test?: {};

      single_good?: {
        exit_data?: string;

        shop_name?: string;
      };

      reviewAggregations?: Array<{
        language_id?: number;

        count?: number;

        avg?: number;
      }>;

      load_position?: number;

      assetInfo?: {
        count?: number;

        image?: {
          gallery?: Array<string>;

          detail_image?: string;

          listing_image?: string;

          hp_image?: string;
        };

        video?: Array<{
          id?: string;

          url?: string;
        }>;

        model3d?: Array<string>;
      };

      count?: number;

      rank?: number;

      lock?: boolean;

      quatro_level?: number;
    }>;

    pmProducts?: Array<{
      affiliate_id?: number;

      category?: string;

      category_hash?: string;

      category_id?: number;

      crawled?: string;

      created?: string;

      description?: string;

      description_path_hash?: string;

      ean?: string;

      feed_id?: number;

      image?: string;

      image_hash?: string;

      image_path_hash?: string;

      manufacturer?: string;

      manufacturer_id?: number;

      name?: string;

      original_id?: string;

      part_number?: string;

      price?: number;

      price_path_hash?: string;

      shop?: Array<{
        _id?: string;

        benefits?: string;

        billing_data_published?: boolean;

        company_id?: number;

        contact_email?: string;

        contact_phone?: string;

        country_id?: number;

        created?: string;

        created_by?: number;

        description?: string;

        edited?: string;

        edited_by?: number;

        feed_url?: string;

        has_backlink?: boolean;

        name?: string;

        next_parse_time?: string;

        original_program_id?: number;

        parse_interval?: number;

        parse_time?: string;

        partnerships?: number;

        ppc?: Array<{
          approx?: number;

          average_position?: number;

          average_ppc?: number;

          category_id?: number;

          country_id?: number;

          ppc?: number;

          product_id?: number;

          shop_id?: number;

          shop_ppc_id?: number;

          user_id?: number;
        }>;

        program_id?: number;

        shop_id?: number;

        slugs?: Array<{
          _id?: string;

          article_id?: number;

          branch_id?: number;

          carrier_id?: number;

          category_id?: number;

          company_id?: number;

          created?: string;

          group?: number;

          language_id?: number;

          manufacturer_id?: number;

          old_slug?: string;

          product_id?: number;

          shop_id?: number;

          slug?: string;

          slug_id?: number;

          updated?: string;

          user_id?: number;
        }>;

        start_date?: string;

        state?: string;

        trusted_days?: number;

        trusted_email?: string;

        trusted_shop?: boolean;

        url?: string;
      }>;

      shop_id?: number;

      supplier_id?: number;

      updated?: string;

      url?: string;

      url_hash?: string;

      exit_link_hash?: string;

      load_position?: number;
    }>;

    aggregations?: {
      minPrice?: number;

      maxPrice?: number;

      categories?: Array<{
        id?: number;

        count?: number;

        name?: string;

        translations?: Array<{
          language_id?: number;

          category_id?: number;

          slug?: string;

          name?: string;
        }>;
      }>;

      manufacturers?: Array<{
        id?: number;

        count?: number;

        name?: string;
      }>;

      top20Products?: Array<{
        product_id?: number;

        impressions_count_last_30_day?: number;

        position?: number;
      }>;

      parameterValueIds?: Array<{
        parameter_id?: string;

        values?: Array<{
          parameter_value_id?: string;

          count?: number;
        }>;
      }>;

      parameterValues?: Array<{
        parameter_id?: string;

        values?: Array<{
          value?: number;

          count?: number;
        }>;
      }>;

      parameterRanges?: Array<{
        parameter_id?: string;

        min?: number;

        max?: number;
      }>;

      cpc?: number;
    };

    total?: number;
  }> {
    let path = '/products';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['countryId'] !== 'undefined') {
      queryParameters['country_id'] = parameters['countryId'];
    }

    queryParameters['country_id'] = this.transformParameter(
      queryParameters['country_id'],
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['withCategory'] !== 'undefined') {
      queryParameters['with_category'] = parameters['withCategory'];
    }

    queryParameters['with_category'] = this.transformParameter(
      queryParameters['with_category'],
    );

    if (typeof parameters['withSlugs'] !== 'undefined') {
      queryParameters['with_slugs'] = parameters['withSlugs'];
    }

    queryParameters['with_slugs'] = this.transformParameter(
      queryParameters['with_slugs'],
    );

    if (typeof parameters['projectType'] !== 'undefined') {
      queryParameters['project_type'] = parameters['projectType'];
    }

    queryParameters['project_type'] = this.transformParameter(
      queryParameters['project_type'],
    );

    if (typeof parameters['showInAutocomplete'] !== 'undefined') {
      queryParameters['show_in_autocomplete'] =
        parameters['showInAutocomplete'];
    }

    queryParameters['show_in_autocomplete'] = this.transformParameter(
      queryParameters['show_in_autocomplete'],
    );

    if (typeof parameters['showInComparision'] !== 'undefined') {
      queryParameters['show_in_comparision'] = parameters['showInComparision'];
    }

    queryParameters['show_in_comparision'] = this.transformParameter(
      queryParameters['show_in_comparision'],
    );

    if (typeof parameters['categoryIds'] !== 'undefined') {
      queryParameters['category_ids'] = parameters['categoryIds'];
    }

    queryParameters['category_ids'] = this.transformParameter(
      queryParameters['category_ids'],
    );

    if (typeof parameters['filterMinPrice'] !== 'undefined') {
      queryParameters['filter_min_price'] = parameters['filterMinPrice'];
    }

    queryParameters['filter_min_price'] = this.transformParameter(
      queryParameters['filter_min_price'],
    );

    if (typeof parameters['filterMaxPrice'] !== 'undefined') {
      queryParameters['filter_max_price'] = parameters['filterMaxPrice'];
    }

    queryParameters['filter_max_price'] = this.transformParameter(
      queryParameters['filter_max_price'],
    );

    if (typeof parameters['filterCategoryIds'] !== 'undefined') {
      queryParameters['filter_category_ids'] = parameters['filterCategoryIds'];
    }

    queryParameters['filter_category_ids'] = this.transformParameter(
      queryParameters['filter_category_ids'],
    );

    if (typeof parameters['filterManufacturerIds'] !== 'undefined') {
      queryParameters['filter_manufacturer_ids'] =
        parameters['filterManufacturerIds'];
    }

    queryParameters['filter_manufacturer_ids'] = this.transformParameter(
      queryParameters['filter_manufacturer_ids'],
    );

    if (typeof parameters['withAggregateMinPrice'] !== 'undefined') {
      queryParameters['with_aggregate_min_price'] =
        parameters['withAggregateMinPrice'];
    }

    queryParameters['with_aggregate_min_price'] = this.transformParameter(
      queryParameters['with_aggregate_min_price'],
    );

    if (typeof parameters['withAggregateMaxPrice'] !== 'undefined') {
      queryParameters['with_aggregate_max_price'] =
        parameters['withAggregateMaxPrice'];
    }

    queryParameters['with_aggregate_max_price'] = this.transformParameter(
      queryParameters['with_aggregate_max_price'],
    );

    if (typeof parameters['withAggregateCategories'] !== 'undefined') {
      queryParameters['with_aggregate_categories'] =
        parameters['withAggregateCategories'];
    }

    queryParameters['with_aggregate_categories'] = this.transformParameter(
      queryParameters['with_aggregate_categories'],
    );

    if (typeof parameters['withAggregateManufacturers'] !== 'undefined') {
      queryParameters['with_aggregate_manufacturers'] =
        parameters['withAggregateManufacturers'];
    }

    queryParameters['with_aggregate_manufacturers'] = this.transformParameter(
      queryParameters['with_aggregate_manufacturers'],
    );

    if (typeof parameters['withTop20Products'] !== 'undefined') {
      queryParameters['with_top_20_products'] = parameters['withTop20Products'];
    }

    queryParameters['with_top_20_products'] = this.transformParameter(
      queryParameters['with_top_20_products'],
    );

    if (typeof parameters['sortType'] !== 'undefined') {
      queryParameters['sort_type'] = parameters['sortType'];
    }

    queryParameters['sort_type'] = this.transformParameter(
      queryParameters['sort_type'],
    );

    if (typeof parameters['sortOrder'] !== 'undefined') {
      queryParameters['sort_order'] = parameters['sortOrder'];
    }

    queryParameters['sort_order'] = this.transformParameter(
      queryParameters['sort_order'],
    );

    if (typeof parameters['withSingleGoodExitData'] !== 'undefined') {
      queryParameters['with_single_good_exit_data'] =
        parameters['withSingleGoodExitData'];
    }

    queryParameters['with_single_good_exit_data'] = this.transformParameter(
      queryParameters['with_single_good_exit_data'],
    );

    if (typeof parameters['clickType'] !== 'undefined') {
      queryParameters['click_type'] = parameters['clickType'];
    }

    queryParameters['click_type'] = this.transformParameter(
      queryParameters['click_type'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalUserAgent'] !== 'undefined'
    ) {
      headers.append(
        'x-original-user-agent',
        extraHeaders['xOriginalUserAgent']!,
      );
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalIpAddress'] !== 'undefined'
    ) {
      headers.append(
        'x-original-ip-address',
        extraHeaders['xOriginalIpAddress']!,
      );
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalSessionId'] !== 'undefined'
    ) {
      headers.append(
        'x-original-session-id',
        extraHeaders['xOriginalSessionId']!,
      );
    }

    if (typeof parameters['withPmProducts'] !== 'undefined') {
      queryParameters['with_pm_products'] = parameters['withPmProducts'];
    }

    queryParameters['with_pm_products'] = this.transformParameter(
      queryParameters['with_pm_products'],
    );

    if (typeof parameters['manufacturerIds'] !== 'undefined') {
      queryParameters['manufacturer_ids'] = parameters['manufacturerIds'];
    }

    queryParameters['manufacturer_ids'] = this.transformParameter(
      queryParameters['manufacturer_ids'],
    );

    if (typeof parameters['createdFrom'] !== 'undefined') {
      queryParameters['created_from'] = parameters['createdFrom'];
    }

    queryParameters['created_from'] = this.transformParameter(
      queryParameters['created_from'],
    );

    if (typeof parameters['createdTo'] !== 'undefined') {
      queryParameters['created_to'] = parameters['createdTo'];
    }

    queryParameters['created_to'] = this.transformParameter(
      queryParameters['created_to'],
    );

    if (typeof parameters['includeMainSubcategories'] !== 'undefined') {
      queryParameters['include_main_subcategories'] =
        parameters['includeMainSubcategories'];
    }

    queryParameters['include_main_subcategories'] = this.transformParameter(
      queryParameters['include_main_subcategories'],
    );

    if (typeof parameters['filterProductIds'] !== 'undefined') {
      queryParameters['filter_product_ids'] = parameters['filterProductIds'];
    }

    queryParameters['filter_product_ids'] = this.transformParameter(
      queryParameters['filter_product_ids'],
    );

    if (typeof parameters['withAssetInfo'] !== 'undefined') {
      queryParameters['with_asset_info'] = parameters['withAssetInfo'];
    }

    queryParameters['with_asset_info'] = this.transformParameter(
      queryParameters['with_asset_info'],
    );

    if (typeof parameters['queryType'] !== 'undefined') {
      queryParameters['query_type'] = parameters['queryType'];
    }

    queryParameters['query_type'] = this.transformParameter(
      queryParameters['query_type'],
    );

    if (typeof parameters['prepareForDetail'] !== 'undefined') {
      queryParameters['prepare_for_detail'] = parameters['prepareForDetail'];
    }

    queryParameters['prepare_for_detail'] = this.transformParameter(
      queryParameters['prepare_for_detail'],
    );

    if (typeof parameters['widgetId'] !== 'undefined') {
      queryParameters['widget_id'] = parameters['widgetId'];
    }

    queryParameters['widget_id'] = this.transformParameter(
      queryParameters['widget_id'],
    );

    if (typeof parameters['withTopPosition'] !== 'undefined') {
      queryParameters['with_top_position'] = parameters['withTopPosition'];
    }

    queryParameters['with_top_position'] = this.transformParameter(
      queryParameters['with_top_position'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalReferer'] !== 'undefined'
    ) {
      headers.append('x-original-referer', extraHeaders['xOriginalReferer']!);
    }

    if (typeof parameters['goodSortType'] !== 'undefined') {
      queryParameters['good_sort_type'] = parameters['goodSortType'];
    }

    queryParameters['good_sort_type'] = this.transformParameter(
      queryParameters['good_sort_type'],
    );

    if (typeof parameters['filterEans'] !== 'undefined') {
      queryParameters['filter_eans'] = parameters['filterEans'];
    }

    queryParameters['filter_eans'] = this.transformParameter(
      queryParameters['filter_eans'],
    );

    if (typeof parameters['filterWithoutParameters'] !== 'undefined') {
      queryParameters['filter_without_parameters'] =
        parameters['filterWithoutParameters'];
    }

    queryParameters['filter_without_parameters'] = this.transformParameter(
      queryParameters['filter_without_parameters'],
    );

    if (typeof parameters['filterParameterValueIds'] !== 'undefined') {
      queryParameters['filter_parameter_value_ids'] =
        parameters['filterParameterValueIds'];
    }

    queryParameters['filter_parameter_value_ids'] = this.transformParameter(
      queryParameters['filter_parameter_value_ids'],
    );

    if (typeof parameters['filterParameterValueCustomIds'] !== 'undefined') {
      queryParameters['filter_parameter_value_custom_ids'] =
        parameters['filterParameterValueCustomIds'];
    }

    queryParameters[
      'filter_parameter_value_custom_ids'
    ] = this.transformParameter(
      queryParameters['filter_parameter_value_custom_ids'],
    );

    if (typeof parameters['filterParameterValueRanges'] !== 'undefined') {
      queryParameters['filter_parameter_value_ranges'] =
        parameters['filterParameterValueRanges'];
    }

    queryParameters['filter_parameter_value_ranges'] = this.transformParameter(
      queryParameters['filter_parameter_value_ranges'],
    );

    if (typeof parameters['withAggregateParametersValueIds'] !== 'undefined') {
      queryParameters['with_aggregate_parameters_value_ids'] =
        parameters['withAggregateParametersValueIds'];
    }

    queryParameters[
      'with_aggregate_parameters_value_ids'
    ] = this.transformParameter(
      queryParameters['with_aggregate_parameters_value_ids'],
    );

    if (typeof parameters['withAggregateParametersValues'] !== 'undefined') {
      queryParameters['with_aggregate_parameters_values'] =
        parameters['withAggregateParametersValues'];
    }

    queryParameters[
      'with_aggregate_parameters_values'
    ] = this.transformParameter(
      queryParameters['with_aggregate_parameters_values'],
    );

    if (typeof parameters['withAggregateParametersRanges'] !== 'undefined') {
      queryParameters['with_aggregate_parameters_ranges'] =
        parameters['withAggregateParametersRanges'];
    }

    queryParameters[
      'with_aggregate_parameters_ranges'
    ] = this.transformParameter(
      queryParameters['with_aggregate_parameters_ranges'],
    );

    if (typeof parameters['withQuatroData'] !== 'undefined') {
      queryParameters['with_quatro_data'] = parameters['withQuatroData'];
    }

    queryParameters['with_quatro_data'] = this.transformParameter(
      queryParameters['with_quatro_data'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create new product
   * @method
   * @name API#createProduct
   */
  createProduct(
    parameters: {} = {},
    body: {
      category_id?: number;

      manufacturer_id?: number;

      part_number?: string;

      ean?: string;

      model_name_suffix?: string;

      model_name_prefix?: string;

      product_groups?: Array<{
        name?: string;

        pm_shop_product_ids?: Array<number>;
      }>;

      mask?: string;

      model_name_original?: string;

      model_name_regex?: string;

      optional1_original?: string;

      optional2_original?: string;

      optional3_original?: string;

      optional4_original?: string;

      optional5_original?: string;

      optional6_original?: string;

      optional1_regex?: string;

      optional2_regex?: string;

      optional3_regex?: string;

      optional4_regex?: string;

      optional5_regex?: string;

      optional6_regex?: string;
    },
  ): Promise<{
    product_id?: number;
  }> {
    let path = '/products';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * getProductBySlug
   * @method
   * @name API#getProductBySlug
   */
  getProductBySlug(
    languageId: number,
    countryId: number,
    slug: string,
    category: string,
    withProductTopPosition?: boolean,
    parameters: {} = {},
  ): Promise<{
    lowest_price: number;

    ean: Array<string>;

    slug: string;

    product_id: number;

    productName: string;

    productCategory: number;

    productManufakturer: number;

    manufacturerName: string;

    description: string;

    productRealState: number;

    rank?: number;
  }> {
    let path =
      '/product/getProductBySlug/{languageId}/{countryId}/{slug}/{category}/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{languageId}', languageId.toString());

    path = path.replace('{countryId}', countryId.toString());

    path = path.replace('{slug}', slug.toString());

    path = path.replace('{category}', category.toString());

    path = path.replace(
      '{with_product_top_position}',
      withProductTopPosition.toString(),
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * getProductParameters
   * @method
   * @name API#getProductParameters
   */
  getProductParameters(
    languageId: string,
    productId: string,
    parameters: {} = {},
  ): Promise<
    Array<{
      parameter_id: string;

      value: string;

      parameter_name_id: number;

      parameterName: string;

      groupName: string;

      parameter_group_id: number;

      sign: string;
    }>
  > {
    let path = '/product/getProductParameters/{languageId}/{productId}/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{languageId}', languageId.toString());

    path = path.replace('{productId}', productId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * priceTrends
   * @method
   * @name API#priceTrends
   */
  priceTrends(
    productId: number,
    countryId: number,
    parameters: {} = {},
  ): Promise<
    Array<{
      date: string;

      lowest_price: number;

      average_price: number;
    }>
  > {
    let path = '/common/priceTrends/{productId}/{countryId}/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId.toString());

    path = path.replace('{countryId}', countryId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update product
   * @method
   * @name API#updateProduct
   */
  updateProduct(
    productId: number,
    parameters: {} = {},
    body: {
      productDescriptions?: Array<object> | null;

      manufacturer_id?: number | null;

      lock?: boolean;
    },
  ): Promise<{
    product?: {
      _id?: string;

      aggregation_update?: string;

      aggregations?: Array<{
        active_shops_count?: number;

        average_price?: number;

        country_id?: number;

        min_price?: number;

        show_in_autocomplete?: boolean;

        show_in_comparision?: boolean;

        sum_price?: number;

        cpc?: number;
      }>;

      approved?: string;

      approved_by?: number;

      category?: {
        _id?: string;

        accessories_param?: boolean;

        category_id?: number;

        category_merging?: boolean;

        created?: string;

        created_by?: number;

        description?: string;

        edited?: string;

        edited_by?: number;

        enabled?: boolean;

        filter_name?: string;

        info?: Array<{
          average_ppc?: number;

          average_price?: number;

          category_id?: number;

          category_info_id?: number;

          clickthroughs?: number;

          country_id?: number;

          highest_price?: number;

          impressions?: number;

          lowest_price?: number;

          num_products?: number;

          updated?: string;
        }>;

        is_adult?: boolean;

        is_filter?: boolean;

        manufacturer_merging?: boolean;

        manufacturer_param?: boolean;

        manufacturer_state?: boolean;

        match_merging?: boolean;

        model_name_param?: boolean;

        model_name_state?: boolean;

        optional1_param?: string;

        optional1_state?: boolean;

        optional2_param?: string;

        optional2_state?: boolean;

        optional3_param?: string;

        optional3_state?: boolean;

        optional4_param?: string;

        optional4_state?: boolean;

        optional5_param?: string;

        optional5_state?: boolean;

        optional6_param?: string;

        optional6_state?: boolean;

        parent_id?: number;

        price_difference?: number;

        product_count?: {};

        slugs?: Array<{
          _id?: string;

          article_id?: number;

          branch_id?: number;

          carrier_id?: number;

          category_id?: number;

          company_id?: number;

          created?: string;

          group?: number;

          language_id?: number;

          manufacturer_id?: number;

          old_slug?: string;

          product_id?: number;

          shop_id?: number;

          slug?: string;

          slug_id?: number;

          updated?: string;

          user_id?: number;
        }>;

        translations?: Array<{
          category_data_id?: number;

          category_id?: number;

          description?: string;

          full_path?: string;

          language_id?: number;

          name?: string;

          name_short?: string;

          name_singular?: string;

          name_singular_short?: string;

          slug?: string;
        }>;

        tree_left?: number;

        tree_level?: number;

        tree_right?: number;

        use_automatic_assigns?: boolean;
      };

      category_id?: number;

      checked_pid?: string;

      created?: string;

      created_by?: number;

      edited?: string;

      edited_by?: string;

      goods?: Array<{
        affiliate_id?: {};

        availability?: number;

        checked?: string;

        created?: string;

        created_by?: number;

        description?: string;

        ean?: {
          disabled?: string;

          ean?: string;

          ean_id?: number;
        };

        ean_id?: string;

        good_id?: string;

        group_hash?: string;

        image?: string;

        image_hash?: string;

        internal_id?: {};

        launched?: string;

        name?: string;

        original_id?: string;

        original_name?: string;

        part_number_id?: string;

        price?: number;

        product_id?: string;

        rule_id?: number;

        rule_type?: string;

        shipping?: string;

        shop_id?: number;

        shop_reviews_count?: number;

        show_in_autocomplete?: boolean;

        show_in_comparision?: boolean;

        state?: string;

        supplier_id?: {};

        updated?: string;

        url?: string;

        url_hash?: string;

        product_detail_data?: {
          exit_hash?: string;

          recommended_exit_hash?: string;

          dynamic_banner_exit_hash?: string;

          shop_name?: string;

          shop_slug?: string;

          is_recommended?: boolean;

          trusted_shop?: boolean;

          trusted_level?: string;

          partnerships?: Array<{
            name?: string;

            type?: string;
          }>;
        };

        quatro_level?: number;
      }>;

      impressions_count_last_30_day?: number;

      manufacturer?: {
        created?: string;

        created_by?: number;

        edited?: {};

        edited_by?: {};

        enabled?: boolean;

        manufacturer_id?: number;

        monitoring?: boolean;

        name?: string;
      };

      manufacturer_id?: number;

      name?: string;

      parameters?: Array<{
        _id?: string;

        parameter_id?: string;

        parameter_value_id?: string;

        value?: {};
      }>;

      productDescriptions?: Array<{
        created?: string;

        description?: string;

        language_id?: number;

        product_description_id?: string;

        product_id?: string;

        score?: number;

        shop_id?: number;
      }>;

      productReviews?: Array<string>;

      productTrends?: Array<{
        average_price?: number;

        country_id?: number;

        date?: string;

        lowest_price?: number;

        product_id?: number;

        product_trend_id?: string;
      }>;

      product_id?: number;

      show_in_autocomplete?: boolean;

      slugs?: Array<{
        _id?: string;

        article_id?: number;

        branch_id?: number;

        carrier_id?: number;

        category_id?: number;

        company_id?: number;

        created?: string;

        group?: number;

        language_id?: number;

        manufacturer_id?: number;

        old_slug?: string;

        product_id?: number;

        shop_id?: number;

        slug?: string;

        slug_id?: number;

        updated?: string;

        user_id?: number;
      }>;

      state?: string;

      test?: {};

      single_good?: {
        exit_data?: string;

        shop_name?: string;
      };

      reviewAggregations?: Array<{
        language_id?: number;

        count?: number;

        avg?: number;
      }>;

      load_position?: number;

      assetInfo?: {
        count?: number;

        image?: {
          gallery?: Array<string>;

          detail_image?: string;

          listing_image?: string;

          hp_image?: string;
        };

        video?: Array<{
          id?: string;

          url?: string;
        }>;

        model3d?: Array<string>;
      };

      count?: number;

      rank?: number;

      lock?: boolean;

      quatro_level?: number;
    };
  }> {
    let path = '/product/{productId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Update product parameters
   * @method
   * @name API#updateProductParameters
   */
  updateProductParameters(
    productId: number,
    parameters: {} = {},
    body: {
      parameters?: Array<{
        _id?: string;

        parameter_id?: string;

        parameter_value_id?: string;

        value?: {};
      }>;

      update_mode?: string;
    },
  ): Promise<{}> {
    let path = '/product/{productId}/parameters';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Upload product main image
   * @method
   * @name API#updateAssetInfo
   */
  updateAssetInfo(
    productId: number,
    parameters: {} = {},
    body: {
      detailImageBase64?: string;

      gallery?: Array<{
        name?: string;

        url?: string;

        base64?: string;
      }>;

      videos?: Array<{
        id?: string;

        url?: string;
      }>;

      model3d?: Array<{
        url?: string;
      }>;
    },
  ): Promise<void> {
    let path = '/product/{productId}/asset_info';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');

    path = path.replace('{productId}', productId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Pair product with Pm products
   * @method
   * @name API#pairPmProducts
   */
  pairPmProducts(
    productId: number,
    parameters: {} = {},
    body: {
      feedIds?: Array<number>;
    },
  ): Promise<{
    count?: number;
  }> {
    let path = '/product/{productId}/pair';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Load create suggestions
   * @method
   * @name API#loadCreateSuggestions
   */
  loadCreateSuggestions(
    parameters: {
      categoryId?: number;
      manufacturerId?: number;
      mainManufacturerId?: number;
      modelNameParamRegex?: string;
      optional1ParamRegex?: string;
      optional2ParamRegex?: string;
      optional3ParamRegex?: string;
      optional4ParamRegex?: string;
      optional5ParamRegex?: string;
      optional6ParamRegex?: string;
      ean?: string;
      partNumber?: string;
      modelNamePrefix?: string;
      modelNameSuffix?: string;
      modelNameParam?: string;
      optional1Param?: string;
      optional2Param?: string;
      optional3Param?: string;
      optional4Param?: string;
      optional5Param?: string;
      optional6Param?: string;
      optional12Separator?: string;
      optional23Separator?: string;
      optional34Separator?: string;
      optional45Separator?: string;
      optional56Separator?: string;
      removedSelectedSuggestions?: Array<number>;
      forcedFeedIds?: Array<number>;
      forceAssignToMainProductFeedIds?: Array<number>;
    } = {},
  ): Promise<{
    productSuggestions?: {
      groups?: Array<{
        id?: string;

        name?: string;

        medianPrice?: number;

        productEntities?: Array<{
          product?: {
            affiliate_id?: number;

            category?: string;

            category_hash?: string;

            category_id?: number;

            crawled?: string;

            created?: string;

            description?: string;

            description_path_hash?: string;

            ean?: string;

            feed_id?: number;

            image?: string;

            image_hash?: string;

            image_path_hash?: string;

            manufacturer?: string;

            manufacturer_id?: number;

            name?: string;

            original_id?: string;

            part_number?: string;

            price?: number;

            price_path_hash?: string;

            shop?: Array<{
              _id?: string;

              benefits?: string;

              billing_data_published?: boolean;

              company_id?: number;

              contact_email?: string;

              contact_phone?: string;

              country_id?: number;

              created?: string;

              created_by?: number;

              description?: string;

              edited?: string;

              edited_by?: number;

              feed_url?: string;

              has_backlink?: boolean;

              name?: string;

              next_parse_time?: string;

              original_program_id?: number;

              parse_interval?: number;

              parse_time?: string;

              partnerships?: number;

              ppc?: Array<{
                approx?: number;

                average_position?: number;

                average_ppc?: number;

                category_id?: number;

                country_id?: number;

                ppc?: number;

                product_id?: number;

                shop_id?: number;

                shop_ppc_id?: number;

                user_id?: number;
              }>;

              program_id?: number;

              shop_id?: number;

              slugs?: Array<{
                _id?: string;

                article_id?: number;

                branch_id?: number;

                carrier_id?: number;

                category_id?: number;

                company_id?: number;

                created?: string;

                group?: number;

                language_id?: number;

                manufacturer_id?: number;

                old_slug?: string;

                product_id?: number;

                shop_id?: number;

                slug?: string;

                slug_id?: number;

                updated?: string;

                user_id?: number;
              }>;

              start_date?: string;

              state?: string;

              trusted_days?: number;

              trusted_email?: string;

              trusted_shop?: boolean;

              url?: string;
            }>;

            shop_id?: number;

            supplier_id?: number;

            updated?: string;

            url?: string;

            url_hash?: string;

            exit_link_hash?: string;

            load_position?: number;
          };

          matchTypes?: Array<string>;
        }>;
      }>;

      unassignedProducts?: Array<{
        affiliate_id?: number;

        category?: string;

        category_hash?: string;

        category_id?: number;

        crawled?: string;

        created?: string;

        description?: string;

        description_path_hash?: string;

        ean?: string;

        feed_id?: number;

        image?: string;

        image_hash?: string;

        image_path_hash?: string;

        manufacturer?: string;

        manufacturer_id?: number;

        name?: string;

        original_id?: string;

        part_number?: string;

        price?: number;

        price_path_hash?: string;

        shop?: Array<{
          _id?: string;

          benefits?: string;

          billing_data_published?: boolean;

          company_id?: number;

          contact_email?: string;

          contact_phone?: string;

          country_id?: number;

          created?: string;

          created_by?: number;

          description?: string;

          edited?: string;

          edited_by?: number;

          feed_url?: string;

          has_backlink?: boolean;

          name?: string;

          next_parse_time?: string;

          original_program_id?: number;

          parse_interval?: number;

          parse_time?: string;

          partnerships?: number;

          ppc?: Array<{
            approx?: number;

            average_position?: number;

            average_ppc?: number;

            category_id?: number;

            country_id?: number;

            ppc?: number;

            product_id?: number;

            shop_id?: number;

            shop_ppc_id?: number;

            user_id?: number;
          }>;

          program_id?: number;

          shop_id?: number;

          slugs?: Array<{
            _id?: string;

            article_id?: number;

            branch_id?: number;

            carrier_id?: number;

            category_id?: number;

            company_id?: number;

            created?: string;

            group?: number;

            language_id?: number;

            manufacturer_id?: number;

            old_slug?: string;

            product_id?: number;

            shop_id?: number;

            slug?: string;

            slug_id?: number;

            updated?: string;

            user_id?: number;
          }>;

          start_date?: string;

          state?: string;

          trusted_days?: number;

          trusted_email?: string;

          trusted_shop?: boolean;

          url?: string;
        }>;

        shop_id?: number;

        supplier_id?: number;

        updated?: string;

        url?: string;

        url_hash?: string;

        exit_link_hash?: string;

        load_position?: number;
      }>;
    };

    catalogProducts?: Array<{
      _id?: string;

      aggregation_update?: string;

      aggregations?: Array<{
        active_shops_count?: number;

        average_price?: number;

        country_id?: number;

        min_price?: number;

        show_in_autocomplete?: boolean;

        show_in_comparision?: boolean;

        sum_price?: number;

        cpc?: number;
      }>;

      approved?: string;

      approved_by?: number;

      category?: {
        _id?: string;

        accessories_param?: boolean;

        category_id?: number;

        category_merging?: boolean;

        created?: string;

        created_by?: number;

        description?: string;

        edited?: string;

        edited_by?: number;

        enabled?: boolean;

        filter_name?: string;

        info?: Array<{
          average_ppc?: number;

          average_price?: number;

          category_id?: number;

          category_info_id?: number;

          clickthroughs?: number;

          country_id?: number;

          highest_price?: number;

          impressions?: number;

          lowest_price?: number;

          num_products?: number;

          updated?: string;
        }>;

        is_adult?: boolean;

        is_filter?: boolean;

        manufacturer_merging?: boolean;

        manufacturer_param?: boolean;

        manufacturer_state?: boolean;

        match_merging?: boolean;

        model_name_param?: boolean;

        model_name_state?: boolean;

        optional1_param?: string;

        optional1_state?: boolean;

        optional2_param?: string;

        optional2_state?: boolean;

        optional3_param?: string;

        optional3_state?: boolean;

        optional4_param?: string;

        optional4_state?: boolean;

        optional5_param?: string;

        optional5_state?: boolean;

        optional6_param?: string;

        optional6_state?: boolean;

        parent_id?: number;

        price_difference?: number;

        product_count?: {};

        slugs?: Array<{
          _id?: string;

          article_id?: number;

          branch_id?: number;

          carrier_id?: number;

          category_id?: number;

          company_id?: number;

          created?: string;

          group?: number;

          language_id?: number;

          manufacturer_id?: number;

          old_slug?: string;

          product_id?: number;

          shop_id?: number;

          slug?: string;

          slug_id?: number;

          updated?: string;

          user_id?: number;
        }>;

        translations?: Array<{
          category_data_id?: number;

          category_id?: number;

          description?: string;

          full_path?: string;

          language_id?: number;

          name?: string;

          name_short?: string;

          name_singular?: string;

          name_singular_short?: string;

          slug?: string;
        }>;

        tree_left?: number;

        tree_level?: number;

        tree_right?: number;

        use_automatic_assigns?: boolean;
      };

      category_id?: number;

      checked_pid?: string;

      created?: string;

      created_by?: number;

      edited?: string;

      edited_by?: string;

      goods?: Array<{
        affiliate_id?: {};

        availability?: number;

        checked?: string;

        created?: string;

        created_by?: number;

        description?: string;

        ean?: {
          disabled?: string;

          ean?: string;

          ean_id?: number;
        };

        ean_id?: string;

        good_id?: string;

        group_hash?: string;

        image?: string;

        image_hash?: string;

        internal_id?: {};

        launched?: string;

        name?: string;

        original_id?: string;

        original_name?: string;

        part_number_id?: string;

        price?: number;

        product_id?: string;

        rule_id?: number;

        rule_type?: string;

        shipping?: string;

        shop_id?: number;

        shop_reviews_count?: number;

        show_in_autocomplete?: boolean;

        show_in_comparision?: boolean;

        state?: string;

        supplier_id?: {};

        updated?: string;

        url?: string;

        url_hash?: string;

        product_detail_data?: {
          exit_hash?: string;

          recommended_exit_hash?: string;

          dynamic_banner_exit_hash?: string;

          shop_name?: string;

          shop_slug?: string;

          is_recommended?: boolean;

          trusted_shop?: boolean;

          trusted_level?: string;

          partnerships?: Array<{
            name?: string;

            type?: string;
          }>;
        };

        quatro_level?: number;
      }>;

      impressions_count_last_30_day?: number;

      manufacturer?: {
        created?: string;

        created_by?: number;

        edited?: {};

        edited_by?: {};

        enabled?: boolean;

        manufacturer_id?: number;

        monitoring?: boolean;

        name?: string;
      };

      manufacturer_id?: number;

      name?: string;

      parameters?: Array<{
        _id?: string;

        parameter_id?: string;

        parameter_value_id?: string;

        value?: {};
      }>;

      productDescriptions?: Array<{
        created?: string;

        description?: string;

        language_id?: number;

        product_description_id?: string;

        product_id?: string;

        score?: number;

        shop_id?: number;
      }>;

      productReviews?: Array<string>;

      productTrends?: Array<{
        average_price?: number;

        country_id?: number;

        date?: string;

        lowest_price?: number;

        product_id?: number;

        product_trend_id?: string;
      }>;

      product_id?: number;

      show_in_autocomplete?: boolean;

      slugs?: Array<{
        _id?: string;

        article_id?: number;

        branch_id?: number;

        carrier_id?: number;

        category_id?: number;

        company_id?: number;

        created?: string;

        group?: number;

        language_id?: number;

        manufacturer_id?: number;

        old_slug?: string;

        product_id?: number;

        shop_id?: number;

        slug?: string;

        slug_id?: number;

        updated?: string;

        user_id?: number;
      }>;

      state?: string;

      test?: {};

      single_good?: {
        exit_data?: string;

        shop_name?: string;
      };

      reviewAggregations?: Array<{
        language_id?: number;

        count?: number;

        avg?: number;
      }>;

      load_position?: number;

      assetInfo?: {
        count?: number;

        image?: {
          gallery?: Array<string>;

          detail_image?: string;

          listing_image?: string;

          hp_image?: string;
        };

        video?: Array<{
          id?: string;

          url?: string;
        }>;

        model3d?: Array<string>;
      };

      count?: number;

      rank?: number;

      lock?: boolean;

      quatro_level?: number;
    }>;
  }> {
    let path = '/product/create/suggestions';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    if (typeof parameters['manufacturerId'] !== 'undefined') {
      queryParameters['manufacturer_id'] = parameters['manufacturerId'];
    }

    queryParameters['manufacturer_id'] = this.transformParameter(
      queryParameters['manufacturer_id'],
    );

    if (typeof parameters['mainManufacturerId'] !== 'undefined') {
      queryParameters['main_manufacturer_id'] =
        parameters['mainManufacturerId'];
    }

    queryParameters['main_manufacturer_id'] = this.transformParameter(
      queryParameters['main_manufacturer_id'],
    );

    if (typeof parameters['modelNameParamRegex'] !== 'undefined') {
      queryParameters['model_name_param_regex'] =
        parameters['modelNameParamRegex'];
    }

    queryParameters['model_name_param_regex'] = this.transformParameter(
      queryParameters['model_name_param_regex'],
    );

    if (typeof parameters['optional1ParamRegex'] !== 'undefined') {
      queryParameters['optional1_param_regex'] =
        parameters['optional1ParamRegex'];
    }

    queryParameters['optional1_param_regex'] = this.transformParameter(
      queryParameters['optional1_param_regex'],
    );

    if (typeof parameters['optional2ParamRegex'] !== 'undefined') {
      queryParameters['optional2_param_regex'] =
        parameters['optional2ParamRegex'];
    }

    queryParameters['optional2_param_regex'] = this.transformParameter(
      queryParameters['optional2_param_regex'],
    );

    if (typeof parameters['optional3ParamRegex'] !== 'undefined') {
      queryParameters['optional3_param_regex'] =
        parameters['optional3ParamRegex'];
    }

    queryParameters['optional3_param_regex'] = this.transformParameter(
      queryParameters['optional3_param_regex'],
    );

    if (typeof parameters['optional4ParamRegex'] !== 'undefined') {
      queryParameters['optional4_param_regex'] =
        parameters['optional4ParamRegex'];
    }

    queryParameters['optional4_param_regex'] = this.transformParameter(
      queryParameters['optional4_param_regex'],
    );

    if (typeof parameters['optional5ParamRegex'] !== 'undefined') {
      queryParameters['optional5_param_regex'] =
        parameters['optional5ParamRegex'];
    }

    queryParameters['optional5_param_regex'] = this.transformParameter(
      queryParameters['optional5_param_regex'],
    );

    if (typeof parameters['optional6ParamRegex'] !== 'undefined') {
      queryParameters['optional6_param_regex'] =
        parameters['optional6ParamRegex'];
    }

    queryParameters['optional6_param_regex'] = this.transformParameter(
      queryParameters['optional6_param_regex'],
    );

    if (typeof parameters['ean'] !== 'undefined') {
      queryParameters['ean'] = parameters['ean'];
    }

    queryParameters['ean'] = this.transformParameter(queryParameters['ean']);

    if (typeof parameters['partNumber'] !== 'undefined') {
      queryParameters['part_number'] = parameters['partNumber'];
    }

    queryParameters['part_number'] = this.transformParameter(
      queryParameters['part_number'],
    );

    if (typeof parameters['modelNamePrefix'] !== 'undefined') {
      queryParameters['model_name_prefix'] = parameters['modelNamePrefix'];
    }

    queryParameters['model_name_prefix'] = this.transformParameter(
      queryParameters['model_name_prefix'],
    );

    if (typeof parameters['modelNameSuffix'] !== 'undefined') {
      queryParameters['model_name_suffix'] = parameters['modelNameSuffix'];
    }

    queryParameters['model_name_suffix'] = this.transformParameter(
      queryParameters['model_name_suffix'],
    );

    if (typeof parameters['modelNameParam'] !== 'undefined') {
      queryParameters['model_name_param'] = parameters['modelNameParam'];
    }

    queryParameters['model_name_param'] = this.transformParameter(
      queryParameters['model_name_param'],
    );

    if (typeof parameters['optional1Param'] !== 'undefined') {
      queryParameters['optional1_param'] = parameters['optional1Param'];
    }

    queryParameters['optional1_param'] = this.transformParameter(
      queryParameters['optional1_param'],
    );

    if (typeof parameters['optional2Param'] !== 'undefined') {
      queryParameters['optional2_param'] = parameters['optional2Param'];
    }

    queryParameters['optional2_param'] = this.transformParameter(
      queryParameters['optional2_param'],
    );

    if (typeof parameters['optional3Param'] !== 'undefined') {
      queryParameters['optional3_param'] = parameters['optional3Param'];
    }

    queryParameters['optional3_param'] = this.transformParameter(
      queryParameters['optional3_param'],
    );

    if (typeof parameters['optional4Param'] !== 'undefined') {
      queryParameters['optional4_param'] = parameters['optional4Param'];
    }

    queryParameters['optional4_param'] = this.transformParameter(
      queryParameters['optional4_param'],
    );

    if (typeof parameters['optional5Param'] !== 'undefined') {
      queryParameters['optional5_param'] = parameters['optional5Param'];
    }

    queryParameters['optional5_param'] = this.transformParameter(
      queryParameters['optional5_param'],
    );

    if (typeof parameters['optional6Param'] !== 'undefined') {
      queryParameters['optional6_param'] = parameters['optional6Param'];
    }

    queryParameters['optional6_param'] = this.transformParameter(
      queryParameters['optional6_param'],
    );

    if (typeof parameters['optional12Separator'] !== 'undefined') {
      queryParameters['optional1_2_separator'] =
        parameters['optional12Separator'];
    }

    queryParameters['optional1_2_separator'] = this.transformParameter(
      queryParameters['optional1_2_separator'],
    );

    if (typeof parameters['optional23Separator'] !== 'undefined') {
      queryParameters['optional2_3_separator'] =
        parameters['optional23Separator'];
    }

    queryParameters['optional2_3_separator'] = this.transformParameter(
      queryParameters['optional2_3_separator'],
    );

    if (typeof parameters['optional34Separator'] !== 'undefined') {
      queryParameters['optional3_4_separator'] =
        parameters['optional34Separator'];
    }

    queryParameters['optional3_4_separator'] = this.transformParameter(
      queryParameters['optional3_4_separator'],
    );

    if (typeof parameters['optional45Separator'] !== 'undefined') {
      queryParameters['optional4_5_separator'] =
        parameters['optional45Separator'];
    }

    queryParameters['optional4_5_separator'] = this.transformParameter(
      queryParameters['optional4_5_separator'],
    );

    if (typeof parameters['optional56Separator'] !== 'undefined') {
      queryParameters['optional5_6_separator'] =
        parameters['optional56Separator'];
    }

    queryParameters['optional5_6_separator'] = this.transformParameter(
      queryParameters['optional5_6_separator'],
    );

    if (typeof parameters['removedSelectedSuggestions'] !== 'undefined') {
      queryParameters['removed_selected_suggestions'] =
        parameters['removedSelectedSuggestions'];
    }

    queryParameters['removed_selected_suggestions'] = this.transformParameter(
      queryParameters['removed_selected_suggestions'],
    );

    if (typeof parameters['forcedFeedIds'] !== 'undefined') {
      queryParameters['forced_feed_ids'] = parameters['forcedFeedIds'];
    }

    queryParameters['forced_feed_ids'] = this.transformParameter(
      queryParameters['forced_feed_ids'],
    );

    if (typeof parameters['forceAssignToMainProductFeedIds'] !== 'undefined') {
      queryParameters['force_assign_to_main_product_feed_ids'] =
        parameters['forceAssignToMainProductFeedIds'];
    }

    queryParameters[
      'force_assign_to_main_product_feed_ids'
    ] = this.transformParameter(
      queryParameters['force_assign_to_main_product_feed_ids'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get create bulk suggestion
   * @method
   * @name API#getCreateBulkSuggestion
   */
  getCreateBulkSuggestion(
    parameters: {
      filterShopId?: number;
      filterCategoryId?: number;
      filterManufacturerId?: number;
      filterMatchType?: string;
      includeChildrenCategories?: boolean;
      limit?: number;
      shopProgramIds?: Array<number>;
    } = {},
  ): Promise<{
    items?: Array<{
      feed_id?: number;

      name?: string;

      ean?: string;

      part_number?: string;

      shop_name?: string;

      shop_program_id?: number;

      pm_products?: Array<{
        feed_id?: number;

        name?: string;

        ean?: string;

        part_number?: string;

        shop_name?: string;

        shop_program_id?: number;
      }>;
    }>;
  }> {
    let path = '/product/create/bulk';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['filterShopId'] !== 'undefined') {
      queryParameters['filter_shop_id'] = parameters['filterShopId'];
    }

    queryParameters['filter_shop_id'] = this.transformParameter(
      queryParameters['filter_shop_id'],
    );

    if (typeof parameters['filterCategoryId'] !== 'undefined') {
      queryParameters['filter_category_id'] = parameters['filterCategoryId'];
    }

    queryParameters['filter_category_id'] = this.transformParameter(
      queryParameters['filter_category_id'],
    );

    if (typeof parameters['filterManufacturerId'] !== 'undefined') {
      queryParameters['filter_manufacturer_id'] =
        parameters['filterManufacturerId'];
    }

    queryParameters['filter_manufacturer_id'] = this.transformParameter(
      queryParameters['filter_manufacturer_id'],
    );

    if (typeof parameters['filterMatchType'] !== 'undefined') {
      queryParameters['filter_match_type'] = parameters['filterMatchType'];
    }

    queryParameters['filter_match_type'] = this.transformParameter(
      queryParameters['filter_match_type'],
    );

    if (typeof parameters['includeChildrenCategories'] !== 'undefined') {
      queryParameters['include_children_categories'] =
        parameters['includeChildrenCategories'];
    }

    queryParameters['include_children_categories'] = this.transformParameter(
      queryParameters['include_children_categories'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['shopProgramIds'] !== 'undefined') {
      queryParameters['shop_program_ids'] = parameters['shopProgramIds'];
    }

    queryParameters['shop_program_ids'] = this.transformParameter(
      queryParameters['shop_program_ids'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Merge two products
   * @method
   * @name API#mergeProducts
   */
  mergeProducts(
    productId1: number,
    productId2: number,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/product/merge/{productId1}/{productId2}/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{productId1}', productId1.toString());

    path = path.replace('{productId2}', productId2.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads products statistics list
   * @method
   * @name API#loadProductsList
   */
  loadProductsList(
    parameters: {
      productId?: Array<number>;
      startDate?: string;
      endDate?: string;
      limit?: number;
      offset?: number;
      sortType?: string;
      sortOrder?: string;
      country?: number;
      categoryIds?: Array<number>;
      shopIds?: Array<number>;
      source?: number;
    } = {},
  ): Promise<{
    total?: number;

    items?: Array<{
      _id?: string;

      product_name?: string;

      category_name?: string;

      cpc?: number;

      total_clicks?: number;

      total_impressions?: number;

      total_payment?: number;

      active_shops?: number;

      active_offers?: number;
    }>;
  }> {
    let path = '/product/stats';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['productId'] !== 'undefined') {
      queryParameters['product_id'] = parameters['productId'];
    }

    queryParameters['product_id'] = this.transformParameter(
      queryParameters['product_id'],
    );

    if (typeof parameters['startDate'] !== 'undefined') {
      queryParameters['start_date'] = parameters['startDate'];
    }

    queryParameters['start_date'] = this.transformParameter(
      queryParameters['start_date'],
    );

    if (typeof parameters['endDate'] !== 'undefined') {
      queryParameters['end_date'] = parameters['endDate'];
    }

    queryParameters['end_date'] = this.transformParameter(
      queryParameters['end_date'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sortType'] !== 'undefined') {
      queryParameters['sortType'] = parameters['sortType'];
    }

    queryParameters['sortType'] = this.transformParameter(
      queryParameters['sortType'],
    );

    if (typeof parameters['sortOrder'] !== 'undefined') {
      queryParameters['sortOrder'] = parameters['sortOrder'];
    }

    queryParameters['sortOrder'] = this.transformParameter(
      queryParameters['sortOrder'],
    );

    if (typeof parameters['country'] !== 'undefined') {
      queryParameters['country'] = parameters['country'];
    }

    queryParameters['country'] = this.transformParameter(
      queryParameters['country'],
    );

    if (typeof parameters['categoryIds'] !== 'undefined') {
      queryParameters['category_ids'] = parameters['categoryIds'];
    }

    queryParameters['category_ids'] = this.transformParameter(
      queryParameters['category_ids'],
    );

    if (typeof parameters['shopIds'] !== 'undefined') {
      queryParameters['shop_ids'] = parameters['shopIds'];
    }

    queryParameters['shop_ids'] = this.transformParameter(
      queryParameters['shop_ids'],
    );

    if (typeof parameters['source'] !== 'undefined') {
      queryParameters['source'] = parameters['source'];
    }

    queryParameters['source'] = this.transformParameter(
      queryParameters['source'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads product statistics general
   * @method
   * @name API#loadGeneralProductsListStats
   */
  loadGeneralProductsListStats(
    parameters: {
      startDate?: string;
      endDate?: string;
      categoryIds?: Array<number>;
      country?: number;
      productId?: Array<number>;
      shopIds?: Array<number>;
      source?: number;
    } = {},
  ): Promise<{
    _id?: string;

    clicks?: number;

    impressions?: number;

    payment?: number;

    count?: number;

    total_offers?: number;

    total_shops?: number;
  }> {
    let path = '/product/stats/total';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['startDate'] !== 'undefined') {
      queryParameters['start_date'] = parameters['startDate'];
    }

    queryParameters['start_date'] = this.transformParameter(
      queryParameters['start_date'],
    );

    if (typeof parameters['endDate'] !== 'undefined') {
      queryParameters['end_date'] = parameters['endDate'];
    }

    queryParameters['end_date'] = this.transformParameter(
      queryParameters['end_date'],
    );

    if (typeof parameters['categoryIds'] !== 'undefined') {
      queryParameters['category_ids'] = parameters['categoryIds'];
    }

    queryParameters['category_ids'] = this.transformParameter(
      queryParameters['category_ids'],
    );

    if (typeof parameters['country'] !== 'undefined') {
      queryParameters['country'] = parameters['country'];
    }

    queryParameters['country'] = this.transformParameter(
      queryParameters['country'],
    );

    if (typeof parameters['productId'] !== 'undefined') {
      queryParameters['product_id'] = parameters['productId'];
    }

    queryParameters['product_id'] = this.transformParameter(
      queryParameters['product_id'],
    );

    if (typeof parameters['shopIds'] !== 'undefined') {
      queryParameters['shop_ids'] = parameters['shopIds'];
    }

    queryParameters['shop_ids'] = this.transformParameter(
      queryParameters['shop_ids'],
    );

    if (typeof parameters['source'] !== 'undefined') {
      queryParameters['source'] = parameters['source'];
    }

    queryParameters['source'] = this.transformParameter(
      queryParameters['source'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads product statistics daily
   * @method
   * @name API#loadGeneralDailyProductsListStats
   */
  loadGeneralDailyProductsListStats(
    parameters: {
      startDate?: string;
      endDate?: string;
      categoryIds?: Array<number>;
      country?: number;
      productId?: Array<number>;
      shopIds?: Array<number>;
      source?: number;
    } = {},
  ): Promise<{
    items?: Array<{
      date?: string;

      clicks?: number;

      impressions?: number;

      payment?: number;

      total_offers?: number;

      total_shops?: number;
    }>;
  }> {
    let path = '/product/stats/daily';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['startDate'] !== 'undefined') {
      queryParameters['start_date'] = parameters['startDate'];
    }

    queryParameters['start_date'] = this.transformParameter(
      queryParameters['start_date'],
    );

    if (typeof parameters['endDate'] !== 'undefined') {
      queryParameters['end_date'] = parameters['endDate'];
    }

    queryParameters['end_date'] = this.transformParameter(
      queryParameters['end_date'],
    );

    if (typeof parameters['categoryIds'] !== 'undefined') {
      queryParameters['category_ids'] = parameters['categoryIds'];
    }

    queryParameters['category_ids'] = this.transformParameter(
      queryParameters['category_ids'],
    );

    if (typeof parameters['country'] !== 'undefined') {
      queryParameters['country'] = parameters['country'];
    }

    queryParameters['country'] = this.transformParameter(
      queryParameters['country'],
    );

    if (typeof parameters['productId'] !== 'undefined') {
      queryParameters['product_id'] = parameters['productId'];
    }

    queryParameters['product_id'] = this.transformParameter(
      queryParameters['product_id'],
    );

    if (typeof parameters['shopIds'] !== 'undefined') {
      queryParameters['shop_ids'] = parameters['shopIds'];
    }

    queryParameters['shop_ids'] = this.transformParameter(
      queryParameters['shop_ids'],
    );

    if (typeof parameters['source'] !== 'undefined') {
      queryParameters['source'] = parameters['source'];
    }

    queryParameters['source'] = this.transformParameter(
      queryParameters['source'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads products information
   * @method
   * @name API#loadPMProducts
   */
  loadPMProducts(
    parameters: {
      query?: string;
      limit?: number;
      offset?: number;
      clickType?: number;
      filterManufacturerName?: Array<string>;
      filterCategoryName?: Array<string>;
      filterMinPrice?: number;
      filterMaxPrice?: number;
      filterShopState?: string;
      sortType?: string;
      sortOrder?: string;
      filterIsPaired?: boolean;
      filterShopIds?: Array<number>;
      filterCategoryId?: Array<number>;
      filterManufacturerId?: Array<number>;
      queryType?: string;
      searchStrategy?: string;
      filterWithoutEmptyNames?: boolean;
      filterNotCategoryIds?: Array<null | string>;
      filterNotManufacturerIds?: Array<null | string>;
      filterFeedId?: Array<number>;
      isNotSkipped?: boolean;
    } = {},
    extraHeaders?: {
      xOriginalUserAgent?: string;
      xOriginalIpAddress?: string;
      xOriginalSessionId?: string;
    },
  ): Promise<{
    limit?: number;

    offset?: number;

    total?: number;

    pm_products?: Array<{
      affiliate_id?: number;

      category?: string;

      category_hash?: string;

      category_id?: number;

      crawled?: string;

      created?: string;

      description?: string;

      description_path_hash?: string;

      ean?: string;

      feed_id?: number;

      image?: string;

      image_hash?: string;

      image_path_hash?: string;

      manufacturer?: string;

      manufacturer_id?: number;

      name?: string;

      original_id?: string;

      part_number?: string;

      price?: number;

      price_path_hash?: string;

      shop?: Array<{
        _id?: string;

        benefits?: string;

        billing_data_published?: boolean;

        company_id?: number;

        contact_email?: string;

        contact_phone?: string;

        country_id?: number;

        created?: string;

        created_by?: number;

        description?: string;

        edited?: string;

        edited_by?: number;

        feed_url?: string;

        has_backlink?: boolean;

        name?: string;

        next_parse_time?: string;

        original_program_id?: number;

        parse_interval?: number;

        parse_time?: string;

        partnerships?: number;

        ppc?: Array<{
          approx?: number;

          average_position?: number;

          average_ppc?: number;

          category_id?: number;

          country_id?: number;

          ppc?: number;

          product_id?: number;

          shop_id?: number;

          shop_ppc_id?: number;

          user_id?: number;
        }>;

        program_id?: number;

        shop_id?: number;

        slugs?: Array<{
          _id?: string;

          article_id?: number;

          branch_id?: number;

          carrier_id?: number;

          category_id?: number;

          company_id?: number;

          created?: string;

          group?: number;

          language_id?: number;

          manufacturer_id?: number;

          old_slug?: string;

          product_id?: number;

          shop_id?: number;

          slug?: string;

          slug_id?: number;

          updated?: string;

          user_id?: number;
        }>;

        start_date?: string;

        state?: string;

        trusted_days?: number;

        trusted_email?: string;

        trusted_shop?: boolean;

        url?: string;
      }>;

      shop_id?: number;

      supplier_id?: number;

      updated?: string;

      url?: string;

      url_hash?: string;

      exit_link_hash?: string;

      load_position?: number;
    }>;
  }> {
    let path = '/pmproducts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    queryParameters['limit'] = 10;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['clickType'] !== 'undefined') {
      queryParameters['click_type'] = parameters['clickType'];
    }

    queryParameters['click_type'] = this.transformParameter(
      queryParameters['click_type'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalUserAgent'] !== 'undefined'
    ) {
      headers.append(
        'x-original-user-agent',
        extraHeaders['xOriginalUserAgent']!,
      );
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalIpAddress'] !== 'undefined'
    ) {
      headers.append(
        'x-original-ip-address',
        extraHeaders['xOriginalIpAddress']!,
      );
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalSessionId'] !== 'undefined'
    ) {
      headers.append(
        'x-original-session-id',
        extraHeaders['xOriginalSessionId']!,
      );
    }

    if (typeof parameters['filterManufacturerName'] !== 'undefined') {
      queryParameters['filter_manufacturer_name'] =
        parameters['filterManufacturerName'];
    }

    queryParameters['filter_manufacturer_name'] = this.transformParameter(
      queryParameters['filter_manufacturer_name'],
    );

    if (typeof parameters['filterCategoryName'] !== 'undefined') {
      queryParameters['filter_category_name'] =
        parameters['filterCategoryName'];
    }

    queryParameters['filter_category_name'] = this.transformParameter(
      queryParameters['filter_category_name'],
    );

    if (typeof parameters['filterMinPrice'] !== 'undefined') {
      queryParameters['filter_min_price'] = parameters['filterMinPrice'];
    }

    queryParameters['filter_min_price'] = this.transformParameter(
      queryParameters['filter_min_price'],
    );

    if (typeof parameters['filterMaxPrice'] !== 'undefined') {
      queryParameters['filter_max_price'] = parameters['filterMaxPrice'];
    }

    queryParameters['filter_max_price'] = this.transformParameter(
      queryParameters['filter_max_price'],
    );

    if (typeof parameters['filterShopState'] !== 'undefined') {
      queryParameters['filter_shop_state'] = parameters['filterShopState'];
    }

    queryParameters['filter_shop_state'] = this.transformParameter(
      queryParameters['filter_shop_state'],
    );

    if (typeof parameters['sortType'] !== 'undefined') {
      queryParameters['sort_type'] = parameters['sortType'];
    }

    queryParameters['sort_type'] = this.transformParameter(
      queryParameters['sort_type'],
    );

    if (typeof parameters['sortOrder'] !== 'undefined') {
      queryParameters['sort_order'] = parameters['sortOrder'];
    }

    queryParameters['sort_order'] = this.transformParameter(
      queryParameters['sort_order'],
    );

    if (typeof parameters['filterIsPaired'] !== 'undefined') {
      queryParameters['filter_is_paired'] = parameters['filterIsPaired'];
    }

    queryParameters['filter_is_paired'] = this.transformParameter(
      queryParameters['filter_is_paired'],
    );

    if (typeof parameters['filterShopIds'] !== 'undefined') {
      queryParameters['filter_shop_ids'] = parameters['filterShopIds'];
    }

    queryParameters['filter_shop_ids'] = this.transformParameter(
      queryParameters['filter_shop_ids'],
    );

    if (typeof parameters['filterCategoryId'] !== 'undefined') {
      queryParameters['filter_category_id'] = parameters['filterCategoryId'];
    }

    queryParameters['filter_category_id'] = this.transformParameter(
      queryParameters['filter_category_id'],
    );

    if (typeof parameters['filterManufacturerId'] !== 'undefined') {
      queryParameters['filter_manufacturer_id'] =
        parameters['filterManufacturerId'];
    }

    queryParameters['filter_manufacturer_id'] = this.transformParameter(
      queryParameters['filter_manufacturer_id'],
    );

    if (typeof parameters['queryType'] !== 'undefined') {
      queryParameters['query_type'] = parameters['queryType'];
    }

    queryParameters['query_type'] = this.transformParameter(
      queryParameters['query_type'],
    );

    if (typeof parameters['searchStrategy'] !== 'undefined') {
      queryParameters['search_strategy'] = parameters['searchStrategy'];
    }

    queryParameters['search_strategy'] = this.transformParameter(
      queryParameters['search_strategy'],
    );

    if (typeof parameters['filterWithoutEmptyNames'] !== 'undefined') {
      queryParameters['filter_without_empty_names'] =
        parameters['filterWithoutEmptyNames'];
    }

    queryParameters['filter_without_empty_names'] = this.transformParameter(
      queryParameters['filter_without_empty_names'],
    );

    if (typeof parameters['filterNotCategoryIds'] !== 'undefined') {
      queryParameters['filter_not_category_ids'] =
        parameters['filterNotCategoryIds'];
    }

    queryParameters['filter_not_category_ids'] = this.transformParameter(
      queryParameters['filter_not_category_ids'],
    );

    if (typeof parameters['filterNotManufacturerIds'] !== 'undefined') {
      queryParameters['filter_not_manufacturer_ids'] =
        parameters['filterNotManufacturerIds'];
    }

    queryParameters['filter_not_manufacturer_ids'] = this.transformParameter(
      queryParameters['filter_not_manufacturer_ids'],
    );

    if (typeof parameters['filterFeedId'] !== 'undefined') {
      queryParameters['filter_feed_id'] = parameters['filterFeedId'];
    }

    queryParameters['filter_feed_id'] = this.transformParameter(
      queryParameters['filter_feed_id'],
    );

    if (typeof parameters['isNotSkipped'] !== 'undefined') {
      queryParameters['is_not_skipped'] = parameters['isNotSkipped'];
    }

    queryParameters['is_not_skipped'] = this.transformParameter(
      queryParameters['is_not_skipped'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update pmproducts
   * @method
   * @name API#updatePmProducts
   */
  updatePmProducts(
    parameters: {} = {},
    body: Array<{
      feed_id?: number;

      category_id?: number;

      manufacturer_id?: number;

      skipped?: number;
    }>,
  ): Promise<{
    success?: boolean;
  }> {
    let path = '/pmproducts';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'PATCH',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Loads loadPmShopStats information
   * @method
   * @name API#loadPmShopStats
   */
  loadPmShopStats(
    parameters: {
      filterShopIds?: Array<number>;
      filterCategoryIds?: Array<number>;
      filterManufacturerIds?: Array<number>;
      filterNotCategoryIds?: Array<null | string>;
      filterNotManufacturerIds?: Array<null | string>;
      withOverallStats?: boolean;
      withCategoryStats?: boolean;
      withManufacturerStats?: boolean;
      withNames?: boolean;
      ignoreEmptyNames?: boolean;
      ignoreUnassigned?: boolean;
      isNotSkipped?: boolean;
    } = {},
  ): Promise<{
    overall_stats?: {
      count?: number;
    };

    category_stats?: Array<{
      category_id?: number;

      count?: number;

      name?: string;
    }>;

    manufacturer_stats?: Array<{
      manufacturer_id?: number;

      count?: number;

      name?: string;
    }>;
  }> {
    let path = '/pmproducts/stats';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['filterShopIds'] !== 'undefined') {
      queryParameters['filter_shop_ids'] = parameters['filterShopIds'];
    }

    queryParameters['filter_shop_ids'] = this.transformParameter(
      queryParameters['filter_shop_ids'],
    );

    if (typeof parameters['filterCategoryIds'] !== 'undefined') {
      queryParameters['filter_category_ids'] = parameters['filterCategoryIds'];
    }

    queryParameters['filter_category_ids'] = this.transformParameter(
      queryParameters['filter_category_ids'],
    );

    if (typeof parameters['filterManufacturerIds'] !== 'undefined') {
      queryParameters['filter_manufacturer_ids'] =
        parameters['filterManufacturerIds'];
    }

    queryParameters['filter_manufacturer_ids'] = this.transformParameter(
      queryParameters['filter_manufacturer_ids'],
    );

    if (typeof parameters['filterNotCategoryIds'] !== 'undefined') {
      queryParameters['filter_not_category_ids'] =
        parameters['filterNotCategoryIds'];
    }

    queryParameters['filter_not_category_ids'] = this.transformParameter(
      queryParameters['filter_not_category_ids'],
    );

    if (typeof parameters['filterNotManufacturerIds'] !== 'undefined') {
      queryParameters['filter_not_manufacturer_ids'] =
        parameters['filterNotManufacturerIds'];
    }

    queryParameters['filter_not_manufacturer_ids'] = this.transformParameter(
      queryParameters['filter_not_manufacturer_ids'],
    );

    if (typeof parameters['withOverallStats'] !== 'undefined') {
      queryParameters['with_overall_stats'] = parameters['withOverallStats'];
    }

    queryParameters['with_overall_stats'] = this.transformParameter(
      queryParameters['with_overall_stats'],
    );

    if (typeof parameters['withCategoryStats'] !== 'undefined') {
      queryParameters['with_category_stats'] = parameters['withCategoryStats'];
    }

    queryParameters['with_category_stats'] = this.transformParameter(
      queryParameters['with_category_stats'],
    );

    if (typeof parameters['withManufacturerStats'] !== 'undefined') {
      queryParameters['with_manufacturer_stats'] =
        parameters['withManufacturerStats'];
    }

    queryParameters['with_manufacturer_stats'] = this.transformParameter(
      queryParameters['with_manufacturer_stats'],
    );

    if (typeof parameters['withNames'] !== 'undefined') {
      queryParameters['with_names'] = parameters['withNames'];
    }

    queryParameters['with_names'] = this.transformParameter(
      queryParameters['with_names'],
    );

    if (typeof parameters['ignoreEmptyNames'] !== 'undefined') {
      queryParameters['ignore_empty_names'] = parameters['ignoreEmptyNames'];
    }

    queryParameters['ignore_empty_names'] = this.transformParameter(
      queryParameters['ignore_empty_names'],
    );

    if (typeof parameters['ignoreUnassigned'] !== 'undefined') {
      queryParameters['ignore_unassigned'] = parameters['ignoreUnassigned'];
    }

    queryParameters['ignore_unassigned'] = this.transformParameter(
      queryParameters['ignore_unassigned'],
    );

    if (typeof parameters['isNotSkipped'] !== 'undefined') {
      queryParameters['is_not_skipped'] = parameters['isNotSkipped'];
    }

    queryParameters['is_not_skipped'] = this.transformParameter(
      queryParameters['is_not_skipped'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Group categories from pmProducts
   * @method
   * @name API#pmCategoriesGroup
   */
  pmCategoriesGroup(
    parameters: {
      shopIds?: Array<number>;
    } = {},
  ): Promise<{
    categories?: Array<{
      category_id?: number;

      category_name?: string;

      count?: number;
    }>;
  }> {
    let path = '/pmproducts/categories/group';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['shopIds'] !== 'undefined') {
      queryParameters['shop_ids'] = parameters['shopIds'];
    }

    queryParameters['shop_ids'] = this.transformParameter(
      queryParameters['shop_ids'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get count of all reviews of product with id {productId}; optionally just those with a specified languageId
   * @method
   * @name API#getProductReviewCount
   */
  getProductReviewCount(
    productId: number,
    parameters: {
      languageId?: number;
    } = {},
  ): Promise<number> {
    let path = '/reviews/product/count_reviews/{productId}/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId.toString());

    if (typeof parameters['languageId'] !== 'undefined') {
      queryParameters['languageId'] = parameters['languageId'];
    }

    queryParameters['languageId'] = this.transformParameter(
      queryParameters['languageId'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Returns all allowed (user_text type) reviews of product; optionally just those with a specified languageId
   * @method
   * @name API#getProductReviews
   */
  getProductReviews(
    productId: number,
    parameters: {
      languageId?: number;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<
    Array<{
      product_review_id: number;

      product_id: number;

      evaluation_for: string;

      evaluation_against: string;

      evaluation_summary: string;

      score: number;

      created: string;

      name: string;

      like: number;

      dislike: number;
    }>
  > {
    let path = '/reviews/product/{productId}/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId.toString());

    if (typeof parameters['languageId'] !== 'undefined') {
      queryParameters['languageId'] = parameters['languageId'];
    }

    queryParameters['languageId'] = this.transformParameter(
      queryParameters['languageId'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get avarage score and vote count for product by his id; optionally just those with a specified languageId
   * @method
   * @name API#getProductRatingSummary
   */
  getProductRatingSummary(
    productId: number,
    parameters: {
      languageId?: number;
    } = {},
  ): Promise<{
    vote_count: number;

    avg_score: number;
  }> {
    let path = '/reviews/product/summary/{productId}/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId.toString());

    if (typeof parameters['languageId'] !== 'undefined') {
      queryParameters['languageId'] = parameters['languageId'];
    }

    queryParameters['languageId'] = this.transformParameter(
      queryParameters['languageId'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Save product review
   * @method
   * @name API#saveProductReview
   */
  saveProductReview(
    productId: number,
    languageId: number,
    parameters: {} = {},
    body: {
      name?: string;

      recommend?: boolean;

      evaluation_for?: string;

      evaluation_against?: string;

      evaluation_summary?: string;

      score?: number;
    },
    extraHeaders?: {
      xOriginalIpAddress?: string;
    },
  ): Promise<{
    success?: boolean;
  }> {
    let path = '/products/{productId}/{languageId}/reviews/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId.toString());

    path = path.replace('{languageId}', languageId.toString());

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalIpAddress'] !== 'undefined'
    ) {
      headers.append(
        'x-original-ip-address',
        extraHeaders['xOriginalIpAddress']!,
      );
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Vote product review
   * @method
   * @name API#voteProductReview
   */
  voteProductReview(
    productId: number,
    reviewId: string,
    parameters: {} = {},
    body: {
      type?: string;

      amount?: number;
    },
    extraHeaders?: {
      xOriginalUserAgent?: string;
      xOriginalIpAddress?: string;
    },
  ): Promise<{
    success?: boolean;
  }> {
    let path = '/products/{productId}/reviews/{reviewId}/vote';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{productId}', productId.toString());

    path = path.replace('{reviewId}', reviewId.toString());

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalUserAgent'] !== 'undefined'
    ) {
      headers.append(
        'x-original-user-agent',
        extraHeaders['xOriginalUserAgent']!,
      );
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalIpAddress'] !== 'undefined'
    ) {
      headers.append(
        'x-original-ip-address',
        extraHeaders['xOriginalIpAddress']!,
      );
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Loads categories information
   * @method
   * @name API#loadCategories
   */
  loadCategories(
    parameters: {
      query?: string;
      limit?: number;
      offset?: number;
      countryId?: number;
      sortType?: string;
      sortOrder?: string;
      withSlugs?: string;
      parentId?: number;
      categoryIds?: Array<number>;
      filterCategoryName?: string;
      source?: number;
      minProducts?: number;
      isFilter?: boolean;
      isLast?: boolean;
    } = {},
  ): Promise<{
    categories?: Array<{
      _id?: string;

      accessories_param?: boolean;

      category_id?: number;

      category_merging?: boolean;

      created?: string;

      created_by?: number;

      description?: string;

      edited?: string;

      edited_by?: number;

      enabled?: boolean;

      filter_name?: string;

      info?: Array<{
        average_ppc?: number;

        average_price?: number;

        category_id?: number;

        category_info_id?: number;

        clickthroughs?: number;

        country_id?: number;

        highest_price?: number;

        impressions?: number;

        lowest_price?: number;

        num_products?: number;

        updated?: string;
      }>;

      is_adult?: boolean;

      is_filter?: boolean;

      manufacturer_merging?: boolean;

      manufacturer_param?: boolean;

      manufacturer_state?: boolean;

      match_merging?: boolean;

      model_name_param?: boolean;

      model_name_state?: boolean;

      optional1_param?: string;

      optional1_state?: boolean;

      optional2_param?: string;

      optional2_state?: boolean;

      optional3_param?: string;

      optional3_state?: boolean;

      optional4_param?: string;

      optional4_state?: boolean;

      optional5_param?: string;

      optional5_state?: boolean;

      optional6_param?: string;

      optional6_state?: boolean;

      parent_id?: number;

      price_difference?: number;

      product_count?: {};

      slugs?: Array<{
        _id?: string;

        article_id?: number;

        branch_id?: number;

        carrier_id?: number;

        category_id?: number;

        company_id?: number;

        created?: string;

        group?: number;

        language_id?: number;

        manufacturer_id?: number;

        old_slug?: string;

        product_id?: number;

        shop_id?: number;

        slug?: string;

        slug_id?: number;

        updated?: string;

        user_id?: number;
      }>;

      translations?: Array<{
        category_data_id?: number;

        category_id?: number;

        description?: string;

        short_description?: string;

        full_path?: string;

        language_id?: number;

        name?: string;

        name_short?: string;

        name_singular?: string;

        name_singular_short?: string;

        slug?: string;
      }>;

      tree_left?: number;

      tree_level?: number;

      tree_right?: number;

      use_automatic_assigns?: boolean;

      children?: Array<{}>;

      parents_names?: Array<string>;

      manufacturer_aggregations?: Array<{
        country_id?: number;

        manufacturer_id?: number;

        count?: number;
      }>;

      parameters?: Array<{
        _id?: string;

        parameter_id?: string;

        position?: number;

        translations?: Array<{
          language_id?: number;

          show?: boolean;

          index?: boolean;

          expand?: boolean;
        }>;
      }>;

      landing_pages?: Array<{
        _id?: string;

        parameters?: Array<{
          parameter_id?: string;

          parameter_value_id?: string;
        }>;

        manufacturers?: Array<number>;

        categories?: Array<number>;

        sort?: {
          type?: string | null;

          order?: string | null;
        };

        price_from?: number | null;

        price_to?: number | null;

        translations?: Array<{
          active?: boolean;

          title?: string | null;

          header?: string | null;

          short_description?: string | null;

          description?: string | null;

          language_id?: number;
        }>;
      }>;

      listing_links?: Array<{
        language_id?: number;

        position?: number;

        name?: string;

        url?: string;
      }>;

      pn_pairing_enabled?: boolean;
    }>;
  }> {
    let path = '/categories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['countryId'] !== 'undefined') {
      queryParameters['country_id'] = parameters['countryId'];
    }

    queryParameters['country_id'] = this.transformParameter(
      queryParameters['country_id'],
    );

    if (typeof parameters['sortType'] !== 'undefined') {
      queryParameters['sort_type'] = parameters['sortType'];
    }

    queryParameters['sort_type'] = this.transformParameter(
      queryParameters['sort_type'],
    );

    if (typeof parameters['sortOrder'] !== 'undefined') {
      queryParameters['sort_order'] = parameters['sortOrder'];
    }

    queryParameters['sort_order'] = this.transformParameter(
      queryParameters['sort_order'],
    );

    if (typeof parameters['withSlugs'] !== 'undefined') {
      queryParameters['with_slugs'] = parameters['withSlugs'];
    }

    queryParameters['with_slugs'] = this.transformParameter(
      queryParameters['with_slugs'],
    );

    if (typeof parameters['parentId'] !== 'undefined') {
      queryParameters['parent_id'] = parameters['parentId'];
    }

    queryParameters['parent_id'] = this.transformParameter(
      queryParameters['parent_id'],
    );

    if (typeof parameters['categoryIds'] !== 'undefined') {
      queryParameters['category_ids'] = parameters['categoryIds'];
    }

    queryParameters['category_ids'] = this.transformParameter(
      queryParameters['category_ids'],
    );

    if (typeof parameters['filterCategoryName'] !== 'undefined') {
      queryParameters['filter_category_name'] =
        parameters['filterCategoryName'];
    }

    queryParameters['filter_category_name'] = this.transformParameter(
      queryParameters['filter_category_name'],
    );

    if (typeof parameters['source'] !== 'undefined') {
      queryParameters['source'] = parameters['source'];
    }

    queryParameters['source'] = this.transformParameter(
      queryParameters['source'],
    );

    if (typeof parameters['minProducts'] !== 'undefined') {
      queryParameters['min_products'] = parameters['minProducts'];
    }

    queryParameters['min_products'] = this.transformParameter(
      queryParameters['min_products'],
    );

    if (typeof parameters['isFilter'] !== 'undefined') {
      queryParameters['is_filter'] = parameters['isFilter'];
    }

    queryParameters['is_filter'] = this.transformParameter(
      queryParameters['is_filter'],
    );

    if (typeof parameters['isLast'] !== 'undefined') {
      queryParameters['is_last'] = parameters['isLast'];
    }

    queryParameters['is_last'] = this.transformParameter(
      queryParameters['is_last'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads categories tree
   * @method
   * @name API#loadCategoriesTree
   */
  loadCategoriesTree(
    parameters: {
      parentId?: number;
      maxDepth?: number;
      projectType?: string;
      withSlugs?: boolean;
    } = {},
  ): Promise<{
    categories?: Array<{
      _id?: string;

      accessories_param?: boolean;

      category_id?: number;

      category_merging?: boolean;

      created?: string;

      created_by?: number;

      description?: string;

      edited?: string;

      edited_by?: number;

      enabled?: boolean;

      filter_name?: string;

      info?: Array<{
        average_ppc?: number;

        average_price?: number;

        category_id?: number;

        category_info_id?: number;

        clickthroughs?: number;

        country_id?: number;

        highest_price?: number;

        impressions?: number;

        lowest_price?: number;

        num_products?: number;

        updated?: string;
      }>;

      is_adult?: boolean;

      is_filter?: boolean;

      manufacturer_merging?: boolean;

      manufacturer_param?: boolean;

      manufacturer_state?: boolean;

      match_merging?: boolean;

      model_name_param?: boolean;

      model_name_state?: boolean;

      optional1_param?: string;

      optional1_state?: boolean;

      optional2_param?: string;

      optional2_state?: boolean;

      optional3_param?: string;

      optional3_state?: boolean;

      optional4_param?: string;

      optional4_state?: boolean;

      optional5_param?: string;

      optional5_state?: boolean;

      optional6_param?: string;

      optional6_state?: boolean;

      parent_id?: number;

      price_difference?: number;

      product_count?: {};

      slugs?: Array<{
        _id?: string;

        article_id?: number;

        branch_id?: number;

        carrier_id?: number;

        category_id?: number;

        company_id?: number;

        created?: string;

        group?: number;

        language_id?: number;

        manufacturer_id?: number;

        old_slug?: string;

        product_id?: number;

        shop_id?: number;

        slug?: string;

        slug_id?: number;

        updated?: string;

        user_id?: number;
      }>;

      translations?: Array<{
        category_data_id?: number;

        category_id?: number;

        description?: string;

        short_description?: string;

        full_path?: string;

        language_id?: number;

        name?: string;

        name_short?: string;

        name_singular?: string;

        name_singular_short?: string;

        slug?: string;
      }>;

      tree_left?: number;

      tree_level?: number;

      tree_right?: number;

      use_automatic_assigns?: boolean;

      children?: Array<{}>;

      parents_names?: Array<string>;

      manufacturer_aggregations?: Array<{
        country_id?: number;

        manufacturer_id?: number;

        count?: number;
      }>;

      parameters?: Array<{
        _id?: string;

        parameter_id?: string;

        position?: number;

        translations?: Array<{
          language_id?: number;

          show?: boolean;

          index?: boolean;

          expand?: boolean;
        }>;
      }>;

      landing_pages?: Array<{
        _id?: string;

        parameters?: Array<{
          parameter_id?: string;

          parameter_value_id?: string;
        }>;

        manufacturers?: Array<number>;

        categories?: Array<number>;

        sort?: {
          type?: string | null;

          order?: string | null;
        };

        price_from?: number | null;

        price_to?: number | null;

        translations?: Array<{
          active?: boolean;

          title?: string | null;

          header?: string | null;

          short_description?: string | null;

          description?: string | null;

          language_id?: number;
        }>;
      }>;

      listing_links?: Array<{
        language_id?: number;

        position?: number;

        name?: string;

        url?: string;
      }>;

      pn_pairing_enabled?: boolean;
    }>;
  }> {
    let path = '/categories/tree';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['parentId'] !== 'undefined') {
      queryParameters['parent_id'] = parameters['parentId'];
    }

    queryParameters['parent_id'] = this.transformParameter(
      queryParameters['parent_id'],
    );

    if (typeof parameters['maxDepth'] !== 'undefined') {
      queryParameters['max_depth'] = parameters['maxDepth'];
    }

    queryParameters['max_depth'] = this.transformParameter(
      queryParameters['max_depth'],
    );

    if (typeof parameters['projectType'] !== 'undefined') {
      queryParameters['project_type'] = parameters['projectType'];
    }

    queryParameters['project_type'] = this.transformParameter(
      queryParameters['project_type'],
    );

    if (typeof parameters['withSlugs'] !== 'undefined') {
      queryParameters['with_slugs'] = parameters['withSlugs'];
    }

    queryParameters['with_slugs'] = this.transformParameter(
      queryParameters['with_slugs'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads categories tree
   * @method
   * @name API#loadCategoriesTreeList
   */
  loadCategoriesTreeList(
    parameters: {
      parentId?: number;
      langId?: number;
      maxDepth?: number;
      delimiter?: string;
      query?: string;
    } = {},
  ): Promise<{
    categoryTreeList?: Array<{
      lastCategoryId?: number;

      treeName?: string;
    }>;
  }> {
    let path = '/categories/tree/list';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['parentId'] !== 'undefined') {
      queryParameters['parent_id'] = parameters['parentId'];
    }

    queryParameters['parent_id'] = this.transformParameter(
      queryParameters['parent_id'],
    );

    if (typeof parameters['langId'] !== 'undefined') {
      queryParameters['lang_id'] = parameters['langId'];
    }

    queryParameters['lang_id'] = this.transformParameter(
      queryParameters['lang_id'],
    );

    if (typeof parameters['maxDepth'] !== 'undefined') {
      queryParameters['max_depth'] = parameters['maxDepth'];
    }

    queryParameters['max_depth'] = this.transformParameter(
      queryParameters['max_depth'],
    );

    if (typeof parameters['delimiter'] !== 'undefined') {
      queryParameters['delimiter'] = parameters['delimiter'];
    }

    queryParameters['delimiter'] = this.transformParameter(
      queryParameters['delimiter'],
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Export categories for QuarticOn
   * @method
   * @name API#loadCategoriesQuarticon
   */
  loadCategoriesQuarticon(
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    total?: number;

    offset?: number;

    limit?: number;

    categories?: Array<{
      _id?: string;

      category_id?: number;

      tree_left?: number;

      tree_right?: number;

      tree_level?: number;

      image?: string;

      translations?: Array<{
        name?: string;

        language_id?: number;

        full_path?: string;
      }>;
    }>;
  }> {
    let path = '/categories/quarticon';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    queryParameters['limit'] = 100;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads categories statistics
   * @method
   * @name API#loadCategoryList
   */
  loadCategoryList(
    parameters: {
      query?: string;
      startDate?: string;
      endDate?: string;
      limit?: number;
      offset?: number;
      sortType?: string;
      sortOrder?: string;
      country?: number;
      categoryIds?: Array<number>;
      source?: number;
    } = {},
  ): Promise<{
    total?: number;

    items?: Array<{
      _id?: string;

      category_name?: string;

      clicks?: number;

      impressions?: number;

      payment?: number;

      count?: number;

      total_category_products?: number;

      active_total_shops?: number;

      active_total_offers?: number;

      avg_offers_product?: number;

      cpc?: number;
    }>;
  }> {
    let path = '/category/category-list';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['startDate'] !== 'undefined') {
      queryParameters['start_date'] = parameters['startDate'];
    }

    queryParameters['start_date'] = this.transformParameter(
      queryParameters['start_date'],
    );

    if (typeof parameters['endDate'] !== 'undefined') {
      queryParameters['end_date'] = parameters['endDate'];
    }

    queryParameters['end_date'] = this.transformParameter(
      queryParameters['end_date'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['sortType'] !== 'undefined') {
      queryParameters['sortType'] = parameters['sortType'];
    }

    queryParameters['sortType'] = this.transformParameter(
      queryParameters['sortType'],
    );

    if (typeof parameters['sortOrder'] !== 'undefined') {
      queryParameters['sortOrder'] = parameters['sortOrder'];
    }

    queryParameters['sortOrder'] = this.transformParameter(
      queryParameters['sortOrder'],
    );

    if (typeof parameters['country'] !== 'undefined') {
      queryParameters['country'] = parameters['country'];
    }

    queryParameters['country'] = this.transformParameter(
      queryParameters['country'],
    );

    if (typeof parameters['categoryIds'] !== 'undefined') {
      queryParameters['category_ids'] = parameters['categoryIds'];
    }

    queryParameters['category_ids'] = this.transformParameter(
      queryParameters['category_ids'],
    );

    if (typeof parameters['source'] !== 'undefined') {
      queryParameters['source'] = parameters['source'];
    }

    queryParameters['source'] = this.transformParameter(
      queryParameters['source'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads categories statistics general
   * @method
   * @name API#loadGeneralCategoryListStats
   */
  loadGeneralCategoryListStats(
    parameters: {
      startDate?: string;
      endDate?: string;
      categoryIds?: Array<number>;
      country?: number;
      source?: number;
    } = {},
  ): Promise<{
    _id?: string;

    clicks?: number;

    impressions?: number;

    payment?: number;

    count?: number;
  }> {
    let path = '/category/category-stats';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['startDate'] !== 'undefined') {
      queryParameters['start_date'] = parameters['startDate'];
    }

    queryParameters['start_date'] = this.transformParameter(
      queryParameters['start_date'],
    );

    if (typeof parameters['endDate'] !== 'undefined') {
      queryParameters['end_date'] = parameters['endDate'];
    }

    queryParameters['end_date'] = this.transformParameter(
      queryParameters['end_date'],
    );

    if (typeof parameters['categoryIds'] !== 'undefined') {
      queryParameters['category_ids'] = parameters['categoryIds'];
    }

    queryParameters['category_ids'] = this.transformParameter(
      queryParameters['category_ids'],
    );

    if (typeof parameters['country'] !== 'undefined') {
      queryParameters['country'] = parameters['country'];
    }

    queryParameters['country'] = this.transformParameter(
      queryParameters['country'],
    );

    if (typeof parameters['source'] !== 'undefined') {
      queryParameters['source'] = parameters['source'];
    }

    queryParameters['source'] = this.transformParameter(
      queryParameters['source'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads categories statistics daily
   * @method
   * @name API#loadGeneralDailyCategoryListStats
   */
  loadGeneralDailyCategoryListStats(
    parameters: {
      startDate?: string;
      endDate?: string;
      categoryIds?: Array<number>;
      country?: number;
      source?: number;
    } = {},
  ): Promise<{
    items?: Array<{
      date?: string;

      clicks?: number;

      impressions?: number;

      payment?: number;

      total_category_products?: number;

      shops_avg?: number;

      active_total_offers?: number;
    }>;
  }> {
    let path = '/category/category-stats-daily';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['startDate'] !== 'undefined') {
      queryParameters['start_date'] = parameters['startDate'];
    }

    queryParameters['start_date'] = this.transformParameter(
      queryParameters['start_date'],
    );

    if (typeof parameters['endDate'] !== 'undefined') {
      queryParameters['end_date'] = parameters['endDate'];
    }

    queryParameters['end_date'] = this.transformParameter(
      queryParameters['end_date'],
    );

    if (typeof parameters['categoryIds'] !== 'undefined') {
      queryParameters['category_ids'] = parameters['categoryIds'];
    }

    queryParameters['category_ids'] = this.transformParameter(
      queryParameters['category_ids'],
    );

    if (typeof parameters['country'] !== 'undefined') {
      queryParameters['country'] = parameters['country'];
    }

    queryParameters['country'] = this.transformParameter(
      queryParameters['country'],
    );

    if (typeof parameters['source'] !== 'undefined') {
      queryParameters['source'] = parameters['source'];
    }

    queryParameters['source'] = this.transformParameter(
      queryParameters['source'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * getCategoryTreeOrBreadcrumb
   * @method
   * @name API#getCategoryTreeOrBreadcrumb
   */
  getCategoryTreeOrBreadcrumb(
    languageId: number,
    categoryId: number,
    parameters: {} = {},
  ): Promise<{
    categories?: Array<{
      _id?: string;

      accessories_param?: boolean;

      category_id?: number;

      category_merging?: boolean;

      created?: string;

      created_by?: number;

      description?: string;

      edited?: string;

      edited_by?: number;

      enabled?: boolean;

      filter_name?: string;

      info?: Array<{
        average_ppc?: number;

        average_price?: number;

        category_id?: number;

        category_info_id?: number;

        clickthroughs?: number;

        country_id?: number;

        highest_price?: number;

        impressions?: number;

        lowest_price?: number;

        num_products?: number;

        updated?: string;
      }>;

      is_adult?: boolean;

      is_filter?: boolean;

      manufacturer_merging?: boolean;

      manufacturer_param?: boolean;

      manufacturer_state?: boolean;

      match_merging?: boolean;

      model_name_param?: boolean;

      model_name_state?: boolean;

      optional1_param?: string;

      optional1_state?: boolean;

      optional2_param?: string;

      optional2_state?: boolean;

      optional3_param?: string;

      optional3_state?: boolean;

      optional4_param?: string;

      optional4_state?: boolean;

      optional5_param?: string;

      optional5_state?: boolean;

      optional6_param?: string;

      optional6_state?: boolean;

      parent_id?: number;

      price_difference?: number;

      product_count?: {};

      slugs?: Array<{
        _id?: string;

        article_id?: number;

        branch_id?: number;

        carrier_id?: number;

        category_id?: number;

        company_id?: number;

        created?: string;

        group?: number;

        language_id?: number;

        manufacturer_id?: number;

        old_slug?: string;

        product_id?: number;

        shop_id?: number;

        slug?: string;

        slug_id?: number;

        updated?: string;

        user_id?: number;
      }>;

      translations?: Array<{
        category_data_id?: number;

        category_id?: number;

        description?: string;

        short_description?: string;

        full_path?: string;

        language_id?: number;

        name?: string;

        name_short?: string;

        name_singular?: string;

        name_singular_short?: string;

        slug?: string;
      }>;

      tree_left?: number;

      tree_level?: number;

      tree_right?: number;

      use_automatic_assigns?: boolean;

      children?: Array<{}>;

      parents_names?: Array<string>;

      manufacturer_aggregations?: Array<{
        country_id?: number;

        manufacturer_id?: number;

        count?: number;
      }>;

      parameters?: Array<{
        _id?: string;

        parameter_id?: string;

        position?: number;

        translations?: Array<{
          language_id?: number;

          show?: boolean;

          index?: boolean;

          expand?: boolean;
        }>;
      }>;

      landing_pages?: Array<{
        _id?: string;

        parameters?: Array<{
          parameter_id?: string;

          parameter_value_id?: string;
        }>;

        manufacturers?: Array<number>;

        categories?: Array<number>;

        sort?: {
          type?: string | null;

          order?: string | null;
        };

        price_from?: number | null;

        price_to?: number | null;

        translations?: Array<{
          active?: boolean;

          title?: string | null;

          header?: string | null;

          short_description?: string | null;

          description?: string | null;

          language_id?: number;
        }>;
      }>;

      listing_links?: Array<{
        language_id?: number;

        position?: number;

        name?: string;

        url?: string;
      }>;

      pn_pairing_enabled?: boolean;
    }>;
  }> {
    let path =
      '/category/getCategoryTreeOrBreadcrumb/{languageId}/{categoryId}/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{languageId}', languageId.toString());

    path = path.replace('{categoryId}', categoryId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * getCategorySlugById
   * @method
   * @name API#getCategorySlugById
   */
  getCategorySlugById(
    languageId: string,
    categories: string,
    parameters: {} = {},
  ): Promise<
    Array<{
      category_id: number;

      slug: string;
    }>
  > {
    let path = '/category/getCategorySlugById/{languageId}/{categories}/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{languageId}', languageId.toString());

    path = path.replace('{categories}', categories.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads count product categories by condition
   * @method
   * @name API#getProductCategoriesCount
   */
  getProductCategoriesCount(
    languageId: number,
    parameters: {
      manufacturerId?: number;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    categories?: Array<{
      category_id?: number;

      count?: number;

      category_name?: string;

      category_slug?: string;
    }>;
  }> {
    let path = '/category/productCategoriesCount/{languageId}/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{languageId}', languageId.toString());

    if (typeof parameters['manufacturerId'] !== 'undefined') {
      queryParameters['manufacturer_id'] = parameters['manufacturerId'];
    }

    queryParameters['manufacturer_id'] = this.transformParameter(
      queryParameters['manufacturer_id'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads pm categories information
   * @method
   * @name API#loadPmCategories
   */
  loadPmCategories(
    parameters: {
      query?: string;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    categories?: Array<{
      name?: string;
    }>;
  }> {
    let path = '/pm_categories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update category parameters
   * @method
   * @name API#updateCategoryParameters
   */
  updateCategoryParameters(
    categoryId: string,
    parameters: {} = {},
    body: {
      parameters?: Array<{
        _id?: string;

        parameter_id?: string;

        position?: number;

        translations?: Array<{
          language_id?: number;

          show?: boolean;

          index?: boolean;

          expand?: boolean;
        }>;
      }>;
    },
  ): Promise<{}> {
    let path = '/category/{categoryId}/parameters';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{categoryId}', categoryId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Update category landing pages
   * @method
   * @name API#updateCategoryLandingPages
   */
  updateCategoryLandingPages(
    categoryId: string,
    parameters: {} = {},
    body: {
      landing_pages?: Array<{
        _id?: string;

        parameters?: Array<{
          parameter_id?: string;

          parameter_value_id?: string;
        }>;

        manufacturers?: Array<number>;

        categories?: Array<number>;

        sort?: {
          type?: string | null;

          order?: string | null;
        };

        price_from?: number | null;

        price_to?: number | null;

        translations?: Array<{
          active?: boolean;

          title?: string | null;

          header?: string | null;

          short_description?: string | null;

          description?: string | null;

          language_id?: number;
        }>;
      }>;
    },
  ): Promise<{}> {
    let path = '/category/{categoryId}/landing_pages';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{categoryId}', categoryId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Update category listing links
   * @method
   * @name API#updateCategoryListingLinks
   */
  updateCategoryListingLinks(
    categoryId: string,
    parameters: {} = {},
    body: {
      listing_links?: Array<{
        language_id?: number;

        position?: number;

        name?: string;

        url?: string;
      }>;
    },
  ): Promise<{}> {
    let path = '/category/{categoryId}/listing_links';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{categoryId}', categoryId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Upload logo
   * @method
   * @name API#uploadCategoryLogo
   */
  uploadCategoryLogo(
    parameters: {
      categoryId: number;
    },
    form: {
      file?: {};
    },
  ): Promise<{}> {
    let path = '/category/upload-image/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    if (typeof parameters['categoryId'] === 'undefined') {
      throw new Error('Missing required parameter: categoryId');
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      form,
      headers,
      queryParameters,
    );
  }
  /**
   * Update category settings
   * @method
   * @name API#updateCategorySettings
   */
  updateCategorySettings(
    categoryId: string,
    parameters: {} = {},
    body: {
      pn_pairing_enabled?: boolean;

      translations?: Array<{
        language_id?: number;

        short_description?: string;

        description?: string;
      }>;
    },
  ): Promise<{}> {
    let path = '/category/{categoryId}/settings/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{categoryId}', categoryId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Loads slugs information
   * @method
   * @name API#loadSlugs
   */
  loadSlugs(
    parameters: {
      languageId?: number;
      slugs?: Array<string>;
      withManufacturer?: boolean;
      limit?: number;
      offset?: number;
      withCategory?: boolean;
      categoryIds?: Array<number>;
    } = {},
  ): Promise<{
    slugs?: Array<{
      _id?: string;

      slug_id?: number;

      article_id?: number;

      branch_id?: number;

      carrier_id?: number;

      category_id?: number;

      company_id?: number;

      created?: string;

      group?: string;

      language_id?: number;

      manufacturer_id?: number;

      old_slug?: string;

      product_id?: number;

      shop_id?: number;

      slug?: string;

      user_id?: number;

      manufacturer?: {
        _id?: string;

        created?: string;

        created_by?: number;

        edited?: string;

        edited_by?: number;

        enabled?: boolean;

        manufacturer_id?: number;

        monitoring?: boolean;

        name?: string;

        data?: Array<{
          manufacturer_data_id?: number;

          description?: string;

          url?: string;

          manufacturer_id?: number;

          language_id?: number;
        }>;

        slugs?: Array<{
          _id?: string;

          article_id?: number;

          branch_id?: number;

          carrier_id?: number;

          category_id?: number;

          company_id?: number;

          created?: string;

          group?: number;

          language_id?: number;

          manufacturer_id?: number;

          old_slug?: string;

          product_id?: number;

          shop_id?: number;

          slug?: string;

          slug_id?: number;

          updated?: string;

          user_id?: number;
        }>;
      };

      category?: {
        _id?: string;

        accessories_param?: boolean;

        category_id?: number;

        category_merging?: boolean;

        created?: string;

        created_by?: number;

        description?: string;

        edited?: string;

        edited_by?: number;

        enabled?: boolean;

        filter_name?: string;

        info?: Array<{
          average_ppc?: number;

          average_price?: number;

          category_id?: number;

          category_info_id?: number;

          clickthroughs?: number;

          country_id?: number;

          highest_price?: number;

          impressions?: number;

          lowest_price?: number;

          num_products?: number;

          updated?: string;
        }>;

        is_adult?: boolean;

        is_filter?: boolean;

        manufacturer_merging?: boolean;

        manufacturer_param?: boolean;

        manufacturer_state?: boolean;

        match_merging?: boolean;

        model_name_param?: boolean;

        model_name_state?: boolean;

        optional1_param?: string;

        optional1_state?: boolean;

        optional2_param?: string;

        optional2_state?: boolean;

        optional3_param?: string;

        optional3_state?: boolean;

        optional4_param?: string;

        optional4_state?: boolean;

        optional5_param?: string;

        optional5_state?: boolean;

        optional6_param?: string;

        optional6_state?: boolean;

        parent_id?: number;

        price_difference?: number;

        product_count?: {};

        slugs?: Array<{
          _id?: string;

          article_id?: number;

          branch_id?: number;

          carrier_id?: number;

          category_id?: number;

          company_id?: number;

          created?: string;

          group?: number;

          language_id?: number;

          manufacturer_id?: number;

          old_slug?: string;

          product_id?: number;

          shop_id?: number;

          slug?: string;

          slug_id?: number;

          updated?: string;

          user_id?: number;
        }>;

        translations?: Array<{
          category_data_id?: number;

          category_id?: number;

          description?: string;

          short_description?: string;

          full_path?: string;

          language_id?: number;

          name?: string;

          name_short?: string;

          name_singular?: string;

          name_singular_short?: string;

          slug?: string;
        }>;

        tree_left?: number;

        tree_level?: number;

        tree_right?: number;

        use_automatic_assigns?: boolean;

        children?: Array<{}>;

        parents_names?: Array<string>;

        manufacturer_aggregations?: Array<{
          country_id?: number;

          manufacturer_id?: number;

          count?: number;
        }>;

        parameters?: Array<{
          _id?: string;

          parameter_id?: string;

          position?: number;

          translations?: Array<{
            language_id?: number;

            show?: boolean;

            index?: boolean;

            expand?: boolean;
          }>;
        }>;

        landing_pages?: Array<{
          _id?: string;

          parameters?: Array<{
            parameter_id?: string;

            parameter_value_id?: string;
          }>;

          manufacturers?: Array<number>;

          categories?: Array<number>;

          sort?: {
            type?: string | null;

            order?: string | null;
          };

          price_from?: number | null;

          price_to?: number | null;

          translations?: Array<{
            active?: boolean;

            title?: string | null;

            header?: string | null;

            short_description?: string | null;

            description?: string | null;

            language_id?: number;
          }>;
        }>;

        listing_links?: Array<{
          language_id?: number;

          position?: number;

          name?: string;

          url?: string;
        }>;

        pn_pairing_enabled?: boolean;
      };
    }>;
  }> {
    let path = '/slugs';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['languageId'] !== 'undefined') {
      queryParameters['language_id'] = parameters['languageId'];
    }

    queryParameters['language_id'] = this.transformParameter(
      queryParameters['language_id'],
    );

    if (typeof parameters['slugs'] !== 'undefined') {
      queryParameters['slugs'] = parameters['slugs'];
    }

    queryParameters['slugs'] = this.transformParameter(
      queryParameters['slugs'],
    );

    if (typeof parameters['withManufacturer'] !== 'undefined') {
      queryParameters['with_manufacturer'] = parameters['withManufacturer'];
    }

    queryParameters['with_manufacturer'] = this.transformParameter(
      queryParameters['with_manufacturer'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['withCategory'] !== 'undefined') {
      queryParameters['with_category'] = parameters['withCategory'];
    }

    queryParameters['with_category'] = this.transformParameter(
      queryParameters['with_category'],
    );

    if (typeof parameters['categoryIds'] !== 'undefined') {
      queryParameters['category_ids'] = parameters['categoryIds'];
    }

    queryParameters['category_ids'] = this.transformParameter(
      queryParameters['category_ids'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Translate slugs
   * @method
   * @name API#translateSlugs
   */
  translateSlugs(parameters: {
    slugs: Array<string>;
  }): Promise<{
    slugs?: Array<{
      original?: string;

      translated?: Array<{
        _id?: string;

        slug_id?: number;

        article_id?: number;

        branch_id?: number;

        carrier_id?: number;

        category_id?: number;

        company_id?: number;

        created?: string;

        group?: string;

        language_id?: number;

        manufacturer_id?: number;

        old_slug?: string;

        product_id?: number;

        shop_id?: number;

        slug?: string;

        user_id?: number;

        manufacturer?: {
          _id?: string;

          created?: string;

          created_by?: number;

          edited?: string;

          edited_by?: number;

          enabled?: boolean;

          manufacturer_id?: number;

          monitoring?: boolean;

          name?: string;

          data?: Array<{
            manufacturer_data_id?: number;

            description?: string;

            url?: string;

            manufacturer_id?: number;

            language_id?: number;
          }>;

          slugs?: Array<{
            _id?: string;

            article_id?: number;

            branch_id?: number;

            carrier_id?: number;

            category_id?: number;

            company_id?: number;

            created?: string;

            group?: number;

            language_id?: number;

            manufacturer_id?: number;

            old_slug?: string;

            product_id?: number;

            shop_id?: number;

            slug?: string;

            slug_id?: number;

            updated?: string;

            user_id?: number;
          }>;
        };

        category?: {
          _id?: string;

          accessories_param?: boolean;

          category_id?: number;

          category_merging?: boolean;

          created?: string;

          created_by?: number;

          description?: string;

          edited?: string;

          edited_by?: number;

          enabled?: boolean;

          filter_name?: string;

          info?: Array<{
            average_ppc?: number;

            average_price?: number;

            category_id?: number;

            category_info_id?: number;

            clickthroughs?: number;

            country_id?: number;

            highest_price?: number;

            impressions?: number;

            lowest_price?: number;

            num_products?: number;

            updated?: string;
          }>;

          is_adult?: boolean;

          is_filter?: boolean;

          manufacturer_merging?: boolean;

          manufacturer_param?: boolean;

          manufacturer_state?: boolean;

          match_merging?: boolean;

          model_name_param?: boolean;

          model_name_state?: boolean;

          optional1_param?: string;

          optional1_state?: boolean;

          optional2_param?: string;

          optional2_state?: boolean;

          optional3_param?: string;

          optional3_state?: boolean;

          optional4_param?: string;

          optional4_state?: boolean;

          optional5_param?: string;

          optional5_state?: boolean;

          optional6_param?: string;

          optional6_state?: boolean;

          parent_id?: number;

          price_difference?: number;

          product_count?: {};

          slugs?: Array<{
            _id?: string;

            article_id?: number;

            branch_id?: number;

            carrier_id?: number;

            category_id?: number;

            company_id?: number;

            created?: string;

            group?: number;

            language_id?: number;

            manufacturer_id?: number;

            old_slug?: string;

            product_id?: number;

            shop_id?: number;

            slug?: string;

            slug_id?: number;

            updated?: string;

            user_id?: number;
          }>;

          translations?: Array<{
            category_data_id?: number;

            category_id?: number;

            description?: string;

            short_description?: string;

            full_path?: string;

            language_id?: number;

            name?: string;

            name_short?: string;

            name_singular?: string;

            name_singular_short?: string;

            slug?: string;
          }>;

          tree_left?: number;

          tree_level?: number;

          tree_right?: number;

          use_automatic_assigns?: boolean;

          children?: Array<{}>;

          parents_names?: Array<string>;

          manufacturer_aggregations?: Array<{
            country_id?: number;

            manufacturer_id?: number;

            count?: number;
          }>;

          parameters?: Array<{
            _id?: string;

            parameter_id?: string;

            position?: number;

            translations?: Array<{
              language_id?: number;

              show?: boolean;

              index?: boolean;

              expand?: boolean;
            }>;
          }>;

          landing_pages?: Array<{
            _id?: string;

            parameters?: Array<{
              parameter_id?: string;

              parameter_value_id?: string;
            }>;

            manufacturers?: Array<number>;

            categories?: Array<number>;

            sort?: {
              type?: string | null;

              order?: string | null;
            };

            price_from?: number | null;

            price_to?: number | null;

            translations?: Array<{
              active?: boolean;

              title?: string | null;

              header?: string | null;

              short_description?: string | null;

              description?: string | null;

              language_id?: number;
            }>;
          }>;

          listing_links?: Array<{
            language_id?: number;

            position?: number;

            name?: string;

            url?: string;
          }>;

          pn_pairing_enabled?: boolean;
        };
      }>;
    }>;
  }> {
    let path = '/slugs/translate';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['slugs'] !== 'undefined') {
      queryParameters['slugs'] = parameters['slugs'];
    }

    queryParameters['slugs'] = this.transformParameter(
      queryParameters['slugs'],
    );

    if (typeof parameters['slugs'] === 'undefined') {
      throw new Error('Missing required parameter: slugs');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads list of manufacturers
   * @method
   * @name API#loadManufacturers
   */
  loadManufacturers(
    parameters: {
      countryId?: number;
      query?: string;
      limit?: number;
      offset?: number;
      manufacturerIds?: Array<number>;
    } = {},
  ): Promise<{
    manufacturers?: Array<{
      _id?: string;

      created?: string;

      created_by?: number;

      edited?: string;

      edited_by?: number;

      enabled?: boolean;

      manufacturer_id?: number;

      monitoring?: boolean;

      name?: string;

      data?: Array<{
        manufacturer_data_id?: number;

        description?: string;

        url?: string;

        manufacturer_id?: number;

        language_id?: number;
      }>;

      slugs?: Array<{
        _id?: string;

        article_id?: number;

        branch_id?: number;

        carrier_id?: number;

        category_id?: number;

        company_id?: number;

        created?: string;

        group?: number;

        language_id?: number;

        manufacturer_id?: number;

        old_slug?: string;

        product_id?: number;

        shop_id?: number;

        slug?: string;

        slug_id?: number;

        updated?: string;

        user_id?: number;
      }>;
    }>;
  }> {
    let path = '/manufacturers';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['countryId'] !== 'undefined') {
      queryParameters['country_id'] = parameters['countryId'];
    }

    queryParameters['country_id'] = this.transformParameter(
      queryParameters['country_id'],
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['manufacturerIds'] !== 'undefined') {
      queryParameters['manufacturer_ids'] = parameters['manufacturerIds'];
    }

    queryParameters['manufacturer_ids'] = this.transformParameter(
      queryParameters['manufacturer_ids'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create manufacturer
   * @method
   * @name API#createNewManufacturer
   */
  createNewManufacturer(
    parameters: {} = {},
    body: {
      name: string;
    },
  ): Promise<{
    manufacturer_id?: number;
  }> {
    let path = '/manufacturers';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Loads list of manufacturers
   * @method
   * @name API#loadManufacturersPaginated
   */
  loadManufacturersPaginated(
    parameters: {
      countryId?: number;
      query?: string;
      limit?: number;
      offset?: number;
      manufacturerIds?: Array<number>;
    } = {},
  ): Promise<{
    manufacturers?: Array<{
      _id?: string;

      created?: string;

      created_by?: number;

      edited?: string;

      edited_by?: number;

      enabled?: boolean;

      manufacturer_id?: number;

      monitoring?: boolean;

      name?: string;

      data?: Array<{
        manufacturer_data_id?: number;

        description?: string;

        url?: string;

        manufacturer_id?: number;

        language_id?: number;
      }>;

      slugs?: Array<{
        _id?: string;

        article_id?: number;

        branch_id?: number;

        carrier_id?: number;

        category_id?: number;

        company_id?: number;

        created?: string;

        group?: number;

        language_id?: number;

        manufacturer_id?: number;

        old_slug?: string;

        product_id?: number;

        shop_id?: number;

        slug?: string;

        slug_id?: number;

        updated?: string;

        user_id?: number;
      }>;
    }>;

    total?: number;
  }> {
    let path = '/manufacturers/list';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['countryId'] !== 'undefined') {
      queryParameters['country_id'] = parameters['countryId'];
    }

    queryParameters['country_id'] = this.transformParameter(
      queryParameters['country_id'],
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['manufacturerIds'] !== 'undefined') {
      queryParameters['manufacturer_ids'] = parameters['manufacturerIds'];
    }

    queryParameters['manufacturer_ids'] = this.transformParameter(
      queryParameters['manufacturer_ids'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create manufacturer
   * @method
   * @name API#createNewManufacturer_1
   */
  createNewManufacturer_1(
    parameters: {} = {},
    body: {
      name: string;
    },
  ): Promise<{
    manufacturer_id?: number;
  }> {
    let path = '/manufacturers/list';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Loads list of pm manufacturers
   * @method
   * @name API#loadPmManufacturers
   */
  loadPmManufacturers(
    parameters: {
      query?: string;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    manufacturers?: Array<{
      name?: string;
    }>;
  }> {
    let path = '/pm_manufacturers';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Upload image
   * @method
   * @name API#uploadManufacturerLogo
   */
  uploadManufacturerLogo(
    parameters: {
      manufacturerId: number;
    },
    form: {
      file?: {};
    },
  ): Promise<{}> {
    let path = '/manufacturers/upload-image/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    if (typeof parameters['manufacturerId'] !== 'undefined') {
      queryParameters['manufacturer_id'] = parameters['manufacturerId'];
    }

    queryParameters['manufacturer_id'] = this.transformParameter(
      queryParameters['manufacturer_id'],
    );

    if (typeof parameters['manufacturerId'] === 'undefined') {
      throw new Error('Missing required parameter: manufacturerId');
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      form,
      headers,
      queryParameters,
    );
  }
  /**
* Loads list of shops. Entity Shop is representation of one merchant on pricemania.sk / cz . Example in current implementation -> https://www.pricemania.sk/alza-sk/

* @method
* @name API#loadShops
*/
  loadShops(
    parameters: {
      countryId?: number;
      query?: string;
      limit?: number;
      offset?: number;
      shopId?: number;
      publicKey?: string;
      withBranches?: boolean;
      shopIds?: Array<number>;
      withCompany?: boolean;
      withExitHash?: boolean;
    } = {},
    extraHeaders?: {
      xOriginalUserAgent?: string;
      xOriginalIpAddress?: string;
      xOriginalSessionId?: string;
    },
  ): Promise<{
    shops?: Array<{
      _id?: string;

      benefits?: string;

      billing_data_published?: boolean;

      company_id?: number;

      contact_email?: string;

      contact_phone?: string;

      country_id?: number;

      created?: string;

      created_by?: number;

      description?: string;

      edited?: string;

      edited_by?: number;

      feed_url?: string;

      has_backlink?: boolean;

      name?: string;

      next_parse_time?: string;

      original_program_id?: number;

      parse_interval?: number;

      parse_time?: string;

      partnerships?: number;

      ppc?: Array<{
        approx?: number;

        average_position?: number;

        average_ppc?: number;

        category_id?: number;

        country_id?: number;

        ppc?: number;

        product_id?: number;

        shop_id?: number;

        shop_ppc_id?: number;

        user_id?: number;
      }>;

      program_id?: number;

      shop_id?: number;

      slugs?: Array<{
        _id?: string;

        article_id?: number;

        branch_id?: number;

        carrier_id?: number;

        category_id?: number;

        company_id?: number;

        created?: string;

        group?: number;

        language_id?: number;

        manufacturer_id?: number;

        old_slug?: string;

        product_id?: number;

        shop_id?: number;

        slug?: string;

        slug_id?: number;

        updated?: string;

        user_id?: number;
      }>;

      company?: Array<{
        _id?: string;

        company_id?: number;

        contact_person_email?: string;

        contact_person_name?: string;

        contact_person_phone?: string;

        contact_person_position_id?: number;

        contact_person_surname?: string;

        created?: string;

        created_by?: number;

        credit?: string;

        credit_currency_id?: number;

        credit_final?: string;

        enabled?: boolean;

        identification_number?: string;

        merchant_id?: number;

        name?: string;

        notification_date?: string;

        notification_id?: number;

        other_information?: number;

        postal_city_id?: number;

        postal_code?: number;

        postal_company_name?: number;

        postal_country_id?: number;

        postal_postal_code?: string;

        postal_street?: string;

        street?: string;

        tax_number?: string;

        vat_number?: string;

        vat_payer?: boolean;
      }>;

      start_date?: string;

      state?: string;

      trusted_days?: number;

      trusted_email?: string;

      trusted_shop?: boolean;

      url?: string;

      public_key?: string;

      average_delivery?: number;

      branches?: Array<{
        _id?: string;

        branch_id?: number;

        address_country_id?: number;

        city_id?: number;

        contact_email?: string;

        contact_phone?: string;

        description?: string;

        latitude?: number;

        longtitude?: number;

        name?: string;

        postal_code?: string;

        street?: string;

        branch_info?: Array<{
          branch_info_id?: number;

          has_bus_stop?: number;

          bus_stop_note?: string;

          has_parking?: number;

          parking_note?: string;

          has_access_for_disabled?: number;

          access_for_disabled_note?: string;

          has_drive_in?: number;

          drive_in_node?: string;

          description?: string;

          branch_id?: number;
        }>;

        branch_opening_hours?: Array<{
          branch_opening_hour_id?: number;

          branch_id?: number;

          day?: number;

          from?: string;

          till?: string;
        }>;
      }>;

      trusted_send_type?: string;

      trusted_level?: string;

      insert_url_utm_parameters?: boolean;

      affiliate_click_coeficients?: Array<{
        affiliate_id?: string;

        coeficient?: number;
      }>;

      connected_shop_products?: {
        shop_id?: number;

        match_properties?: Array<string>;
      };

      show_in_widgets?: boolean;

      rule_pairing_enabled?: boolean;

      pn_pairing_enabled?: boolean;

      exit_hash?: string;

      product_bidding_enabled?: boolean;
    }>;

    total?: number;
  }> {
    let path = '/shops';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['countryId'] !== 'undefined') {
      queryParameters['country_id'] = parameters['countryId'];
    }

    queryParameters['country_id'] = this.transformParameter(
      queryParameters['country_id'],
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['shopId'] !== 'undefined') {
      queryParameters['shop_id'] = parameters['shopId'];
    }

    queryParameters['shop_id'] = this.transformParameter(
      queryParameters['shop_id'],
    );

    if (typeof parameters['publicKey'] !== 'undefined') {
      queryParameters['public_key'] = parameters['publicKey'];
    }

    queryParameters['public_key'] = this.transformParameter(
      queryParameters['public_key'],
    );

    if (typeof parameters['withBranches'] !== 'undefined') {
      queryParameters['with_branches'] = parameters['withBranches'];
    }

    queryParameters['with_branches'] = this.transformParameter(
      queryParameters['with_branches'],
    );

    if (typeof parameters['shopIds'] !== 'undefined') {
      queryParameters['shop_ids'] = parameters['shopIds'];
    }

    queryParameters['shop_ids'] = this.transformParameter(
      queryParameters['shop_ids'],
    );

    if (typeof parameters['withCompany'] !== 'undefined') {
      queryParameters['with_company'] = parameters['withCompany'];
    }

    queryParameters['with_company'] = this.transformParameter(
      queryParameters['with_company'],
    );

    if (typeof parameters['withExitHash'] !== 'undefined') {
      queryParameters['with_exit_hash'] = parameters['withExitHash'];
    }

    queryParameters['with_exit_hash'] = this.transformParameter(
      queryParameters['with_exit_hash'],
    );

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalUserAgent'] !== 'undefined'
    ) {
      headers.append(
        'x-original-user-agent',
        extraHeaders['xOriginalUserAgent']!,
      );
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalIpAddress'] !== 'undefined'
    ) {
      headers.append(
        'x-original-ip-address',
        extraHeaders['xOriginalIpAddress']!,
      );
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalSessionId'] !== 'undefined'
    ) {
      headers.append(
        'x-original-session-id',
        extraHeaders['xOriginalSessionId']!,
      );
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
* Loads list of shops. Entity Shop is representation of one merchant on pricemania.sk / cz . Example in current implementation -> https://www.pricemania.sk/alza-sk/

* @method
* @name API#loadShopsList
*/
  loadShopsList(
    parameters: {
      countryId?: number;
      query?: string;
      limit?: number;
      sortType?: string;
      sortOrder?: string;
      offset?: number;
      shopId?: number;
      publicKey?: string;
      withBranches?: boolean;
      shopIds?: Array<number>;
      companyIds?: Array<number>;
      startDate?: string;
      endDate?: string;
      source?: number;
      withCategories?: boolean;
      withManufacturers?: boolean;
      programIds?: Array<number>;
      backlinkFilter?: number;
    } = {},
  ): Promise<{
    shops?: Array<{
      _id?: string;

      clicks?: number;

      impressions?: number;

      payment?: number;

      products_total_count?: number;

      products_active?: number;

      manufacturersCount?: number;

      categoriesCount?: number;

      ppc?: Array<{
        approx?: number;

        average_position?: number;

        average_ppc?: number;

        category_id?: number;

        country_id?: number;

        ppc?: number;

        product_id?: number;

        shop_id?: number;

        shop_ppc_id?: number;

        user_id?: number;
      }>;

      company?: Array<{
        _id?: string;

        company_id?: number;

        contact_person_email?: string;

        contact_person_name?: string;

        contact_person_phone?: string;

        contact_person_position_id?: number;

        contact_person_surname?: string;

        created?: string;

        created_by?: number;

        credit?: string;

        credit_currency_id?: number;

        credit_final?: string;

        enabled?: boolean;

        identification_number?: string;

        merchant_id?: number;

        name?: string;

        notification_date?: string;

        notification_id?: number;

        other_information?: number;

        postal_city_id?: number;

        postal_code?: number;

        postal_company_name?: number;

        postal_country_id?: number;

        postal_postal_code?: string;

        postal_street?: string;

        street?: string;

        tax_number?: string;

        vat_number?: string;

        vat_payer?: boolean;
      }>;

      slugs?: Array<{
        _id?: string;

        article_id?: number;

        branch_id?: number;

        carrier_id?: number;

        category_id?: number;

        company_id?: number;

        created?: string;

        group?: number;

        language_id?: number;

        manufacturer_id?: number;

        old_slug?: string;

        product_id?: number;

        shop_id?: number;

        slug?: string;

        slug_id?: number;

        updated?: string;

        user_id?: number;
      }>;

      shop?: Array<{
        _id?: string;

        benefits?: string;

        billing_data_published?: boolean;

        company_id?: number;

        contact_email?: string;

        contact_phone?: string;

        country_id?: number;

        created?: string;

        created_by?: number;

        description?: string;

        edited?: string;

        edited_by?: number;

        feed_url?: string;

        has_backlink?: boolean;

        name?: string;

        next_parse_time?: string;

        original_program_id?: number;

        parse_interval?: number;

        parse_time?: string;

        partnerships?: number;

        payment?: number;

        impressions?: number;

        products_active?: number;

        products_total_count?: number;

        program_id?: number;

        shop_id?: number;

        start_date?: string;

        state?: string;

        trusted_days?: number;

        trusted_email?: string;

        trusted_shop?: boolean;

        url?: string;

        public_key?: string;

        average_delivery?: number;

        branches?: Array<{
          _id?: string;

          branch_id?: number;

          address_country_id?: number;

          city_id?: number;

          contact_email?: string;

          contact_phone?: string;

          description?: string;

          latitude?: number;

          longtitude?: number;

          name?: string;

          postal_code?: string;

          street?: string;

          branch_info?: Array<{
            branch_info_id?: number;

            has_bus_stop?: number;

            bus_stop_note?: string;

            has_parking?: number;

            parking_note?: string;

            has_access_for_disabled?: number;

            access_for_disabled_note?: string;

            has_drive_in?: number;

            drive_in_node?: string;

            description?: string;

            branch_id?: number;
          }>;

          branch_opening_hours?: Array<{
            branch_opening_hour_id?: number;

            branch_id?: number;

            day?: number;

            from?: string;

            till?: string;
          }>;
        }>;

        trusted_send_type?: string;

        trusted_level?: string;

        insert_url_utm_parameters?: boolean;
      }>;
    }>;

    total?: number;
  }> {
    let path = '/shops/shop-list';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['countryId'] !== 'undefined') {
      queryParameters['country_id'] = parameters['countryId'];
    }

    queryParameters['country_id'] = this.transformParameter(
      queryParameters['country_id'],
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['sortType'] !== 'undefined') {
      queryParameters['sortType'] = parameters['sortType'];
    }

    queryParameters['sortType'] = this.transformParameter(
      queryParameters['sortType'],
    );

    if (typeof parameters['sortOrder'] !== 'undefined') {
      queryParameters['sortOrder'] = parameters['sortOrder'];
    }

    queryParameters['sortOrder'] = this.transformParameter(
      queryParameters['sortOrder'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['shopId'] !== 'undefined') {
      queryParameters['shop_id'] = parameters['shopId'];
    }

    queryParameters['shop_id'] = this.transformParameter(
      queryParameters['shop_id'],
    );

    if (typeof parameters['publicKey'] !== 'undefined') {
      queryParameters['public_key'] = parameters['publicKey'];
    }

    queryParameters['public_key'] = this.transformParameter(
      queryParameters['public_key'],
    );

    if (typeof parameters['withBranches'] !== 'undefined') {
      queryParameters['with_branches'] = parameters['withBranches'];
    }

    queryParameters['with_branches'] = this.transformParameter(
      queryParameters['with_branches'],
    );

    if (typeof parameters['shopIds'] !== 'undefined') {
      queryParameters['shop_ids'] = parameters['shopIds'];
    }

    queryParameters['shop_ids'] = this.transformParameter(
      queryParameters['shop_ids'],
    );

    if (typeof parameters['companyIds'] !== 'undefined') {
      queryParameters['company_ids'] = parameters['companyIds'];
    }

    queryParameters['company_ids'] = this.transformParameter(
      queryParameters['company_ids'],
    );

    if (typeof parameters['startDate'] !== 'undefined') {
      queryParameters['startDate'] = parameters['startDate'];
    }

    queryParameters['startDate'] = this.transformParameter(
      queryParameters['startDate'],
    );

    if (typeof parameters['endDate'] !== 'undefined') {
      queryParameters['endDate'] = parameters['endDate'];
    }

    queryParameters['endDate'] = this.transformParameter(
      queryParameters['endDate'],
    );

    if (typeof parameters['source'] !== 'undefined') {
      queryParameters['source'] = parameters['source'];
    }

    queryParameters['source'] = this.transformParameter(
      queryParameters['source'],
    );

    if (typeof parameters['withCategories'] !== 'undefined') {
      queryParameters['with_categories'] = parameters['withCategories'];
    }

    queryParameters['with_categories'] = this.transformParameter(
      queryParameters['with_categories'],
    );

    if (typeof parameters['withManufacturers'] !== 'undefined') {
      queryParameters['with_manufacturers'] = parameters['withManufacturers'];
    }

    queryParameters['with_manufacturers'] = this.transformParameter(
      queryParameters['with_manufacturers'],
    );

    if (typeof parameters['programIds'] !== 'undefined') {
      queryParameters['program_ids'] = parameters['programIds'];
    }

    queryParameters['program_ids'] = this.transformParameter(
      queryParameters['program_ids'],
    );

    if (typeof parameters['backlinkFilter'] !== 'undefined') {
      queryParameters['backlink_filter'] = parameters['backlinkFilter'];
    }

    queryParameters['backlink_filter'] = this.transformParameter(
      queryParameters['backlink_filter'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Save user survey
   * @method
   * @name API#saveSurvey
   */
  saveSurvey(parameters: {
    shopOrderId: string;
    username?: string;
    email?: string;
    deliveryDate?: string;
    purchaseExperience?: string;
    isRecommended?: boolean;
    orderState?: number;
    shopRating?: string;
    carrierRating?: string;
    purchaseRating?: string;
    surveyReminder?: string;
    cancelReason?: string;
    filled?: boolean;
  }): Promise<{
    status?: string;

    type?: string;

    shop_review_id?: string;
  }> {
    let path = '/shops/survey/save';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['shopOrderId'] !== 'undefined') {
      queryParameters['shop_order_id'] = parameters['shopOrderId'];
    }

    queryParameters['shop_order_id'] = this.transformParameter(
      queryParameters['shop_order_id'],
    );

    if (typeof parameters['shopOrderId'] === 'undefined') {
      throw new Error('Missing required parameter: shopOrderId');
    }

    if (typeof parameters['username'] !== 'undefined') {
      queryParameters['username'] = parameters['username'];
    }

    queryParameters['username'] = this.transformParameter(
      queryParameters['username'],
    );

    if (typeof parameters['email'] !== 'undefined') {
      queryParameters['email'] = parameters['email'];
    }

    queryParameters['email'] = this.transformParameter(
      queryParameters['email'],
    );

    if (typeof parameters['deliveryDate'] !== 'undefined') {
      queryParameters['delivery_date'] = parameters['deliveryDate'];
    }

    queryParameters['delivery_date'] = this.transformParameter(
      queryParameters['delivery_date'],
    );

    if (typeof parameters['purchaseExperience'] !== 'undefined') {
      queryParameters['purchase_experience'] = parameters['purchaseExperience'];
    }

    queryParameters['purchase_experience'] = this.transformParameter(
      queryParameters['purchase_experience'],
    );

    if (typeof parameters['isRecommended'] !== 'undefined') {
      queryParameters['is_recommended'] = parameters['isRecommended'];
    }

    queryParameters['is_recommended'] = this.transformParameter(
      queryParameters['is_recommended'],
    );

    if (typeof parameters['orderState'] !== 'undefined') {
      queryParameters['order_state'] = parameters['orderState'];
    }

    queryParameters['order_state'] = this.transformParameter(
      queryParameters['order_state'],
    );

    if (typeof parameters['shopRating'] !== 'undefined') {
      queryParameters['shop_rating'] = parameters['shopRating'];
    }

    queryParameters['shop_rating'] = this.transformParameter(
      queryParameters['shop_rating'],
    );

    if (typeof parameters['carrierRating'] !== 'undefined') {
      queryParameters['carrier_rating'] = parameters['carrierRating'];
    }

    queryParameters['carrier_rating'] = this.transformParameter(
      queryParameters['carrier_rating'],
    );

    if (typeof parameters['purchaseRating'] !== 'undefined') {
      queryParameters['purchase_rating'] = parameters['purchaseRating'];
    }

    queryParameters['purchase_rating'] = this.transformParameter(
      queryParameters['purchase_rating'],
    );

    if (typeof parameters['surveyReminder'] !== 'undefined') {
      queryParameters['survey_reminder'] = parameters['surveyReminder'];
    }

    queryParameters['survey_reminder'] = this.transformParameter(
      queryParameters['survey_reminder'],
    );

    if (typeof parameters['cancelReason'] !== 'undefined') {
      queryParameters['cancel_reason'] = parameters['cancelReason'];
    }

    queryParameters['cancel_reason'] = this.transformParameter(
      queryParameters['cancel_reason'],
    );

    if (typeof parameters['filled'] !== 'undefined') {
      queryParameters['filled'] = parameters['filled'];
    }

    queryParameters['filled'] = this.transformParameter(
      queryParameters['filled'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * get shop order
   * @method
   * @name API#getShopOrder
   */
  getShopOrder(
    parameters: {
      id?: string;
    } = {},
  ): Promise<{
    _id?: string;

    shop_id?: string;

    public_key?: string;

    order_id?: string;

    user_email?: string;

    is_from_pma?: boolean;

    created_at?: string;

    products?: Array<{
      name?: string;

      price?: number;

      quantity?: number;
    }>;
  }> {
    let path = '/shops/get-order';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['id'] = parameters['id'];
    }

    queryParameters['id'] = this.transformParameter(queryParameters['id']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * get shop reviews
   * @method
   * @name API#getShopReviews
   */
  getShopReviews(
    parameters: {
      shopId?: number;
      limit?: number;
      offset?: number;
      withAvgRatings?: boolean;
      id?: string;
      shopOrderId?: string;
      sortType?: string;
      sortOrder?: string;
      publicKey?: string;
      withStatistics?: boolean;
      filterOnlyFilled?: boolean;
      withShopOrder?: boolean;
    } = {},
  ): Promise<{
    shop_reviews?: Array<{
      _id?: string;

      shop_id?: number;

      shop_order_id?: string;

      username?: string;

      email?: string;

      delivery_date?: string;

      purchase_experience?: string;

      order_state?: number;

      shop_rating?: number;

      carrier_rating?: number;

      purchase_rating?: number;

      survey_reminder?: number;

      cancel_reason?: string;

      is_new_type?: boolean;

      like?: number;

      dislike?: number;

      shop_review_id?: number;

      advantages?: string;

      created?: string;

      customer_care?: number;

      delivery_service?: number;

      disadvantages?: string;

      is_multiple_purchase?: boolean;

      is_recommended?: boolean;

      parent_id?: number;

      summary?: string;

      website_usability?: number;

      overal_review?: number;

      is_from_pma?: boolean;

      filled?: boolean;

      responses?: Array<{
        user_id?: number;

        text?: string;
      }>;

      shop_order?: {
        _id?: string;

        shop_id?: string;

        public_key?: string;

        order_id?: string;

        user_email?: string;

        is_from_pma?: boolean;

        created_at?: string;

        products?: Array<{
          name?: string;

          price?: number;

          quantity?: number;
        }>;
      };
    }>;

    total?: number;

    avg_ratings?: {
      customer_care?: number;

      delivery_service?: number;

      website_usability?: number;

      shop_rating?: number;

      carrier_rating?: number;

      purchase_rating?: number;

      overal?: number;
    };

    statistics?: {
      recently_count?: number;

      recently_recommended_count?: number;

      avg_delivery_days?: number;

      fast_delivery_count?: number;

      with_delivery_time_count?: number;
    };
  }> {
    let path = '/shop/reviews';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['shopId'] !== 'undefined') {
      queryParameters['shop_id'] = parameters['shopId'];
    }

    queryParameters['shop_id'] = this.transformParameter(
      queryParameters['shop_id'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['withAvgRatings'] !== 'undefined') {
      queryParameters['with_avg_ratings'] = parameters['withAvgRatings'];
    }

    queryParameters['with_avg_ratings'] = this.transformParameter(
      queryParameters['with_avg_ratings'],
    );

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['_id'] = parameters['id'];
    }

    queryParameters['_id'] = this.transformParameter(queryParameters['_id']);

    if (typeof parameters['shopOrderId'] !== 'undefined') {
      queryParameters['shop_order_id'] = parameters['shopOrderId'];
    }

    queryParameters['shop_order_id'] = this.transformParameter(
      queryParameters['shop_order_id'],
    );

    if (typeof parameters['sortType'] !== 'undefined') {
      queryParameters['sort_type'] = parameters['sortType'];
    }

    queryParameters['sort_type'] = this.transformParameter(
      queryParameters['sort_type'],
    );

    if (typeof parameters['sortOrder'] !== 'undefined') {
      queryParameters['sort_order'] = parameters['sortOrder'];
    }

    queryParameters['sort_order'] = this.transformParameter(
      queryParameters['sort_order'],
    );

    if (typeof parameters['publicKey'] !== 'undefined') {
      queryParameters['public_key'] = parameters['publicKey'];
    }

    queryParameters['public_key'] = this.transformParameter(
      queryParameters['public_key'],
    );

    if (typeof parameters['withStatistics'] !== 'undefined') {
      queryParameters['with_statistics'] = parameters['withStatistics'];
    }

    queryParameters['with_statistics'] = this.transformParameter(
      queryParameters['with_statistics'],
    );

    if (typeof parameters['filterOnlyFilled'] !== 'undefined') {
      queryParameters['filter_only_filled'] = parameters['filterOnlyFilled'];
    }

    queryParameters['filter_only_filled'] = this.transformParameter(
      queryParameters['filter_only_filled'],
    );

    if (typeof parameters['withShopOrder'] !== 'undefined') {
      queryParameters['with_shop_order'] = parameters['withShopOrder'];
    }

    queryParameters['with_shop_order'] = this.transformParameter(
      queryParameters['with_shop_order'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Add response to review
   * @method
   * @name API#addReviewResponse
   */
  addReviewResponse(parameters: {
    id: string;
    userId?: number;
    text?: string;
  }): Promise<{
    success?: boolean;
  }> {
    let path = '/shop/reviews/add-response';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['id'] !== 'undefined') {
      queryParameters['_id'] = parameters['id'];
    }

    queryParameters['_id'] = this.transformParameter(queryParameters['_id']);

    if (typeof parameters['id'] === 'undefined') {
      throw new Error('Missing required parameter: id');
    }

    if (typeof parameters['userId'] !== 'undefined') {
      queryParameters['user_id'] = parameters['userId'];
    }

    queryParameters['user_id'] = this.transformParameter(
      queryParameters['user_id'],
    );

    if (typeof parameters['text'] !== 'undefined') {
      queryParameters['text'] = parameters['text'];
    }

    queryParameters['text'] = this.transformParameter(queryParameters['text']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Save shop settings
   * @method
   * @name API#saveShopSettings
   */
  saveShopSettings(
    id: number,
    parameters: {} = {},
    body: {
      trusted_email?: string;

      trusted_send_type?: string;

      trusted_days?: number;

      trusted_level?: string | null;

      next_parse_time?: string;

      insert_url_utm_parameters?: boolean;

      programId?: number;

      connected_shop_products?: {
        shop_id?: number | null;

        match_properties?: Array<string>;
      };

      show_in_widgets?: boolean;

      rule_pairing_enabled?: boolean;

      pn_pairing_enabled?: boolean;

      product_bidding_enabled?: boolean;
    },
  ): Promise<{
    success?: boolean;
  }> {
    let path = '/shop/settings/{id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * get affiliate coeficients
   * @method
   * @name API#getShopAffiliateCoeficients
   */
  getShopAffiliateCoeficients(
    id: number,
    parameters: {
      prefilDefault?: boolean;
      filterNotUsed?: boolean;
    } = {},
  ): Promise<{
    affiliateClickCoeficients?: Array<{
      affiliate_name?: string;

      affiliate_id?: string;

      coeficient?: number;
    }>;
  }> {
    let path = '/shop/{id}/affiliate_click_coeficients';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    if (typeof parameters['prefilDefault'] !== 'undefined') {
      queryParameters['prefil_default'] = parameters['prefilDefault'];
    }

    queryParameters['prefil_default'] = this.transformParameter(
      queryParameters['prefil_default'],
    );

    if (typeof parameters['filterNotUsed'] !== 'undefined') {
      queryParameters['filter_not_used'] = parameters['filterNotUsed'];
    }

    queryParameters['filter_not_used'] = this.transformParameter(
      queryParameters['filter_not_used'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Uptate affiliate coeficients
   * @method
   * @name API#udpateShopAffiliateCoeficients
   */
  udpateShopAffiliateCoeficients(
    id: number,
    parameters: {} = {},
    body: {
      affiliateClickCoeficients?: Array<{
        affiliate_id?: string;

        coeficient?: number;
      }>;
    },
  ): Promise<{
    success?: boolean;
  }> {
    let path = '/shop/{id}/affiliate_click_coeficients';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{id}', id.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Eshop stats
   * @method
   * @name API#loadGeneralShopListStats
   */
  loadGeneralShopListStats(
    parameters: {
      startDate?: string;
      endDate?: string;
      limit?: number;
      offset?: number;
      tags?: string;
      shopIds?: Array<number>;
      companyIds?: Array<number>;
      soruce?: number;
      country?: number;
      programIds?: Array<number>;
      backlinkFilter?: number;
    } = {},
  ): Promise<{
    _id?: string;

    clicks?: number;

    impressions?: number;

    payment?: number;

    count?: number;
  }> {
    let path = '/shop/shop-stats/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['startDate'] !== 'undefined') {
      queryParameters['start_date'] = parameters['startDate'];
    }

    queryParameters['start_date'] = this.transformParameter(
      queryParameters['start_date'],
    );

    if (typeof parameters['endDate'] !== 'undefined') {
      queryParameters['end_date'] = parameters['endDate'];
    }

    queryParameters['end_date'] = this.transformParameter(
      queryParameters['end_date'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['tags'] !== 'undefined') {
      queryParameters['tags'] = parameters['tags'];
    }

    queryParameters['tags'] = this.transformParameter(queryParameters['tags']);

    if (typeof parameters['shopIds'] !== 'undefined') {
      queryParameters['shopIds'] = parameters['shopIds'];
    }

    queryParameters['shopIds'] = this.transformParameter(
      queryParameters['shopIds'],
    );

    if (typeof parameters['companyIds'] !== 'undefined') {
      queryParameters['companyIds'] = parameters['companyIds'];
    }

    queryParameters['companyIds'] = this.transformParameter(
      queryParameters['companyIds'],
    );

    if (typeof parameters['soruce'] !== 'undefined') {
      queryParameters['soruce'] = parameters['soruce'];
    }

    queryParameters['soruce'] = this.transformParameter(
      queryParameters['soruce'],
    );

    if (typeof parameters['country'] !== 'undefined') {
      queryParameters['country'] = parameters['country'];
    }

    queryParameters['country'] = this.transformParameter(
      queryParameters['country'],
    );

    if (typeof parameters['programIds'] !== 'undefined') {
      queryParameters['program_ids'] = parameters['programIds'];
    }

    queryParameters['program_ids'] = this.transformParameter(
      queryParameters['program_ids'],
    );

    if (typeof parameters['backlinkFilter'] !== 'undefined') {
      queryParameters['backlink_filter'] = parameters['backlinkFilter'];
    }

    queryParameters['backlink_filter'] = this.transformParameter(
      queryParameters['backlink_filter'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Eshop daily stats
   * @method
   * @name API#loadGeneraDailyShopListStats
   */
  loadGeneraDailyShopListStats(
    parameters: {
      startDate?: string;
      endDate?: string;
      limit?: number;
      offset?: number;
      shopIds?: Array<number>;
      companyIds?: Array<number>;
      soruce?: number;
      country?: number;
      programIds?: Array<number>;
      backlinkFilter?: number;
    } = {},
  ): Promise<{
    items?: Array<{
      date?: string;

      clicks?: number;

      impressions?: number;

      payment?: number;

      count?: number;
    }>;
  }> {
    let path = '/shop/shop-daily-stats/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['startDate'] !== 'undefined') {
      queryParameters['start_date'] = parameters['startDate'];
    }

    queryParameters['start_date'] = this.transformParameter(
      queryParameters['start_date'],
    );

    if (typeof parameters['endDate'] !== 'undefined') {
      queryParameters['end_date'] = parameters['endDate'];
    }

    queryParameters['end_date'] = this.transformParameter(
      queryParameters['end_date'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['shopIds'] !== 'undefined') {
      queryParameters['shopIds'] = parameters['shopIds'];
    }

    queryParameters['shopIds'] = this.transformParameter(
      queryParameters['shopIds'],
    );

    if (typeof parameters['companyIds'] !== 'undefined') {
      queryParameters['companyIds'] = parameters['companyIds'];
    }

    queryParameters['companyIds'] = this.transformParameter(
      queryParameters['companyIds'],
    );

    if (typeof parameters['soruce'] !== 'undefined') {
      queryParameters['soruce'] = parameters['soruce'];
    }

    queryParameters['soruce'] = this.transformParameter(
      queryParameters['soruce'],
    );

    if (typeof parameters['country'] !== 'undefined') {
      queryParameters['country'] = parameters['country'];
    }

    queryParameters['country'] = this.transformParameter(
      queryParameters['country'],
    );

    if (typeof parameters['programIds'] !== 'undefined') {
      queryParameters['program_ids'] = parameters['programIds'];
    }

    queryParameters['program_ids'] = this.transformParameter(
      queryParameters['program_ids'],
    );

    if (typeof parameters['backlinkFilter'] !== 'undefined') {
      queryParameters['backlink_filter'] = parameters['backlinkFilter'];
    }

    queryParameters['backlink_filter'] = this.transformParameter(
      queryParameters['backlink_filter'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Post data from shop form
   * @method
   * @name API#uploadShopLogo
   */
  uploadShopLogo(
    parameters: {
      shopId?: number;
    } = {},
    form: {
      file?: {};
    },
  ): Promise<{}> {
    let path = '/shop/upload-image/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    if (typeof parameters['shopId'] !== 'undefined') {
      queryParameters['shop_id'] = parameters['shopId'];
    }

    queryParameters['shop_id'] = this.transformParameter(
      queryParameters['shop_id'],
    );

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      form,
      headers,
      queryParameters,
    );
  }
  /**
* Get current informations about companies

* @method
* @name API#loadCompanies
*/
  loadCompanies(
    parameters: {
      countryId?: number;
      query?: string;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    companies?: Array<{
      _id?: string;

      company_id?: number;

      contact_person_email?: string;

      contact_person_name?: string;

      contact_person_phone?: string;

      contact_person_position_id?: number;

      contact_person_surname?: string;

      created?: string;

      created_by?: number;

      credit?: string;

      credit_currency_id?: number;

      credit_final?: string;

      enabled?: boolean;

      identification_number?: string;

      merchant_id?: number;

      name?: string;

      notification_date?: string;

      notification_id?: number;

      other_information?: number;

      postal_city_id?: number;

      postal_code?: number;

      postal_company_name?: number;

      postal_country_id?: number;

      postal_postal_code?: string;

      postal_street?: string;

      street?: string;

      tax_number?: string;

      vat_number?: string;

      vat_payer?: boolean;

      shop_data?: Array<{
        _id?: string;

        benefits?: string;

        billing_data_published?: boolean;

        company_id?: number;

        contact_email?: string;

        contact_phone?: string;

        country_id?: number;

        created?: string;

        created_by?: number;

        description?: string;

        edited?: string;

        edited_by?: number;

        feed_url?: string;

        has_backlink?: boolean;

        name?: string;

        next_parse_time?: string;

        original_program_id?: number;

        parse_interval?: number;

        parse_time?: string;

        partnerships?: number;

        ppc?: Array<{
          approx?: number;

          average_position?: number;

          average_ppc?: number;

          category_id?: number;

          country_id?: number;

          ppc?: number;

          product_id?: number;

          shop_id?: number;

          shop_ppc_id?: number;

          user_id?: number;
        }>;

        program_id?: number;

        shop_id?: number;

        slugs?: Array<{
          _id?: string;

          article_id?: number;

          branch_id?: number;

          carrier_id?: number;

          category_id?: number;

          company_id?: number;

          created?: string;

          group?: number;

          language_id?: number;

          manufacturer_id?: number;

          old_slug?: string;

          product_id?: number;

          shop_id?: number;

          slug?: string;

          slug_id?: number;

          updated?: string;

          user_id?: number;
        }>;

        company?: Array<{
          _id?: string;

          company_id?: number;

          contact_person_email?: string;

          contact_person_name?: string;

          contact_person_phone?: string;

          contact_person_position_id?: number;

          contact_person_surname?: string;

          created?: string;

          created_by?: number;

          credit?: string;

          credit_currency_id?: number;

          credit_final?: string;

          enabled?: boolean;

          identification_number?: string;

          merchant_id?: number;

          name?: string;

          notification_date?: string;

          notification_id?: number;

          other_information?: number;

          postal_city_id?: number;

          postal_code?: number;

          postal_company_name?: number;

          postal_country_id?: number;

          postal_postal_code?: string;

          postal_street?: string;

          street?: string;

          tax_number?: string;

          vat_number?: string;

          vat_payer?: boolean;
        }>;

        start_date?: string;

        state?: string;

        trusted_days?: number;

        trusted_email?: string;

        trusted_shop?: boolean;

        url?: string;

        public_key?: string;

        average_delivery?: number;

        branches?: Array<{
          _id?: string;

          branch_id?: number;

          address_country_id?: number;

          city_id?: number;

          contact_email?: string;

          contact_phone?: string;

          description?: string;

          latitude?: number;

          longtitude?: number;

          name?: string;

          postal_code?: string;

          street?: string;

          branch_info?: Array<{
            branch_info_id?: number;

            has_bus_stop?: number;

            bus_stop_note?: string;

            has_parking?: number;

            parking_note?: string;

            has_access_for_disabled?: number;

            access_for_disabled_note?: string;

            has_drive_in?: number;

            drive_in_node?: string;

            description?: string;

            branch_id?: number;
          }>;

          branch_opening_hours?: Array<{
            branch_opening_hour_id?: number;

            branch_id?: number;

            day?: number;

            from?: string;

            till?: string;
          }>;
        }>;

        trusted_send_type?: string;

        trusted_level?: string;

        insert_url_utm_parameters?: boolean;

        affiliate_click_coeficients?: Array<{
          affiliate_id?: string;

          coeficient?: number;
        }>;

        connected_shop_products?: {
          shop_id?: number;

          match_properties?: Array<string>;
        };

        show_in_widgets?: boolean;

        rule_pairing_enabled?: boolean;

        pn_pairing_enabled?: boolean;

        exit_hash?: string;

        product_bidding_enabled?: boolean;
      }>;
    }>;

    total?: number;
  }> {
    let path = '/companies';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['countryId'] !== 'undefined') {
      queryParameters['country_id'] = parameters['countryId'];
    }

    queryParameters['country_id'] = this.transformParameter(
      queryParameters['country_id'],
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Register exit links
   * @method
   * @name API#exitLink
   */
  exitLink(
    parameters: {
      exitHash: string;
      wsid?: string;
    },
    extraHeaders?: {
      xOriginalUserAgent: string;
      xOriginalIpAddress: string;
      xOriginalSessionId: string;
      xOriginalReferer: string;
    },
  ): Promise<{
    success?: boolean;

    url?: string;

    payment?: string;

    currency?: {
      code?: string;
    };

    id?: string;

    referer?: string;

    sku?: string;

    type?: string;

    shopName?: string;

    productId?: number;

    productPrice?: number;
  }> {
    let path = '/exit_link';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['exitHash'] !== 'undefined') {
      queryParameters['exitHash'] = parameters['exitHash'];
    }

    queryParameters['exitHash'] = this.transformParameter(
      queryParameters['exitHash'],
    );

    if (typeof parameters['exitHash'] === 'undefined') {
      throw new Error('Missing required parameter: exitHash');
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalUserAgent'] !== 'undefined'
    ) {
      headers.append(
        'x-original-user-agent',
        extraHeaders['xOriginalUserAgent']!,
      );
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalIpAddress'] !== 'undefined'
    ) {
      headers.append(
        'x-original-ip-address',
        extraHeaders['xOriginalIpAddress']!,
      );
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalSessionId'] !== 'undefined'
    ) {
      headers.append(
        'x-original-session-id',
        extraHeaders['xOriginalSessionId']!,
      );
    }

    if (
      extraHeaders &&
      typeof extraHeaders['xOriginalReferer'] !== 'undefined'
    ) {
      headers.append('x-original-referer', extraHeaders['xOriginalReferer']!);
    }

    if (typeof parameters['wsid'] !== 'undefined') {
      queryParameters['wsid'] = parameters['wsid'];
    }

    queryParameters['wsid'] = this.transformParameter(queryParameters['wsid']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Process exit links
   * @method
   * @name API#processExitLink
   */
  processExitLink(parameters: {
    ip: string;
  }): Promise<{
    valid?: boolean;
  }> {
    let path = '/process_exit_link';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['ip'] !== 'undefined') {
      queryParameters['ip'] = parameters['ip'];
    }

    queryParameters['ip'] = this.transformParameter(queryParameters['ip']);

    if (typeof parameters['ip'] === 'undefined') {
      throw new Error('Missing required parameter: ip');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Google-sheet export
   * @method
   * @name API#googleSheetExport
   */
  googleSheetExport(parameters: {} = {}): Promise<void> {
    let path = '/export/google-sheet';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Google ads feed export
   * @method
   * @name API#googleAdsFeedCategoryBrand
   */
  googleAdsFeedCategoryBrand(parameters: {} = {}): Promise<void> {
    let path = '/export/google-ads-feed-category-brand';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Google ads feed export
   * @method
   * @name API#googleAdsFeedBrand
   */
  googleAdsFeedBrand(parameters: {} = {}): Promise<void> {
    let path = '/export/google-ads-feed-brand';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Persoo export
   * @method
   * @name API#persoo
   */
  persoo(lang: string, parameters: {} = {}): Promise<void> {
    let path = '/export/persoo/{lang}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);

    path = path.replace('{lang}', lang.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Vk marketig export
   * @method
   * @name API#exportVkmarketingFeed
   */
  exportVkmarketingFeed(feedId: number, parameters: {} = {}): Promise<void> {
    let path = '/export/vkmarketing/{feed_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    path = path.replace('{feed_id}', feedId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Return min program prices for currencies
   * @method
   * @name API#getLowestPrices
   */
  getLowestPrices(
    parameters: {} = {},
  ): Promise<{
    lowest_prices?: {
      min_price?: number;

      currency_id?: number;
    };
  }> {
    let path = '/programs/lowest-prices';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads feed report
   * @method
   * @name API#loadFeedReport
   */
  loadFeedReport(
    parameters: {
      datetimeFrom?: string;
      datetimeTo?: string;
      errorsCountFrom?: number;
      errorsCountTo?: number;
      shopIds?: Array<number>;
      limit?: number;
      offset?: number;
      withShop?: boolean;
      sortType?: string;
      sortOrder?: string;
    } = {},
  ): Promise<{
    limit?: number;

    offset?: number;

    total?: number;

    feed_parser_reports?: Array<{
      _id?: string;

      shop_id?: number;

      parsed_product_count?: number;

      all_errors_count?: number;

      error_overflow?: boolean;

      errors?: Array<{
        message?: string;
      }>;

      created_at?: string;

      shop?: {
        _id?: string;

        benefits?: string;

        billing_data_published?: boolean;

        company_id?: number;

        contact_email?: string;

        contact_phone?: string;

        country_id?: number;

        created?: string;

        created_by?: number;

        description?: string;

        edited?: string;

        edited_by?: number;

        feed_url?: string;

        has_backlink?: boolean;

        name?: string;

        next_parse_time?: string;

        original_program_id?: number;

        parse_interval?: number;

        parse_time?: string;

        partnerships?: number;

        ppc?: Array<{
          approx?: number;

          average_position?: number;

          average_ppc?: number;

          category_id?: number;

          country_id?: number;

          ppc?: number;

          product_id?: number;

          shop_id?: number;

          shop_ppc_id?: number;

          user_id?: number;
        }>;

        program_id?: number;

        shop_id?: number;

        slugs?: Array<{
          _id?: string;

          article_id?: number;

          branch_id?: number;

          carrier_id?: number;

          category_id?: number;

          company_id?: number;

          created?: string;

          group?: number;

          language_id?: number;

          manufacturer_id?: number;

          old_slug?: string;

          product_id?: number;

          shop_id?: number;

          slug?: string;

          slug_id?: number;

          updated?: string;

          user_id?: number;
        }>;

        company?: Array<{
          _id?: string;

          company_id?: number;

          contact_person_email?: string;

          contact_person_name?: string;

          contact_person_phone?: string;

          contact_person_position_id?: number;

          contact_person_surname?: string;

          created?: string;

          created_by?: number;

          credit?: string;

          credit_currency_id?: number;

          credit_final?: string;

          enabled?: boolean;

          identification_number?: string;

          merchant_id?: number;

          name?: string;

          notification_date?: string;

          notification_id?: number;

          other_information?: number;

          postal_city_id?: number;

          postal_code?: number;

          postal_company_name?: number;

          postal_country_id?: number;

          postal_postal_code?: string;

          postal_street?: string;

          street?: string;

          tax_number?: string;

          vat_number?: string;

          vat_payer?: boolean;
        }>;

        start_date?: string;

        state?: string;

        trusted_days?: number;

        trusted_email?: string;

        trusted_shop?: boolean;

        url?: string;

        public_key?: string;

        average_delivery?: number;

        branches?: Array<{
          _id?: string;

          branch_id?: number;

          address_country_id?: number;

          city_id?: number;

          contact_email?: string;

          contact_phone?: string;

          description?: string;

          latitude?: number;

          longtitude?: number;

          name?: string;

          postal_code?: string;

          street?: string;

          branch_info?: Array<{
            branch_info_id?: number;

            has_bus_stop?: number;

            bus_stop_note?: string;

            has_parking?: number;

            parking_note?: string;

            has_access_for_disabled?: number;

            access_for_disabled_note?: string;

            has_drive_in?: number;

            drive_in_node?: string;

            description?: string;

            branch_id?: number;
          }>;

          branch_opening_hours?: Array<{
            branch_opening_hour_id?: number;

            branch_id?: number;

            day?: number;

            from?: string;

            till?: string;
          }>;
        }>;

        trusted_send_type?: string;

        trusted_level?: string;

        insert_url_utm_parameters?: boolean;

        affiliate_click_coeficients?: Array<{
          affiliate_id?: string;

          coeficient?: number;
        }>;

        connected_shop_products?: {
          shop_id?: number;

          match_properties?: Array<string>;
        };

        show_in_widgets?: boolean;

        rule_pairing_enabled?: boolean;

        pn_pairing_enabled?: boolean;

        exit_hash?: string;

        product_bidding_enabled?: boolean;
      };
    }>;
  }> {
    let path = '/feed/report';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['datetimeFrom'] !== 'undefined') {
      queryParameters['datetime_from'] = parameters['datetimeFrom'];
    }

    queryParameters['datetime_from'] = this.transformParameter(
      queryParameters['datetime_from'],
    );

    if (typeof parameters['datetimeTo'] !== 'undefined') {
      queryParameters['datetime_to'] = parameters['datetimeTo'];
    }

    queryParameters['datetime_to'] = this.transformParameter(
      queryParameters['datetime_to'],
    );

    if (typeof parameters['errorsCountFrom'] !== 'undefined') {
      queryParameters['errors_count_from'] = parameters['errorsCountFrom'];
    }

    queryParameters['errors_count_from'] = this.transformParameter(
      queryParameters['errors_count_from'],
    );

    if (typeof parameters['errorsCountTo'] !== 'undefined') {
      queryParameters['errors_count_to'] = parameters['errorsCountTo'];
    }

    queryParameters['errors_count_to'] = this.transformParameter(
      queryParameters['errors_count_to'],
    );

    if (typeof parameters['shopIds'] !== 'undefined') {
      queryParameters['shop_ids'] = parameters['shopIds'];
    }

    queryParameters['shop_ids'] = this.transformParameter(
      queryParameters['shop_ids'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['withShop'] !== 'undefined') {
      queryParameters['with_shop'] = parameters['withShop'];
    }

    queryParameters['with_shop'] = this.transformParameter(
      queryParameters['with_shop'],
    );

    if (typeof parameters['sortType'] !== 'undefined') {
      queryParameters['sort_type'] = parameters['sortType'];
    }

    queryParameters['sort_type'] = this.transformParameter(
      queryParameters['sort_type'],
    );

    if (typeof parameters['sortOrder'] !== 'undefined') {
      queryParameters['sort_order'] = parameters['sortOrder'];
    }

    queryParameters['sort_order'] = this.transformParameter(
      queryParameters['sort_order'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Loads feed statistics
   * @method
   * @name API#loadFeedStatistics
   */
  loadFeedStatistics(
    parameters: {} = {},
  ): Promise<{
    limit?: number;

    offset?: number;

    total?: number;

    feed_parser_reports?: Array<{
      _id?: string;

      shop_id?: number;

      parsed_product_count?: number;

      all_errors_count?: number;

      error_overflow?: boolean;

      errors?: Array<{
        message?: string;
      }>;

      created_at?: string;

      shop?: {
        _id?: string;

        benefits?: string;

        billing_data_published?: boolean;

        company_id?: number;

        contact_email?: string;

        contact_phone?: string;

        country_id?: number;

        created?: string;

        created_by?: number;

        description?: string;

        edited?: string;

        edited_by?: number;

        feed_url?: string;

        has_backlink?: boolean;

        name?: string;

        next_parse_time?: string;

        original_program_id?: number;

        parse_interval?: number;

        parse_time?: string;

        partnerships?: number;

        ppc?: Array<{
          approx?: number;

          average_position?: number;

          average_ppc?: number;

          category_id?: number;

          country_id?: number;

          ppc?: number;

          product_id?: number;

          shop_id?: number;

          shop_ppc_id?: number;

          user_id?: number;
        }>;

        program_id?: number;

        shop_id?: number;

        slugs?: Array<{
          _id?: string;

          article_id?: number;

          branch_id?: number;

          carrier_id?: number;

          category_id?: number;

          company_id?: number;

          created?: string;

          group?: number;

          language_id?: number;

          manufacturer_id?: number;

          old_slug?: string;

          product_id?: number;

          shop_id?: number;

          slug?: string;

          slug_id?: number;

          updated?: string;

          user_id?: number;
        }>;

        company?: Array<{
          _id?: string;

          company_id?: number;

          contact_person_email?: string;

          contact_person_name?: string;

          contact_person_phone?: string;

          contact_person_position_id?: number;

          contact_person_surname?: string;

          created?: string;

          created_by?: number;

          credit?: string;

          credit_currency_id?: number;

          credit_final?: string;

          enabled?: boolean;

          identification_number?: string;

          merchant_id?: number;

          name?: string;

          notification_date?: string;

          notification_id?: number;

          other_information?: number;

          postal_city_id?: number;

          postal_code?: number;

          postal_company_name?: number;

          postal_country_id?: number;

          postal_postal_code?: string;

          postal_street?: string;

          street?: string;

          tax_number?: string;

          vat_number?: string;

          vat_payer?: boolean;
        }>;

        start_date?: string;

        state?: string;

        trusted_days?: number;

        trusted_email?: string;

        trusted_shop?: boolean;

        url?: string;

        public_key?: string;

        average_delivery?: number;

        branches?: Array<{
          _id?: string;

          branch_id?: number;

          address_country_id?: number;

          city_id?: number;

          contact_email?: string;

          contact_phone?: string;

          description?: string;

          latitude?: number;

          longtitude?: number;

          name?: string;

          postal_code?: string;

          street?: string;

          branch_info?: Array<{
            branch_info_id?: number;

            has_bus_stop?: number;

            bus_stop_note?: string;

            has_parking?: number;

            parking_note?: string;

            has_access_for_disabled?: number;

            access_for_disabled_note?: string;

            has_drive_in?: number;

            drive_in_node?: string;

            description?: string;

            branch_id?: number;
          }>;

          branch_opening_hours?: Array<{
            branch_opening_hour_id?: number;

            branch_id?: number;

            day?: number;

            from?: string;

            till?: string;
          }>;
        }>;

        trusted_send_type?: string;

        trusted_level?: string;

        insert_url_utm_parameters?: boolean;

        affiliate_click_coeficients?: Array<{
          affiliate_id?: string;

          coeficient?: number;
        }>;

        connected_shop_products?: {
          shop_id?: number;

          match_properties?: Array<string>;
        };

        show_in_widgets?: boolean;

        rule_pairing_enabled?: boolean;

        pn_pairing_enabled?: boolean;

        exit_hash?: string;

        product_bidding_enabled?: boolean;
      };
    }>;
  }> {
    let path = '/feed/statistics';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create parameter mapping
   * @method
   * @name API#createParameterMapping
   */
  createParameterMapping(
    parameters: {} = {},
    body: {
      shop_id?: number;

      category_id?: number;
    },
  ): Promise<{}> {
    let path = '/feed/parameter_mappings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Load paramerter feed mappings
   * @method
   * @name API#loadParameterFeedMappings
   */
  loadParameterFeedMappings(
    parameters: {
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    items?: Array<{
      _id?: string;

      shop_id?: number;

      category_id?: number;

      mapping?: Array<{
        _id?: string;

        shop_parameter_name?: string;

        parameter_id?: string | null;
      }>;
    }>;

    total?: number;
  }> {
    let path = '/feed/parameter_mappings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update paramerter feed mappings
   * @method
   * @name API#updateParameterFeedMappings
   */
  updateParameterFeedMappings(
    feedParameterMappingId: string,
    parameters: {} = {},
    body: {
      mapping?: Array<{
        _id?: string;

        shop_parameter_name?: string;

        parameter_id?: string | null;
      }>;
    },
  ): Promise<{}> {
    let path = '/feed/parameter_mapping/{feed_parameter_mapping_id}/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace(
      '{feed_parameter_mapping_id}',
      feedParameterMappingId.toString(),
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#assignmentLogs
   */
  assignmentLogs(parameters: {} = {}): Promise<{}> {
    let path = '/logs/assignment_logs';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#insertQuatroLog
   */
  insertQuatroLog(
    parameters: {} = {},
    body: {
      type?: string;

      good_id?: string;

      product_id?: string;
    },
  ): Promise<{}> {
    let path = '/logs/quatro_log';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Search pairing data
   * @method
   * @name API#searchPairing
   */
  searchPairing(
    parameters: {
      filterShopIds?: Array<number>;
      filterCategoryIds?: Array<number>;
      filterManufacturerIds?: Array<number>;
      filterMatchTypes?: Array<string>;
      filterShopProgramIds?: Array<number>;
      searchName?: string;
      includeChildrenCategories?: boolean;
      limit?: number;
      filterProductIds?: Array<number>;
      maxVsScore?: number;
    } = {},
  ): Promise<{
    items?: Array<{
      _id?: string;

      compare_id?: number;

      product?: {
        name?: string;

        product_id?: number;

        productDescriptions?: Array<{
          language_id?: number;

          description?: string;
        }>;

        category?: {
          translations?: Array<{
            language_id?: number;

            name?: string;
          }>;
        };

        manufacturer?: {
          manufacturer_id?: number;

          name?: string;
        };

        current_aggregation?: {
          average_price?: number;

          assignment_average_price?: number;
        };
      };

      pm_shops_product?: {
        feed_id?: string;

        name?: string;

        image?: string;

        converted_price?: number;

        description?: string;

        ean?: string;

        part_number?: string;

        manufacturer?: {
          manufacturer_id?: number;

          name?: string;
        };

        category?: {
          category_id?: number;

          slugs?: Array<{
            language_id?: number;

            slug?: string;
          }>;

          translations?: Array<{
            language_id?: number;

            name?: string;
          }>;

          parents_names?: Array<string>;
        };
      };

      shop?: {
        name?: string;

        country_id?: number;

        program_id?: number;
      };

      price_check?: boolean;

      category_check?: boolean;

      manufacturer_check?: boolean;

      name_check?: boolean;

      name_check_score?: number;

      match_type?: string;

      ean?: string;

      part_number?: string;

      visualsearch_score?: number;
    }>;
  }> {
    let path = '/pairing/search';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['filterShopIds'] !== 'undefined') {
      queryParameters['filter_shop_ids'] = parameters['filterShopIds'];
    }

    queryParameters['filter_shop_ids'] = this.transformParameter(
      queryParameters['filter_shop_ids'],
    );

    if (typeof parameters['filterCategoryIds'] !== 'undefined') {
      queryParameters['filter_category_ids'] = parameters['filterCategoryIds'];
    }

    queryParameters['filter_category_ids'] = this.transformParameter(
      queryParameters['filter_category_ids'],
    );

    if (typeof parameters['filterManufacturerIds'] !== 'undefined') {
      queryParameters['filter_manufacturer_ids'] =
        parameters['filterManufacturerIds'];
    }

    queryParameters['filter_manufacturer_ids'] = this.transformParameter(
      queryParameters['filter_manufacturer_ids'],
    );

    if (typeof parameters['filterMatchTypes'] !== 'undefined') {
      queryParameters['filter_match_types'] = parameters['filterMatchTypes'];
    }

    queryParameters['filter_match_types'] = this.transformParameter(
      queryParameters['filter_match_types'],
    );

    if (typeof parameters['filterShopProgramIds'] !== 'undefined') {
      queryParameters['filter_shop_program_ids'] =
        parameters['filterShopProgramIds'];
    }

    queryParameters['filter_shop_program_ids'] = this.transformParameter(
      queryParameters['filter_shop_program_ids'],
    );

    if (typeof parameters['searchName'] !== 'undefined') {
      queryParameters['search_name'] = parameters['searchName'];
    }

    queryParameters['search_name'] = this.transformParameter(
      queryParameters['search_name'],
    );

    if (typeof parameters['includeChildrenCategories'] !== 'undefined') {
      queryParameters['include_children_categories'] =
        parameters['includeChildrenCategories'];
    }

    queryParameters['include_children_categories'] = this.transformParameter(
      queryParameters['include_children_categories'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['filterProductIds'] !== 'undefined') {
      queryParameters['filter_product_ids'] = parameters['filterProductIds'];
    }

    queryParameters['filter_product_ids'] = this.transformParameter(
      queryParameters['filter_product_ids'],
    );

    if (typeof parameters['maxVsScore'] !== 'undefined') {
      queryParameters['max_vs_score'] = parameters['maxVsScore'];
    }

    queryParameters['max_vs_score'] = this.transformParameter(
      queryParameters['max_vs_score'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Search pairing data
   * @method
   * @name API#searchPairingProducts
   */
  searchPairingProducts(
    parameters: {
      filterShopIds?: Array<number>;
      filterCategoryIds?: Array<number>;
      filterManufacturerIds?: Array<number>;
      filterMatchTypes?: Array<string>;
      filterShopProgramIds?: Array<number>;
      searchName?: string;
      includeChildrenCategories?: boolean;
      limit?: number;
    } = {},
  ): Promise<{
    items?: Array<{
      _id?: string;

      aggregation_update?: string;

      aggregations?: Array<{
        active_shops_count?: number;

        average_price?: number;

        country_id?: number;

        min_price?: number;

        show_in_autocomplete?: boolean;

        show_in_comparision?: boolean;

        sum_price?: number;

        cpc?: number;
      }>;

      approved?: string;

      approved_by?: number;

      category?: {
        _id?: string;

        accessories_param?: boolean;

        category_id?: number;

        category_merging?: boolean;

        created?: string;

        created_by?: number;

        description?: string;

        edited?: string;

        edited_by?: number;

        enabled?: boolean;

        filter_name?: string;

        info?: Array<{
          average_ppc?: number;

          average_price?: number;

          category_id?: number;

          category_info_id?: number;

          clickthroughs?: number;

          country_id?: number;

          highest_price?: number;

          impressions?: number;

          lowest_price?: number;

          num_products?: number;

          updated?: string;
        }>;

        is_adult?: boolean;

        is_filter?: boolean;

        manufacturer_merging?: boolean;

        manufacturer_param?: boolean;

        manufacturer_state?: boolean;

        match_merging?: boolean;

        model_name_param?: boolean;

        model_name_state?: boolean;

        optional1_param?: string;

        optional1_state?: boolean;

        optional2_param?: string;

        optional2_state?: boolean;

        optional3_param?: string;

        optional3_state?: boolean;

        optional4_param?: string;

        optional4_state?: boolean;

        optional5_param?: string;

        optional5_state?: boolean;

        optional6_param?: string;

        optional6_state?: boolean;

        parent_id?: number;

        price_difference?: number;

        product_count?: {};

        slugs?: Array<{
          _id?: string;

          article_id?: number;

          branch_id?: number;

          carrier_id?: number;

          category_id?: number;

          company_id?: number;

          created?: string;

          group?: number;

          language_id?: number;

          manufacturer_id?: number;

          old_slug?: string;

          product_id?: number;

          shop_id?: number;

          slug?: string;

          slug_id?: number;

          updated?: string;

          user_id?: number;
        }>;

        translations?: Array<{
          category_data_id?: number;

          category_id?: number;

          description?: string;

          full_path?: string;

          language_id?: number;

          name?: string;

          name_short?: string;

          name_singular?: string;

          name_singular_short?: string;

          slug?: string;
        }>;

        tree_left?: number;

        tree_level?: number;

        tree_right?: number;

        use_automatic_assigns?: boolean;
      };

      category_id?: number;

      checked_pid?: string;

      created?: string;

      created_by?: number;

      edited?: string;

      edited_by?: string;

      goods?: Array<{
        affiliate_id?: {};

        availability?: number;

        checked?: string;

        created?: string;

        created_by?: number;

        description?: string;

        ean?: {
          disabled?: string;

          ean?: string;

          ean_id?: number;
        };

        ean_id?: string;

        good_id?: string;

        group_hash?: string;

        image?: string;

        image_hash?: string;

        internal_id?: {};

        launched?: string;

        name?: string;

        original_id?: string;

        original_name?: string;

        part_number_id?: string;

        price?: number;

        product_id?: string;

        rule_id?: number;

        rule_type?: string;

        shipping?: string;

        shop_id?: number;

        shop_reviews_count?: number;

        show_in_autocomplete?: boolean;

        show_in_comparision?: boolean;

        state?: string;

        supplier_id?: {};

        updated?: string;

        url?: string;

        url_hash?: string;

        product_detail_data?: {
          exit_hash?: string;

          recommended_exit_hash?: string;

          dynamic_banner_exit_hash?: string;

          shop_name?: string;

          shop_slug?: string;

          is_recommended?: boolean;

          trusted_shop?: boolean;

          trusted_level?: string;

          partnerships?: Array<{
            name?: string;

            type?: string;
          }>;
        };

        quatro_level?: number;
      }>;

      impressions_count_last_30_day?: number;

      manufacturer?: {
        created?: string;

        created_by?: number;

        edited?: {};

        edited_by?: {};

        enabled?: boolean;

        manufacturer_id?: number;

        monitoring?: boolean;

        name?: string;
      };

      manufacturer_id?: number;

      name?: string;

      parameters?: Array<{
        _id?: string;

        parameter_id?: string;

        parameter_value_id?: string;

        value?: {};
      }>;

      productDescriptions?: Array<{
        created?: string;

        description?: string;

        language_id?: number;

        product_description_id?: string;

        product_id?: string;

        score?: number;

        shop_id?: number;
      }>;

      productReviews?: Array<string>;

      productTrends?: Array<{
        average_price?: number;

        country_id?: number;

        date?: string;

        lowest_price?: number;

        product_id?: number;

        product_trend_id?: string;
      }>;

      product_id?: number;

      show_in_autocomplete?: boolean;

      slugs?: Array<{
        _id?: string;

        article_id?: number;

        branch_id?: number;

        carrier_id?: number;

        category_id?: number;

        company_id?: number;

        created?: string;

        group?: number;

        language_id?: number;

        manufacturer_id?: number;

        old_slug?: string;

        product_id?: number;

        shop_id?: number;

        slug?: string;

        slug_id?: number;

        updated?: string;

        user_id?: number;
      }>;

      state?: string;

      test?: {};

      single_good?: {
        exit_data?: string;

        shop_name?: string;
      };

      reviewAggregations?: Array<{
        language_id?: number;

        count?: number;

        avg?: number;
      }>;

      load_position?: number;

      assetInfo?: {
        count?: number;

        image?: {
          gallery?: Array<string>;

          detail_image?: string;

          listing_image?: string;

          hp_image?: string;
        };

        video?: Array<{
          id?: string;

          url?: string;
        }>;

        model3d?: Array<string>;
      };

      count?: number;

      rank?: number;

      lock?: boolean;

      quatro_level?: number;
    }>;
  }> {
    let path = '/pairing/search/products';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['filterShopIds'] !== 'undefined') {
      queryParameters['filter_shop_ids'] = parameters['filterShopIds'];
    }

    queryParameters['filter_shop_ids'] = this.transformParameter(
      queryParameters['filter_shop_ids'],
    );

    if (typeof parameters['filterCategoryIds'] !== 'undefined') {
      queryParameters['filter_category_ids'] = parameters['filterCategoryIds'];
    }

    queryParameters['filter_category_ids'] = this.transformParameter(
      queryParameters['filter_category_ids'],
    );

    if (typeof parameters['filterManufacturerIds'] !== 'undefined') {
      queryParameters['filter_manufacturer_ids'] =
        parameters['filterManufacturerIds'];
    }

    queryParameters['filter_manufacturer_ids'] = this.transformParameter(
      queryParameters['filter_manufacturer_ids'],
    );

    if (typeof parameters['filterMatchTypes'] !== 'undefined') {
      queryParameters['filter_match_types'] = parameters['filterMatchTypes'];
    }

    queryParameters['filter_match_types'] = this.transformParameter(
      queryParameters['filter_match_types'],
    );

    if (typeof parameters['filterShopProgramIds'] !== 'undefined') {
      queryParameters['filter_shop_program_ids'] =
        parameters['filterShopProgramIds'];
    }

    queryParameters['filter_shop_program_ids'] = this.transformParameter(
      queryParameters['filter_shop_program_ids'],
    );

    if (typeof parameters['searchName'] !== 'undefined') {
      queryParameters['search_name'] = parameters['searchName'];
    }

    queryParameters['search_name'] = this.transformParameter(
      queryParameters['search_name'],
    );

    if (typeof parameters['includeChildrenCategories'] !== 'undefined') {
      queryParameters['include_children_categories'] =
        parameters['includeChildrenCategories'];
    }

    queryParameters['include_children_categories'] = this.transformParameter(
      queryParameters['include_children_categories'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update automatic assignments
   * @method
   * @name API#updateAutomaticAssignment
   */
  updateAutomaticAssignment(
    compareId: number,
    parameters: {} = {},
    body: {
      force_import?: boolean;

      rejected?: boolean;

      force_assign?: string;
    },
  ): Promise<{}> {
    let path = '/pairing/update/{compareId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{compareId}', compareId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * List of mapping manufacturers
   * @method
   * @name API#listManufacturersMapping
   */
  listManufacturersMapping(
    parameters: {
      limit?: number;
      offset?: number;
      onlyNotMapped?: boolean;
      shopId?: Array<number>;
      query?: string;
    } = {},
  ): Promise<{
    items?: Array<{
      shop_manufacturer?: string;

      shop_id?: number;

      manufacturer_id?: number;

      manufacturer_mapping_id?: number;

      manufacturer?: {
        _id?: string;

        created?: string;

        created_by?: number;

        edited?: string;

        edited_by?: number;

        enabled?: boolean;

        manufacturer_id?: number;

        monitoring?: boolean;

        name?: string;

        data?: Array<{
          manufacturer_data_id?: number;

          description?: string;

          url?: string;

          manufacturer_id?: number;

          language_id?: number;
        }>;

        slugs?: Array<{
          _id?: string;

          article_id?: number;

          branch_id?: number;

          carrier_id?: number;

          category_id?: number;

          company_id?: number;

          created?: string;

          group?: number;

          language_id?: number;

          manufacturer_id?: number;

          old_slug?: string;

          product_id?: number;

          shop_id?: number;

          slug?: string;

          slug_id?: number;

          updated?: string;

          user_id?: number;
        }>;
      };
    }>;

    total?: number;

    offset?: number;

    limit?: number;
  }> {
    let path = '/mapping/manufacturers/list';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['onlyNotMapped'] !== 'undefined') {
      queryParameters['only_not_mapped'] = parameters['onlyNotMapped'];
    }

    queryParameters['only_not_mapped'] = this.transformParameter(
      queryParameters['only_not_mapped'],
    );

    if (typeof parameters['shopId'] !== 'undefined') {
      queryParameters['shop_id'] = parameters['shopId'];
    }

    queryParameters['shop_id'] = this.transformParameter(
      queryParameters['shop_id'],
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update manufacturer mapping
   * @method
   * @name API#updateManufacturerMapping
   */
  updateManufacturerMapping(
    manufacturerMappingId: number,
    parameters: {} = {},
    body: {
      shop_id?: number;

      shop_manufacturer?: string;

      manufacturer_id?: number;
    },
  ): Promise<{}> {
    let path = '/mapping/manufacturers/{manufacturerMappingId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace(
      '{manufacturerMappingId}',
      manufacturerMappingId.toString(),
    );

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete manufacturer mapping
   * @method
   * @name API#deleteManufacturerMapping
   */
  deleteManufacturerMapping(
    manufacturerMappingId: number,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/mapping/manufacturers/{manufacturerMappingId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace(
      '{manufacturerMappingId}',
      manufacturerMappingId.toString(),
    );

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create manufacturer mapping
   * @method
   * @name API#createManufacturerMapping
   */
  createManufacturerMapping(
    parameters: {} = {},
    body: {
      manufacturer_id: number;

      shop_id: number;

      shop_manufacturer: string;
    },
  ): Promise<{
    manufacturer_mapping_id?: number;
  }> {
    let path = '/mapping/manufacturers';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Reject category mapping
   * @method
   * @name API#rejectCategoryMapping
   */
  rejectCategoryMapping(
    parameters: {} = {},
    body: {
      shop_id: number;

      shop_category: string;
    },
  ): Promise<{
    category_mapping_id?: number;
  }> {
    let path = '/mapping/categories/reject';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * List of mapping categories
   * @method
   * @name API#listCategoriesMapping
   */
  listCategoriesMapping(
    parameters: {
      shopIds?: Array<number>;
      showRejected?: boolean;
      onlyNotMapped?: boolean;
      limit?: number;
      offset?: number;
      query?: string;
    } = {},
  ): Promise<{
    items?: Array<{
      shop_category?: string;

      shop_id?: number;

      category_id?: number;

      category_mapping_id?: number;

      category?: {
        _id?: string;

        accessories_param?: boolean;

        category_id?: number;

        category_merging?: boolean;

        created?: string;

        created_by?: number;

        description?: string;

        edited?: string;

        edited_by?: number;

        enabled?: boolean;

        filter_name?: string;

        info?: Array<{
          average_ppc?: number;

          average_price?: number;

          category_id?: number;

          category_info_id?: number;

          clickthroughs?: number;

          country_id?: number;

          highest_price?: number;

          impressions?: number;

          lowest_price?: number;

          num_products?: number;

          updated?: string;
        }>;

        is_adult?: boolean;

        is_filter?: boolean;

        manufacturer_merging?: boolean;

        manufacturer_param?: boolean;

        manufacturer_state?: boolean;

        match_merging?: boolean;

        model_name_param?: boolean;

        model_name_state?: boolean;

        optional1_param?: string;

        optional1_state?: boolean;

        optional2_param?: string;

        optional2_state?: boolean;

        optional3_param?: string;

        optional3_state?: boolean;

        optional4_param?: string;

        optional4_state?: boolean;

        optional5_param?: string;

        optional5_state?: boolean;

        optional6_param?: string;

        optional6_state?: boolean;

        parent_id?: number;

        price_difference?: number;

        product_count?: {};

        slugs?: Array<{
          _id?: string;

          article_id?: number;

          branch_id?: number;

          carrier_id?: number;

          category_id?: number;

          company_id?: number;

          created?: string;

          group?: number;

          language_id?: number;

          manufacturer_id?: number;

          old_slug?: string;

          product_id?: number;

          shop_id?: number;

          slug?: string;

          slug_id?: number;

          updated?: string;

          user_id?: number;
        }>;

        translations?: Array<{
          category_data_id?: number;

          category_id?: number;

          description?: string;

          short_description?: string;

          full_path?: string;

          language_id?: number;

          name?: string;

          name_short?: string;

          name_singular?: string;

          name_singular_short?: string;

          slug?: string;
        }>;

        tree_left?: number;

        tree_level?: number;

        tree_right?: number;

        use_automatic_assigns?: boolean;

        children?: Array<{}>;

        parents_names?: Array<string>;

        manufacturer_aggregations?: Array<{
          country_id?: number;

          manufacturer_id?: number;

          count?: number;
        }>;

        parameters?: Array<{
          _id?: string;

          parameter_id?: string;

          position?: number;

          translations?: Array<{
            language_id?: number;

            show?: boolean;

            index?: boolean;

            expand?: boolean;
          }>;
        }>;

        landing_pages?: Array<{
          _id?: string;

          parameters?: Array<{
            parameter_id?: string;

            parameter_value_id?: string;
          }>;

          manufacturers?: Array<number>;

          categories?: Array<number>;

          sort?: {
            type?: string | null;

            order?: string | null;
          };

          price_from?: number | null;

          price_to?: number | null;

          translations?: Array<{
            active?: boolean;

            title?: string | null;

            header?: string | null;

            short_description?: string | null;

            description?: string | null;

            language_id?: number;
          }>;
        }>;

        listing_links?: Array<{
          language_id?: number;

          position?: number;

          name?: string;

          url?: string;
        }>;

        pn_pairing_enabled?: boolean;
      };
    }>;

    total?: number;

    offset?: number;

    limit?: number;
  }> {
    let path = '/mapping/categories/list';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['shopIds'] !== 'undefined') {
      queryParameters['shop_ids'] = parameters['shopIds'];
    }

    queryParameters['shop_ids'] = this.transformParameter(
      queryParameters['shop_ids'],
    );

    if (typeof parameters['showRejected'] !== 'undefined') {
      queryParameters['show_rejected'] = parameters['showRejected'];
    }

    queryParameters['show_rejected'] = this.transformParameter(
      queryParameters['show_rejected'],
    );

    if (typeof parameters['onlyNotMapped'] !== 'undefined') {
      queryParameters['only_not_mapped'] = parameters['onlyNotMapped'];
    }

    queryParameters['only_not_mapped'] = this.transformParameter(
      queryParameters['only_not_mapped'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update category mapping
   * @method
   * @name API#updateCategoryMapping
   */
  updateCategoryMapping(
    categoryMappingId: number,
    parameters: {} = {},
    body: {
      shop_id?: number;

      shop_category?: string;

      category_id?: number;
    },
  ): Promise<{}> {
    let path = '/mapping/categories/{categoryMappingId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{categoryMappingId}', categoryMappingId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete category mapping
   * @method
   * @name API#deleteCategoryMapping
   */
  deleteCategoryMapping(
    categoryMappingId: number,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/mapping/categories/{categoryMappingId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{categoryMappingId}', categoryMappingId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create category mapping
   * @method
   * @name API#createCategoryMapping
   */
  createCategoryMapping(
    parameters: {} = {},
    body: {
      category_id: number;

      shop_id: number;

      shop_category: string;
    },
  ): Promise<{
    category_mapping_id?: number;
  }> {
    let path = '/mapping/categories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * List of replacement phrases
   * @method
   * @name API#listReplacementPhrases
   */
  listReplacementPhrases(
    parameters: {
      limit?: number;
      offset?: number;
      originalWord?: string;
      categoryId?: Array<number>;
      manufacturerId?: Array<number>;
      withManufacturerNull?: boolean;
      field?: string;
      humanRegex?: string;
    } = {},
  ): Promise<{
    items?: Array<{
      replacement_id?: number;

      category_id?: number;

      created?: string;

      created_by?: number;

      edited?: string;

      edited_by?: number;

      field?: string;

      manufacturer_id?: number;

      original_word?: string;

      replace_word?: string;

      rule_id?: number;

      type?: string;
    }>;
  }> {
    let path = '/replacements/phrases/list';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['originalWord'] !== 'undefined') {
      queryParameters['originalWord'] = parameters['originalWord'];
    }

    queryParameters['originalWord'] = this.transformParameter(
      queryParameters['originalWord'],
    );

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    if (typeof parameters['manufacturerId'] !== 'undefined') {
      queryParameters['manufacturer_id'] = parameters['manufacturerId'];
    }

    queryParameters['manufacturer_id'] = this.transformParameter(
      queryParameters['manufacturer_id'],
    );

    if (typeof parameters['withManufacturerNull'] !== 'undefined') {
      queryParameters['with_manufacturer_null'] =
        parameters['withManufacturerNull'];
    }

    queryParameters['with_manufacturer_null'] = this.transformParameter(
      queryParameters['with_manufacturer_null'],
    );

    if (typeof parameters['field'] !== 'undefined') {
      queryParameters['field'] = parameters['field'];
    }

    queryParameters['field'] = this.transformParameter(
      queryParameters['field'],
    );

    if (typeof parameters['humanRegex'] !== 'undefined') {
      queryParameters['human_regex'] = parameters['humanRegex'];
    }

    queryParameters['human_regex'] = this.transformParameter(
      queryParameters['human_regex'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update replacement phrase
   * @method
   * @name API#updateReplacementPhrase
   */
  updateReplacementPhrase(
    replacementId: number,
    parameters: {} = {},
    body: {
      category_id?: number;

      field?: string;

      manufacturer_id?: number;

      original_word?: string;

      replace_word?: string;

      rule_id?: number;
    },
  ): Promise<{}> {
    let path = '/replacements/phrases/{replacementId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{replacementId}', replacementId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete replacement phrase
   * @method
   * @name API#deleteReplacementPhrase
   */
  deleteReplacementPhrase(
    replacementId: number,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/replacements/phrases/{replacementId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{replacementId}', replacementId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create new replacement phrase
   * @method
   * @name API#createReplacementPhrase
   */
  createReplacementPhrase(
    parameters: {} = {},
    body: {
      category_id: number;

      field: string;

      manufacturer_id: number;

      original_word: string;

      replace_word: string;

      rule_id: number;

      human_regex?: string;
    },
  ): Promise<{
    replacement_id?: number;
  }> {
    let path = '/replacements/phrases';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * List of replacement words
   * @method
   * @name API#listReplacementWords
   */
  listReplacementWords(
    parameters: {
      limit?: number;
      offset?: number;
      originalWord?: string;
      categoryId?: Array<number>;
      manufacturerId?: Array<number>;
      withManufacturerNull?: boolean;
    } = {},
  ): Promise<{
    items?: Array<{
      replacement_id?: number;

      category_id?: number;

      created?: string;

      created_by?: number;

      edited?: string;

      edited_by?: number;

      field?: string;

      manufacturer_id?: number;

      original_word?: string;

      replace_word?: string;

      rule_id?: number;

      type?: string;
    }>;
  }> {
    let path = '/replacements/words/list';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['originalWord'] !== 'undefined') {
      queryParameters['originalWord'] = parameters['originalWord'];
    }

    queryParameters['originalWord'] = this.transformParameter(
      queryParameters['originalWord'],
    );

    if (typeof parameters['categoryId'] !== 'undefined') {
      queryParameters['category_id'] = parameters['categoryId'];
    }

    queryParameters['category_id'] = this.transformParameter(
      queryParameters['category_id'],
    );

    if (typeof parameters['manufacturerId'] !== 'undefined') {
      queryParameters['manufacturer_id'] = parameters['manufacturerId'];
    }

    queryParameters['manufacturer_id'] = this.transformParameter(
      queryParameters['manufacturer_id'],
    );

    if (typeof parameters['withManufacturerNull'] !== 'undefined') {
      queryParameters['with_manufacturer_null'] =
        parameters['withManufacturerNull'];
    }

    queryParameters['with_manufacturer_null'] = this.transformParameter(
      queryParameters['with_manufacturer_null'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update replacement word
   * @method
   * @name API#updateReplacementWord
   */
  updateReplacementWord(
    replacementId: number,
    parameters: {} = {},
    body: {
      category_id?: number;

      field?: string;

      manufacturer_id?: number;

      original_word?: string;

      replace_word?: string;

      rule_id?: number;
    },
  ): Promise<{}> {
    let path = '/replacements/words/{replacementId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{replacementId}', replacementId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Delete replacement word
   * @method
   * @name API#deleteReplacementWord
   */
  deleteReplacementWord(
    replacementId: number,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/replacements/words/{replacementId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{replacementId}', replacementId.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create new replacement word
   * @method
   * @name API#createReplacementWord
   */
  createReplacementWord(
    parameters: {} = {},
    body: {
      category_id: number;

      field: string;

      manufacturer_id: number;

      original_word: string;

      replace_word: string;

      rule_id: number;

      human_regex?: string;
    },
  ): Promise<{
    replacement_id?: number;
  }> {
    let path = '/replacements/words';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#loadWidgets
   */
  loadWidgets(
    parameters: {
      widgetIds?: Array<string>;
      affiliateIds?: Array<number>;
    } = {},
  ): Promise<{
    limit?: number;

    offset?: number;

    total?: number;

    items?: Array<{
      _id?: string;

      widget_id?: number;

      country_id?: number;

      affiliate_id?: number;

      type?: string;

      widget_product?: {
        product_id?: number;

        product?: {
          name?: string;

          category?: {
            name?: string;
          };

          aggregations?: Array<{
            active_offers_count?: number;

            active_shops_count?: number;

            country_id?: number;
          }>;
        };

        settings?: {
          colors?: {
            primary?: string;
          };

          show_header?: boolean;

          max_shops?: number;
        };
      };

      tags?: Array<string>;

      affiliate?: {
        name?: string;
      };
    }>;
  }> {
    let path = '/widgets';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['widgetIds'] !== 'undefined') {
      queryParameters['widget_ids'] = parameters['widgetIds'];
    }

    queryParameters['widget_ids'] = this.transformParameter(
      queryParameters['widget_ids'],
    );

    if (typeof parameters['affiliateIds'] !== 'undefined') {
      queryParameters['affiliate_ids'] = parameters['affiliateIds'];
    }

    queryParameters['affiliate_ids'] = this.transformParameter(
      queryParameters['affiliate_ids'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create product widget
   * @method
   * @name API#createProductWidget
   */
  createProductWidget(
    parameters: {} = {},
    body: {
      product_id: number;

      country_id?: number;
    },
    extraHeaders?: {
      authorization?: string;
    },
  ): Promise<{
    _id?: string;

    widget_id?: number;

    html_codes?: Array<{
      width?: number;

      code?: string;
    }>;
  }> {
    let path = '/widgets/product';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (extraHeaders && typeof extraHeaders['authorization'] !== 'undefined') {
      headers.append('Authorization', extraHeaders['authorization']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Update product widget settings
   * @method
   * @name API#updateProductWidgetSettings
   */
  updateProductWidgetSettings(
    widgetId: string,
    parameters: {} = {},
    body: {
      colors?: {
        primary?: string;
      };

      show_header?: boolean;

      max_shops?: number;
    },
  ): Promise<{
    _id?: string;

    widget_id?: number;

    html_codes?: Array<{
      width?: number;

      code?: string;
    }>;
  }> {
    let path = '/widgets/product/{widget_id}/settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{widget_id}', widgetId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Create modal product widget
   * @method
   * @name API#createModalProductWidget
   */
  createModalProductWidget(
    parameters: {} = {},
    body: {
      product_id: number;

      country_id?: number;
    },
    extraHeaders?: {
      authorization?: string;
    },
  ): Promise<{
    _id?: string;

    widget_id?: number;

    html_codes?: Array<{
      width?: number;

      code?: string;
    }>;
  }> {
    let path = '/widgets/modal_product';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (extraHeaders && typeof extraHeaders['authorization'] !== 'undefined') {
      headers.append('Authorization', extraHeaders['authorization']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Create button product widget
   * @method
   * @name API#createButtonProductWidget
   */
  createButtonProductWidget(
    parameters: {} = {},
    body: {
      product_id: number;

      country_id?: number;
    },
    extraHeaders?: {
      authorization?: string;
    },
  ): Promise<{
    _id?: string;

    widget_id?: number;

    html_codes?: Array<{
      width?: number;

      code?: string;
    }>;
  }> {
    let path = '/widgets/button_product';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (extraHeaders && typeof extraHeaders['authorization'] !== 'undefined') {
      headers.append('Authorization', extraHeaders['authorization']!);
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Widget stats
   * @method
   * @name API#loadWidgetStats
   */
  loadWidgetStats(
    parameters: {
      startDate?: string;
      endDate?: string;
      affiliateId?: number;
      limit?: number;
      offset?: number;
      tags?: string;
      categoryIds?: Array<number>;
    } = {},
  ): Promise<{
    limit?: number;

    offset?: number;

    total?: number;

    items?: Array<{
      _id?: string;

      clicks?: number;

      widget_session_clicks?: number;

      widget_session_widget_clicks?: number;

      impressions?: number;

      payment?: number;

      widget_session_payment?: number;

      widget_session_payment_provision?: number;

      count?: number;

      widget?: {
        _id?: string;

        widget_id?: number;

        country_id?: number;

        affiliate_id?: number;

        type?: string;

        widget_product?: {
          product_id?: number;

          product?: {
            name?: string;

            category?: {
              name?: string;
            };

            aggregations?: Array<{
              active_offers_count?: number;

              active_shops_count?: number;

              country_id?: number;
            }>;
          };

          settings?: {
            colors?: {
              primary?: string;
            };

            show_header?: boolean;

            max_shops?: number;
          };
        };

        tags?: Array<string>;

        affiliate?: {
          name?: string;
        };
      };
    }>;
  }> {
    let path = '/widgets/stats/';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['startDate'] !== 'undefined') {
      queryParameters['start_date'] = parameters['startDate'];
    }

    queryParameters['start_date'] = this.transformParameter(
      queryParameters['start_date'],
    );

    if (typeof parameters['endDate'] !== 'undefined') {
      queryParameters['end_date'] = parameters['endDate'];
    }

    queryParameters['end_date'] = this.transformParameter(
      queryParameters['end_date'],
    );

    if (typeof parameters['affiliateId'] !== 'undefined') {
      queryParameters['affiliate_id'] = parameters['affiliateId'];
    }

    queryParameters['affiliate_id'] = this.transformParameter(
      queryParameters['affiliate_id'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['tags'] !== 'undefined') {
      queryParameters['tags'] = parameters['tags'];
    }

    queryParameters['tags'] = this.transformParameter(queryParameters['tags']);

    if (typeof parameters['categoryIds'] !== 'undefined') {
      queryParameters['category_ids'] = parameters['categoryIds'];
    }

    queryParameters['category_ids'] = this.transformParameter(
      queryParameters['category_ids'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Export widget stats --> DEPRECATED
   * @method
   * @name API#exportWidgetStats
   */
  exportWidgetStats(
    parameters: {
      startDate?: string;
      endDate?: string;
      affiliateId?: number;
      limit?: number;
      offset?: number;
      tags?: string;
      categoryIds?: Array<number>;
    } = {},
  ): Promise<{
    csv?: string;
  }> {
    let path = '/widgets/stats/export';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['startDate'] !== 'undefined') {
      queryParameters['start_date'] = parameters['startDate'];
    }

    queryParameters['start_date'] = this.transformParameter(
      queryParameters['start_date'],
    );

    if (typeof parameters['endDate'] !== 'undefined') {
      queryParameters['end_date'] = parameters['endDate'];
    }

    queryParameters['end_date'] = this.transformParameter(
      queryParameters['end_date'],
    );

    if (typeof parameters['affiliateId'] !== 'undefined') {
      queryParameters['affiliate_id'] = parameters['affiliateId'];
    }

    queryParameters['affiliate_id'] = this.transformParameter(
      queryParameters['affiliate_id'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['tags'] !== 'undefined') {
      queryParameters['tags'] = parameters['tags'];
    }

    queryParameters['tags'] = this.transformParameter(queryParameters['tags']);

    if (typeof parameters['categoryIds'] !== 'undefined') {
      queryParameters['category_ids'] = parameters['categoryIds'];
    }

    queryParameters['category_ids'] = this.transformParameter(
      queryParameters['category_ids'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Widget general stats
   * @method
   * @name API#loadWidgetGeneralStats
   */
  loadWidgetGeneralStats(
    parameters: {
      startDate?: string;
      endDate?: string;
      affiliateIds?: Array<number>;
      tags?: string;
      categoryIds?: Array<number>;
    } = {},
  ): Promise<{
    clicks?: number;

    widget_session_clicks?: number;

    widget_session_widget_clicks?: number;

    impressions?: number;

    payment?: number;

    widget_session_payment?: number;

    widget_session_payment_provision?: number;

    count?: number;
  }> {
    let path = '/widgets/stats/general';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['startDate'] !== 'undefined') {
      queryParameters['start_date'] = parameters['startDate'];
    }

    queryParameters['start_date'] = this.transformParameter(
      queryParameters['start_date'],
    );

    if (typeof parameters['endDate'] !== 'undefined') {
      queryParameters['end_date'] = parameters['endDate'];
    }

    queryParameters['end_date'] = this.transformParameter(
      queryParameters['end_date'],
    );

    if (typeof parameters['affiliateIds'] !== 'undefined') {
      queryParameters['affiliate_ids'] = parameters['affiliateIds'];
    }

    queryParameters['affiliate_ids'] = this.transformParameter(
      queryParameters['affiliate_ids'],
    );

    if (typeof parameters['tags'] !== 'undefined') {
      queryParameters['tags'] = parameters['tags'];
    }

    queryParameters['tags'] = this.transformParameter(queryParameters['tags']);

    if (typeof parameters['categoryIds'] !== 'undefined') {
      queryParameters['category_ids'] = parameters['categoryIds'];
    }

    queryParameters['category_ids'] = this.transformParameter(
      queryParameters['category_ids'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Widget general stats
   * @method
   * @name API#loadWidgetGeneralAffiliatesStats
   */
  loadWidgetGeneralAffiliatesStats(
    parameters: {
      startDate?: string;
      endDate?: string;
      affiliateIds?: Array<number>;
      tags?: string;
      categoryIds?: Array<number>;
    } = {},
  ): Promise<{
    total?: number;

    items?: Array<{
      affiliate_id?: number;

      affiliate_name?: string;

      clicks?: number;

      impressions?: number;

      payment?: number;

      count?: number;
    }>;
  }> {
    let path = '/widgets/stats/general/affiliates';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['startDate'] !== 'undefined') {
      queryParameters['start_date'] = parameters['startDate'];
    }

    queryParameters['start_date'] = this.transformParameter(
      queryParameters['start_date'],
    );

    if (typeof parameters['endDate'] !== 'undefined') {
      queryParameters['end_date'] = parameters['endDate'];
    }

    queryParameters['end_date'] = this.transformParameter(
      queryParameters['end_date'],
    );

    if (typeof parameters['affiliateIds'] !== 'undefined') {
      queryParameters['affiliate_ids'] = parameters['affiliateIds'];
    }

    queryParameters['affiliate_ids'] = this.transformParameter(
      queryParameters['affiliate_ids'],
    );

    if (typeof parameters['tags'] !== 'undefined') {
      queryParameters['tags'] = parameters['tags'];
    }

    queryParameters['tags'] = this.transformParameter(queryParameters['tags']);

    if (typeof parameters['categoryIds'] !== 'undefined') {
      queryParameters['category_ids'] = parameters['categoryIds'];
    }

    queryParameters['category_ids'] = this.transformParameter(
      queryParameters['category_ids'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Widget general daily stats
   * @method
   * @name API#loadWidgetGeneralDailyStats
   */
  loadWidgetGeneralDailyStats(
    parameters: {
      startDate?: string;
      endDate?: string;
      affiliateIds?: Array<number>;
      tags?: string;
      categoryIds?: Array<number>;
    } = {},
  ): Promise<{
    items?: Array<{
      date?: string;

      clicks?: number;

      widget_session_clicks?: number;

      widget_session_widget_clicks?: number;

      impressions?: number;

      payment?: number;

      widget_session_payment?: number;

      widget_session_payment_provision?: number;

      count?: number;
    }>;
  }> {
    let path = '/widgets/stats/general/daily';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['startDate'] !== 'undefined') {
      queryParameters['start_date'] = parameters['startDate'];
    }

    queryParameters['start_date'] = this.transformParameter(
      queryParameters['start_date'],
    );

    if (typeof parameters['endDate'] !== 'undefined') {
      queryParameters['end_date'] = parameters['endDate'];
    }

    queryParameters['end_date'] = this.transformParameter(
      queryParameters['end_date'],
    );

    if (typeof parameters['affiliateIds'] !== 'undefined') {
      queryParameters['affiliate_ids'] = parameters['affiliateIds'];
    }

    queryParameters['affiliate_ids'] = this.transformParameter(
      queryParameters['affiliate_ids'],
    );

    if (typeof parameters['tags'] !== 'undefined') {
      queryParameters['tags'] = parameters['tags'];
    }

    queryParameters['tags'] = this.transformParameter(queryParameters['tags']);

    if (typeof parameters['categoryIds'] !== 'undefined') {
      queryParameters['category_ids'] = parameters['categoryIds'];
    }

    queryParameters['category_ids'] = this.transformParameter(
      queryParameters['category_ids'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Widgets stats
   * @method
   * @name API#loadWidgetsTags
   */
  loadWidgetsTags(
    parameters: {
      affiliateId?: number;
    } = {},
  ): Promise<{
    tags?: Array<{
      name?: string;
    }>;
  }> {
    let path = '/widgets/tags';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['affiliateId'] !== 'undefined') {
      queryParameters['affiliate_id'] = parameters['affiliateId'];
    }

    queryParameters['affiliate_id'] = this.transformParameter(
      queryParameters['affiliate_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update widget tags
   * @method
   * @name API#updateWidgetTags
   */
  updateWidgetTags(
    widgetId: string,
    parameters: {} = {},
    body: {
      tags?: Array<string>;
    },
    extraHeaders?: {
      authorization?: string;
    },
  ): Promise<{}> {
    let path = '/widgets/{widget_id}/tags';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{widget_id}', widgetId.toString());

    if (extraHeaders && typeof extraHeaders['authorization'] !== 'undefined') {
      headers.append('Authorization', extraHeaders['authorization']!);
    }

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Widgets categories
   * @method
   * @name API#loadWidgetsCategories
   */
  loadWidgetsCategories(
    parameters: {
      affiliateId?: number;
    } = {},
  ): Promise<{
    categories?: Array<{
      category_id?: number;

      count?: number;
    }>;
  }> {
    let path = '/widgets/categories';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['affiliateId'] !== 'undefined') {
      queryParameters['affiliate_id'] = parameters['affiliateId'];
    }

    queryParameters['affiliate_id'] = this.transformParameter(
      queryParameters['affiliate_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#loadWebWidgets
   */
  loadWebWidgets(parameters: {
    widgetIds?: Array<number>;
    limit?: number;
    countryId: number;
  }): Promise<{
    items?: Array<{
      created?: number;

      updated?: number;

      widget_id?: number;

      widget_name?: string;

      widget_data?: Array<{
        _id?: number;

        index?: number;

        userDefined?: boolean;

        name?: string;

        nameSk?: string;

        nameCz?: string;

        itemImgSk?: string;

        itemImgCz?: string;

        itemUrlSk?: string;

        itemUrlCz?: string;

        price_cz?: number;

        price_sk?: number;
      }>;
    }>;
  }> {
    let path = '/widgets/web-widgets';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['widgetIds'] !== 'undefined') {
      queryParameters['widget_ids'] = parameters['widgetIds'];
    }

    queryParameters['widget_ids'] = this.transformParameter(
      queryParameters['widget_ids'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['countryId'] !== 'undefined') {
      queryParameters['country_id'] = parameters['countryId'];
    }

    queryParameters['country_id'] = this.transformParameter(
      queryParameters['country_id'],
    );

    if (typeof parameters['countryId'] === 'undefined') {
      throw new Error('Missing required parameter: countryId');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#updateItemToWidget
   */
  updateItemToWidget(parameters: {
    widgetId?: number;
    itemId?: number;
    newIndex?: number;
    extra?: string;
    countryId: number;
  }): Promise<{
    items?: Array<{
      created?: number;

      updated?: number;

      widget_id?: number;

      widget_name?: string;

      widget_data?: Array<{
        _id?: number;

        index?: number;

        userDefined?: boolean;

        name?: string;

        nameSk?: string;

        nameCz?: string;

        itemImgSk?: string;

        itemImgCz?: string;

        itemUrlSk?: string;

        itemUrlCz?: string;

        price_cz?: number;

        price_sk?: number;
      }>;
    }>;
  }> {
    let path = '/widgets/web-widgets-save';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['widgetId'] !== 'undefined') {
      queryParameters['widget_id'] = parameters['widgetId'];
    }

    queryParameters['widget_id'] = this.transformParameter(
      queryParameters['widget_id'],
    );

    if (typeof parameters['itemId'] !== 'undefined') {
      queryParameters['item_id'] = parameters['itemId'];
    }

    queryParameters['item_id'] = this.transformParameter(
      queryParameters['item_id'],
    );

    if (typeof parameters['newIndex'] !== 'undefined') {
      queryParameters['new_index'] = parameters['newIndex'];
    }

    queryParameters['new_index'] = this.transformParameter(
      queryParameters['new_index'],
    );

    if (typeof parameters['extra'] !== 'undefined') {
      queryParameters['extra'] = parameters['extra'];
    }

    queryParameters['extra'] = this.transformParameter(
      queryParameters['extra'],
    );

    if (typeof parameters['countryId'] !== 'undefined') {
      queryParameters['country_id'] = parameters['countryId'];
    }

    queryParameters['country_id'] = this.transformParameter(
      queryParameters['country_id'],
    );

    if (typeof parameters['countryId'] === 'undefined') {
      throw new Error('Missing required parameter: countryId');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#loadWebWidgetsClient
   */
  loadWebWidgetsClient(parameters: {
    widgetId: number;
    limit?: number;
    countryId?: number;
  }): Promise<
    Array<{
      _id?: number;

      index?: number;

      userDefined?: boolean;

      name?: string;

      nameSk?: string;

      nameCz?: string;

      itemImgSk?: string;

      itemImgCz?: string;

      itemUrlSk?: string;

      itemUrlCz?: string;

      price_cz?: number;

      price_sk?: number;
    }>
  > {
    let path = '/widgets/frontend';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['widgetId'] !== 'undefined') {
      queryParameters['widget_id'] = parameters['widgetId'];
    }

    queryParameters['widget_id'] = this.transformParameter(
      queryParameters['widget_id'],
    );

    if (typeof parameters['widgetId'] === 'undefined') {
      throw new Error('Missing required parameter: widgetId');
    }

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['countryId'] !== 'undefined') {
      queryParameters['country_id'] = parameters['countryId'];
    }

    queryParameters['country_id'] = this.transformParameter(
      queryParameters['country_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Check widget
   * @method
   * @name API#checkWidget
   */
  checkWidget(
    widgetId: string,
    parameters: {},
    extraHeaders?: {
      authorization: string;
    },
  ): Promise<{}> {
    let path = '/widgets/check/{widget_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{widget_id}', widgetId.toString());

    if (extraHeaders && typeof extraHeaders['authorization'] !== 'undefined') {
      headers.append('authorization', extraHeaders['authorization']!);
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create widget session
   * @method
   * @name API#createWidgetSession
   */
  createWidgetSession(
    widgetId: string,
    parameters: {} = {},
  ): Promise<{
    session_id?: string;
  }> {
    let path = '/widgets/session/{widget_id}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{widget_id}', widgetId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Activate widget session
   * @method
   * @name API#activateWidgetSession
   */
  activateWidgetSession(
    sessionId: string,
    parameters: {} = {},
  ): Promise<{
    success?: boolean;
  }> {
    let path = '/widgets/session/{session_id}/activate';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{session_id}', sessionId.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Load parameter
   * @method
   * @name API#loadParameter
   */
  loadParameter(
    parameterId: string,
    parameters: {} = {},
  ): Promise<{
    _id?: string;

    custom_id?: number;

    internal_name?: string;

    type?: string;

    values?: Array<{
      _id?: string;

      custom_id?: number;

      internal_name?: string;

      position?: number;

      translated_names?: Array<{
        language_id?: number;

        name?: string;
      }>;
    }>;

    translations?: Array<{
      language_id?: number;

      name?: string;

      description?: string;

      unit?: string;
    }>;

    products_count?: number;

    index?: boolean;

    show?: boolean;

    expand?: boolean;
  }> {
    let path = '/parameter/{parameterId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{parameterId}', parameterId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update parameter
   * @method
   * @name API#updateParameter
   */
  updateParameter(
    parameterId: string,
    parameters: {} = {},
    body: {
      _id?: string;

      internal_name?: string;

      type?: string;

      values?: Array<{
        _id?: string;

        internal_name?: string;

        position?: number;

        translated_names?: Array<{
          language_id?: number;

          name?: string;
        }>;
      }>;

      translations?: Array<{
        language_id?: number;

        name?: string;

        description?: string;

        unit?: string;
      }>;
    },
  ): Promise<{}> {
    let path = '/parameter/{parameterId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{parameterId}', parameterId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Load parameters
   * @method
   * @name API#loadParameters
   */
  loadParameters(
    parameters: {
      filterParameterIds?: Array<string>;
      limit?: number;
      offset?: number;
      withAssignedCategories?: boolean;
      sortType?: string;
      sortOrder?: string;
      languageId?: number;
      filterInternalName?: string;
      filterCategoryIds?: Array<number>;
      filterShow?: boolean;
      filterIndex?: boolean;
    } = {},
  ): Promise<{
    total?: number;

    items?: Array<{
      _id?: string;

      custom_id?: number;

      internal_name?: string;

      type?: string;

      values?: Array<{
        _id?: string;

        custom_id?: number;

        internal_name?: string;

        position?: number;

        translated_names?: Array<{
          language_id?: number;

          name?: string;
        }>;
      }>;

      translations?: Array<{
        language_id?: number;

        name?: string;

        description?: string;

        unit?: string;
      }>;

      products_count?: number;

      index?: boolean;

      show?: boolean;

      expand?: boolean;
    }>;
  }> {
    let path = '/parameters';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['filterParameterIds'] !== 'undefined') {
      queryParameters['filter_parameter_ids'] =
        parameters['filterParameterIds'];
    }

    queryParameters['filter_parameter_ids'] = this.transformParameter(
      queryParameters['filter_parameter_ids'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    if (typeof parameters['withAssignedCategories'] !== 'undefined') {
      queryParameters['with_assigned_categories'] =
        parameters['withAssignedCategories'];
    }

    queryParameters['with_assigned_categories'] = this.transformParameter(
      queryParameters['with_assigned_categories'],
    );

    if (typeof parameters['sortType'] !== 'undefined') {
      queryParameters['sort_type'] = parameters['sortType'];
    }

    queryParameters['sort_type'] = this.transformParameter(
      queryParameters['sort_type'],
    );

    if (typeof parameters['sortOrder'] !== 'undefined') {
      queryParameters['sort_order'] = parameters['sortOrder'];
    }

    queryParameters['sort_order'] = this.transformParameter(
      queryParameters['sort_order'],
    );

    if (typeof parameters['languageId'] !== 'undefined') {
      queryParameters['language_id'] = parameters['languageId'];
    }

    queryParameters['language_id'] = this.transformParameter(
      queryParameters['language_id'],
    );

    if (typeof parameters['filterInternalName'] !== 'undefined') {
      queryParameters['filter_internal_name'] =
        parameters['filterInternalName'];
    }

    queryParameters['filter_internal_name'] = this.transformParameter(
      queryParameters['filter_internal_name'],
    );

    if (typeof parameters['filterCategoryIds'] !== 'undefined') {
      queryParameters['filter_category_ids'] = parameters['filterCategoryIds'];
    }

    queryParameters['filter_category_ids'] = this.transformParameter(
      queryParameters['filter_category_ids'],
    );

    if (typeof parameters['filterShow'] !== 'undefined') {
      queryParameters['filter_show'] = parameters['filterShow'];
    }

    queryParameters['filter_show'] = this.transformParameter(
      queryParameters['filter_show'],
    );

    if (typeof parameters['filterIndex'] !== 'undefined') {
      queryParameters['filter_index'] = parameters['filterIndex'];
    }

    queryParameters['filter_index'] = this.transformParameter(
      queryParameters['filter_index'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create parameter
   * @method
   * @name API#createParameter
   */
  createParameter(
    parameters: {} = {},
  ): Promise<{
    parameter?: {
      _id?: string;

      custom_id?: number;

      internal_name?: string;

      type?: string;

      values?: Array<{
        _id?: string;

        custom_id?: number;

        internal_name?: string;

        position?: number;

        translated_names?: Array<{
          language_id?: number;

          name?: string;
        }>;
      }>;

      translations?: Array<{
        language_id?: number;

        name?: string;

        description?: string;

        unit?: string;
      }>;

      products_count?: number;

      index?: boolean;

      show?: boolean;

      expand?: boolean;
    };
  }> {
    let path = '/parameters';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Load icecat mappings
   * @method
   * @name API#loadParameterIcecatMappings
   */
  loadParameterIcecatMappings(
    parameters: {
      filterIds?: Array<string>;
    } = {},
  ): Promise<{
    total?: number;

    items?: Array<{
      _id?: string;

      category_ids?: Array<number>;

      value_parameters?: Array<{
        icecat_id?: number | string;

        parameter_id?: string;

        values?: Array<{
          icecat_id?: number | string;

          parameter_value_id?: string;
        }>;
      }>;

      number_parameters?: Array<{
        icecat_id?: number | string;

        parameter_id?: string;
      }>;

      create_parameters_enabled?: boolean;
    }>;
  }> {
    let path = '/parameters/icecat/mappings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['filterIds'] !== 'undefined') {
      queryParameters['filter_ids'] = parameters['filterIds'];
    }

    queryParameters['filter_ids'] = this.transformParameter(
      queryParameters['filter_ids'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create icecat mappings
   * @method
   * @name API#createParameterIcecatMappings
   */
  createParameterIcecatMappings(parameters: {} = {}): Promise<{}> {
    let path = '/parameters/icecat/mappings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Update icecat mapping
   * @method
   * @name API#updateParameterIcecatMapping
   */
  updateParameterIcecatMapping(
    icecatMappingId: string,
    parameters: {} = {},
    body: {
      _id?: string;

      category_ids?: Array<number>;

      value_parameters?: Array<{
        icecat_id?: number | string;

        parameter_id?: string;

        values?: Array<{
          icecat_id?: number | string;

          parameter_value_id?: string;
        }>;
      }>;

      number_parameters?: Array<{
        icecat_id?: number | string;

        parameter_id?: string;
      }>;

      create_parameters_enabled?: boolean;
    },
  ): Promise<{}> {
    let path = '/parameters/icecat/mapping/{icecatMappingId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{icecatMappingId}', icecatMappingId.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Set shop order
   * @method
   * @name API#setShopOrder
   */
  setShopOrder(
    parameters: {} = {},
    body: {
      public_key: string;

      order_id: string;

      user_email?: string;

      products?: Array<{
        name?: string;

        price?: number;

        quantity?: number;
      }>;

      is_from_pma?: boolean;
    },
  ): Promise<void> {
    let path = '/public/shop/order';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * get
   * @method
   * @name API#getAffils
   */
  getAffils(
    parameters: {
      hasAssignedUser?: boolean;
      sortType?: string;
      sortOrder?: string;
    } = {},
  ): Promise<{
    items?: Array<{
      _id?: string;

      affiliate_id?: number;

      name?: string;
    }>;
  }> {
    let path = '/affil';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['hasAssignedUser'] !== 'undefined') {
      queryParameters['has_assigned_user'] = parameters['hasAssignedUser'];
    }

    queryParameters['has_assigned_user'] = this.transformParameter(
      queryParameters['has_assigned_user'],
    );

    if (typeof parameters['sortType'] !== 'undefined') {
      queryParameters['sort_type'] = parameters['sortType'];
    }

    queryParameters['sort_type'] = this.transformParameter(
      queryParameters['sort_type'],
    );

    if (typeof parameters['sortOrder'] !== 'undefined') {
      queryParameters['sort_order'] = parameters['sortOrder'];
    }

    queryParameters['sort_order'] = this.transformParameter(
      queryParameters['sort_order'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get data for affil form
   * @method
   * @name API#getAffilSettings
   */
  getAffilSettings(
    parameters: {
      affiliateId?: number;
    } = {},
  ): Promise<{}> {
    let path = '/affil/settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['affiliateId'] !== 'undefined') {
      queryParameters['affiliate_id'] = parameters['affiliateId'];
    }

    queryParameters['affiliate_id'] = this.transformParameter(
      queryParameters['affiliate_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Post data from affil form
   * @method
   * @name API#postAffilSettings
   */
  postAffilSettings(
    parameters: {} = {},
    body: {
      projectId: number | null;

      imagePath: string | null;

      widget?: {
        countryId?: number;

        showHeader?: boolean;

        maxShops?: number;

        hideWithoutOffer?: boolean;

        linkToPricemaniaWithoutOffer?: boolean;
      };

      colors: {
        primary: string;

        secondary: string;
      };
    },
  ): Promise<{}> {
    let path = '/affil/settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Get data for affil form
   * @method
   * @name API#getAffilUserSettings
   */
  getAffilUserSettings(
    parameters: {
      affiliateId?: number;
    } = {},
  ): Promise<{}> {
    let path = '/affil/user/settings';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['affiliateId'] !== 'undefined') {
      queryParameters['affiliate_id'] = parameters['affiliateId'];
    }

    queryParameters['affiliate_id'] = this.transformParameter(
      queryParameters['affiliate_id'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Post data from affil form
   * @method
   * @name API#uploadAffilLogo
   */
  uploadAffilLogo(
    parameters: {} = {},
    form: {
      file?: {};
    },
  ): Promise<{}> {
    let path = '/affil/logo';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      form,
      headers,
      queryParameters,
    );
  }
  /**
   * Create scheduled task
   * @method
   * @name API#createScheduledTask
   */
  createScheduledTask(
    parameters: {} = {},
    body: {
      type?: string;

      shop_id?: number;

      data?: {};
    },
  ): Promise<{
    success?: boolean;
  }> {
    let path = '/scheduled_tasks';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Load scheduled tasks
   * @method
   * @name API#loadScheduledTasks
   */
  loadScheduledTasks(
    parameters: {
      filterShopIds?: Array<number>;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    total?: number;

    items?: Array<{
      _id?: string;

      type?: string;

      shop_id?: number;

      data?: {};

      response?: {};

      status?: string;

      created_at?: string;

      started_at?: string;

      finished_at?: string;
    }>;
  }> {
    let path = '/scheduled_tasks';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['filterShopIds'] !== 'undefined') {
      queryParameters['filter_shop_ids'] = parameters['filterShopIds'];
    }

    queryParameters['filter_shop_ids'] = this.transformParameter(
      queryParameters['filter_shop_ids'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Create search log
   * @method
   * @name API#createSearchLog
   */
  createSearchLog(
    parameters: {} = {},
    body: {
      type?: string;

      search_query?: string;
    },
  ): Promise<{
    success?: boolean;
  }> {
    let path = '/logs/search_logs';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Load search phrases
   * @method
   * @name API#loadSearchPhrases
   */
  loadSearchPhrases(
    parameters: {
      firstCharacter?: string;
      sortType?: string;
      sortOrder?: string;
      languageId?: number;
      limit?: number;
      offset?: number;
    } = {},
  ): Promise<{
    total?: number;

    items?: Array<{
      phrase?: string;
    }>;
  }> {
    let path = '/logs/search_logs';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['firstCharacter'] !== 'undefined') {
      queryParameters['first_character'] = parameters['firstCharacter'];
    }

    queryParameters['first_character'] = this.transformParameter(
      queryParameters['first_character'],
    );

    if (typeof parameters['sortType'] !== 'undefined') {
      queryParameters['sort_type'] = parameters['sortType'];
    }

    queryParameters['sort_type'] = this.transformParameter(
      queryParameters['sort_type'],
    );

    if (typeof parameters['sortOrder'] !== 'undefined') {
      queryParameters['sort_order'] = parameters['sortOrder'];
    }

    queryParameters['sort_order'] = this.transformParameter(
      queryParameters['sort_order'],
    );

    if (typeof parameters['languageId'] !== 'undefined') {
      queryParameters['language_id'] = parameters['languageId'];
    }

    queryParameters['language_id'] = this.transformParameter(
      queryParameters['language_id'],
    );

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#openapiJson
   */
  openapiJson(parameters: {} = {}): Promise<{}> {
    let path = '/openapi.json';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new API();

