import { Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { availableTagsSelector, loadTags, selectedTagsSelector, updateWidgetTags} from "./slice";

const WidgetTagsSettings = (props) => {
    
    const dispatch = useDispatch();
    const { widgetId } = props;

    React.useEffect(() => {
       dispatch(loadTags(widgetId))
    },[widgetId,dispatch]);

    const availableTags = useSelector((state: ApplicationState) => 
        availableTagsSelector(state)
    );

    const selectedTags = useSelector((state: ApplicationState) => 
        selectedTagsSelector(state)
    );
    
    const onTagsChange = (value) => {
        dispatch(updateWidgetTags(widgetId,value));
    }

    return (
        <> 
            <Select mode="tags" value={selectedTags} style={{ width: '100%' }} placeholder="Tagy" onChange={onTagsChange}>
               { availableTags.map(availableTag => {
                  return <Select.Option value={availableTag} key={availableTag}>{availableTag}</Select.Option>
               })}
            </Select>
        </>
    )
}

export default WidgetTagsSettings;