import { DatePicker, PageHeader, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/sk_SK';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ApplicationState } from '../../store';
import { generalPublishersStatsSelector, loadGeneralPublishersStats,generalPublishersStatsFethingSelector, setFilter, startDateActiveFiltersSelector, endDateActiveFiltersSelector } from './slice';
import moment from 'moment';
import { Link } from 'react-router-dom';

const StatsSection = styled.div`
   margin-top: 20px;
`

const PublisherLink = styled(Link)`
   cursor: pointer;
`

const AffilStatsPublishers = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        async function init() {
          await dispatch(loadGeneralPublishersStats());
        }
        init();
    }, [dispatch]);

    const onDateRangeChange = (value) => {
        if(value){
            dispatch(setFilter('startDate',value[0]));
            dispatch(setFilter('endDate',value[1]));
        }
        else{
            dispatch(setFilter('startDate',null));
            dispatch(setFilter('endDate',null));
        }
        dispatch(loadGeneralPublishersStats());
    }

    const generalPublishersStats = useSelector((state: ApplicationState) =>
        generalPublishersStatsSelector(state)
    );

    const generalPublishersStatsFetching = useSelector((state: ApplicationState) =>
        generalPublishersStatsFethingSelector(state)
    );

    const startDate = useSelector((state: ApplicationState) =>
        startDateActiveFiltersSelector(state)
    );

    const endDate = useSelector((state: ApplicationState) =>
        endDateActiveFiltersSelector(state) 
    );

    const buildStatsLink = (affiliate_id:number) => {
        const params = {
            affiliate_id: affiliate_id,
            start_date: startDate?startDate:undefined,
            end_date: endDate?endDate:undefined
        }

        var queryString = Object.keys(params).filter(key => params[key]).map(key => key + '=' + params[key]).join('&');
        return `/affil/stats?${queryString}`;
    }

    return (
        <>  
           <PageHeader
                    title={`Štatistiky publisherov`}
                    ghost={false}
                    extra={[
                    <DatePicker.RangePicker
                        locale={locale}
                        ranges={{
                            'Dnes': [moment(), moment()],
                            'Včera': [moment().subtract(1, 'days'),moment().subtract(1, 'days')],
                            'Tento mesiac': [ moment().startOf('month'),moment().endOf('month')  ], 
                            'Minulý mesiac': [ moment().subtract(1,'months').startOf('month'),moment().subtract(1,'months').endOf('month')]
                            //'Posledných 7 dní': [moment().subtract(7, "days"), moment()],
                        }}
                        onChange={onDateRangeChange}
                        format={"DD.MM.YYYY"}
                    />
                    
                ]} />
                
           <StatsSection>
                <Table 
                    loading={generalPublishersStatsFetching}
                    columns={[ 
                        {
                            title: ('Publisher'),
                            dataIndex: 'affiliate_name',
                            sorter: (a, b) => a.affiliate_name.localeCompare(b.affiliate_name),
                            render: (item, record) => {
                                return  <PublisherLink to={buildStatsLink(record.affiliate_id)}> {item} </PublisherLink>
                            }
                        },
                        {
                            title: ('Widgety'),
                            dataIndex: 'count',
                            sorter: (a, b) => b.count - a.count,
                        },
                        {
                            title:('Impresie'),
                            dataIndex: 'impressions',
                            sorter: (a, b) => b.impressions - a.impressions,
                        },
                        {
                            title:('Kliky'),
                            dataIndex: 'clicks',
                            sorter: (a, b) => b.clicks - a.clicks,
                        },
                        {
                            title: ('CTR'),
                            dataIndex: 'ctr',
                            sorter: (a, b) => b.ctr - a.ctr,
                        },
                        {
                            title: ('Obrat'),
                            dataIndex: 'payment',
                            sorter: (a, b) => b.payment - a.payment,
                        }
                    ]}
                    dataSource={generalPublishersStats}
                    >
                </Table>
           </StatsSection>
        </>
    )

}

export default AffilStatsPublishers;