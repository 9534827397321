import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Table, Popconfirm, Form,  Input, Button
} from 'antd';
import styled from 'styled-components';
const EditableContext = React.createContext(null);

const UniqueEditableTable = (props) => {

    const { columns, dataSource, onCreate, onUpdate, onDelete, defaultItem, loading, trans = {}, ebabledAddRowButton, rowSelection, showHeader, onRow } = props;

    const [editingKey, setEditingKey] = useState('');
    const [newRows, setNewRows] = useState([]);
    const [fullData, setFullData] = useState([]);

    React.useEffect(() => {
        setFullData(dataSource.concat(newRows));
    }, [dataSource, newRows])

    const isEditing = record => record.key === editingKey;
    const [form] = Form.useForm();

    const RowButton = styled(Button)`
        margin-right: 5px;
    `

    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        rules,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            });
        };

        const setEdit = () => {
            setEditing(true);
            form.setFieldsValue({
                [dataIndex]: record[dataIndex],
            });
        }

        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({ ...record, ...values },dataIndex);
            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        let childNode = children;

        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={dataIndex}
                    rules={rules}
                >
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                </Form.Item>
            ) : (
                    <div
                        className="editable-cell-value-wrap"
                        style={{
                        }}
                    >
                        {children}
                    </div>
                );
        }

        return <td {...restProps}  onClick={editable?setEdit:null}>{childNode}</td>;
    };

    const onSave = async (record,dataIndex) => {
        try {
            const index = dataSource.findIndex(item => record.key === item.key);
            if (index > -1) {
                onUpdate(record,dataIndex);
                setEditingKey('');
            } else {
                const row = await form.validateFields();
                onCreate(row);

                setNewRows(
                    newRows.filter(newRow => newRow.key !== record.key)
                );

                setEditingKey('');
            }
        }
        catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const onCancel = (record) => {
        const selectedNewRow = newRows.find((nr) => nr.key === record.key);
        if (selectedNewRow) {
            setNewRows(
                newRows.filter(newRow => newRow.key !== selectedNewRow.key)
            )
        }

        setEditingKey('');
    };

    const onEdit = record => {
        form.setFieldsValue({
            name: '',
            age: '',
            address: '',
            ...record,
        });

        setEditingKey(record.key);
    };

    const onDeleteRow = record => {
        const selectedNewRow = newRows.find((nr) => nr.key === record.key);
        if (selectedNewRow) {
            setNewRows(
                newRows.filter(newRow => newRow.key !== selectedNewRow.key)
            )
        }
        else {
            onDelete(record)
        }
    }

    const handleAdd = () => {
        let minRowId = 0;
        for (const newRow of newRows) {
            if (newRow.key < minRowId) {
                minRowId = newRow.key;
            }
        }
        minRowId -= 1;

        const newDefaultItem = { ...defaultItem, key: minRowId }
        const newNewRows = [...newRows, newDefaultItem];
        setNewRows(newNewRows);
        setEditingKey(newDefaultItem.key);
    };

    const mergedColumns = columns.map(col => {
        if (col.operations && col.operations.length) {
            col = {
                ...col,
                render: (_, record) => {
                    const editable = isEditing(record);
                    return editable ? (
                        <span>
                            <RowButton
                                onClick={() => onSave(record.key,null)}
                            >
                                {trans.saveText ? trans.saveText : "Save"}
                            </RowButton>
                            <Popconfirm okText={trans.okPopConfirmText} cancelText={trans.cancelPopConfirmText} title={trans.cancelConfirmText ? trans.cancelConfirmText : "Sure to cancel?"} onConfirm={() => onCancel(record)}>
                                <RowButton> {trans.cancelText ? trans.cancelText : "Cancel"} </RowButton>
                            </Popconfirm>

                        </span>
                    ) : (
                            <>
                                <RowButton onClick={() => onEdit(record)}>
                                    {trans.editText ? trans.editText : "Edit"}
                                </RowButton>
                                <Popconfirm okText={trans.okPopConfirmText} cancelText={trans.cancelPopConfirmText} title={trans.deleteConfirmText ? trans.deleteConfirmText : "Sure to Delete?"} onConfirm={() => onDeleteRow(record)}>
                                    <RowButton> {trans.deleteText ? trans.deleteText : "Delete"} </RowButton>
                                </Popconfirm>
                            </>
                        );
                },
            }
        }

        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                rules: col.rules,
                handleSave: onSave,
            }),
        };
    });

    return <Form form={form} component={false}>
        {ebabledAddRowButton !== false ?
            <Button
                onClick={handleAdd}
                type="primary"
                style={{
                    marginBottom: 16,
                }}
            >
                {trans.addRowText ? trans.addRowText : "Add a row"}
            </Button>
            : null}

        <Table
            components={{
                body: {
                    row: EditableRow,
                    cell: EditableCell,
                },
            }}
            bordered
            columns={mergedColumns}
            dataSource={fullData}
            pagination={false}
            showHeader={showHeader}
            loading={loading}
            rowSelection={rowSelection}
            onRow={onRow}
        />
    </Form>
}

export default UniqueEditableTable;