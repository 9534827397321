import React from 'react';
import {
    Select,
    Spin
} from 'antd';

const { Option } = Select;

const ConnectedMultiSelect = (props) => {
    const { selectWidth } = props;

    return (
      <Select
        value={props.value}
        mode="multiple"
        labelInValue
        placeholder={props.name}
        notFoundContent={props.isFetching ? <Spin size="small" /> : null}
        filterOption={false}
        onChange={props.onSelect}
        onSearch={props.onChange}
        style={{ width: selectWidth }}
        disabled={ props.disabled }
      >
          {props.data.map(d => (
            <Option value={d.value} key={d.value + d.name}>
                {d.name}
            </Option>
          ))}
      </Select>
    );

}

export default ConnectedMultiSelect;
