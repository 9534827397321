import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  Col,
  PageHeader,
  Row,
  Table,
  DatePicker,
  Select,
  Card,
  Tag,
} from 'antd';
import { Line } from '@ant-design/charts';
import locale from 'antd/es/date-picker/locale/sk_SK';
import moment from 'moment';
import ConnectedMultiSelect from '../../components/Select/ConnectedMultiSelect';
import { ApplicationState } from '../../store';
import { __ } from '../../utilities';
import {
  loadCompanyFilterData,
  companyActiveFiltersSelector,
  companyFiltersDataSelector,
  companyFiltersDataIsFetching,
  startDateActiveFiltersSelector,
  endDateActiveFiltersSelector,
  loadShopFilterData,
  loadShops,
  paginationSelector,
  setFilter,
  setPagination,
  setSorter,
  shopActiveFiltersSelector,
  shopFiltersDataIsFetching,
  shopFiltersDataSelector,
  shopsIsFethingSelector,
  shopsSelector,
  totalSelector,
  loadGeneralStats,
  generalStatsSelector,
  generalStatsFethingSelector,
  generalDailyStatsSelector,
  loadGeneralDailyStats,
  sourceActiveFiltersSelector,
} from './slice';
import { Link } from 'react-router-dom';

const MainContent = styled.div`
  margin-top: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`;

const StatsSection = styled.div`
  margin-top: 20px;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

const CustomCard = styled(Card)`
  height: 100%;
`;
const ClickableDiv = styled.div`
  cursor: pointer;
`;

const ShopList = () => {
  const dispatch = useDispatch();

  const [cpcGraphActiveNumber, setCpcGraphActiveNumber] = useState(1);
  const shops = useSelector((state: ApplicationState) => shopsSelector(state));

  const history = useHistory();

  React.useEffect(() => {
    async function init() {
      if (history.action !== 'PUSH') {
        await dispatch(loadShops());
        await dispatch(loadGeneralStats());
        await dispatch(loadGeneralDailyStats());
      }
    }
    init();
  }, [dispatch, history.action]);
  //TODO pridat do db zoznam krajín
  const countryData = [
    { name: 'SK', value: 1 },
    { name: 'CZ', value: 2 },
  ];

  const sources = [
    { name: 'SEARCH', value: 5 },
    { name: 'PRODUCT', value: 8 },
    { name: 'SHOP', value: 11 },
    { name: 'BANNER', value: 143 },
    { name: 'WIDGET', value: 149 },
  ];

  const isFetching = useSelector((state: ApplicationState) =>
    shopsIsFethingSelector(state)
  );

  const total = useSelector((state: ApplicationState) => totalSelector(state));

  const pagination = useSelector((state: ApplicationState) =>
    paginationSelector(state)
  );
  const loadData = () => {
    dispatch(loadShops());
    dispatch(loadGeneralStats());
    dispatch(loadGeneralDailyStats());
  };

  const onChangeTable = async (pagination, filters, sorters) => {
    dispatch(setPagination(pagination.current, pagination.pageSize));
    dispatch(setSorter(sorters.field, sorters.order));
    const filterVal: Array<number> = Object.values(filters);
   
    if (filterVal[0]) {
      dispatch(setFilter('program_id', filterVal[0]));
    } else {
      dispatch(setFilter('program_id', null));
    }
    if (filterVal[1]) {
      dispatch(setFilter('has_backlink', Number(filterVal[1])));
    } else {
      dispatch(setFilter('has_backlink', null));
    }
    loadData();
  };

  const isFetchingShopData = useSelector((state: ApplicationState) =>
    shopFiltersDataIsFetching(state)
  );

  const shopActiveFilters = useSelector((state: ApplicationState) =>
    shopActiveFiltersSelector(state)
  );

  const shopData = useSelector((state: ApplicationState) =>
    shopFiltersDataSelector(state)
  );
  const onSelectShopFilter = async value => {
    dispatch(setFilter('shop', value));
    loadData();
  };

  const onChangeShopFilter = async value => {
    await dispatch(loadShopFilterData(value));
  };
  const onDateRangeChange = value => {
    if (value) {
      dispatch(setFilter('startDate', value[0]));
      dispatch(setFilter('endDate', value[1]));
    } else {
      dispatch(setFilter('startDate', null));
      dispatch(setFilter('endDate', null));
    }
    loadData();
  };

  const startDate = useSelector((state: ApplicationState) =>
    startDateActiveFiltersSelector(state)
  );

  const endDate = useSelector((state: ApplicationState) =>
    endDateActiveFiltersSelector(state)
  );

  const isFetchingCompanyData = useSelector((state: ApplicationState) =>
    companyFiltersDataIsFetching(state)
  );
  const companyActiveFilters = useSelector((state: ApplicationState) =>
    companyActiveFiltersSelector(state)
  );
  const companyData = useSelector((state: ApplicationState) =>
    companyFiltersDataSelector(state)
  );
  const onChangeCompanyFilter = async value => {
    await dispatch(loadCompanyFilterData(value));
  };
  const onSelectCompanyFilter = async value => {
    dispatch(setFilter('company', value));
    loadData();
  };
  const onSelectCountryFilter = async value => {
    dispatch(setFilter('country', value));
    loadData();
  };
  const generalStatsFething = useSelector((state: ApplicationState) =>
    generalStatsFethingSelector(state)
  );
  const generalDailyStats = useSelector((state: ApplicationState) =>
    generalDailyStatsSelector(state)
  );
  const generalStats = useSelector((state: ApplicationState) =>
    generalStatsSelector(state)
  );
  const onSourceChange = async value => {
    dispatch(setFilter('source', value));
    loadData();
  };
  const selectedSource = useSelector((state: ApplicationState) =>
    sourceActiveFiltersSelector(state)
  );
  return (
    <>
      <PageHeader
        title={'Obchody'}
        ghost={false}
        extra={[
          <Select
            key={'Krajina'}
            showSearch
            style={{ width: 100 }}
            placeholder="Krajina"
            optionFilterProp="children"
            onChange={onSelectCountryFilter}
          >
            <Select.Option key={'X'} value={0}>
              Všetky
            </Select.Option>
            {countryData.map(country => {
              return (
                <Select.Option value={country.value} key={country.value}>
                  {country.name}
                </Select.Option>
              );
            })}
          </Select>,

          <ConnectedMultiSelect
            key={'Firma'}
            name={__('Firma')}
            isFetching={isFetchingCompanyData}
            value={companyActiveFilters}
            data={companyData}
            selectWidth={180}
            onChange={onChangeCompanyFilter}
            onSelect={onSelectCompanyFilter}
          />,

          <ConnectedMultiSelect
            key={'Eshop'}
            name={__('Eshop')}
            isFetching={isFetchingShopData}
            value={shopActiveFilters}
            data={shopData}
            selectWidth={200}
            onChange={onChangeShopFilter}
            onSelect={onSelectShopFilter}
          />,
          <Select
            key={'Zdroj'}
            showSearch
            style={{ width: 100 }}
            placeholder="Zdroj"
            optionFilterProp="children"
            onChange={onSourceChange}
            value={selectedSource}
          >
            <Select.Option value={null}>Zdroj</Select.Option>
            {sources &&
              sources.map(source => {
                return (
                  <Select.Option value={source.value} key={source.value}>
                    {source.name}
                  </Select.Option>
                );
              })}
          </Select>,
          <DatePicker.RangePicker
            key={'Date'}
            locale={locale}
            ranges={{
              Dnes: [moment(), moment()],
              Včera: [
                moment().subtract(1, 'days'),
                moment().subtract(1, 'days'),
              ],
              'Tento mesiac': [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
              'Minulý mesiac': [
                moment()
                  .subtract(1, 'months')
                  .startOf('month'),
                moment()
                  .subtract(1, 'months')
                  .endOf('month'),
              ],
              //'Posledných 7 dní': [moment().subtract(7, "days"), moment()],
            }}
            value={[startDate, endDate]}
            onChange={onDateRangeChange}
            format={'DD.MM.YYYY'}
          />,
        ]}
      >
        {startDate || endDate ? (
          <>
            Za obdobie {startDate ? 'od ' + startDate.format('DD.MM.YYYY') : ''}{' '}
            {endDate ? 'do ' + endDate.format('DD.MM.YYYY') : ''}
          </>
        ) : null}
      </PageHeader>
      <StatsSection>
        <Row justify="start" gutter={[16, 16]}>
          <Col span={12}>
            <CustomCard
              title="CTR"
              bordered={false}
              loading={generalStatsFething}
            >
              <Card.Grid style={{ width: '50%', height: '148px' }}>
                <CardGrid>
                  {generalStats ? (
                    <>
                      <div>
                        <div>Celkové CTR:</div>
                        {/*
                                                <div>CTR widgetov:</div>
                                                */}
                      </div>
                      <div>
                        <div>
                          {' '}
                          {generalStats.impressions
                            ? (
                                (generalStats.clicks /
                                  generalStats.impressions) *
                                100
                              ).toFixed(2)
                            : 0}{' '}
                          %{' '}
                        </div>
                        {/*
                                                <div> {generalStats.impressions?((generalStats.clicks/generalStats.impressions)*100).toFixed(2):0} % </div>
                                                */}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </CardGrid>
              </Card.Grid>
              <Card.Grid style={{ width: '50%' }}>
                <Line
                  {...{
                    data: generalDailyStats
                      ? generalDailyStats.map(generalDailyStat => {
                          return {
                            date: generalDailyStat.date,
                            value: generalDailyStat.impressions
                              ? parseFloat(
                                  (
                                    (generalDailyStat.clicks /
                                      generalDailyStat.impressions) *
                                    100
                                  ).toFixed(2)
                                )
                              : 0,
                          };
                        })
                      : [],
                    height: 100,
                    autoFit: true,
                    xField: 'date',
                    yField: 'value',
                    smooth: true,
                    padding: 'auto',
                    point: {
                      size: 3,
                      shape: 'circle',
                    },
                    xAxis: {
                      type: 'timeCat',
                      tickCount: 5,
                      label: {
                        formatter: function formatter(v) {
                          return moment(v).format('DD.MM.YYYY');
                        },
                      },
                    },
                    yAxis: {
                      label: {
                        formatter: function formatter(v) {
                          return v + ' %';
                        },
                      },
                    },
                    tooltip: {
                      formatter: (value: any) => {
                        return { name: 'CTR', value: value.value + '%' };
                      },
                    },
                  }}
                />
              </Card.Grid>
            </CustomCard>
          </Col>
          <Col span={12}>
            <CustomCard
              title="Obrat"
              bordered={false}
              loading={generalStatsFething}
            >
              <Card.Grid style={{ width: '50%', height: '148px' }}>
                <CardGrid>
                  {generalStats ? (
                    <>
                      <div>
                        <ClickableDiv
                          onClick={() => {
                            setCpcGraphActiveNumber(1);
                          }}
                        >
                          Celková hodnota preklikov:
                        </ClickableDiv>
                        <ClickableDiv
                          onClick={() => {
                            setCpcGraphActiveNumber(2);
                          }}
                        >
                          CPC:
                        </ClickableDiv>
                      </div>
                      <div>
                        <div>{generalStats.payment.toFixed(2)} €</div>
                        <div>
                          {generalStats.clicks
                            ? parseFloat(
                                (
                                  generalStats.payment / generalStats.clicks
                                ).toFixed(2)
                              )
                            : 0}{' '}
                          €
                        </div>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </CardGrid>
              </Card.Grid>
              <Card.Grid style={{ width: '50%' }}>
                {cpcGraphActiveNumber === 1 ? (
                  <Line
                    {...{
                      data: generalDailyStats
                        ? generalDailyStats.map(generalDailyStat => {
                            return {
                              date: generalDailyStat.date,
                              value: generalDailyStat.payment
                                ? parseFloat(
                                    generalDailyStat.payment.toFixed(2)
                                  )
                                : 0,
                            };
                          })
                        : [],
                      height: 100,
                      autoFit: true,
                      xField: 'date',
                      yField: 'value',
                      smooth: true,
                      padding: 'auto',
                      point: {
                        size: 3,
                        shape: 'circle',
                      },
                      xAxis: {
                        type: 'timeCat',
                        tickCount: 5,
                        label: {
                          formatter: function formatter(v) {
                            return moment(v).format('DD.MM.YYYY');
                          },
                        },
                      },
                      yAxis: {
                        label: {
                          formatter: function formatter(v) {
                            return v + ' €';
                          },
                        },
                      },
                      tooltip: {
                        formatter: (value: any) => {
                          return {
                            name: 'Hodnota preklikov',
                            value: value.value + '€',
                          };
                        },
                      },
                      color: '#3CB371',
                    }}
                  />
                ) : null}

                {cpcGraphActiveNumber === 2 ? (
                  <Line
                    {...{
                      data: generalDailyStats
                        ? generalDailyStats.map(generalDailyStat => {
                            return {
                              date: generalDailyStat.date,
                              value: generalDailyStat.clicks
                                ? parseFloat(
                                    (
                                      generalDailyStat.payment /
                                      generalDailyStat.clicks
                                    ).toFixed(2)
                                  )
                                : 0,
                            };
                          })
                        : [],
                      height: 100,
                      autoFit: true,
                      xField: 'date',
                      yField: 'value',
                      smooth: true,
                      padding: 'auto',
                      point: {
                        size: 3,
                        shape: 'circle',
                      },
                      xAxis: {
                        type: 'timeCat',
                        tickCount: 5,
                        label: {
                          formatter: function formatter(v) {
                            return moment(v).format('DD.MM.YYYY');
                          },
                        },
                      },
                      yAxis: {
                        label: {
                          formatter: function formatter(v) {
                            return v + ' €';
                          },
                        },
                      },
                      tooltip: {
                        formatter: (value: any) => {
                          return { name: 'CPC', value: value.value + '€' };
                        },
                      },
                      color: '#3CB371',
                    }}
                  />
                ) : null}
              </Card.Grid>
            </CustomCard>
          </Col>
        </Row>

        <Row justify="start" gutter={[16, 16]}>
          <Col span={12}>
            <CustomCard
              title="Impresie"
              bordered={false}
              loading={generalStatsFething}
            >
              <Card.Grid style={{ width: '50%', height: '148px' }}>
                <CardGrid>
                  {generalStats ? (
                    <>
                      <div>
                        <div>Celkový počet impresií:</div>
                        {/* 
                                                <div>Impresie widgetov:</div>
                                                */}
                      </div>
                      <div>
                        <div>
                          {generalStats.impressions.toLocaleString('sk-SK')}
                        </div>
                        {/* 
                                                <div>{generalStats.impressions}</div>
                                                */}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </CardGrid>
              </Card.Grid>
              <Card.Grid style={{ width: '50%' }}>
                <Line
                  {...{
                    data: generalDailyStats
                      ? generalDailyStats.map(generalDailyStat => {
                          return {
                            date: generalDailyStat.date,
                            value: Number(generalDailyStat.impressions),
                          };
                        })
                      : [],
                    height: 100,
                    autoFit: true,
                    xField: 'date',
                    yField: 'value',
                    smooth: true,
                    padding: 'auto',
                    point: {
                      size: 3,
                      shape: 'circle',
                    },
                    xAxis: {
                      type: 'timeCat',
                      tickCount: 5,
                      label: {
                        formatter: function formatter(v) {
                          return moment(v).format('DD.MM.YYYY');
                        },
                      },
                    },
                    tooltip: {
                      formatter: (value: any) => {
                        return { name: 'Impresie', value: value.value };
                      },
                    },
                    color: '#000000',
                  }}
                />
              </Card.Grid>
            </CustomCard>
          </Col>
          <Col span={12}>
            <CustomCard
              title="Prekliky"
              bordered={false}
              loading={generalStatsFething}
            >
              <Card.Grid style={{ width: '50%', height: '148px' }}>
                <CardGrid>
                  {generalStats ? (
                    <>
                      <div>
                        <div>Celkový počet preklikov:</div>
                        {/* 
                                                <div>Prekliky z widgetov:</div>
                                                */}
                      </div>
                      <div>
                        <div>{generalStats.clicks.toLocaleString('sk-SK')}</div>
                        {/* 
                                                <div>{generalStats.clicks}</div>
                                                */}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </CardGrid>
              </Card.Grid>

              <Card.Grid style={{ width: '50%' }}>
                <Line
                  {...{
                    data: generalDailyStats
                      ? generalDailyStats.map(generalDailyStat => {
                          return {
                            date: generalDailyStat.date,
                            value: Number(generalDailyStat.clicks),
                          };
                        })
                      : [],
                    height: 100,
                    autoFit: true,
                    xField: 'date',
                    yField: 'value',
                    smooth: true,
                    padding: 'auto',
                    point: {
                      size: 3,
                      shape: 'circle',
                    },
                    xAxis: {
                      type: 'timeCat',
                      tickCount: 5,
                      label: {
                        formatter: function formatter(v) {
                          return moment(v).format('DD.MM.YYYY');
                        },
                      },
                    },
                    tooltip: {
                      formatter: (value: any) => {
                        return { name: 'Prekliky', value: value.value };
                      },
                    },
                    color: '#f6921e',
                  }}
                />
              </Card.Grid>
            </CustomCard>
          </Col>
        </Row>
      </StatsSection>

      <MainContent>
        <Table
          loading={isFetching}
          columns={[
            {
              title: __('Nazov'),
              dataIndex: 'name',
              sorter: true,
              render: (name, data) => {
                return (
                  <Link
                    className="ant-dropdown-link"
                    to={`/shop/detail/${data.shop_id}`}
                  >
                    {data.name}
                  </Link>
                );
              },
            },
            {
              title: __('Firma'),
              dataIndex: ['company'],
              sorter: true,
            },
            {
              title: __('Krajina'),
              dataIndex: 'country_id',
              sorter: true,
              render: (type, record) => {
                if (type === 1) {
                  return 'SK';
                } else if (type === 2) {
                  return 'CZ';
                }
              },
            },
            {
              title: __('Program'),
              dataIndex: 'program_id',
              sorter: true,
              filters: [
                {
                  text: <Tag color="green">Partner</Tag>,
                  value: 1,
                },
                {
                  text: <Tag color="blue">Promo</Tag>,
                  value: 2,
                },
                {
                  text: <Tag color="gold">Premium</Tag>,
                  value: 3,
                },
              ],
              render: type => {
                if (type === 1) {
                  return <Tag color="green">Partner</Tag>;
                } else if (type === 2) {
                  return <Tag color="blue">Promo</Tag>;
                } else if (type === 3) {
                  return <Tag color="gold">Premium</Tag>;
                }
              },
            },
            {
              title: __('Status'),
              dataIndex: 'state',
              sorter: true,
              render: (state, record) => {
                if (state === 'inactive') {
                  return <Tag color="default">Neaktívny</Tag>;
                } else if (state === 'active') {
                  return <Tag color="success">Aktívny</Tag>;
                }
              },
            },
            {
              title: __('Backlink'),
              dataIndex: 'has_backlink',
              sorter: true,
              render: (state, record) => {
                if (!state) {
                  return <Tag color="default">Nie</Tag>;
                } else {
                  return <Tag color="success">Áno</Tag>;
                }
              },
              filters: [
                {
                  text: <Tag color="default">Nie</Tag>,
                  value: 1,
                },
                {
                  text: <Tag color="success">Ano</Tag>,
                  value: 2,
                },
              ],
            },
            {
              title: __('Impresie'),
              dataIndex: 'impressions',
              sorter: true,
              render: state => {
                return state.toLocaleString('sk-SK');
              },
            },
            {
              title: __('Prekliky'),
              dataIndex: 'clicks',
              sorter: true,
              render: state => {
                return state.toLocaleString('sk-SK');
              },
            },
            {
              title: __('CPC'),
              dataIndex: 'cpc',
              render: state => {
                return `${state} €`;
              },
            },
            {
              title: __('Spend'),
              dataIndex: 'payment',
              sorter: true,
              render: (state, record) => {
                return `${state.toFixed(2)} €`;
              },
            },
            {
              title: __('Počet produktov'),
              dataIndex: 'products_count_active',
              sorter: true,
            },
            {
              title: __('Kredit'),
              dataIndex: 'credit_final',
              sorter: false,
            },
          ]}
          dataSource={shops}
          pagination={{
            total: total,
            current: pagination.current,
            pageSize: pagination.pageSize,
          }}
          onChange={(pagination, filters, sorters) => {
            onChangeTable(pagination, filters, sorters);
          }}
        />
      </MainContent>
    </>
  );
};

export default ShopList;
