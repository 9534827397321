import React, { useState } from 'react';
import {
  Table, Row, Col, Dropdown, Menu, Button
} from 'antd';
import { __ } from '../../utilities';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import styled from 'styled-components';
import {
  loadShopFilterData,
  shopFiltersDataIsFetching,
  shopActiveFiltersSelector,
  shopFiltersDataSelector,
  setFilter,
  manufacturerFiltersDataIsFetching,
  manufacturerActiveFiltersSelector,
  manufacturerFiltersDataSelector,
  loadManufacturerFilterData,
  categoryFiltersDataIsFetching,
  categoryActiveFiltersSelector,
  categoryFiltersDataSelector,
  loadCategoryFilterData,
  matchTypeActiveFiltersSelector,
  filterResultsIsFetchingSelector,
  searchPmProducts,
  filterResultsItemsSelector,
  shopTypeActiveFiltersSelector
} from './slice';
import ConnectedSingleSelect from '../../components/Select/ConnectedSingleSelect';

const TableRow = styled(Row)`
  margin-bottom: 15px; 
`

const FitlerRow = styled(Row)`
  margin-bottom: 15px; 
`;

const EmptyDiv: any = styled.div`
  position: fixed;
  top: ${(props: any) => props.top + "px"} ;
  left: ${(props: any) => props.left + "px"};
`
const ProductCreateBulk = () => {

  const dispatch = useDispatch();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [dropDownTop, setDropdownTop] = useState(0);
  const [dropDownLeft, setDropdownLeft] = useState(0);
  const [dropdowClickRecord, setDropdownClickRecord] = useState(-1);

  const [currentDataSource] = useState([]);
  const [selectedRows] = useState([]);
  const [childrenSelectedRows] = useState([]); 
  //const [lastClickedIndex, setLastClickedIndex] = useState(-1);

  const onDropdownMenuClick = (item) => {
    let currentSelectedRows = selectedRows.filter(item => item).filter(item => currentDataSource.map(item => item.key).includes(item.key));
    if (!currentSelectedRows.length) {
      currentSelectedRows = [dropdowClickRecord];
    }

    const currentSelectedRowsKeys = currentSelectedRows.filter(item => item).map(item => item.key);
    const compareIds = [];
    for(const currentSelectedRowKey of currentSelectedRowsKeys){
      if(childrenSelectedRows[currentSelectedRowKey] && childrenSelectedRows[currentSelectedRowKey].length){
        for(const childrenSelectedRow of childrenSelectedRows[currentSelectedRowKey]){
          compareIds.push(childrenSelectedRow.key);
        }  
      }
    }

    if (item.key === 'create_product') {
      if(currentSelectedRows && currentSelectedRows.length){
          const currentSelectedRow = currentSelectedRows[0];
          const feedIds = currentSelectedRow.pm_products.map(item => {
              return item.feed_id;
          });

          window.open(window.location.origin+"/product/create?force_ids="+feedIds.join(','), "_blank");
      }
      
      //dispatch(pairAutomaticPairingItems(compareIds));
    }
    /*
    else if (item.key === 'reject') {
      const r = window.confirm(__('Naozaj chcete zamietnuť párovanie?'));
      if (r === true) {
        dispatch(rejectAutomaticPairingItems(currentSelectedRowsKeys));
      }
    }
    */

    return true;
  }

  const dropDownmenu = (
    <Menu onClick={(item) => { onDropdownMenuClick(item) }}>
      <Menu.Item key="create_product">Vytvoriť produkt</Menu.Item>
    </Menu>
  );

  const onChangeShopFilter = async value => {
    await dispatch(loadShopFilterData(value));
  };

  const isFetchingShopData = useSelector((state: ApplicationState) =>
    shopFiltersDataIsFetching(state)
  );

  const shopActiveFilters = useSelector((state: ApplicationState) =>
    shopActiveFiltersSelector(state)
  );

  const shopData = useSelector((state: ApplicationState) =>
    shopFiltersDataSelector(state)
  );

  const onSelectShopFilter = async value => {
    dispatch(setFilter('shopSelected', value));
    //dispatch(loadAutomaticPairingProducts());
  };

  const isFetchingManufacturerData = useSelector((state: ApplicationState) =>
    manufacturerFiltersDataIsFetching(state)
  );

  const manufacturerActiveFilters = useSelector((state: ApplicationState) =>
    manufacturerActiveFiltersSelector(state)
  );

  const manufacturerData = useSelector((state: ApplicationState) =>
    manufacturerFiltersDataSelector(state)
  );

  const onChangeManufacturerFilter = async value => {
    await dispatch(loadManufacturerFilterData(value));
  };

  const onSelectManufacturerFilter = async value => {
    dispatch(setFilter('manufacturerSelected', value));
    //dispatch(loadAutomaticPairingProducts());
  };

  const isFetchingCategoryData = useSelector((state: ApplicationState) =>
    categoryFiltersDataIsFetching(state)
  );

  const categoryActiveFilters = useSelector((state: ApplicationState) =>
    categoryActiveFiltersSelector(state)
  );

  const categoryData = useSelector((state: ApplicationState) =>
    categoryFiltersDataSelector(state)
  );

  const onChangeCategoryFilter = async value => {
    await dispatch(loadCategoryFilterData(value));
  };

  const onSelectCategoryFilter = async value => {
    dispatch(setFilter('categorySelected', value));
    //dispatch(loadAutomaticPairingProducts());
  };

  const onRow = (record, index) => ({
    onContextMenu: event => {
      event.preventDefault();
      setDropdownTop(event.clientY);
      setDropdownLeft(event.clientX);
      setDropdownVisible(true);
      setDropdownClickRecord(record);
      return true;
    }
  });

  const onSelectMatchTypeFilter = async value => {
    dispatch(setFilter('matchTypeSelected', value));
    //setLastClickedIndex(-1);
    //dispatch(loadAutomaticPairingProducts());
  };

  const onSelectShopTypeFilter = async value => {
    dispatch(setFilter('shopTypeSelected', value));
  }

  const matchTypeActiveFilters = useSelector((state: ApplicationState) =>
    matchTypeActiveFiltersSelector(state)
  );

  const shopTypeActiveFilters = useSelector((state: ApplicationState) =>
    shopTypeActiveFiltersSelector(state)
  );

  const isFetching = useSelector((state: ApplicationState) =>
    filterResultsIsFetchingSelector(state)
  );

  const filterResults = useSelector((state: ApplicationState) =>
    filterResultsItemsSelector(state)
  );
  
  /*
  const onChangeShowPartners = async value => {
    dispatch(setFilter('showPartners', value.target.checked));
    //setLastClickedIndex(-1);
    dispatch(loadAutomaticPairingProducts());
  };
  */
  /*
  const handleModalReject = () => {
    const item = automaticPairingItems[currentIndex];
    dispatch(rejectAutomaticPairingItems([item.key]));

    if (automaticPairingItems.length > currentIndex + 1) {
      setCurrentIndex(currentIndex);
      //setLastClickedIndex(currentIndex);
      setModalFromRecord(automaticPairingItems[currentIndex + 1]);
    } else {
      setCurrentIndex(-1);
      setModalVisible(false);
    }
  };

  const handleModalPair = () => {
    const item = automaticPairingItems[currentIndex];
    dispatch(pairAutomaticPairingItems([item.key]));

    if (automaticPairingItems.length > currentIndex + 1) {
      setCurrentIndex(currentIndex);
      //setLastClickedIndex(currentIndex);
      setModalFromRecord(automaticPairingItems[currentIndex + 1]); 
    } else {
      setCurrentIndex(-1);
      setModalVisible(false);
    }
  };
  */

  const onSearchButton = () => {
      dispatch(searchPmProducts());
      console.log("ON SEARCH BUTTON");
  }

  return (
    <>
      <TableRow>
        <Col className="gutter-row" span={24}>
          <FitlerRow gutter={{ lg: 5 }}>
            <Col>
              <ConnectedSingleSelect
                name={__('Shop')}
                isFetching={isFetchingShopData}
                value={shopActiveFilters}
                data={shopData}
                selectWidth={200}
                onChange={onChangeShopFilter}
                onSelect={onSelectShopFilter}
              />
            </Col>

            <Col>
              <ConnectedSingleSelect
                name={__('Výrobca')}
                isFetching={isFetchingManufacturerData}
                value={manufacturerActiveFilters}
                data={manufacturerData}
                selectWidth={200}
                onChange={onChangeManufacturerFilter}
                onSelect={onSelectManufacturerFilter}
              />
            </Col>

            <Col>
              <ConnectedSingleSelect
                name={__('Kategória')}
                isFetching={isFetchingCategoryData}
                value={categoryActiveFilters}
                data={categoryData}
                selectWidth={500}
                onChange={onChangeCategoryFilter}
                onSelect={onSelectCategoryFilter}
              />
            </Col>

            <Col>
              <ConnectedSingleSelect
                name={__('Typ zhody')}
                value={matchTypeActiveFilters}
                data={[{ name: "EAN", value: "EAN" }/*, { name: "PN", value: "PN" }*/]}
                selectWidth={200}
                onSelect={onSelectMatchTypeFilter}
              />
            </Col>

            <Col>
              <ConnectedSingleSelect
                name={__('Typ obhodu')}
                value={shopTypeActiveFilters}
                data={[{ name: "Všetky obchody", value: "all_shops" },{ name:"Iba platené obchody", value:'only_paid' }]}
                selectWidth={200}
                onSelect={onSelectShopTypeFilter}
              />
            </Col>

            <Col>
              <Button 
                className="primary"
                disabled={((shopActiveFilters && shopActiveFilters.length!==0) || 
                          (manufacturerActiveFilters && manufacturerActiveFilters.length!==0) ||
                          (categoryActiveFilters && categoryActiveFilters.length!==0)) &&
                          matchTypeActiveFilters && matchTypeActiveFilters.length!==0
                          ?false: true
                          }
                onClick={onSearchButton}
              >
                {__('Vyhľadať')}
              </Button>
            </Col>

          </FitlerRow>
        </Col>
      </TableRow>
      <Table 
         loading = {isFetching}
         columns = {[
            {
                title: __('Názov'),
                dataIndex: 'name'
            },
            {
                title: __('Ean'),
                dataIndex: 'ean'
            },
            {
                title: __('PN'),
                dataIndex: 'part_number'
            },
            {
                title: __('Počet'),
                dataIndex: 'count',
                sorter: (a, b) => a.count - b.count,
            },
         ]}
         dataSource={filterResults}
         onRow={onRow}
      />

      <Dropdown overlay={dropDownmenu}
        visible={isDropdownVisible}
        onVisibleChange={(visible) => { setDropdownVisible(visible) }}
      >
        <EmptyDiv top={dropDownTop} left={dropDownLeft} />
      </Dropdown>
    </>
  )
}

export default ProductCreateBulk;