import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import H1 from "../../components/Headings/H1";
import {__} from "../../utilities";
import {Alert, Button, Col, Modal, Popover, Row, Spin, Table} from "antd";
import { useHistory, useLocation} from "react-router-dom";
import { DownloadOutlined, PlusSquareOutlined} from "@ant-design/icons";
import {
  filterResultsIsFetchingSelector,
  filterResultsProductsSelector,
  setFilter,
  loadTop50Products,
  loadCategoryFilterData,
  categoryActiveFiltersSelector,
  categoryFiltersDataSelector,
  categoryFiltersDataIsFetching,
  exportTop50Products
} from "../ProductSearch/slice";
import {ApplicationState} from "../../store";
import {chooseProduct,  productSelector, exportActiveSelector} from "./slice";
import ConnectedMultiSelect from "../../components/Select/ConnectedMultiSelect";
import {rem} from "polished";
import styled from "styled-components";
import WidgetTypeSelector from "../WidgetCreate/WidgetTypeSelector";
import {resetState} from "../WidgetCreate/slice";

const FitlerRow = styled(Row)`
  margin-bottom: 15px; 
`;

const ExportButton = styled(Button)`
  float:right;
  margin-top: ${rem(20)}
`

const CustomAlert = styled(Alert)`
  margin-top:${rem(15)}
`

const Top50 = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  React.useEffect(() => {
    async function init() {
      const params = new URLSearchParams(location.search);
      let categoryId = params.get('category_id');
      dispatch(loadTop50Products(categoryId));
      dispatch(resetState())
    }
    init();
  }, [dispatch, location]);

  const widgetRequest = (product) => {
    let path = `widgets/create/`
    history.push(path);
  }
  const selectWidth = rem(175);


  const columns = [
    {
      title: 'Obrázok',
      dataIndex: 'imageUrl',
      render: imageUrl => (
        <Popover content={<img
          alt=""
          style={{ height: '200px '}}
          src={imageUrl}
        />    }>
        <div
          style={{width: '100%', height: '100%',display:"flex", justifyContent:"center",alignItems:"center"}}
        >
        <img
          alt="example"
          style={{ height: '55px' }}
          src={imageUrl}
        />
        </div>
        </Popover>
      ),
    },
    {
      title: __('Názov Produktu'),
      dataIndex: 'name',
      sorter: false,
    },
    {
      title: __('Kategória'),
      dataIndex: 'category_name',
      sorter: false,
    },
    {
      title: __('TOP'),
      dataIndex: 'rank',
      sorter: false,
    },
    {
      title: __('CPC'),
      dataIndex: 'cpc',
      sorter: false,
    },
    {
      title: __(''),
      dataIndex: 'product_id',
      render: (activeState, record) => {
        
        if(record.show_widget_button){
          return (
            <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
              <Button
                type="primary"
                icon={<PlusSquareOutlined />}
                size={'small'}
                onClick={() => showWidgetTypeModal(record)}
              >
                Vytvoriť widget
              </Button>
            </div>
          );
        } else {
          return <></>
        }

      },
    }
  ];


  const isFetchingProducts = useSelector((state: ApplicationState) =>
    filterResultsIsFetchingSelector(state)
  );
  const products = useSelector((state: ApplicationState) =>
    filterResultsProductsSelector(state)
  );
  
  /*
  const onChangeTable = async (pagination, filters, sorters) => {
    dispatch(setPagination(pagination.current));
    dispatch(setSorter(sorters.field, sorters.order));
  };
  */

  const onChangeCategoryFilter = async value => {
    await dispatch(loadCategoryFilterData(value));
  };


  const onSelectCategoryFilter = async value => {
    dispatch(setFilter('categorySelected', value));
    dispatch(loadTop50Products());
  };

  const showWidgetTypeModal = (record)  => {
    dispatch(chooseProduct(record))
    setIsModalVisible(true);
  }

  const categoryActiveFilters = useSelector((state: ApplicationState) =>
    categoryActiveFiltersSelector(state)
  );

  const categoryData = useSelector((state: ApplicationState) =>
    categoryFiltersDataSelector(state)
  );

  const isFetchingCategoryData = useSelector((state: ApplicationState) =>
    categoryFiltersDataIsFetching(state)
  );
  const product = useSelector((state:ApplicationState) => productSelector(state))

  const onExportButtonClick = async value => {
    dispatch(exportTop50Products(window,document));
  }

  const exportActive = useSelector((state: ApplicationState) =>
     exportActiveSelector(state)
  );

  return (<>
    <H1>{__('TOP 50 produktov')}</H1>
    <Spin spinning={exportActive}>
      <Col className="gutter-row" span={24}>
        <FitlerRow gutter={{ lg: 10 }}>
          <Col>Filtre:</Col>
          <Col>
            <ConnectedMultiSelect
              name={__('Kategória')}
              isFetching={isFetchingCategoryData}
              value={categoryActiveFilters}
              data={categoryData}
              selectWidth={selectWidth}
              onChange={onChangeCategoryFilter}
              onSelect={onSelectCategoryFilter}
            />
          </Col>

        </FitlerRow>
      </Col>

      <Table
        columns={columns}
        loading={isFetchingProducts}
        dataSource={products}
        pagination={false}
      />
    
      <ExportButton disabled={!products || (products.length === 0)} type="primary" icon={<DownloadOutlined />} onClick={onExportButtonClick}>
            Exportovať
      </ExportButton>

      <CustomAlert 
        message=""
        description="Výška CPC je priemerná hodnota za uplynulých 30 dní. Finálna výška CPC závisí od aktuálnych ponúk eshopov a nastavenej výšky koeficientov pre váš program jednotlivými eshopmi."
        type="info"
      />
      
      <Modal title="Typ widgetu" visible={isModalVisible}  footer={[]} onCancel={handleCancel}>
        <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center"}}>
          <WidgetTypeSelector></WidgetTypeSelector>
          <div style={{display:"flex",justifyContent:"center",width:'100%'}}>
            <Button
            style={{marginTop:7}}
            type="primary"
            icon={<PlusSquareOutlined />}
            size={'small'}
            onClick={() => widgetRequest(product)}
          >
            Vytvoriť widget
          </Button>
        </div>
        </div>
      </Modal>
    </Spin>
  </>)
}

export default Top50;