import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import { setModalVisibility, WordReplacementType, mainManufacturerActiveFiltersSelector, wordPhraseReplacementModalSelector, createPhraseReplacement, deletePhraseReplacement, updatePhraseReplacement, wordPhraseReplacementSelector } from './slice';
import {
    Modal, Checkbox
  } from 'antd';
import EditableTable from './EditableTable';

const PhraseWordReplacementModal = () => {

    const dispatch = useDispatch();

    const wordPhraseReplacementModal = useSelector((state: ApplicationState) =>
      wordPhraseReplacementModalSelector(state)
    );

    const handlePhraseReplacementModalCancel = () => {
        dispatch(setModalVisibility("wordPhraseReplacement",false));
    };  
  
    const handlePhraseReplacementModalSave = () => {
        dispatch(setModalVisibility("wordPhraseReplacement",false));
    };

    const wordPhraseReplacement = useSelector((state: ApplicationState) =>
        wordPhraseReplacementSelector(state)
    );

    const onCreateReplacement = (item) => {
        dispatch(createPhraseReplacement(item));
    }

    const onDeleteReplacement = (item) => {
        dispatch(deletePhraseReplacement(item.replacementId));
    }

    const mainManufacturerActiveFilters = useSelector((state: ApplicationState) =>
      mainManufacturerActiveFiltersSelector(state)
    );

    const onUpdateReplacement = (item) => {
        dispatch(updatePhraseReplacement(item));
    } 

    return <Modal
        visible={wordPhraseReplacementModal.isVisible}
        onCancel={handlePhraseReplacementModalCancel}
        onOk={handlePhraseReplacementModalSave}
        title="Zámena slova"
        width = {1000}
    >   
        <EditableTable 
            onCreate = {onCreateReplacement}
            onUpdate = {onUpdateReplacement}
            onDelete = {onDeleteReplacement}
            loading  = {wordPhraseReplacement.isFetching}
            showHeader = {false}
            trans = {{
                addRowText : "Pridať zámenu",
                editText : "Editovať",
                deleteText : "Zmazať",
                saveText: "Uložiť",
                cancelText: "Zrušiť",
                cancelConfirmText: "Si si isty?",
                deleteConfirmText: "Si si isty?",
                okPopConfirmText: "OK",
                cancelPopConfirmText: "Zrušiť"
            }}
            defaultItem = {  
                { replacementId: -1, original:"TEST3", replacement:"TEST4", state:null, type: WordReplacementType.SINGLE_WORD }
            }
            columns = {[
                    {
                        title: "replacementWord",
                        dataIndex: 'replacementWord',
                        key: 'replacementWord',
                        inputType: 'text',
                        width: '33%',
                        editable: true,
                        rules:[
                            {
                                required: true,
                                message: `Pole je povinné`,
                            }
                        ] 
                    },
                    {
                        title: "withManufacturer",
                        dataIndex: 'withManufacturer',
                        key: 'withManufacturer',
                        inputType: 'checkbox',
                        width: '33%',
                        editable: true,
                        valuePropName: "checked",
                        render: manufacturerId => (
                            manufacturerId?(
                            <p>Iba {mainManufacturerActiveFilters?mainManufacturerActiveFilters.name:""}</p>
                            ):(
                            <p> Všetky </p>
                            )
                        ),
                        renderEdit: (manufacturerId) => (
                            <Checkbox> Iba {mainManufacturerActiveFilters?mainManufacturerActiveFilters.name:""} </Checkbox>
                        )
                    },
                    {
                        title: 'operations',
                        dataIndex: 'operations',
                        width: '33%',
                        operations:[
                            'new','edit','delete'
                        ]  
                    },
                ]}
            
            dataSource = { wordPhraseReplacement.data } 
        /> 
    </Modal> 
}

export default PhraseWordReplacementModal;