/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class API
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class API {
  protected baseUrl: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        let param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }

        let value = [
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];

        if (Array.isArray(parameters[p])) {
          param = parameters[p] as Array<string>;
          value = param.map(x => {
            return `${encodeURIComponent(p)}=${encodeURIComponent(String(x))}`;
          });
        }

        return [...acc, ...value];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      return response.json();
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   * Prihlasenie pouzivatela
   * @method
   * @name API#oauthAuthorize
   */
  oauthAuthorize(parameters: {
    clientId: string;
    clientSecret?: string;
    redirectUri: string;
    responseType?: string;
    scope: string;
  }): Promise<{}> {
    let path = '/oauth/authorize';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');

    if (typeof parameters['clientId'] !== 'undefined') {
      queryParameters['client_id'] = parameters['clientId'];
    }

    queryParameters['client_id'] = this.transformParameter(
      queryParameters['client_id'],
    );

    if (typeof parameters['clientId'] === 'undefined') {
      throw new Error('Missing required parameter: clientId');
    }

    if (typeof parameters['clientSecret'] !== 'undefined') {
      queryParameters['client_secret'] = parameters['clientSecret'];
    }

    queryParameters['client_secret'] = this.transformParameter(
      queryParameters['client_secret'],
    );

    if (typeof parameters['redirectUri'] !== 'undefined') {
      queryParameters['redirect_uri'] = parameters['redirectUri'];
    }

    queryParameters['redirect_uri'] = this.transformParameter(
      queryParameters['redirect_uri'],
    );

    if (typeof parameters['redirectUri'] === 'undefined') {
      throw new Error('Missing required parameter: redirectUri');
    }

    queryParameters['response_type'] = 'token';

    if (typeof parameters['responseType'] !== 'undefined') {
      queryParameters['response_type'] = parameters['responseType'];
    }

    queryParameters['response_type'] = this.transformParameter(
      queryParameters['response_type'],
    );

    if (typeof parameters['scope'] !== 'undefined') {
      queryParameters['scope'] = parameters['scope'];
    }

    queryParameters['scope'] = this.transformParameter(
      queryParameters['scope'],
    );

    if (typeof parameters['scope'] === 'undefined') {
      throw new Error('Missing required parameter: scope');
    }

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get info token
   * @method
   * @name API#tokenInfo
   */
  tokenInfo(parameters: {
    accessToken: string;
    appSpace: string;
  }): Promise<{
    name?: string;

    surename?: string;

    token_info?: string;

    type?: {};
  }> {
    let path = '/oauth/token-info';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['accessToken'] !== 'undefined') {
      queryParameters['access_token'] = parameters['accessToken'];
    }

    queryParameters['access_token'] = this.transformParameter(
      queryParameters['access_token'],
    );

    if (typeof parameters['accessToken'] === 'undefined') {
      throw new Error('Missing required parameter: accessToken');
    }

    if (typeof parameters['appSpace'] !== 'undefined') {
      queryParameters['appSpace'] = parameters['appSpace'];
    }

    queryParameters['appSpace'] = this.transformParameter(
      queryParameters['appSpace'],
    );

    if (typeof parameters['appSpace'] === 'undefined') {
      throw new Error('Missing required parameter: appSpace');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Token Change
   * @method
   * @name API#changeCode
   */
  changeCode(
    parameters: {} = {},
    body: {
      code?: string;
    },
  ): Promise<void> {
    let path = '/oauth/change-code';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }
  /**
   * Impersonate for user
   * @method
   * @name API#impersonate
   */
  impersonate(parameters: { user: string; redirectUri: string }): Promise<{}> {
    let path = '/oauth/impersonate';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    if (typeof parameters['user'] !== 'undefined') {
      queryParameters['user'] = parameters['user'];
    }

    queryParameters['user'] = this.transformParameter(queryParameters['user']);

    if (typeof parameters['user'] === 'undefined') {
      throw new Error('Missing required parameter: user');
    }

    if (typeof parameters['redirectUri'] !== 'undefined') {
      queryParameters['redirect_uri'] = parameters['redirectUri'];
    }

    queryParameters['redirect_uri'] = this.transformParameter(
      queryParameters['redirect_uri'],
    );

    if (typeof parameters['redirectUri'] === 'undefined') {
      throw new Error('Missing required parameter: redirectUri');
    }

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Shows actual memory usage of API process
   * @method
   * @name API#heap
   */
  heap(parameters: {} = {}): Promise<{}> {
    let path = '/status/heap';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
* Loads status of API. If everything is OK shows status: green.
This link is used in whole system of monitoring API (called by Elastic Heartbeat)
Actually checks:
* MongoDB connection
* Elastic connection
* Redis connection

* @method
* @name API#status
*/
  status(parameters: {} = {}): Promise<{}> {
    let path = '/status';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   *
   * @method
   * @name API#openapiJson
   */
  openapiJson(parameters: {} = {}): Promise<{}> {
    let path = '/openapi.json';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new API();

