import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, ApplicationState } from "../../store";
import API from "../../services/API";
import { FormInstance } from "antd/lib/form";
import { arrayMove } from "react-sortable-hoc";
import { message } from 'antd';

const NAMESPACE = 'parameterDetail';

interface TranslatedName {
    language_id: number,
    name: string
}

export interface LocaleParameterValue { 
    _id: string,
    internal_name: string,
    name: string;
    index: number;
}

interface ParameterValue {
    _id: string,
    internal_name: string,
    translated_names: TranslatedName[],
    position?:number 
}

interface ParameterTranslation {
    language_id: number,
    name: string,
    description: string,
    unit: string
}

interface Parameter {
    _id: string,
    internal_name: string,
    type: string,
    values: ParameterValue[],
    translations: ParameterTranslation[]
}

interface ParameterDetailState {
    parameter: Parameter,
    locale_id: number
}

const initialState : ParameterDetailState = {
    parameter: null,
    locale_id: 1
}

export const parameterDetailSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setParameter: (state, action: PayloadAction<Parameter>) => {
            state.parameter = action.payload
        },
        setLocaleId: (state, action: PayloadAction<number>) => {
            state.locale_id = action.payload
        },
        updateParameterValue: (state, action: PayloadAction<{_id:string,dataIndex:string,value:any }>) => {
            const parameterValues = state.parameter.values;
            const value = parameterValues.find(parameterValue => parameterValue._id === action.payload._id);
            value[action.payload.dataIndex] = action.payload.value;
            state.parameter.values = parameterValues;
        },
        updateTranslatedParameterValue: (state, action: PayloadAction<{_id:string,dataIndex:string,languageId:number,languageDataIndex:string,value:any }>) => {
            const parameterValues = state.parameter.values;
            const value = parameterValues.find(parameterValue => parameterValue._id === action.payload._id);
            const translatedValues = value[action.payload.languageDataIndex];
            const translatedValue = translatedValues.find(tv => tv.language_id === action.payload.languageId);
            translatedValue[action.payload.dataIndex] = action.payload.value;
            state.parameter.values = parameterValues;
        },
        createParameterValue: (state, action: PayloadAction<{parameterValue:ParameterValue}>) => {
            const parameterValues = state.parameter.values;
            parameterValues.push(action.payload.parameterValue);
            state.parameter.values = parameterValues;
        },
        updateParameterValuesPositions: (state, action: PayloadAction<{oldIndex:number,newIndex:number}>) => {
            const parameterValues = state.parameter.values;
            const newData = arrayMove([].concat(parameterValues), action.payload.oldIndex, action.payload.newIndex).filter(el => !!el);
            state.parameter.values = newData;
        },
        updateParameterField:(state,action: PayloadAction<{dataIndex:string,value:any }>) => {
            const parameter = state.parameter;
            parameter[action.payload.dataIndex] = action.payload.value;
            state.parameter = parameter;
        },
        updateParameterTranslatedField: (state,action: PayloadAction<{dataIndex:string,value:any,languageId:number}>) => {
            const parameter = state.parameter;
            const translation = parameter.translations.find(t=>t.language_id === action.payload.languageId);
            translation[action.payload.dataIndex] = action.payload.value;
            state.parameter = parameter;
        },
        setCorrectValuesPositions: (state) => {
            const parameter = state.parameter;
            let position = 1;
            for(const value of parameter.values){
                value.position = position;
                position++
            }
            state.parameter = parameter;
        },
        removeParameterValue:(state,action: PayloadAction<{_id:string}>) => {
            const parameter = state.parameter;
            parameter.values = parameter.values.filter(v => v._id !== action.payload._id);
            state.parameter = parameter;
        },
        /*
        setIsFetchingFilterResults: (state, action: PayloadAction<{value: boolean}>) => {
            state.filterResults.isFetching = action.payload.value
        },
        setFilterResults: (state, action: PayloadAction<{total: number, feedReports: Array<ListingFeedReport>}>) => {
            state.filterResults.total       = action.payload.total;
            state.filterResults.feedReports = action.payload.feedReports;
        },
        setPagination: (state, action: PayloadAction<{current: number}>) => {
            state.pagination.current = action.payload.current
        },
        setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },
        setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
        setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | number | null}>) => {
            state.activeFilters[action.payload.name] = action.payload.value;
        },
        */
    }
});

export const loadParameter = (parameterId:string,form:FormInstance) : AppThunk => async (dispatch, getState) => {
    API.loadParameter(parameterId).then(response => {
        const parameter:Parameter = {
            _id: response._id,
            internal_name: response.internal_name,
            type: response.type,
            values: response.values.sort((a, b) => (a.position > b.position) ? 1 : -1).map(value => {
                return {
                    _id: value._id,
                    internal_name: value.internal_name,
                    translated_names: value.translated_names.map(translatedName => {
                        return {
                            language_id: translatedName.language_id,
                            name: translatedName.name
                        }
                    })
                }
            }),
            translations: response.translations.map(translation => {
                return {
                    language_id: translation.language_id,
                    name: translation.name,
                    description: translation.description,
                    unit: translation.unit
                }
            })
        }

        for(const localeId of [1,2]){
            const translation = parameter.translations.find(t => t.language_id === localeId);
            if(!translation){
                parameter.translations.push({
                    language_id: localeId,
                    name: "",
                    description: "",
                    unit: ""
                })
            }
        }



        dispatch(parameterDetailSlice.actions.setParameter(parameter));
        dispatch(setFormValuesFromParameter(form));
    });
}

const setFormValuesFromParameter = (form:FormInstance) : AppThunk => async (dispatch, getState) => {
    const parameter = parameterSelector(getState());
    const localeId = localeIdSelector(getState());

    const fieldValues = {};
    fieldValues["internal_name"] = parameter.internal_name;
    fieldValues["type"] = parameter.type;

    const translation = parameter.translations.find(t => t.language_id === localeId);
    fieldValues["unit"] = translation.unit;
    fieldValues["description"] = translation.description;
    fieldValues["name"] = translation.name;

    for(const value of parameter.values){
        fieldValues["value_"+value._id+"_internal_name"] = value.internal_name
    }

    form.setFieldsValue(fieldValues)
}

export const setLocaleId = (localeId:number,form:FormInstance): AppThunk => async (dispatch, getState) => {
    dispatch(parameterDetailSlice.actions.setLocaleId(localeId));
    dispatch(setFormValuesFromParameter(form));
}

export const updateParameterValue = (dataIndex:string,data:LocaleParameterValue) : AppThunk => async (dispatch, getState) => {
    const localeId = localeIdSelector(getState());
    const value = data[dataIndex];
    if(dataIndex === 'name'){
        dispatch(parameterDetailSlice.actions.updateTranslatedParameterValue({_id:data._id,dataIndex:'name',value,languageDataIndex:'translated_names',languageId:localeId}));
    } else{
        dispatch(parameterDetailSlice.actions.updateParameterValue({_id:data._id,dataIndex,value}));
    }
}

export const updateParameterField = (dataIndex:string,value:string) : AppThunk => async (dispatch, getState) => {
    const localeId = localeIdSelector(getState());

    if(["unit","description","name"].includes(dataIndex)){
        dispatch(parameterDetailSlice.actions.updateParameterTranslatedField({dataIndex,value,languageId:localeId}));
    }
    else{
        dispatch(parameterDetailSlice.actions.updateParameterField({dataIndex,value}));
    }
}

export const createParameterValue = (data:LocaleParameterValue): AppThunk => async (dispatch, getState) => {
    const parameter = parameterSelector(getState());
    let maxPosition:number = -1;
    for(const value of parameter.values){
        if(value.position && value.position > maxPosition){
            maxPosition = value.position
        }
    }

    let nextPosition:number = undefined;
    if(maxPosition > 0){
        nextPosition = maxPosition++;
    }

    const parameterValue:ParameterValue = {
        _id: data._id,
        internal_name: data.internal_name,
        position: nextPosition,
        translated_names:[
            {
                language_id: 1,
                name: data.name
            },
            {
                language_id: 2,
                name: data.name
            }
        ]
    }

    dispatch(parameterDetailSlice.actions.createParameterValue({parameterValue:parameterValue}));
}

export const createLocaleParameterValues = (parameterValues:ParameterValue[], localeId:number):LocaleParameterValue[] => {
    let index = 0;
    const localeParameterValues:LocaleParameterValue[] = parameterValues.map(parameterValue => {
        const translatedName = parameterValue.translated_names.find(tn => tn.language_id === localeId);
        index ++;
        return {
           _id: parameterValue._id,
           internal_name: parameterValue.internal_name,
           name: translatedName?translatedName.name:"",
           index: index -1
        }
    });

    return localeParameterValues;
}

export const updateParameterValuesPositions = (oldIndex:number,newIndex:number) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterDetailSlice.actions.updateParameterValuesPositions({oldIndex,newIndex}))
}

export const updateParameter = (form) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterDetailSlice.actions.setCorrectValuesPositions());
    const parameter = parameterSelector(getState());

    API.updateParameter(parameter._id,{},parameter).then(response => {
        message.success("Parameter bol uložený");
        dispatch(loadParameter(parameter._id,form));
    });
}

export const removeParameterValue = (_id:string) : AppThunk => async (dispatch, getState) => {
    dispatch(parameterDetailSlice.actions.removeParameterValue({_id}));
}

export const parameterSelector = (state: ApplicationState) => state.parameterDetail.parameter;
export const localeIdSelector = (state: ApplicationState) => state.parameterDetail.locale_id;

export default parameterDetailSlice.reducer;







