import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApplicationState, AppThunk} from "../../store";
import API from "../../services/API";

const NAMESPACE = 'productCreateBulk';

interface PmEntityInterface {
    feed_id: number,
    name: string, 
    ean: string,
    part_number: string,
    shop_name: string,
    count: number,
    pm_products:{
        feed_id: number,
        name: string
        ean: string,
        part_number: string,
        shop_name: string
    }[]
}

interface AutomaticPairingListState {
    activeFilters: {
        shopSelected: {value: number}[],
        manufacturerSelected: {value: number}[],
        categorySelected: { value: number}[],
        matchTypeSelected: { value: string}[],
        shopTypeSelected: string,
        query: string,
        showPartners: boolean
    },
    filtersData: {
        shop: {
            isFetching: boolean,
            data: any
        },
        manufacturer: {
            isFetching: boolean,
            data: any
        },
        category: {
            isFetching: boolean,
            data: any
        }
    },
    filterResults: {
        offset: number
        isFetching: boolean,
        total: number;
        items: PmEntityInterface[];
    },
    sorterData: {
        type: string,
        order: string;
    }
}

const initialState : AutomaticPairingListState = {
    activeFilters: {
        shopSelected: [],
        manufacturerSelected: [],
        categorySelected: [],
        matchTypeSelected: [],
        shopTypeSelected: "all_shops",
        query: "",
        showPartners: false
    },
    filtersData: {
        shop: {
            isFetching: false,
            data: []
        },
        manufacturer: {
            isFetching: false,
            data: []
        },
        category: {
            isFetching: false,
            data: []
        }
    },
    filterResults: {
        isFetching: false,
        total: 0,
        offset: 0,
        items: []
    },
    sorterData: {
        type: null,
        order: 'asc'
    },
};

export const productCreateBulkSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },
        setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
        setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | number | null}>) => {
            state.activeFilters[action.payload.name] = action.payload.value;
        },
        setIsFetchingFilterResults: (state, action: PayloadAction<{value: boolean}>) => {
            state.filterResults.isFetching = action.payload.value
        },
        setFilterResults: (state, action: PayloadAction<Array<PmEntityInterface>>) => {
            state.filterResults.items = action.payload
        },
    }
});

export const loadShopFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(productCreateBulkSlice.actions.setIsFetchingFilterData({name: 'shop', value: true}));

    API.loadShops({query:value,limit:100}).then(response => {
      dispatch(productCreateBulkSlice.actions.setFilterData({name: 'shop', value: 
      response.shops.map(shop => {
          return {
            name: shop.name+` (${shop.program_id})`,
            value: shop.shop_id
          }
        })
      }));
      
      dispatch(productCreateBulkSlice.actions.setIsFetchingFilterData({name: 'shop', value: false}));
    })  
}

export const loadCategoryFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(productCreateBulkSlice.actions.setIsFetchingFilterData({name: 'category', value: true}));
  
    API.loadCategories({query:value,limit:100}).then(response => {
      dispatch(productCreateBulkSlice.actions.setFilterData({name: 'category', value: 
        response.categories.map( category => {
   
          let categoryName;
          if(category && category.translations && category.translations.length){
              const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
              if(categoryTranslation){
                  categoryName = categoryTranslation.name;
              }
          }
  
          return {
            name: category.parents_names ? [...category.parents_names].reverse().filter((item, index) => index % 2 === 0).join(' < ') : categoryName,
            value: category.category_id
          }
        })
      }));
      
      dispatch(productCreateBulkSlice.actions.setIsFetchingFilterData({name: 'category', value: false}));
    });
}

export const loadManufacturerFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(productCreateBulkSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: true}));
  
    API.loadManufacturers({query:value,limit:100}).then(response => {
      dispatch(productCreateBulkSlice.actions.setFilterData({name: 'manufacturer', value: 
        response.manufacturers.map( manufacturer => {
            return {
              name: manufacturer.name,
              value: manufacturer.manufacturer_id
            } 
        })  
      }));
  
      dispatch(productCreateBulkSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: false}));   
    });
}

export const setFilter = (filter: string, value: number[] | string | number | null) => (dispatch, getState) => {
    dispatch(productCreateBulkSlice.actions.setActiveFilterValue({name: filter, value}));
}

export const searchPmProducts = () => (dispatch, getState) => {
     
    const filterShopId:any = shopActiveFiltersSelector(getState());
    const filterManufacturerId:any = manufacturerActiveFiltersSelector(getState());
    const filterCategoryId:any = categoryActiveFiltersSelector(getState());
    const filterMatchType:any  = matchTypeActiveFiltersSelector(getState());
    const filterShopType:any = shopTypeActiveFiltersSelector(getState());

    dispatch(productCreateBulkSlice.actions.setIsFetchingFilterResults({value:true}));
    API.getCreateBulkSuggestion({
        filterShopId: filterShopId?filterShopId:undefined,
        filterCategoryId:filterCategoryId?filterCategoryId:undefined,
        filterManufacturerId: filterManufacturerId?filterManufacturerId:undefined,
        filterMatchType: filterMatchType?filterMatchType:undefined,
        includeChildrenCategories: true,
        shopProgramIds: filterShopType?filterShopType==='only_paid'?[2,3]:undefined:undefined
    }).then(response => {
       const filterResults:Array<PmEntityInterface> = response.items.map(item => {
          return {
              feed_id: item.feed_id,
              ean: item.ean,
              name: item.name,
              part_number: item.part_number,
              shop_name: item.shop_name,
              count: item.pm_products.length,
              pm_products: item.pm_products.map(sub_item => {
                  return {
                    feed_id: sub_item.feed_id,
                    ean: sub_item.ean,
                    name: sub_item.name,
                    part_number: sub_item.part_number,
                    shop_name: sub_item.shop_name,
                  }
              })
          }
       });
       dispatch(productCreateBulkSlice.actions.setFilterResults(filterResults));
       dispatch(productCreateBulkSlice.actions.setIsFetchingFilterResults({value:false}));
    })
}

export const shopActiveFiltersSelector = (state: ApplicationState) => state.productCreateBulk.activeFilters.shopSelected;
export const shopFiltersDataSelector = (state: ApplicationState) => state.productCreateBulk.filtersData.shop.data;
export const shopFiltersDataIsFetching = (state: ApplicationState) => state.productCreateBulk.filtersData.shop.isFetching;

export const matchTypeActiveFiltersSelector = (state: ApplicationState) => state.productCreateBulk.activeFilters.matchTypeSelected;
export const shopTypeActiveFiltersSelector = (state: ApplicationState) => state.productCreateBulk.activeFilters.shopTypeSelected;

export const queryActiveFiltersSelector = (state: ApplicationState) => state.productCreateBulk.activeFilters.query;

export const manufacturerActiveFiltersSelector = (state: ApplicationState) => state.productCreateBulk.activeFilters.manufacturerSelected;
export const manufacturerFiltersDataSelector = (state: ApplicationState) => state.productCreateBulk.filtersData.manufacturer.data;
export const manufacturerFiltersDataIsFetching = (state: ApplicationState) => state.productCreateBulk.filtersData.manufacturer.isFetching;

export const categoryActiveFiltersSelector = (state: ApplicationState) => state.productCreateBulk.activeFilters.categorySelected;
export const categoryFiltersDataSelector = (state: ApplicationState) => state.productCreateBulk.filtersData.category.data;
export const categoryFiltersDataIsFetching = (state: ApplicationState) => state.productCreateBulk.filtersData.category.isFetching;

export const filterResultsIsFetchingSelector   = (state: ApplicationState) => state.productCreateBulk.filterResults.isFetching;
export const filterResultsItemsSelector        = (state: ApplicationState) => state.productCreateBulk.filterResults.items;

export default productCreateBulkSlice.reducer;







