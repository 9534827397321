import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState, AppThunk } from '../../store';
import API from '../../services/API';
import { message } from 'antd';
import { tokenSelector } from '../App/slice';

const NAMESPACE = 'categoryDetail';
interface Category {
  _id: string;
  category_id: number;
  name: string,
  pn_pairing_enabled: boolean;
  translations: Array<{
    language_id: number,
    description: string,
    short_description: string
  }>
}
interface CategoryDetailState {
  category: Category;
  locale_id: number;
  uploadImage: {
    uploading: boolean;
  }
}

const initialState: CategoryDetailState = {
  category: {
    _id: null,
    category_id: null,
    name: null,
    pn_pairing_enabled: true,
    translations: [
      {
        language_id: 1,
        description: null,
        short_description: null
      },
      {
        language_id: 2,
        description: null,
        short_description: null
      }
    ]
  },
  uploadImage: {
    uploading: false,
  },
  locale_id: 1,
};

export const categoryDetailSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setCategory: (state, action: PayloadAction<Category>) => {
      state.category = action.payload;
    },
    setLocaleId: (state, action: PayloadAction<number>) => {
      state.locale_id = action.payload;
    },
    setUploadingImage: (
        state,
        action: PayloadAction<boolean>
      ) => {
        state.uploadImage.uploading = action.payload
    },
    changePnPairingEnabled:(
      state,
      action: PayloadAction<boolean>
    ) => {
      state.category.pn_pairing_enabled = action.payload
    },
    changeTranslatedValue: (state, action: PayloadAction<{localeId:number,dataIndex:string,value:string}>) => {
        const translation = state.category.translations.find(translation => translation.language_id === action.payload.localeId);
        if(translation){
            translation[action.payload.dataIndex] = action.payload.value;
        }
    },
  },
});

export const loadCategory = (categoryId: number): AppThunk => async (
  dispatch,
  getState
) => {
  const localeId = localeIdSelector(getState());
  API.loadCategories({ categoryIds: [categoryId] }).then(response => {
    const category = response.categories.length ? response.categories[0] : null;
    if (category) {

      const categoryName = category.translations.reduce((acc, m) => {
        if (m.language_id === localeId) {
          acc = m;
        } else {
          if (!acc.language_id) {
            acc = m;
          }
        }
        return acc;
      }, {});
      const rCategory: Category = {
        _id: category._id,
        category_id: category.category_id,
        name: categoryName.name,
        pn_pairing_enabled: category.pn_pairing_enabled,
        translations: category.translations.map(translation => {
          return {
            language_id: translation.language_id,
            description: translation.description,
            short_description: translation.short_description
          }
        })
      };
      dispatch(categoryDetailSlice.actions.setCategory(rCategory));
    }
  });
};

export const setLocaleId = (localeId: number): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(categoryDetailSlice.actions.setLocaleId(localeId));
};

export const updateCategory = () => async (dispatch, getState) => {
  const category = categorySelector(getState());
  console.log(category);
  /*
    API.updateCategoryParameters(category._id,{},{
        parameters: category.parameters.map(parameter => {
            return {
                _id: parameter._id,
                parameter_id: parameter.parameter_id,
                position: parameter.position,
                translations: parameter.translations.map(translation => {
                    return {
                        language_id: translation.language_id,
                        show: translation.show, 
                        index: translation.index,
                        expand: translation.expand
                    }
                })
            }
        })
    }).then(response => {
        console.log("UPDATE SUCESS");
    })
    */
};
export const uploadNewCategoryImage = (images) => async (
    dispatch,
    getState
  ) => {
    dispatch(categoryDetailSlice.actions.setUploadingImage(true));
    const category = categorySelector(getState());
    API.uploadCategoryLogo({categoryId: category.category_id},images).then(response => {
      if(response){
        dispatch(categoryDetailSlice.actions.setUploadingImage(false));
      }
    }).catch((e) => {
      dispatch(categoryDetailSlice.actions.setUploadingImage(false));
    })
};

export const changePnPairingEnabled = (pnPairingEnabled:boolean) => async (dispatch, getState) => {
   dispatch(categoryDetailSlice.actions.changePnPairingEnabled(pnPairingEnabled));
}

export const updateSettings = () => async (dispatch, getState) => {
  const category = categorySelector(getState());

  const token = tokenSelector(getState());
  API.setToken(token);
  
  API.updateCategorySettings(category._id,{},{
    pn_pairing_enabled: category.pn_pairing_enabled,
    translations: category.translations
  }).then(response => {
    message.success(`Nastavenia boli uložené`);
  })
}

export const changeTranslatedName = (localeId:number,dataIndex:string,value:string) :AppThunk => async (dispatch, getState) => {
  dispatch(categoryDetailSlice.actions.changeTranslatedValue({localeId,dataIndex,value}));
}

export const localeIdSelector = (state: ApplicationState) =>
  state.categoryDetail.locale_id;
export const categorySelector = (state: ApplicationState) =>
  state.categoryDetail.category;
export const uploadingImageSelector = (state: ApplicationState) =>
  state.categoryDetail.uploadImage.uploading;
export default categoryDetailSlice.reducer;
