import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApplicationState, AppThunk} from "../../store";

const NAMESPACE = 'Top50';
export interface ListingProduct {
  imageUrl: string;
  name: string;
  manufacturer_name: string;
  category_name: string;
  active_shops_count: number;
  lowest_price: number;
  productUrl: string;
  productId: number;
  ean: string;
  pn: string;
}

interface ProductSearchListState {
  activeFilters: {
    manufacturerSelected: {value: number}[],
    categorySelected: {value: number}[],
    category: number[],
    since: string,
    changeDate: string,
    lang: number,
    query: string
  },
  filtersData: {
    manufacturer: {
      isFetching: boolean,
      data: any
    },
    category: {
      isFetching: boolean,
      data: any
    }
  },
  sorterData: {
    type: string,
    order: string;
  },
  filterResults: {
    offset: number
    isFetching: boolean,
    total: number;
    products: any[];
  },
  pagination: {
    pageSize: number;
    current: number
  },
  selectedProdutIds: number[]
  widgetProduct:ListingProduct | null
  exportActive: boolean
}

const initialState : ProductSearchListState = {
  activeFilters: {
    manufacturerSelected: [],
    categorySelected: [],
    category: [],
    since: null,
    changeDate: null,
    lang: 1,
    query: null
  },
  filtersData: {
    manufacturer: {
      isFetching: false,
      data: []
    },
    category: {
      isFetching: false,
      data: []
    }
  },
  sorterData: {
    type: null,
    order: 'asc'
  },
  filterResults: {
    isFetching: false,
    total: 0,
    offset: 0,
    products: []
  },
  pagination: {
    pageSize: 50,
    current: 1
  },
  selectedProdutIds: [],
  widgetProduct: null,
  exportActive: false
}

export const topProductSearchListSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setPagination: (state, action: PayloadAction<{current: number}>) => {
      state.pagination.current = action.payload.current
    },
    setSelectedProductIds: (state, action: PayloadAction<{selectedProductIds: number[]}>) => {
      state.selectedProdutIds = action.payload.selectedProductIds
    },
    setWidgetProduct: (state,action:PayloadAction<{product:ListingProduct}>) => {
      state.widgetProduct = action.payload.product
    },
    setExportActive: (state,action:PayloadAction<boolean>) => {
      state.exportActive = action.payload
    }
  }
});

export const tokenInfoSelector = (state: ApplicationState) => state.general.tokeninfo;
export const productSelector = (state: ApplicationState) => state.topProductsSearchList.widgetProduct;
export const exportActiveSelector = (state: ApplicationState) => state.topProductsSearchList.exportActive;

export const chooseProduct = (product:ListingProduct):AppThunk => async (dispatch, getState)  => {
  // const widgetId = await API.createProductWidget({},{product_id:product_id})
  await dispatch(topProductSearchListSlice.actions.setWidgetProduct({product:product}))
}

export const setExportActive = (exportActive:boolean):AppThunk => async (dispatch, getState)  => {
  await dispatch(topProductSearchListSlice.actions.setExportActive(exportActive)) 
}

export default topProductSearchListSlice.reducer;
