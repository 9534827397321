import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, ApplicationState } from '../../store';
import API from '../../services/API';
import { getValuesFromArray } from '../../utilities';

const NAMESPACE = 'manufacturersList';

interface Manufacturer {
  name: string;
  id: number;
  key: any;
}

interface ManufacturersListState {
  activeFilters: {
    manufacturers: any;
  };
  filterResults: {
    offset: number;
    isFetching: boolean;
    total: number;
    manufacturers: Manufacturer[];
    manufacturersFilter: any;
    isFetchingFilter: boolean;
  };
  pagination: {
    pageSize: number;
    current: number;
  };
  sorterData: {
    type: string;
    order: string;
  };
}

const initialState: ManufacturersListState = {
  activeFilters: {
    manufacturers: [],
  },
  filterResults: {
    isFetching: false,
    total: 0,
    offset: 0,
    manufacturers: [],
    manufacturersFilter: [],
    isFetchingFilter: false,
  },
  pagination: {
    pageSize: 10,
    current: 1,
  },
  sorterData: {
    type: null,
    order: 'asc',
  }
};

export const manufacturersListState = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setManufacturers: (
      state,
      action: PayloadAction<{
        total: number;
        manufacturers: Array<Manufacturer>;
      }>
    ) => {
      state.filterResults.total = action.payload.total;
      state.filterResults.manufacturers = action.payload.manufacturers;
      state.filterResults.isFetching = false;
    },
    setIsFetchingManufacturersList: (state, action: PayloadAction<boolean>) => {
      state.filterResults.isFetching = action.payload;
    },
    setIsFetchingFilter: (state, action: PayloadAction<boolean>) => {
      state.filterResults.isFetchingFilter = action.payload;
    },
    setManufacturersFiltersData: (state, action: PayloadAction<any>) => {
      state.filterResults.manufacturersFilter = action.payload;
      state.filterResults.isFetchingFilter = false
    },
    setActiveFilterValue: (
      state,
      action: PayloadAction<{
        name: string;
        value: number[] | string | number | null | boolean;
      }>
    ) => {
      state.activeFilters[action.payload.name] = action.payload.value;
    },
    setPagination: (
      state,
      action: PayloadAction<{ current: number; pageSize: number }>
    ) => {
      state.pagination.current = action.payload.current;
      state.pagination.pageSize = action.payload.pageSize;
    },
    setSorterData: (
      state,
      action: PayloadAction<{ type: string; order: string }>
    ) => {
      state.sorterData.type = action.payload.type;
      state.sorterData.order = action.payload.order;
    }
  },
});

export const loadManufacturersList = (): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(manufacturersListState.actions.setIsFetchingManufacturersList(true));
  const offset =
    (getState().manufacturersList.pagination.current - 1) *
    getState().manufacturersList.pagination.pageSize;
  const manufacturers_ids = getValuesFromArray<number>(manufaturersFiltersActiveFiltersSelector(getState())) 
  API.loadManufacturersPaginated({
    offset: offset,
    limit: getState().manufacturersList.pagination.pageSize,
    manufacturerIds: manufacturers_ids ? manufacturers_ids : undefined
  }).then(response => {
    const manufacturers = response.manufacturers.map(item => {
      return {
        name: item.name,
        id: item.manufacturer_id,
        key: item._id,
      };
    });
    dispatch(
      manufacturersListState.actions.setManufacturers({
        total: response.total,
        manufacturers,
      })
    );
  });
};

export const setFilter = (
  filter: string,
  value: number[] | string | number | null | boolean
) => (dispatch, getState) => {
  dispatch(
    manufacturersListState.actions.setActiveFilterValue({ name: filter, value })
  );
};

export const setPagination = (current: number, pageSize: number) => (
  dispatch,
  getState
) => {
  dispatch(
    manufacturersListState.actions.setPagination({
      current: current,
      pageSize: pageSize,
    })
  );
};

export const setSorter = (field: string, orderName: string) => (
  dispatch,
  getState
) => {
  const type: string = orderName ? field : undefined;
  const order: string = orderName === 'descend' ? 'desc' : 'asc';

  dispatch(
    manufacturersListState.actions.setSorterData({ type: type, order: order })
  );
};
export const loadManufaturersFilterData = (value: string): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(manufacturersListState.actions.setIsFetchingFilter(true));

  API.loadManufacturers({
    query: value,
    limit: 100,
  }).then(response => {
    dispatch(
      manufacturersListState.actions.setManufacturersFiltersData(
        response.manufacturers.map(item => {
          return {
            name: item.name,
            value: item.manufacturer_id,
          };
        })
      )
    );
  });
};



export const manufacturersListIsFethingSelector = (state: ApplicationState) =>
  state.manufacturersList.filterResults.isFetching;
export const manufacturersListSelector = (state: ApplicationState) =>
  state.manufacturersList.filterResults.manufacturers;
export const totalSelector = (state: ApplicationState) =>
  state.manufacturersList.filterResults.total;
export const paginationSelector = (state: ApplicationState) =>
  state.manufacturersList.pagination;

export const manufaturersFiltersDataIsFetching = (state: ApplicationState) =>
  state.manufacturersList.filterResults.isFetchingFilter;
export const manufaturersFiltersData = (state: ApplicationState) =>
  state.manufacturersList.filterResults.manufacturersFilter;
export const manufaturersFiltersActiveFiltersSelector = (
  state: ApplicationState
) => state.manufacturersList.activeFilters.manufacturers;

export default manufacturersListState.reducer;
