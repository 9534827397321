import React from "react";
import {Button, Form, Input } from "antd";
import {useDispatch, useSelector} from "react-redux";
import {changePassword, tokenInfoSelector} from "./slice";
import {ApplicationState} from "../../store";
import H1 from "../../components/Headings/H1";
import {__} from "../../utilities";
import { useHistory } from "react-router-dom";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const tokenInfo = useSelector((state: ApplicationState) =>
    tokenInfoSelector(state)
  );

  const onFinish = values => {
    const id  = tokenInfo['_id'];
    dispatch(changePassword(id,values['password'],history));
  };

  return (
    <>
      <H1>{__('Zmena hesla')}</H1>
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={onFinish}
      >
        <Form.Item
          name="password"
          label="Nové heslo"
          rules={[
            {
              required: true,
              message: 'Vyplňte toto pole prosím!',
            },
            { min: 8, 
              message: 'Minimálna dĺžka hesla je 8 znakov' 
            },
          ]}
          hasFeedback
        >
          <Input type={"password"} />
        </Form.Item>
        <Form.Item
          name="password2"
          label="Nové heslo znova"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Vyplňte toto pole prosím!',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Heslá a nezhodujú!');
              },
            })
          ]}
          hasFeedback
        >
          <Input type={"password"} />
        </Form.Item>
        <Form.Item  wrapperCol={{ span: 14, offset: 4 }}>
          <Button type={"primary"} htmlType="submit" >Zmeniť</Button>
        </Form.Item>
      </Form>
    </>
  );
}

export default ChangePassword;