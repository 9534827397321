import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, PageHeader, Row, Col, Card, Select, Table, Input, Space, Button, Spin } from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/sk_SK';
import { loadGeneralStats, setFilter, startDateActiveFiltersSelector, endDateActiveFiltersSelector, generalStatsSelector, loadUserAffil, affilNameSelector, userRoleSelector, loadAffilList, affiliateListSelector, selectedAffiliateNameSelector, setAffiliateNameFromId, loadWidgetStats, widgetStatsSelector, widgetStatsFethingSelector, loadAvailableTags, selectedAffiliateIdSelector, setSelectedTags, tagsListSelector, categoryFiltersDataIsFetching, categoryActiveFiltersSelector, categoryFiltersDataSelector, loadCategoryFilterData, generalStatsFethingSelector, loadCategoryIds, loadGeneralDailyStats, generalDailyStatsSelector, exportWidgetStats, exportFethingSelector, setDefaultValues} from './slice';
import { ApplicationState } from '../../store';
import { DownloadOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import Paragraph from 'antd/lib/typography/Paragraph';
import ConnectedMultiSelect from '../../components/Select/ConnectedMultiSelect';
import { Line } from '@ant-design/charts';


const StatsSection = styled.div`
   margin-top: 20px;
`

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`

const CustomCard = styled(Card)`
  height: 100%;
`

const ClickableDiv = styled.div`
  cursor: pointer;
`

const ExportButtonWrapper = styled.div`
  display:flex;
  align-items: flex-end;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
`

const AffilStats = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchInput,setSearchInput] = useState(null);
    const [cpcGraphActiveNumber, setCpcGraphActiveNumber] = useState(1);

    React.useEffect(() => {
        async function init() {
          await dispatch(setDefaultValues(location.search,() => {
            dispatch(loadGeneralStats());
            dispatch(loadGeneralDailyStats());
            dispatch(loadWidgetStats());
          }));
          
          await dispatch(loadUserAffil());
          await dispatch(loadAffilList());
          await dispatch(loadCategoryIds());
        }
        init();
    }, [dispatch,location.search]);

    const selectedAffiliateId = useSelector((state: ApplicationState) =>
        selectedAffiliateIdSelector(state)
    );

    React.useEffect(() => {
        async function init() {
            await dispatch(loadAvailableTags(selectedAffiliateId));
        }
        init();
    }, [selectedAffiliateId,dispatch]);

    
    const onDateRangeChange = (value) => {
        if(value){
            dispatch(setFilter('startDate',value[0]));
            dispatch(setFilter('endDate',value[1]));
        }
        else{
            dispatch(setFilter('startDate',null));
            dispatch(setFilter('endDate',null));
        }
        dispatch(loadGeneralStats());
        dispatch(loadGeneralDailyStats());
        dispatch(loadWidgetStats());
    }

    const startDate = useSelector((state: ApplicationState) =>
        startDateActiveFiltersSelector(state)
    );

    const endDate = useSelector((state: ApplicationState) =>
        endDateActiveFiltersSelector(state)
    );

    const generalStats = useSelector((state: ApplicationState) =>
        generalStatsSelector(state)
    );

    const affilName = useSelector((state: ApplicationState) =>
        affilNameSelector(state)
    );

    const userRole:string = useSelector((state: ApplicationState) =>
        userRoleSelector(state)
    );

    const selectedAffiliateName = useSelector((state: ApplicationState) =>
        selectedAffiliateNameSelector(state)
    );

    const affiliateList = useSelector((state: ApplicationState) =>
        affiliateListSelector(state)
    );

    const widgetStats = useSelector((state: ApplicationState) =>
         widgetStatsSelector(state)
    );

    const widgetStatsFetching = useSelector((state: ApplicationState) =>
         widgetStatsFethingSelector(state)
    );

    const availableTags = useSelector((state: ApplicationState) =>
       tagsListSelector(state)
    );

    const isFetchingCategoryData = useSelector((state: ApplicationState) =>
        categoryFiltersDataIsFetching(state)
    );

    const categoryActiveFilters = useSelector((state: ApplicationState) =>
        categoryActiveFiltersSelector(state)
    );

    const categoryData = useSelector((state: ApplicationState) =>
        categoryFiltersDataSelector(state)
    );

    const generalStatsFething = useSelector((state: ApplicationState) =>
        generalStatsFethingSelector(state)
    );

    const generalDailyStats = useSelector((state: ApplicationState) =>
        generalDailyStatsSelector(state)
    );

    const exportFetching = useSelector((state: ApplicationState) =>
        exportFethingSelector(state)
    );

    /*
    const generalDailyStatsFetching = useSelector((state: ApplicationState) =>
        generalDailyStatsFethingSelector(state)
    );
    */

    const onAffilChange = (value) => {
        dispatch(setFilter('selectedAffiliateId',value));
        dispatch(setAffiliateNameFromId(value));
        dispatch(loadGeneralStats());
        dispatch(loadGeneralDailyStats());
        dispatch(loadWidgetStats());
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
       confirm();
    }

    const handleReset = (clearFilters) => {
       clearFilters();
    }

    const onTagsChange = (value) => {
        dispatch(setSelectedTags(value));
        dispatch(loadGeneralStats());
        dispatch(loadGeneralDailyStats());
        dispatch(loadWidgetStats());
    }

    const onChangeCategoryFilter = async value => {
        await dispatch(loadCategoryFilterData(value));
      };
    
    const onSelectCategoryFilter = async value => {
        dispatch(setFilter('categorySelected', value));
        dispatch(loadGeneralDailyStats());
        dispatch(loadGeneralStats());
        dispatch(loadWidgetStats());
    };

    const onExportButtonClick = async () => {
        dispatch(exportWidgetStats(window,document));
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
              <Input
                ref={node => {
                  setSearchInput(node);
                }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
              </Space>
            </div>
          ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput?searchInput.select():null, 100);
            }
        },
    })

    return (
        <>  
            <Spin spinning={exportFetching}>
                <PageHeader
                    title={userRole === 'ADMIN'?`Štatistiky ${selectedAffiliateName?selectedAffiliateName:'celkovo'} `:`Štatistiky partnera ${affilName?affilName:""}`}
                    ghost={false}
                    extra={[
                    
                    <Select mode="tags" style={{ width: 200 }} placeholder="Tagy" onChange={onTagsChange}>
                        { availableTags.data.map(availableTag => {
                            return <Select.Option value={availableTag} key={availableTag}>{availableTag}</Select.Option>
                        })}
                    </Select>,
                    <ConnectedMultiSelect
                        name={'Kategória'}
                        isFetching={isFetchingCategoryData}
                        value={categoryActiveFilters}
                        data={categoryData}
                        selectWidth={200}
                        onChange={onChangeCategoryFilter}
                        onSelect={onSelectCategoryFilter}
                    />,   
                    

                    userRole === 'ADMIN'?
                        <Select
                            showSearch
                            style={{ width: 200 }}
                            placeholder="Vybrať partnera"
                            optionFilterProp="children"
                            onChange = {onAffilChange}
                            value={selectedAffiliateId}
                        >      
                            <Select.Option value={null}>Celkovo</Select.Option>
                            {affiliateList.data && affiliateList.data.map(affiliate => {
                                return <Select.Option value={affiliate.affiliate_id}>{affiliate.name}</Select.Option>
                            })
                            }
                        </Select>  
                    : null 
                    ,
                    <DatePicker.RangePicker
                        locale={locale}
                        ranges={{
                            'Dnes': [moment(), moment()],
                            'Včera': [moment().subtract(1, 'days'),moment().subtract(1, 'days')],
                            'Tento mesiac': [ moment().startOf('month'),moment().endOf('month')  ], 
                            'Minulý mesiac': [ moment().subtract(1,'months').startOf('month'),moment().subtract(1,'months').endOf('month')]
                            //'Posledných 7 dní': [moment().subtract(7, "days"), moment()],
                        }}
                        value={[startDate,endDate]}
                        onChange={onDateRangeChange}
                        format={"DD.MM.YYYY"}
                    />
                    
                ]}
                >
                    {(startDate || endDate)?
                        <>
                            Za obdobie {startDate?"od "+startDate.format('DD.MM.YYYY'):""} { endDate?"do "+endDate.format('DD.MM.YYYY'):""}
                        </>
                        :null
                    }
                </PageHeader>
                
                {/*
                <OveralStatSetcion>
                Celková hodnota preklikov <br/>
                15.50 € <br/>
                Priemerná doba prekliku 10.58 €
                </OveralStatSetcion>
                */}
                
                <StatsSection>
                <Row justify="start" gutter={[16, 16]}> 
                    <Col span={12}>
                            <CustomCard title="CTR" bordered={false} loading={generalStatsFething} >
                                <Card.Grid style={{width:"50%",height:"148px"}}>
                                    <CardGrid>
                                        {(generalStats)?<> 
                                            <div>
                                                <div>Celkové CTR:</div>
                                                {/*
                                                <div>CTR widgetov:</div>
                                                */}
                                            </div>
                                            <div>
                                                <div> {generalStats.impressions?((generalStats.clicks/generalStats.impressions)*100).toFixed(2):0} % </div>
                                                {/*
                                                <div> {generalStats.impressions?((generalStats.clicks/generalStats.impressions)*100).toFixed(2):0} % </div>
                                                */}
                                            </div>
                                        </>:""}
                                    </CardGrid>
                                </Card.Grid>
                                <Card.Grid style={{width:"50%"}}>
                                    <Line {...{
                                            data: generalDailyStats?generalDailyStats.map(generalDailyStat => {
                                                return {
                                                date: generalDailyStat.date,
                                                value: generalDailyStat.impressions?parseFloat(((generalDailyStat.clicks/generalDailyStat.impressions)*100).toFixed(2)):0
                                                }
                                            }):[]
                                            ,
                                            height: 100,
                                            autoFit: true,
                                            xField: 'date',
                                            yField: 'value',
                                            smooth: true,
                                            padding: 'auto',
                                            point: {
                                                size: 3,
                                                shape: 'circle',
                                            },
                                            xAxis: {
                                                type: 'timeCat',
                                                tickCount: 5,
                                                label: {
                                                    formatter: function formatter(v) {
                                                        return moment(v).format('DD.MM.YYYY');
                                                    },
                                                },
                                            },
                                            yAxis: {
                                                label: {
                                                    formatter: function formatter(v) {
                                                        return v+" %";
                                                    },
                                                },
                                            },
                                            tooltip: { 
                                                formatter: (value: any) => {
                                                    return { name: "CTR", value: value.value + '%' };
                                                }
                                            },
                                        }}  />
                                </Card.Grid>
                            </CustomCard>
                    </Col>
                    <Col span={12}>
                            <CustomCard title="Obrat" bordered={false} loading={generalStatsFething}>
                                <Card.Grid style={{width:"50%",height:"148px"}}>
                                    <CardGrid>
                                        {(generalStats)?<> 
                                            <div>
                                                <ClickableDiv onClick={() => { setCpcGraphActiveNumber(1)}} >Celková hodnota preklikov:</ClickableDiv>
                                                <ClickableDiv onClick={() => { setCpcGraphActiveNumber(2)}} >CPC:</ClickableDiv>
                                            </div>
                                            <div>
                                                <div>{generalStats.payment.toFixed(2)} €</div>
                                                <div>{generalStats.clicks?parseFloat((generalStats.payment/generalStats.clicks).toFixed(2)):0} €</div>
                                            </div>
                                        </>:""}
                                    </CardGrid>
                                </Card.Grid>
                                <Card.Grid style={{width:"50%"}}>
                                    {cpcGraphActiveNumber === 1?
                                        <Line {...{
                                            data: generalDailyStats?generalDailyStats.map(generalDailyStat => {
                                                return {
                                                date: generalDailyStat.date,
                                                value: generalDailyStat.payment?parseFloat(generalDailyStat.payment.toFixed(2)):0,
                                                
                                                }
                                            }):[]
                                            ,
                                            height: 100,
                                            autoFit: true,
                                            xField: 'date',
                                            yField: 'value',
                                            smooth: true,
                                            padding: 'auto',
                                            point: {
                                                size: 3,
                                                shape: 'circle',
                                            },
                                            xAxis: {
                                                type: 'timeCat',
                                                tickCount: 5,
                                                label: {
                                                    formatter: function formatter(v) {
                                                        return moment(v).format('DD.MM.YYYY');
                                                    },
                                                },
                                            },
                                            yAxis: {
                                                label: {
                                                    formatter: function formatter(v) {
                                                        return v+ " €";
                                                    },
                                                },
                                            },
                                            tooltip: { 
                                                formatter: (value: any) => {
                                                    return { name: "Hodnota preklikov", value: value.value + '€' };
                                                }
                                            },
                                            color: '#3CB371',
                                        }}  />
                                        :null
                                    }

                                    { cpcGraphActiveNumber === 2? 
                                        <Line {...{
                                            data: generalDailyStats?generalDailyStats.map(generalDailyStat => {
                                                return {
                                                date: generalDailyStat.date,
                                                value: generalDailyStat.clicks?parseFloat((generalDailyStat.payment/generalDailyStat.clicks).toFixed(2)):0,
                                                
                                                }
                                            }):[]
                                            ,
                                            height: 100,
                                            autoFit: true,
                                            xField: 'date',
                                            yField: 'value',
                                            smooth: true,
                                            padding: 'auto',
                                            point: {
                                                size: 3,
                                                shape: 'circle',
                                            },
                                            xAxis: {
                                                type: 'timeCat',
                                                tickCount: 5,
                                                label: {
                                                    formatter: function formatter(v) {
                                                        return moment(v).format('DD.MM.YYYY');
                                                    },
                                                },
                                            },
                                            yAxis: {
                                                label: {
                                                    formatter: function formatter(v) {
                                                        return v+ " €";
                                                    },
                                                },
                                            },
                                            tooltip: { 
                                                formatter: (value: any) => {
                                                    return { name: "CPC", value: value.value + '€' };
                                                }
                                            },
                                            color: '#3CB371',
                                        }}  /> : null
                                
                                    } 

                                </Card.Grid>

                            </CustomCard>
                    </Col>
                </Row>

                <Row justify="start" gutter={[16, 16]}> 
                    <Col span={12}>
                            <CustomCard title="Impresie" bordered={false} loading={generalStatsFething}>
                                <Card.Grid style={{width:"50%",height:"148px"}}>
                                    <CardGrid>
                                        {(generalStats)?<> 
                                            <div>
                                                <div>Celkový počet impresií:</div>
                                                {/* 
                                                <div>Impresie widgetov:</div>
                                                */}
                                            </div>
                                            <div>
                                                <div>{generalStats.impressions}</div>
                                                {/* 
                                                <div>{generalStats.impressions}</div>
                                                */}
                                            </div>
                                        </>:""}
                                    </CardGrid>
                                </Card.Grid>
                                <Card.Grid style={{width:"50%"}}>
                                <Line {...{
                                        data: generalDailyStats?generalDailyStats.map(generalDailyStat => {
                                            return {
                                            date: generalDailyStat.date,
                                            value: generalDailyStat.impressions
                                            }
                                        }):[]
                                        ,
                                        height: 100,
                                        autoFit: true,
                                        xField: 'date',
                                        yField: 'value',
                                        smooth: true,
                                        padding: 'auto',
                                        point: {
                                            size: 3,
                                            shape: 'circle',
                                        },
                                        xAxis: {
                                            type: 'timeCat',
                                            tickCount: 5,
                                            label: {
                                                formatter: function formatter(v) {
                                                    return moment(v).format('DD.MM.YYYY');
                                                },
                                            },
                                        },
                                        tooltip: { 
                                            formatter: (value: any) => {
                                                return { name: "Impresie", value: value.value };
                                            }
                                        },
                                        color: '#000000',
                                    }}  />
                                </Card.Grid>
                            </CustomCard>
                    </Col>
                    <Col span={12}>
                            <CustomCard title="Prekliky" bordered={false} loading={generalStatsFething}>
                                <Card.Grid style={{width:"50%",height:"148px"}}>
                                    <CardGrid>
                                        {(generalStats)?<> 
                                            <div>
                                                <div>Celkový počet preklikov:</div>
                                                {/* 
                                                <div>Prekliky z widgetov:</div>
                                                */}
                                            </div>
                                            <div>
                                                <div>{generalStats.clicks}</div>
                                                {/* 
                                                <div>{generalStats.clicks}</div>
                                                */}
                                            </div>
                                        </>:""}
                                    </CardGrid>
                                </Card.Grid>
                                
                                <Card.Grid style={{width:"50%"}}>
                                <Line {...{
                                        data: generalDailyStats?generalDailyStats.map(generalDailyStat => {
                                            return {
                                            date: generalDailyStat.date,
                                            value: generalDailyStat.clicks
                                            }
                                        }):[]
                                        ,
                                        height: 100,
                                        autoFit: true,
                                        xField: 'date',
                                        yField: 'value',
                                        smooth: true,
                                        padding: 'auto',
                                        point: {
                                            size: 3,
                                            shape: 'circle',
                                        },
                                        xAxis: {
                                            type: 'timeCat',
                                            tickCount: 5,
                                            label: {
                                                formatter: function formatter(v) {
                                                    return moment(v).format('DD.MM.YYYY');
                                                },
                                            },
                                        },
                                        tooltip: { 
                                            formatter: (value: any) => {
                                                return { name: "Prekliky", value: value.value };
                                            }
                                        },
                                        color: '#f6921e'
                                    }}  />
                                </Card.Grid>

                            </CustomCard>
                    </Col>
                </Row>
                </StatsSection>

                <PageHeader
                title = {"Štatistiky widgetov"}
                ghost={false}
                > 
                <Paragraph>
                    Štatistiky sú aktualizované každé 4 hodiny. 
                </Paragraph>
                <Paragraph>
                    Prekliky kontrulujeme a v prípade zistenia neštandardného správania môžu byť niektoré prekliky zmazané - môže sa teda stať, že počet preklikov nad vybranými widgetmi po aktualizácii aj poklesne.
                </Paragraph>
                </PageHeader>

               
                <ExportButtonWrapper>
                    <Button type="primary" icon={<DownloadOutlined />} onClick={onExportButtonClick}>
                        Exportovať
                    </Button>
                </ExportButtonWrapper>

                <StatsSection>
                    <Table 
                    loading={widgetStatsFetching}
                    columns={[
                        {
                            title: ('Obsah'),
                            dataIndex: 'content',
                            sorter: (a, b) => a.content.localeCompare(b.content),
                            ...getColumnSearchProps('content')
                        },
                        {
                                title: ('E-shopy / ponuky'),
                                dataIndex: 'activeOffersCount',
                                sorter: (a, b) => a.activeOffersCount - b.activeOffersCount,
                                render: (type, record) => {
                                    return(  
                                    <> 
                                    <div> {record.activeShopsCount}/{record.activeOffersCount}  </div>
                                    </>
                                    )
                                }
                        },
                        {
                            title: ('Kategória'),
                            dataIndex: 'categoryName',
                            sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
                        },
                        {
                            title: ('Typ'), 
                            dataIndex: 'type',
                            sorter: false,
                            filters: [
                                {
                                text: 'Product',
                                value: 'product',
                                },
                                {
                                text: 'Pop-up',
                                value: 'modal_product',
                                },
                                {
                                text: 'Single',
                                value: 'button_product',
                                }
                            ],
                            onFilter: (value, record) => record.type.indexOf(value) === 0,
                            render: (type, record) => {
                                if(type === 'product'){
                                    return "Product"
                                }
                                else if(type === 'modal_product'){
                                    return "Pop-up";
                                }
                                else if(type === 'button_product'){
                                    return "Single";
                                }
                                else{
                                    return type
                                }
                            }
                        },
                        {
                            title: ('Impresie'), 
                            dataIndex: 'impressions',
                            sorter: (a, b) => b.impressions - a.impressions,
                        },
                        {
                            title: ('Prekliky'),  
                            dataIndex: 'clicks',
                            sorter: (a, b) => b.clicks - a.clicks,
                        },
                        /*
                        {
                            title: ('Prekliky IN'),  
                            dataIndex: 'widget_session_widget_clicks',
                            sorter: (a, b) => b.widget_session_widget_clicks - a.widget_session_widget_clicks,
                        },
                        {
                            title: ('Prekliky OUT'),  
                            dataIndex: 'widget_session_clicks',
                            sorter: (a, b) => b.widget_session_clicks - a.widget_session_clicks,
                        },
                        */
                        {
                            title: ('CTR'),  
                            dataIndex: 'ctr',
                            sorter: (a, b) => b.ctr - a.ctr,
                            render: (ctr, record) => (
                                <> {ctr.toFixed(2)} </>
                            )
                        },
                        {
                            title: ('CPC'),  
                            dataIndex: 'cpc',
                            sorter: (a, b) => b.cpc - a.cpc, 
                            render: (cpc, record) => (
                                <> {cpc.toFixed(2)} </>
                            )
                        },
                        {
                            title: ('Obrat'),  
                            dataIndex: 'payment',
                            sorter: (a, b) => b.payment - a.payment,
                            render: (payment, record) => (
                                <> {payment.toFixed(2)} </>
                            )
                        },
                        /*
                        {
                            title: ('Provizia'),  
                            dataIndex: 'widget_session_payment_provision',
                            sorter: (a, b) => b.widget_session_payment_provision - a.widget_session_payment_provision,
                            render: (widget_session_payment_provision, record) => (
                                <> {widget_session_payment_provision.toFixed(2)} </>
                            )
                        },
                        */
                        {
                                title: ('Akcia'),
                                dataIndex: '_id',
                                render: (widgetId,data) => {
                                    return <Link to={`/widgets/detail/${widgetId}`}> 
                                                <Button type="primary"
                                                    size="small"
                                                    icon={<EditOutlined />} />
                                        </Link>
                                                    
                                }
                        },
                        ...!selectedAffiliateName && userRole==="ADMIN"?[
                                {
                                    title: ('Publisher'),
                                    dataIndex: 'affiliateName'
                                }
                        ]:[]
                        
                    ]}
                    dataSource={widgetStats.items}
                    ></Table>
                </StatsSection>
            </Spin>
             
        </>
    )

}

export default AffilStats;