import { Table } from 'antd';
import React, { useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { __ } from '../../utilities';
import { productIsFetchingSelector, productsSelector, setSelectedKeys } from './slice';

const ProductParametersTable = () => {

    const dispatch = useDispatch();
    const [selectedRowKeys,setSelectedRowKeys] = useState([]);

    const products = useSelector((state: ApplicationState) =>
        productsSelector(state)
    );

    React.useEffect(() => {
        async function init() {
            dispatch(setSelectedKeys([]));
            setSelectedRowKeys([]);
        }
        init();
    }, [dispatch,products]);

    const isFetching = useSelector((state: ApplicationState) =>
        productIsFetchingSelector(state)
    );

    return <Table 
        loading ={isFetching}
        columns = {[
                {
                    title: __('Nazov'),
                    dataIndex: 'name',
                    sorter: true,
                },
                {
                    title: __('Kategória'),
                    dataIndex: 'category_name',
                    sorter: true,
                },
                {
                    title: __('Výrobca'),
                    dataIndex: 'manufacturer_name',
                    sorter: true,
                },  
        ]}
        dataSource = {products}
        rowSelection = {{
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                dispatch(setSelectedKeys(selectedRowKeys?(selectedRowKeys as any):[]));
                setSelectedRowKeys(selectedRowKeys);
            },
        }}
        pagination={{
            pageSize: 1000,
        }}
        size = "small"
    />
}

export default ProductParametersTable