import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApplicationState, AppThunk} from "../../store";
import API from "../../services/API";
import {getValuesFromArray} from "../../utilities";
import { tokenSelector } from "../App/slice";

const NAMESPACE = 'automaticPairingProductList';

export interface ListingAutomaticPairingItem {
    key: number;
    catalog_product_name: string;
    shop_product_name: string;
    category_name: string;
    shop_name: string;
    name_check: boolean,
    price_check: boolean,
    category_check: boolean,
    manufacturer_check:boolean,
    match_type: string,
    product_price: number,
    pm_product_price: number,
    name_check_score: number
}

export interface AutomaticPairingProductEntity {
  count: number;
  product_id: number;
  name: string;
  key: number;
}

interface AutomaticPairingListState {
    activeFilters: {
        shopSelected: {value: number}[],
        manufacturerSelected: {value: number}[],
        categorySelected: { value: number}[],
        matchTypeSelected: { value: string}[],
        query: string,
        showPartners: boolean
    },
    filtersData: {
        shop: {
            isFetching: boolean,
            data: any
        },
        manufacturer: {
            isFetching: boolean,
            data: any
        },
        category: {
            isFetching: boolean,
            data: any
        }
    },
    sorterData: {
        type: string,
        order: string;
    },
    filterResults: {
        offset: number
        isFetching: boolean,
        total: number;
        items: AutomaticPairingProductEntity[];
    },
    pagination: {
        pageSize: number;
        current: number
    },
    recordsForProduct: {
      [key: number]: ListingAutomaticPairingItem[]
    },
    loadingRecords: {
      [key: number]: boolean
    }
}

const initialState : AutomaticPairingListState = {
    activeFilters: {
        shopSelected: [],
        manufacturerSelected: [],
        categorySelected: [],
        matchTypeSelected: [],
        query: "",
        showPartners: false
    },
    filtersData: {
        shop: {
            isFetching: false,
            data: []
        },
        manufacturer: {
            isFetching: false,
            data: []
        },
        category: {
            isFetching: false,
            data: []
        }
    },
    sorterData: {
        type: null,
        order: 'asc'
    },
    filterResults: {
        isFetching: false,
        total: 0,
        offset: 0,
        items: []
    },
    pagination: {
        pageSize: 20,
        current: 1
    },
    recordsForProduct: {},
    loadingRecords: {}
};

export const automaticPairingSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setIsFetchingFilterResults: (state, action: PayloadAction<{value: boolean}>) => {
            state.filterResults.isFetching = action.payload.value
        },
        setFilterResults: (state, action: PayloadAction<{total: number, items: Array<AutomaticPairingProductEntity>}>) => {
            state.filterResults.total       = action.payload.total;
            state.filterResults.items = action.payload.items;
        },
        setPagination: (state, action: PayloadAction<{current: number}>) => {
            state.pagination.current = action.payload.current
        },
        setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },
        setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
        setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | number | null}>) => {
            state.activeFilters[action.payload.name] = action.payload.value;
        },
        setRecordsForProduct: (state, action: PayloadAction<{product_id: number, value: ListingAutomaticPairingItem[]}>) => {
          state.recordsForProduct[action.payload.product_id] = action.payload.value;
        },
        resetAllRecordsForProduct: (state, action: PayloadAction<{}>) => {
          state.recordsForProduct = [];
        },
        setLoadingRecords: (state, action: PayloadAction<{product_id: number, value: boolean}>) => {
          state.loadingRecords[action.payload.product_id] = action.payload.value;
        },
    }
});

export const loadAutomaticPairingProducts = () : AppThunk => async (dispatch, getState) => {
  dispatch(automaticPairingSlice.actions.setIsFetchingFilterResults({value:true}));
  const filterShopIds = getValuesFromArray<number>(shopActiveFiltersSelector(getState()));
  const filterManufacturerIds = getValuesFromArray<number>(manufacturerActiveFiltersSelector(getState()));
  const filterCategoryIds = getValuesFromArray<number>(categoryActiveFiltersSelector(getState()));
  const filterMatchTypes  = getValuesFromArray<string>(matchTypeActiveFiltersSelector(getState()));
  const query = queryActiveFiltersSelector(getState());
  const showPartners = showPartnersSelector(getState());

  if(!filterShopIds && !filterManufacturerIds && !filterCategoryIds && !query && !filterMatchTypes){
    dispatch(automaticPairingSlice.actions.setIsFetchingFilterResults({value:false}));
    dispatch(
      automaticPairingSlice.actions.setFilterResults({
        total: 1000,
        items: []
      })
    );

    return;
  }

  API.searchPairingProducts({
    limit: 100,
    filterShopIds: filterShopIds && filterShopIds.length?filterShopIds:undefined,
    filterManufacturerIds: filterManufacturerIds && filterManufacturerIds.length?filterManufacturerIds:undefined,
    filterCategoryIds: filterCategoryIds && filterCategoryIds.length?filterCategoryIds:undefined,
    filterMatchTypes: filterMatchTypes && filterMatchTypes.length?filterMatchTypes:undefined,
    searchName: query && query.length?query:undefined,
    includeChildrenCategories: true,
    filterShopProgramIds:showPartners?undefined:[2,3]
  }).then(response => {
    const listingAutomaticPairingProducts: Array<AutomaticPairingProductEntity> = response.items.map(item => {
      return {
        count: item.count,
        product_id: item.product_id,
        name: item.name,
        key: item.product_id
      }
    });

    dispatch(automaticPairingSlice.actions.setIsFetchingFilterResults({value:false}));
    dispatch(
      automaticPairingSlice.actions.setFilterResults({
        total: 1000,
        items: listingAutomaticPairingProducts,
      })
    );
  })
};

export const loadAutomaticPairingForProduct = (product_id: number) : AppThunk => async (dispatch, getState) => {
    const filterShopIds = getValuesFromArray<number>(shopActiveFiltersSelector(getState()));
    const filterManufacturerIds = getValuesFromArray<number>(manufacturerActiveFiltersSelector(getState()));
    const filterCategoryIds = getValuesFromArray<number>(categoryActiveFiltersSelector(getState()));
    const filterMatchTypes  = getValuesFromArray<string>(matchTypeActiveFiltersSelector(getState()));
    const query = queryActiveFiltersSelector(getState());
    const showPartners = showPartnersSelector(getState());

    API.searchPairing({
        limit: 30,
        filterShopIds: filterShopIds && filterShopIds.length?filterShopIds:undefined,
        filterManufacturerIds: filterManufacturerIds && filterManufacturerIds.length?filterManufacturerIds:undefined,
        filterCategoryIds: filterCategoryIds && filterCategoryIds.length?filterCategoryIds:undefined,
        filterMatchTypes: filterMatchTypes && filterMatchTypes.length?filterMatchTypes:undefined,
        searchName: query && query.length?query:undefined,
        includeChildrenCategories: true,
        filterShopProgramIds:showPartners?undefined:[2,3],
        filterProductIds: [product_id]
    }).then(response => {
        const listingAutomaticPairingItems: Array<ListingAutomaticPairingItem> = response.items.map(
            feed_parser_report => {

                let productDescription = null;
                productDescription = feed_parser_report.product.productDescriptions.find( productDescription => productDescription.language_id === feed_parser_report.shop.country_id)
                if(!productDescription && feed_parser_report.product && feed_parser_report.product.productDescriptions) {
                    productDescription = feed_parser_report.product.productDescriptions[0];
                }

                return {
                    key: feed_parser_report.compare_id,  
                    catalog_product_id: feed_parser_report.product.product_id,
                    catalog_product_name: feed_parser_report.product.name,
                    catalog_product_description: productDescription?productDescription.description:"",
                    shop_product_name: feed_parser_report.pm_shops_product.name,
                    category_name: feed_parser_report.product.category.translations[0].name,
                    category: feed_parser_report.product.category,
                    manufacturer: feed_parser_report.product.manufacturer,
                    shop_name: feed_parser_report.shop.name,
                    ean: feed_parser_report.ean,
                    part_number: feed_parser_report.part_number,
                    name_check: feed_parser_report.name_check,
                    name_check_score: feed_parser_report.name_check_score,
                    category_check: feed_parser_report.category_check,
                    manufacturer_check: feed_parser_report.manufacturer_check,
                    price_check: feed_parser_report.price_check,
                    match_type: feed_parser_report.match_type,
                    product_price: feed_parser_report.product.current_aggregation.average_price,
                    pm_product_price: feed_parser_report.pm_shops_product.converted_price,
                    pm_product_image_url: feed_parser_report.pm_shops_product.image,
                    pm_product_description: feed_parser_report.pm_shops_product.description,
                    pm_product_ean: feed_parser_report.pm_shops_product.ean,
                    pm_product_part_number: feed_parser_report.pm_shops_product.part_number,
                    pm_product_category: feed_parser_report.pm_shops_product.category,
                    pm_product_manufacturer: feed_parser_report.pm_shops_product.manufacturer
                }
            }
        );

        dispatch(setLoadingRecords(product_id, false));
        dispatch(setRecordsForProduct(product_id, listingAutomaticPairingItems));
    })
};

export const loadShopFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(automaticPairingSlice.actions.setIsFetchingFilterData({name: 'shop', value: true}));

    API.loadShops({query:value,limit:100}).then(response => {
      dispatch(automaticPairingSlice.actions.setFilterData({name: 'shop', value: 
      response.shops.map(shop => {
          return {
            name: shop.name+` (${shop.program_id})`,
            value: shop.shop_id
          }
        })
      }));
      
      dispatch(automaticPairingSlice.actions.setIsFetchingFilterData({name: 'shop', value: false}));
    })  
}

export const loadCategoryFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(automaticPairingSlice.actions.setIsFetchingFilterData({name: 'category', value: true}));
  
    API.loadCategories({query:value,limit:100}).then(response => {
      dispatch(automaticPairingSlice.actions.setFilterData({name: 'category', value: 
        response.categories.map( category => {
   
          let categoryName;
          if(category && category.translations && category.translations.length){
              const categoryTranslation = category.translations.find(translation => translation.language_id === 1);
              if(categoryTranslation){
                  categoryName = categoryTranslation.name;
              }
          }
  
          return {
            name: category.parents_names ? [...category.parents_names].reverse().filter((item, index) => index % 2 === 0).join(' < ') : categoryName,
            value: category.category_id
          }
        })
      }));
      
      dispatch(automaticPairingSlice.actions.setIsFetchingFilterData({name: 'category', value: false}));
    });
}

export const loadManufacturerFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    dispatch(automaticPairingSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: true}));
  
    API.loadManufacturers({query:value,limit:100}).then(response => {
      dispatch(automaticPairingSlice.actions.setFilterData({name: 'manufacturer', value: 
        response.manufacturers.map( manufacturer => {
            return {
              name: manufacturer.name,
              value: manufacturer.manufacturer_id
            } 
        })  
      }));
  
      dispatch(automaticPairingSlice.actions.setIsFetchingFilterData({name: 'manufacturer', value: false}));   
    });
}

export const pairAutomaticPairingItems = (keysToReduce:Array<number>) => async (dispatch, getState) => {
    for(const keyToReduce of keysToReduce){
      const token = tokenSelector(getState());
      API.setToken(token);
      await API.updateAutomaticAssignment(keyToReduce,{},{force_assign:'manual'});
    }

    dispatch(loadAutomaticPairingProducts());

    /*
    const listingAutomaticPairingItems = getState().automaticPairingList.filterResults.items; 
    
    for(const keyToReduce of keysToReduce){
        await API.updateAutomaticAssignment(keyToReduce,{},{force_assign:'manual'});
    }

    const newListingAutomaticPairingItems = listingAutomaticPairingItems.filter(item => !keysToReduce.includes(item.key));

    if (newListingAutomaticPairingItems.length > 0) {
      dispatch(
        automaticPairingSlice.actions.setFilterResults({
            total: 1000,
            items: newListingAutomaticPairingItems,
        })
      );
    } else {
      // ak ostalo 0 produktov, nacitame dalsie
      dispatch(loadAutomaticPairingProducts());
    }
    */
};

export const rejectAutomaticPairingItems = (keysToReduce:Array<number>) => async (dispatch, getState) => {
    dispatch(automaticPairingSlice.actions.setIsFetchingFilterResults({value:true}));

    for(const keyToReduce of keysToReduce){
        const token = tokenSelector(getState());
        API.setToken(token);
        await API.updateAutomaticAssignment(keyToReduce,{},{rejected: true});
    }

    dispatch(automaticPairingSlice.actions.resetAllRecordsForProduct({}));

    dispatch(
      automaticPairingSlice.actions.setFilterResults({
        total: 1000,
        items: [],
      })
    );

    dispatch(loadAutomaticPairingProducts());
    
    /*
    const newListingAutomaticPairingItems = listingAutomaticPairingItems.filter(item => !keysToReduce.includes(item.key));
    dispatch(
        automaticPairingSlice.actions.setFilterResults({
            total: 1000,
            items: newListingAutomaticPairingItems,
        })
    );
    */
}

export const setFilter = (filter: string, value: number[] | string | number | null) => (dispatch, getState) => {
    dispatch(automaticPairingSlice.actions.setActiveFilterValue({name: filter, value}));
}

export const setLoadingRecords = (product_id: number, value: boolean) => (dispatch, getState) => {
  dispatch(automaticPairingSlice.actions.setLoadingRecords({product_id: product_id, value: value}));
};

export const setRecordsForProduct = (product_id: number, value: ListingAutomaticPairingItem[]) => (dispatch, getState) => {
  dispatch(automaticPairingSlice.actions.setRecordsForProduct({product_id: product_id, value: value}));
};

export const paginationSelector                = (state: ApplicationState) => state.automaticPairingProductList.pagination;
export const filterResultsTotalSelector        = (state: ApplicationState) => state.automaticPairingProductList.filterResults.total;
export const filterResultsIsFetchingSelector   = (state: ApplicationState) => state.automaticPairingProductList.filterResults.isFetching;
export const filterResultsOffsetSelector       = (state: ApplicationState) => state.automaticPairingProductList.filterResults.offset;
export const filterResultsItemsSelector        = (state: ApplicationState) => state.automaticPairingProductList.filterResults.items;

export const shopActiveFiltersSelector = (state: ApplicationState) => state.automaticPairingProductList.activeFilters.shopSelected;
export const shopFiltersDataSelector = (state: ApplicationState) => state.automaticPairingProductList.filtersData.shop.data;
export const shopFiltersDataIsFetching = (state: ApplicationState) => state.automaticPairingProductList.filtersData.shop.isFetching;

export const matchTypeActiveFiltersSelector = (state: ApplicationState) => state.automaticPairingProductList.activeFilters.matchTypeSelected;

export const queryActiveFiltersSelector = (state: ApplicationState) => state.automaticPairingProductList.activeFilters.query;

export const manufacturerActiveFiltersSelector = (state: ApplicationState) => state.automaticPairingProductList.activeFilters.manufacturerSelected;
export const manufacturerFiltersDataSelector = (state: ApplicationState) => state.automaticPairingProductList.filtersData.manufacturer.data;
export const manufacturerFiltersDataIsFetching = (state: ApplicationState) => state.automaticPairingProductList.filtersData.manufacturer.isFetching;

export const categoryActiveFiltersSelector = (state: ApplicationState) => state.automaticPairingProductList.activeFilters.categorySelected;
export const categoryFiltersDataSelector = (state: ApplicationState) => state.automaticPairingProductList.filtersData.category.data;
export const categoryFiltersDataIsFetching = (state: ApplicationState) => state.automaticPairingProductList.filtersData.category.isFetching;

export const showPartnersSelector = (state: ApplicationState) => state.automaticPairingProductList.activeFilters.showPartners;
export const loadingRecordsSelector = (state: ApplicationState) => state.automaticPairingProductList.loadingRecords;
export const recordsForProductSelector = (state: ApplicationState) => state.automaticPairingProductList.recordsForProduct;

export default automaticPairingSlice.reducer;







