import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {__} from "../../utilities";
import {Alert, Button, PageHeader, Popover,Table} from "antd";
import {ApplicationState} from "../../store";
import styled from "styled-components";
import { loadTopCategories, setCategorySelected, topCategoriesSelector, isFetchingSelector } from "./slice";
import CategorySelect from "../../components/Select/CategorySelect";
import { useHistory } from "react-router";
import { rem } from "polished";


const MainContent = styled.div`
  margin-top: 25px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
`

const CustomTable = styled(Table)`
  width: 100%;
`

const CustomAlert = styled(Alert)`
  margin-top:${rem(15)}
`

const TopCategories = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    React.useEffect(() => {
        async function init() {
            dispatch(loadTopCategories());
        }
        init();
    }, [dispatch]);

    const topCategories = useSelector((state: ApplicationState) =>
        topCategoriesSelector(state)
    );

    const isFetching = useSelector((state: ApplicationState) =>
        isFetchingSelector(state)
    );

    const onProductsClick = (categoryId) => {
        let path = `/top50?category_id=${categoryId}`
        history.push(path);
    }

    return (<>
        <PageHeader 
            title = {"TOP Kategórie"}
            ghost={false}
            extra={[
                <CategorySelect 
                    width={500} 
                    onlyLast={false}
                    onSelect={(value) => {
                        dispatch(setCategorySelected(value));
                    }}
                    disabled={isFetching}
                />
            ]}
        > 
        </PageHeader>
        <MainContent>
            <CustomTable
                columns={[
                    {   
                        title: __('Obrázok'),
                        dataIndex: 'image',
                        width: 40,
                        render: imageUrl => (
                            <Popover content={<img
                              alt=""
                              style={{ height: '200px '}}
                              src={imageUrl}
                            />    }>
                            <div
                              style={{width: '100%', height: '100%',display:"flex", justifyContent:"center",alignItems:"center"}}
                            >
                            <img
                              alt="example"
                              style={{ height: '55px' }}
                              src={imageUrl}
                            />
                            </div>
                            </Popover>
                          ),
                    },
                    {
                        title: __('Názov'),
                        dataIndex: 'name',
                        sorter: false,
                    },
                    {
                        title: __('CPC'),
                        dataIndex: 'cpc',
                        sorter: false,
                    },
                    {
                        title: __('Akcia'),
                        dataIndex: 'category_id',
                        width: 40,
                        render: categoryId => (
                           <>
                              <Button type="primary" onClick={() => {onProductsClick(categoryId)}}>{__('Zobraziť produkty')}</Button>  
                           </>
                        ),
                    }

                ]}
                loading={isFetching}
                dataSource={topCategories}
                pagination={false}
            />

            <CustomAlert 
                message=""
                description="Výška CPC je priemerná hodnota za uplynulých 30 dní. Finálna výška CPC závisí od aktuálnych ponúk eshopov a nastavenej výšky koeficientov pre váš program jednotlivými eshopmi."
                type="info"
            />
        </MainContent>
    </>)
}

export default TopCategories;