import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { PageHeader, List, Skeleton } from 'antd';
import { loadWidgets } from './slice';
import { ApplicationState } from '../../store';
import { widgetsDataSelector, widgetsIsFetchingSelector } from './slice';
import { Link } from 'react-router-dom';
const MainContent = styled.div`
  margin-top: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 25px;
`;

const WebWidget = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    async function init() {
      await dispatch(loadWidgets());
    }
    init();
  }, [dispatch]);

  const widgetData = useSelector((state: ApplicationState) =>
    widgetsDataSelector(state)
  );
  const widgetIsFetching = useSelector((state: ApplicationState) =>
   widgetsIsFetchingSelector(state)
  );

  return (
    <>
      <PageHeader
        title={'Správa widgetov'}
        ghost={false}
        extra={[]}
      ></PageHeader>

      <MainContent>
        <List
          loading={widgetIsFetching}
          itemLayout="horizontal"
          dataSource={widgetData}
          renderItem={item => (
            <List.Item
              actions={[
                <Link to={`/web-widgets/${item['widget_id']}`}>Editovať</Link>,
              ]}
            >
              <Skeleton avatar title={false} loading={false} active>
                <List.Item.Meta
                  title={ <Link to={`/web-widgets/${item['widget_id']}`}>{item['widget_name']}</Link>}
                  description="Pozicia: Index"
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </MainContent>
    </>
  );
};

export default WebWidget;
