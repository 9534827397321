import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState, AppThunk } from "../../store";
import API from "../../services/API";
import { tokenInfoSelector } from "../App/slice";

const NAMESPACE = 'AffilSettings';

enum StatsEmailsFrequency {
  NEVER   = 'never',
  DAILY   = 'daily',
  WEEKLY  = 'weekly',
  MONTHLY = 'monthly' 
}
interface AffilLinks {
  link1: string,
  link2: string,
  link3: string,
  link4: string,
  link5: string,
}
export interface AffilSettingsData {
  projectId: number,
  imagePath: string,
  colors: {
    primary: string,
    secondary: string,
  },
  widget:{ 
    countryId: number,
    showHeader: boolean,
    maxShops: number,
    hideWithoutOffer: boolean,
    linkToPricemaniaWithoutOffer: boolean,
  },
  statsEmails:{
    frequency: StatsEmailsFrequency
  },
  links: AffilLinks,
}


export interface AffilSettingsState {
  data: AffilSettingsData,
  user:{
    apiKey: string
  }
}

const initialState: AffilSettingsState = {
  data: {
    projectId: null,
    imagePath: null,
    colors: {
      primary: '#fff',
      secondary: '#000',
    },
    widget:{
      countryId: 1,
      showHeader: true,
      maxShops: 5,
      hideWithoutOffer: true,
      linkToPricemaniaWithoutOffer: false
    },
    links: {
      link1: null,
      link2: null,
      link3: null,
      link4: null,
      link5: null,
    },
    statsEmails:{
      frequency: StatsEmailsFrequency.NEVER
    }
  },
  user:{
    apiKey: null
  }
}
// SELECTORS
const dataSelector = (state: ApplicationState) => state.AffilSettings.data;

export const affilSettingsSlice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setPrimaryColorValue: (
      state,
      action: PayloadAction<string>
    ) => {
      state.data.colors.primary = action.payload;
    },
    setSecondaryColorValue: (
      state,
      action: PayloadAction<string>
    ) => {
      state.data.colors.secondary = action.payload;
    },
    setImgPathValue: (
      state,
      action: PayloadAction<string>
    ) => {
      state.data.imagePath = action.payload;
    },
    setLink: (
      state,
      action: PayloadAction<{ value: string, id: string }>
    ) => {
      state.data.links[action.payload.id] = action.payload.value
    },
    setAllValues: (
      state,
      action: PayloadAction<AffilSettingsData>
    ) => {
      const affilSettingsData = action.payload;
      if(!affilSettingsData.widget.maxShops){
        affilSettingsData.widget.maxShops = 5;
      }

      state.data = action.payload
    },

    setCountryId: (
      state,
      action: PayloadAction<number>
    ) => {
      state.data.widget.countryId = action.payload;
    },

    setShowWidgetHeader: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.data.widget.showHeader = action.payload;
    },

    setHideWithoutOffer: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.data.widget.hideWithoutOffer = action.payload;
    },

    setLinkToPricemaniaWithoutOffer: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.data.widget.linkToPricemaniaWithoutOffer = action.payload;
    },

    setStatsEmailsFrequency: (
      state,
      action: PayloadAction<StatsEmailsFrequency>
    ) => {
      state.data.statsEmails.frequency = action.payload;
    },

    setUser:(
      state,
      action: PayloadAction<{apiKey:string}>
    ) => {
      state.user = action.payload
    },
    setUserApiKey:(
      state,
      action: PayloadAction<string>
    ) => {
      state.user.apiKey = action.payload
    },
    setMaxShops:(
      state,
      action: PayloadAction<number>
    ) => {
      state.data.widget.maxShops = action.payload
    }

  }

});

export const setPrimaryColorValueAction = (value: string) => (dispatch) => {
  dispatch(
    affilSettingsSlice.actions.setPrimaryColorValue(value)
  )
}

export const setSecondaryColorValueAction = (value: string) => (dispatch) => {
  dispatch(
    affilSettingsSlice.actions.setSecondaryColorValue(value)
  )
}
export const setImgPathValueAction = (value: string) => (dispatch) => {
  dispatch(
    affilSettingsSlice.actions.setImgPathValue(value)
  )
}
export const setLinkValueAction = (value: string, id: string) => (dispatch) => {
  dispatch(
    affilSettingsSlice.actions.setLink({ value, id })
  )
}

export const setCountryIdAction = (value: number) => (dispatch) => {
  dispatch(affilSettingsSlice.actions.setCountryId(value));
}

export const setShowWidgetHeaderAction = (value: string) => (dispatch) => {
  dispatch(affilSettingsSlice.actions.setShowWidgetHeader(value === "1"?true:false));
}

export const setHideWithoutOfferAction = (value: string) => (dispatch) => {
  dispatch(affilSettingsSlice.actions.setHideWithoutOffer(value === "1"?true:false));
}

export const setLinkToPricemaniaWithoutOfferAction = (value: string) => (dispatch) => {
  dispatch(affilSettingsSlice.actions.setLinkToPricemaniaWithoutOffer(value === "1"?true:false));
}

export const setStatsEmailsFrequencyAction = (value: string | any) => (dispatch) => {
  dispatch(affilSettingsSlice.actions.setStatsEmailsFrequency(value));
}

export const setMaxShops = (value: number) => (dispatch) => {
  dispatch(affilSettingsSlice.actions.setMaxShops(value));
}

export const postSettingsAction = (): AppThunk => async (dispatch, getState) => {
  let settings = dataSelector(getState());

  const token = tokenSelector(getState());
  API.setToken(token);

  API.postAffilSettings(
    {},
    settings,
  ).then(()=>{
    alert("Nastavenia uložené!")
  }).catch(() => {
    alert("Nastavenia sa nepodarilo uložiť, skúste to znova")
  });
}

export const loadValues = (): AppThunk => async (dispatch, getState) => {
  const tokenInfo:any = tokenInfoSelector(getState());

  const response:any = await API.getAffilUserSettings();
  const userResponse = await API.loadUser(tokenInfo._id);

  if(!response.statsEmails){
    response.statsEmails = {
      frequency: StatsEmailsFrequency.NEVER
    }
  }
  
  if(!response.statsEmails.frequency){
    response.statsEmails.frequency = StatsEmailsFrequency.NEVER
  }

  if(response.widget && response.widget.hideWithoutOffer === undefined){
    response.widget.hideWithoutOffer = true;
  }

  if(response.widget && response.widget.linkToPricemaniaWithoutOffer === undefined){
    response.widget.linkToPricemaniaWithoutOffer = false;
  }

  dispatch(
    affilSettingsSlice.actions.setAllValues(response)
  )

  dispatch(
    affilSettingsSlice.actions.setUser({apiKey: userResponse.api_key})
  )
};

export const uploadLogo = (data:any): AppThunk => async (dispatch, getState) => {
  const token = tokenSelector(getState());
  API.setToken(token);

  const response:any = await API.uploadAffilLogo(
    {},
    data
  );
  dispatch(
    affilSettingsSlice.actions.setImgPathValue(response.imgPath)
  );
}

export const regenerateApiKey = (): AppThunk => async (dispatch, getState) => {
  const tokenInfo:any = tokenInfoSelector(getState());
  const regenerateApiKeyResponse = await API.regenerateApiKey(tokenInfo._id);
  dispatch(affilSettingsSlice.actions.setUserApiKey(regenerateApiKeyResponse.api_key));
}

export const tokenSelector = (state: ApplicationState) => state.general.token;

export default affilSettingsSlice.reducer