import { Col, Modal, Row, Typography } from "antd";
import { rem } from "polished";
import React, { useState } from "react";
import API from "../../services/API";
import ConnectedSingleSelect from "../Select/ConnectedSingleSelect";

export const CategorySelectModal = (props) => {

    const { isVisible=true, onCancel, title, onOk } = props;
    
    const [categoryActiveFilter,setCategoryActiveFilter] = useState(null as number); 
    const [categoryData, setCategoryData] = useState([]);
    
    const onChangeCategoryFilter = (value) => {
        API.loadCategories({query:value,limit:100}).then(response => {
            const categories = response.categories.map( currentCategory => {
                let categoryName;
                if(currentCategory && currentCategory.translations && currentCategory.translations.length){
                    const categoryTranslation = currentCategory.translations.find(translation => translation.language_id === 1);
                    if(categoryTranslation){
                        categoryName = categoryTranslation.name;
                    }
                } 

                return {
                    name: categoryName,
                    value: currentCategory.category_id
                } 
            });
            setCategoryData(categories);  
        });
    }

    const onSelectCategoryFilter = (value) => {
        setCategoryActiveFilter(value);
    }

    return (
        <Modal
                title = {title}
                visible = {isVisible}
                onOk = {() => {onOk(categoryActiveFilter)}}
                onCancel = {onCancel}
            >  
            
            <Row justify="space-around" align="middle">
                <Col>
                    <Typography.Text>
                        Vybrať výrobcu:
                    </Typography.Text>         
                </Col>
                <Col>
                    <ConnectedSingleSelect
                        value={categoryActiveFilter}
                        data={categoryData}
                        selectWidth={rem(200)}
                        onChange={onChangeCategoryFilter}
                        onSelect={onSelectCategoryFilter}
                    />
                </Col>
            </Row>
               
        </Modal>
    )
}