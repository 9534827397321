import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ApplicationState, AppThunk} from "../../store";
import API from "../../services/API";
import {__} from "../../utilities";

const NAMESPACE = 'changePassword';

export interface changePasswordState {
  newPassword: string;
  newPassword2: string
}

const initialState = {
  newPassword: "",
  newPassword2: ""

}

export const tokenInfoSelector = (state: ApplicationState) => state.general.tokeninfo;


export const changePasswordState = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
      setPassword: (state, action: PayloadAction<string>) => {
        state.newPassword = action.payload
      },
      setPassword2: (state, action: PayloadAction<string>) => {
        state.newPassword2 = action.payload
      }
    }
  });


export const setPassword = (value:string) => (dispatch, getState) => {
  dispatch(changePasswordState.actions.setPassword(value))
}
export const setPassword2 = (value:string) => (dispatch, getState) => {
  dispatch(changePasswordState.actions.setPassword2(value))
}

export const changePassword = (id:string, password:string,history):AppThunk => async (dispatch, getState) => {
  const  apiResponse =  await API.changePassword(id,{},{password:password});
  if (apiResponse.success) {
    alert(__("Heslo bolo úspešne zmenené"));
    history.goBack();
  }else{
    alert(__("Heslo sa zhoduje s predošlým heslom alebo vznikol problém pri jeho zmene "))
  }
}
export default changePasswordState.reducer;