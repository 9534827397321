import React  from 'react';
import {
  Select,
  DatePicker,
  Button,
  Tooltip,
  Typography,
  Input,
  Row,
  Col,
  Table,
} from 'antd';

import { CloseCircleFilled } from '@ant-design/icons';
import { __, FORMAT_HUMANE_DATE } from '../../utilities';
import { useDispatch, useSelector } from 'react-redux';
import ConnectedMultiSelect from '../../components/Select/ConnectedMultiSelect';
import {
  loadManufacturerFilterData,
  manufacturerActiveFiltersSelector,
  manufacturerFiltersDataIsFetching,
  manufacturerFiltersDataSelector,
  setFilter,
  setPagination,
  filterResultsProductsSelector,
  loadCategoryFilterData,
  categoryActiveFiltersSelector,
  categoryFiltersDataIsFetching,
  categoryFiltersDataSelector,
  loadProducts,
  setSorter,
  paginationSelector,
  filterResultsTotalSelector,
  filterResultsIsFetchingSelector,
} from './slice';
import { rem } from 'polished';
import { ApplicationState } from '../../store';
import H1 from '../../components/Headings/H1';
import { ActionButtonsListing } from '../../components/ActionButtons/ActionButtons';
import { ArrowRightOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";

const { Option } = Select;
const { Text, Title } = Typography;
const dateFormat = FORMAT_HUMANE_DATE;

const selectWidth = rem(175);

const columns = [
  {
    title: 'Img',
    dataIndex: 'imageUrl',
    render: imageUrl => (
      <img
        alt="example"
        style={{ maxHeight: '50px ', width: '50px' }}
        src={imageUrl}
      />
    ),
  },
  {
    title: __('Názov Produktu'),
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: __('Editacia'),
    dataIndex: 'productId',
    sorter: true,
    render: productId => (
      <Link to={`/produkt-detail/${productId}`}>Edit</Link>
    ),
  },
  {
    title: __('Link'),
    dataIndex: 'productUrl',
    render: productUrl => (
      <Button
        type="primary"
        icon={<ArrowRightOutlined />}
        size={'small'}
        href={productUrl}
        target="_blank"
      />
    ),
  },
  {
    title: __('Výrobca'),
    dataIndex: 'manufacturer_name',
    sorter: true,
  },
  {
    title: __('Kategória'),
    dataIndex: 'category_name',
    sorter: true,
  },
  {
    title: __('Počet ponúk'),
    dataIndex: 'active_shops_count',
    sorter: true,
  },
  {
    title: __('Cena'),
    dataIndex: 'lowest_price',
    sorter: true,
  },
];

const Listing = () => {
  function onCancelAllFilters() {
    dispatch(setFilter('categorySelected', null));
    dispatch(setFilter('manufacturerSelected', null));
    dispatch(setFilter('since', null));
    dispatch(setFilter('manufacturerSelected', null));
    dispatch(setFilter('query', null));
    dispatch(loadProducts());
  }

  const dispatch = useDispatch();

  // Nahrada componentDidMount
  React.useEffect(() => {
    async function init() {
      // TODO IMPLEMENT
      dispatch(loadProducts());
    }
    init();
  }, [dispatch]);

  const onChangeManufacturerFilter = async value => {
    await dispatch(loadManufacturerFilterData(value));
  };

  const onSelectManufacturerFilter = async value => {
    dispatch(setFilter('manufacturerSelected', value));
    dispatch(loadProducts());
  };

  const onChangeCategoryFilter = async value => {
    await dispatch(loadCategoryFilterData(value));
  };

  const onSelectCategoryFilter = async value => {
    dispatch(setFilter('categorySelected', value));
    dispatch(loadProducts());
  };

  const onChangeSinceFilter = async value => {
    dispatch(setFilter('since', value));
    dispatch(loadProducts());
  };

  // const onChangeChangeDateFilter = async value => {
  //   dispatch(setFilter('changeDate', value));
  //   dispatch(loadProducts());
  // };

  const onSearchQuery = async value => {
    dispatch(setFilter('query', value));
    dispatch(loadProducts());
  };

  const onChangeTable = async (pagination, filters, sorters) => {
    dispatch(setPagination(pagination.current));
    dispatch(setSorter(sorters.field, sorters.order));
    dispatch(loadProducts());
  };

  const manufacturerActiveFilters = useSelector((state: ApplicationState) =>
    manufacturerActiveFiltersSelector(state)
  );

  const manufacturerData = useSelector((state: ApplicationState) =>
    manufacturerFiltersDataSelector(state)
  );

  const isFetchingManufacturerData = useSelector((state: ApplicationState) =>
    manufacturerFiltersDataIsFetching(state)
  );

  const categoryActiveFilters = useSelector((state: ApplicationState) =>
    categoryActiveFiltersSelector(state)
  );

  const categoryData = useSelector((state: ApplicationState) =>
    categoryFiltersDataSelector(state)
  );

  const isFetchingCategoryData = useSelector((state: ApplicationState) =>
    categoryFiltersDataIsFetching(state)
  );

  const products = useSelector((state: ApplicationState) =>
    filterResultsProductsSelector(state)
  );

  const pagination = useSelector((state: ApplicationState) =>
    paginationSelector(state)
  );

  const total = useSelector((state: ApplicationState) =>
    filterResultsTotalSelector(state)
  );

  const isFetchingProducts = useSelector((state: ApplicationState) =>
    filterResultsIsFetchingSelector(state)
  );

  return (
    <>
      <H1>{__('Nové produkty')}</H1>
      <Row>
        {/*todo samostatny comp*/}
        <Col className="gutter-row" span={24}>
          <Row gutter={{ lg: 5 }}>
            <Col>
              <ConnectedMultiSelect
                name={__('Výrobca')}
                isFetching={isFetchingManufacturerData}
                value={manufacturerActiveFilters}
                data={manufacturerData}
                selectWidth={selectWidth}
                onChange={onChangeManufacturerFilter}
                onSelect={onSelectManufacturerFilter}
              />
            </Col>
            <Col>
              <ConnectedMultiSelect
                name={__('Kategória')}
                isFetching={isFetchingCategoryData}
                value={categoryActiveFilters}
                data={categoryData}
                selectWidth={selectWidth}
                onChange={onChangeCategoryFilter}
                onSelect={onSelectCategoryFilter}
              />
            </Col>
            <Col>
              <Text style={{ marginLeft: 10 }}>{__('V listingu od:')}</Text>

              <DatePicker
                style={{ width: 125, marginLeft: 5 }}
                onChange={onChangeSinceFilter}
                format={dateFormat}
              />
            </Col>
            {/*
                        <Col>
                            <Text style={{ marginLeft: 10 }}>Dátum zmeny:</Text>

                            <DatePicker
                                style={{ width: 125, marginLeft: 5 }}
                                onChange={onChangeChangeDateFilter}
                                format={dateFormat}
                            />
                        </Col>
                        */}
            <Col>
              <Select
                style={{ width: 100 }}
                placeholder={__('Krajina')}
                defaultValue="1"
                disabled
              >
                <Option value="1">SK</Option>
                <Option value="2">CZ</Option>
              </Select>
            </Col>
            <Col>
              <Input.Search
                style={{
                  width: selectWidth + rem(50),
                }}
                placeholder={__('Vyhľadať')}
                onSearch={onSearchQuery}
                enterButton
              />
            </Col>
            <Col>
              <Tooltip title={__('Zrušiť filtre')}>
                <CloseCircleFilled
                  onClick={() => {
                    onCancelAllFilters();
                  }}
                  style={{ fontSize: '32px' }}
                />
              </Tooltip>
            </Col>
          </Row>
        </Col>

        <Col className="gutter-row" span={24}>
          <Title level={4}>
            {__('Počet záznamov: ')}
            {total < pagination.pageSize ? total : pagination.pageSize} z{' '}
            {total}
          </Title>
        </Col>

        <Col className="gutter-row" span={24}>
          {/* TODO Doplnit ROW SELECTION cez REDUX */}
          <Table
            columns={columns}
            pagination={{
              total: total,
              current: pagination.current,
              pageSize: pagination.pageSize,
            }}
            loading={isFetchingProducts}
            dataSource={products}
            onChange={(pagination, filters, sorters) => {
              onChangeTable(pagination, filters, sorters);
            }}
          />
        </Col>

        <Col className="gutter-row" span={24}>
          <ActionButtonsListing />
        </Col>
      </Row>
    </>
  );
};

export default Listing;
