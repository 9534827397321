import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../store';
import {
    Modal
  } from 'antd';
import { modalProductPreviewDataSelector, modalProductPreviewIsVisible, setModalVisible } from './slice';
import styled from 'styled-components';

const ModalProduct = styled.div`
  flex: 1;
  padding: 1rem;  
`

const ModalProductName = styled.p`
`

const ModalProductImage = styled.img`
   max-width: 100%;
   max-height: 250px;
`

const ModalProductDescription = styled.p`
   
`

const ProductPreviewModal = (props) => {

    const dispatch = useDispatch();

    // productPreviewModal
    const isProductPreviewModalVisible = useSelector((state: ApplicationState) => 
        modalProductPreviewIsVisible(state)
    )

    const productPreviewModalData = useSelector((state: ApplicationState) => 
        modalProductPreviewDataSelector(state)
    )

    const onProductPreviewModalClose = (value) => {
        dispatch(setModalVisible("productPreview",false));
    }

    return <Modal visible = {isProductPreviewModalVisible}
                onCancel={onProductPreviewModalClose}
                onOk={onProductPreviewModalClose}
                title="Náhľad produktu"
            >    
                {productPreviewModalData? 
                    <ModalProduct> 
                        <ModalProductName> {productPreviewModalData.product_name} </ModalProductName>
                        <ModalProductImage src={productPreviewModalData.picture} />
                        <ModalProductDescription> {productPreviewModalData.short_description} </ModalProductDescription>  
                    </ModalProduct>   
                :null} 

            </Modal>
}

export default ProductPreviewModal;