import React from 'react';
import { Button } from 'antd';
import { __ } from '../../utilities';

export const ActionButtonsListing = props => { 
    return (
        <div style={{ float: 'right' }}>
            <Button type="primary">{__('Hromadná editácia')}</Button>
            <Button type="primary" style={{ marginLeft: 5 }}>
                {__('Vytvoriť nové')}
            </Button>
            <Button type="primary" style={{ marginLeft: 5 }}>
                {__('Zlúčiť Ponuky')}
            </Button>
        </div>
    );
};

export const ActionButtonsDetail = props => {
    return (
        <div style={{ float: 'right' }}>
            <Button type="primary">{__('Deaktivovať')}</Button>
            <Button type="primary" disabled style={{ marginLeft: 5 }}>
                {__('Nastav ako master')}
            </Button>
            <Button type="primary" style={{ marginLeft: 5 }}>
                {__('Vytvoriť nový')}
            </Button>
            <Button type="primary" style={{ marginLeft: 5 }}>
                {__('Napárovať produkty')}
            </Button>
        </div>
    );
};

export const ActionButtonsSave = props => {
    return (
        <div style={{ float: 'right' }}>
            <Button type="primary">{__('Náhľad')}</Button>
            <Button type="primary" style={{ marginLeft: 5 }}>
                {__('Uložiť a pokračovať')}
            </Button>
            <Button type="primary" style={{ marginLeft: 5 }} onClick={props.onClickSave} >
                {__('Uložiť')}
            </Button>

        </div>
    );
};

