import { DeleteOutlined } from "@ant-design/icons";
import { Button, Switch } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import EditableTable from "../../containers/ParameterDetail/EditableTable";
import { ApplicationState } from "../../store";
import { getRandomString, __ } from "../../utilities";
import { ParameterSelect } from "./ParameterSelect";
import { addToChildren, CategoryParameter, categoryParametersSelector, createCategoryParameter, inheritFromParent, loadCategory, localeIdSelector, parametersSelector, removeCategoryParameter, setLocaleId, setParameterTranslatedValue, updateCategoryParameterPositions } from "./slice";

const AddNewParameterButton = styled(Button)`
   margin-bottom: 10px;
   width: 200px;
`

const InheritFromParentButton = styled(Button)`
   margin-left: 10px;
   margin-bottom: 10px;
   width: 200px;
`

const AddToChildrenButton = styled(Button)`
   margin-left: 10px;
   margin-bottom: 10px;
   width: 200px;
` 

const CategoryParameters = ({categoryId,defaultLocaleId}) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        async function init() {
          if(categoryId){
            await dispatch(loadCategory(categoryId))
          }  
        }
        init();
    }, [dispatch, categoryId]);

    React.useEffect(() => {
        async function init() {
          await dispatch(setLocaleId(defaultLocaleId))
        }
        init();
    }, [dispatch, defaultLocaleId]);

    const categoryParameters = useSelector((state: ApplicationState) =>
        categoryParametersSelector(state)
    );

    const parameters = useSelector((state: ApplicationState) =>
        parametersSelector(state)
    );

    const onClickAddNewParameter = () => {
        dispatch(createCategoryParameter({
            _id: "NEW_"+getRandomString(10),
            index: 200,
            position: 200,
            translations:[
                {
                    language_id: 1,
                    expand: false,
                    index: false,
                    show: false
                },
                {
                    language_id: 2,
                    expand: false,
                    index: false,
                    show: false
                }
            ],
            parameter_id: null
        }))
        console.log("====");
    }

    const localeId = useSelector((state: ApplicationState) =>
        localeIdSelector(state)
    );

    const onCategoryParameterRemoveClick = (_id) => {
        dispatch(removeCategoryParameter(_id));
    }

    const clickInheritFromParentButton = () => {
        dispatch(inheritFromParent())
    }

    const clickAddToChildrenButton = () => {
        dispatch(addToChildren())
    }

    const clickDetailButton = (categoryId:number) => {
        if(window){
            window.location.href   = `/category/detail/${categoryId}`
        }
    }

    return <>
        <div>
            <AddNewParameterButton onClick={onClickAddNewParameter}> {__("Pridať nový parameter")} </AddNewParameterButton>
            <InheritFromParentButton onClick={clickInheritFromParentButton}> {__("Zdediť z parenta")} </InheritFromParentButton>
            <AddToChildrenButton onClick={clickAddToChildrenButton}> {__("Predať na childy")} </AddToChildrenButton>
            <AddToChildrenButton onClick={() => clickDetailButton(categoryId)}> {__("Detail")} </AddToChildrenButton>
        </div>
        <EditableTable
                    rowKey = "_id"
                    onUpdatePositions = {(data => {
                        dispatch(updateCategoryParameterPositions(data.oldIndex,data.newIndex));
                    })}
                    columns = {[
                        {
                            title: __('Interný názov'),
                            dataIndex: 'parameter_name',
                            sorter: false,
                            render: (_id,data:CategoryParameter) => {
                                const currentParameter = parameters.find(parameter => parameter._id === data.parameter_id);
                                return <ParameterSelect _id = {data._id} currentParameter={currentParameter}/>
                                /*
                                const currentParameter = parameters.find(parameter => parameter._id === data.parameter_id);
                                if(currentParameter){
                                    return currentParameter.internal_name
                                }
                                else{
                                    return "";
                                }
                                */
                            }
                        },
                        {
                            title: __('Zobrazovať'),
                            dataIndex: 'parameter_name',
                            sorter: false,
                            render: (_id,data:CategoryParameter) => {
                                const currentTranslation = data.translations.find(t => t.language_id === localeId);
                                if(currentTranslation){
                                    return <Switch checked={currentTranslation.show} onChange={(value) => dispatch(setParameterTranslatedValue(data._id,"show",value))} />
                                }
                                else{
                                    return <Switch checked={false}/>
                                }
                            }
                        },
                        {
                            title: __('Indexovať'),
                            dataIndex: 'parameter_name',
                            sorter: false,
                            render: (_id,data:CategoryParameter) => {
                                const currentTranslation = data.translations.find(t => t.language_id === localeId);
                                if(currentTranslation){
                                    return <Switch checked={currentTranslation.index} onChange={(value) => dispatch(setParameterTranslatedValue(data._id,"index",value))}/>
                                }
                                else{
                                    return <Switch checked={false}/>
                                }
                            }
                        },
                        {
                            title: __('Expandovať'),
                            dataIndex: 'parameter_name',
                            sorter: false,
                            render: (_id,data:CategoryParameter) => {
                                const currentTranslation = data.translations.find(t => t.language_id === localeId);
                                if(currentTranslation){
                                    return <Switch checked={currentTranslation.expand} onChange={(value) => dispatch(setParameterTranslatedValue(data._id,"expand",value))}/>
                                }
                                else{
                                    return <Switch checked={false}/>
                                }
                            }
                        },
                        {
                            title: __('Akcie'),
                            dataIndex: 'actions',
                            sorter: false,
                            editable: false,
                            render: (_id,data) => {
                                return <Button type="primary"
                                              size="small"
                                              icon={<DeleteOutlined />}
                                              onClick={() => {
                                                onCategoryParameterRemoveClick(data._id);
                                              }} 
                                       />
                                        
                             }
                        }
                    ]}
                    dataSource = {categoryParameters}
                    isSortable = {true}
                    ebabledAddRowButton = {false}
        />     
    </>
}

export default CategoryParameters