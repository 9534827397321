import React from 'react';
import styled from 'styled-components';
import { PageHeader, Card, message } from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import { ApplicationState } from '../../store';
import WidgetSettings from '../../components/WidgetSettings/WidgetSettings';
import {loadWidgetSettings, widgetNameSelector, widgetTypeSelector} from '../../components/WidgetSettings/slice';
import WidgetTagsSettings from '../../components/WidgetTagsSettings/WidgetTagsSettings';

const MainBox = styled.div`
  margin-bottom: 30px;
`

const MainBoxContent = styled.div`
`

const WidgetDetail = (props) => {
  const dispatch = useDispatch();
    const { widgetId } = props;

  React.useEffect(() => {
    async function init() {
      dispatch(loadWidgetSettings(widgetId));
    }
    init();
  }, [dispatch,widgetId]);

    const updateCallback = () => {
        message.success("Widget bol aktualizovaný");
    }

    const widgetName = useSelector((state: ApplicationState) => 
        widgetNameSelector(state)
    );

    const widgetType = useSelector((state: ApplicationState) => 
        widgetTypeSelector(state)
    );
    /*
    const affiliateId = useSelector((state: ApplicationState) => 
        widgetAffiliateIdSelector(state)
    );
    */
    
    return (
        <>  <MainBox>
                <PageHeader
                title = "Detail widgetu"
                subTitle = {widgetName}
                ghost={false}
                />
            </MainBox>
 
            <MainBox>
                <Card title="Nastavenia tagov">
                    <MainBoxContent>
                        <WidgetTagsSettings widgetId={widgetId} />
                    </MainBoxContent>        
                </Card>     
            </MainBox>
            { widgetType === null || widgetType=== 'products' || widgetType ==='modal_products' ?
                <MainBox>
                    <Card title="Nastavenia">
                        <MainBoxContent>
                            <p>Zmeniť východzie hodnoty</p>
                            <WidgetSettings widgetId={widgetId} updateCallback={updateCallback} />
                        </MainBoxContent>        
                    </Card>     
                </MainBox>
                :null
            }
        </>
    )
}

export default WidgetDetail;