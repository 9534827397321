import React, {useState} from 'react';
import {
  DatePicker,
  Button,
  Tooltip,
  Typography,
  Input,
  Row,
  Col,
  Table,
  Radio,
  Checkbox,
  Select,
} from 'antd';

import { CloseCircleFilled } from '@ant-design/icons';
import { __, FORMAT_HUMANE_DATE } from '../../utilities';
import { useDispatch, useSelector } from 'react-redux';
import ConnectedMultiSelect from '../../components/Select/ConnectedMultiSelect';
import { rem } from 'polished';
import { ApplicationState } from '../../store';
import H1 from '../../components/Headings/H1';
import {
  ActionButtonsDetail,
  ActionButtonsSave,
} from '../../components/ActionButtons/ActionButtons';
import { EyeOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import {
  shopFiltersDataIsFetching,
  shopFiltersDataSelector,
  loadShopFilterData,
  loadGoods,
  productDataSelector,
  productActiveCountrySelector,
  setProductActiveCountry,
  setProductActiveQuery,
  filterGoods,
  setFilter, cleanAllGoodsFilters, productShopActiveFiltersSelector, updateProductLock, updateProductParameters, setProductDescription,
} from './slice';
import ProductDescriptionEditor from "../../components/ProductDetail/ProductDescriptionEditor";
import GalleryModal from '../../components/ProductDetail/ProductGalleryModal';
import ManufacturerModal from '../../components/ProductDetail/ProductManufacturerModal';
import { ProductDetailParameters } from './ProductDetailParameters';

const { Text } = Typography;
const dateFormat = FORMAT_HUMANE_DATE;

const selectWidth = rem(175);

const columns = [
  {
    title: 'Img',
    dataIndex: 'imageUrl',
    width: 50,
    render: imageUrl => (
      <img
        alt="example"
        style={{ maxHeight: '50px', width: '50px' }}
        src={imageUrl}
      />
    ),
  },
  {
    title: __('Názov Produktu'),
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: __('Výrobca'),
    dataIndex: 'manufacturer_name',
  },
  {
    title: __('Shop'),
    dataIndex: 'shop',
    render: shop => (
      <>
        {shop.name}
      </>
    ),
    sorter: (a, b) => a.shop.name.localeCompare(b.shop.name),
  },
  {
    title: __('Cena'),
    dataIndex: 'lowest_price',
    render: lowest_price => (
      <>
        {lowest_price}€
      </>
    ),
    sorter: (a, b) => a.lowest_price - b.lowest_price,
  },
  // {
  //   title: __('Napárované'),
  //   dataIndex: 'paired',
  //   sorter: (a, b) => a.paired - b.paired,
  // },
];

const ClickableInput = styled(Input)`
  cursor: pointer; 
`

const ProductDetail = ({productId}) => {

  const dispatch = useDispatch();

  const [isEditorShowed, setShowEditor] = useState(false);
  const [isGalleryModalVisible, setIsGalleryModalVisible] = useState(false);
  const [isManufacturerModalVisible, setIsManufacturerModalVisible] = useState(false);

  React.useEffect(() => {
    async function init() {
      // TODO IMPLEMENT
      await dispatch(loadGoods(productId));
    }
    init();
  }, [dispatch, productId]);


  const isFetchingShopData = useSelector((state: ApplicationState) =>
    shopFiltersDataIsFetching(state)
  );
  const shopData = useSelector((state: ApplicationState) =>
    shopFiltersDataSelector(state)
  );
  const language = useSelector((state: ApplicationState) =>
    productActiveCountrySelector(state)
  );
  const detailProductInfo = useSelector((state: ApplicationState) =>
    productDataSelector(state)
  );
  const activeShopsFilter = useSelector((state: ApplicationState) =>
    productShopActiveFiltersSelector(state)
  );

  const updateByLanguage = (value: number) => {
    dispatch(setProductActiveCountry(value));
    dispatch(loadGoods(productId));
  };

  const onSelectShopFilter = shop => {
    dispatch(setFilter('shopSelected', shop));
    dispatch(filterGoods());
  };

  const onCancelAllFilters = () => {
    dispatch(cleanAllGoodsFilters());
    dispatch(filterGoods());
  };

  const onSearchQuery = e => {
    dispatch(setProductActiveQuery(e.target.value));
    dispatch(filterGoods());
  };

  const onChangeShopFilter = async value => {
    await dispatch(loadShopFilterData(value));
  };
  
  const onGalleryModalOpen = () => {
    setIsGalleryModalVisible(true);  
  }

  const onGalleryModalClose = () => {
    setIsGalleryModalVisible(false);  
  }

  const onManufacturerModalOpen = () => {
    setIsManufacturerModalVisible(true);
  }

  const onManufacturerModalClose = () => {
    setIsManufacturerModalVisible(false);
  }

  const onDescriptionChange = (description) => {
    dispatch(setProductDescription(description));
  }

  return (
    <>
      <Row gutter={[10, 24]} align="middle">
        <Col>
          <H1>
            {detailProductInfo && detailProductInfo.manufacturerName}
            {' - '}
            {detailProductInfo && detailProductInfo.name}
          </H1>
        </Col>
        <Col>
          <Button style={{position: 'relative', 'top': -7}}>
            <EyeOutlined />
            {__('Náhľad')}
          </Button>
        </Col>
      </Row>

      <GalleryModal 
        isVisible = {isGalleryModalVisible}
        onClose = {onGalleryModalClose}
        title = {detailProductInfo && detailProductInfo.name}
        assetInfo = {detailProductInfo.assetInfo }
        productId = { detailProductInfo.productId }
        detailImageUrl = {detailProductInfo.imageUrl}
        gallery = {detailProductInfo.gallery}
        detailImageFile = {detailProductInfo.detailImageFile}
        model3d = {detailProductInfo.model3d}
      />
      
      <ManufacturerModal 
        isVisible = {isManufacturerModalVisible}
        onClose = {onManufacturerModalClose}
        manufacturerName = {detailProductInfo && detailProductInfo.manufacturerName}
        title = "Mapovanie výrobcu"
      />

      <Row gutter={[0, 34]}>
        <Col className="gutter-row" xl={7} xxl={4}>
          <Radio.Group
            value={language}
            buttonStyle="solid"
            style={{ width: '100%' }}
            onChange={e => {
              updateByLanguage(e.target.value);
            }}
          >
            <Radio.Button value={1}>SK</Radio.Button>
            <Radio.Button value={2}>CZ</Radio.Button>
            <Radio.Button value={0}>EN</Radio.Button>
          </Radio.Group>
          <img
            alt={detailProductInfo.imageUrl}
            style={{ maxWidth: rem(200), marginTop: 25, marginBottom: 25 }}
            src={detailProductInfo.imageUrl}
          />
          <br/>
        <Button type="primary"
                onClick={onGalleryModalOpen}
        >
           {__('Uprav galériu')}
        </Button>
        </Col>
        <Col className="gutter-row" xl={7} xxl={6}>
          <table style={{width: "100%"}}>
            <tr>
              <td>
                <Text>{__('Výrobca')}</Text>
              </td>
              <td>
                <ClickableInput
                  style={{
                    width: 200,
                  }}
                  value={detailProductInfo && detailProductInfo.manufacturerName}
                  onClick = {onManufacturerModalOpen}
                />  
              </td>
            </tr>
            <tr>
              <td>
                <Text>{__('Z feedu')}</Text>
              </td>
              <td>
                <Input
                  style={{
                    width: 200,
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Text>{__('Kategória')}</Text>
              </td>
              <td>
                <Select
                  value={detailProductInfo && detailProductInfo.categoryName}
                  style={{
                    width: 200,
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Text>{__('Url')}</Text>
              </td>
              <td>
                <Input
                  value={detailProductInfo && detailProductInfo.productUrl}
                  style={{
                    width: 200,
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Text>{__('H1')}</Text>
              </td>
              <td>
                <Input
                  style={{
                    width: 200,
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Text>{__('Title')}</Text>
              </td>
              <td>
                <Input
                  style={{
                    width: 200,
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Text>{__('Cena')}</Text>
              </td>
              <td>
                <Input
                  value={detailProductInfo && detailProductInfo.lowestPrice}
                  disabled
                  style={{
                    width: 200,
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Text>{__('Vytvorené')}</Text>
              </td>
              <td>
                <Input
                  disabled
                  value={detailProductInfo.created}
                  style={{
                    width: 200,
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <Text>{__('Publikované')}</Text>
              </td>
              <td>
                <Checkbox defaultChecked />
              </td>
            </tr>
            <tr>
              <td>
                <Text>{__('Uzamknúť zmeny')}</Text>
              </td>
              <td>
                <Checkbox checked = {detailProductInfo.lock} onChange={(e) => dispatch(updateProductLock(!detailProductInfo.lock)) } />
              </td>
            </tr>
            <tr>
              <td>
                <Text>{__('Publikovať od')}</Text>
              </td>
              <td>
                <Checkbox />
                <DatePicker
                  style={{
                    width: 125,
                  }}
                  format={dateFormat}
                />
              </td>
            </tr>
          </table>
        </Col>

        <Col className="gutter-row" xl={10} xxl={14}>
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <ProductDescriptionEditor 
                showEditor={isEditorShowed} 
                description={detailProductInfo && detailProductInfo.description}
                onChange={onDescriptionChange}
              />
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <Button style={{ float: 'right' }} type="primary" onClick={() => {setShowEditor(!isEditorShowed)}}>
                {__('Uprav popis')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[0, 24]}>
        <Col className="gutter-row" span={24}>
          <Row gutter={{ lg: 5 }}>
            <Col>
              <ConnectedMultiSelect
                value={activeShopsFilter}
                selectWidth={selectWidth}
                name={__('Shop')}
                isFetching={isFetchingShopData}
                data={shopData}
                onChange={onChangeShopFilter}
                onSelect={onSelectShopFilter}
              />
            </Col>
            <Col>
              <Input.Search
                placeholder={__('Vyhľadať')}
                onChange={onSearchQuery}
                id="query"
                enterButton
              />
            </Col>
            <Col>
              <Tooltip title={__('Zrušiť filtre')}>
                <CloseCircleFilled
                  onClick={() => {
                    onCancelAllFilters();
                  }}
                  style={{ fontSize: '32px' }}
                />
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[0, 24]}>
        <Col className="gutter-row" span={24}>
          <Table

            columns={columns}
            //loading={isFetchingGoods}
            dataSource={detailProductInfo.goodsFiltered}
            pagination={false}
          />
        </Col>
      </Row>
      
      <Row gutter={[0, 24]}>
        <Col className="gutter-row" span={24}>
          <ProductDetailParameters/>
        </Col>
      </Row>

      <Row gutter={[0, 24]}>
        <Col className="gutter-row" span={24}>
          <ActionButtonsDetail />
        </Col>
        <Col className="gutter-row" span={24}>
          <ActionButtonsSave onClickSave={() => {dispatch(updateProductParameters())}}/>
        </Col>
      </Row>
    </>
  );
};

export default ProductDetail;
