import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ApplicationState } from '../../store';
import { getRandomString, __ } from '../../utilities';
import EditableTable from '../ParameterDetail/EditableTable';
import { ParameterSelect } from './ParameterSelect';
import { createParameter, Parameter, parametersSelector, ProductParameter, productParametersSelector, removeParameter, updateProductParameter } from './slice';


const AddNewParameterButton = styled(Button)`
   margin-bottom: 10px;
`

export const ProductDetailParameters = () => {
    const dispatch = useDispatch();

    const productParameters:ProductParameter[] = useSelector((state: ApplicationState) =>
        productParametersSelector(state)
    );

    const parameters:Parameter[] = useSelector((state: ApplicationState) =>
        parametersSelector(state)
    )

    const onValueRemoveClick = (_id) => {
        dispatch(removeParameter(_id));
    }

    const onChangeValueParameterValue = (_id, value_id) => {
        dispatch(updateProductParameter({_id,parameterValueId:value_id}));
    }

    const onChangeNumberParameterValue = (_id,value) => {
        dispatch(updateProductParameter({_id,value}));
    }

    const onClickAddNewParameter = () => {
        dispatch(createParameter({
            _id: "NEW_"+getRandomString(10),
            parameter_id: null, 
            parameter_value_id: null, 
            value: null
        }));
    }

    /*
    const buildTableData = (productParameters:ProductParameter[],parameters:Parameter[]) => { 
        return productParameters.map(productParameter => {
            const currentParameter = parameters.find(parameter => parameter._id === productParameter.parameter_id);
            if(currentParameter){
               const currentValue = currentParameter.values.find(v => v._id === productParameter.parameter_value_id);
               let parameterValueName = "";
               if(currentValue){
                  parameterValueName = currentValue.internal_name;
               }

               return {...productParameter,parameter_name: currentParameter.internal_name, parameter_value_name: parameterValueName}
            }
            
            return productParameter
        })
    }
    */
    
    return <>
        <AddNewParameterButton onClick={onClickAddNewParameter}> {__("Pridať nový parameter")} </AddNewParameterButton>
        <EditableTable
            ebabledAddRowButton = {false}
            rowKey = "_id"
            columns = {[
                {
                    title: __('Názov parametra'),
                    dataIndex: 'parameter_name',
                    sorter: false,
                    render: (_id,data) => {
                        const currentParameter = parameters.find(parameter => parameter._id === data.parameter_id);
                        return <ParameterSelect _id = {data._id} currentParameter={currentParameter}/>

                        /*
                        return <ConnectedSingleSelect
                            isFetching={false}
                            //value={currentParameter?currentParameter.internal_name:""}
                            value={paramSearchValue}
                            data={parametersSearch?parametersSearch:[]}
                            selectWidth={rem(200)}    
                            onChange={onParameterChange}                      
                        />
                        */
                    }
                },
                {
                    title: __('Hodnota'),
                    dataIndex: 'parameter_value_name',
                    sorter: false,
                    editable: false, 
                    render: (_id,data) => {
                        const currentParameter = parameters.find(parameter => parameter._id === data.parameter_id);
                        if(currentParameter && currentParameter.type === 'VALUES'){
                            return  <Select defaultValue={data.parameter_value_id} style={{ width: 240 }} onChange={(parameter_value_id) => {onChangeValueParameterValue(data._id,parameter_value_id)  }}>
                                        {currentParameter && currentParameter.values.map(value => {
                                          return <Select.Option value={value._id}>{value.internal_name}</Select.Option>
                                        })}
                                    </Select>
                        }
                        else{
                            return <Input defaultValue={data.value} onBlur={(e) => onChangeNumberParameterValue(data._id,e.target.value)  } />
                        }
                    }
                },
                {
                    title: __('Akcie'),
                    dataIndex: 'actions',
                    sorter: false,
                    editable: false,
                    render: (_id,data) => {
                        return <Button type="primary"
                                      size="small"
                                      icon={<DeleteOutlined />}
                                      onClick={() => {
                                        onValueRemoveClick(data._id);
                                      }} 
                               />
                                
                     }
                }
            ]}
            dataSource = {productParameters}
            trans={{
                addRowText:"Pridať nový parameter"
            }}
        />
    </>
}