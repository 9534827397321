import { Col, Modal, Row, Typography } from "antd";
import { rem } from "polished";
import React, { useState } from "react";
import styled from "styled-components";
import API from "../../services/API";
import ConnectedSingleSelect from "../Select/ConnectedSingleSelect";

const ParameterRow = styled(Row)`
   margin-top: 25px;
`

export const ParameterValueSelectModal = (props) => {

    const { isVisible=true, onCancel, title, onOk } = props;
    
    const [parameterActiveFilter,setParameterActiveFilter] = useState(null as number); 
    const [parameterData, setParameterData] = useState([]);

    const [parameterValueActiveFilter,setParameterValueActiveFilter] = useState(null as number); 
    const [parameterValueData, setParameterValueData] = useState([]);

    const onChangeParameterFilter = (value) => {
        setParameterValueData([]);
        setParameterValueActiveFilter(null);

        API.loadParameters({filterInternalName:value,limit:100}).then(response => {
            const parameters = response.items.map(parameter => {
                return {
                    name: parameter.internal_name,
                    value: parameter._id,
                    internal_values: parameter.values.map(value => {
                        return {
                            name: value.internal_name,
                            value: value._id
                        }
                    })
                } 
            });
            setParameterData(parameters);  
        });
    }

    const onSelectParameterFilter = (value) => {
        setParameterActiveFilter(value);
        const actualParameter = parameterData.find(pd => pd.value === value);
        setParameterValueData(actualParameter.internal_values);
    }

    const onSelectParameterValueFilter = (value) => {
        setParameterValueActiveFilter(value);
    }

    return (
        <Modal
                title = {title}
                visible = {isVisible}
                onOk = {() => {onOk(parameterActiveFilter,parameterValueActiveFilter)}}
                onCancel = {onCancel}
            >  
            
            <Row justify="space-around" align="middle">
                <Col>
                    <Typography.Text>
                        Vybrať parameter:
                    </Typography.Text>         
                </Col>
                <Col>
                    <ConnectedSingleSelect
                        value={parameterActiveFilter}
                        data={parameterData}
                        selectWidth={rem(200)}
                        onChange={onChangeParameterFilter}
                        onSelect={onSelectParameterFilter}
                    />
                </Col>
            </Row>
            {(parameterValueData && parameterValueData.length)? 
                <ParameterRow justify="space-around" align="middle">
                    <Col>
                        <Typography.Text>
                            Vybrať hodnotu parametru:
                        </Typography.Text>         
                    </Col>
                    <Col>
                        <ConnectedSingleSelect
                            value={parameterValueActiveFilter}
                            data={parameterValueData}
                            selectWidth={rem(200)}
                            onSelect={onSelectParameterValueFilter}
                        />
                    </Col>
                </ParameterRow> 
                :""
            }

               
        </Modal>
    )
}