
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, ApplicationState } from "../../store";
import API from "../../services/API";
import { calculateMasks } from "../ProductCreate/slice";
import { replaceAll } from "../../utilities";

const NAMESPACE = 'uniqueProducts';

interface PmCategoriesGroupData {
    key: number,
    order: number,
    category_id: number, 
    category_name: string, 
    count: number
}

interface MainTableOptionalColumn {
    paramName: string;
    paramNameValue: string;
    value:string;
}

interface MainTableDataOptionalParam {
    name: string,
    value: string,
    defaultMask: {
        humanRegex: string,
        key: string,
        lowestNumberPosition: number,
        regex: string
    }
}

enum StringStyle {
    NONE = "NONE",
    FIRST_UPPER = "FIRST_UPPER",
    ALL_UPPER = "ALL_UPPER",
    ALL_LOWER = "ALL_LOWER"
}
interface MainTableData {
    key: number,
    picture: string;
    shop_product_name: string;
    manufacturer_param: string;
    product_name: string;
    model_name_param: string;
    model_name_param_prefix: string;
    model_name_param_suffix: string;
    optional1_param: string;
    optional2_param: string;
    optional3_param: string;
    optional4_param: string;
    optional5_param: string;
    optional6_param: string;
    optionalParams:MainTableDataOptionalParam[],
    url: string;
    short_description: string;

    category_id: number, 
    manufacturer_id: number,
    part_number: string, 
    ean:string,
    string_style:StringStyle
}

interface UniqueWorld {
    key: string,
    disabled: boolean
    word: string,
    replace: string,
    count: number
}

interface UniqueProductsState {
    activeFilters: {
        shopSelected: number,
        categorySelected: number,
        changeManufacturerSelected: number,
        changeCategorySelected: number
    },
    filtersData: {
        shop: {
            isFetching: boolean,
            data: any
        },
        changeManufacturer: {
            isFetching: boolean,
            data: any
        },
        changeCategory: {
            isFetching: boolean,
            data: any
        }
    },
    modals: {
        categories: {
            isVisible: boolean
        },
        productPreview: {
            isVisible: boolean,
            productData: MainTableData
        },
        changeCategory: {
            isVisible: boolean,
        },
        changeManufacturer: {
            isVisible: boolean,
        }
    }
    pmCategoriesGroup: {
        isFetching: boolean,
        data: PmCategoriesGroupData[]
    },
    mainTable:{
        isFetching: boolean,
        optionalParams:MainTableOptionalColumn[],
        data: MainTableData[]
    },
    uniqueWords:UniqueWorld[],
    currentSelectedRows: MainTableData[],
    removedKeys: number[]
}

const initialState : UniqueProductsState  = {
    activeFilters: {
        shopSelected: null,
        categorySelected: null,
        changeManufacturerSelected: null,
        changeCategorySelected: null
    },
    filtersData: {
        shop: {
            isFetching: false,
            data: []
        },
        changeManufacturer: {
            isFetching: false,
            data: []
        },
        changeCategory: {
            isFetching: false,
            data: []
        }
    },
    modals: {
        categories: {
            isVisible: false
        },
        productPreview: {
            productData: null,
            isVisible: false
        },
        changeCategory: {
            isVisible: false
        },
        changeManufacturer: {
            isVisible: false
        }, 
    },
    pmCategoriesGroup :{
        isFetching: false,
        data: []
    },
    mainTable:{
        isFetching: false,
        optionalParams: [],
        data: []
    },
    uniqueWords:[],
    currentSelectedRows:[],
    removedKeys:[]
}

export const uniqueProductsSlice = createSlice({
    name: NAMESPACE,
    initialState,
    reducers: {
        setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
        setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | number | null}>) => {
            state.activeFilters[action.payload.name] = action.payload.value;
        },
        setModalVisible: (state, action: PayloadAction<{modalName:string,value:boolean}>) => {
            state.modals[action.payload.modalName].isVisible = action.payload.value
        },
        setPmCategoriesGroupFetching: (state, action: PayloadAction<boolean>) => {
            state.pmCategoriesGroup.isFetching =  action.payload
        },
        setPmCategoriesGroupData: (state, action: PayloadAction<Array<PmCategoriesGroupData>>) => {
            state.pmCategoriesGroup.data =  action.payload
        },
        setMainTableFetching: (state,action: PayloadAction<boolean>) => {
            state.mainTable.isFetching = action.payload
        },
        setMainTableData: (state,action: PayloadAction<Array<MainTableData>>) => {
            state.mainTable.data = action.payload
        },
        setOptionalColumns: (state,action: PayloadAction<Array<MainTableOptionalColumn>>) => {
            state.mainTable.optionalParams = action.payload
        },
        setUniqueWords: (state,action: PayloadAction<Array<UniqueWorld>>) => {
            state.uniqueWords = action.payload
        },
        updateDisabledUniqueWords:(state,action:PayloadAction<Array<any>>) => {
            const uniqueWords = state.uniqueWords;
            for(const uniqueWord of uniqueWords){
                uniqueWord.disabled = action.payload.includes(uniqueWord.key);
            }
            state.uniqueWords = uniqueWords
        },
        recalculateMainTable: (state) => {
            state.mainTable.data = recalculateMainTable(state.mainTable.data,state.uniqueWords)
        },
        updateUniqueWord: (state,action:PayloadAction<{key:string,replace:string}>) => {
            const uniqueWords = state.uniqueWords;
            for(const uniqueWord of uniqueWords){
                if(uniqueWord.key === action.payload.key){
                    uniqueWord.replace = action.payload.replace;
                }
            }
            state.uniqueWords = uniqueWords
        },
        updateMainTableData: (state,action:PayloadAction<Array<{key:number,mainTableData:MainTableData}>>) => {
            const mainTableDatas = state.mainTable.data;
            for(const payloadAction of action.payload){
                const mainTableData = mainTableDatas.find(o => o.key === payloadAction.key);
                if(mainTableData){
                    mainTableData.model_name_param = payloadAction.mainTableData.model_name_param;
                    mainTableData.model_name_param_prefix = payloadAction.mainTableData.model_name_param_prefix;
                    mainTableData.model_name_param_suffix = payloadAction.mainTableData.model_name_param_suffix;
                }
            }
            state.mainTable.data = mainTableDatas;
        },
        removeMainTableData: (state, action:PayloadAction<Array<number>>) => {
            let filteredMainTableDatas = state.mainTable.data.filter(data => !action.payload.includes(data.key));
            state.mainTable.data = filteredMainTableDatas;
        },
        updateOptionalParams: (state,action:PayloadAction<Array<{key:number,optionalParam:MainTableDataOptionalParam}>>) => {
            const mainTableDatas = state.mainTable.data;
            for(const payloadAction of action.payload){
               const mainTableData = mainTableDatas.find(o => o.key === payloadAction.key);
               const optionalParams = mainTableData.optionalParams;
               const currentOptionalParam = optionalParams.find(op => op.name === payloadAction.optionalParam.name);
               if(currentOptionalParam){
                    currentOptionalParam.value = payloadAction.optionalParam.value;
                    currentOptionalParam.defaultMask = payloadAction.optionalParam.defaultMask;
               }
               else{
                optionalParams.push(payloadAction.optionalParam)
               }
               mainTableData[payloadAction.optionalParam.name] = payloadAction.optionalParam.value;
            }
            state.mainTable.data = recalculateMainTable(mainTableDatas,state.uniqueWords)
        },
        setProductPreviewModalData:(state,action:PayloadAction<MainTableData>) => {
            state.modals.productPreview.productData = action.payload
        },
        setIsFetchingFilterData: (state, action: PayloadAction<{ name: string, value: boolean }>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },
        setCurrentSelectedRows: (state, action: PayloadAction<Array<MainTableData>>) => {
            state.currentSelectedRows = action.payload
        },
        updateManufacturers: (state, action: PayloadAction<{keys:Array<number>,manufacturerId:number,manufacturerName:string}>) => {
            const mainTableDatas = state.mainTable.data;
            for(const mainTableData of mainTableDatas){
                if(action.payload.keys.includes(mainTableData.key)){
                    mainTableData.manufacturer_id = action.payload.manufacturerId;
                    mainTableData.manufacturer_param = action.payload.manufacturerName;
                }
            }
            state.mainTable.data = mainTableDatas
        },
        updateCategories: (state, action: PayloadAction<{keys:Array<number>,categoryId:number,categoryName:string}>) => {
            const mainTableDatas = state.mainTable.data;
            for(const mainTableData of mainTableDatas){
                if(action.payload.keys.includes(mainTableData.key)){
                    mainTableData.category_id = action.payload.categoryId;
                }
            }
            state.mainTable.data = mainTableDatas
        },
        updateFirstUpper: (state, action: PayloadAction<{keys:Array<number>}>) => {
            const mainTableDatas = state.mainTable.data;
            for(const mainTableData of mainTableDatas){
                if(action.payload.keys.includes(mainTableData.key)){
                    mainTableData.model_name_param = mainTableData.model_name_param.trim().charAt(0).toUpperCase() + mainTableData.model_name_param.trim().slice(1);
                }
            }
            state.mainTable.data = mainTableDatas
        },
        updateAllUpper: (state, action: PayloadAction<{keys:Array<number>}>) => {
            const mainTableDatas = state.mainTable.data;
            for(const mainTableData of mainTableDatas){
                if(action.payload.keys.includes(mainTableData.key)){
                    mainTableData.model_name_param = mainTableData.model_name_param.toUpperCase();
                }
            }
            state.mainTable.data = mainTableDatas
        },
        updateAllLower: (state, action: PayloadAction<{keys:Array<number>}>) => {
            const mainTableDatas = state.mainTable.data;
            for(const mainTableData of mainTableDatas){
                if(action.payload.keys.includes(mainTableData.key)){
                    mainTableData.model_name_param = mainTableData.model_name_param.toLowerCase();
                }
            }
            state.mainTable.data = mainTableDatas
        },
        updateStringStyle: (state, action: PayloadAction<{keys:Array<number>,stringStyle:StringStyle}>) => {
            const mainTableDatas = state.mainTable.data;
            for(const mainTableData of mainTableDatas){
                if(action.payload.keys.includes(mainTableData.key)){
                    mainTableData.string_style = action.payload.stringStyle
                }
            }
            state.mainTable.data = recalculateMainTable(mainTableDatas,state.uniqueWords);
        },
        addRemovedKeys: (state, action: PayloadAction<{keys:Array<number>}>) => {
            let removedKeys = state.removedKeys;
            removedKeys = removedKeys.concat(action.payload.keys);
            state.removedKeys = removedKeys;
        },

        /*
        setIsFetchingFilterResults: (state, action: PayloadAction<{value: boolean}>) => {
            state.filterResults.isFetching = action.payload.value
        },
        setFilterResults: (state, action: PayloadAction<{total: number, feedReports: Array<ListingFeedReport>}>) => {
            state.filterResults.total       = action.payload.total;
            state.filterResults.feedReports = action.payload.feedReports;
        },
        setPagination: (state, action: PayloadAction<{current: number}>) => {
            state.pagination.current = action.payload.current
        },
        setIsFetchingFilterData: (state, action: PayloadAction<{name: string, value: boolean}>) => {
            state.filtersData[action.payload.name].isFetching = action.payload.value;
        },
        setFilterData: (state, action: PayloadAction<{name: string, value:  any}>) => {
            state.filtersData[action.payload.name].data = action.payload.value;
        },
        setActiveFilterValue: (state, action: PayloadAction<{name: string, value:  number[] | string | number | null}>) => {
            state.activeFilters[action.payload.name] = action.payload.value;
        },
        */
    }
});

export const setFilter = (filter: string, value: number[] | string | number | null) => (dispatch, getState) => {
    dispatch(uniqueProductsSlice.actions.setActiveFilterValue({name: filter, value}));
}

export const setModalVisible = (modalName: string, value: boolean) => (dispatch, getState) => {
    dispatch(uniqueProductsSlice.actions.setModalVisible({modalName,value}));
}

export const loadShopFilterData = (value: string) : AppThunk => async (dispatch, getState) => {
    API.loadShops({query:value,limit:100}).then(response => {
      dispatch(uniqueProductsSlice.actions.setFilterData({name: 'shop', value: 
      response.shops.map(shop => {
          return {
            name: shop.name+` (${shop.program_id})`,
            value: shop.shop_id
          }
        })
      }));
    })  
}

export const loadGroupCategories = () : AppThunk => async (dispatch, getState) => {
    const shopIds = shopActiveFiltersSelector(getState());
    dispatch(uniqueProductsSlice.actions.setPmCategoriesGroupFetching(true));
    API.pmCategoriesGroup({shopIds:[shopIds]}).then(response => {
        const data:Array<PmCategoriesGroupData> = response.categories.map((category,index) => {
            return {
               key: category.category_id,
               order: index+1,
               category_id: category.category_id,
               category_name: category.category_name,
               count: category.count
            }
        })  ;
        dispatch(uniqueProductsSlice.actions.setPmCategoriesGroupData(data));
        dispatch(uniqueProductsSlice.actions.setPmCategoriesGroupFetching(false));
    });
}

export const loadMainTable = (): AppThunk => async (dispatch, getState) => {
    const shopId = shopActiveFiltersSelector(getState());
    const categoryId = categoryActiveFilterSelector(getState());

    //const shopId = 215;
    //const categoryId = 6162;

    dispatch(uniqueProductsSlice.actions.setMainTableFetching(true));

    API.loadCategories({categoryIds:[categoryId]}).then(response => {
        const category = response.categories && response.categories.length?response.categories[0]:null;
        if(category){
            // set optional params
            const optionalParams:MainTableOptionalColumn[] = [];
            for(let i=1;i<7;i++){
                let optionalParamValue = null;
                for (const [key, value] of Object.entries(category)) {
                    if(key === `optional${i}_param`){
                        optionalParamValue = value;
                    }
                }

                if(optionalParamValue){
                    optionalParams.push({
                        paramName: `optional${i}_param`,
                        paramNameValue: optionalParamValue,
                        value:"" 
                    })
                }
            }
            dispatch(uniqueProductsSlice.actions.setOptionalColumns(optionalParams));

            API.loadPMProducts({filterShopIds:[shopId],filterCategoryId:[categoryId],limit:1000, searchStrategy:"standard" }).then(response => {
                const manufacturerIds = response.pm_products.map(pmProduct => pmProduct.manufacturer_id).filter(o=>o).filter((x, i, a) => a.indexOf(x) === i);
                API.loadManufacturers({manufacturerIds:manufacturerIds,limit:10000}).then(manufacturerResponse => {
                    const mainTableData:MainTableData[] = response.pm_products.map(pmProduct => {
                        let defaultName = pmProduct.name;
                        if(pmProduct.manufacturer){
                            defaultName = defaultName.replace(pmProduct.manufacturer,"");
                        }

                        const originalManufacturer = manufacturerResponse.manufacturers.find(manufacturer => manufacturer.manufacturer_id === pmProduct.manufacturer_id);
    
                        return {
                            key: pmProduct.feed_id,
                            shop_product_name: pmProduct.name,
                            manufacturer_param: originalManufacturer?originalManufacturer.name:pmProduct.manufacturer!=='N/A'?pmProduct.manufacturer:"",
                            picture: pmProduct.image,
                            model_name_param: defaultName,
                            product_name: defaultName,
                            optional1_param: "",
                            optional2_param: "",
                            optional3_param: "",
                            optional4_param: "",
                            optional5_param: "",
                            optional6_param: "",
                            optionalParams:[],
                            model_name_param_prefix: "",
                            model_name_param_suffix: "",
                            url: pmProduct.url,
                            short_description: pmProduct.description?pmProduct.description.slice(0,200):"",
    
                            category_id: pmProduct.category_id,
                            manufacturer_id: pmProduct.manufacturer_id,
                            part_number: pmProduct.part_number,
                            ean: pmProduct.ean,
                            string_style: StringStyle.NONE
                        }
                    });
    
                    const uniqueWords:UniqueWorld[] = [];
                    for(const mainTableDataItem of mainTableData){
                        const words = mainTableDataItem.product_name.split(" ");
                        for(let word of words){
                            word = " "+word+" ";
                            const uniqueWord = uniqueWords.find(o => o.word === word);
                            if(uniqueWord){
                                uniqueWord.count+=1;
                            }
                            else{
                                uniqueWords.push({
                                    key: word,
                                    disabled: false,
                                    count: 1,
                                    word: word,
                                    replace: null
                                })
                            }
                        }
                    }
    
                    dispatch(uniqueProductsSlice.actions.setUniqueWords(uniqueWords));
                    dispatch(uniqueProductsSlice.actions.setMainTableData(mainTableData));
                    dispatch(uniqueProductsSlice.actions.setMainTableFetching(false)); 
                });
            })
        }
    });

    console.log("LOADING MAIN TABLE");
}

export const updateDisabledUniqueWords = (keys): AppThunk => async (dispatch, getState) => {
   dispatch(uniqueProductsSlice.actions.updateDisabledUniqueWords(keys));
   dispatch(uniqueProductsSlice.actions.recalculateMainTable());
}

export const updateUniqueWord = (key:string, replace:string) => async (dispatch, getState) => {
    dispatch(uniqueProductsSlice.actions.updateUniqueWord({key,replace}));
    dispatch(uniqueProductsSlice.actions.recalculateMainTable());
}

export const updateMainTable = (key:number, mainTableData:MainTableData, dataIndex:string) => async (dispatch, getState) => {
    const reduxMainTableData = mainTableDataSelector(getState());

    if(dataIndex === 'model_name_param' || 
       dataIndex === 'model_name_param_prefix' ||
       dataIndex === 'model_name_param_suffix'
      ){
        dispatch(uniqueProductsSlice.actions.updateMainTableData([{key,mainTableData}]));
    }
    else if(dataIndex.includes('optional')){
        const value = mainTableData[dataIndex];
        const masks = calculateMasks(value);
        const defaultMask = masks.defaultMask;
        const expr = new RegExp(defaultMask.regex,'i');
        
        const optionalParamsToUpdate:Array<{key:number,optionalParam:MainTableDataOptionalParam}> = [];

        for(const mainTableDataItem of reduxMainTableData){
            const regMatch = mainTableDataItem.model_name_param.match(expr);
            if(regMatch){
               const optionalParam: MainTableDataOptionalParam = {
                   name: dataIndex,
                   value: regMatch[0],
                   defaultMask: defaultMask
               };

               optionalParamsToUpdate.push({key:mainTableDataItem.key,optionalParam});
            }
        }

        dispatch(uniqueProductsSlice.actions.updateOptionalParams(optionalParamsToUpdate));
    }
}

export const recalculateMainTable = (mainTableData:Array<MainTableData>,uniqueWords:Array<UniqueWorld>):Array<MainTableData> => {
   const disabledUniqueWords = [...uniqueWords].sort((a,b) => b.word.length - a.word.length).filter(uniqueWord => uniqueWord.disabled);
   const replaceUniqueWords  = [...uniqueWords].sort((a,b) => b.word.length - a.word.length).filter(uniqueWord => uniqueWord.replace && uniqueWord.replace !== "");
   
   const newMainTableData = [];

   for(const oldMainTableDataRow of mainTableData){
      const mainTableDataRow = {...oldMainTableDataRow};
      let newProductName = mainTableDataRow.product_name; 

      for(const disableUniqueWord of disabledUniqueWords){
        newProductName = replaceAllRegex(newProductName,disableUniqueWord.word.trim(),"");
      }

      for(const replaceUniqueWord of replaceUniqueWords){  
        newProductName = replaceAllRegex(newProductName,replaceUniqueWord.word,replaceUniqueWord.replace);
        newProductName = replaceAllRegex(newProductName,replaceUniqueWord.word.trimLeft(),replaceUniqueWord.replace);
        newProductName = replaceAllRegex(newProductName,replaceUniqueWord.word.trimRight(),replaceUniqueWord.replace);
      }

      if(mainTableDataRow.optionalParams){
            for(const optionalParam of mainTableDataRow.optionalParams){
                newProductName = newProductName.replace(optionalParam.value,"");
            }
      }
      
      if(mainTableDataRow.string_style === StringStyle.FIRST_UPPER){
        newProductName = newProductName.trim().charAt(0).toUpperCase() + newProductName.trim().slice(1);
      }
      else if(mainTableDataRow.string_style === StringStyle.ALL_UPPER){
        newProductName = newProductName.toUpperCase();
      }
      else if(mainTableDataRow.string_style === StringStyle.ALL_LOWER){
        newProductName = newProductName.toLowerCase();
      }

      mainTableDataRow.model_name_param = newProductName;
      newMainTableData.push(mainTableDataRow);
   }

   return newMainTableData;
}

export const setProductPreviewModalData = (key:number|null): AppThunk => async (dispatch, getState) => {
   const mainTableData = mainTableDataSelector(getState());
   const productPreview = mainTableData.find(mt => mt.key === key);
   dispatch(uniqueProductsSlice.actions.setProductPreviewModalData(productPreview));
}

export const approveProducts = (mainTableData:Array<MainTableData>): AppThunk => async (dispatch, getState) => {
   console.log("APPROVE PRODUCTS!");
   for(const mainTableItem of mainTableData){
        
        const finalName = calculateFinalName(mainTableItem);
        const productGroups = [
            {
                name: finalName,
                pm_shop_product_ids: [mainTableItem.key]
            }
        ];

        const masks = calculateMasks(mainTableItem.model_name_param);
        const defaultMask = masks.defaultMask;

        const optional1Param = mainTableItem.optionalParams.find(op => op.name === 'optional1_param');
        const optional2Param = mainTableItem.optionalParams.find(op => op.name === 'optional2_param');
        const optional3Param = mainTableItem.optionalParams.find(op => op.name === 'optional3_param');
        const optional4Param = mainTableItem.optionalParams.find(op => op.name === 'optional4_param');
        const optional5Param = mainTableItem.optionalParams.find(op => op.name === 'optional5_param');
        const optional6Param = mainTableItem.optionalParams.find(op => op.name === 'optional6_param');

        let mask = "[manufacturer_param] [model_name_param_prefix] [model_name_param] [model_name_param_suffix]"
        for(const optionalParam of mainTableItem.optionalParams){
            mask += " ["+optionalParam.name+"]";
        }

        await API.createProduct({},{
            category_id: mainTableItem.category_id,
            manufacturer_id: mainTableItem.manufacturer_id,
            part_number: mainTableItem.part_number,
            ean: mainTableItem.ean,
            product_groups: productGroups,
            model_name_suffix: mainTableItem.model_name_param_suffix?mainTableItem.model_name_param_suffix:null,
            model_name_prefix: mainTableItem.model_name_param_prefix?mainTableItem.model_name_param_prefix:null,
            mask: mask,
            model_name_original: mainTableItem.model_name_param,
            model_name_regex: defaultMask.regex,
            optional1_original: optional1Param?optional1Param.value:null,
            optional2_original: optional2Param?optional2Param.value:null,
            optional3_original: optional3Param?optional3Param.value:null,
            optional4_original: optional4Param?optional4Param.value:null,
            optional5_original: optional5Param?optional5Param.value:null,
            optional6_original: optional6Param?optional6Param.value:null,
            optional1_regex: optional1Param?optional1Param.defaultMask.regex:null,
            optional2_regex: optional2Param?optional2Param.defaultMask.regex:null,
            optional3_regex: optional3Param?optional3Param.defaultMask.regex:null,
            optional4_regex: optional4Param?optional4Param.defaultMask.regex:null,
            optional5_regex: optional5Param?optional5Param.defaultMask.regex:null,
            optional6_regex: optional6Param?optional6Param.defaultMask.regex:null,
        }).then(response => {
            dispatch(uniqueProductsSlice.actions.removeMainTableData([mainTableItem.key]));
        }); 
   }
}

const calculateFinalName = (mainTableItem:MainTableData):string => {
    const finalNameParts = [];
    if(mainTableItem.manufacturer_param && mainTableItem.manufacturer_param!=='N/A') finalNameParts.push(mainTableItem.manufacturer_param.trim());
    if(mainTableItem.model_name_param_prefix) finalNameParts.push(mainTableItem.model_name_param_prefix.trim());
    if(mainTableItem.model_name_param) finalNameParts.push(mainTableItem.model_name_param.trim());
    if(mainTableItem.model_name_param_suffix) finalNameParts.push(mainTableItem.model_name_param_suffix.trim());
    for(const optionalParam of mainTableItem.optionalParams){
        if(optionalParam.value.trim()){
            finalNameParts.push(optionalParam.value.trim());
        }
    }

    const finalName = finalNameParts.join(" ");
    return finalName;
}

export const loadChangeCategoryFilterData = (value): AppThunk => async (dispatch, getState) => {
    dispatch(uniqueProductsSlice.actions.setIsFetchingFilterData({ name: 'changeCategory', value: true }));
    API.loadCategories({ query: value, limit: 100 }).then(response => {
        dispatch(uniqueProductsSlice.actions.setFilterData({
            name: 'changeCategory', value:
                response.categories.map(category => {
                    return {
                        name: [...category.parents_names].reverse().filter((item, index) => index % 2 === 0).join(' < '),
                        value: category.category_id
                    }
                })
        }));
        dispatch(uniqueProductsSlice.actions.setIsFetchingFilterData({ name: 'changeCategory', value: false }));
    });
}

export const loadChangeManufacturerFilterData = (value): AppThunk => async (dispatch, getState) => {
    dispatch(uniqueProductsSlice.actions.setIsFetchingFilterData({ name: 'changeManufacturer', value: true }));
    API.loadManufacturers({ query: value, limit: 100 }).then(response => {
        dispatch(uniqueProductsSlice.actions.setFilterData({
            name: 'changeManufacturer', value:
                response.manufacturers.map(manufacturer => {
                    return {
                        name: manufacturer.name,
                        value: manufacturer.manufacturer_id
                    }
                })
        }));
        dispatch(uniqueProductsSlice.actions.setIsFetchingFilterData({ name: 'changeManufacturer', value: false }));
    });
}

export const setCurrentSelectedRows = (currentSelectedRows:Array<MainTableData>): AppThunk => async (dispatch, getState) => {
    dispatch(uniqueProductsSlice.actions.setCurrentSelectedRows(currentSelectedRows));
}

export const updateManufacturers = (): AppThunk => async (dispatch, getState) => {
    const currentSelectedRows = currentSelectedRowsSelector(getState());
    const changeManufacturer = changeManufacturerActiveFiltersSelector(getState());
    
    API.loadManufacturers({manufacturerIds:[changeManufacturer]}).then(response => {
        const manufacturer = response.manufacturers && response.manufacturers.length?response.manufacturers[0]:null;
        if(manufacturer){
            const keys = currentSelectedRows.map(o => o.key);
            dispatch(uniqueProductsSlice.actions.updateManufacturers({keys:keys,manufacturerId:manufacturer.manufacturer_id,manufacturerName: manufacturer.name}));
        }
    })
}

export const updateCategories = (): AppThunk => async (dispatch, getState) => {
    const currentSelectedRows = currentSelectedRowsSelector(getState());  
    const changeCategory = changeCategoryActiveFiltersSelector(getState());

    API.loadCategories({categoryIds:[changeCategory]}).then(response => {
        const category = response.categories && response.categories.length?response.categories[0]:null;
        if(category){
            const keys = currentSelectedRows.map(o => o.key);
            dispatch(uniqueProductsSlice.actions.updateCategories({keys:keys,categoryId:category.category_id, categoryName:""}))
        }
    })
}

export const updateFirstUpper = (currentSelectedRows:Array<MainTableData>):AppThunk => async (dispatch, getState) => {
    const keys = currentSelectedRows.map(o => o.key);
    dispatch(uniqueProductsSlice.actions.updateStringStyle({keys,stringStyle:StringStyle.FIRST_UPPER}));
    //dispatch(uniqueProductsSlice.actions.updateFirstUpper({keys}));
}

export const updateAllUpper = (currentSelectedRows:Array<MainTableData>):AppThunk => async (dispatch, getState) => {
    const keys = currentSelectedRows.map(o => o.key);
    dispatch(uniqueProductsSlice.actions.updateStringStyle({keys,stringStyle:StringStyle.ALL_UPPER}));
    //dispatch(uniqueProductsSlice.actions.updateAllUpper({keys}));
}

export const updateAllLower = (currentSelectedRows:Array<MainTableData>):AppThunk => async (dispatch, getState) => {
    const keys = currentSelectedRows.map(o => o.key);
    dispatch(uniqueProductsSlice.actions.updateStringStyle({keys,stringStyle:StringStyle.ALL_LOWER}));
    //dispatch(uniqueProductsSlice.actions.updateAllLower({keys}));
}

const replaceAllRegex = (original:string,searchValue:string,replaceValue:string):string => {

    searchValue = replaceAll(searchValue,"(","\\(");
    searchValue = replaceAll(searchValue,")","\\)");

    const regEx = new RegExp(searchValue, "ig");
    const result = original.replace(regEx, replaceValue);
    return result;
}

export const removeProducts = (currentSelectedRows:Array<MainTableData>):AppThunk => async (dispatch, getState) => {
    dispatch(uniqueProductsSlice.actions.addRemovedKeys({keys:currentSelectedRows.map(o => o.key)}));
}

export const rejectProducts = (currentSelectedRows:Array<MainTableData>):AppThunk => async (dispatch, getState) => {
     /*
    const keys = currentSelectedRows.map(o => o.key);
    console.log(currentSelectedRows);
    
    for(const key of keys){
        await API.updateAutomaticAssignment(key,{},{rejected: true});
        dispatch(uniqueProductsSlice.actions.addRemovedKeys({keys:[key]}));
    }
    */
}

export const shopActiveFiltersSelector = (state: ApplicationState) => state.uniqueProducts.activeFilters.shopSelected;
export const shopFiltersDataSelector = (state: ApplicationState) => state.uniqueProducts.filtersData.shop.data;
export const shopFiltersDataIsFetching = (state: ApplicationState) => state.uniqueProducts.filtersData.shop.isFetching;

export const categoryActiveFilterSelector = (state: ApplicationState) => state.uniqueProducts.activeFilters.categorySelected;

export const modalsCategoriesIsVisible = (state: ApplicationState) => state.uniqueProducts.modals.categories.isVisible;

export const modalProductPreviewIsVisible = (state: ApplicationState) => state.uniqueProducts.modals.productPreview.isVisible;
export const modalProductPreviewDataSelector = (state: ApplicationState) => state.uniqueProducts.modals.productPreview.productData;
export const changeCategoryModalSelector = (state: ApplicationState) => state.uniqueProducts.modals.changeCategory;
export const changeManufactureModalSelector = (state: ApplicationState) => state.uniqueProducts.modals.changeManufacturer;

export const pmCategoriesGroupIsFetchingSelector = (state: ApplicationState) => state.uniqueProducts.pmCategoriesGroup.isFetching;
export const pmCategoriesGroupDataSelector = (state: ApplicationState) => state.uniqueProducts.pmCategoriesGroup.data;

export const mainTableIsFetchingSelector = (state: ApplicationState) => state.uniqueProducts.mainTable.isFetching;
export const mainTableOptionalParamsSelector = (state: ApplicationState) => state.uniqueProducts.mainTable.optionalParams;
export const mainTableDataSelector = (state: ApplicationState) => state.uniqueProducts.mainTable.data;

export const uniqueWordsSelector = (state: ApplicationState) => state.uniqueProducts.uniqueWords;

export const changeManufacturerActiveFiltersSelector = (state: ApplicationState) => state.uniqueProducts.activeFilters.changeManufacturerSelected;
export const changeManufacturerFiltersDataSelector = (state: ApplicationState) => state.uniqueProducts.filtersData.changeManufacturer.data;
export const changeManufacturerFiltersDataIsFetching = (state: ApplicationState) => state.uniqueProducts.filtersData.changeManufacturer.isFetching;

export const changeCategoryActiveFiltersSelector = (state: ApplicationState) => state.uniqueProducts.activeFilters.changeCategorySelected;
export const changeCategoryFiltersDataSelector = (state: ApplicationState) => state.uniqueProducts.filtersData.changeCategory.data;
export const changeCategoryFiltersDataIsFetching = (state: ApplicationState) => state.uniqueProducts.filtersData.changeCategory.isFetching;

export const currentSelectedRowsSelector = (state: ApplicationState) => state.uniqueProducts.currentSelectedRows;
export const removedKeysSelector = (state: ApplicationState) => state.uniqueProducts.removedKeys;

export default uniqueProductsSlice.reducer;