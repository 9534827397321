import moment from 'moment';

export const FORMAT_DATE_CHART = 'YYYYMMDD';
export const FORMAT_DATE_CHECK = 'YYYY-MM-DD';
export const FORMAT_HUMANE_DATE_DAY = 'dddd DD.MM.YYYY';
export const FORMAT_HUMANE_DATETIME = 'DD.MM.YYYY HH:mm';
export const FORMAT_HUMANE_DATE = 'DD.MM.YYYY';
export const FORMAT_HUMANE_TIME = 'HH.mm';

export const __ = (text: string) => {
  return text;
};

export const prop = (object, path, defaultValue = null) => {
  const val = path
    .trim()
    .split('.')
    .reduce((o, x) => {
      return typeof o === 'undefined' || o === null ? defaultValue : o[x];
    }, object);
  if (typeof val === 'undefined') {
    return defaultValue;
  }
  return val;
};

export const getProp = (object, path, defaultValue: any = '') => {
  if (path === undefined || path === null || path.length <= 0) {
    return defaultValue;
  }
  if (typeof path !== 'string') {
    throw new Error(`Invalid getProp argument path of type: ${typeof path}`);
  }
  const val = path.split('.').reduce((o, x) => {
    return typeof o === 'undefined' || o === null ? defaultValue : o[x];
  }, object);
  if (typeof val === 'undefined' || val === null) {
    return defaultValue;
  }
  return val;
};


export const getValuesFromArray = <T>(array: { value: T }[]): T[] | null => {
  if (array && array.length > 0) {
    return array.map(a => a.value);
  }
  return null;
};

export const formatDate = (date: any, format = FORMAT_DATE_CHECK) => {
  return moment.isMoment(date) ? moment(date).format(format) : '';
};

export const env = <T>(env: string): T => {
  return prop(process.env, env);
};

export const round = (num: number,decimalPlaces:number = 2): number => {
  return Math.round((num + Number.EPSILON) * Math.pow(10,decimalPlaces)) / Math.pow(10,decimalPlaces);
};

export function countInstancesInString(string, word) {
  return string.split(word).length - 1;
}

export function getRandomArbitrary(min:number, max:number):number {
  return Math.random() * (max - min) + min;
}

export function getRandomString(length):string{
  return Math.random().toString(36).substring(length);
}

export function escapeRegExp(string){
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}
 
/* Define functin to find and replace specified term with replacement string */
export function replaceAll(str, term, replacement) {
  return str.replace(new RegExp(escapeRegExp(term), 'g'), replacement);
}

export function median(values){
	values.sort(function(a,b){
  	return a-b;
  });
  var half = Math.floor(values.length / 2);
  
  if (values.length % 2)
  	return values[half];
  else
  	return (values[half - 1] + values[half]) / 2.0;
}

export function checkNullOrUndefined(value){
  return value === null || value === undefined;
}

export function chunk(array, size) {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
}



