import { Col, Modal, Row, Typography } from "antd";
import { rem } from "polished";
import React, { useState } from "react";
import API from "../../services/API";
import ConnectedSingleSelect from "../Select/ConnectedSingleSelect";

export const ManufacturerSelectModal = (props) => {

    const { isVisible=true, onCancel, title, onOk } = props;

    const [manufacturerActiveFilter,setManufacturerActiveFilter] = useState(null as number); 
    const [manufacturerData, setManufacturerData] = useState([]);
    
    const onChangeManufacturerFilter = (value) => {
        API.loadManufacturers({query:value,limit:100}).then(response => {
            const manufacturers = response.manufacturers.map( manufacturer => {
                return {
                    name: manufacturer.name,
                    value: manufacturer.manufacturer_id
                } 
            });
            setManufacturerData(manufacturers);  
        });
    }

    const onSelectManufacturerFilter = (value) => {
        setManufacturerActiveFilter(value);
    }

    return (
        <Modal
                title = {title}
                visible = {isVisible}
                onOk = {() => {onOk(manufacturerActiveFilter)}}
                onCancel = {onCancel}
            >  
            
            <Row justify="space-around" align="middle">
                <Col>
                    <Typography.Text>
                        Vybrať výrobcu:
                    </Typography.Text>         
                </Col>
                <Col>
                    <ConnectedSingleSelect
                        value={manufacturerActiveFilter}
                        data={manufacturerData}
                        selectWidth={rem(200)}
                        onChange={onChangeManufacturerFilter}
                        onSelect={onSelectManufacturerFilter}
                    />
                </Col>
            </Row>
               
        </Modal>
    )
}