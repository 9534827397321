import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Input, PageHeader, Radio, Row, Table } from 'antd';
import React  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ConnectedMultiSelect from '../../components/Select/ConnectedMultiSelect';
import { ApplicationState } from '../../store';
import { __ } from '../../utilities';
import { activeFilterIndexSelector, activeFilterShowSelector, categoryActiveFiltersSelector, categoryFiltersDataIsFetching, categoryFiltersDataSelector, loadCategoryFilterData, loadParameters, onCreate, paginationSelector, parametersSelector, setFilter, setPagination, setSorter, totalSelector } from './slice';

const MainContent = styled.div`
  margin-top: 25px;
  padding: 25px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
`

const ParameterList = () => {
    const dispatch = useDispatch();
    
    React.useEffect(() => {
        async function init() {
          await dispatch(loadParameters());
        }
        init();
    }, [dispatch]);

    const parameters = useSelector((state: ApplicationState) =>
        parametersSelector(state)
    );

    const total = useSelector((state: ApplicationState) =>
        totalSelector(state)
    );

    const pagination = useSelector((state: ApplicationState) =>
      paginationSelector(state)
    );

    const isFetchingCategoryData = useSelector((state: ApplicationState) =>
      categoryFiltersDataIsFetching(state)
    );

    const categoryActiveFilters = useSelector((state: ApplicationState) =>
      categoryActiveFiltersSelector(state)
    );

    const filterShow = useSelector((state: ApplicationState) =>
      activeFilterShowSelector(state)
    );

    const filterIndex = useSelector((state: ApplicationState) =>
      activeFilterIndexSelector(state)
    );

    const categoryData = useSelector((state: ApplicationState) =>
      categoryFiltersDataSelector(state)
    );

    const onChangeCategoryFilter = async value => {
        await dispatch(loadCategoryFilterData(value));
    };
    
    const onSelectCategoryFilter = async value => {
        dispatch(setFilter('category', value));
        dispatch(loadParameters());
    };

    const onChangeTable = async (pagination, filters, sorters) => {
        dispatch(setPagination(pagination.current));
        dispatch(setSorter(sorters.field, sorters.order));
        dispatch(loadParameters());
    };

    const onCreateClick = async value => {
        dispatch(onCreate());
    }

    const onChangeNameFilter = (e) => {
        dispatch(setFilter("name",e.target.value));
    }

    const onChangeShow = (e) => {
        const value = !!parseInt(e.target.value);

        console.log("XXXX");
        console.log(value);
        console.log(filterShow); 

        if(value === filterShow){
            dispatch(setFilter("show",null));
        }
        else{
            dispatch(setFilter("show",value));
        }
    }

    const onChangeIndex = (e) => {
        const value = !!parseInt(e.target.value);

        if(value === filterIndex){
            dispatch(setFilter("index",null));
        }
        else{
            dispatch(setFilter("index",value));
        }
    }

    return <> 
        <PageHeader 
            title = {"Parametre"}
            ghost={false}
            extra={[
               <Button onClick={onCreateClick}> {__("Vytvoriť nový")} </Button>
            ]}
        >   
           <Row gutter={16}>
                <Col span={3}>
                    <Input placeholder="Názov" onChange={onChangeNameFilter}/>
                </Col>
                <Col span={8}>
                    <ConnectedMultiSelect
                      name={__('Kategória')}
                      isFetching={isFetchingCategoryData}
                      value={categoryActiveFilters}
                      data={categoryData}
                      selectWidth={500}
                      onChange={onChangeCategoryFilter}
                      onSelect={onSelectCategoryFilter}
                    />
                </Col>
                <Col span={4}> 
                    <Radio.Group value = {filterShow===true?"1":filterShow===false?"0":null} >
                        <Radio.Button value="1" onClick={onChangeShow}>Zobrazovať</Radio.Button>
                        <Radio.Button value="0" onClick={onChangeShow}>Nezobrazovať</Radio.Button>
                    </Radio.Group>
                </Col>
                
                <Col span={4}>
                    <Radio.Group onChange = {onChangeIndex}  value={filterIndex===true?"1":filterIndex===false?"0":null}>
                        <Radio.Button value="1" onClick={onChangeIndex} >Indexovať</Radio.Button>
                        <Radio.Button value="0" onClick={onChangeIndex} >Neindexovať</Radio.Button>
                    </Radio.Group>
                </Col>
           </Row> 

            </PageHeader>
        <MainContent>
            <Table 
                columns = {[
                    {
                        title: __('Nazov'),
                        dataIndex: 'name',
                        sorter: true,
                    },
                    {
                        title: __('Jednotka'),
                        dataIndex: 'unit',
                        sorter: true,
                    },
                    {
                        title: __('Produkty'),
                        dataIndex: 'products_count',
                        sorter: true,
                    },
                    {
                        title: __('Indexovať'),
                        dataIndex: 'index',
                        sorter: true,
                        render: (field,data) => {
                            return <> {field?'Áno':'Nie'} </>
                        }
                    },
                    {
                        title: __('Zobrazovať'),
                        dataIndex: 'show',
                        sorter: true,
                        render: (field,data) => {
                            return <> {field?'Áno':'Nie'} </>
                        }
                    },
                    {
                        title: __('Rozbaľovať'),
                        dataIndex: 'expand',
                        sorter: true,
                        render: (field,data) => {
                            return <> {field?'Áno':'Nie'} </>
                        }
                    },
                    {
                        title: __('Akcie'),
                        dataIndex: 'actions',
                        sorter: false,
                        render: (_id,data) => {
                            return  <Link to={`/parameter/detail/${data._id}`} target="_blank"  > 
                                        <Button type="primary"
                                            size="small"
                                            icon={<EditOutlined />} />
                                    </Link>
                         }
                    }
                ]}
                dataSource = {parameters}
                pagination={{
                    total: total,
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                }}
                onChange={(pagination, filters, sorters) => {
                    onChangeTable(pagination, filters, sorters);
                }}
            />
        </MainContent>  
    </>
}

export default ParameterList