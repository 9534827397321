import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, ApplicationState } from '../../store';
import API from '../../services/API';
import { getValuesFromArray } from '../../utilities';
import moment from "moment";

const NAMESPACE = 'productListStats';

interface Product {
  ctr?: string;
  product_id?: number;
  product_name?: string;
  total_clicks?: number;
  total_impressions?: number;
  total_payment?: number;
  active_shops?: number;
  active_offers?: number;
}
interface GeneralStats {
  count: number;
  impressions: number;
  clicks: number;
  payment: number;
}

interface GeneralDailyStats {
  date: string;
  impressions: number;
  clicks: number;
  payment: number;
}

interface TreeNode {
  key?: string | number;
  title?: string;
  isLeaf?: boolean;
  children?: TreeNode[];
  parameters?: any[];
  value?: string | number;
}

interface ProductListStatsState {
  activeFilters: {
    startDate: moment.Moment;
    endDate: moment.Moment;
    category: number[];
    country: number;
    shop: { value: number }[];
    source: number;
    productIds: {value: number}[]
  };
  filtersData: {
    shop: {
      isFetching: boolean;
      data: any;
    };
    products: {
      data: any;
    };
  };
  filterResults: {
    offset: number;
    isFetching: boolean;
    total: number;
    products: Product[];
  };
  generalStats: {
    isFetching: boolean;
    data: GeneralStats;
  };
  generalDailyStats: {
    isFetching: boolean;
    data: Array<GeneralDailyStats>;
  };
  pagination: {
    pageSize: number;
    current: number;
  };
  sorterData: {
    type: string;
    order: string;
  };
  categoryTree: {
    categoryTree: TreeNode[];
    localeId: number;
  };
}

const initialState: ProductListStatsState = {
  activeFilters: {
    startDate: moment().subtract(7,"days"),
    endDate: moment(),
    category: null,
    country: null,
    shop: [],
    source: 0,
    productIds: [],
  },
  filtersData: {
    shop: {
      isFetching: false,
      data: [],
    },
    products: {
      data: [],
    },
  },
  filterResults: {
    isFetching: false,
    total: 0,
    offset: 0,
    products: [],
  },
  generalStats: {
    isFetching: false,
    data: null,
  },
  generalDailyStats: {
    isFetching: false,
    data: null,
  },
  pagination: {
    pageSize: 20,
    current: 1,
  },
  sorterData: {
    type: null,
    order: 'asc',
  },
  categoryTree: {
    categoryTree: [],
    localeId: 1,
  },
};

export const productListStats = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setProducts: (
      state,
      action: PayloadAction<{ total: number; products: Array<Product> }>
    ) => {
      state.filterResults.total = action.payload.total;
      state.filterResults.products = action.payload.products;
    },
    setIsFetchingProductList: (state, action: PayloadAction<boolean>) => {
      state.filterResults.isFetching = action.payload;
    },
    setActiveFilterValue: (
      state,
      action: PayloadAction<{
        name: string;
        value: number[] | string | number | null | boolean;
      }>
    ) => {
      state.activeFilters[action.payload.name] = action.payload.value;
    },
    setIsFetchingFilterData: (
      state,
      action: PayloadAction<{ name: string; value: boolean }>
    ) => {
      state.filtersData[action.payload.name].isFetching = action.payload.value;
    },
    setFilterData: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      state.filtersData[action.payload.name].data = action.payload.value;
    },
    setPagination: (
      state,
      action: PayloadAction<{ current: number; pageSize: number }>
    ) => {
      state.pagination.current = action.payload.current;
      state.pagination.pageSize = action.payload.pageSize;
    },
    setSorterData: (
      state,
      action: PayloadAction<{ type: string; order: string }>
    ) => {
      state.sorterData.type = action.payload.type;
      state.sorterData.order = action.payload.order;
    },
    setIsFetchingGeneralStats: (
      state,
      action: PayloadAction<{ value: boolean }>
    ) => {
      state.generalStats.isFetching = action.payload.value;
    },
    setGeneralStats: (state, action: PayloadAction<GeneralStats>) => {
      state.generalStats.data = action.payload;
    },
    setGeneralDailyStats: (
      state,
      action: PayloadAction<Array<GeneralDailyStats>>
    ) => {
      state.generalDailyStats.data = action.payload;
    },
    setTopCategories: (state, action: PayloadAction<TreeNode[]>) => {
      state.categoryTree.categoryTree = action.payload;
    },
    setChildCategories: (
      state,
      action: PayloadAction<{ parentId: string | number; children: TreeNode[] }>
    ) => {
      const categoryTree = state.categoryTree.categoryTree;
      const treeNode = findInTree(categoryTree, action.payload.parentId);
      if (treeNode) {
        treeNode.children = action.payload.children;
      }

      function findInTree(categoryTree: TreeNode[], parentId): TreeNode {
        for (const node of categoryTree) {
          if (node.key === parentId) return node;
          const childNode = findInTree(node.children, parentId);
          if (childNode) return childNode;
        }
        return null;
      }
    },
  },
});

export const loadProductStatList = (): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(productListStats.actions.setIsFetchingProductList(true));
  const startDate = startDateActiveFiltersSelector(getState());
  const endDate = endDateActiveFiltersSelector(getState());
  const offset =
    (getState().productListStats.pagination.current - 1) *
    getState().productListStats.pagination.pageSize;
  const category_ids = getState().productListStats.activeFilters.category;
  const shop = getValuesFromArray(shopActiveFiltersSelector(getState()));
  const country = countrySelector(getState());
  const source = sourceActiveFiltersSelector(getState());
  const productIds =  getValuesFromArray(productIdsActiveFiltersSelector(getState()));
  API.loadProductsList({
    startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
    endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
    offset: offset,
    limit: getState().productListStats.pagination.pageSize,
    sortType: getState().productListStats.sorterData.type
      ? getState().productListStats.sorterData.type
      : undefined,
    sortOrder: getState().productListStats.sorterData.order,
    categoryIds: category_ids ? category_ids : [],
    country: country ? country : 0,
    shopIds: shop && shop.length ? shop : undefined,
    source: source,
    productId: productIds && productIds.length ? productIds : undefined,
  }).then(response => {
    if (response.items) {
      const productsList = response.items.map(product => {
        let ctr = (
          (product.total_clicks / product.total_impressions) *
          100
        ).toFixed(2);
        if (ctr === 'NaN') {
          ctr = '0';
        }
        return {
          key: product._id,
          name: product.product_name,
          category_name: product.category_name,
          impressions: product.total_impressions,
          clicks: product.total_clicks,
          ctr: ctr,
          spend: product.total_payment.toFixed(2),
          cpc: product.cpc.toFixed(2),
          active_shops: product.active_shops,
          active_offers: product.active_offers,
        };
      });
      dispatch(
        productListStats.actions.setProducts({
          total: response.total,
          products: productsList,
        })
      );
      dispatch(productListStats.actions.setIsFetchingProductList(false));
    }
  });
};

export const loadGeneralStats = (): AppThunk => async (dispatch, getState) => {
  dispatch(productListStats.actions.setIsFetchingGeneralStats({ value: true }));
  const country = countrySelector(getState());
  const startDate = startDateActiveFiltersSelector(getState());
  const endDate = endDateActiveFiltersSelector(getState());
  const category_ids = getState().productListStats.activeFilters.category;
  const shop = getValuesFromArray(shopActiveFiltersSelector(getState()));
  const source = sourceActiveFiltersSelector(getState());
  const productIds =  getValuesFromArray(productIdsActiveFiltersSelector(getState()));
  API.loadGeneralProductsListStats({
    startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
    endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
    categoryIds: category_ids ? category_ids : [],
    country: country ? country : 0,
    shopIds: shop && shop.length ? shop : undefined,
    source: source,
    productId: productIds && productIds.length ? productIds : undefined,
  }).then(response => {
    const generalStats: GeneralStats = {
      clicks: response.clicks,
      count: response.count,
      impressions: response.impressions,
      payment: response.payment,
    };
    dispatch(productListStats.actions.setGeneralStats(generalStats));
  });
};

export const loadGeneralDailyStats = (): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(productListStats.actions.setIsFetchingGeneralStats({ value: true }));
  const country = countrySelector(getState());
  const startDate = startDateActiveFiltersSelector(getState());
  const endDate = endDateActiveFiltersSelector(getState());
  const category_ids = getState().productListStats.activeFilters.category;
  const shop = getValuesFromArray(shopActiveFiltersSelector(getState()));
  const source = sourceActiveFiltersSelector(getState());
  const productIds =  getValuesFromArray(productIdsActiveFiltersSelector(getState()));
  API.loadGeneralDailyProductsListStats({
    startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
    endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
    categoryIds: category_ids ? category_ids : [],
    country: country ? country : 0,
    shopIds: shop && shop.length ? shop : undefined,
    source: source,
    productId: productIds && productIds.length ? productIds : undefined,
  }).then(response => {
    const generalDailyStats: GeneralDailyStats[] = response.items.map(item => {
      return {
        date: item.date,
        clicks: item.clicks,
        impressions: item.impressions,
        payment: item.payment,
      };
    });

    generalDailyStats.sort((a, b) => {
      return a.date.localeCompare(b.date);
    });
    dispatch(productListStats.actions.setGeneralDailyStats(generalDailyStats));
    dispatch(
      productListStats.actions.setIsFetchingGeneralStats({ value: false })
    );
  });
};

export const loadTopCategories = (): AppThunk => async (dispatch, getState) => {
  API.loadCategoriesTree({ maxDepth: 1 }).then(response => {
    const treeNodes: TreeNode[] = response.categories.map(category => {
      let categoryName;
      if (category && category.translations && category.translations.length) {
        const categoryTranslation = category.translations.find(
          translation => translation.language_id === 1
        );
        if (categoryTranslation) {
          categoryName = categoryTranslation.name;
        }
      }
      return {
        title: categoryName,
        isLeaf: category.children && category.children.length ? false : true,
        children: [],
        parameters: category.parameters ? category.parameters : [],
        key: category.category_id,
        value: category.category_id,
      };
    });

    treeNodes.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });
    dispatch(productListStats.actions.setTopCategories(treeNodes));
  });
};

export const loadChildrenCategories = (
  parentId: string | number
): AppThunk => async (dispatch, getState) => {
  const response = await API.loadCategoriesTree({
    parentId: parentId as number,
    maxDepth: 1,
  });
  const treeNodes: TreeNode[] = response.categories.map(category => {
    let categoryName;
    if (category && category.translations && category.translations.length) {
      const categoryTranslation = category.translations.find(
        translation => translation.language_id === 1
      );
      if (categoryTranslation) {
        categoryName = categoryTranslation.name;
      }
    }

    return {
      key: category.category_id,
      title: categoryName,
      isLeaf: category.children && category.children.length ? false : true,
      children: [],
      parameters: category.parameters ? category.parameters : [],
      value: category.category_id,
    };
  });

  treeNodes.sort((a, b) => {
    return a.title.localeCompare(b.title);
  });

  dispatch(
    productListStats.actions.setChildCategories({
      parentId: parentId,
      children: treeNodes,
    })
  );
};

export const loadShopFilterData = (value: string): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(
    productListStats.actions.setIsFetchingFilterData({
      name: 'shop',
      value: true,
    })
  );

  const country = countrySelector(getState());
  API.loadShops({
    query: value,
    limit: 100,
    countryId: country ? country : undefined,
  }).then(response => {
    dispatch(
      productListStats.actions.setFilterData({
        name: 'shop',
        value: response.shops.map(shop => {
          return {
            name: shop.name + ` (${shop.program_id})`,
            value: shop.shop_id,
          };
        }),
      })
    );
    dispatch(
      productListStats.actions.setIsFetchingFilterData({
        name: 'shop',
        value: false,
      })
    );
  });
};

export const setFilter = (
  filter: string,
  value: number[] | string | number | null | boolean
) => (dispatch, getState) => {
  dispatch(
    productListStats.actions.setActiveFilterValue({ name: filter, value })
  );
};

export const setPagination = (current: number, pageSize: number) => (
  dispatch,
  getState
) => {
  dispatch(
    productListStats.actions.setPagination({
      current: current,
      pageSize: pageSize,
    })
  );
};

export const setSorter = (field: string, orderName: string) => (
  dispatch,
  getState
) => {
  const type: string = orderName ? field : undefined;
  const order: string = orderName === 'descend' ? 'desc' : 'asc';

  dispatch(
    productListStats.actions.setSorterData({ type: type, order: order })
  );
};
export const loadSetProduct = (query): AppThunk => async (
  dispatch,
  getState
) => {
  API.loadProducts({
    query: query,
    projectType: 'autocomplete',
    limit: 10,
  }).then(response => {
    const data = response.products.map(product => {
      return {
        name: product.name,
        value: product.product_id,
      };
    });
    dispatch(
      productListStats.actions.setFilterData({
        name: 'products',
        value: data,
      })
    );
  });
};

export const productsListIsFethingSelector = (state: ApplicationState) =>
  state.productListStats.filterResults.isFetching;
export const productsListSelector = (state: ApplicationState) =>
  state.productListStats.filterResults.products;
export const totalSelector = (state: ApplicationState) =>
  state.productListStats.filterResults.total;
export const paginationSelector = (state: ApplicationState) =>
  state.productListStats.pagination;

export const startDateActiveFiltersSelector = (state: ApplicationState) =>
  state.productListStats.activeFilters.startDate;
export const endDateActiveFiltersSelector = (state: ApplicationState) =>
  state.productListStats.activeFilters.endDate;

export const generalStatsSelector = (state: ApplicationState) =>
  state.productListStats.generalStats.data;
export const generalStatsFethingSelector = (state: ApplicationState) =>
  state.productListStats.generalStats.isFetching;
export const generalDailyStatsSelector = (state: ApplicationState) =>
  state.productListStats.generalDailyStats.data;

export const categoryTreeSelector = (state: ApplicationState) =>
  state.productListStats.categoryTree.categoryTree;

export const countrySelector = (state: ApplicationState) =>
  state.productListStats.activeFilters.country;

export const shopFiltersDataIsFetching = (state: ApplicationState) =>
  state.productListStats.filtersData.shop.isFetching;
export const shopActiveFiltersSelector = (state: ApplicationState) =>
  state.productListStats.activeFilters.shop;
export const shopFiltersDataSelector = (state: ApplicationState) =>
  state.productListStats.filtersData.shop.data;
export const sourceActiveFiltersSelector = (state: ApplicationState) =>
  state.productListStats.activeFilters.source;
export const productIdsActiveFiltersSelector = (state: ApplicationState) =>
  state.productListStats.activeFilters.productIds;
export const productIdsDataSelector = (state: ApplicationState) =>
  state.productListStats.filtersData.products.data;
export default productListStats.reducer;
