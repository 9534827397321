import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, ApplicationState } from '../../../store';
import API from '../../../services/API';

const NAMESPACE = 'manufacturerDetail';

interface Manufacturer {
  name: string;
  id: number;
  key: any;
}

interface ManufacturersDetailState {
  data: {
    isFetching: boolean;
    data: Manufacturer
  };
  uploadImage: {
    id: number;
    uploading: boolean;
  };
}

const initialState: ManufacturersDetailState = {
  data: {
    isFetching: false,
    data: null,
  },
  uploadImage: {
    id: null,
    uploading: false,
  },
};

export const manufacturersDetailState = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    setManufacturers: (
      state,
      action: PayloadAction<{
        manufacturers: Manufacturer;
      }>
    ) => {
      state.data.data = action.payload.manufacturers;
      state.data.isFetching = false;
    },
    setManufacturersIsFetching: (state, action: PayloadAction<boolean>) => {
      state.data.isFetching = action.payload;
    },
    setUploadingImage: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.uploadImage.uploading = action.payload
    },
  },
});

export const loadManufacturers = (id): AppThunk => async (
  dispatch,
  getState
) => {
  dispatch(manufacturersDetailState.actions.setManufacturersIsFetching(true));

  API.loadManufacturers({
    manufacturerIds: id ? id : undefined,
  }).then(response => {
    const manufacturers = response.manufacturers.map(item => {
      return {
        name: item.name,
        id: item.manufacturer_id,
        key: item._id,
      };
    });
    dispatch(
      manufacturersDetailState.actions.setManufacturers({
        manufacturers: manufacturers[0],
      })
    );
  });
};

export const uploadNewManufacturerImage = (images) => async (
  dispatch,
  getState
) => {
  dispatch(manufacturersDetailState.actions.setUploadingImage(true));
  const data = manufacturersDataDetailSelector(getState());
  API.uploadManufacturerLogo({manufacturerId: data.id}, images).then(response => {
    if(response){
      dispatch(manufacturersDetailState.actions.setUploadingImage(false));
    }
  }).catch((e) => {
    dispatch(manufacturersDetailState.actions.setUploadingImage(false));
  })
};

export const manufacturersDetailIsFethingSelector = (state: ApplicationState) =>
  state.manufacturersDetail.data.isFetching;
export const manufacturersDataDetailSelector = (state: ApplicationState) =>
  state.manufacturersDetail.data.data;
  
export const uploadingImageSelector = (state: ApplicationState) =>
  state.manufacturersDetail.uploadImage.uploading;


export default manufacturersDetailState.reducer;
